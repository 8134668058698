import { Button, Grid } from "@material-ui/core";
import { Fragment } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TimezoneForm from "./TimezoneForm";

const Timezone = (props) => {
    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <div className="onboarding__setp__btn__group__inner">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        size="small" 
                        className="mr-2" 
                        startIcon={<ArrowBackIcon />} 
                        onClick={() => props.goToStep(props.activeStep-1)}
                        disabled={props.loading}
                    >
                        Previous Step
                    </Button>
                    {
                        props.data.has_timezone && (
                            <Button 
                                variant="contained" 
                                color="primary" 
                                size="small" 
                                endIcon={<ArrowForwardIcon />} 
                                onClick={() => props.goToStep(props.activeStep+1)}
                                disabled={props.loading}
                            >
                                Next Step
                            </Button>
                        )
                    }
                    </div>
                    <p className="onboarding__step__one__title_log_out" onClick={props.logout}>Log Out</p>
                </Grid>
                <Grid item xs={12} className="pb-4">
                    <TimezoneForm user={props.data.user} callback={() => props.goToStep(props.activeStep+1)}/>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Timezone;