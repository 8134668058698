import { useEffect, useState } from "react";
import {useHistory} from 'react-router-dom';
import PostCardApp from "./postcard";
import LetterApp from "./letter";
import './css/DirecMailPostCard.css'
import NotecardApp from "./notecard";
import ZendirectApp from "./zendirect";

export const DIRECT_MAIL_FOR_SEND = 'direct-mail-send';
export const DIRECT_MAIL_FOR_CAMPAIGN_SETTING = 'campaign-setting';
export const DIRECT_MAIL_FOR_MAP = 'map-setting';

const DirectMailV2App = () => {
    const history = useHistory();
    const [fromLink, setFromLink] = useState(null);
    const [type, setType] = useState('');
    const [processFor, setProcessFor] = useState('direct-mail-send'); // for = direct-mail-send, campaign-setting, map-setting
    const [campaignData, setCampaignData] = useState(null)

    useEffect(() => {
        if (history.location.state !== undefined) {
          setFromLink(history.location.state.from);
          setType(history.location.state.card_type);
          setProcessFor(history.location.state.for);
          if (history.location.state.for === DIRECT_MAIL_FOR_CAMPAIGN_SETTING) {
            setCampaignData(history.location.state.campaign_setting);
          }
        } else {
          const locationHash = history.location.hash;
          const params = new URLSearchParams(history.location.search);
          if (
            locationHash !== undefined &&
            locationHash != null &&
            locationHash !== ""
          ) {
            if (locationHash === "#power-dialer-send") {
              try {
                let contactId = params.get("contactId"),
                  card_type = params.get("card_type"),
                  forView = params.get("for");
                if (
                  contactId !== undefined &&
                  contactId != null &&
                  card_type !== undefined &&
                  card_type != null
                ) {
                  setFromLink("");
                  setType(card_type);
                  setProcessFor(forView);
                  return;
                }
              } catch (error) {
                window.close();
                return;
              }
            }
          }
          window.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
      const handleBack = (e, afterSend = false) => {
        if (fromLink === "") {
          window.close();
          return;
        }
        if (localStorage.getItem("pype_postcard_store_data")) {
          localStorage.removeItem("pype_postcard_store_data");
        }
        if (e !== undefined && e != null) {
          e.preventDefault();
        }
        history.push({
          pathname: fromLink,
          state: {
            from: "direct-mail-setting-v2",
            afterSend: afterSend,
          },
        });
      };

    const renderCard = () => {
        if(type === 'postcard'){
            return <PostCardApp from={fromLink} handleBack={handleBack} processFor={processFor} campaignData={campaignData} />
        }
        if(type === 'letter'){
            return <LetterApp from={fromLink} handleBack={handleBack} processFor={processFor} campaignData={campaignData} />
        }
        if(type === 'notecard'){
            return <NotecardApp from={fromLink} handleBack={handleBack} processFor={processFor} campaignData={campaignData} />
        }
        if(type === 'zendirect_gift'){
            return <ZendirectApp from={fromLink} handleBack={handleBack} processFor={processFor} campaignData={campaignData} />
        }
        return null;
    }
    return(renderCard());
}
export default DirectMailV2App;