export const CustomFieldType = {
    TEXT : 1,
    MULTILINE_TEXT : 2,
    NUMERIC : 3,
    DATE : 4,
    CHECKBOX : 5,
    SELECT : 6,
    RADIO : 7,
    PHONE : 8,
    ZIP_CODE : 9,
    WEBSITE : 10
}