import { Skeleton } from "@material-ui/lab";
import React from "react";

export const TriggerModuleSkeleton = () => {
  return (
    <div className="trigger-skeleton-parent">
      <div className="d-flex justify-content-between trigger-child-one mb-3">
        <div className=" d-flex justify-content-start p-3">
          <div>
            <Skeleton variant="text" width={90} height={30} />
          </div>
        </div>
        <div className="d-flex justify-content-end p-3 gap-15">
          <div>
            <Skeleton variant="rect" width={170} height={50} />
          </div>
          <div>
            <Skeleton variant="rect" width={170} height={50} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between trigger-child-two p-3 gap-15 mb-3">
        <div>
          <Skeleton variant="text" width={420} height={20} />
        </div>

        <div>
          <Skeleton variant="rect" width={20} height={20} />
        </div>
      </div>
      <div className="d-flex justify-content-between trigger-child-three p-3 gap-15">
        <div className=" d-flex justify-content-start p-3 gap-15">
          <div>
            <Skeleton variant="text" width={30} height={30} />
          </div>
          <div>
            <Skeleton variant="rect" width={130} height={30} />
          </div>
        </div>
        <div className="d-flex justify-content-end p-3 gap-15">
          <div>
            <Skeleton variant="rect" width={90} height={30} />
          </div>
          <div>
            <Skeleton variant="rect" width={100} height={30} />
          </div>
        </div>
      </div>
      {/* <div style={{width:"100%"}}><Skeleton height={2}/></div> */}
      {Array.from(Array(3)).map((_, i) => (
        <div key={i} className="trigger-child-three-subitem d-flex justify-content-between p-3">
        <div className="d-flex justify-content-start p-4 gap-10">
          <div>
            <Skeleton variant="text" width={60} height={24} />
          </div>
          <div>
            <Skeleton variant="circle" width={24} height={24} />
          </div>
          <div>
            <Skeleton variant="rect" width={60} height={24} />
          </div>
        </div>
        <div className="d-flex justify-content-end p-4">
          <div>
            <Skeleton variant="rect" width={130} height={30} />
          </div>
        </div>
      </div>
      ))}

<div className="trigger-child-three-subitem-two d-flex justify-content-between p-3 mt-3">
        <div className="d-flex justify-content-start p-3 gap-10">
          <div>
            <Skeleton variant="text" width={60} height={24} />
          </div>
          <div>
            <Skeleton variant="circle" width={24} height={24} />
          </div>
          <div>
            <Skeleton variant="rect" width={60} height={24} />
          </div>
        </div>
        <div className="d-flex justify-content-end p-4">
          <div>
            <Skeleton variant="rect" width={130} height={30} />
          </div>
        </div>
      </div>
     
    </div>
  );
};
