// import React from "react";
import * as ACTION from "./contactActionTypes";

export const setContactDetails = payload => ({
    type: ACTION.SET_CONTACT_DETAILS,
    payload: payload
});

export const updateContactInfo = payload => ({
    type: ACTION.UPDATE_CONTACT,
    payload: payload
});

export const updateContactInfoWithCallback = (payload, callback) => ({
    type: ACTION.UPDATE_CONTACT,
    payload: {payload, callback}
});

export const getContactTagList = payload =>({
    type: ACTION.GET_CONTACT_TAG,
    payload: payload
})

export const getCategoryList = (payload, callback) =>({
    type: ACTION.GET_MAIL_CATEGORY,
    payload: {payload, callback}
})

export const CreateCardTemplate = (payload,callback)=>({
    type: ACTION.CREATE_POSTCARD_TEMPLATE,
    payload: {payload, callback}
})

export const toggleNoteModal = payload =>({
    type: ACTION.SHOW_NOTE_MODAL,
    payload: payload
})
export const toggleTaskModal = payload =>({
    type: ACTION.SHOW_TASK_MODAL,
    payload: payload
})

export const getUserVirtualNumberList = payload =>({
    type: ACTION.GET_VIRTUAL_NUMBER_LIST,
    payload: payload
})

export const getVideoFolders = () => ({
    type:ACTION.FETCH_VIDEO_FOLDERS,
    payload: {}
});
export const getVideos = payload => ({
    type:ACTION.FETCH_VIDEOS,
    payload: payload
});
export const deleteVideo = (payload, callback)=> ({
    type:ACTION.DELETE_VIDEOS,
    payload: {payload, callback}
});
export const updateFolderId = payload => ({
    type:ACTION.UPDATE_FOLDER_ID,
    payload: payload
});
export const getMessageTemplates = payload => ({
    type:ACTION.FETCH_MESSAGE_TEMPLATES,
    payload: payload
});
export const getAllContacts =  (payload, callback) => ({
    type:ACTION.GET_ALL_CONTACTS,
    payload: {payload, callback}
});

export const fetchTasks =  (payload, callback) => ({
    type:ACTION.FETCH_TASKS,
    payload: {payload, callback}
});

export const followupActivitySave = (payload, callback) => ({
    type: ACTION.SAVE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
});

export const getSubUserList = (payload,callback)=>({
    type: ACTION.GET_SUBUSER_LIST,
    payload: {payload, callback}
})
export const setSubUserListFromAction = payload => ({
    type: ACTION.SET_SUBUSER_LIST,
    payload: payload
});
export const getUserTagList = (payload,callback)=>({
    type: ACTION.GET_USERTAG_LIST,
    payload: {payload, callback}
});
export const setUserTagList = (payload)=>({
    type: ACTION.SET_USERTAG_LIST,
    payload: {payload}
});
export const getUserSourceList = (payload,callback)=>({
    type: ACTION.GET_USERSOURCE_LIST_RIGHTSIDE,
    payload: {payload, callback}
});
export const setUserSourceList = (payload)=>({
    type: ACTION.SET_USERSOURCE_LIST_RIGHTSIDE,
    payload: {payload}
});

export const getUserCustomFieldList = (payload,callback)=>({
    type: ACTION.GET_USERCUSTOMFIELD_LIST,
    payload: {payload, callback}
});
export const setUserCustomFieldList = (payload)=>({
    type: ACTION.SET_USERCUSTOMFIELD_LIST,
    payload: {payload}
});
export const getPipelineList = (payload,callback)=>({
    type: ACTION.GET_PIPELINE_LIST,
    payload: {payload,callback}
});
export const setPipelineList = (payload)=>({
    type: ACTION.SET_PIPELINE_LIST,
    payload: {payload}
});
export const fetchCampaignList = (payload,callback)=>({
    type: ACTION.GET_CAMPAIGN_LIST,
    payload: {payload,callback}
});
export const setCampaignList = (payload)=>({
    type: ACTION.SET_CAMPAIGN_LIST,
    payload: {payload}
});
export const campaignStart = (payload, callback) => ({
    type: ACTION.CAMPAIGN_START,
    payload: {payload, callback}
})

export const campaignPause = (payload, callback) => ({
    type: ACTION.CAMPAIGN_PAUSE,
    payload: {payload, callback}
})

export const updateRightSideReducerState = (payload) => ({
    type: ACTION.UPDATE_FIELD_DATA,
    payload: payload
})

export const updateContactColumn = payload => ({
    type: ACTION.UPDATE_CONTACT_COLUMN,
    payload: payload
});