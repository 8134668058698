import Styles from "./Style.module.css";
import AnnouncementIcon from "@material-ui/icons/Announcement";
const MessageBox = ({message="", reason=""}) => {
    return (
        <div className={Styles.tdpMbWrp}>
            <div className={Styles.announceTitle}><AnnouncementIcon />Attention</div>
            <span className={Styles.tdpMwMessage}>{message}</span>
            {reason !== "" && <span className={Styles.tdpMwReason}>Reason: {reason}</span>}
        </div>
    );
}
export default MessageBox;