import {useContext, useState} from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ContactDetailContext } from '../../reducer/GlobalReduers';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ContactHelper from '../../Helper';
import TimezoneConversation from '../../components/Accordion/TimezoneConversion';
import { confirmAlert } from 'react-confirm-alert';
import ApiList from '../../ApiList';

export const List = ({data, index, status, expand, refresh, key, editActivity, makeDone}) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [markAsDone, setMarkAsDone] = useState(false)
    const [submitMarkAsDone, setSubmitMarkAsDone] = useState(false);

    const checkDisabled = (forEdit = false) => {
        let disable = true,
            assigned_to = data.assigned_to != null ? parseInt(data.assigned_to) : null,
            task_user_id = parseInt(data.user_id),
            contact_owner = parseInt(detailStates.contactDetails?.user_id),
            user_id = parseInt(ContactHelper.getAccountData('userId'));

        if(makeDone!= null){
            if(task_user_id === user_id){
                disable = false
            }
            else if(assigned_to === user_id && !forEdit){
                disable = false
            }
            else if(contact_owner === user_id && !forEdit){
                disable = false
            }
        }
        return disable;
    }

    const changeMarkAsDone = (task_id) => {
        if(submitMarkAsDone) return;

        let disable = checkDisabled();
        if(disable){
            confirmAlert({
                title: 'Alert!',
                message: 'You can not add or update appointment to member user lead.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
            return
        }

        let loggedUserId = ContactHelper.getAccountData('userId')
        if (detailStates.contactDetails.user_id.toString() !== loggedUserId.toString()) {
            confirmAlert({
                title: 'Alert!',
                message: 'You can not add or update appointment to member user lead.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
            return false;
        }
        else{
            setMarkAsDone(true)
            setSubmitMarkAsDone(true)
            ApiList.activityMarkAsDone({id: data.id}).then(res => {
                setMarkAsDone(false);
                setSubmitMarkAsDone(false);
                refresh();
            })
        }
    }

    const updateActivity = () => {
        let disable = checkDisabled(true)
        if(disable){
            return
        }
        if(status == 1){
            editActivity(data.id, data)
        }
    }

    const renderCreatedBy = () => {
        let title = ""
        if((data.user !== undefined && data.user != null && data.user.full_name !== undefined)){
            title = data.user.full_name
        }
        else{
            title = ContactHelper.getAccountData('fullName')
        }

        return (
            <span className="g-appintment-info-user">
                By {title}
            </span>
        );
    }
    const renderCheckBox = () => {
        return (
            <div className="g-appointment-check">
                {(status == 1 && !markAsDone) &&
                    <span onClick={changeMarkAsDone}><CheckBoxOutlineBlankIcon /></span>            
                }
                {(status == 1 && markAsDone) &&
                    <CheckBoxIcon />            
                }
            </div>
        );
    }

    return(
        <div className="g-contact-appointment-each" key={key}>
            {renderCheckBox()}
            <div onClick={updateActivity} className="g-appintment-info">
                <span className="g-appintment-info-title" style={{color: '#039be5'}}>
                    {data.task_title}
                </span>
                <span className="g-appintment-info-date">
                    {TimezoneConversation(data.date + " " + data.time,'UTC',ContactHelper.getAccountData('userTimezoneRaw') , "MM/DD/YYYY hh:mm A")}
                </span>
                {/* <span className="g-appintment-info-user">
                    By {detailStates.contactDetails?.user?.full_name}
                </span> */}
                {renderCreatedBy()}
            </div>
        </div> 
    )
}