import React, { useState } from "react";
import { DropdownHelper } from "../addContact/DropdownHelper";
import ApiListAddDeal from "./assets/_ApiListAddDeal";
import CustomDatePicker from "../addContact/datePicker/CustomDatePicker";

const PipelineInformaiton = (props) => {
 
  const [stageList, setStageList] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState("");

  const handlePipelineChange = (value) => {
    setSelectedPipeline(value);
    props.handleUpdateState({ stage: "" });
    setStageList([]);
    ApiListAddDeal.getAllStageByPipeline({ pipelineId: value }).then((res) => {
      let response = res.data;
      setStageList(response.dealStage);
    });
  };

  const handleStage = (value) => {
    props.handleUpdateState({ stage: value })
  };

  return (
    <div className="deal__single__section__wr">
      <div className="deal__single__section__wr__row_1">
        <h3 className="add__deal__label__title">Deal Title</h3>
        <input
          className="add__deal__text__field"
          min="0"
          value={props.prevStates.title}
          onChange={(event) => props.handleUpdateState({ title: event.target.value })}
          placeholder="Enter title"
        />
      </div>

      <div className="deal__single__section__wr__row_2">
        <div className="deal__single__section__wr__row_3">
          <h3 className="add__deal__label__title">
            Select Pipeline
          </h3>

          <DropdownHelper
            datalist={props.pipelines}
            noneFieldRequired={true}
            noneFieldTitle="Select Pipeline"
            className=""
            // mapping={{ title: "label", id: "value" }}
            mapping={{ title: "title", id: "id" }}
            menuItemClassName=""
            updateSelectedData={handlePipelineChange}
            selectedValue={selectedPipeline}
            updateDatatable={() => console.log("update")}
            dropdownHeader={"ownerChange"}
            parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
            from="add-deal"
          />
        </div>

        <div className="">
          <h3 className="add__deal__label__title">
            Select a stage
          </h3>

          <DropdownHelper
            datalist={stageList}
            noneFieldRequired={true}
            noneFieldTitle="Select stage"
            className=""
            mapping={{ title: "label", id: "value" }}
            menuItemClassName=""
            updateSelectedData={handleStage}
            selectedValue={props.prevStates.stage}
            updateDatatable={() => console.log("update")}
            dropdownHeader={"ownerChange"}
            parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
            from="add-deal"
          />
        </div>
      </div>

      <div className="deal__value__with__estimate__date">
        <div className="deal__value__with__estimate__1">
          <h3 className="add__deal__label__title">Deal Value</h3>
          <input
            className="add__deal__text__field"
            min="0"
            value={props.prevStates.dealValue}
            onChange={(event) => props.handleUpdateState({ dealValue: event.target.value })}
            type="number"
            placeholder="Enter deal value"
          />
        </div>

        <div className="deal__value__with__estimate__2">
          <h3 className="add__deal__label__title">
            Estimate Close Date
          </h3>
          <div className="add__deal__text__field add__deal__text__field__datePicker">
            <CustomDatePicker
              value={props.prevStates.estimatedClosingDate}
              changeDate={(date) => props.handleUpdateState({ estimatedClosingDate: date })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineInformaiton;
