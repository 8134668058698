const PostcardImagePreview = ({
    image_url,
    selectedTemplate
}) => {
    return(
        <div className='dmpc_3rd_tv_right_inner_img' style={image_url != null ? {background:`url('${image_url}')`} : {}}>
            <div className='dmpc_3rd_tv_right_inner_red_border'>
                <div className='dmpc_3rd_tv_right_inner_border'>
                    <div className='dmpc_3rd_no_image_found'>
                        <div className='dmpc_3rd_no_image_found_inner'>
                            <div className='dmpc_3rd_postage_title'>
                                <div>sender address</div>
                                <div>------------</div>
                                <div>------------</div>
                            </div>
                            <sapn className="dmpc_3rd_postage">POSTAGE INDICIA</sapn>
                        </div>
                        <h4 className='dmpc_3rd_postage_uppercase'>RECIPIENT NAME ADDRESS CITY,STATE ZIPCODE</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PostcardImagePreview;