import { Box, Typography, makeStyles, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomTitle from "../common/CustomTitle";
import EditAdditionalInfoModal from "./EditAdditionalInfoModal";
import {setContactDetails} from "../../../../actions/contactAction";
import EachCustomField from "./EachCustomField";
import {updateContactCustomFieldV2} from "../../../../api/contactApi";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const AdditionalInfo = (props) => {
  const [open, setOpen] = useState(false);
  const [contactCustomFields, setContactCustomFields] = useState([]);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if(props.contact?.all_contact_custom_fields) {
            setContactCustomFields(props.contact.all_contact_custom_fields);
        }
    }, [props.contact]);

    const fields = () => {
        console.log('LINE 38 ', contactCustomFields);
        let arrayData = [];
        contactCustomFields.map((data, index) =>
            arrayData.push(<EachCustomField data={data} key={index} value={contactCustomFields} />)
        );

        return arrayData;
    };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    const handleSubmit = (customFieldValues) => {
        updateContactCustomFieldV2({
            contactId: props.contact.id,
            params: Object.values(customFieldValues),
        }).then((response) => {
            if (response.success) {
                setContactCustomFields(response.data);
                setOpen(false);
            } else if (!response.success && response.status === 403) {
                if (response.data && response.data.data) {
                    setErrors(response.data.data);
                }
            }
        });
    };

  const icons = useStyles();
  return (
    <Box className={icons.hoverDiv} sx={{ mt: 3 }}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle
          title={"CUSTOM FIELDS"}
          iconHide={icons.icon}
          handleOpen={handleOpen}
        />
      </Box>

      <Box
        sx={{
          borderRadius: "4px !important",
          border: "1px solid #ced4da !important",
          padding: "8px 8px",
          overflow: "hidden",
        }}
      >
          {contactCustomFields.length > 0 && (
              <Paper variant='outlined' elevation={0}>
                  <Box sx={{ maxHeight: "500px" }}  p={1}>
                      {fields()}
                  </Box>
              </Paper>
          )}
      </Box>
      <EditAdditionalInfoModal  open={open}
                                errors={errors}
                                setErrors={setErrors}
                                contactCustomFields={contactCustomFields}
                                onClose={handleClose}
                                onSubmit={handleSubmit} />
    </Box>
  );
};

const mapStateToProps = (state) => {
    return {
        contact: state.addContactReducer.contact
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setContactDetails: (params) => dispatch(setContactDetails(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
