import { useEffect, useState } from "react";
import { Grid } from '@material-ui/core';
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from './defaultStyle';
import './note.css';
import { getAllTeamMembers } from "../../redux/contactDetailApi";

const mentionStyle = {
    backgroundColor: '#cee4e5'
};

const NoteComponent = ({
    value,
    onChange
}) => {
    const [allTeamUser, setAllTeamUser] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        loadTeamUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeNote = (event) => {
        onChange((event.target.value) ? event.target.value : '')
    }
    const loadTeamUserList = () => {
        if(loading){
            return
        }
        setLoading(true)
        getAllTeamMembers({}).then(res=>{
            if(res.data.users){
                let allTeamUser=res.data.users
                let  users = allTeamUser.map((data) => {
                    return {
                        id: data.value,
                        display: data.label,
                    };
                });
                setAllTeamUser(users)
                setLoading(false)
            }else{
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
        })
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className="modal-content p-0">
                    <div className="chat__bottom_wrapper update">
                        <div className="tiny-mce">
                            <MentionsInput
                                cols={30}
                                rows={5}
                                className="control-overflow mentionClass"
                                value={value}
                                onChange={(e)=>handleChangeNote(e)}
                                style={defaultStyle}
                                markup="@[__display__](__id__) "
                                placeholder={"Add notes and mentions (@username)"}
                            >
                                <Mention
                                    type="user"
                                    trigger="@"
                                    data={allTeamUser}
                                    renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                        <div className="user">{highlightedDisplay}</div>
                                    )}
                                    style={mentionStyle}
                                />
                            </MentionsInput>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
export default NoteComponent;