import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getContactsFromTopbar, getContactsFromTopbarNew ,searchContactsFromElastic} from "../../api/globalApi";
import useDelayCallback from "../../hooks/useDelayCallback";
import { useIsMounted } from "../../hooks/useIsMounted";
import GlobalSearchBox from "../globals/GlobalSearchBox/GlobalSearchBox";
import Skeleton from "@material-ui/lab/Skeleton";

let typingTimer;
const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const getHtmlText = (message) => {
    let regix = /\@.*?\)/g;
    let matchStrign = message.match(regix);
    if (matchStrign !== null && Array.isArray(matchStrign)) {
        matchStrign.map((str) => {
            let eachStr = str.match(/@\[(.*)\]\(.*\)/);
            message = message.replace(str, "@" + eachStr[1])
        });
    }
    return message;
}

const SearchMenu = (props) => {
    const isMounted = useIsMounted();
    const [menuVisible, setMenuVisible] = useState(false);
    const [searchTabValue, setSearchTabValue] = useState(0);
    const [searchText, setSearchText] = useState('');
    // const [totalContacts, setTotalContacts] = useState(0);
    const [totalNotes, setTotalNotes] = useState(0);
    const [contacts, setContacts] = useState([]);
    const [notes, setNotes] = useState([]);
    const [scrolling, setScrolling] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [isMoreData, setIsMoreData] = useState(false)
    const [loadingNewData, setLoadingNewData] = useState(false)

    const [loadingData, setLoadingData] = useState(false)

    const global_search_text_ref = useRef("")
    const eventListener = useRef()

    function clickListener(){
        let test = document.getElementById("top-search-result");
        let search_div = document.getElementById("global_search_text_ref_id");
        if(test){
            if(global_search_text_ref.current.value === "" || search_div !== document.activeElement){
                if(test.classList.contains("global___search___panel")){
                    setMenuVisible(false)
                    test.classList.remove("global___search___panel");
                }
            }
        }
    }

    useEffect(()=>{
        if(!menuVisible){
             window.removeEventListener(
                "click", 
                eventListener.current
            );
        }else{
            eventListener.current = clickListener
            window.addEventListener(
                "click", 
                clickListener
            );
        }
    },[menuVisible])

    useEffect(() => {
        setMenuVisible(searchText !== "");
        setLoadingData(true)
    },[searchText]);

    useDelayCallback(() => {
        if (isMounted && searchText !== "" && (props.from === undefined || props.from !== 'activity-modal')) {
            setScrolling(true)

            if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){
                searchUsingElastic(1)

            }else{
                getSearchContactsForScroll(1)
            }
        }else if (isMounted && searchText !== "" && props.from !== undefined && props.from === 'activity-modal'){
            if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){
                searchUsingElastic(1)

            }else{
                getSearchContactsForScroll(1)
            }
            // searchContactsFromElastic('GET',{},"?search="+searchText).then(res => {
            //     setContacts(res.data?.contactInfos);
            //     setLoadingData(false)
            // }).catch(error => {
            //     setLoadingData(false)
            // });
        }
    },[searchText])

    const getSearchContactsForScroll = (page, onScroll=false )=>{
        
        if(!onScroll){
            setLoadingNewData(true)
        }
        setPageNo(page);
        getContactsFromTopbarNew({q:searchText,page:page, perPage: 10}).then(res => {
            setLoadingData(false)
            // setTotalContacts(countTotalContact(res.data.data));
            if(onScroll){
                setContacts((prevData)=> [...prevData,...res.data.data.pypepro_contacts]);

            }else{
                setContacts(res.data.data.pypepro_contacts)
                if(!onScroll){
                    setLoadingNewData(false)
                }
            }
            if(res.data.data.pypepro_contacts.length < 10){
                setIsMoreData(false)
            }else{
                setIsMoreData(true)
            }

            // setTotalNotes(countTotalNotes(res.data.data));
            setNotes(res.data.data.pypepro_notes);

            setScrolling(false)


    });
    }

    const searchUsingElastic= (page,onScroll=false)=>{

        if(!onScroll){
            setLoadingNewData(true)
        }
        setPageNo(page);

        searchContactsFromElastic('GET',{},"?page=1&size=10&search="+searchText)
        .then(res => {
            setLoadingData(false)
        if (!res) {
            window.showNotification('ERROR', 'Something went wrong')
        
        }
        if(onScroll){
            setContacts((prevData)=> [...prevData,...res.data.contactInfos]);

        }else{
            setContacts(res.data?.contactInfos)
            if(!onScroll){
                setLoadingNewData(false)
            }
        }
        if(res.data.contactInfos.length < 10){
            setIsMoreData(false)
        }else{
            setIsMoreData(true)
        }

        // setTotalNotes(countTotalNotes(res.data.data));
        // setNotes(res.data.data.pypepro_notes);

        setScrolling(false)

        
        })
        .catch(e=>{
            console.log('error : '+e);
            setLoadingData(false)
        });
    }

    const countTotalNotes = (data) => {
        let counts = [];
        for(var i = 0; i < data.aggs.length ; i++ ){
            counts[data.aggs[i].key] = data.aggs[i].doc_count;
        }
        return (typeof counts["pypepro_notes"] != "undefined") ? counts["pypepro_notes"] : 0;
    }

    const countTotalContact = (data) => {
        let counts = [];
        for(var i = 0; i < data.aggs.length ; i++ ) {
            counts[data.aggs[i].key] = data.aggs[i].doc_count;
        }
        return (typeof counts["pypepro_contacts"] != "undefined") ? counts["pypepro_contacts"] : 0;
    }
    // For laravel search, dont remove
    const renderContacts = () => {

        if(typeof contacts != 'undefined'){
            let allContacts = contacts;
            return allContacts.map((contact,index) => {
                let firstName = contact['first_name'] ? contact['first_name'] : '';
                let lastName = contact['last_name'] ? contact['last_name'] : '';
                let avatar = firstName?.substring(0,1) + lastName?.substring(0,1)
                return <div className="search-list-items" key={index}>
                    <Link to={"/contacts/"+contact.id} onClick={(e) => {e.stopPropagation();setMenuVisible(false)}} className="d-flex justify-content-start align-items-center search_content">
                        <span className="d-flex align-items-center justify-content-center search_avatar">{avatar}</span>
                        <div className="search_infos">
                            <p className="search_name">{(firstName + " "+lastName).trim()}</p>
                            <p className="search_email">{contact['email']}</p>
                            <p className="search_number">{contact['number']}</p>
                        </div>
                    </Link>
                </div>
            });
        }
        return "";
    }

    // For laravel search, dont remove
    const renderElasticContacts = () => {
        if(typeof contacts != 'undefined'){
            let allContacts = contacts;
            return allContacts.map((contact,index) => {
                let firstName = contact['firstName'] ? contact['firstName'] : '';
                let lastName = contact['lastName'] ? contact['lastName'] : '';
                let avatar = firstName?.substring(0,1) + lastName?.substring(0,1)
                return <div className="search-list-items" key={index}>
                    <Link to={"/contacts/"+contact.id} onClick={(e) => {e.stopPropagation();setMenuVisible(false)}} className="d-flex justify-content-start align-items-center search_content">
                        <span className="d-flex align-items-center justify-content-center search_avatar ml-2">{avatar}</span>
                        <div className="search_infos">
                            <p className="search_name">{(firstName + " "+lastName).trim()}</p>
                            <p className="search_email">{contact['email']}</p>
                            <p className="search_number">{contact['number']}</p>
                        </div>
                    </Link>
                </div>
            });
        }
        return "";
    }

    const checkFullNameOrNumber = (contactObj,key) => {
       let res =null ;
       if(contactObj[key] !== undefined && contactObj[key] != null && contactObj[key] !==""){
           res = contactObj[key] ; 
       }
       return res;
    }
    
    const renderContactsForActivityModal = () => {

        if(loadingData){
            return(
                <div className="empty__loading__container">
                    {loadingData ? "Please wait..." : "No contact available !"}
                </div>
            )
        }
        
        if(typeof contacts != 'undefined'){
            if(contacts.length === 0){
                return(
                    <div className="empty__loading__container">
                        No contact available !
                    </div>
                )
            }
            // let allContacts = contacts.slice(0, 5);
            // return allContacts.map((contact,index) => {
            //     return <div className="search-list-items cursor-pointer" key={index} onClick={()=>props.onSubmit(contact)}>
            //             <div className="search_infos">
            //                 <p className="search_name">{contact['first_name'] + " "+contact['last_name']}</p>
            //                 <p className="search_email">{contact['email']}</p>
            //                 <p className="search_number">{contact['number']}</p>
            //             </div>
            //     </div>
            // });
            let maxlen = contacts.length > 10 ? 10 : contacts.length;
            let dataArray = []
            let contactIds = []
            
            for(let i=0; i< maxlen ;i++){
                if(!contactIds.includes(contacts[i].id)){
                    contactIds.push(contacts[i].id)
                    dataArray.push(
                        <div className="search-list-items cursor-pointer" key={i} onClick={()=>props.onSubmit(contacts[i])}>
                         <div className="search_infos">

                             <p className="search_name">{checkFullNameOrNumber(contacts[i],'fullName') ?? ""}</p>
                             <p className="search_email">{contacts[i]['email']}</p>
                             <p className="search_number">{checkFullNameOrNumber(contacts[i],'number') ?? ""}</p>
                             
                         </div>
                 </div>
                )
                }
            }
            return dataArray
            
        }
        else{
            return(
                <div className="empty__loading__container">
                    {loadingData ? "Please wait..." : "No contact available !"}
                </div>
            )
        }
        return null;
    }

    const renderNotes = () => {
        if(typeof notes != 'undefined'){
            let allNotes = notes.slice(0, 5);
            return allNotes.map((note,index) => {
                return <div className="search-list-items">
                <Link to={"/contacts/"+note.contact_id} onClick={(e) => {e.stopPropagation();setMenuVisible(false)}} className=" d-flex justify-content-start align-items-center">
                    <span className=" d-flex align-items-center justify-content-center">
                        <svg width="12" height="12" viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.20879 0.65232L6.20882 0.652287C6.30633 0.55478 6.43867 0.5 6.5761 0.5H10.845C11.1322 0.5 11.3646 0.7324 11.3646 1.01957V5.288C11.3646 5.42582 11.3099 5.55831 11.2121 5.65649C11.212 5.65658 11.212 5.65668 11.2119 5.65678L5.6557 11.2119L5.65567 11.2119C5.45279 11.4148 5.12398 11.4148 4.9211 11.2119L0.652386 6.94323C0.44908 6.73966 0.449405 6.41068 0.65216 6.20793L6.20879 0.65232ZM7.79612 2.80382C7.79612 3.50206 8.36255 4.0685 9.0608 4.0685C9.75904 4.0685 10.3255 3.50206 10.3255 2.80382C10.3255 2.10557 9.75904 1.53914 9.0608 1.53914C8.36255 1.53914 7.79612 2.10557 7.79612 2.80382Z"
                                fill="#546376" stroke="#546376"/>
                        </svg>
                    </span>
                    <p>{getHtmlText(note.message !== undefined ? note.message : '')}</p>
                </Link>
            </div>
            });
        }
        return "";
    }

    const loadMoreContact =(e)=>{
        setScrolling(true)
        if((props.from === undefined || props.from !== 'activity-modal') && !scrolling && isMoreData &&
            Math.round(e.target.scrollTop + e.target.clientHeight+50, 20) >= Math.round(e.target.scrollHeight, 10)){
            // getMoreData(pageNo+1)
            clearTimeout(typingTimer);

            typingTimer = setTimeout(() => { 
                
                if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){
                searchUsingElastic(pageNo+1, true)
                }else{
                    getSearchContactsForScroll(pageNo+1, true)
                }
            }, 500);

            
        } else{
            setScrolling(false)
        }
    }

    const onFocusGlobalSearch = (e) => {
        e.stopPropagation();
        let test = document.getElementById("top-search-result");
        if(searchText !== ""){
            if(test){
                setMenuVisible(true)
            }
        }else{
            if(test){
                if(test.classList.contains("global___search___panel")){
                    setMenuVisible(false)
                    test.classList.remove("global___search___panel");
                }
            }
        }
    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 5; i++) {
            skeletons.push(
            <div
                key={i}
                className={`skeleton_each_topbar_search`}
                style={{}}
            >
                <div className="skeleton_search_topbar_details" style={{display: 'flex', flexDirection: 'row'}}>
                    <Skeleton variant="rect" width={390} height={80} marginTop={'10px'}/>
                </div>
            </div>
            );
        }
        return skeletons;
    };

    return (
        <>
        <div className="header-search-wrapper hide-on-med-and-down global__search__box__wr">
            <GlobalSearchBox dataTarget="top-search-result"
                className="" 
                type="search" 
                autocomplete="off"
                name="Search"
                placeholder="Search" 
                // id="searchInput"
                onChange={(e) => setSearchText(e.target.value.replace(/[#,%,$,!]/g, ''))}
                // onBlur={e => setMenuVisible(false)}
                onFocus={e => onFocusGlobalSearch(e)}
                value={searchText}
                ref={global_search_text_ref}
                id="global_search_text_ref_id"
            />
        </div>
        <div id="top-search-result" tabIndex="0" onClick={(e) => {e.stopPropagation(); setMenuVisible(true)}} onBlur={() => setMenuVisible(false)} 
            className={`top-bar-search-result top-bar-search-wrapper dropdown-content dropdown-tabbed ${ menuVisible? 'global___search___panel' : ''}`} style={{opacity: menuVisible ? 1: 0, visibility: menuVisible ? 'visible': 'hidden'}}>
            <div id="top-search-result-off">
            {(props.from === undefined || props.from !== 'activity-modal') &&

                <div className="search-tab-top-content d-flex align-items-center">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                            fill="#133159"/>
                    </svg>
                    <p>Search all results for “{searchText}”</p>
                </div>
                }
                {(props.from === undefined || props.from !== 'activity-modal') &&

                <div className="tabs-title">
                    <AppBar position="static" color="default">
                        <Tabs value={searchTabValue}
                            onChange={(event, newValue) => {setSearchTabValue(newValue);}}
                            indicatorColor="primary"
                            textColor="primary"
                            ariaLabel="simple tabs example">
                            <Tab label={"People"}/>
                            {/* {(props.from === undefined || props.from !== 'activity-modal') &&
                            <Tab label={"Note"}/>

                            } */}
                        </Tabs>
                    </AppBar>
                </div>
                }
                <TabPanel className="search-result-tab p-0" id="5peoples" value={searchTabValue} index={0}>
                    <div className="search-list-content" onScroll={(e)=>loadMoreContact(e)}>
                    {(props.from === undefined || props.from !== 'activity-modal') && !loadingNewData && process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic' &&

                        renderElasticContacts()
                    }
                    {(props.from === undefined || props.from !== 'activity-modal') && !loadingNewData && process.env.REACT_APP_DIALER_CONTACT_SEARCH !== 'elastic' &&

                        renderContacts()
                    }
                    {(props.from !== undefined || props.from === 'activity-modal' ) &&

                        renderContactsForActivityModal()
                    }
                    {(props.from === undefined || props.from !== 'activity-modal') && loadingNewData &&
                        loadingSkeleton()
                    }
                    </div>
                </TabPanel>
                {/* {(props.from === undefined || props.from !== 'activity-modal') &&
                <TabPanel className="search-result-tab p-0" id="3notes" value={searchTabValue} index={1}>
                    <div className="search-list-content">
                        {renderNotes()}
                    </div>
                </TabPanel>
                } */}
            </div>
        </div>
        </>
    );
}
 
export default SearchMenu;