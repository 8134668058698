import React from "react";
import VoiceSend from "../../SendModules/voice";
import ModalGlobalModal from "../ModalGlobalModal";

const VoiceSendModal = (props) => {
    return(
        <ModalGlobalModal
            open={props.open}
            title="Send Voice Mail"
            onClose={() => props.onClose(false)}
            width={800}
            hideFooter={true}
            className="global_modal_overflow_hidden activity__modal_overflow"
        >
            <VoiceSend
                showTimeSetting = {true}

                showSelectedContact= {false}
                selectedContactItem= {(<div>contact information</div>)}

                contact_id={props.data.id}
                contact_data={props.data}
                onClose={() => props.onClose(false)}
                onSubmit={()=>props.onSubmit!== undefined ? props.onSubmit() : console.log("submit") }
            />
        </ModalGlobalModal>
    )

}
export default VoiceSendModal;