

const Helper={

    getIconTextFromContact:(contact)=>{
        
        let firstName = (contact.first_name !== undefined && contact.first_name !== null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name !== undefined && contact.last_name !== null) ? contact.last_name.trim() : ''
        
        if(firstName !== '' && lastName !== ''){
            return (firstName.substring(0,1)+lastName.substring(0,1)).toUpperCase()
        }else if(firstName !== ''){
            if(firstName.includes(' ')){
                let data = firstName.replace(/  +/g, ' ')
                
                let arrayData = data.split(' ');
                if(arrayData[1] !== undefined){
                    return (arrayData[0].substring(0,1)+arrayData[1].substring(0,1)).toUpperCase()
                }
            }
            return firstName.substring(0,2).toUpperCase()
        }else if(lastName !== ''){
            let data = lastName.replace(/  +/g, ' ')
                
                let arrayData = data.split(' ');
                if(arrayData[1] !== undefined){
                    return (arrayData[0].substring(0,1)+arrayData[0].substring(0,1)).toUpperCase()
                }
            return lastName.substring(0,2).toUpperCase()
        }else {
            return 'N/A'
        }
    },

    isEmpty:(text)=>{
        if(text !== undefined && text!== null && text!== ''){
            return false
        }else{
            return true
        }
    },

    getContactName: (contact)=>{

        let firstName = contact.first_name ? contact.first_name.trim() : 
        contact.firstName ? contact.firstName.trim() : ''
        let lastName = contact.last_name ? contact.last_name.trim() : contact.lastName ? contact.lastName.trim() : '' 
        if(firstName ==='' && lastName ===''){

            if(!Helper.isEmpty(contact.number)){
                return contact.number
            }else if(!Helper.isEmpty(contact.email)){
                return contact.email
            }else{
                return null
            }
        }else{
            return (firstName+' '+lastName).trim()
        }

        
    },
    limitMessage: (message, limit=100)=>{

        if(message !== undefined && message !== null & message !== ''){

            return message.substring(0,limit)+'...'
        }else{
            return ''
        }


        
    }
}

export default Helper;