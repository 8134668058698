import React from "react";
import { Box,  DialogTitle, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles({
  root: {
    background: "#316aff",
    height: "60px",
    textAlign: "center",
    color: "#ffffff",
    fontWeight: 700,

  },
  dialog_box_header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
});

function CustomModal({  onClose, title, icon, value, children }) {

  const classes = useStyles();

  return (
    <Box>     
        <DialogTitle id='alert-dialog-title' className={classes.root}>
          <Box className={classes.dialog_box_header}>
            <Box sx={{ width: "4%", marginTop: "3px" }}>{icon}</Box>
            <Box sx={{ width: "90%", fontWeight: 700 }}>{title}</Box>
            <Box sx={{ width: "4%", marginTop: "3px", cursor: "pointer" }}>
              <CloseIcon onClick={onClose} />
            </Box>
          </Box>
        </DialogTitle>
        {children} 
    </Box>
  );
}

export default CustomModal;
