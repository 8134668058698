import FooterButton from "./FooterButton";

const LetterView = ({selectDesignStep, handleStepChange}) => {
    return (
        <div className='dmpc_later_tv_right'>
            <div className='dmpc_later_tv_right_inner'>
                {selectDesignStep.selectedTemplate != null &&
                <div className='dmpc_later_tv_right_inner_img' style={{background:`url('${selectDesignStep.selectedTemplate.image_path}')`}}>
                   <div className="dmpc_later_tv_right_inner_img_top_borer">
                   
                   </div>
                    <div className='dmpc__letter__select__address_block'>
                        <span>Address block</span>
                        <span>Keep clear</span>
                    </div>
                </div>
                }
                {selectDesignStep.selectedTemplate == null &&
                <div className='dmpc_later_tv_right_inner_img'>
                    <div className="dmpc_later_tv_right_inner_img_top_borer">
                    
                   </div>
                    <div className='gallery_prview_select_empty'>Select a template</div>
                    <div className='dmpc__letter__select__address_block'>
                        <span>Address block</span>
                        <span>Keep clear</span>
                    </div>
                </div>
                }
                <div className='dpmp_later_tv_footer_line_wr'>
                    <div className='dpmp_later_tv_footer_line_left'>
                        <span className='dpmp_later_tv_footer_line_left_border'></span>
                        <span>Safety Line</span>
                    </div>
                </div>

                <FooterButton 
                    selectDesignStep={selectDesignStep}
                    handleStepChange={handleStepChange}
                />
            </div>
        </div>
    );
}
export default LetterView;