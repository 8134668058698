const ActionType = {
    STORE_PROPS: 'STORE_PROPS',
    HANDLE_LOADING: 'HANDLE_LOADING',
    STORE_USER_INFO: 'STORE_USER_INFO',
    STORE_FROM_EMAILS_INFO: 'STORE_FROM_EMAILS_INFO',
    UPDATE_SELECTED_FROM_EMAIL: 'UPDATE_SELECTED_FROM_EMAIL',
    STORE_TO_EMAILS_INFO: 'STORE_TO_EMAILS_INFO',
    UPDATE_TO_EMAIL: 'UPDATE_TO_EMAIL',
    UPDATE_SAVE_AS_FLAG: 'UPDATE_SAVE_AS_FLAG',
    UPDATE_QUCIK_REPLY_SHOW_FLAG: 'UPDATE_QUCIK_REPLY_SHOW_FLAG',
    UPDATE_SUBJECT: 'UPDATE_SUBJECT',
    UPDATE_MESSAGE: 'UPDATE_MESSAGE',
    UPDATE_ATTACHMENT: 'UPDATE_ATTACHMENT',
    UPDATE_SENDING_FLAG: 'UPDATE_SENDING_FLAG',
    STORE_PRIMARY_EMAIL: 'STORE_PRIMARY_EMAIL',
    STORE_QUICK_REPLIES: 'STORE_QUICK_REPLIES',
    STORE_PERSONALIZED: 'STORE_PERSONALIZED',
    UPDATE_SAVE_AS_TITLE: 'UPDATE_SAVE_AS_TITLE',
    /* cc and bcc options */
    UPDATE_CC_BCC_FLAG: 'UPDATE_CC_BCC_FLAG',
    STORE_CC_MAIL_INFO: 'STORE_CC_MAIL_INFO',
    STORE_BCC_MAIL_INFO: 'STORE_BCC_MAIL_INFO',

    UPDATE_TO_EMAIL_SINGLE: 'UPDATE_TO_EMAIL_SINGLE'
}
export default ActionType;