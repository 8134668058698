//Site
export const FETCH_SITE_NOTIFICATION = 'FETCH_SITE_NOTIFICATION';
export const STORE_SITE_NOTIFICATION = 'STORE_SITE_NOTIFICATION';
export const RESTORE_SITE_NOTIFICATION = 'RESTORE_SITE_NOTIFICATION';
export const SCROLL_FETCH_SITE_NOTIFICATION = 'SCROLL_FETCH_SITE_NOTIFICATION';
export const APPEND_SITE_NOTIFICATION = 'APPEND_SITE_NOTIFICATION';
export const SET_ALL_MARK_AS_SEEN_SITE_NOTIFICATION = 'SET_ALL_MARK_AS_SEEN_SITE_NOTIFICATION';
export const UPDATE_ALL_SITE_NOTIFICATION_AS_SEEN = 'UPDATE_ALL_SITE_NOTIFICATION_AS_SEEN';
export const CLOSE_SITE_NOTIFICATION_REQUEST = 'CLOSE_SITE_NOTIFICATION_REQUEST';
export const CLOSE_SITE_NOTIFICATION = 'CLOSE_SITE_NOTIFICATION';
export const SET_SITE_NOTIFICATION_LOADED = 'SET_SITE_NOTIFICATION_LOADED';

//Lead
export const FETCH_LEAD_NOTIFICATION = 'FETCH_LEAD_NOTIFICATION';
export const STORE_LEAD_NOTIFICATION = 'STORE_LEAD_NOTIFICATION';
export const RESTORE_LEAD_NOTIFICATION = 'RESTORE_LEAD_NOTIFICATION';
export const SCROLL_FETCH_LEAD_NOTIFICATION = 'SCROLL_FETCH_LEAD_NOTIFICATION';
export const APPEND_LEAD_NOTIFICATION = 'APPEND_LEAD_NOTIFICATION';
export const SET_ALL_MARK_AS_SEEN_LEAD_NOTIFICATION = 'SET_ALL_MARK_AS_SEEN_LEAD_NOTIFICATION';
export const UPDATE_ALL_LEAD_NOTIFICATION_AS_SEEN = 'UPDATE_ALL_LEAD_NOTIFICATION_AS_SEEN';
export const CLOSE_LEAD_NOTIFICATION_REQUEST = 'CLOSE_LEAD_NOTIFICATION_REQUEST';
export const CLOSE_LEAD_NOTIFICATION = 'CLOSE_LEAD_NOTIFICATION';
export const SET_LEAD_NOTIFICATION_LOADED = 'SET_LEAD_NOTIFICATION_LOADED';

//Count
export const FETCH_NOTIFICATION_COUNT = 'FETCH_NOTIFICATION_COUNT';
export const INCREMENT_NOTIFICATION_COUNT = 'INCREMENT_NOTIFICATION_COUNT';
export const STORE_NOTIFICATION_COUNT = 'STORE_NOTIFICATION_COUNT';
