import { useEffect, useState } from 'react';
import AddOption from '../upload/AddOption';
import Header from '../upload/Header';
import Footer from '../upload/Footer';
import { uploadImageFileApi } from '../../../../api/ApiAction';
import CanvaZoneView from './CanvaZoneView';
import CanvaInfoModal from '../../../CanvaService/CanvaInfoModal';

const canvaApiKey = 'b39MbXGEYSY6mzq7Y87SPXcE';
const canvaStyle = {
    marginTop: '10px',
    padding: '30px 0',
    borderRadius: '16px',
    background: 'rgba(143,146,161,.05)',
    border: '2px dashed #e0e5f1'
}

const CanvaView = ({selectDesignStep, handleDataChange, handleStepChange, selectSizeStep, type}) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [isShowHintModal, setIsShowHintModal] = useState(false);
    const [canvaStates, setCanvaStates] = useState({
        canvaApi: "",
        has_init: false,
        isOpenCanvaStarModal: false,
        canvaImage: null
    });

    useEffect(() => {
        canvaInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* start canva process */

    const canvaInit = () => {
        let _this = canvaStates;
        if (!_this.has_init) {
            (function (document, url) {
                var script = document.createElement('script');
                script.src = url;
                script.onload = function () {
                    init();
                };
                document.body.appendChild(script);
            })(document, 'https://sdk.canva.com/designbutton/v2/api.js');
        } else {
            createCanvaDesign();
        }
    }

    const init = () => {
        let _this = canvaStates;
        (async function () {
            if (window.Canva && window.Canva.DesignButton) {
                if (!_this.has_init) {
                    const canvaApi = await window.Canva.DesignButton.initialize({
                        apiKey: canvaApiKey,
                    });

                    setCanvaStates({
                        ...canvaStates,
                        has_init: true,
                        canvaApi: canvaApi
                    })
                } else {
                    createCanvaDesign();
                }
            }
        })();
    }

    const createCanvaDesign = () => {
        /* 
        setting_type={cardMultiStepContext.messageType === LETTER ? 'letter' : 'postcard'}
        design_type={cardMultiStepContext.messageType === LETTER ? 'Letter' : 'Postcard'}
        design_width={cardMultiStepContext.messageType === LETTER ? 1275 : cardMultiStepContext.messageType === POSTCARD ? 1875 : 1800}
        design_height={cardMultiStepContext.messageType === LETTER ? 1875 : cardMultiStepContext.messageType === POSTCARD ? 1275 : 1313}
        callback={updateFile}
        uploadedFile={props.uploadedFile}
        */
        setIsShowHintModal(false)
        let info_data = getDesignTypeForCanva()
        canvaStates.canvaApi.createDesign({
            design: {
                type: info_data.design_type,
                dimensions: {
                    width: info_data.design_width,
                    height: info_data.design_height,
                    units: 'px'
                }
            },
            editor: {
                fileType: 'png'
            },
            onDesignOpen: ({ designId }) => {
                // Triggered when editor finishes loading and opens a new design.
                // You can save designId for future use.
            },
            onDesignPublish: ({ exportUrl, designId }) => {
                // Triggered when design is published to an image.
                // Save the image to your server as the exportUrl will expire shortly.
                // this.props.callback(exportUrl)
                // console.log("exportUrl", exportUrl)
                handleDataChange({from: 'canva', uploadUrl: exportUrl})
                setIsShowHintModal(false)
            },
            onDesignClose: () => {
                // Triggered when editor is closed.
            },
        });
    }

    const getDesignTypeForCanva = () => {
        let data = {
            setting_type: 'postcard',
            design_type: 'Postcard',
            design_width: '1875',
            design_height: '1275'
        }
        if(type === 'letter'){
            data['setting_type'] = 'letter'
            data['design_type'] = 'Letter'
            data['design_width'] = 1275
            data['design_height'] = 1875
        }
        return data;
    }
    /* end canva process */

    const handleSwitch = () => {
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{isTrue: !selectDesignStep.saveAsTemplate.isTrue}}
        handleDataChange({
            saveAsTemplate : old_data
        })
        setErrorMsg("")
    };

    const handleTitleChange = (event) => {
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{title: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleChangeCategory = (event) =>{
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{category: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleAddNewCategory = (event) => {
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{new_category_title: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleChangeOrientation = (event) =>{
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{orientation: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleBack = () => {
        if(selectDesignStep.uploadingFile){
            return
        }
        handleDataChange({from: 'template'})
    }

    const goForward = async () => {
        if(selectDesignStep.uploadingFile){
            return
        }
        if(selectDesignStep.uploadUrl !== ""){
            /* todo: check validation */
            if(selectDesignStep.saveAsTemplate.isTrue){
                if(selectDesignStep.saveAsTemplate.title.trim() === ""){
                    setErrorMsg("Please fill up template title !")
                    return
                }
                if(selectDesignStep.saveAsTemplate.category == null){
                    setErrorMsg("Please select a categoty !")
                    return
                }
                if(selectDesignStep.saveAsTemplate.category != null && selectDesignStep.saveAsTemplate.category === "add_new" && selectDesignStep.saveAsTemplate.new_category_title.trim() === ""){
                    setErrorMsg("Please fill up category title !")
                    return
                }
            }
            handleDataChange({uploadingFile: true})
            setErrorMsg("")
            let formData = new FormData();
            formData.append("is_saved", Boolean(selectDesignStep.saveAsTemplate.isTrue));
            formData.append("new_card_title", selectDesignStep.saveAsTemplate.title);
            formData.append("new_card_category_id", selectDesignStep.saveAsTemplate.category);
            formData.append("new_card_category_title", selectDesignStep.saveAsTemplate.new_category_title);
            
            formData.append("source_type", 2);
            formData.append("upload_file_data", selectDesignStep.uploadUrl);
            formData.append("card_type", type);
            if(type === 'postcard'){
                formData.append("card_size", selectSizeStep.selected_size);
            }
            else{
                formData.append("card_size", null);
            }

            if(type === 'zendirect_gift'){
                formData.append("new_card_orientation", 1);
            }
            else{
                formData.append("new_card_orientation", 1);
            }
            if(selectDesignStep.uploadUrl === ""){
                formData.append("info_update", true);
                formData.append("image_path", selectDesignStep.uploadUrl);
                formData.append("image_thumb_path", selectDesignStep.uploadThumbUrl);
            }

            await uploadImageFileApi(formData).then(res => {
                let response = res.data;
                if(response.status === "success"){
                    if(selectDesignStep.saveAsTemplate.isTrue){
                        handleDataChange({
                            uploadingFile: false,
                            uploadUrl: response.data.image_path,
                            uploadThumbUrl: response.data.image_thumb_path,
                            uploadData: null,
                            selectedCategory: response.data.category_id,
                            selectedTemplate: response.data.card_info,
                        })
                    }
                    else{
                        handleDataChange({
                            uploadingFile: false,
                            uploadUrl: response.data.image_path,
                            uploadThumbUrl: response.data.image_thumb_path,
                            uploadData: null
                        })
                    }
                    handleStepChange(true)
                }else{
                    window.showNotification('Error', response.message);
                    handleDataChange({uploadingFile: false})
                }
            }).catch(error => {
                handleDataChange({uploadingFile: false})
            })
        }
        else{
            setErrorMsg("Please upload your valid image file !")
            return
        }
    }

    const renderCustomDropProps = () => { 
        if(type === 'postcard'){
            return {
                ratio: {width: 1875, height: 1275},
                ratioMessage: 'Image file width & height ratio should be 1875 X 1275',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1875 X 1275).'
            };
        }
        else if(type === 'letter'){
            return {
                ratio: {width: 2550, height: 3300},
                ratioMessage: 'Image file width & height ratio should be 2550 X 3300',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (2550 X 3300).'
            };
        }
        else if(type === 'notecard'){
            return {
                ratio: {width: 1800, height: 1313},
                ratioMessage: 'Image file width & height ratio should be 1800 X 1313',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1800 X 1313).'
            };
        }
        else if(type === 'zendirect_gift'){
            if(selectDesignStep.saveAsTemplate.orientation === 1){
                return {
                    ratio: {width: 522, height: 378},
                    ratioMessage: 'Image file width & height ratio should be 522 X 378',
                    hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (522 X 378).'
                };
            }
            else if(selectDesignStep.saveAsTemplate.orientation === 2){
                return {
                    ratio: {width: 378, height: 522},
                    ratioMessage: 'Image file width & height ratio should be 378 X 522',
                    hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (378 X 522).'
                };
            }
            return {
                ratio: {width: 1500, height: 1500},
                ratioMessage: 'Image file width & height ratio should be 1500 X 1500',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1500 X 1500).'
            };
        }
        return {
            ratio: {width: 1875, height: 1275},
            ratioMessage: 'Image file width & height ratio should be 1875 X 1275',
            hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1875 X 1275).'
        };
    }

    return (
        <div className='dmpc_templete_upload'>
            <Header 
                flag={selectDesignStep.saveAsTemplate.isTrue}
                onChange={handleSwitch}
            />

            {selectDesignStep.saveAsTemplate.isTrue &&
                <AddOption 
                    selectDesignStep={selectDesignStep}
                    handleTitleChange={handleTitleChange}
                    handleChangeCategory={handleChangeCategory}
                    handleAddNewCategory={handleAddNewCategory}
                    type={type}
                    handleChangeOrientation={handleChangeOrientation}
                    handleDataChange={handleDataChange}
                />
            }

            {errorMsg !== "" &&
            <div style={{color: '#FF264A', fontSize: '14px', marginTop: '10px'}}>{errorMsg}</div>
            }

            <CanvaZoneView 
                canvaIntegModalHandler={() => setIsShowHintModal(true)}
                hintsText={renderCustomDropProps().hints}
                showHints={false}
                uploadedFile={selectDesignStep.uploadUrl}
            />

            <Footer 
                handleBack={handleBack}
                selectDesignStep={selectDesignStep}
                goForward={goForward}
            />

            {isShowHintModal &&
            <CanvaInfoModal 
                open={isShowHintModal}
                onClose={(value) => setIsShowHintModal(value)}
                onClick={createCanvaDesign}
            />
            }

        </div>
    );
}
export default CanvaView;