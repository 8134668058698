import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

const hours = [
    0,1,2,3,4,5,6,7,8,9,10,11,12
];

const minutes = [
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,35,40,45,50,55
];

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 350,
        width: 200
    }
}));

const LeterSameDay = ({readyToSend, handleReadyToSendChange}) => {
    const classes = useStyles();

    const renderHour = () => {
        let view = [];
        hours.forEach((item, index) => {
            view.push(
                <MenuItem key={index} value={item} className={'dropdownhelper-menuitem-class'}>
                    {item}
                </MenuItem>
            );
        });
        return view;
    }

    const renderMinutes = () => {
        let view = [];
        minutes.forEach((item, index) => {
            view.push(
                <MenuItem key={index} value={item} className={'dropdownhelper-menuitem-class'}>
                    {item}
                </MenuItem>
            );
        });
        return view;
    }

    return(
        <div className='dmpc_4th_tab_day'>
            <div className='dmpc_4th_tab_day_inner'>
                <div className='dmpc_4th_tab_day_hour'>
                    <div className='dmpc_4th_tab_day_hour_inner'>
                        <FormControl className="day_select_formControll day_select_formControll_97">
                            <Select
                                value={readyToSend.hours} 
                                IconComponent={ExpandMoreIcon}
                                onChange={(e) => handleReadyToSendChange({hours: e.target.value})}
                                className={"day_select_sf"}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={null} className='dropdownhelper-menuitem-class' disabled >Hour</MenuItem>
                                {renderHour()}
                            </Select>
                        </FormControl>
                        <span className='dmpc_4th_tab_day_hour_title'>Hours</span>
                    </div>
                </div>

                <div className='dmpc_4th_tab_day_hour'>
                    <div className='dmpc_4th_tab_day_hour_inner'>
                        <FormControl className="day_select_formControll day_select_formControll_97">
                            <Select
                                IconComponent={ExpandMoreIcon}
                                value={readyToSend.minutes} 
                                onChange={(e) => handleReadyToSendChange({minutes: e.target.value})}
                                className={"day_select_sf"}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.menuPaper}
                                }}
                            >
                                <MenuItem value={null} className='dropdownhelper-menuitem-class' disabled >Minute</MenuItem>
                                {renderMinutes()}
                            </Select>
                        </FormControl>
                        <span className='dmpc_4th_tab_day_hour_title'>Minutes later</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LeterSameDay;