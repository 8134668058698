const ActionType = {
    STORE_PROPS: 'STORE_PROPS',
    HANDLE_LOADING: 'HANDLE_LOADING',
    STORE_VIRTUAL_NUMBERS: 'STORE_VIRTUAL_NUMBERS',
    UPDATE_SELECTED_VN: 'UPDATE_SELECTED_VN',
    UPDATE_FILE_TYPE: 'UPDATE_FILE_TYPE', //RECORD, UPLOAD, TEMPLATE
    UPDATE_TEMPLATE_ID: 'UPDATE_TEMPLATE_ID',
    UPDATE_IS_SHOW_TEMPLATE: 'UPDATE_IS_SHOW_TEMPLATE',
    STORE_TEMPLATES: 'STORE_TEMPLATES',
    UPDATE_SENDING_FLAG: 'UPDATE_SENDING_FLAG',
    UPDATE_FILE_DATA: 'UPDATE_FILE_DATA',
    UPDATE_SAVE_AS_TEMPLATE_FLAG: 'UPDATE_SAVE_AS_TEMPLATE_FLAG',
    UPDATE_TITLE: 'UPDATE_TITLE',
    UPDATE_SELECTED_TO_NUM: 'UPDATE_SELECTED_TO_NUM'
}
export default ActionType;