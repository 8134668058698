import React, { useEffect, useState, useContext } from "react";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { EachTag } from "./EachTag";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import ApiList from "../../ApiList";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkFile from "../../LinkFile";
import Style from './tags.module.css'

const Tags = (props) => {
    const isMounted = LinkFile.isMounted()
    const {detailStates} = useContext(ContactDetailContext)
    const [assignTags, setAssignTags] = useState([])
    const [expand, setExpand] = useState(false)
    const [expandAddMore, setExpandAddMore] = useState(false)
    const [allTags, setAllTags] = useState([])
    const [search, setSearch] = useState('')
    const [filterTags, setFilterTags] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [submit, setSubmit] = useState(false)
    const [render, setRender] = useState(false)
    const [fetchContactTags, setFetchContactTags] = useState(false)

    useEffect(() => {
        return () => setFetchContactTags(false)
    }, [])

    useEffect(() => {
        if(expand) getContactTags()
        setExpandAddMore(false)
    }, [expand])

    LinkFile.useDelayCallback(() => {
        if(isMounted){
            fetchAllTagsSearch()
        }
    }, [search])

    const getContactTags = (isChangeLoadingFlag = true) => {
        if(fetchContactTags)return;
        if(isChangeLoadingFlag){
            setFetchContactTags(true)
        }
        ApiList.getContactTags({
            contactId: detailStates.contactId
        }).then(res => {
            let response = res.data
            if(response.success){
                setAssignTags(response.data)
            }
            setFetchContactTags(false)
        })
    }

    const fetchAllTags = (onlyFetch = false) => {
        if(!expandAddMore){
            setLoading(true)
            ApiList.getAllTags({text: search}).then(res => {
                let response = res.data
                setAllTags(response)
                setLoading(false)
            })
        }
        if(onlyFetch){
            setExpandAddMore(!expandAddMore)
            setSelectedTags([])
        }
    }
    const fetchAllTagsSearch = () => {
        setLoading(true)
        ApiList.getAllTags({text: search}).then(res => {
            let response = res.data
            setAllTags(response)
            setLoading(false)
        })
    }

    const removeExistingTag = (id, index) => {
        ApiList.removeTagFromContact({
            contact_id: detailStates.contactId,
            tag_id: id
        }).then(res => {
            let response = res.data
            let tags = assignTags
            tags.splice(index, 1)
            setAssignTags(tags)
            setRender(!render)
            if(detailStates.from === 'deal-details'){
                if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                    detailStates.dealCallback(true)
                    // props.reloadDealStage(true)
                }
            }
        })
    }

    const handleSearch = (event) => {
        // let query = event.target.value
        // query = query.toLowerCase()
        // let filterList = allTags.filter(item => {
        //     let text = item.name.toLowerCase()
        //     if(text.includes(query)) return true
        //     return false
        // })
        setSearch(event.target.value)
        // setFilterTags(filterList)

        setLoading(true)
    }

    const renderAvailableTags = () => {
        let tagList = allTags;
        let tagsToShow = [];
        if(search != ''){
            // tagList = filterTags
        }
        if(tagList.length == 0){
            return <span className="g-no-tag-found">No tags created {search == '' ? '.' : 'with '+search.toUpperCase()}</span>
        }
        tagList.map((item, index) => {
            tagsToShow.push(
                <EachTag item={item} selectedTags={selectedTags} onCheckChange={handleCheckChange} key={index} />
            )
        })
        return tagsToShow;
    }

    const handleCheckChange = (id) => {
        let newSelectItem = selectedTags
        let itemIndex = selectedTags.indexOf(id)
        if(itemIndex >= 0){
            newSelectItem.splice(itemIndex, 1)
        }
        else{
            newSelectItem.push(id)
        }
        setSelectedTags(newSelectItem)
        setRender(!render)
    }

    const handleCreateAndAssign = () => {
        if(submit || search == ''){
            return
        }
        ApiList.addTagToContact({
            assign: false,
            contact_id: [detailStates.contactId],
            new: true,
            tags: search
        }).then(res => {
            getContactTags(false)
            if(detailStates.from === 'deal-details'){
                if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                    detailStates.dealCallback(true)
                    // props.reloadDealStage(true)
                }
            }
            setSelectedTags([])
            fetchAllTags(true)
            setSearch('')
        })
    }

    const handleSubmit = () => {
        if(submit || selectedTags.length == 0){
            return
        }
        setSubmit(true)
        ApiList.addTagToContact({
            assign: true,
            contact_id: [detailStates.contactId],
            new: false,
            tags: selectedTags
        }).then(res => {
            getContactTags(false)
            if(detailStates.from === 'deal-details'){
                if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                    detailStates.dealCallback(true)
                    // props.reloadDealStage(true)
                }
            }
            setSubmit(false)
            setSelectedTags([])
        })
    }

    /* render assign tags */
    const renderTags = () => {
        let assign_tags = []
        assignTags.map((tag, index) => {
            assign_tags.push(
                <button>
                    {tag?.tag?.name}
                    <span onClick={() => removeExistingTag(tag.tag_id, index)}>&times;</span>
                </button>
            )
        })
        return assign_tags;
    }

    return(
        <div className="g-more-info-right g-more-info-column">
            <CustomAccordion expand={expand}  className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span className="g-header-title"><LocalOfferIcon /> Tags </span>
                    <span className="g-custom-caret">{icons.caretIcon}</span>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>

                    {fetchContactTags ? 
                        <div className="g-empty-content">loading...</div>
                    :
                        <div className="g-contact-tags">
                            {assignTags.length == 0 ?
                                <span className="g-empty-content-tags">No tag assign for this contact</span>    
                            :
                                renderTags()
                            }
                        </div>
                    }

                    <div className="g-add-tag-content">
                        <div className="g-add-more-tag-button" onClick={fetchAllTags}>
                            <span>+ Add Tags</span>
                            <span>{expandAddMore ? <ArrowDropUpIcon fontSize={'large'}/> : <ArrowDropDownIcon fontSize={'large'}/> }</span>
                        </div>

                        {(expandAddMore && loading) &&
                            <div className="g-loader-content">Loading...</div>
                        }

                        {(expandAddMore /* && !loading */) &&
                        <div className="g-tag-type-search">
                            <label htmlFor="">Type to search or create tag</label>
                            <input className="g-tag-search" type={'text'} placeholder={"Search or Create ..."} value={search} onChange={handleSearch}/>
                            {search != '' &&
                                <span className="g-create-assign-text" onClick={handleCreateAndAssign}>
                                    Create and assign with this&nbsp;&nbsp;<strong> {search.toUpperCase()}</strong>
                                </span>
                            }
                            {!loading &&
                            <div className="awesome__scroll_bar g-all-tag-content">
                                {renderAvailableTags()}
                            </div>
                            }
                        </div>  
                        }
                    </div>
                    
                    {expandAddMore &&
                    <div className="g-assign-tag-button" onClick={handleSubmit}>
                        {submit ? <MoreHorizIcon /> : ''}
                        {selectedTags.length == 0 ? 'Select Tag' : ('Assign ' + (selectedTags.length == 1 ? 'Tag' : 'Tags')) }
                    </div>
                    }
                
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
export default Tags;

