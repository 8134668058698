
import axios from "axios";
import ContactHelper from "./Helper";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const marketingRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const inboxRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
// const apiKey = ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)

/* api for voice send module */
const getVirtualNumber= () => {
    return axios.request({
        method: 'POST',
        url: rootUrl+'/get-virtual-number-by-user',
        data: {
            'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)
        },
    });
}
/* get voice templates */
const getVoiceTemplates = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: marketingRootUrl+'/message-templates',
        data: data
    });
}
/* voice send */
const submitVoiceSend = (params) => {
    params.append('api-key', ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    return axios.request({
        method: 'POST',
        url: inboxRootUrl+'/make-conversation',
        data: params
    });
}
/* get contact details by id with last connected */
const getContactLastConnectedVn = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl+'/get-contact-last-connected-vn',
        data: data
    });
}

const sendSmsFromMap = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    if(formData){
        params.append('api-key', ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl + '/make-conversation-from-map',
        data: formData ? params : data
    });
}

const ApiList = {
    getVirtualNumber,
    getVoiceTemplates,
    submitVoiceSend,
    getContactLastConnectedVn,
    sendSmsFromMap
}
export default ApiList;