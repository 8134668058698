import React from 'react';

const VarifiedOkIcon = ({ height="16", width="16" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 512 429.76" fill="#3B8BD7">
        <path d="M96.3 0h242.43c-15.45 14.62-30.92 29.65-46 44.92l-.78.79H96.3c-13.9 0-26.55 5.7-35.72 14.87-9.17 9.17-14.87 21.82-14.87 35.71v237.17c0 27.86 22.73 50.59 50.59 50.59h319.4c27.7 0 50.59-22.89 50.59-50.59V100.71c12.47-14.55 25-28.94 37.48-43.14A94.985 94.985 0 0 1 512 96.29v237.17c0 52.91-43.39 96.3-96.3 96.3H96.3c-52.84 0-96.3-43.47-96.3-96.3V96.29C0 69.8 10.83 45.71 28.27 28.27 45.71 10.83 69.8 0 96.3 0zm92.81 146.72c22.15 12.77 36.58 23.38 53.76 42.31 44.55-71.71 94.83-113.36 157.71-169.76l6.15-2.37h68.8C383.28 119.36 314.7 201.19 245.77 324.73c-41.43-69.82-69.31-114.77-129.55-161.54l72.89-16.47z"/>
    </svg>
      );
};

export default VarifiedOkIcon;

// export const VerifiedOkIcon = ({ height="16", width="16" }) => (
//     <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
//         <path fill="green" d="M74.7,7.8c-11.2,2-24.4,7.7-33,14.2C25.5,34.3,14.5,52.2,11.1,72c-1.5,8.2-1.4,104,0,112.2c5.7,32.4,31.6,58.3,64,64c5.6,1,24.5,1.4,26.7,0.6c2.7-1.1,2.9-2.7,2.9-29.2c0-27.2-0.1-28.4-3.4-32.3c-3.6-4.4-5-4.7-23.3-4.7c-16,0-16.2,0-19.4-1.6c-2-1-3.9-2.6-5-4.3l-1.8-2.6V128V81.8l1.8-2.6c1.1-1.6,3-3.2,5-4.3c3.2-1.6,3.4-1.6,18.8-1.6c9.4,0,16.6-0.3,18.1-0.7c3.2-0.9,7.4-5.1,8.4-8.6c0.6-1.8,0.8-9.7,0.8-27.7c0-26.4-0.2-28.1-2.9-29.2C99.8,6.5,79.6,6.9,74.7,7.8z"/>
//         <path fill="green" d="M228.3,83.9c-1.3,0.6-10.1,8.8-21.9,20.4c-10.8,10.7-24.3,24-30,29.6l-10.3,10.1L152,130c-7.7-7.7-15.1-14.5-16.3-15.1c-4.3-2.2-11.6-0.9-14.7,2.6c-3.5,3.9-4.3,10.1-2,14.5c0.8,1.5,10.8,12,24,25.2l22.8,22.7l38.9-38.3c21.4-21.1,39.4-39.3,40.1-40.5c1.3-2.4,1.6-7.8,0.5-10.5c-0.9-2.4-4.3-5.8-6.7-6.9C236,82.7,231.1,82.7,228.3,83.9z"/>
//     </svg>
// );