import * as ACTION from "../constants/contactActionTypes";

const initialState = {
    tags : null
};

const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_CONTACT_TAG:
            return {...state,tags:action.payload}
        default:
            return state;
    }
}

export default tagReducer;
