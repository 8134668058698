import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function GiftImagePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div onMouseEnter={handlePopoverOpen}
    onMouseLeave={handlePopoverClose}>
      {props.children}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <img className='dsm__gift_full_image' src={props.imageUrl} alt="gift_full"/>
        <div style={{display: 'none'}}>
          {props.data?.gift_price !== undefined && 
          <span>Gift price: ${parseFloat(props.data.gift_price).toFixed(2)}</span>
          }

          {props.data?.gift_handling_fee !== undefined && 
          <span>Gift Handling price: ${parseFloat(props.data.gift_handling_fee).toFixed(2)}</span>
          }
          
          {props.data?.greeting_card_price !== undefined && 
          <span>Greeting card price: ${parseFloat(props.data.greeting_card_price).toFixed(2)}</span>
          }

          {props.data?.postage_fee !== undefined && 
          <span>Postage fee: ${parseFloat(props.data.postage_fee).toFixed(2)}</span>
          }
        </div>
      </Popover>
    </div>
  );
}