const NotecardImagePreview = ({
    image_url,
    selectedTemplate
}) => {
    return(
        <div className='dmpc__type_message__image_preview_container' style={{paddingTop: '0px'}}>
            <div className="dmpc_tmipc_image_preview" style={{height: '1028.11px', background:`url('${image_url}')`}}>
                <div className="dmpc_tmipc_ip_cut_zone"></div>
                <div className="dmpc_tmipc_ip_folding_zone"></div>
            </div>
        </div>
    );
}
export default NotecardImagePreview;