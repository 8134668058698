import Details from './details'
import {  ContactDetailsProvider } from './reducer/GlobalReduers'
import './css/g-contact-details.css'
import './css/g-contact-details-responsive.css'

const ContactDetailModule = (props) => {
    return(
        <ContactDetailsProvider>
            <Details 
                open={props.open}
                onClose={props.onClose}
                title={props.title}
                dealInfo={props.dealInfo || null}
                id={props.id}
                callbackOnOpen={props.callbackOnOpen}
                from={props.from}
                dealCallback={props.dealCallback}
                loggedOnUser={props.loggedOnUser}
            />
        </ContactDetailsProvider>
    )
}
export default ContactDetailModule;