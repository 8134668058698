import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import UserLayout from "../components/layouts/UserLayout";
import { checkCookie } from "../helpers/Cookie";
import Utils from "../helpers/Utils";
import Onboard from '../components/onboard/Onboard';
import { Profile, Dashboard } from '../components/micromodulecomponent/common';
import { ApmRoute } from '@elastic/apm-rum-react'
import { useLocation } from 'react-router-dom'
import { getUserSettingDataApi } from '../api/globalApi';

const UserLayoutRouter = ({ component: Component, fullScreen, ...rest }) => {
    const history = useHistory();
    let url = ''
    const location = useLocation();
    let address = location.pathname
    if (address.includes("user/claim-contact")) {
        url = "?redirect_url=" + address
    } 
    else if (address.includes("oauth2/authorize") && location.search !== undefined){
        url = "?redirect_url=" + address+location.search
    }
    else {
        url = '';
    }

    useEffect(() => {
        // GetAddressBarLocation();
        // getUserSettingData()
    }, [])

    // const GetAddressBarLocation = () => {

    // }

    const getUserSettingData = () => {

    }


    return (
        <ApmRoute {...rest} render={props => {
            // return <UserLayout>
            //           <Component {...props} />
            //  </UserLayout>;

            if (checkCookie(process.env.REACT_APP_ACCESS_TOKEN)) {
                if (!(Utils.getAccountData('starterPack') || Utils.getAccountData('starterProPack') || Utils.getAccountData('standardPack'))) {
                    // console.log('Component.name === Profile.name', Component.name === Profile.name);
                    if (Component.name === Profile.name) {
                        return (
                            <UserLayout getUserSettingData={getUserSettingData}>
                                <Component {...props} />
                            </UserLayout>
                        );
                    } else {
                        return <Redirect to="/user/profile/packages" />;
                    }
                }

                if (!Utils.getAccountData('AuthUserAgencyAccountStatus')) {
                    if (Component.name === Dashboard.name) {
                        return (
                            <UserLayout getUserSettingData={getUserSettingData}>
                                <Component {...props} />
                            </UserLayout>
                        );
                    } else {
                        return <Redirect to="/" />;
                    }
                }

                if (Utils.getAccountData('userOnboard')) {
                    if (Component.name === Onboard.name) {
                        return (
                            <UserLayout getUserSettingData={getUserSettingData}>
                                <Component {...props} />
                            </UserLayout>
                        );
                    }

                    const urlSearchParams = new URLSearchParams(window.location.search);
                    const { code } = Object.fromEntries(urlSearchParams.entries());

                    if (code) {
                        return <Redirect to={`/onboard?code=${code}`} />;
                    }

                    return <Redirect to="/onboard" />;
                } else {
                    if (Component.name === Onboard.name || (address === '/inbox' && Utils.getAccountData('isTemplateUser') === 1)) {
                        return <Redirect to="/" />;
                    }

                    return (
                        <UserLayout getUserSettingData={getUserSettingData} fullScreen={fullScreen}>
                            <Component {...props} />
                        </UserLayout>
                    );
                }
            } else {
                if (url !== "") {

                    let to = "/user/login" + url
                    return <Redirect to={to} />;
                }
                return <Redirect to="/user/login" />;
            }
            // return checkCookie(process.env.REACT_APP_ACCESS_TOKEN) ?
            // <UserLayout>
            //     <Component {...props} />
            // </UserLayout> : <Redirect to="/user/login" />;
        }} />
    )
};

export default UserLayoutRouter;