import * as ACTION from "./cardBroadcastActionTypes";

const initialState = {
    postcardCategoryList : null,
    greetingCardCategoryList: null,
    letterCategoryList: null,
    thanksIoGreetingCardCategoryList: null,
    giftCategoryList: null,
    storedPersonalizeFields: [],
    selectedCardSize: '',
    selectedHandWritingId: '',
    selectedTemplate: null,
    selectedCategoryId: '',
    categoryList: null,
    templateList: null,
    useThanksIo: true,
    stepNo: 1,
    cardSizes: [],
    loadingThirdPartyInfo: false,
    selectedGift: null,
    textEditorValue: '',
    createNewPostcardFrom: '',
    pdfLink: '',
    loadingPDF: true,
    showPrintDeliveryOptions: false,
    submittingDirectMail: false,
    uploadedFile: null,
    newTemplateTitle:'',
    newCategoryId: '',
    reloadTemplateList:false,
    newCategoryOrientation: 1,
    showPDF: false,
    tabValue: 1,
    hour: 0,
    min : 0,
    day : 2,
    time: '08:00',
    startDate : new Date(),
    greetingOption : 1,
    selectedThanksIOGiftPrice : '',
    directMailPrice : ''
};

const cardBroadcastReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST:
            return {...state,postcardCategoryList:action.payload}
        case ACTION.SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST:
            return {...state,greetingCardCategoryList:action.payload}
        case ACTION.SET_LETTER_CATEGORY_IN_CARD_BROADCAST:
            return {...state,letterCategoryList:action.payload} 
        case ACTION.SET_GIFT_CATEGORY_IN_CARD_BROADCAST:
            return {...state,giftCategoryList:action.payload} 
        case ACTION.SET_THANKSIOGREETINGCARD_CATEGORY_IN_CARD_BROADCAST:
            return {...state,thanksIoGreetingCardCategoryList:action.payload}             
        case ACTION.SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST:
            return {...state,storedPersonalizeFields:action.payload}
        case ACTION.SET_SELECTED_SIZE:
            return {...state,selectedCardSize:action.payload} 
        case ACTION.SET_SELECTED_Template:
            return {...state,selectedTemplate:action.payload}
        case ACTION.SET_SELECTED_CATEGORY_ID:
            return {...state,selectedCategoryId:action.payload}
        case ACTION.SET_CATEGORY_LIST:
            return {...state,categoryList:action.payload}
        case ACTION.SET_TEMPLATE_LIST:
            return {...state,templateList:action.payload}
        case ACTION.SET_USE_THANKISO:
            return {...state,useThanksIo:action.payload}
        case ACTION.SET_STEP_NO:
            return {...state,stepNo:action.payload}
        case ACTION.SET_HANDWRITING_ID:
            return {...state,selectedHandWritingId:action.payload}        
        case ACTION.SET_THANKSIO_GIFT_PRICE:
            return {...state,selectedThanksIOGiftPrice:action.payload}
        case ACTION.UPDATE_MULTIPLE_REDUCERS:
            return {...state,...action.payload}
        case ACTION.SET_DIRECT_MAIL_PRICE:
            return {...state,directMailPrice: action.payload}
        case ACTION.SET_INITIAL_STATES:
            return {...state,...initialState}
        default:
            return state;
    }
}

export default cardBroadcastReducer;
