import { useState } from "react";
import { fetchAccountData } from "../../api/loginApi";
import { deleteCookie, getCookie, setCookie } from "../../helpers/Cookie";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import Utils from "../../helpers/Utils";

const delay = (time) => (new Promise(resolve => setTimeout(resolve, time)))
const LoginToParent = () => {

    const [isLoading, setIsLoading] = useState(false);

    const handleLoginToParent = async (e) => {
        e.preventDefault();

        if(isLoading) return;
        let userId= Utils.getAccountData('userId');

        //Managing cookies
        let parentKey = getCookie(process.env.REACT_APP_ACCESS_PARENT_TOKEN);
        deleteCookie(process.env.REACT_APP_ACCESS_PARENT_TOKEN);
        deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
        setCookie(process.env.REACT_APP_ACCESS_TOKEN, parentKey, process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
        if(localStorage.getItem("global__custom__field__data")){
            localStorage.removeItem("global__custom__field__data");
        }

        setIsLoading(true);
        await delay(300);

        //Fetching the account data
        fetchAccountData().then(async response => {

            if (response.data.status) {
                setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.data.data), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                // await delay(300);
                // document.location.href="/user/profile/team";
                const channel = new BroadcastChannel('pypepro-channel');

                    // Send a message to other tabs
                channel.postMessage({"action" : "reload-page-"+userId,"link": "/user/profile/team"});
            } else {
                window.showNotification('ERROR','Opps! something went wrong. Please reload the page');
            } 
        });
    }

    return (
        (getCookie(process.env.REACT_APP_ACCESS_PARENT_TOKEN) != "" || isLoading) ?
        <li className="top-dial-icon  refresh">
            <BootstrapTooltip arrow title={isLoading ? "Please wait..." : "Login Back to your account"}>
                <a href="/" onClick={handleLoginToParent} className="top-bar-refresh">
                    <svg className={isLoading ? "icon_circular_rotating" : ""} width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path
                                d="M19.2897 2.75895C18.8599 2.71977 18.4799 3.03637 18.4407 3.46605L18.3372 4.60156C16.581 1.84352 13.5324 0 10 0C6.19997 0 2.8511 2.16883 1.18868 5.42398C0.99243 5.80824 1.14485 6.27883 1.52911 6.47508C1.91345 6.67133 2.38395 6.51895 2.5802 6.13465C3.98712 3.3798 6.81239 1.5625 10 1.5625C12.9054 1.5625 15.5273 3.07543 17.0294 5.44563L15.9155 4.65004C15.5643 4.3993 15.0764 4.48063 14.8257 4.83172C14.5749 5.18285 14.6563 5.67074 15.0074 5.92152L18.3853 8.33406C18.8804 8.68699 19.5632 8.36391 19.6174 7.76926L19.9967 3.60793C20.0359 3.1782 19.7193 2.79813 19.2897 2.75895Z"
                                fill="white"/>
                            <path
                                d="M18.4709 13.5255C18.0866 13.3293 17.616 13.4817 17.4198 13.866C16.0128 16.6208 13.1876 18.4381 9.99996 18.4381C7.09461 18.4381 4.47265 16.9252 2.97054 14.555L4.08449 15.3506C4.43562 15.6013 4.92351 15.52 5.17429 15.1689C5.42503 14.8178 5.34371 14.3299 4.99261 14.0791L1.61468 11.6665C1.12566 11.3172 0.437301 11.6306 0.382614 12.2313L0.00327764 16.3927C-0.035902 16.8224 0.2807 17.2024 0.710348 17.2416C1.14058 17.2807 1.52015 16.9638 1.55929 16.5345L1.66281 15.399C3.41898 18.1571 6.46757 20.0006 9.99996 20.0006C13.8 20.0006 17.1489 17.8318 18.8113 14.5766C19.0075 14.1924 18.8551 13.7218 18.4709 13.5255Z"
                                fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </BootstrapTooltip>
        </li> : ''
    );
}
 
export default LoginToParent;