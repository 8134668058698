import React from "react";
import { Button } from "@material-ui/core";
import { RightSideStyle } from "../../RightSideStyle";

const PrimaryRoundedButton = ({
  size = "small",
  startIcon,
  fullWidth = false,
  disableElevation = true,
  onClick,
  children,
}) => {
  const { primaryButtonRounded } = RightSideStyle();

  return (
    <Button
      variant='contained'
      color='primary'
      size={size}
      className={primaryButtonRounded}
      startIcon={startIcon}
      fullWidth={fullWidth}
      disableElevation={disableElevation}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PrimaryRoundedButton;
