import Item from "./pages/Item";
import './css/global-quick-reply-card.css';

const QucikReplyCard = (props) => {
    return (
        <Item 
            data={props.data}
            key={props.key}
            onSelect={props.onSelect}
            className={props.className !== undefined ? props.className : ''}
        />
    )
}
export default QucikReplyCard;