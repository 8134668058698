import React from "react";
import { DefaultIcons, ErrorIcons, InfoIcons, SuccessIcons, WarningIcons } from "./NotificationIcons";
import { renderToString } from "react-dom/server";
import {NotificationHtmlTemplate} from "../../helpers/Utils";

export const PublicLayout = ({ children }) => {

  window.showNotification = (type = 'INFO', message = 'Information', des="", closeText='Dismiss') => {
    /* 'SUCCESS' //ERROR, WARNING, INFO */
    let icon = <InfoIcons />, classText="info";
    type = type.toUpperCase();
    if(type === 'SUCCESS'){
      icon = <SuccessIcons />
      classText="success"
    }
    else if(type === 'ERROR'){
      icon = <ErrorIcons />
      classText="error"
    }
    else if(type === 'WARNING'){
      icon = <WarningIcons />
      classText="warning"
    }else{
      icon = <DefaultIcons />
      classText="default"
    }
    NotificationHtmlTemplate({
      leftIcon: renderToString(icon),
      text: '',
      des: message,
      closeText: closeText,
      timer: 3000,
      classText:classText
    })
  }

  return (
      <div className="public_page_container">
        {children}
      </div>
  );
}