const PostcardHints = () => {
    return (
        <div className='dpmp_tv_footer_line_wr'>
            <div className='dpmp_tv_footer_line_left'>
                <span className='dpmp_tv_footer_line_left_border'></span>
                <span>Cut Line</span>
            </div>
            <div className='dpmp_tv_footer_line_left'>
                <span className='dpmp_tv_footer_line_right_border'></span>
                <span>Safety Zone</span>
            </div>
        </div>
    );
}
export default PostcardHints