import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading";
import './force_login.css'
import { loginSuccess } from "../../actions/loginAction";
import { deleteCookie, setCookie, setCookieLogin } from "../../helpers/Cookie";
import Api from "../../constants/Api";
import axios from "axios";

const ForceLogin = props => {
    const [state, setState] = useState({
        loading: true,
        userId: props.match.params.user_id,
        message: ''
    });

    useEffect(() => {
        if (state.userId != '') {

            axios.post(Api.forceLogin, { userId: state.userId, securityKey: btoa('agencyLoginCheck'), 'webaddress': window.location.hostname })
                .then(response => {

                    if (response.data.status) {
                        loginResponse(response.data);
                    } else {
                        setState({
                            ...state,
                            loading: false,
                            message: response.data.message
                        });
                    }
                })
                .catch((error) => {
                    setState({
                        ...state,
                        loading: false,
                        message: 'Oops! Something went wrong. Please try again'
                    });
                })
        } else {
            setState({
                ...state,
                loading: false,
                message: 'User not found. Please try again'
            });
        }
    }, []);

    const loginResponse = async (response) => {
        try {
            deleteCookie(process.env.REACT_APP_ACCESS_PARENT_TOKEN);
            setCookie(process.env.REACT_APP_ACCESS_TOKEN, response.api_key, process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            // setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            setCookieLogin(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            if (response.accountData.userId) {
                const channel = new BroadcastChannel('pypepro-channel');

                // Send a message to other tabs
                channel.postMessage({ "action": "reload-page-" + response.accountData.userId, "link": "RELOAD" });

            }
            props.loginSuccess(response.api_key);

        }
        catch (e) {
            console.log('cookie set error : ' + e);
        }
        setTimeout(function () { window.location.href = "/"; }, 3000);
    }

    return (
        <div className='force__login'>
            {state.loading ?
                <Loading />
                :
                <div>
                    <h2>{state.message}</h2>
                </div>
            }

        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        loginSuccess: (params) => dispatch(loginSuccess(params)),
    };
}

export default connect(null, mapDispatchToProps)(ForceLogin);