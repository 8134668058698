import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import '../../sharePages/sharePage.css';
import {DeviceHub} from "@material-ui/icons";
import {nylasAccessTokenConfig} from "../../../api/notificationApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Button} from "@material-ui/core";
import {clearQueryParams} from "./NylasConnectEmail";

const NylasActivatingPage = ({ handleClose, updateSuccessStatus }) => {
    const codeObj = useLocation().search;
    const code = new URLSearchParams(codeObj).get('code');
    const [dots, setDots] = useState('');
    const [message, setMessage] = useState('Wait while request is being verified');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCloseAble, setIsCloseAble] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (isProcessing){
            const intervalId = setInterval(() => {
                setDots(prevDots => prevDots.length < 3 ? prevDots + '.' : '');
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [isProcessing]);

    useEffect(() => {
        (async () => {
            setIsProcessing(true);
            const resp = await nylasAccessTokenConfig({ code: code });

            if (resp && resp.success) {
                setTimeout(() => {
                    setIsProcessing(false);
                    setIsCloseAble(true);
                    setIsError(false);
                    setMessage('🎉 Congratulations! Request verified successfully');

                    setTimeout(() => {
                        setMessage('Please wait while redirecting...');

                        setTimeout(() => {
                            updateSuccessStatus(true);
                            handleClose();
                            clearQueryParams();
                        }, 1000);
                    }, 2000);
                }, 3000);
            } else {
                setTimeout(() => {
                    setIsProcessing(false);
                    setIsCloseAble(true);
                    setIsError(true);
                    updateSuccessStatus(false);
                    setMessage('Sorry! Unable to verify the request. Please try again later!');
                }, 3000);
            }
        })();
    },[]);

    return ( 
        <div className="share_page__wrapper trigger_share" style={{ paddingTop: "130px" }}>
            <div className="share_page__inner_wrapper">
                <div className="nylas_connect">
                    <DeviceHub/>
                </div>
                <h4>Connecting Email</h4>
                {
                    <h5 className="mt-4">{message}
                        {
                            isProcessing ?
                            <span style={{position: 'absolute'}}>{dots}</span> :
                                ''
                        }
                    </h5>
                }
                {
                    !isCloseAble ?
                        <p>Please do not close the tab or change the page</p> :
                        isError ? <p>Close to tab and try again later</p> : ''
                }
                {
                    isCloseAble && isError ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="mr-2"
                            startIcon={<ArrowBackIcon />}
                            onClick={()=>{ handleClose(); clearQueryParams(); }}
                        >
                            Go back
                        </Button> : null
                }
            </div>
        </div>
    );
}

export default NylasActivatingPage;