import React, { useEffect, useState, useContext } from "react";
import SaveIcon from '@material-ui/icons/Save';
import { EachTag } from "./EachTag";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import ApiList from "../../ApiList";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LinkFile from "../../LinkFile";
import { Box } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Style from './tags.module.css'

const Tags = ({contactId, setTagsList,setOpenModal, handleTagSubmit}) => {
  const isMounted = LinkFile.isMounted();
  const { detailStates } = useContext(ContactDetailContext);
  const [assignTags, setAssignTags] = useState([]);
  const [expand, setExpand] = useState(false);
  const [expandAddMore, setExpandAddMore] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [fetchContactTags, setFetchContactTags] = useState(false);

  useEffect(() => {
    return () => setFetchContactTags(false);
  }, []);

  useEffect(() => {
    if (expand) getContactTags();
    setExpandAddMore(false);
  }, [expand]);

  useEffect(()=>{
    ApiList.getAllTags({ text: search }).then((res) => {
      let response = res.data;
      setAllTags(response);
      setLoading(false);
    });
  },[])

  LinkFile.useDelayCallback(() => {
    if (isMounted) {
      fetchAllTagsSearch();
    }
  }, [search]);

  const getContactTags = (isChangeLoadingFlag = true) => {
    if (fetchContactTags) return;
    if (isChangeLoadingFlag) {
      setFetchContactTags(true);
    }
    ApiList.getContactTags({
      contactId: contactId,
    }).then((res) => {
      let response = res.data;
      if (response.success) {
        setAssignTags(response.data);
      }
      setFetchContactTags(false);
    });
  };

  const fetchAllTags = (onlyFetch = false) => {
    // if (!expandAddMore) {
    //   setLoading(true);
     
    // }
    // if (onlyFetch) {
    //   setExpandAddMore(!expandAddMore);
    //   setSelectedTags([]);
    // }
  };
  const fetchAllTagsSearch = () => {
    setLoading(true);
    ApiList.getAllTags({ text: search }).then((res) => {
      let response = res.data;
      setAllTags(response);
      setLoading(false);
    });
  };

  const removeExistingTag = (id, index) => {
    ApiList.removeTagFromContact({
      contact_id: contactId,
      tag_id: id,
    }).then((res) => {
      let response = res.data;
      let tags = assignTags;
      tags.splice(index, 1);
      setAssignTags(tags);
      setRender(!render);
      if (detailStates.from === "deal-details") {
        if (
          detailStates.dealCallback !== undefined &&
          detailStates.dealCallback !== null
        ) {
          detailStates.dealCallback(true);
          // props.reloadDealStage(true)
        }
      }
    });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setLoading(true);
  };

  const renderAvailableTags = () => {
    let tagList = allTags;
    let tagsToShow = [];
    if (search != "") {
      // tagList = filterTags
    }
    if (allTags.length == 0) {
      return (
        <span className="g-no-tag-found" style={{marginTop:'16px'}}>
          No tags created {search == "" ? "." : "with " + search.toUpperCase()}
        </span>
      );
    }
    allTags.map((item, index) => {
      tagsToShow.push(
        <EachTag
          item={item}
          selectedTags={selectedTags}
          onCheckChange={handleCheckChange}
          key={index}
        />
      );
    });
    return tagsToShow;
  };

  const handleCheckChange = (id) => {
    let newSelectItem = selectedTags;
    let itemIndex = selectedTags.indexOf(id);
    if (itemIndex >= 0) {
      newSelectItem.splice(itemIndex, 1);
    } else {
      newSelectItem.push(id);
    }
    setSelectedTags(newSelectItem);
    setRender(!render);
  };

  const handleCreateAndAssign = () => {
    if (submit || search == "") {
      return;
    }
    ApiList.addTagToContact({
      assign: false,
      contact_id: [contactId],
      new: true,
      tags: search,
    }).then((res) => {
      getContactTags(false);
      handleTagSubmit();
      setSelectedTags([]);
      // fetchAllTags(true);
      setSearch("");
    });
  };

  const handleSubmit = () => {
    if (submit || selectedTags.length == 0) {
      return;
    }
    setSubmit(true);
    setTagsList(selectedTags)
    ApiList.addTagToContact({
      assign: true,
      contact_id: [contactId],
      new: false,
      tags: selectedTags,
    }).then((res) => {
      handleTagSubmit();
    });
  };

  /* render assign tags */
  const renderTags = () => {
    let assign_tags = [];
    assignTags.map((tag, index) => {
      assign_tags.push(
        <button>
          {tag?.tag?.name}
          <span onClick={() => removeExistingTag(tag.tag_id, index)}>
            &times;
          </span>
        </button>
      );
    });
    return assign_tags;
  };

  return (
    <Box className="mt-2">
      {fetchContactTags ? (
        <div className="g-empty-content">loading...</div>
      ) : (
        <div className="g-contact-tags">
          {/*{assignTags.length == 0 ? (*/}
          {/*  <span className="g-empty-content-tags">*/}
          {/*    /!*No tag assign for this contact*!/*/}
          {/*  </span>*/}
          {/*) : (*/}
          {/*  renderTags()*/}
          {/*)}*/}
        </div>
      )}

      <div className="g-add-tag-content">
        {expandAddMore && loading && (
          <div className="g-loader-content">Loading...</div>
        )}
          <div className="g-tag-type-search">
            <label htmlFor="">Type to search or create tag</label>
            <input
              className="g-tag-search"
              type={"text"}
              placeholder={"Search or Create ..."}
              value={search}
              style={{marginBottom:'16px'}}
              onChange={handleSearch}
            />
            {search != "" && (
                <button className={Style.create_new_tag} onClick={handleCreateAndAssign}>Create and assign with this <strong>{search.toUpperCase()}</strong></button>
              // <span
              //   className={Style.create_new_tag}
              //   onClick={handleCreateAndAssign}
              // >
              //   Create and assign with this&nbsp;&nbsp;
              //   <strong> {search.toUpperCase()}</strong>
              // </span>
            )}
            {!loading && (
              <div className="awesome__scroll_bar g-all-tag-content" style={{marginTop:'16px'}}>
                {renderAvailableTags()}
              </div>
            )}
          </div>
      </div>

      
        <Box position={'sticky'} bottom={0} display={"flex"} justifyContent={"center"} bgcolor={'white'}>
          {" "}
          <div className="g-assign-tag-button" onClick={handleSubmit}>
            <SaveIcon/>
            {submit ? <MoreHorizIcon /> : ""}
            {selectedTags.length == 0
              ? "Assign Tag"
              : "Assign " + (selectedTags.length == 1 ? "Tag" : "Tags")}
          </div>
          <div className="g-assign-close-button" onClick={()=>setOpenModal(false)}>
            <Close/>
        <p>Close</p>
          </div>
         
        </Box>
     
    </Box>
  );
};
export default Tags;
