import {useContext, useEffect, useState} from 'react';
import CustomSelect from '../../Voice/components/CustomSelect';
import { SmsSendContext } from "../reducers/GlobalReduers"
import { MenuItem } from "@material-ui/core";
import {updateSelectedToNum} from '../reducers/Action';
import Utils from '../../../../helpers/Utils';

const ToNumber = (props) => {
    const {states, dispatch} = useContext(SmsSendContext);
    const [allNumbers, setAllNumbers] = useState([]);

    const hanldeVirtualNumber = (value) => {
        dispatch(updateSelectedToNum(value))
    }

    useEffect(()=>{
        const numbers = [];

        if (states.contactInfo && states.contactInfo.number){
            numbers.push({
                label: Utils.formatPhoneNumber(states.contactInfo.number) + " (Primary Email)",
                value: -1
            });
        }

        if (states.contactNumbers && Array.isArray(states.contactNumbers) && states.contactNumbers[0]){
            numbers.push(...states.contactNumbers);
        }

        setAllNumbers(numbers);
    },[states]);


    return(
        <div className="sms__email__wrapper__alt">
            <div className="sms__email__wrapper__top">
                <h3 className="sms__email__wrapper__top__title">To Number: </h3>
                <div className="sms__email__wrapper__top__select">
                    <CustomSelect
                    formControlClass="sms__email__wrapper__top__select__innter"
                    value={states.selectedToNum}
                    handleChange={(event) => hanldeVirtualNumber(event.target.value)}
                    placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a contact number </MenuItem>)}
                    item={allNumbers}
                    titel_field={'label'}
                    value_field={'value'}
                    menuItemClass="dropdownhelper-menuitem-class"                
                />
                </div>
            </div>
            
        </div>
    )
}
export default ToNumber;