import GlobalModal from "../../GlobalModal/Modal/GlobalModal";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import QucikReplyCard from "../../QuickReplyCard";
import GlobalCustomFieldGrouped from "../../GlobalCustomFieldGrouped/Index";
const LinkFile = {
    'modal' : GlobalModal,
    'qucikReplyCard': QucikReplyCard,
    'BootstrapTooltip': BootstrapTooltip,
    'GlobalCustomFieldGrouped': GlobalCustomFieldGrouped,
}
export default LinkFile;