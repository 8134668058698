import React, { useEffect, useState, useContext } from "react";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CreateIcon from "@material-ui/icons/Create";
import Avatar from "@material-ui/core/Avatar";
import { Grid } from "@material-ui/core";
import ApiList from "../../ApiList";
import { List } from "./List";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BasicInput, BasicInputSelect } from "../../RightSide/RightSideStyle";
import { connect } from "react-redux";
import Utils from "../../../../helpers/Utils";
import DealConfirmAlert from "./custom-alert/new-alert/DealConfirmAlert";
import {
  changeDealStatus,
  fetchContactStages,
} from "../../../../api/contactApi";
import RightSideModalEdit from "./RightSideModalEdit";
import DealDrawer from "./DealDrawer";

const Deal = (props) => {
  const { detailStates } = useContext(ContactDetailContext);
  const [expand, setExpand] = useState(false);
  const [contactStages, setContactStages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState("1");
  const [selectedStorage, setSelectedStorage] = useState("1");
  const [formEntries, setFormEntries] = useState([]);
  const [editDeal, setEditDeal] = useState(0);
  const [activeTab, setActiveTab] = useState("open");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.contact?.id) {
      setLoading(true);
      ApiList.getContactDeals({
        id: props.contact.id,
      }).then((res) => {
        let response = res.data;
        setContactStages(response.deal);
        setLoading(false);
      });
    }
  }, [props.contact]);

  const toggleDrawer = () => {
    setState(!state);
  };
  const pipelines = [
    { id: 1, value: "1", label: "default pipeline" },
    { id: 2, value: "2", label: "maga pipeline" },
    { id: 3, value: "3", label: "apple pipeline" },
  ];

  useEffect(() => {
    refreshData("firstTime");
  }, [props.contact]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshData = (condition = "") => {
    fetchContactStages({ id: props.contactId })
      .then((res) => {
        if (res.data.status === "success") {
          setContactStages(res.data.data);
        } else {
          setContactStages([]);
        }
      })
      .catch((e) => {
        setContactStages([]);
      });
  };

  const dealMarkAsWinLose = (contactStageId, status) => {
    DealConfirmAlert({
      onSubmit: () => {
        changeDealStatus({
          stage_id: contactStageId,
          status: status,
        }).then((response) => {
          refreshData();
        });
      },
      title: `Please confirm your selection below:`,
      description: "",
      cancelText: "Cancel",
      symbol: status === 2 ? 1 : 0,
      submitText: status === 2 ? "Mark Deal As Won!" : "Mark Deal As Lost!",
    });
  };

  const populateDeals = () => {
    let filteredList = contactStages.filter((item, index) => {
      if (activeTab === "open" && item.status === 1) {
        return true;
      } else if (activeTab === "won" && item.status === 2) {
        return true;
      } else if (activeTab === "lost" && item.status === 3) {
        return true;
      } else {
        return null;
      }
    });
    if (filteredList.length > 0) {
    
      return filteredList.map((eachStage, index) => {
        return (
          <div
            className="deal_each_list_inbox_extra deal-each-list__inbox "
            key={`eachStage-${index}`}
          >
            <h6 className="text-gray cursor-pointer truncate i-deal-title-alt m-0">
              {" "}
              <b>Pipeline:</b>{" "}
              {eachStage.stage &&
              eachStage.stage.pipeline &&
              eachStage.stage.pipeline.title
                ? eachStage.stage.pipeline.title
                : "N/A"}
            </h6>
            <h6 className="text-gray cursor-pointer truncate i-deal-title-alt m-0">
              {" "}
              <b>Stage:</b>{" "}
              {eachStage.stage && eachStage.stage.stage
                ? eachStage.stage.stage
                : "N/A"}
            </h6>
            {/*<div className="d-flex justify-content-between align-items-center">*/}
            <div className="">
              <div
                className="i-deal-list-info__each"
                style={{ display: "flex" }}
              >
                <span className=" deal_each_list_inbox_extra_new text-gray cursor-pointer truncate i-deal-title-alt m-0">
                  {eachStage.title ? (
                    <span>
                      <b>Deal: </b>
                      {eachStage.title}
                    </span>
                  ) : (
                    "no title"
                  )}
                </span>
                <span className="i-deal-value__alt">
                  ({Utils.calculateCash(eachStage.deal_value)})
                </span>
              </div>

              <div
                className="deal-won-lost__inbox"
                style={{ display: "flex", gap: "10px" }}
              >
                {eachStage.status === 1 && (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                    className="deal_each_list_inbox_extra_edit  small-round-icon cursor-pointer " 
                    onClick={() => {
                      openRightSideEditModal(eachStage);
                    }}
                  >
                    {<CreateIcon fontSize="small" />}
                    <span>Edit</span>
                  </span>
                )}
                {eachStage.status !== 2 && (
                  <div
                    onClick={() => {
                      dealMarkAsWinLose(eachStage.id, 2);
                    }}
                    className="ml-1 px-2 d-flex justify-content-center align-items-center cursor-pointer each-deal-won-action-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <svg
                      className="deal_each_list_inbox_extra_svg"
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M.953 12.864h1.36a.953.953 0 0 0 .954-.951V5.66a.953.953 0 0 0-.953-.951H.953A.953.953 0 0 0 0 5.66v6.252c0 .524.427.951.953.951zM10.28 13a2.173 2.173 0 0 0 2.145-1.805l.61-3.533a2.174 2.174 0 0 0-2.146-2.544H8.32s.409-.816.409-2.175c0-1.63-1.225-2.174-1.77-2.174-.544 0-.816.272-.816 1.63 0 1.292-1.253 2.332-2.059 2.867V12c.872.403 2.617 1 5.325 1h.872z"
                        fill="#6FCF97"
                      />
                    </svg>
                    <span className="deal_each_list_inbox_extra_won">
                      {"WON"}
                    </span>
                  </div>
                )}
                {eachStage.status !== 3 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                    className="px-2 d-flex justify-content-center align-items-center cursor-pointer each-deal-lost-action-button"
                    onClick={() => {
                      dealMarkAsWinLose(eachStage.id, 3);
                    }}
                  >
                    <svg
                      className="deal_each_list_inbox_extra_svg"
                      viewBox="0 0 14 13"
                      width="14"
                      height="13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.114.136h-1.361a.953.953 0 0 0-.953.951V7.34c0 .524.427.951.953.951h1.36a.953.953 0 0 0 .954-.951V1.087a.953.953 0 0 0-.953-.951zM2.787 0A2.173 2.173 0 0 0 .642 1.805l-.61 3.533a2.174 2.174 0 0 0 2.145 2.544h2.57s-.408.816-.408 2.175c0 1.63 1.225 2.174 1.77 2.174.543 0 .816-.272.816-1.63 0-1.292 1.252-2.332 2.058-2.867V1C8.112.597 6.367 0 3.658 0h-.871z"
                        fill="#EB5757"
                      />
                    </svg>
                    <span className="deal_each_list_inbox_extra_won">
                      {"LOST"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="no-deal-div">
          <span>{`No ${activeTab} deal`}</span>
        </div>
      );
    }
  };

  const openRightSideEditModal = (deal) => {
    if (deal.status === 1) {
      let isTrue = false;
      if (deal.contact_id === Utils.getAccountData("userId")) {
        isTrue = true;
      } else if (Utils.getAccountData("userIsOwner")) {
        isTrue = true;
      } else if (Utils.getAccountData("userIsAdmin")) {
        isTrue = true;
      } else {
        if (props.collaborators.length > 0) {
          isTrue = props.collaborators.some(
            (item) => item.contact_id === Utils.getAccountData("userId")
          );
        }
      }

      if (isTrue) {
        setEditDeal(deal);
        setShowEditModal(true);
      } else {
        window.showNotification(
          "ERROR",
          "You can not add or update stage to other user lead"
        );
      }
    }
  };

  const openRightSideModal = () => {
    /* collaborator, team owner and account owner can */
    let isTrue = false;
    if (props.people.user_id === Utils.getAccountData("userId")) {
      isTrue = true;
    } else if (Utils.getAccountData("userIsOwner")) {
      isTrue = true;
    } else if (Utils.getAccountData("userIsAdmin")) {
      isTrue = true;
    } else {
      if (props.collaborators.length > 0) {
        isTrue = props.collaborators.some(
          (item) => item.user_id === Utils.getAccountData("userId")
        );
      }
    }

    if (isTrue) {
      setShowModal(true);
    } else {
      window.showNotification(
        "ERROR",
        "You can not add or update stage to other user lead"
      );
    }
  };

  return (
    <div className=" mt-2">
      <CustomAccordionHeader
        onClick={() => setExpand(!expand)}
        className={`g-collapsible-header ${expand ? "expand" : ""}`}
      >
        <span className="g-header-title">
          <AttachMoneyIcon /> Deals{" "}
          <span className="g-header-count">
            {contactStages != null ? contactStages.length : 0}
          </span>{" "}
        </span>
        <div className="g-header-action-content">
          <span className="g-add-task-button" onClick={toggleDrawer}>
            <AddCircleIcon />
          </span>
          <span className="g-custom-caret">{icons.caretIcon}</span>
        </div>
      </CustomAccordionHeader>
      <CustomAccordionBody className="g-collapsible-body" expand={expand}>
        <React.Fragment>
          {contactStages !== undefined &&
            contactStages != null &&
            contactStages.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="accordion__deal__expend d-flex justify-content-between mt-2">
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("open");
                      }}
                      class={`deals_btn_collapsible py-1 px-4  `}
                      style={{
                        backgroundColor:
                          `${activeTab}` === "open"
                            ? "var(--light_blue)"
                            : "var(--gray_dark)",
                        color: `${activeTab}` === "open" ? "white" : "black",
                        borderRadius: "6px",
                      }}
                    >
                      Open
                    </a>
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("won");
                      }}
                      class={`deals_btn_collapsible py-1 px-4 `}
                      style={{
                        backgroundColor:
                          `${activeTab}` === "won"
                            ? "var(--light_blue)"
                            : "var(--gray_dark)",
                        color: `${activeTab}` === "won" ? "white" : "black",
                        borderRadius: "6px",
                      }}
                    >
                      Won
                    </a>
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("lost");
                      }}
                      class={`deals_btn_collapsible py-1 px-4 `}
                      style={{
                        backgroundColor:
                          `${activeTab}` === "lost"
                            ? "var(--light_blue)"
                            : "var(--gray_dark)",
                        color: `${activeTab}` === "lost" ? "white" : "black",
                        borderRadius: "6px",
                      }}
                    >
                      Lost
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {contactStages != null && populateDeals()}
                </Grid>
              </Grid>
            )}
          {(contactStages === undefined ||
            contactStages == null ||
            contactStages.length === 0) && (
            <div className="task-list-wrapper">
              <div className="m-widget2 custom-list-container">
                No Deal Available
              </div>
            </div>
          )}
        </React.Fragment>
      </CustomAccordionBody>

      <DealDrawer
        state={state}
        setState={setState}
        selectedPipeline={selectedPipeline}
        pipelines={pipelines}
        setSelectedPipeline={setSelectedPipeline}
        selectedStorage={selectedStorage}
        setSelectedStorage={setSelectedStorage}
      />
      {showEditModal && (
        <RightSideModalEdit
          isOpen={showEditModal}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          url={"/user/update-contact-deal"}
          // toggle={toggleRightSideModalEdit}
          contact_id={props.contactId}
          // onModalFormSubmit={onModalFormSubmit}
          edit_deal={editDeal}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
  };
};

export default connect(mapStateToProps)(Deal);
