const tabArray =[
    {
        id:1,
        title:"Immediately",
    },
    {
        id:2,
        title:"Later the Same Day"
    },
    {
        id:3,
        title:"On a later Day",
    },
    {
        id:4,
        title:"Custom Date"
    },
    {
        id:5,
        title:"Greetings",
    },
    {
        id:6,
        title:"Recurring"
    }
]

const Steps = ({onClick, activeTab, contactInfo, processFor}) => {

    const renderClassName = (id) => {
        if(id === 5){
            if(processFor === 'campaign-setting'){
                return ""
            }
            if(contactInfo == null){
                return "dmpc__send_type_block__block";
            }
            else if(contactInfo.birth_date == null & contactInfo.anniversary_date == null){
                return "dmpc__send_type_block__block"
            }
            else if(contactInfo.birth_date === "" & contactInfo.anniversary_date === ""){
                return "dmpc__send_type_block__block"
            }
            else if(contactInfo.birth_date === "" & contactInfo.anniversary_date == null){
                return "dmpc__send_type_block__block"
            }
            else if(contactInfo.birth_date == null & contactInfo.anniversary_date === ""){
                return "dmpc__send_type_block__block"
            }
        }
        return ""
    }

    const renderTab = () => {
        let view = [];
        tabArray.forEach((item, index) => {
            if(item.id === 6 || item.id === 2 || item.id === 3 || item.id === 5){
                if(processFor === 'campaign-setting'){
                    view.push(
                        <div 
                            className={`dmpc_4th_mc_tab_single ${activeTab === index ? "dmpc_4th_mc_tab_active" : ''} ${renderClassName(item.id)}`} 
                            onClick={()=>onClick(item,index)}
                        >
                            <span>{item.title}</span>
                        </div>
                    );
                }
            }
            else{
                view.push(
                    <div 
                        className={`dmpc_4th_mc_tab_single ${activeTab === index ? "dmpc_4th_mc_tab_active" : ''} ${renderClassName(item.id)}`} 
                        onClick={()=>onClick(item,index)}
                    >
                        <span>{item.title}</span>
                    </div>
                );
            }  
        });
        return view;
    }

    return (
        <>
        <h4 className='dmpc_4th_mc_title'>Select when to send this followup</h4>
        <div className='dmpc_4th_mc_tab'>
            {renderTab()}
        </div>
        </>
    );
}
export default Steps;