import React, { useState, useContext } from "react";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button } from "@material-ui/core";
import NewConfirmAlert from "./NewConfirmAlert";
import { removeBulkContact } from "../../../../api/contactApi";

const Delete = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const removeContact = () => {
        NewConfirmAlert({
            title: 'Confirm to delete',
            description: 'Are you sure to delete contact?',
            cancelText: "Cancel",
            submitText: "Yes, Delete!",
            onSubmit: async () => {
                setDeleting(true);
                try{
                    let response = await removeBulkContact({ 'ids': [props.contact_id] });
                    if (response.data.success) {
                        window.showNotification('SUCCESS', 'Contact deleted successfully');
                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                    } else {
                        window.showNotification('ERROR', 'Something went wrong')
                    }
                }catch (err){
                    window.showNotification('ERROR', 'Something went wrong')
                }
                setDeleting(false);
            }
        })
    }

    return(
       <Box display={'flex'} justifyContent={'center'} mt={'10px'}>
        <Button onClick={ () => removeContact()} variant="contained" startIcon={<DeleteIcon style={{marginTop:'-3px'}}/>} style={{background:'red',color:'white',width:'100%'}}>Delete Contact</Button>
       </Box>
    )
}
export default Delete;