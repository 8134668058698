import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Popover } from '@material-ui/core';
import { HandwritingPopoverItems } from './HandwritingPopoverItems';
import './handwritingStyle.css';


export const HandwritingStyles = (props) => {

  const [selectedStyleText, setSelectedStyleText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    for (let i = 0; i < props.list.length; i++) {
      if(props.list[i].handwriting_style_id === props.selected) {
        setSelectedStyleText(props.list[i].name);
        break;
      }
    }
  },[props.list.toString(),props.selected]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChangeStyle = (value) => {
    setAnchorEl(null);
    props.onChangeStyle(value);
  }

  return (
    <div className='handwriting_comp_wrapper' onClick={e => {setAnchorEl(Boolean(anchorEl) ? null : e.currentTarget)}}>
      <div className='handwriting_comp_text'>
        {selectedStyleText}
      </div>
      <KeyboardArrowDownIcon/>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className="handwriting_comp_popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <HandwritingPopoverItems
          list={props.list}
          selected={props.selected}
          onChangeStyle={handleChangeStyle}/> 
      </Popover>
    </div>
  )
}
