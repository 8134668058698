import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Box, Chip } from "@material-ui/core";
import { RightSideStyle } from "../RightSideStyle";
import { getContactTagList, getUserTagList } from "../../../../actions/contactAction";
import {deleteContactTag} from "../../../../api/contactApi";
import NewConfirmAlert from "../../alert/NewConfirmAlert";

const TagsList = (props) => {
  const { flexCenter, primaryChip } = RightSideStyle();

  useEffect(()=>{
      props.fetchUserTagList({});
  },[]);

    const deleteTag = (tag) => {
        NewConfirmAlert({
            title: 'Confirm to remove',
            description: 'Do you want to remove this tag?',
            cancelText: "Cancel",
            submitText: "Yes, Remove!",
            onSubmit: async () => {
                deleteContactTag({
                    contact_id: props.contact.id,
                    tag_id: tag,
                }).then((res) => {
                    props.getContactTagList({
                        contactId: props.contact.id,
                    });
                });
            }
        })
    };

    let content = null;

    if (props?.contactTags)
        content = props.contactTags.map((tag) => (
            <Chip
                key={tag.id}
                variant='default'
                label={tag.tag.name}
                color='primary'
                className={primaryChip}
                size='small'
                onDelete={() => deleteTag(tag.tag.id)}
            />
        ));

    return (
        <Box className={flexCenter} sx={{ flexWrap: "wrap", gap: 10 }}>
            {content}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        contact: state.addContactReducer.contact,
        contactTags: state.tagReducer.tags,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContactTagList: (params) => dispatch(getContactTagList(params)),
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsList);
