import CustomCircleLoader from "../../../CustomCircleLoader";
import RightSideFooterButton from "./RightSideFooterButton";
import LetterHints from "./type-wise-right-side-preview/LetterHints";
import LetterImagePreview from "./type-wise-right-side-preview/LetterImagePreview";
import NotecardImagePreview from "./type-wise-right-side-preview/NotecardImagePreview";
import PdfPreview from "./type-wise-right-side-preview/PdfPreview";
import PostcardHints from "./type-wise-right-side-preview/PostcardHints";
import PostcardImagePreview from "./type-wise-right-side-preview/PostcardImagePreview";
import ZendireactGreetingProcessPreview from "./type-wise-right-side-preview/ZendireactGreetingProcessPreview";

const RightSidePreview = ({
    image_url,
    handleStepChange,
    handleDataChange,
    typeYourMessageSteps,
    handleForward,
    type,
    selectedTemplate,
    generatePdf
}) => {
    const renderPreviewHints = () => {
        if(type === 'postcard'){
            return <PostcardHints />
        }
        else if(type === 'letter'){
            return <LetterHints />;
        }
        return null;
    }
    const renderImagePreview = () => {
        if(type === 'postcard'){
            return <PostcardImagePreview image_url={image_url} selectedTemplate={selectedTemplate} />
        }
        else if(type === 'letter'){
            return <LetterImagePreview image_url={image_url} selectedTemplate={selectedTemplate} />;
        }
        else if(type === 'notecard'){
            return <NotecardImagePreview image_url={image_url} selectedTemplate={selectedTemplate} />;
        }
        else if(type === 'zendirect_gift'){
            return(
                <ZendireactGreetingProcessPreview 
                    image_url={image_url} 
                    selectedTemplate={selectedTemplate} 
                    typeYourMessageSteps={typeYourMessageSteps}
                    handleDataChange={handleDataChange}
                />
            );
        }
        return null;
    }
    const renderClassName = () =>{
        if(type === 'postcard'){
            return {
                'main': 'tmpp_postcard',
                'inner': 'tmpp__inner_postcard'
            };
        }
        else if(type === 'letter'){
            return {
                'main': 'tmpp_letter',
                'inner': 'tmpp__inner_letter'
            };
        }
        return {
            'main': 'tmpp_postcard',
            'inner': 'tmpp__inner_postcard'
        };
    }
    const classes = renderClassName();
    return (
        <div className={`dmpc_3rd_tv_right ${classes.main}`}>
            {typeYourMessageSteps.processingPreviewImage &&
            <div className='dmpc_3rd_tv_right_text_loader'>
                <CustomCircleLoader fill="#ea4335" />
            </div>
            }
            <div className={`dmpc_3rd_tv_right_inner ${classes.inner}`}>
                {!typeYourMessageSteps.isShowingPdf && renderImagePreview()}
                {typeYourMessageSteps.isShowingPdf && 
                    <PdfPreview 
                        typeYourMessageSteps={typeYourMessageSteps} 
                        type={type}
                    />
                }
                {renderPreviewHints()}
                <RightSideFooterButton 
                    handleForward={handleForward}
                    typeYourMessageSteps={typeYourMessageSteps}
                    handleDataChange={handleDataChange}
                    handleStepChange={handleStepChange}
                    generatePdf={generatePdf}
                />
            </div>
        </div>
    );
}
export default RightSidePreview;