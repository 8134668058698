import { Button, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import _Helper from "../_Helper";
import CustomSelect from "./CustomSelect";

import moment from 'moment'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';


const TimeSetting = props => {

    const [timeSetting, setTimeSetting] = useState({
        tab: '1', //1 = immediate, 2 = custom
        date: new Date(),
        time: '',
        hour: '',
        mins: ''
    })

    const handleTabClick = (value) => {
        setTimeSetting({
            ...timeSetting,
            tab: value
        })
        props.handleTimesetting('tab', value)
    }

    const handleDateChange = (value) => {
        setTimeSetting({
            ...timeSetting,
            date: value
        })
        props.handleTimesetting('date', value)
    }

    const timeChangeHandler = value => {
        let selectedTime = value.split(':')
        let hour = ('0' + selectedTime[0]).slice(-2);
        let timeMin = ('0' + selectedTime[1]).slice(-2);

        setTimeSetting({
            ...timeSetting,
            time: value,
            hour: hour,
            mins: timeMin
        })
        props.handleTimesetting('time', value)
    }

    return (
        <div className="global-send-module-time-setting ds__followup">
            <h3 className="trigger__modal_title m-0 pb-2">Select when to send this followup</h3>
            <div className="time-setting-tabs">
                <Button className={`${timeSetting.tab == '1' ? 'active accent--bg--color' : '' }`} variant={`${timeSetting.tab == '1' ? 'contained' : 'outlined'}`} onClick={() => handleTabClick('1')}>NOW</Button>
                <Button className={`${timeSetting.tab == '2' ? 'active accent--bg--color' : '' }`} variant={`${timeSetting.tab == '2' ? 'contained' : 'outlined'}`} onClick={() => handleTabClick('2')}>LATER</Button>
            </div>

            {timeSetting.tab == '1' &&
            <div className="ds__send_immediately">Message will send immediately</div>
            }

            {timeSetting.tab == '2' &&
            <div className="d-flex align-items-center ds__custom_date_wrapper">
                <div>
                    <h3 className="trigger__modal_title ds__alter">Date</h3>
                    <FormControl className="alt customFormControl border-1 height-48">
                        {/* <DatePicker 
                            placeholderText="MM/DD/YYYY"
                            minDate={new Date()} 
                            className="trigger__modal_textField dealValue setFullWidth" 
                            onChange={(date) => handleDateChange(date)} 
                            selected={timeSetting.date != null ? new Date(timeSetting.date) : null}
                        /> */}

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                                format="MM/dd/yyyy"
                                value={(timeSetting.date != null ) ? moment(new Date()).format("YYYY-MM-DD") : moment(new Date(timeSetting.date)).format("YYYY-MM-DD")}
                                onChange={(date) => handleDateChange(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="trigger__modal_textField dealValue setFullWidth" 
                            />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </div>

                <div className="ml-3 flex-1">
                    <h3 className="trigger__modal_title mt20pxSpaceTitle ds__alter">Time</h3>
                    <CustomSelect
                        // formControlClass={'alt customFormControl border-none'}
                        formControlClass={'alt customFormControl border-none'}
                        id='gloabal-sms-send-time-selector'
                        value={timeSetting.time}
                        handleChange={(event) => timeChangeHandler(event.target.value)}
                        placeholder={(<MenuItem value="" disabled>Select time</MenuItem>)}
                        item={_Helper.timeIntervalOptions(15)}
                        value_field={'label'}
                        titel_field={'label'}
                        customClass={'time-select'}
                    />
                    {props.isValidTime &&
                    <p>{props.timeValidationMessage}</p>
                    }
                </div>

            </div>
            }
        </div>
    )
}
export default TimeSetting;