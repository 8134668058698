import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const WRITING_STYLE = [
    // {
    //     value: 'Allura',
    //     label: 'Allura',
    //     class: 'pype_Allura_font_family'
    // },
    // {
    //     value: 'Lobster',
    //     label: 'Lobster',
    //     class: 'pype_Lobster_font_family'
    // },
    // {
    //     value: 'Freehand',
    //     label: 'Freehand',
    //     class: 'pype_Freehand_font_family'
    // },
    {
        value: 'Handlee',
        label: 'Handlee',
        class: 'pype_Handlee_font_family'
    },
    {
        value: 'Shrikhand',
        label: 'Shrikhand',
        class: 'pype_Shrikhand_font_family'
    },
    {
        value: 'Fasthand',
        label: 'Fasthand',
        class: 'pype_Fasthand_font_family'
    },
    {
        value: 'Gochi Hand',
        label: 'Gochi Hand',
        class: 'pype_Gochi_Hand_font_family'
    },
    {
        value: 'Bilbo Swash Caps',
        label: 'Bilbo Swash Caps',
        class: 'pype_Bilbo_Swash_Caps_font_family'
    },
    {
        value: 'Playfair Display',
        label: 'Playfair Display',
        class: 'pype_Playfair_Display_font_family'
    },
    {
        value: 'Edu TAS Beginner',
        label: 'Edu TAS Beginner',
        class: 'pype_Edu_TAS_Beginner_font_family'
    }
];

const ZendirectHandwriting = ({onChange, value}) => {

    const renderList = () => {
        let view = [];

        WRITING_STYLE.forEach((item, index) => {
            view.push(
                <MenuItem 
                    className={`dropdownhelper-menuitem-class ${item.class}`} 
                    // value={item.class} key={index}
                    value={item} key={index}
                >
                    {item.label}
                </MenuItem>
            )
        });
        return view;
    }
    return (
        <div>
            <FormControl className="dmpc_3rd_tag_formControll">
                <Select
                    value={value} 
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => onChange(e.target.value)}
                    className={"dmpc_3rd_tag_s"}
                    displayEmpty
                    inputProps={{ 'className': "className__update"}}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    <MenuItem value={null} disabled className={'dropdownhelper-menuitem-class'}>
                        Select handwriting style
                    </MenuItem>
                    {renderList()}
                </Select>
            </FormControl>
        </div>
    );
}
export default ZendirectHandwriting;