import React, {createContext, useReducer} from 'react'
import ActionType from './ActionType';

const VoiceSendContext = createContext({});

export const voiceSendStates = {
    /* main props */
    contactId: null,
    from: '',
    sendSuccessCallBack: null,
    onClose: null,
    contactInfo: null,

    /* virtual number */
    virtualNumbers: [],
    selectedVirtualNumber: '',
    selectedToNum: '',
    contactNumbers:  [],

    /* voice template state */
    isOpenQuickReply: false,
    quickReplies: [],
    fileData: null,
    selectedTemplate: null,
    selectedTemplateId: null,
    sendFileType: 'RECORD', //RECORD, UPLOAD, TEMPLATE

    isLoading: true,
    wentWrong: false,
    errorText: '',
    sending: false,

    saveAsTemplate: false,
    templateTitle: '',
    templateFlag: 'template_file'
}

export const voiceSendReducer = (state, action) => {
    switch(action.type){
        case ActionType.HANDLE_LOADING:
            if(typeof action.payload === 'object'){
                return {
                    ...state, 
                    isLoading: action.payload.isLoading !== undefined ? action.payload.isLoading : state.isLoading, 
                    wentWrong: action.payload.wentWrong,
                    errorText: action.payload.errorText
                }
            }
            return {...state, isLoading: action.payload}

        case ActionType.STORE_PROPS:
            return {
                ...state, 
                contactId: action.payload.contactId, 
                from: action.payload.from, 
                sendSuccessCallBack: action.payload.sendSuccessCallBack,
                onClose: action.payload.onClose,
                primaryEmail: action.payload.primaryEmail,
                contactInfo: action.payload.contactInfo !== undefined ? action.payload.contactInfo : null,
                contactNumbers: action.payload.contactNumbers ? action.payload.contactNumbers : [],
            }
        case ActionType.STORE_VIRTUAL_NUMBERS:
            return { ...state, virtualNumbers: action.payload }

        case ActionType.UPDATE_SELECTED_VN:
            return { ...state, selectedVirtualNumber: action.payload}

        case ActionType.UPDATE_FILE_TYPE:
            return {...state, sendFileType: action.payload}

        case ActionType.UPDATE_TEMPLATE_ID:
            return {...state, selectedTemplate: action.payload.data, selectedTemplateId: action.payload.id, templateFlag: action.payload.flag}

        case ActionType.UPDATE_IS_SHOW_TEMPLATE:
            return {...state, isOpenQuickReply: action.payload}

        case ActionType.STORE_TEMPLATES:
            return {...state, quickReplies: action.payload}

        case ActionType.UPDATE_SENDING_FLAG:
            return {...state, sending: action.payload}
            
        case ActionType.UPDATE_FILE_DATA:
            return {...state, fileData: action.payload}

        case ActionType.UPDATE_SAVE_AS_TEMPLATE_FLAG:
            return { ...state, saveAsTemplate: action.payload }
        
        case ActionType.UPDATE_TITLE:
            return { ...state, templateTitle: action.payload }
        case ActionType.UPDATE_SELECTED_TO_NUM:
            return { ...state, selectedToNum: action.payload }

        default: return state
    }
}

const VoiceSendProvider = ({children}) => {
    const [states, dispatch] = useReducer(voiceSendReducer, voiceSendStates)
    return (
        <VoiceSendContext.Provider
            value={{
                states,
                dispatch
            }}
        >
            {children}
        </VoiceSendContext.Provider>
    )
}
export {VoiceSendContext, VoiceSendProvider}