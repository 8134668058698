import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import icons from "../assets/icons";
import { _updateStateField } from "../redux/contactDetailAction";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import _Helper from "../assets/Helper";
import './header.css';
import VideoCallIcon from "@material-ui/icons/VideoCall";

const mapStateToProps = state => ({
    contactDetails: state.contactDetailReducer.contactDetails,
    tab: state.contactDetailReducer.tab,
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params))
})

const HeaderApp = (props) => {
    const [states, setStates] = useState({tab: props.tab})
    const [showSmsSendModal, setShowSmsSendModal] = useState(false)
    const [showEmailSendModal, setShowEmailSendModal] = useState(false)
    const [showVoiceSendModal, setShowVoiceSendModal] = useState(false)
    const [showVideoSendModal, setShowVideoSendModal] = useState(false)

    useEffect(() => {
        setStates({...states, tab: props.tab})
    }, [props.tab])

    const handleUpdateTab = (option) => {
        props._updateStateField({tab: option})
    }

    const handleModal = (modalType) => {
        if(modalType == 'sms'){
            props._updateStateField({showingSmsSendModal: true})
        }
        else if(modalType == 'email'){
            props._updateStateField({showingEmailSendModal: true})
        }
    }

    const getContactEmail = () => {
        let contact = props.contactDetails
        if(contact.email !== undefined && contact.email !== null){
            return contact.email
        }
        return ""
    }

    const handleMakeCall = () => {
        window.initiateGlobalDialer(props.contactDetails)
        props.onClose()
    }

    const handleSendModal = (modalType) => {
        switch (modalType) {
            case 'sms':
                if(_Helper.isEmpty(props.contactDetails.number)){
                    alert('Can not send sms. This contact has no number')
                    return
                }
                if(window.sendCommunication !== undefined){
                    window.sendCommunication({
                        open: true,
                        onClose:() => setShowSmsSendModal(false),
                        id:props.contactDetails.id,
                        from:"call-outcome",
                        fetchContactDetails:false,
                        contactInfo: props.contactDetails
                    },'sms')
                }
                break;

            case 'email':
                if(_Helper.isEmpty(props.contactDetails.email)){
                    alert('Can not send email. This contact has no email address')
                    return
                }
                if(window.sendCommunication !== undefined){
                    window.sendCommunication({
                        open: true,
                        onClose:() => setShowEmailSendModal(false),
                        id: props.contactDetails.id,
                        from:"call-outcome",
                        primaryEmail: getContactEmail(),
                        fetchContactDetails:false
                    },'email')
                }
                break;

            case 'voice':
                if(_Helper.isEmpty(props.contactDetails.number)){
                    alert('Can not send voice mail. This contact has no number')
                    return
                }
                if(window.sendCommunication !== undefined){
                    window.sendCommunication({
                        open: true,
                        onClose:() => setShowVoiceSendModal(false),
                        id:props.contactDetails.id,
                        from:"call-outcome",
                        fetchContactDetails:false
                    },'voice')
                }
                break;

            case 'video_email':
                let contactDetails = props.contactDetails;
                props.onClose();
                window.sendCommunication({
                    open: true,
                    contactId: contactDetails.id,
                    contactDetails: contactDetails,
                    from: 'deal',
                },'videoEmail')
                break;

            case 'note':
                handleUpdateTab('note');
                break;
            default:
                break;
        }
    }

    return (
      <div className="d-flex align-items-center justify-content-between addName__actions_wrapper">
        <div className="addName__actions">
            <ul className="outcome_tabs">
                <li className={`d-flex align-items-center ${states.tab === 'call_review' ? 'active' : ''}`}><a href="#" onClick={e => {e.preventDefault(); handleUpdateTab('call_review')}}>Call Outcome</a></li>
                <li className={`d-flex align-items-center ${states.tab === 'contact' ? 'active' : ''}`}><a href="#" onClick={e => {e.preventDefault(); handleUpdateTab('contact')}}>Contact Info</a></li>
                <li className={`d-flex align-items-center ${states.tab === 'note' ? 'active' : ''}`}><a href="#" onClick={e => {e.preventDefault(); handleUpdateTab('note')}}>Note</a></li>
            </ul>
            {/*<button className={states.tab === 'call_review' ? "accent--bg--color" : "accent-bg-opacity"} style={states.tab === 'call_review' ? {backgroundColor: '#3c7ef3', color: '#fff'}: {color: '#fff'}} onClick={() => handleUpdateTab('call_review')}>{icons.fullScreenIcon} <span className="ml-3">Call Outcome</span></button>*/}
            {/*<button className={states.tab === 'contact' ? "accent--bg--color" : "accent-bg-opacity"} style={states.tab === 'contact' ? {backgroundColor: '#3c7ef3', color: '#fff'}: {color: '#fff'}} onClick={() => handleUpdateTab('contact')}>{icons.fullScreenIcon} <span className="ml-3">Contact Info</span></button>*/}
            {/*<button className={states.tab === 'note' ? "accent--bg--color" : "accent-bg-opacity"} style={states.tab === 'note' ? {backgroundColor: '#3c7ef3', color: '#fff'}: {color: '#fff'}} onClick={() => handleUpdateTab('note')}>{icons.editIcon} <span className="ml-3">Note</span></button>*/}
        </div>
        <div className="action_icons">
            <ul className="contact__info_lists alt">
                <li className="d-flex align-items-center" onClick={() => handleSendModal('sms')}><span>{icons.SmsIcon}</span></li>
                <li className="d-flex align-items-center" onClick={() => handleSendModal('email')}><span>{icons.blackEnvIcon}</span></li>
                <li className="d-flex align-items-center" onClick={() => handleSendModal('voice')}><span><KeyboardVoiceIcon fontSize="small" /></span></li>
                <li className="d-flex align-items-center" onClick={() => handleMakeCall()}><span>{icons.blackCallIcon}</span></li>
                <li className="d-flex align-items-center" onClick={() => handleSendModal('video_email')}><span><VideoCallIcon /></span></li>
                <li className="d-flex align-items-center" onClick={() => handleSendModal('note')}><span>{icons.editIcon}</span></li>
                {/* <li className="d-flex align-items-center"><span>{icons.mapMarkerIcon}</span></li> */}
            </ul>
        </div>
      </div>
    );
}
const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderApp);
export default Header;