import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Slide } from "@material-ui/core";
import './style.css'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function DirectMailCardModal (props)  {
    const classes = useStyles();

    const [show, setShow] = useState(props.open)

    useEffect(() => {
        setShow(props.open);
    },[props.open])

    const closeModal=()=>{
        if(props.customCloseModal){
            props.onClose(true, true)
        }else {
            setShow(false);
            setTimeout(()=>{
                props.onClose(false)
            },500)
        }

    }

    let extra_props = {}
    if(props.extra_props_active !== undefined){
        extra_props['disableEnforceFocus'] = true;
        extra_props['disableAutoFocus'] = true;
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={show}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
            onBackdropClick ={closeModal}
            {...extra_props}
        >
            <Slide in={show} direction="left">
                <div className={`globalModalWrapper direct_mpc_modal_wr ${props.className !== undefined ? props.className : ''} ${props.hideFooter !== true ? '': 'global-modal-has-no-footer' }`}>
                    <div id="globalModal">
                        <div className="d-flex direct_mpc_modal_header justify-content-between align-items-center">
                            <div >
                                <h2 className="direct_mpc_modal_title">{props.title}</h2>
                            </div>
                            <div onClick={closeModal} className="d-flex direct_mpc_modal_close justify-content-center align-items-center">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 2.5C8.096 2.5 2.5 8.096 2.5 15S8.096 27.5 15 27.5 27.5 21.904 27.5 15 21.904 2.5 15 2.5Zm3.625 15c.317.308.087.587-.221.904-.317.317-.596.538-.914.221L15 16.135l-2.49 2.49c-.318.317-.596.096-.904-.221-.317-.317-.539-.596-.231-.904l2.49-2.5-2.49-2.49c-.308-.308-.086-.596.23-.904.309-.317.587-.539.905-.231l2.49 2.5 2.49-2.5c.318-.308.597-.086.914.23.308.309.538.597.221.905L16.135 15l2.49 2.5Z" fill="#FF264A"/></svg>
                            </div>
                        </div>

                        <div className="direct_mpc_modal_body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    );
}