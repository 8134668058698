import React from 'react';
import moment from 'moment';
import axis from 'axis.js';
import "moment-timezone";
import EachScheduleForActivity from "./EachScheduleForActivity";

class TimeScheduleListForActivity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSelected : null
        }
    }

    handler = (date,time)=>{
        this.setState({
            isSelected : time
        });
    };

    handlerConfirm = (date,time)=>{
        if (this.props.timeZone != this.props.userTimezone) {
            date = this.convertTime(date.replace(' 00:00:00', ''), time, this.props.timeZone, this.props.userTimezone).format('YYYY-M-D') + ' 00:00:00';
            time = this.convertTime(date.replace(' 00:00:00', ''), time, this.props.timeZone, this.props.userTimezone).format('hh:mma');
        }
        alert('LINE 26');
        // this.props.submitConfirm(date,time);
    };

    convertTime = (date, time, from, to) => {
        let dateTime = moment(date+' '+time,['YYYY-M-D h:ma']);
        return dateTime.tz(from, true).tz(to);
    }

    render() {

        let range = this.props.dateRange;
        let lists = [];
        for (let schedule in range){

            if(axis.isObject(range[schedule])){
                let date = moment(range[schedule].start,['YYYY-MM-DD']).format('YYYY-M-D');
                let start = range[schedule].title.split('-')[0];
                let end = range[schedule].title.split('-')[1];

                if (this.props.timeZone != this.props.userTimezone) {
                    date = this.convertTime(date, start, this.props.userTimezone, this.props.timeZone).format('YYYY-M-D');
                    start = this.convertTime(date, start, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                    end = this.convertTime(date, end, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                }

                do {
                    let time = moment(start,['h:m a', 'H:m']).format('h:mma');
                    let slotStart = moment(start,['h:m a', 'H:m']);
                    let slotEnd = moment(start,['h:m a', 'H:m']).add(this.props.duration,'m');
                    if (date in  this.props.reserveSlots) {

                        let isExist = this.props.reserveSlots[date].filter((reserveTime)=>{
                            let reserveStartTime = '';
                            let reserveEndTime = '';
                            if (this.props.timeZone != this.props.userTimezone) {
                                reserveStartTime = this.convertTime(date, reserveTime.start, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                                reserveEndTime = this.convertTime(date, reserveTime.end, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                            } else {
                                reserveStartTime = reserveTime.start;
                                reserveEndTime = reserveTime.end;
                            }
                            let startDate = moment(reserveStartTime,['h:m a', 'H:m']);
                            let endDate = moment(reserveEndTime,['h:m a', 'H:m']);
                            return slotStart.isSame(startDate) || slotStart.isBetween(startDate, endDate) || slotEnd.isBetween(startDate, endDate);
                        });

                        if(axis.isArray(isExist) && isExist.length === 0){
                            lists.push({
                                time : time,
                                date :range[schedule].start
                            });
                        }

                    } else{
                        lists.push({
                            time : time,
                            date :range[schedule].start
                        });
                    }

                    start = moment(start,['h:m a', 'H:m']).add(this.props.increments,'m');
                    end = moment(end,['h:m a', 'H:m']);


                }
                while(start < end);
            }
        }


        let today = moment().format('YYYY-MM-DD');

        let content = lists.map((data,index)=>{

            if(moment(moment(data.date).format('YYYY-MM-DD')).isSame(today)){
                if(moment(data.time,['h:m a', 'H:m']) > moment(moment(),['h:m a', 'H:m'])){
                    return  <EachScheduleForActivity isSelected={this.state.isSelected} handler={this.handler} handlerConfirm={this.handlerConfirm}  time={data} key={index}/>
                }
            }else {
                return  <EachScheduleForActivity isSelected={this.state.isSelected} handler={this.handler} handlerConfirm={this.handlerConfirm}  time={data} key={index}/>
            }
        });

        return (
            <React.Fragment>{content} </React.Fragment>
        );
    }

}

export default TimeScheduleListForActivity;