import { Button } from "@material-ui/core";
import { Fragment, useEffect } from "react";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { isDefaultAgency } from "../../helpers/Utils";

const Welcome = (props) => {
  useEffect(() => {
    // not working
    /* if (props.agencyId === 1) {
            const script1 = document.createElement("script");
            const script2 = document.createElement("script");

            script1.src = "https://fast.wistia.com/embed/medias/jlui3o59vm.jsonp";
            script1.async = true;

            script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
            script2.async = true;

            document.body.appendChild(script1);
            document.body.appendChild(script2);
        } */
  }, []);

  const nextStep = () => {
    props.goToStep(props.activeStep + 1);
  };

  const renderVideo = () => {
    if (props.agencyId && isDefaultAgency(props.agencyId)) {
      return (
        <iframe
          id='onboarding__step__one__iframe_id'
          src='https://fast.wistia.com/embed/medias/jlui3o59vm'
          title='Introduction'
          width='560'
          height='315'
          controls
        ></iframe>
      );
    } else if (props.welcomeVideo) {
      if (props.welcomeVideo.indexOf("iframe") === 1) {
        return (
          <div dangerouslySetInnerHTML={{ __html: props.welcomeVideo }}></div>
        );
      } else if (props.welcomeVideo.indexOf("iframe") > 1) {
        return (
          <div dangerouslySetInnerHTML={{ __html: props.welcomeVideo }}></div>
        );
      } else {
        return (
          <iframe
            src={props.welcomeVideo}
            title='Introduction'
            width='560'
            height='315'
            controls
          ></iframe>
        );
      }
    } else {
      return (
        <p>
          Welcome to the on-boarding guide. Completing some steps you will setup
          your account within a few minutes.
        </p>
      );
    }
  };

  return (
    <Fragment>
      <p
        className='onboarding__step__one__title_log_out'
        onClick={props.logout}
      >
        Log Out
      </p>
      <p className='onboarding__step__one__title'>Introduction</p>

      <div style={{ textAlign: "center" }}>
        <div>{renderVideo()}</div>

        <p>
          <Button
            variant='contained'
            color='primary'
            endIcon={<PlayCircleFilledIcon />}
            className='mt-2'
            onClick={nextStep}
            disabled={props.loading}
          >
            {props.loading ? "Please Wait.." : "Let's Get Started"}
          </Button>
        </p>
      </div>
    </Fragment>
  );
};

export default Welcome;
