import React from "react";
import SmsSend from "../../SendModules/sms";
import ModalGlobalModal from "../ModalGlobalModal";

const SmsSendModal = (props) => {
    return(
        <ModalGlobalModal
            open={props.open}
            title="Send Sms"
            onClose={() => props.onClose(false)}
            width={800}
            hideFooter={true}
            className="global_modal_overflow_hidden activity__modal_overflow contact__send_sms_modal"
        >
            <SmsSend
                showTimeSetting = {true}

                showSelectedContact= {false}
                selectedContactItem= {(<div>contact information</div>)}

                contact_id={props.data.id}
                contact_data={props.data}
                onSubmit={()=> (props.onSubmit !== undefined && props.onSubmit != null) ? props.onSubmit() : console.log('submit')}
                onClose={() => props.onClose(false)}
            />
        </ModalGlobalModal>
    )

}
export default SmsSendModal;