import {useContext, useEffect, useState} from 'react';
import CustomSelect from '../components/CustomSelect';
import {VoiceSendContext} from "../reducers/GlobalReduers"
import { MenuItem } from "@material-ui/core";
import { updateVirtualNumber } from '../reducers/Action';
import Utils from "../../../../helpers/Utils";

const FromNumber = (props) => {
    const {states, dispatch} = useContext(VoiceSendContext);
    const [virtualNumbers, setVirtualNumbers] = useState([]);

    useEffect(()=>{
        if (Array.isArray(states.virtualNumbers)){
            setVirtualNumbers(states.virtualNumbers.map((vn)=>({ label: Utils.formatPhoneNumber(vn.label), value: vn.value })));
        }
    },[states.virtualNumbers]);

    const hanldeVirtualNumber = (value) => {
        dispatch(updateVirtualNumber(value))
    }

    return(
        <div className="voice__email__wrapper__alt">
            <div className="voice__email__wrapper__top">
                <h3 className="voice__email__wrapper__top__title">From Number: </h3>
                <div className="voice__email__wrapper__top__select">
                    <CustomSelect
                    formControlClass="voice__email__wrapper__top__select__innter"
                    value={states.selectedVirtualNumber}
                    handleChange={(event) => hanldeVirtualNumber(event.target.value)}
                    placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a virtual number </MenuItem>)}
                    item={virtualNumbers}
                    titel_field={'label'}
                    value_field={'value'}
                    menuItemClass="dropdownhelper-menuitem-class"                
                />
                </div>
            </div>
            
        </div>
    )
}
export default FromNumber;