const Header = ({type}) => {
    return(
        <div className='dmpc_3rd_middle_top'>
            <div className='dmpc_3mt_left'>
                <h4 className='dmpc_3mt_left_title'>Customize your Card Message</h4>
                {type === 'zendirect_gift' ?
                <h5 className='dmpc_3mt_left_subtitle'>Customize your message below. Change page from the right side</h5>
                :
                <h5 className='dmpc_3mt_left_subtitle'>Select a handwriting style and customize your message below.</h5>
                }
            </div>
        </div>
    );
}
export default Header;