import React,{useEffect,useState} from 'react';
import { fetchTermsAndConditions } from '../../../api/loginApi';
import { Helmet } from 'react-helmet';
import './privacyAndTc.css';
import {Link} from "react-router-dom";


const TermsAndConditions = () => {

    const [pageInfo, setPageInfo] = useState({
        agencyLogo: '',
        favIcon: '',
        agencyName: '',
        termsAndConditionsText: ''
    });

    useEffect(() => {
        fetchTermsAndConditions({}).then(res => {
            setPageInfo({
                ...pageInfo,
                domain: res.data.data.subdomain,
                agencyLogo: res.data.data.agencyLogo,
                favIcon: res.data.data.favIcon,
                agencyName: res.data.data.agencyName,
                termsAndConditionsText: res.data.data.termsAndConditionsText?.page_text

            });
        });
    },[])

    return (
        <>
        <Helmet>
            <link rel="icon" href={pageInfo.favIcon} />
            <title>{`Terms And Conditions | ${pageInfo.agencyName}`}</title>
            <meta name="description" content="All-In-One Sales & Marketing Platform" />
            <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`} />
            <meta name="author" content={pageInfo.agencyName} />
        </Helmet>
        <div className="pypepro__privacy__policy">
            <div className="pypepro__header">

                <div className="pypepro__container d-flex justify-content-between align-items-center">
                    <div className="pypepro__privacy__logo">
                        <img src={pageInfo.agencyLogo} width="150px" alt="logo" />
                    </div>
                    <div className="header-right-buttons">
                        <Link to="/paid/get-started" className={"btn m-btn btn-primary btn-sm accent--bg--text--color mr-2"} > Sign Up</Link>
                        <Link to="/login" className={"btn m-btn btn-primary btn-sm accent--bg--text--color"} > Sign In</Link>
                    </div>
                </div>
                
            </div>
        
            <div className="pypepro__banner">
                <div className="pypepro__banner__overly"></div>
                <div className="pypepro__container">
                    <div className="pypepro__banner__content">
                            <h1>TERMS AND CONDITIONS</h1>
                    </div>
                </div>
            </div>
    
            <div className="pypepro__inner__content">
                <div className="pypepro__container">
                { (pageInfo.termsAndConditionsText === null || pageInfo.termsAndConditionsText === undefined) ? (
                    <>
                    <div className="pypepro__single__item">
                        <h5>TERMS OF USE
                        </h5>
                        <p> {pageInfo.agencyName} products and services are provided by {pageInfo.agencyName}, LLC dba {pageInfo.agencyName} (“{pageInfo.agencyName}”). These Terms of Use (“Terms”) govern your use of {pageInfo.domain} (“Website”), the {pageInfo.agencyName} Dashboard (“Dashboard”), and {pageInfo.agencyName} other products and services (collectively, “Services”). Please read these Terms carefully, and contact {pageInfo.agencyName} if you have any questions. By using the Services, you agree to be bound by these Terms, including the policies referenced in these Terms.</p>
                    </div>
                    
                    <div className="pypepro__single__item">
                        <p> {pageInfo.agencyName} reserves the right, at any time and from time to time, to amend or to modify these Terms without prior notice to you, provided that if any such alterations constitute a material change to these Terms, as determined by {pageInfo.agencyName}, {pageInfo.agencyName} will notify you by posting an announcement on the Website or the Dashboard. Amendments and modifications shall take effect immediately when posted. By continuing to access or use the Services after any such posting, you agree to be bound by such amended or modified Terms. If you do not agree to any change to these Terms, then you must immediately stop using the Services.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>USING THE SERVICES
                        </h5>
                        <h5>1. Who May Use the Services</h5>
                        <p>You may use the Services only if you can form a binding contract with {pageInfo.agencyName}. By registering for the Services, you agree to use the Services in compliance with these Terms and all applicable laws. Any use or access by anyone under the age of thirteen (13) is prohibited, and certain Services may have additional requirements and/or restrictions.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>2. License to You; Ownership
                        </h5>
                        <p> Subject to these Terms and {pageInfo.agencyName} policies (including the Privacy Policy and other terms), {pageInfo.agencyName} grants you a limited, personal, non-exclusive, non-transferable, and revocable license to use the Services for up to the number of users set forth in the pricing table upon receipt of the applicable license fee, if any.{pageInfo.agencyName}and/or its licensors own and shall retain all rights, titles, and interests, including all intellectual property rights, in and to the Services, and all elements thereof. Except for the express licenses granted to you herein, you neither have nor acquire any rights, titles, or interests in or to the Services, or any element thereof.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>3. Registration, Passwords, and Account Information
                        </h5>
                        <p> You may only use the Services if you are registered and have an account with {pageInfo.agencyName}. To open an account, you must complete the registration process by providing {pageInfo.agencyName} with current, complete, and accurate information as prompted by the registration form and maintain such information to ensure it remains current, complete, and accurate. Should{pageInfo.agencyName}suspect that such information is untrue, inaccurate, not current, or incomplete,{pageInfo.agencyName}has the right to suspend or terminate your usage of the Services.{pageInfo.agencyName}is entitled to rely on the information you provide, and you will be responsible for updating this information to maintain current information. Accounts are non-transferable and cannot be shared or used by anyone other than you and the named sub users. Once you have become an authenticated user, you will be given a password or the option to create your own password. You are and will be responsible for maintaining the confidentiality of that password, for all activities conducted on and with the Services that make use of that password, and for charges or fees incurred by the use of that password, if any, including any use you may subsequently contend was not unauthorized by you. You are solely responsible for any and all activities that occur under your account including inputting, maintaining, and managing accurate information and ensuring that you exit or log-off from your account at the end of each session of use. You shall notify{pageInfo.agencyName}immediately of any unauthorized use of your password or account or any other breach of security that is known or suspected by you.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>4. Cancellations
                        </h5>
                        <p> You may delete your account, cancel your access to the Services, or terminate your account and these Terms at any time. If you cancel Services that you paid for, your access to and use of the Services will be terminated and you will not be entitled to a refund, provided that{pageInfo.agencyName}may issue you with a refund of any fees paid on a case-by-case basis, and solely in {pageInfo.agencyName} sole discretion, including if the Services are unavailable. It is your responsibility to retrieve all relevant data and content from your account prior to termination. On termination of your account, the Services, or these Terms,{pageInfo.agencyName}may retain your account information, data, content, or other documents (including copies) as required by law or regulation, or as may be automatically retained by backup or disaster recovery systems. You acknowledge and agree to authorize{pageInfo.agencyName}to retain and/or destroy such information, data, content, and documents upon termination of these Terms or your account.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>5. Suspension
                        </h5>
                        <p> Generally, {pageInfo.agencyName} may suspend your right to access or use any portion or all of the Services immediately upon notice to you if{pageInfo.agencyName}determines:</p>
                    </div>
                    <div className="pypepro__single__item">
                        <p> (a) your use of the Services: (i) poses a security risk to the Services or any third party, (ii) could adversely impact {pageInfo.agencyName} systems, the Services or the systems or content of any other{pageInfo.agencyName}customer, (iii) could subject{pageInfo.agencyName}or any third party to liability, or (iv) could be fraudulent;</p>
                    </div>
                    <div className="pypepro__single__item">
                        <p> (b) you are in breach of these Terms;</p>
                    </div>
                    <div className="pypepro__single__item">
                        <p> (c) you are in breach of your payment obligations; or</p>
                    </div>
                    <div className="pypepro__single__item">
                        <p> (d) you have ceased to operate in the ordinary course, made an assignment for the benefit of creditors or similar disposition of your assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <p>If {pageInfo.agencyName} suspends your right to access or use any portion or all of the Services, you remain responsible for all fees and charges you incur during the period of suspension.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>6. Service Modifications
                        </h5>
                        <p>{pageInfo.agencyName} takes pride in its products and Services, but unexpected events do occur.{pageInfo.agencyName}reserves the right to cancel, interrupt, or modify its Services from time to time, including any features or functionality contained within the Services at any time. In addition,{pageInfo.agencyName}is constantly attempting to improve the Services, which may result in changes to the Services, including the addition or removal of features or functionality. For example,{pageInfo.agencyName}may add features or functions, and as a result{pageInfo.agencyName}may suspend or stop providing other features or functions. {pageInfo.agencyName}, and third parties it engages to provide the Services, and each parties employees, contractors, and other agents (the “{pageInfo.agencyName} Parties”) shall have no liability to you for any such action.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>7. Compliance with Law
                        </h5>
                        <p> In using the Services, you agree to comply with, and refrain from violations of, any right of any other person, entity, law, or contractual duty, including, without limitation, those laws forbidding: (a) defamation and harassment, (b) forgery, identity theft, misdirection, and or interference with electronic communications, (c) invasion of privacy, (d) unlawful sending of commercial electronic messages or other marketing or electronic communications, (e) collection of excessive user data from individuals, including children, or other improper data collection activities, (f) securities violations, wire fraud, money laundering, or terrorist activities, or (f) false advertising, propagating or profiting from frauds and unfair schemes. You will also comply with the affirmative requirements of law governing use of the Services, including but not limited to: (i) disclosure requirements, including those regarding notification of security breaches, (ii) records maintenance for regulated industries, and (iii) financial institution safeguards. The term “law” in these Terms means any statute, law, ordinance, regulation, rule, judgment or order of a government, court, or tribunal of competent jurisdiction, including, without limitation, any data protection laws, privacy laws, any laws that requires you to obtain consent from a third party or provide notice to a third party in connection with such third party, any state, federal, and international laws, regulations, and rules related to the recording or monitoring of telephone calls, SMS messages, or other communications, the U.S. Foreign Corrupt Practices Act, rules established by the Federal Communications Commission, any federal or state anti-spam statute or regulation, including the CAN SPAM Act of 2003, or any federal or state statute or regulation prohibiting the dissemination of unsolicited communications, including the Telephone Consumer Protection Act of 1991.</p>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>YOUR CONTENT
                        </h5>
                    </div>
                    <div className="pypepro__single__item">
                        <h5>1. Generally
                        </h5>
                        <p> Some Services enable you to upload content for use with the Services (“User Content”). For example, you may personalize a campaign. You retain all intellectual property rights in, and are responsible for, the User Content you upload into the Services.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>2. How {pageInfo.agencyName} May Use User Content
                        </h5>
                        <p> If you provide User Content, you grant {pageInfo.agencyName} a royalty-free, perpetual, sublicensable, transferable, non-exclusive, worldwide license to copy, distribute, modify, create derivative works based on, perform, display, and otherwise use the User Content for purposes of providing the Services. This license includes granting{pageInfo.agencyName}the right to share your information with {pageInfo.agencyName}’s hosting provider and other third parties{pageInfo.agencyName}users to provide the Services to you.{pageInfo.agencyName}reserves the right to remove User Content for any reason, including User Content that{pageInfo.agencyName}believes violates these Terms.</p>
                       
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>3. Feedback
                        </h5>
                        <p> From time to time, you may provide suggestions, ideas, comments, and other feedback related to the Services (“Feedback”). By submitting Feedback to {pageInfo.agencyName} you grant{pageInfo.agencyName}the right to use the Feedback without any restriction or any compensation to you. By accepting your Feedback,{pageInfo.agencyName}does not waive any rights to use similar or related Feedback previously known to {pageInfo.agencyName}, developed by its employees or contractors, or obtained from other sources.</p>
                       
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>4. Infringing User Content
                        </h5>
                        <p> You agree not to use the Services in violation of the copyrights, trademarks, patents or trade secrets of third parties, nor shall you utilize the Services to publish such materials in a manner that would expose them to public view in violation of the law. The provisions of the Digital Millennium Copyright Act of 1998 (as required under 17 U.S.C. §512) and all other applicable international trademark, copyright, patent or other intellectual property laws will apply to issues presented by allegations of copyright violations by third parties. If a third party believes that you are violating its intellectual property rights, it must notify{pageInfo.agencyName}by email. A notification should include information reasonably sufficient to permit{pageInfo.agencyName}to locate the allegedly infringing material, such as the IP address, email, or URL of the specific online location where the alleged infringement is occurring or occurred.{pageInfo.agencyName}reserves the right to prevent any further activity associated with an account if infringement is alleged by a third party.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>GENERAL TERMS
                        </h5>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>1. Security
                        </h5>
                        <p> {pageInfo.agencyName} cares about the security of all users and has implemented reasonable and appropriate measures designed to help secure your information and User Content against accidental or unlawful loss, access, or disclosure. While{pageInfo.agencyName}works to protect the security of your information and User Content,{pageInfo.agencyName}cannot guarantee that unauthorized third parties will not be able to defeat {pageInfo.agencyName} security measures.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>2. Third Party Content
                        </h5>
                        <p> Through the Services, you will have the ability to access and/or use content provided by third parties and links to websites and services maintained by third parties. {pageInfo.agencyName} cannot guarantee that such third party content, in the Services or elsewhere, will be free of material you may find objectionable or otherwise inappropriate or of malware or other contaminants that may harm your computer, mobile device, or any files therein. {pageInfo.agencyName} disclaims any responsibility or liability related to your access or use of such third party content.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>3. GDPR
                        </h5>
                        <p> You hereby represent and warrant that no personal data subject to General Data Protection Regulation (EU) 2016/679 (“GDPR”) is, or will be, processed by {pageInfo.agencyName} as a processor on your behalf as part of the product, services, or these Terms. You shall communicate to{pageInfo.agencyName}in advance and in writing of any anticipated change affecting this representation and warranty and before requesting{pageInfo.agencyName}to process any personal data or providing any personal data to {pageInfo.agencyName} If you communicate any anticipated change affecting this representation and warranty to {pageInfo.agencyName} you and{pageInfo.agencyName}will work in good faith to eliminate the need for{pageInfo.agencyName}to process personal data. If the parties mutually agree that{pageInfo.agencyName}will process personal data that is subject to GDPR,{pageInfo.agencyName}agrees to: (a) only process personal data based on your instructions; and (b) implement technical and organizational measures to protect such personal data. You agree to assist{pageInfo.agencyName}in any request or procedure by a national data protection supervisory authority, or in any lawsuit, relating to the processing of any personal data covered by this representation and warranty. Furthermore, you shall indemnify and hold{pageInfo.agencyName}harmless for any cost, charge, damages, expenses, or loss (including reasonable attorneys’ fees) arising from {pageInfo.agencyName} receiving or processing of personal data that is subject to GDPR. Any undefined terms used in this Section shall have the meaning set forth in GDPR.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>4. Disclaimers
                        </h5>
                        <p> THE SERVICES AND ALL INCLUDED CONTENT ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE{pageInfo.agencyName}PARTIES SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.{pageInfo.agencyName}DOES NOT MAKE, AND EXPRESSLY DISCLAIMS, ANY REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY, OR COMPLETENESS OF THE SERVICES; NOR DOES{pageInfo.agencyName}WARRANT OR REPRESENT THAT YOUR USE OF THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED, ERROR-FREE, OR OPERATE IN COMBINATION WITH ANY OTHER SERVICES. THE{pageInfo.agencyName}PARTIES FURTHER DISCLAIM ANY AND ALL LIABILITY RELATED TO YOUR ACCESS OR USE OF THE SERVICES OR ANY RELATED CONTENT. YOU ACKNOWLEDGE AND AGREE THAT ANY ACCESS TO OR USE OF THE SERVICES, OR SUCH CONTENT CONTAINED THEREIN, IS AT YOUR OWN RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM ANY{pageInfo.agencyName}PARTIES, OR THROUGH OR FROM THE SERVICES, SHALL CREATE ANY REPRESENTATION OR WARRANTY BY {pageInfo.agencyName}</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>5. Limitation of Liability
                        </h5>
                        <p> TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE {pageInfo.agencyName} PARTIES SHALL NOT BE LIABLE FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) ANY CONDUCT OR CONTENT OF ANY PARTY OTHER THAN THE APPLICABLE{pageInfo.agencyName}PARTY, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT OR INFORMATION. IN NO EVENT SHALL {pageInfo.agencyName}, OR ANY{pageInfo.agencyName}PARTIES, AGGREGATE LIABILITY FOR ALL CLAIMS RELATED TO THE SERVICES EXCEED ONE HUNDRED FIFTY U.S. DOLLARS ($150) OR THE TOTAL AMOUNT OF FEES RECEIVED BY{pageInfo.agencyName}FROM YOU FOR THE USE OF SERVICES DURING THE THREE (3) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE LIABILITY, WHICHEVER IS GREATER.</p>
                        <p>YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THESE TERMS REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND THE{pageInfo.agencyName}PARTIES, AND THAT THESE LIMITATIONS ARE AN ESSENTIAL BASIS TO {pageInfo.agencyName} ABILITY TO MAKE THE SERVICES AVAILABLE TO YOU ON AN ECONOMICALLY FEASIBLE BASIS. YOU AGREE THAT ANY CAUSE OF ACTION RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>6. Indemnification
                        </h5>
                        <p> You agree to indemnify, defend, and hold harmless the {pageInfo.agencyName} Parties from any and all claims, actions, lawsuits, judgements, fines, penalties, liabilities, expenses, and damages, including reasonable attorneys’ fees and costs, asserted against, incurred by, or assessed against any{pageInfo.agencyName}Party arising out of or related to: (a) your use or attempted use of the Services; (b) your breach of these Terms; (c) your violation of any law or rights of any third party; or (d) User Content, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>7. Governing Law and Jurisdiction
                        </h5>
                        <p> In order to ensure consistency in the interpretation and enforcement of these Terms, these Terms will be governed exclusively by Michigan law and controlling United States federal law, without regard to the choice or conflicts of law provisions of any jurisdiction. You expressly agree that any litigation arising between you and{pageInfo.agencyName}related, in any way, to the Services and/or these Terms, and/or any and all disputes, actions, claims, or causes of action related thereto, shall be initiated and maintained only in the U.S. District Court for the Eastern District of Michigan, Southern Division, and/or the Circuit Court of the County of Oakland, Michigan. You expressly consent and irrevocably submit to the exclusive personal jurisdiction and venue of such courts.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>8. Class Action/Jury Trial Waiver
                        </h5>
                        <p> WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS WHETHER THEY HAVE OBTAINED OR USED THE SERVICES FOR PERSONAL, COMMERCIAL, OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND UNLESS{pageInfo.agencyName}AGREES OTHERWISE, MULTIPLE CLAIMS MAY NOT BE CONSOLIDATED. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND{pageInfo.agencyName}ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND RELATING TO THE PRODUCTS OR THESE TERMS. Certain laws, rules, and regulations that may apply to you may not allow you to pre-agree to binding arbitration or waive your rights to join in a representative proceeding or waive your rights to a jury trial. If such laws apply to you, the class action/jury trial waiver set forth in subsection does not apply to you.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>9. Severability; Waiver
                        </h5>
                        <p> If it turns out that a particular provision of these Terms is not enforceable or invalid as determined by a court of competent jurisdiction, this will not affect any other terms and such provision shall be modified to the extent necessary to be valid and enforceable. If you do not comply with these Terms, and{pageInfo.agencyName}does not take immediate action, this does not indicate that{pageInfo.agencyName}relinquishes any rights that{pageInfo.agencyName}may have (such as taking action in the future). Any waiver of rights by{pageInfo.agencyName}must be acknowledged and agreed to by you and{pageInfo.agencyName}in writing.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>10. Relationship
                        </h5>
                        <p> The relationship between you and {pageInfo.agencyName} is that of independent contractor. No joint venture, partnership, employment, or agency relationship exists between you and{pageInfo.agencyName}as a result of these Terms, or your access to, and use of, the Services. Neither party has the authority to create any obligations for the other, or to bind the other to any representation, statement, or document. Any person or entity who is not a party to these Terms has no right to benefit under or to enforce any of these Terms.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>11. Survival
                        </h5>
                        <p> Subject to these Terms, all provisions of these Terms relating to ownership, limitations of liability, indemnification, and other provisions which by their nature survive termination of these Terms shall survive termination of these Terms.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>12. Notice
                        </h5>
                        <p> Any notice required or permitted to be given to you under these Terms will be addressed to the email address provided by you at registration or posted on the Website or Dashboard as set forth herein.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>13. Assignment
                        </h5>
                        <p> These Terms are personal to you. You may not assign, transfer, or sublicense, in whole or part, any of your rights and/or obligations under these Terms without {pageInfo.agencyName} prior written consent. Any attempted assignment without such written consent shall be void. Notwithstanding the foregoing,{pageInfo.agencyName}may assign this Agreement without your consent, in whole, but not in part. Subject to the provisions of this Section, these Terms will be binding upon and will inure to the benefit of the parties and their respective successors and assigns.</p>
                    </div>
    
    
                    <div className="pypepro__single__item">
                        <h5>14. Entire Agreement
                        </h5>
                        <p> These Terms, the Privacy Policy, the Acceptable Use Policy, and any other terms provided by{pageInfo.agencyName}on or through the Services constitute the entire agreement between you and{pageInfo.agencyName}relating to the Services and supersede any and all prior communications and/or agreements between you and{pageInfo.agencyName}relating to access and use of the Services.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <h5>ACCEPTABLE USE POLICY
                        </h5>
                    </div>
    
                    <div className="pypepro__single__item">
                        <hr />
                        <p>This policy provides guidelines for using the Services.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <p>Although {pageInfo.agencyName} does not routinely screen or monitor User Content or campaigns,{pageInfo.agencyName}may remove or edit inappropriate content, and suspend, disable, or terminate a user’s access to all or part of the Services.</p>
                    </div>
    
                    <div className="pypepro__single__item">
                        <p>You are prohibited from using the Services to:</p>
                    </div>
    
                    <div className="pypepro__single__item">
                       <ul>
                           <li>Share illegal content or promote illegal activities or substances.</li>
                           <li>Share content that is defamatory, obscene, pornographic, vulgar, offensive, discriminatory, hateful, racist, harassing, or harmful; or that promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group.</li>
                           <li>Make threats or organize acts of real-world violence.</li>
                           <li>Harass others.</li>
                           <li>Violate intellectual property, privacy, laws, regulations, or any other third party’s rights.</li>
                           <li>Spam others, or share irrelevant or inappropriate advertising, promotional, or solicitation content.</li>
                       </ul>
                    </div>
    
                    <div className="pypepro__single__item">
                        <p>You also shall not:</p>
                    </div>
    
                    <div className="pypepro__single__item">
                       <ul>
                           <li>Do anything that violates local, state, national, or international law or breaches any of your contractual obligations or fiduciary duties.</li>
                           <li>Share any passwords, let anyone access your information with {pageInfo.agencyName}, or do anything that might put your information at risk.</li>
                           <li>Attempt to access any other user’s information.</li>
                           <li>Reproduce, transfer, sell, resell, or otherwise misuse any content from the Services, unless specifically authorized to do so.</li>
                           <li>Access, tamper with, or use non-public areas of {pageInfo.agencyName} systems, unless specifically authorized to do so.</li>
                           <li>Break or circumvent {pageInfo.agencyName} authentication or security measures or otherwise test the vulnerability of {pageInfo.agencyName} systems or networks, unless specifically authorized to do so.</li>
                           <li>Try to reverse engineer any portion of the Services.</li>
                           <li>Try to interfere with any user, host, or network, for example by sending a virus, overloading, spamming, or mail-bombing.</li>
                           <li>Use the Services to distribute malware.</li>
                           <li>Impersonate or misrepresent your affiliation with any person or entity.</li>
                           <li>Encourage or help anyone do any of the things on this list.</li>
                       </ul>
                    </div>
                    </>
                    ): <div dangerouslySetInnerHTML={{__html: pageInfo.termsAndConditionsText}}></div>}
                </div>
            </div>
        </div>
        
        
    </>
    );
}

export default TermsAndConditions;