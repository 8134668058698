import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogContentText, Divider, Grid, makeStyles } from "@material-ui/core";
import { RightSideStyle } from "../RightSideStyle";
import CustomModal from "../common/CustomModal/CustomModal";
import DynamicInputField from "./DynamicInputField";
import Typography from "@material-ui/core/Typography";


const useStyles=makeStyles({
  dialogContentWIdth:{
    width:'700px'
  }
})

const customTypes = {
  1: "text",
  2: "multiline_text",
  3: "number",
  4: "date",
  5: "checkbox",
  6: "select",
  7: "radio",
  8: "phone",
  9: "zip_code",
  10: "website",
  11: "datetime",
};

const EditAdditionalInfoModal = ({ open, onClose, contactCustomFields, onSubmit, errors, setErrors }) => {
  const { primaryButton, closeButton, flexColumn, alignJustifyCenter, flexCenter } = RightSideStyle();
  const [customFields, setCustomFields] = useState([]);
  const [customFieldValues, setCustomFieldValues] = useState({});

  useEffect(() => {
    window
        .globalCustomFieldWithPersonalized({
          get_custom_data: true,
          get_personalized_data: false,
          field_name: ["*"],
          for_drop_down: false,
          drop_down_field: {
            value_field: "id",
            title_field: "title",
          },
        })
        .then((res) => {
          let customFieldData = res.data.custom_field;
          setCustomFields(customFieldData);
        });
  }, []);

  useEffect(() => {
    if (
        contactCustomFields !== undefined &&
        contactCustomFields != null &&
        Array.isArray(contactCustomFields) &&
        contactCustomFields[0]
    ) {
      let newValues = {};
      for (let i = 0; i < customFields.length; i++) {
        let initValue = contactCustomFields.find((item) => item.user_custom_field_id === customFields[i].id);

        if (initValue) {
          let values = "";

          if (initValue.value) {
            values = initValue.value.replace(/null,/g, "").replace(/,null/g, "");
          }

          newValues[`${customFields[i].title}-${customFields[i].id}`] = {
            id: customFields[i].id,
            value: values,
          };
        }
      }

      setCustomFieldValues(newValues);
    } else {
      setCustomFieldValues({});
    }
  }, [contactCustomFields, customFields]);

  const handleCustomFieldValues = (obj) => {
    let newValues = { ...customFieldValues, ...obj };

    if (errors && obj) {
      let extObj = Object.values(obj)[0];
      if (extObj && errors[`field-${extObj.id}`]) {
        setErrors((prevErrors) => {
          const newData = { ...prevErrors };
          newData[`field-${extObj.id}`] = "";
          return newData;
        });
      }
    }

    setCustomFieldValues(newValues);
  };

  const renderCustomFields = () => {
    if (!customFields[0]) {
      return (
          <Grid item md={12}>
            <Box className={flexCenter} sx={{ flexDirection: "column", justifyContent: "center", mt: 2 }}>
              <Typography variant='body1' color='textSecondary' style={{ marginBottom: "15px" }}>
                <i>No custom fields</i>
              </Typography>
            </Box>
          </Grid>
      );
    }

    let item = [];

    for (let i = 0; i < customFields.length; i++) {
      let error = "";

      if (errors && errors[`field-${customFields[i].id}`]) {
        error = errors[`field-${customFields[i].id}`];
      }

      item.push(
          <Grid item md={6}>
            <DynamicInputField
                error={error}
                data={customFields[i]}
                fieldType={customTypes[customFields[i].type_id]}
                maxLength={customFields[i].max_length}
                title={customFields[i].title}
                values={customFieldValues}
                contactCustomFields={contactCustomFields}
                callback={handleCustomFieldValues}
            />
          </Grid>
      );
    }

    return item;
  };
  const classes=useStyles()
  return (

      <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth={1200}>
        <CustomModal title={"Additional Info"} open={open} onClose={onClose}/>
        <DialogContent className={classes.dialogContentWIdth}>
          <DialogContentText>
            <Box py={2} >
              <Grid container spacing={2}>
                {renderCustomFields()}
              </Grid>
            </Box>

            {customFields[0] && (
                <Fragment>
                  <Divider light />
                  <Box className={alignJustifyCenter} sx={{ gap: "10px" }} pt={2} pb={1}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          onSubmit(customFieldValues);
                        }}
                        disableElevation
                        className={primaryButton}
                    >
                      Update Additional Info
                    </Button>
                    <Button className={closeButton} variant='contained' color='secondary' disableElevation onClick={onClose}>
                      Close
                    </Button>
                  </Box>
                </Fragment>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
};

export default EditAdditionalInfoModal;
