import DirectMailIcons from "../../DirectMailIcons";

const FooterButton = ({handleStepChange}) => {
    return(
        <div className='dmpc_tv_right_inner_btn'>
            <div className='dmpc_tv_right_inner_savebtn' onClick={()=>handleStepChange(true)}>
                <span>{DirectMailIcons.saveContinueIcon}</span>
                <span>Save & Continue</span>
            </div>
        </div>
    );
}
export default FooterButton;