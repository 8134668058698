import * as ACTION from "../constants/loginActionTypes";

export const updateUser = payload => ({
    type: ACTION.LOGIN_SUCCESS,
    payload: payload
});

export const attemptLogin = (credentials, callback) => ({
    type: ACTION.LOGIN_ATTEMPT,
    payload: {credentials, callback}
});

export const attemptOtpChecking = (credentials, callback) => ({
    type: ACTION.OTP_CHECK,
    payload: {credentials, callback}
});

export const loginSuccess = payload => ({
    type: ACTION.LOGIN_SUCCESS,
    payload: payload
});