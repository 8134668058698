import CustomCircleLoader from "../../CustomCircleLoader";
import DirectMailIcons from "../../DirectMailIcons";
import Header from "./ReadyToSendPages/Header";
import SendTypeStep from "./ReadyToSendPages/SendTypeStep";
import Steps from "./ReadyToSendPages/Step";

const ReadyToSend = ({readyToSend, handleReadyToSendChange, handleStepChange, handleSend, contactInfo, processFor}) => {

    const handlerTab = (item,index) =>{
        if(item.id === 5 && processFor==='direct-mail-send'){
            if(contactInfo == null){
                return;
            }
            else if(contactInfo.birth_date == null & contactInfo.anniversary_date == null){
                return;
            }
            else if(contactInfo.birth_date === "" & contactInfo.anniversary_date === ""){
                return;
            }
            else if(contactInfo.birth_date === "" & contactInfo.anniversary_date == null){
                return;
            }
            else if(contactInfo.birth_date == null & contactInfo.anniversary_date === ""){
                return;
            }
        }
        handleReadyToSendChange({send_type: index})
    }

    return (
        <div className='dmpc_4th_middle'>
           <Header />
            
            <div className='dmpc_4th_middle_container'>
                <div className='dmpc_4th_middle_container_top'>
                    <Steps 
                        onClick={handlerTab}
                        activeTab={readyToSend.send_type}
                        contactInfo={contactInfo}
                        processFor={processFor}
                    />

                    <SendTypeStep 
                        activeTab={readyToSend.send_type}
                        readyToSend={readyToSend}
                        handleReadyToSendChange={handleReadyToSendChange}
                        contactInfo={contactInfo}
                        processFor={processFor}
                    />
                </div>

                {readyToSend.errorMsg !== "" &&
                    <div className="dmpc__send__error__text">{readyToSend.errorMsg}</div>
                }

                <div className='dmpc_4th_footer'>
                    <div className='dmpc_tv_right_inner_btn'>
                        <div className='dmpc_tv_right_inner_backbtn' onClick={()=>handleStepChange(false)}>
                            <span>{DirectMailIcons.backIcon}</span>
                            <span>Go Back</span>
                        </div>
                        <div className='dmpc_tv_right_inner_savebtn' onClick={handleSend}>
                            {readyToSend.isSending ?
                                <span className="dmpc_btn_circle_loader"><CustomCircleLoader/></span>
                            :
                                <span>{DirectMailIcons.saveContinueIcon}</span>
                            }
                            <span>{processFor === 'campaign-setting' ? 'Save setting' : 'Send'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default ReadyToSend;