import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { RightSideStyle } from "../RightSideStyle";
import PhoneIcon from "@material-ui/icons/Phone";
import { formatPhoneNumber, setNumberType } from "../../../../helpers/Utils";
import {
  updateAdditionalContactApi,
  updateContactDetailsV2,
} from "../../../../api/contactApi";
import { updateMultipleData } from "../../../../actions/contactAction";
import { connect } from "react-redux";
import {
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
  PHONE_TYPES,
} from "../helper/core_constant";

const buttonSize = makeStyles({
  fixedWidth: {
    "&:.MuiButtonBase-root": {
      minWidth: "160px !important",
      height: "30px !important",
    },
    justifyContent: "left",
    width: "160px",
    borderRadius: "20px !important",
    background: "#316aff !important",
  },
});

const BasicInputSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    color: "var(--dark_blue)",
    fontSize: "13px",
    margin: "0 !important",
    height: "22px !important",
    padding: "6px 12px 6px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

const EachPhoneNumber = ({
  contact = null,
  value,
  numberState,
  id = null,
  index = null,
  contactAdditionalContacts,
  updateMultipleData,
  contactAdditionalContact,
}) => {
  const { flexCenter, phoneButtonRounded } = RightSideStyle();
  const { fixedWidth } = buttonSize();

  const onSaveDefaultNumber = async (phoneType) => {
    try {
      let response = await updateContactDetailsV2({
        contactId: contact?.id,
        params: {
          number: value,
          additional_informations: {
            number_state: phoneType,
          },
        },
      });
      if (response.success) {
        resetProcess({
          number: value,
          number_state: phoneType,
          isDefault: true,
        });
      } else {
        window.showNotification(
          "Error",
          "Unable to update data. Contact with administrator."
        );
      }
    } catch (er) {
      window.showNotification(
        "Error",
        "Unable to update data. Contact with administrator."
      );
    }
  };

  const onUpdateAdditionalNumber = async (phoneType) => {
    try {
      let response = await updateAdditionalContactApi({
        id: id,
        contact_id: contact?.id,
        number: value,
        number_state: phoneType,
        first_name: "---",
        last_name: "---",
        contact_relation: "OTHERS",
        label: "---",
      });

      if (response && response.data) {
        let data = response.data;
        if (data && data.status === "success") {
          let tempAddi = [...contactAdditionalContacts];
          tempAddi[index].number = value;
          tempAddi[index].number_state = phoneType;
          updateMultipleData({ contactAdditionalContacts: tempAddi });
        } else {
          window.showNotification(
            "Error",
            "Unable to update data. Contact with administrator."
          );
        }
      }
    } catch (er) {
      console.log(er);
      window.showNotification(
        "Error",
        "Unable to update data. Contact with administrator."
      );
    }
  };

  const resetProcess = (payload = null) => {
    let tmpContact = { ...contact };

    if (payload && payload.number) {
      tmpContact.number = payload.number;
    }

    if (payload && payload.isDefault) {
      if (!tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations = {};
      }

      if (tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations.number_validation_status =
          "NOT_VERIFIED";
        tmpContact.contact_additional_informations.number_validation_infos =
          null;

        tmpContact.contact_additional_informations.dnc_status = "NOT_VERIFIED";
        tmpContact.contact_additional_informations.dnc_validation_infos = null;

        if (payload && payload.number_state) {
          tmpContact.contact_additional_informations.number_state =
            payload.number_state;
        }
      }
    }

    updateMultipleData({ contact: tmpContact });
  };

  const callNumber = (number) => {
    let tmpContact = contact;
    tmpContact.number = number;
    window.initiateGlobalDialer(tmpContact, null);
  };

  return (
    <Box className={flexCenter} sx={{ gap: 10 }}>
      <Box className={flexCenter} sx={{ flex: 1, gap: 10, flexWrap: "wrap" }}>
        <Box>
          <Button
            className={`${phoneButtonRounded} ${fixedWidth}`}
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            startIcon={<PhoneIcon style={{ marginRight: "-11px" }} />}
            onClick={() => callNumber(value)}
          >
            <Typography style={{ fontSize: "13px" }}>
              {formatPhoneNumber(value)}
            </Typography>
          </Button>
        </Box>

        <Box sx={{ flex: 1 }}>
          <FormControl fullWidth>
            <Select
              labelId="phone-type-label"
              id="phone-type"
              value={setNumberType(numberState)}
              displayEmpty
              onChange={async (e) => {
                if (id) {
                  await onUpdateAdditionalNumber(e.target.value);
                } else {
                  await onSaveDefaultNumber(e.target.value);
                }
              }}
              input={<BasicInputSelect />}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {PHONE_TYPES.map((phoneType) => (
                <MenuItem
                  className="dropdownhelper-menuitem-class"
                  value={phoneType.value}
                >
                  {phoneType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/*<Box className={flexCenter} sx={{ gap: 5 }}>*/}
      {/*    <CarrierLookup*/}
      {/*        lookupType={LOOKUP_TYPE_DNC_LOOKUP}*/}
      {/*        validator={false}*/}
      {/*        additional={contactAdditionalContact ? { ...contactAdditionalContact, ogIndex: index } : null}*/}
      {/*    />*/}
      {/*    <CarrierLookup*/}
      {/*        lookupType={LOOKUP_TYPE_CARRIER_LOOKUP}*/}
      {/*        validator={false}*/}
      {/*        additional={contactAdditionalContact ? { ...contactAdditionalContact, ogIndex: index } : null}*/}
      {/*    />*/}
      {/*</Box>*/}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
    contactAdditionalContacts:
      state.addContactReducer.contactAdditionalContacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EachPhoneNumber);
