import { useEffect, useRef, useState } from 'react';
import './drag-drop.css'
import DeleteIcon from '@material-ui/icons/Delete';

const dragIcon = (<svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.2546 29.5525H24.7895H23.0486H22.6727V20.8807H25.5088C26.228 20.8807 26.653 20.0634 26.228 19.4749L20.7111 11.8411C20.3596 11.3507 19.6322 11.3507 19.2808 11.8411L13.7638 19.4749C13.3388 20.0634 13.7556 20.8807 14.483 20.8807H17.3192V29.5525H16.9432H15.2023H7.7074C3.41643 29.3155 0 25.3024 0 20.9543C0 17.9547 1.62648 15.3392 4.0376 13.9252C3.81692 13.3286 3.70249 12.6911 3.70249 12.0209C3.70249 8.95589 6.17899 6.47939 9.24397 6.47939C9.90601 6.47939 10.5435 6.59381 11.1402 6.81449C12.9138 3.05478 16.7389 0.44751 21.1851 0.44751C26.9391 0.455683 31.6796 4.86108 32.219 10.4761C36.6408 11.2362 40 15.331 40 19.9653C40 24.9183 36.1422 29.2093 31.2546 29.5525Z" fill="#3C7EF3"/>
                </svg>
)

const CustomDragAndDrop = ({storeImage, ratio = null, ratioMessage="", imagePath = ""}) => {
    const imagePeviewRef = useRef(null)
    const inputImageRef = useRef(null)
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [hints, setHints] = useState('')

    useEffect(() => {
        addEventListener();
        if(imagePath !== ""){
            imagePeviewRef.current.src = imagePath;
            setIsShowPreview(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addEventListener = () => {
        const image_drop_area = document.querySelector("#pcddc");
        // Event listener for dragging the image over the div
        image_drop_area.addEventListener('dragover', (event) => {
            event.stopPropagation();
            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';
            image_drop_area.className = "pypepro__custom__drag__drop__container pypepro__draging__container";
        });

        image_drop_area.addEventListener('dragleave', (event) => {
            event.stopPropagation();
            event.preventDefault();
            image_drop_area.className = "pypepro__custom__drag__drop__container";
        });

        // Event listener for dropping the image inside the div
        // image_drop_area.addEventListener('drop', (event) => {
        //     event.stopPropagation();
        //     event.preventDefault();
        //     image_drop_area.className = "pypepro__custom__drag__drop__container";
        //     readImage(event.dataTransfer.files[0]);
        // });
    }

    const readImage = (file) => {
        if(!file.type.includes("image")){
            setHints("Please upload a valid file !")
            return
        }
        if(file.size > 5*1024*1024){
            setHints("Max file size limit 5MB !")
            return
        }
        setHints("")
        storeImage({
            uploadData: file
        })
        let _URL = window.URL || window.webkitURL;
        let _img = new Image();
        _img.onload = function() {
            if(ratio.width !== this.width || ratio.height !== this.height){
                setHints(ratioMessage)
                setIsShowPreview(false)
                imagePeviewRef.current.src= "";
                inputImageRef.current.value = ""
                storeImage({
                    uploadUrl: "",
                    uploadData: null
                })
                return
            }else{
                setHints("")
                storeImage({
                    uploadData: file
                })
                var reader = new FileReader();
                imagePeviewRef.current.title = file.name;
                reader.onload = function(event) {
                    imagePeviewRef.current.src = event.target.result;
                    setIsShowPreview(true);
                };
                reader.readAsDataURL(file);
            }
        };
        _img.onerror = function() {
            setHints("not a valid file: " + file.type)
            console.log( "not a valid file: " + file.type);
        };
        _img.src = _URL.createObjectURL(file);


        // var reader = new FileReader();
        // imagePeviewRef.current.title = file.name;
        // reader.onload = function(event) {
        //     console.log(event)
        //     imagePeviewRef.current.src = event.target.result;
        //     setIsShowPreview(true);
        // };
        // reader.readAsDataURL(file);
    }

    const handleUploadClick = () => {
        inputImageRef.current.click();
    }
    
    const handleFileChange = (event) => {
        if(event.target.value !== ""){
            let file = event.target.files[0];
            if(!file.type.includes("image")){
                setHints("Please upload a valid file !")
                return
            }
            if(file.size > 5*1024*1024){
                setHints("Max file size limit 5MB !")
                return
            }

            if (file && ratio != null) {
                let _URL = window.URL || window.webkitURL;
                let _img = new Image();
                _img.onload = function() {
                    if(ratio.width !== this.width || ratio.height !== this.height){
                        setHints(ratioMessage)
                        setIsShowPreview(false)
                        imagePeviewRef.current.src= "";
                        inputImageRef.current.value = ""
                        storeImage({
                            uploadUrl: "",
                            uploadData: null
                        })
                        return
                    }else{
                        setHints("")
                        storeImage({
                            uploadData: file
                        })
                        var reader = new FileReader();
                        imagePeviewRef.current.title = file.name;
                        reader.onload = function(event) {
                            imagePeviewRef.current.src = event.target.result;
                            setIsShowPreview(true);
                        };
                        reader.readAsDataURL(file);
                    }
                };
                _img.onerror = function() {
                    console.log( "not a valid file: " + file.type);
                };
                _img.src = _URL.createObjectURL(file);
            }
            else{
                setHints("")
                storeImage({
                    uploadData: file
                })
                var reader = new FileReader();
                imagePeviewRef.current.title = file.name;
                reader.onload = function(event) {
                    imagePeviewRef.current.src = event.target.result;
                    setIsShowPreview(true);
                };
                reader.readAsDataURL(file);
            }
        }
    }

    const handleDeleteImage = () => {
        setIsShowPreview(false)
        imagePeviewRef.current.src= "";
        inputImageRef.current.value = ""
        storeImage({
            uploadUrl: "",
            uploadData: null
        })
    }

    const dropListner = (event) => {
        event.stopPropagation();
        event.preventDefault();
        event.currentTarget.classList.remove('pypepro__draging__container');
        const eventType = event.type; //drop
        if(eventType === 'drop'){
            readImage(event.dataTransfer.files[0]);
            event.dataTransfer.clearData();
        }
    }

    return (
        <div className="pypepro__custom__drag__drop__container" id="pcddc" onDrop={dropListner}>
            <div className='pcddc__upload__action' onClick={handleUploadClick}>
                <span className='pcddc__upload__action_img'>{dragIcon}</span>
                <span className='pcddc__upload__action_title'>Add Photo</span>
                <span className='pcddc__upload__action_subtitle'>OR Drag & Drop files here</span>
            </div>
            <div className={`pcddc__image_preview__container ${isShowPreview ? '' : 'pcddc__hide'}`}>
                <img 
                    src={''}
                    alt={'upload preview'}
                    ref={imagePeviewRef}
                    className={'pcddc__ipc__image'}
                />
                <div className='pcddc__ipc__delete' onClick={handleDeleteImage}>
                    <DeleteIcon fontSize='medium' color={'error'} />
                </div>
            </div>
            <input 
                type={'file'}
                ref={inputImageRef}
                className='pcddc__input__field__file'
                onChange={handleFileChange}
                accept="image/*" 
            />
            {hints !== '' &&
            <div className='pcddc__show__hints'>
                {hints}
            </div>
            }
        </div>
    );
}
export default CustomDragAndDrop;