import React from "react";
import { Box } from "@material-ui/core";
import { RightSideStyle } from "../../RightSideStyle";
import LeadOwner from "./LeadOwner";
import Styles from "./leadstatus.module.css";
import LeadType from "./LeadType";
export const LeadStatus = ({ contactDetails }) => {
  const { alignJustifyCenter } = RightSideStyle();
  return (
    <Box className={Styles.leadstatus} sx={{ gap: "10px", my: 2 }}>
        <LeadOwner contactDetails={contactDetails} />
        <LeadType contactDetails={contactDetails} />
      
    </Box>
  );
};
