import React from "react";
import EmailSend from "../../SendModules/email";
import ModalGlobalModal from "../ModalGlobalModal";

const EmailSendModal = (props) => {
    return(
        <ModalGlobalModal
            open={props.open}
            title="Send Email"
            onClose={() => props.onClose(false)}
            width={800}
            hideFooter={true}
            className="global_modal_overflow_hidden activity__modal_overflow v2__send_email"
        >
            <EmailSend
                showTimeSetting = {true}

                showSelectedContact= {false}
                selectedContactItem= {(<div>contact information</div>)}

                contact_id={props.data.id}
                contact_data={props.data}

                primary_email={props.data.email}
                onClose={() => props.onClose(false)}
                onSubmit={()=>props.onSubmit!== undefined ? props.onSubmit() : console.log("submit") }
            />
        </ModalGlobalModal>
    )

}
export default EmailSendModal;