import moment from "moment-timezone";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { appendLeadNotification, closeLeadNotification, fetchLeadNotification } from "../../actions/notificationAction";
import { updateLeadNotificationApi } from "../../api/notificationApi";
import Utils from "../../helpers/Utils";
import OnScrollFetchBox from "../common/OnScrollFetchBox";
import "./Notifications.css"
import { RenderSkeleton } from "./RenderSkeleton";

const LeadNotifications = props => {

    const dispatch = useDispatch();
    const { leadNotifications, leadNotificationsLoaded } = useSelector((state) => state.notificationReducer);
    

    useEffect(() => {
        if(!leadNotificationsLoaded) {
            dispatch(fetchLeadNotification());
        }
    },[]);

    const handleFetchSiteNotification = () => {
        dispatch(appendLeadNotification())
    }

    const closeNotification = (id,index) => {
        dispatch(closeLeadNotification({
            index : index,
            notification_id : id,
            seen : 2
        }))
    }

    const handleEachItemOnClick = (index, item) => {
        props.closePopup()
        leadNotifications[index].seen = 1
        updateNotificationStatus(item, 1)
    }

    const updateNotificationStatus = (item, status = 1) => {
        updateLeadNotificationApi({
            id: item.id,
            status: status
        }).then(res => {
            let response = res.data;
            if(response.status === "success"){
                window.updateNotificationCount();
            }
        })
    }
   
      

    
    const renderLeadNotifications = () => {
        if(!leadNotifications) {
           return (
            <div className="notification-skeleton-parent">
                <RenderSkeleton/>
            </div>
           );
        } else {
            if(leadNotifications.length===0){
                return(
                    <div className="notification-skeleton-parent">
                         <div className="no-notification">No notifications</div>
                    </div>
                );
            } else{
                return leadNotifications.map((notification,index) => {

            const fullName = notification.contact ? notification.contact.first_name + ' ' + notification.contact.last_name : '';
            const number = notification.contact ? notification.contact.number : '';
            const email = notification.contact ? notification.contact.email : '';
            let message = notification.message.replace(/<head>[\s\S]*?<\/head>/, '').replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;|&lt;|&gt;|&amp;|&quot;|&apos;|&cent;|&pound;|&yen;|&euro;|&copy;|&reg;|\\n/g, '').trim();
            message = message.length > 200 ? message.slice(0, 200)+'...' : message;

            let usageDate = '';
            if(notification.created_at !== undefined){
                let userTimeZone = Utils.getAccountData('userTimezoneRaw');
                try{
                    usageDate = moment.utc(notification.created_at).tz(userTimeZone).format('lll');

                }catch(e){

                    console.log(e);
                }
            }

            return <div key={index} className={`people-list-items d-flex align-items-center ${notification.seen === 0 ? 'unseen' : ''}`}>
            <Link to={"/contacts/"+notification.contact.id} onClick={e => handleEachItemOnClick(index, notification)} className="d-flex align-items-center"><span
                className="avatar d-flex align-items-center justify-content-center">{Utils.getInitial(fullName)}</span>
                <div className="notification-name">
                    <p className="name"> {Utils.getContactDisplayName(fullName,number,email,true)}
                        <span className="notification-time ml-5">{usageDate}</span>
                    </p>
                    <p className="title">{ message }</p>
                </div>
                <div className="notification-time" onClick={e => {e.preventDefault();e.stopPropagation(); closeNotification(notification.id,index)}}>
                    <svg width="21" height="21" viewBox="0 0 21 21"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.3827 16.6369L16.3932 14.625L6.13544 4.36054L4.12492 6.37239L14.3827 16.6369Z"
                            fill="#FF264A"/>
                        <path
                            d="M6.13544 16.6394L16.3932 6.37491L14.3827 4.36306L4.12492 14.6275L6.13544 16.6394Z"
                            fill="#FF264A"/>
                    </svg>
                </div>
            </Link>
        </div>
        })
        }
        }

        
    }

    return ( 
        <OnScrollFetchBox className="people-list-content awesome__scroll_bar" scrollCallBack={handleFetchSiteNotification}>
            {renderLeadNotifications()}
        </OnScrollFetchBox>
    );
}
 
export default LeadNotifications;