const SignUpSuccess = props => {
    return (
        <div className="center-align">
            <div className="congratulations_page">
                <div className="site_logo">
                    <img src={props.logo} alt="Logo" />
                </div>
                <div className="congratularion">
                    <img src={process.env.REACT_APP_CDN_LINK+"asset/images/registration/releases/1.0.0/congratulations.png"} alt="Congratulation" />
                        <h1>Congratulation!</h1>
                        <p> Your Account Is Created Successfully</p>
                        <a href="#!" onClick={e => e.preventDefault()}>Check your email to continue</a>
                </div>
            </div>
        </div>
    );
};

export default SignUpSuccess;