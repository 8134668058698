import { sendRequest } from './rootApi';
import Api from "../constants/Api";
export const setupZenDirect = async (payload) => {
    return sendRequest("get", Api.setupZenDirect, payload);
}

export const getUserCampaignRegistrationStatusApi = async (payload) => {
    return sendRequest("POST", Api.getUserCampaignRegistrationStatus, payload);
}

export const submitForTiktokAuth = async (payload) => {
    return sendRequest("POST", Api.tiktokAuthRequest, payload);
}