import { all, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from '../constants/sendModuleActionTypes'
import * as DASHBOARD_API from "../api/sendModulesApi";
import Utils from "../helpers/Utils";

function* sendModuleWatcher() {
    yield takeEvery(ACTION.FETCH_CUSTOM_FIELD, customFieldDataMiddleware)
}

function* customFieldDataMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(DASHBOARD_API.fetchCustomFieldApi, action.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* sendModuleMiddleware() {
    yield all([
        sendModuleWatcher()
    ])
}