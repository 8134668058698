import { Button } from "@material-ui/core";
import { Fragment, useState } from "react"
import { onboardGuideCompleted } from "../../api/onboardApi";
import {useHistory} from "react-router-dom";
import {getCookie, getCookieLogin, setCookie, setCookieLogin} from "../../helpers/Cookie";

const Congratulation = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const completeOnClick = () => {

        if(!props.completed){
            setLoading(true);

            onboardGuideCompleted({
            }).then((response) => {
                setLoading(false);
                if (response.data.status === 'success') {
                    let accountData = null;
                    let check_new = getCookie("encode__process__new")
                    if(check_new !== undefined){
                        accountData = JSON.parse(getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA));
                        accountData['userOnboard'] = false;
                        setCookieLogin(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                        history.push('/');
                    }else{
                        accountData = JSON.parse(getCookie(process.env.REACT_APP_ACCOUNT_DATA));
                        accountData['userOnboard'] = false;
                        setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                        history.push('/');
                    }
                } else {
                  window.showNotification("ERROR", response.data.html)
                }
            });
        }else{
            let accountData = null;
            let check_new = getCookie("encode__process__new")
            if(check_new !== undefined){
                accountData = JSON.parse(getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA));
                accountData['userOnboard'] = false;
                setCookieLogin(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                history.push('/');
            }else{
                accountData = JSON.parse(getCookie(process.env.REACT_APP_ACCOUNT_DATA));
                accountData['userOnboard'] = false;
                setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                history.push('/');
            }
        }

    }


    return (
        <div className="congratulation__step__v2">
            <p className="onboarding__step__one__title_log_out" onClick={props.logout}>Log Out</p>
            <h5>{props.completed ? 'Onboard is completed already' : 'Setup Complete!'}</h5>
            {!props.completed &&
                <div className="mt-5">
                    Welcome
                </div>
            }
            <div className="mt-5 pb-4">
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    className="mr-2" 
                    onClick={completeOnClick}
                    disabled={loading}
                >
                    {props.completed ? "proceed" : "Get Started"}
                </Button>
            </div>
        </div>
    )
}

export default Congratulation;