import { Skeleton } from "@material-ui/lab";
import React from "react";
import graphicon from "./graphicon";

export const ReportModuleSkeleton = () => {
  return (
    <div className="report-skeleton-parent">
      <div className="reeport-child-one d-flex justify-content-between m-2 p-2">
        <div className="d-flex justify-content-start">
          <div>
            <Skeleton variant="text" width={380} height={45} />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-10">
          <div>
            <Skeleton variant="rect" width={115} height={40} />
          </div>
          <div>
            <Skeleton variant="rect" width={115} height={40} />
          </div>
          <div>
            <Skeleton variant="rect" width={115} height={40} />
          </div>
        </div>
      </div>
      <div className="p-2" style={{ width: "100%" }}>
        <Skeleton variant="rect" height={2} />
      </div>
      <div className="report-child-two m-2 p-2">
        <div className="d-flex justify-content-start gap-10">
          <div>
            <Skeleton variant="rect" width={115} height={40} />
          </div>
          <div>
            <Skeleton variant="rect" width={115} height={40} />
          </div>
        </div>
        <div className="graph-icon">{graphicon.graph}</div>
      </div>
      <div className="report-child-three  m-2 p-2">
      {Array.from(Array(2)).map((_, i) => (
      <div key={i} className=" m-2 d-flex justify-content-between">
        {Array.from(Array(7)).map((_, i) => (
          <div key={i}>
            <Skeleton variant="rect" width={160} height={160} />
          </div>
        ))}
      </div>
       ))}
       </div>
      
    </div>
  );
};
