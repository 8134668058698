import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';

const Notification = props => {

  const [exit, setExit] = useState(false);
  const [width, setWidth] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [onHover, setOnHover] = useState(false);

  useEffect(() => {
    if (width === 100) {
      setIsRunning(false);
    }
  }, [width]);


  useEffect(() => {
    let intervalId = null;
    let timeoutId = null;

    if(isRunning) {
      intervalId = setInterval(() => {
        setWidth(prev => {
            if (prev < 100) {
            return prev + 0.5;
          }
          clearInterval(intervalId);
          return prev;
        });
      }, 10);
    } else {
      if(!onHover) {
        setExit(true);
        timeoutId = setTimeout(() => {
          props.dispatch({
            type: props.view === 'single' ? "DELETE_SINGLE_NOTIFICATION" : "DELETE_MULTIPLE_NOTIFICATION",
            id: props.id
          })
        }, 400)
      }
    }

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    }
  },[isRunning,onHover, props]);


  return (
    <div
      onMouseEnter={() => { 
        if(!exit) {
          setIsRunning(false); 
          setOnHover(true);
      } }}
      onMouseLeave={() => {
        if(!exit) {
          setIsRunning(true); 
          setOnHover(false);
        }
      }}
      className={`notification-item ${
        props.type === "SUCCESS" ? "success" : (props.type === "ERROR" ? "error" : props.type === "WARNING" ? 'warning' : 'info') //success, warning, error
      } ${exit ? "exit" : ""}`}
    >
      <InfoIcon className="globalIcon notificationIcon infoIcon" /> 
      <ErrorOutlineIcon className="globalIcon notificationIcon errorIcon" />
      <CheckCircleOutlineIcon className="globalIcon notificationIcon successIcon" />
      <WarningIcon className="globalIcon notificationIcon warningIcon" />
      <div className="notification__message_body">
        <p>{props.message}</p>
      </div>
      <CloseIcon className="timesIcon" onClick={() => { setIsRunning(false); setOnHover(false) }} />
      <div className={"bar"} style={{ width: `${width}%` }} />
    </div>
  );
};
export default Notification;