import { Skeleton } from "@material-ui/lab";
import React from "react";

export const DealModuleSkeleton = () => {
  return (
    <div className="deal-loader-parent">
      {" "}
      {/*parent div*/}
      <div className="deal-child-one d-flex p-2 justify-content-between">
        {" "}
        {/*child 1 div*/}
        <div>
          <Skeleton variant="text" width={40} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" width={200} height={40} />
        </div>
        <div>
          <Skeleton variant="rect" width={128} height={40} />
        </div>
        <div>
          <Skeleton variant="rect" width={72} height={40} />
        </div>
        <div>
          <Skeleton variant="text" width={140} height={20} />
        </div>
        <div>
          <Skeleton variant="text" width={140} height={20} />
        </div>
        <div>
          <Skeleton
            className="borderRadius50px"
            variant="rect"
            width={269}
            height={40}
          />
        </div>
        <div>
          <Skeleton variant="rect" width={35} height={35} />
        </div>
        <div>
          <Skeleton variant="rect" width={35} height={35}/>
        </div>
        <div>
          <Skeleton variant="rect" width={35} height={35} />
        </div>
        <div>
          <Skeleton
            className="borderRadius50-percent"
            variant="circle"
            width={35}
            height={35}
          />
        </div>
        <div>
          <Skeleton
            className="borderRadius50-percent"
            variant="circle"
            width={35}
            height={35}
          />
        </div>
      </div>
      {/* <hr style={{border:"none",borderBottom:"2px solid #e5e8ef"}}></hr> */}
      {/* <Skeleton variant="rect" height={1}/> */}
      <div className="d-flex mt-4">
        <div className="deal-child-two"></div>
        <div className="deal-child-two-middle"></div>
        <div className="deal-child-two-middle"></div>
        <div className="deal-child-two-middle"></div>
        <div className="deal-child-two-end"></div>
      </div>
      <div className="d-flex mt-4 deal-child-three justify-content-between">
      {Array.from(Array(5)).map((_, i) =>
               <div className="loader-box" key={i}>
              
               {Array.from(Array(7)).map((_, i) =>
                 <div className="m-3" key={i}><Skeleton variant="rect" height={60}/></div>
               )}
             </div>
           )}
        
      </div>
    </div>
  );
};
