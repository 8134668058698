import React, { useState, useEffect } from "react";
import moment from "moment";
import UtilsAddDeal, { handlePrototype } from "./assets/_UtilsAddDeal";
import ApiListAddDeal from "./assets/_ApiListAddDeal";
import { Messsages } from "./assets/_GlobalAddDealConstants";
import SelectExistingDeal from "./SelectExistingDeal";
import CreateNewDeal from "./CreateNewDeal";
import PipelineInformaiton from "./PipelineInformaiton";
import "./css/_global_new_deal.css";

const GlobalAddDeal = (props) => {
  const [states, setStates] = useState({
    /* pipeline information */
    stage: "",
    estimatedClosingDate: new Date(),
    dealValue: 0,
    title: "",

    /* contact information */
    contact_id: "",

    number: "",
    email: "",
    source: "",
    firstName: "",
    lastName: "",
    defaultLeadSource: "",

    /* submitting */
    submitting: false,
    newDeal: false,
  });

  const [pipelineList, setPipelineList] = useState([]);
  const [leadSource, setLeadSource] = useState([]);

  useEffect(() => {
    handlePrototype();
    ApiListAddDeal.getAllPipelineV2({ pipelineId: "" }).then((res) => {
      let response = res.data;
      setPipelineList(response.data);
    });
    ApiListAddDeal.getLeadSource().then((res) => {
      let response = res.data;
      updateStateData({
        defaultLeadSource: response.selectedSource,
      });
      setLeadSource(response.leadSources);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateStateData = (objectData) => {
    return setStates({ ...states, ...objectData });
  };

  const handleSubmit = () => {
    if (states.submitting) {
      return;
    }

    if (states.newDeal) {
      if (states.email.trim() === "" && states.number.trim() === "") {
        window.showNotification("ERROR", Messsages.addNewDealRequired);
        return false;
      } else if (states.email.trim() !== "" && !UtilsAddDeal.validateEmail(states.email)) {
        window.showNotification("ERROR", Messsages.inValidEmail);
        return;
      } else if (!states.number.validateMobileNumber()) {
        window.showNotification("ERROR", Messsages.invalidNumber);
        return;
      }
    } else {
      if (states.contact_id === "") {
        window.showNotification("ERROR", Messsages.noContactSelect);
        return;
      }
    }

    if (states.stage === "") {
      window.showNotification("ERROR", Messsages.noStageSelect);
      return;
    }

    if (
      states.estimatedClosingDate &&
      !moment(states.estimatedClosingDate).isValid()
    ) {
      window.showNotification("ERROR", "Invalid Estimated Closing Date!");
      return;
    }

    updateStateData({ submitting: true });

    let formData = {
      closing_date: !states.estimatedClosingDate
        ? ""
        : moment(states.estimatedClosingDate).format("MMM DD, YYYY"),
      deal: states.dealValue.toString(),
      stage: states.stage.toString(),
      title: states.title,
    };

    if (!states.newDeal) {
      formData["contact_id"] = states.contact_id;
      formData["createNewContact"] = false;
    } else {
      formData["createNewContact"] = true;
      formData["selectedSource"] = states.source;
      formData["first_name"] = states.firstName;
      formData["last_name"] = states.lastName;
      formData["number"] = states.number;
      formData["email"] = states.email;
    }
    ApiListAddDeal.addDealSubmit(formData).then((res) => {
      let response = res.data;
      if (response[0].status === "success") {
        window.showNotification("SUCCESS", response[0].html);
        updateStateData({ submitting: false });
        if (props.onClose !== undefined) {
          props.onClose();
        }
        if (props.callBack !== undefined) {
          props.callBack();
        }
      }
    });
  };

  return (
    <div className="global_add_deal_module_container activity__modal_overflow">
      <div className={states.newDeal ? "crate__add__deal__btn__wrapper__back": "crate__add__deal__btn__wrapper__create"}>
        <div
          className="btn crate__add__deal__btn__container accent--bg--color "
          onClick={() => updateStateData({ newDeal: !states.newDeal })}
        >
          {states.newDeal ? "<< Back" : <span><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16 10c0 .553-.048 1-.601 1H11v4.399c0 .552-.447.601-1 .601-.553 0-1-.049-1-.601V11H4.601C4.049 11 4 10.553 4 10c0-.553.049-1 .601-1H9V4.601C9 4.048 9.447 4 10 4c.553 0 1 .048 1 .601V9h4.399c.553 0 .601.447.601 1z"/></svg>create new</span>}
        </div>
      </div>

      {!states.newDeal && (
        <SelectExistingDeal handleExistingSelect={updateStateData} />
      )}

      {states.newDeal && (
        <CreateNewDeal
          prevStates={states}
          leadSources={leadSource}
          handleUpdateState={updateStateData}
        />
      )}

      <PipelineInformaiton
        pipelines={pipelineList}
        prevStates={states}
        handleUpdateState={updateStateData}
      />

      <div
        className="btn btn-primary add__bottom__btn__container accent--bg--color"
        onClick={handleSubmit}
      >
        {states.submitting ? "Adding" : "Add"} Deal
      </div>
    </div>
  );
};
export default GlobalAddDeal;
