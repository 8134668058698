import {useContext} from 'react'
import TimezoneConversation from "../components/Accordion/TimezoneConversion"
import ContactHelper from "../Helper"
import { icons } from "../icons"
import { ContactDetailContext } from "../reducer/GlobalReduers"
import Utils from "../../../helpers/Utils";


export const NoteItem = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const getUserTimezone = ContactHelper.getAccountData('userTimezoneRaw')
    let userTimezone = (getUserTimezone && getUserTimezone != null) ? getUserTimezone : 'UTC'
  
    return(
        <div className="g-contact-note-each-content" key={props.key}>
            <div className="g-note-list-header-contect">
                <span className="g-note-left-icon">{icons.editIcon}</span>
                <div className="g-note-header">
                    {props.data.user.full_name}
                    {
                        Utils.getAccountData("note_edit_access") == "1" ? (
                            <span className="g-note-edit-icon" onClick={() => props.updateNote(props.data)}>
                                {icons.editIcon} Edit
                            </span>
                        ) : null
                    }
                </div> 
                <span className="g-note-time">{TimezoneConversation(props.data.created_at, 'UTC', userTimezone, 'LLL')}</span>
            </div>
            <div className="g-note-message" dangerouslySetInnerHTML={{ __html: ContactHelper.getHtmlText(props.data.message) }} />
        </div>
    )
}