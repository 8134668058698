import React, {useState} from "react";
import {
    clearDialNumber,
    addDialNumber
} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";

const NumberPad = props => {
    const [digits, setDigits] = useState('');

    const clearDialNumber = () => {
        if (digits.length) {
            setDigits('');
        }
    }

    const onChangeHandle = (digit) =>{
        setDigits(digit);
    }

    const sendDigits = (e,digit) => {
        e.preventDefault();
        setDigits(digits + digit);

        props.sendDigit(digit);
    }

    return (
        <React.Fragment>
            <div className="dial-input-wrapper">
                <input autoFocus type="text" placeholder="Press key" value={digits} readOnly={true} onChange={(e) => onChangeHandle(e.target.value)}/>
                <span style={{cursor: 'pointer'}} onClick={ (e) => {e.preventDefault();clearDialNumber()}}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.8542 12.2483L12.2461 10.8555L5.14458 3.74929L3.75267 5.14211L10.8542 12.2483Z"
                            fill="white"/>
                        <path
                            d="M5.14458 12.2507L12.2461 5.14453L10.8542 3.75171L3.75267 10.8579L5.14458 12.2507Z"
                            fill="white"/>
                    </svg>
                </span>
            </div>
            <div className="numbers-pad tabs-content">
                <div className="active" id="contact-number-pad">
                    <div className="pad-number">
                        <a className="btn d-flex justify-content-center align-items-center" onClick={(e) => sendDigits(e,'1')} href="#!">1</a>
                        <a className="btn" onClick={(e) => sendDigits(e,'2')} href="#!">2 <span>ABC</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'3')} href="#!">3 <span>DEF</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'4')} href="#!">4 <span>GHI</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'5')} href="#!">5 <span>JKL</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'6')} href="#!">6 <span>MNO</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'7')} href="#!">7 <span>PQRS</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'8')} href="#!">8 <span>TUV</span></a>
                        <a className="btn" onClick={(e) => sendDigits(e,'9')} href="#!">9 <span>WXYZ</span></a>
                        <a className="btn d-flex justify-content-center align-items-center" onClick={(e) => sendDigits(e,'*')} href="#!">*</a>
                        <a className="btn" onClick={(e) => sendDigits(e,'0')} href="#!">0 <span>+</span></a>
                        <a className="btn d-flex justify-content-center align-items-center" onClick={(e) => sendDigits(e,'#')} href="#!">#</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        dialNumber: state.dialNumber
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearDialNumber: () => dispatch(clearDialNumber()),
        addDialNumber: (dialNumber) => dispatch(addDialNumber(dialNumber)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberPad);
