const Error = () => {
    return (
        <div className="chatbox-area chatbox-empty" id="cutomScrollbar2" style={{'marginTop': '30px'}}>
            <div className="row">
                <div className="col s12">
                    <div className="no-data-found" style={{'textAlign': 'center'}}>
                        <img className="responsive-img" src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/no-data-found.jpg`} alt="No Data Found"/>
                        <h3>This page is on maintenance!</h3>
                        <p>We are coming back soon<br /> Please, try again after sometime</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;