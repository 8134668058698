import { useEffect, useRef, useState } from "react";
import { Backdrop, CircularProgress } from '@material-ui/core';
import { getAgencyApiDomain } from "../../../api/publicDocApi";


const ApiDoc = () => {

    const [loading, setLoading] = useState(true)
    const windowInnerHeight = window.innerHeight;
    const windowHeight = window.outerHeight;
    let iframeHeight = windowHeight - 120;

    if (windowInnerHeight > windowHeight - 120) {
        iframeHeight = windowInnerHeight;
    } else {
        iframeHeight = windowHeight - 120;
    }
    const iframeRef = useRef();

    useEffect(() => {
        // Fetch your HTML file

        let host = window.location.host;

        fetch('/api-doc.html')
            .then(response => response.text())
            .then(rawHtml => {
                // Replace dynamic values in the HTML content
                getAgencyApiDomain({ domain: host }).then(response => {
                    let address = 'https://api.pypepro.io';
                    if (response.data.success && response.data.apiDomain) {

                        host = response.data.apiDomain;
                        address = window.location.protocol + '//' + host;
                        
                    }
                    rawHtml = rawHtml.replace(/\[white-level-domain\]/g, address);
                        setLoading(false)
                        // Inject the HTML content into the iframe
                        if (iframeRef.current) {
                            const iframeDocument = iframeRef.current.contentDocument;
                            iframeDocument.open();
                            iframeDocument.write(rawHtml);
                            iframeDocument.close();
    
                            // Set the initial height
                            // Update the height when the window is resized
                        }
                    
                });
            })
            .catch(error => console.error('Error fetching HTML:', error));

        // Clean up the event listener when the component is unmounted
    }, []);

    return (
        <>
            {loading ?
                <Backdrop open={loading} height={iframeHeight} width="100%">
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <iframe ref={iframeRef} title="Api Documentation" width="100%" height={iframeHeight} />

            }
        </>

    );
}

export default ApiDoc;