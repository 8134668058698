import './not_found.css';

const NotFound = () => {
    return (
        <div className="not__found__page">
            <p>Oops! Something went wrong here</p>
        </div>
    );
}

export default NotFound;