import { makeStyles, Tooltip } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black + "!important",
    },
    tooltip: {
      backgroundColor: theme.palette.common.black + "!important",
      fontSize:12+"px!important"
    },
}));

const BootstrapTooltip = (props) => {

    const classes = useStylesBootstrap();
  
    return <Tooltip classes={classes} {...props} />;
}
 
export default BootstrapTooltip;