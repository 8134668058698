import Utils from "../../../helpers/Utils";
import React, {useEffect, useRef, useState} from "react";
import {getConverstionDataApi, loadVideoData} from "../../../api/previewEmailApi";
import Api from "../../../constants/Api";
import Loading from "../../common/Loading";
import VideoPlayer from "./VideoPlayer";
import {Helmet} from "react-helmet";
import ChatConversation from "./chat-conversation/ChatConversation";

const PreviewVideoEmail = props => {
    const [state, setState] = useState({
        user          : null,
        video_link     : null,
        isLoading     : true,
        showErrorPage : false,
        replyText     : '',
        videoId       : props.match.params.videoId,
        contactId     : props.match.params.contactId,
        userId        : props.match.params.userId,
        subject       : null,
        isSendingReply: false
    });
    const [chatData, setChatData] = useState([])
    const [page, setPage] = useState(0)
    const [loadingData, setLoadingData] = useState(true)
    const [lastChatId, setLastChatId] = useState(null)

    const fileUploadInputRef = useRef(null);

    useEffect(() => {
        loadVideoData({videoId: state.videoId, userId: state.userId, contactId: state.contactId}).then(response => {
            if (response.data.status) {
                sessionStorage.setItem('isVisited', 'true');
                setState({
                    ...state,
                    video_link: response.data.video_link,
                    isLoading: false,
                    subject: state.contactId ? response.data.conversation.message_subject : null,
                    user: {
                        'user_id': response.data.user.id,
                        'user_name': response.data.user.full_name,
                        'company':  response.data.user.company_name ? response.data.user.company_name : '',
                        'mobile': response.data.user.phone ? response.data.user.phone : '',
                        'email': response.data.user.email ? response.data.user.email : '',
                        'signature': response.data.user.signature ? response.data.user.signature : '',
                        'logo': response.data.user.logo ? response.data.user.logo : '',
                    }
                });
            } else {
                setState({
                    ...state,
                    isLoading: false,
                    showErrorPage: true
                });
            }
        })
        getChatConversationData()
    }, []);
    const getChatConversationData = (current_page = 0) => {
      setLoadingData(true)
      getConverstionDataApi({
        user_id: state.userId,
        contact_id: state.contactId,
        // page_no: current_page, 
        page_no: 0, 
        last_chat_id: lastChatId
      }).then(res => {
        let response = res.data;
        if(response.status === "success"){
          const length = response.message.length;
          const res_data = response.message;
          const modified_data = res_data.map((item, index) => {
            let style_type = 'single';
            if(index === 0){
              if((index+1) < length && res_data[index+1].in_out === item.in_out){
                style_type = 'start'
              }
              const old_list_length = chatData.length;
              if(old_list_length > 0){
                if(chatData[old_list_length - 1].in_out === item.in_out){
                  if(chatData[old_list_length - 1].styleType === 'single'){
                    chatData[old_list_length - 1].styleType = 'start'
                  }else if(chatData[old_list_length - 1].styleType === 'end'){
                    chatData[old_list_length - 1].styleType = 'wrap'
                  }
                  
                  if((index+1) < length && res_data[index+1].in_out === item.in_out){
                    style_type = 'wrap'
                  }
                  else{
                    style_type = 'end'
                  }
                }
              }
            }
            else if((index+1) === length){
              if(res_data[index-1].in_out === item.in_out){
                style_type = 'end'
              }
            }
            else{
              const before = res_data[index-1].in_out;
              const after = res_data[index+1].in_out;
              if(item.in_out === before && item.in_out === after){
                style_type = 'wrap'
              }
              else if(item.in_out === before){
                style_type = 'end'
              }
              else if(item.in_out === after){
                style_type = 'start'
              }
            }
            return {
              ...item,
              styleType: style_type
            }
          })
          // setChatData([...chatData, ...response.message])
          setChatData([...chatData, ...modified_data])
          try{
            if(typeof response.message === 'object' && response.message.length === 0){
              setPage(current_page-1)
            }
            else{
              setLastChatId(response.message[response.message.length - 1].id)
            }
          }
          catch(error){}
        }
        setLoadingData(false)
      }).catch(error => {setLoadingData(false)})
    }
    const handleLoadMore = (e) => {
      let current_page = page + 1;
      setPage(current_page)
      getChatConversationData(current_page)
    }

    const triggerFileUpload = () => {
        fileUploadInputRef.current.click();
    }

    const fileUploadOnChangeHandler = (e) => {
        let file = document.querySelector('#file').files[0];
        let preview = document.getElementById("resize_upload_img");

        if (e.target.value) {
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                preview.src = reader.result;
                document.getElementById('file-preview-holder').classList.remove("d-none");
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        }

    }

    const removeAttachment = () => {
        document.getElementById("file").value = "";
        document.getElementById("resize_upload_img").src = '';
        document.getElementById('file-preview-holder').classList.add("d-none");
    }

    const onChangeHandle = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name] : value
        });
    };

    const messageSend = () => {
        const { replyText, videoId, userId, contactId, subject } = state;
        if (typeof state.contactId === 'undefined') {
            Utils.showNotification('Reply can\'t be sent from preview page', 'Error');
            return;
        }

        if (replyText != '') {
            let formData = new FormData();
            formData.append('replyText', replyText);
            formData.append('videoId', videoId);
            formData.append('userId', userId);
            formData.append('contactId', contactId);
            formData.append('subject', subject);
            if (document.querySelector('#file').files[0]) {
                formData.append('qqfile', document.querySelector('#file').files[0]);
            }

            setState({
                ...state,
                isSendingReply: true
            });

            fetch(Api.replyVideoEmail,{
                method: 'POST',
                body: formData
            }).then(response => {
                response.json().then(data => {
                    if (data.status) {
                        setState({
                            ...state,
                            replyText: '',
                            isSendingReply: false
                        });
                        Utils.showNotification('Reply is sent.', 'Success');

                        removeAttachment();
                        getChatConversationData()
                        // setChatData([...chatData, ...[data.fulldata]])
                    } else {
                        Utils.showNotification(data.html, 'Error');
                    }
                });
            }).catch(err => {
                setState({
                    ...state,
                    replyText: '',
                    isSendingReply: false
                });
                Utils.showNotification('Server Error', 'Error');
            });
        } else {
            Utils.showNotification("Reply can't be empty", 'Error');
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={Utils.getAccountData('favIcon')} />
                <title>Preview & Reply Video Email</title>
                <meta name="description" content="All-In-One Sales & Marketing Platform" />
                <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${Utils.getAccountData('AuthUserAgencyName')}`} />
                <meta name="author" content={Utils.getAccountData('AuthUserAgencyName')} />
            </Helmet>
            {
                (state.user && state.user.logo) ?
                <div className="mx-auto">
                    <div className="row">
                        <div className="col-md-12 text-center my-2" style={{textAlign: 'center'}}>
                            <a href="#" className="d-flex justify-content-center">
                                <img src={state.user.logo} alt="LOGO" style={{maxWidth: '220px'}} />
                            </a>
                        </div>
                    </div>
                </div> : <></>
            }

            <div className="video-landing-page-wrapper">
                <div className="container video-landing-page-container">
                    {state.isLoading  ?
                        <Loading />
                        :
                        <React.Fragment>
                            {state.showErrorPage ?
                                <div className="text-center mt-2 noDataFound">
                                    <img src={`${window.location.origin}/assets/images/preview-email/empty-conversation.png`} alt='Video Not Found'/>
                                    <h5 className="mt-5 mb-5">Data Not Found</h5>
                                </div>
                                :
                                <React.Fragment>
                                    {(state.user && state.user.user_id && state.video_link) ?
                                        <div className="row">
                                            <div className="col s12">
                                                <VideoPlayer src={state.video_link}/>
                                            </div>
                                            <div className="col s12 mt-4">
                                              <ChatConversation 
                                                data={chatData}
                                                loading={loadingData}
                                                onLoadMore={handleLoadMore}
                                              />
                                            </div>
                                            <div className="col s12">
                                                <div className="video_footer mt-4">
                                                    <div className="video-reply-area">
                                                       <div className="form-group m-form__group">
                                                                <div className='d-none preview-image' id='file-preview-holder'>
                                                                    <img max-width="100" height="100" src="" alt="" id="resize_upload_img"/>
                                                                    {/* <i onClick={removeAttachment} className='fa fa-times text-danger'></i> */}
                                                                    <span onClick={removeAttachment} class="material-icons">
                                                                    close
                                                                    </span>
                                                                </div>
                                                                <textarea name="replyText" value={state.replyText} onChange={onChangeHandle}  className="form-control m-input m-input--solid" placeholder="Write a reply..." rows="4"></textarea>
                                                        </div>
                                                        <div className=" reply-action-container text-right mt-2 d-flex">
                                                                <input ref={fileUploadInputRef} onChange={fileUploadOnChangeHandler} type="file" className='d-none' id='file' accept="image/*"/>
                                                                {/* <span onClick={triggerFileUpload} data-toggle="m-tooltip" data-skin="dark" title="Attach an image" type="button" className="btn image_upload d-flex align-items-center btn-secondary mr-2"><svg className="interface-icon o__standard" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path d="M13.833 13H2.167A1.162 1.162 0 0 1 1 11.842V3.158C1 2.518 1.522 2 2.167 2h11.666C14.478 2 15 2.518 15 3.158v8.684c0 .64-.522 1.158-1.167 1.158zM2.4 3a.4.4 0 0 0-.4.4v8.2c0 .22.18.4.4.4h11.2a.4.4 0 0 0 .4-.4V3.4a.4.4 0 0 0-.4-.4H2.4zm7.625 2.005a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm4.49 6.495a.503.503 0 0 1-.311-.106l-3.305-2.601-1.31 1.03.41.365a.43.43 0 0 1 .014.64.51.51 0 0 1-.684.014L5.68 7.594l-3.845 3.765a.509.509 0 0 1-.686.015.432.432 0 0 1-.016-.638l4.18-4.093a.497.497 0 0 1 .34-.141.445.445 0 0 1 .345.124l2.886 2.57 1.703-1.34a.51.51 0 0 1 .624 0l3.617 2.847c.204.16.23.446.058.636a.5.5 0 0 1-.37.161z"></path></svg></span> */}
                                                                <button type="button" disabled={state.isSendingReply} className="btn btn-success send-btn" onClick={messageSend}> {state.isSendingReply ? <React.Fragment>Sending  Reply <i className="fa fa-spinner fa-spin"></i></React.Fragment> : 'Send  Reply'}</button>
                                                            </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                        :
                                        <div className="text-center mt-2 noDataFound">
                                            {/* <img src='../../assets/images/preview-email/empty-conversation.png' alt='Video Not Found'/> */}
                                            <img src={`${window.location.origin}/assets/images/preview-email/empty-conversation.png`} alt='Video Not Found'/>
                                            <h5 className="mt-5 mb-5">Video Not Found</h5>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default PreviewVideoEmail;