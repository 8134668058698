import React, {Fragment} from "react";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import {a2pOnBoard} from "./index";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

const AskRegion = ({ onClose, setActiveStep }) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return(
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={`${Styles.headerTitle} ${Styles.headerTitleCenter}`} variant="h5">A2P/10DLC Registration</Typography>
            </div>

            <div className={Styles.modalBody}>
                <div className={Styles.askConsent}>
                    Do you send text messages to contacts located in the United States?
                </div>
               <div>
                   <Button  size="large" onClick={() => {
                       setActiveStep(a2pOnBoard.EIN_CONFIRM)
                   }} className={`${classes.root} ${Styles.overwriteButton} ${Styles.mr1} ${Styles.width140px}`} variant="contained">
                       YES
                   </Button>
                   <Button onClick={() => {
                       setActiveStep(a2pOnBoard.EXIT_CONSENT)
                   }} size="large" className={`${classes.root} ${Styles.redButtonFill} ${Styles.width140px}`} variant="contained">
                       NO
                   </Button>
               </div>
            </div>
            <div className={Styles.modalFooter}>
                <div className={`${Styles.welcomeMessage} ${Styles.headerTitleCenter}`}>
                    <p>
                        If you are based in the United States registration is mandatory. If you are located outside of the
                        United States, but send messages to US numbers you are also required to register.
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default AskRegion;