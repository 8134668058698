import React, { useState, useContext } from "react";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import ApiList from "../../ApiList";
import DeleteIcon from '@material-ui/icons/Delete';

const Delete = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const removeDealFromStage = () => {
        if(deleting) return;
        if(!confirmDelete){
            setConfirmDelete(true)
            setTimeout(() => {
                if(confirmDelete != undefined){
                    setConfirmDelete(false)
                }
            }, 3000);
        }else{
            setConfirmDelete(false)
            setDeleting(true)
            ApiList.deleteContact({id: detailStates.contactId}).then(res => {
                setDeleting(false)
                if(detailStates.from === 'deal-details'){
                    if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                        detailStates.dealCallback(true)
                    }
                }else if (detailStates.from === 'contact-details'){
                    window.showNotification('SUCCESS', 'Contact deleted successfully');
                    window.location.href = '/contacts';
                }
                props.onClose()
            })
        }
    }

    return(
        <div className="g-more-info-left g-more-info-column mt-4">
            <div onClick={removeDealFromStage} className="g-contact-delete-content">
                <DeleteIcon />
                {confirmDelete ? 'Sure to Delete ? ' : (deleting ? 'Deleting Contact' : 'Delete Contact')}
            </div>
        </div> 
    )
}
export default Delete;