import * as ACTION from "./videoEmailActionTypes";
import * as SEND_MODULE_ACTION from "../../../../constants/sendModuleActionTypes";

const initialState = {
    messageTemplates: null,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    isVideoPlaying: false,
    likeStatus: false,
    willSaveAsTemplate: false,

    /*TimeSchedule start*/
    tabValue: 0,
    hour: 0,
    min: 0,
    day: 2,
    recurringDay: 1,
    laterHour: '08',
    laterMin: '00',
    time: '08:00',
    startDate: new Date(),
    arrSlotTimeHour1: '08',
    arrSlotTimeMin1: '00',
    greetingOption: 1,
    greetingsOptionTimeMin1: '00',
    greetingsOptionTimeHour1: '08',
    recurringTimeHour1: '00',
    recurringTimeMin1: '00',
    /*TimeSchedule end*/
};

const inboxReducer = (state = initialState, action) => {
    switch (action.type) {

        /* Video Email Start */
        case ACTION.FETCH_VIDEOS:
            return {...state, videoLoader: true}

        case ACTION.STORE_VIDEOS:
            return {...state, ...action.payload, videoLoader: false}

        case ACTION.STORE_VIDEO_FOLDERS:
            return {...state, videoFolders: action.payload, videoLoader: false}

        case ACTION.UPDATE_FOLDER_ID:
            return {...state, folderId: action.payload}

        case ACTION.CHANGE_IS_VIDEO_PLAYING:
            return {...state, isVideoPlaying: action.payload}

        case ACTION.CHANGE_LIKE_STATUS:
            return {...state, likeStatus: action.payload}

        case ACTION.CHANGE_SAVE_AS_TEMPLATE:
            return {...state, willSaveAsTemplate: !state.willSaveAsTemplate}
        /* Video Email End */

        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return {...state, messageTemplates: action.payload}
        /* Message Template End */

        /* Update time setting start */
        case SEND_MODULE_ACTION.UPDATE_TAB_VALUE:
            return {...state, tabValue: action.payload}

        case SEND_MODULE_ACTION.UPDATE_HOUR:
            return {...state, hour: action.payload}

        case SEND_MODULE_ACTION.UPDATE_MIN:
            return {...state, min: action.payload}

        case SEND_MODULE_ACTION.UPDATE_DAY:
            return {...state, day: action.payload}

        case SEND_MODULE_ACTION.UPDATE_RECURRING_DAY:
            return {...state, recurringDay: action.payload}

        case SEND_MODULE_ACTION.UPDATE_LATER_HOUR:
            return {...state, laterHour: action.payload}

        case SEND_MODULE_ACTION.UPDATE_LATER_MIN:
            return {...state, laterMin: action.payload}

        case SEND_MODULE_ACTION.UPDATE_TIME:
            return {...state, time: action.payload}

        case SEND_MODULE_ACTION.UPDATE_START_DATE:
            return {...state, startDate: action.payload}

        case SEND_MODULE_ACTION.UPDATE_ARR_SLOT_TIME_HOUR1:
            return {...state, arrSlotTimeHour1: action.payload}

        case SEND_MODULE_ACTION.UPDATE_ARR_SLOT_TIME_MIN1:
            return {...state, arrSlotTimeMin1: action.payload}

        case SEND_MODULE_ACTION.UPDATE_GREETING_OPTION:
            return {...state, greetingOption: action.payload}

        case SEND_MODULE_ACTION.UPDATE_GREETING_OPTION_TIME_MIN1:
            return {...state, greetingsOptionTimeMin1: action.payload}

        case SEND_MODULE_ACTION.UPDATE_GREETING_OPTION_TIME_HOUR1:
            return {...state, greetingsOptionTimeHour1: action.payload}

        case SEND_MODULE_ACTION.UPDATE_RECURRING_TIME_HOUR1:
            return {...state, recurringTimeHour1: action.payload}

        case SEND_MODULE_ACTION.UPDATE_RECURRING_TIME_MIN1:
            return {...state, recurringTimeMin1: action.payload}
        /* Update time setting end */

        default:
            return state;
    }
}

export default inboxReducer;
