
export const DirectMailIcons={
postcardFirstStepCard : <svg width="111" height="74" viewBox="0 0 111 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="1" width="109" height="72" fill="white" stroke="#546376" strokeWidth="2" />
<rect x="17" y="55" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
<rect x="17" y="46" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
<rect x="17" y="37" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
<rect x="72" y="16" width="29" height="22" fill="#E5E8EF" />
</svg>

}