

export function CdnFiles(moduleName, linkArray = null){

    const contactModuleCDN = ["asset/css/releases/1.0.0/Contact1.0.3.css",
    "asset/css/releases/1.0.0/contact-list1.0.8.css",
    "asset/css/releases/1.0.0/contact-list-responsive1.0.0.css",
    "asset/css/releases/1.0.0/ContactResponsive1.0.0.css"];

    const dashboardModuleCDN = ["asset/css/releases/1.0.0/dashboard1.0.1.css"];
    const billingModuleCDN = ["asset/css/releases/1.0.0/billing1.0.0.css"];
    const marketingModuleCDN = ["asset/css/releases/1.0.0/marketing1.0.4.css"];
    const dealModuleCDN = ["asset/css/releases/1.0.0/deal1.0.0.css",
        'asset/css/releases/1.0.0/deal-module1.0.0.css'];

    const inboxModuleCDN = ["asset/css/releases/1.0.0/inbox1.0.1.css",
        'asset/css/releases/1.0.0/inbox-responsive1.0.0.css'];
    let links =[];


    if(linkArray != null){
        links = linkArray;
    }else{
        switch (moduleName){

            case 'ContactModule':
                links = contactModuleCDN;
                break;
            case 'BillingModule':
                links = billingModuleCDN
                break;
            case 'MarketingModule':
                links = marketingModuleCDN
                break;
            case 'DealModule':
                links = dealModuleCDN;
                break;
            case 'DashboardModule':
                links = dashboardModuleCDN;
                break;
            case 'InboxModule':
                links = inboxModuleCDN;
                break;
            default:
                links = []

        }
    }

    for(let i=0; i< links.length; i++){
        let link = document.createElement("link");
        link.rel = 'stylesheet';
        link.type="text/css";
        link.media="screen,projection";
        link.href = process.env.REACT_APP_CDN_LINK+links[i];
        document.head.appendChild(link);
    }

    
}