import './css/global-email-send-component.css'
import './css/email-send-time-setting.css'
import { EmailSendProvider } from './reducers/GlobalReduers'
import SendModule from './pages/SendModule'

const GlobalEmailSendModule = (props) => {
    return(
        <EmailSendProvider>
            <SendModule 
                open={props.open}
                emailId={props.emailId}
                onClose={props.onClose}
                id={props.id}
                from={props.from}
                sendSuccessCallBack={props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null}
                primaryEmail={props.primaryEmail !== undefined ? props.primaryEmail : ""}
                fetchContactDetails={props.fetchContactDetails !== undefined ? props.fetchContactDetails : false}
                contactInfo={props.contactInfo !== undefined ? props.contactInfo : null}
            />
        </EmailSendProvider>
    )
}
export default GlobalEmailSendModule;