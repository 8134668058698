import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const LikeVideo = props => {
    const [likeStatus, setLikeStatus] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        setIsVideoPlaying(props.isVideoPlaying);
    }, [props.isVideoPlaying]);

    useEffect(() => {
        setLikeStatus(props.likeStatus);
    }, [props.likeStatus]);

    return (
        <div>
            <div className="likeArea">
                {(!likeStatus && !isVideoPlaying) &&
                    <React.Fragment>
                        <p>Are you happy with your video ?</p>
                        <div className="buttons">
                            <div className="button-set" id="button-set-select-video" onClick={() => props.selectVideo(1)}>
                                <CheckCircleOutlineIcon style={{ color: "rgb(76, 175, 80)" }} fontSize='large'/>
                            </div>
                            <div className="button-set" onClick={() => props.selectVideo(0)}>
                                <HighlightOffIcon style={{ color: "rgb(219, 20, 20)" }} fontSize='large'/>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isVideoPlaying: state.videoEmailReducer.isVideoPlaying,
        likeStatus: state.videoEmailReducer.likeStatus,
    }
}

export default connect(mapStateToProps, null)(LikeVideo);