const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/direct-mail`;
const marketingUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`;

const Api = {
    getThanksIoImagePreview: `${rootUrl}/get-direct-mail-preview-image`,
    generatePdfFile: `${rootUrl}/generate-pdf-file`,
    getAgencyCategories: `${rootUrl}/get-agency-categories`,
    getAgencyTemplates: `${rootUrl}/get-agency-templates`,
    getHandWritingStyles: `${rootUrl}/get-handwriting-styles`,
    uploadImageFile: `${rootUrl}/direct-mail-local-upload-image-file-v2`,
    submitDirectMailSend: `${rootUrl}/submit-direct-mail-v2`,
    getContactDetails: `${rootUrl}/direct-mail-get-contact-details-v2`,
    getCardPrice: `${marketingUrl}/get-direct-mail-price`,
    getZendirectGiftList: `${rootUrl}/direct-mail-get-zendirect-gift-list`,
    getUserCategories: `${rootUrl}/get-user-categories`,
    getUserTemplates: `${rootUrl}/get-user-templates`,
}
export default Api;