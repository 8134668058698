import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export const EachTag = props => {
    let isChecked = props.selectedTags.includes(props.item.id) 
    return(
        <div className="g-each-tag-content">
            <div className="g-selected-tag-each" key={props.key} onClick={() => props.onCheckChange(props.item.id)}>
                {isChecked ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon />}
                <span>{props.item.name}</span>
            </div>
        </div>
    )
}