import React, { useEffect, useState, useContext } from "react";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import ApiList from "../../ApiList";
import { List } from "./List";

const Deal = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [expand, setExpand] = useState(false)
    const [deal, setDeal] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(expand){
            setLoading(true)
            ApiList.getContactDeals({
                id: detailStates.contactId
            }).then(res => {
                let response = res.data
                setDeal(response.deal)
                setLoading(false)
            })
        }
    }, [expand])

    const renderList = () => {
        let showList = []
        deal.map((each, index) => {
            showList.push(
                <List
                    key={index}
                    item={each}
                /> 
            )
        })
        return showList;
    }

    return(
        <div className="g-more-info-left g-more-info-column mt-4">
            <CustomAccordion expand={expand} className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span className="g-header-title"><AttachMoneyIcon /> Deals </span>
                    <span className="g-custom-caret">{icons.caretIcon}</span>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>
                    <div className="g-contact-deal-content">
                        {loading && <span className="g-loading-content">Loading...</span> }
                        {!loading && 
                        <div className="g-contact-appointment-list awesome__scroll_bar">
                            {deal.length == 0 ?
                                <span className="g-no-appointment">No deal found!</span>
                                :
                                renderList()
                            }                                
                        </div>
                        }
                    </div>
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
export default Deal;