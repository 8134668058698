const PdfPreview = ({typeYourMessageSteps, type}) => {
    const renderClassName=()=>{
        if(type === 'postcard'){
            return {
                'main': 'tmc_pp_main_postcard',
                'body': 'tmc_pp_body_postcard',
                'iframe': 'tmc_pp_iframe_postcard',
                'title': 'Postcard'
            }
        }
        else if(type === 'letter'){
            return {
                'main': 'tmc_pp_main_letter',
                'body': 'tmc_pp_body_letter',
                'iframe': 'tmc_pp_iframe_letter',
                'title': 'Letter'
            }
        }
        else if(type === 'zendirect_gift'){
            return {
                'main': 'tmc_pp_main_gg',
                'body': 'tmc_pp_body_gg',
                'iframe': 'tmc_pp_iframe_gg',
                'title': 'Gift'
            }
        }
    }
    let classes = renderClassName();
    return(
        <div className={`dmpc_3rd_tv_right_inner_img ${classes.main}`}>
            <div className={`dmpc_3rd_tv_right_inner_border ${classes.body}`}>
                <iframe 
                    className={classes.iframe} 
                    src={typeYourMessageSteps.pdfUrl}
                    frameborder="0"
                    title={classes.title}
                    loading={'eager'} 
                    name={classes.title} 
                    menu={true}
                    allowFullScreen
                    width={'100%'}
                    height={'100%'}
                />
            </div>
        </div> 
    );
}
export default PdfPreview;