import * as ACTION from "./ActionTypes";

const initialState = {
    carrierLookupPrices: null
};

const enhancedDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_CARRIER_LOOKUP_PRICES:
            return { ...state, carrierLookupPrices: action.payload };
        default:
            return state;
    }
};

export default enhancedDataReducer;
