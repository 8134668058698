import React from "react";
import Styles from "./Styles.module.css";
import Icons from "./Icons";

const ResponseMessage = ({ submissionError, response, hasError, hasSuccess, handleClose, ...rest }) => {

    const renderMessage = () => {
        if(response !== undefined && response != null){
            if(response.campaign_setting_available !== undefined && response.campaign_setting_available){
                return <div
                    className={Styles.responseMessageButton}
                    onClick={() => handleClose({onlyClose: true, redirect: true})}
                >
                    Close the window
                </div>;
            }
        }
        return <div
            className={Styles.responseMessageButton}
            onClick={() => handleClose({onlyClose: true, redirect: true})}
        >
            Go to next step {">"}
        </div>;
    };

    return(
        <div className={Styles.processingWrapper} {...rest}>
            <div className={Styles.responseMessageIcon}>
                {hasSuccess !== "" ? Icons.success : Icons.failed}
            </div>
            <h4 className={Styles.responseMessage}>
                {hasError === "" ? "Your registration is successful!" : hasError}
            </h4>
            {
                hasSuccess !== "" ?
                    renderMessage() :
                    <div className={Styles.responseMessageListWrapper}>
                        {
                            submissionError && submissionError[0] &&
                            <ul>
                                {
                                    submissionError.map((submissionErrorItem)=>(
                                        <li style={{ color: "red" }}>* {submissionErrorItem}</li>
                                    ))
                                }
                            </ul>
                        }
                        <div
                            className={Styles.responseMessageButton}
                            onClick={() => handleClose({onlyClose: true, redirect: false})}
                        >
                            Try again
                        </div>
                    </div>
            }
        </div>
    );
};

export default ResponseMessage;