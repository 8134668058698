import FooterButton from "./FooterButton";

const PostcardView = ({selectDesignStep, handleStepChange}) => {
    return (
        <div className='dmpc_tv_right'>
            <div className='dmpc_tv_right_inner'>
                <div>
                {selectDesignStep.selectedTemplate != null &&
                <div className='dmpc_tv_right_inner_img' style={{background:`url('${selectDesignStep.selectedTemplate.image_path}')`}}>
                    <div className="dmpc_tv_right_inner_img__top__border">
                    <div className="dmpc_tv_right_inner_img__bottom__border"></div>
                    </div>
                    {/* <div className="dmpc_tv_right_inner_img__bottom__border"></div>
                    <img src={selectDesignStep.selectedTemplate.image_path} alt={'Template View'} /> */}
                </div>
                }
                {selectDesignStep.selectedTemplate == null &&
                <div className='dmpc_tv_right_inner_img'>
                    <div className="dmpc_tv_right_inner_img__top__border">
                    <div className="dmpc_tv_right_inner_img__bottom__border"></div>
                    </div>
                   
                    <div className='gallery_prview_select_empty'>Select a template</div>
                </div>
                }
                <div className='dpmp_tv_footer_line_wr'>
                    <div className='dpmp_tv_footer_line_left'>
                        <span className='dpmp_tv_footer_line_left_border'></span>
                        <span>Cut Line</span>
                    </div>
                    <div className='dpmp_tv_footer_line_left'>
                        <span className='dpmp_tv_footer_line_right_border'></span>
                        <span>Safety Zone</span>
                    </div>
                </div>
                </div>

                <FooterButton 
                    selectDesignStep={selectDesignStep}
                    handleStepChange={handleStepChange}
                />
            </div>
        </div>
    );
}
export default PostcardView;