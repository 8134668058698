import {useEffect } from 'react';

function _addDealUseDelayCallback (callback,array) {
    
    useEffect(() => {
        let timer;
        timer = setTimeout(() => {
            callback();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    },array); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
}
export default _addDealUseDelayCallback;