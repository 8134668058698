import React, { Fragment, useRef, useState } from "react";
import {
    Button,
    Paper,
    Modal,
    makeStyles,
} from "@material-ui/core";
import Styles from "./a2pModalStyles.module.css";
import GeneralInformation from "./GeneralInformation";
import BusinessInformation from "./BusinessInformation";
import PointOfContact from "./PointOfContact";
import TermsAndPolicy from "./TermsAndPolicy";
import { closeA2pModalApi, submitStandardPolicyApi } from "../../api/globalApi";
import Utils from "../../helpers/Utils";
import Processing from "./Processing";
import ResponseMessage from "./ResponseMessage";
import { useHistory } from "react-router-dom";

const steps = [
    {
        label: "General Information",
        title: "A2P/10DLC Registration - General Information",
    },
    {
        label: "Business Information",
        title: "A2P/10DLC Registration - Business Information",
    },
    {
        label: "Point of Contact",
        title: "A2P/10DLC Registration - Points of Contact",
    },
    {
        label: "Terms & Privacy Policy",
        title: "A2P/10DLC Registration - Terms & Privacy Policy",
    }
];

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});
const MultiStepperModal = ({ isOpen, onClose, data }) => {
    const inputScrollToViewRef = useRef([]);
    const width = window.innerWidth - 288;
    const height = window.innerHeight - 264;
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [hasError, setHasError] = useState("");
    const [submissionError, setSubmissionError] = useState([]);
    const [hasSuccess, setHasSuccess] = useState("");
    const [response, setResponse] = useState(null);
    const [isFinal, setIsFinal] = useState(false);
    const [a2pDlcData, setA2pDlcData] = useState({
        customer_name: "",
        street: "",
        city: "",
        postal_code: "",
        region: "",
        iso_country: "US",
        policyType: data?.policyType,

        business_identity: "",
        business_type: "",
        business_industry: "",
        business_registration_id_type: "EIN",
        business_registration_id: "",
        website_url: "",
        type: "",

        rep_user_1_business_title: "",
        rep_user_1_email: "",
        rep_user_1_first_name: "",
        rep_user_1_job_position: "",
        rep_user_1_last_name: "",
        rep_user_1_phone_number: "",
        auth_rep_1: "true",

        rep_user_2_business_title: "",
        rep_user_2_email: "",
        rep_user_2_first_name: "",
        rep_user_2_job_position: "",
        rep_user_2_last_name: "",
        rep_user_2_phone_number: "",
        auth_rep_2: "false",

        accept_term_privacy: "no",
    });
    const [a2pDlcError, setA2pDlcError] = useState({
        customer_name: "",
        street: "",
        city: "",
        postal_code: "",
        region: "",
        iso_country: "",

        business_identity: "",
        business_type: "",
        business_industry: "",
        business_registration_id_type: "",
        business_registration_id: "",
        website_url: "",
        type: "",

        rep_user_1_business_title: "",
        rep_user_1_email: "",
        rep_user_1_first_name: "",
        rep_user_1_job_position: "",
        rep_user_1_last_name: "",
        rep_user_1_phone_number: "",

        rep_user_2_business_title: "",
        rep_user_2_email: "",
        rep_user_2_first_name: "",
        rep_user_2_job_position: "",
        rep_user_2_last_name: "",
        rep_user_2_phone_number: "",

        accept_term_privacy: "",
    });

    const validate = () => {
        if (a2pDlcData) {
            if (activeStep === 0) {
                let message = {
                    customer_name: "",
                    street: "",
                    city: "",
                    region: "",
                    postal_code: "",
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if (key === "customer_name" && value.trim() === "") {
                        message.customer_name = "Legal Business Name is required!";
                    } else if (key === "street" && value.trim() === "") {
                        message.street = "Street Address is required!";
                    } else if (key === "city" && value.trim() === "") {
                        message.city = "City is required!";
                    } else if (key === "region" && value.trim() === "") {
                        message.region = "State is required!";
                    } else if (key === "postal_code" && value.trim() === "") {
                        message.postal_code = "Postal/Zip Code is required!";
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }

            if (activeStep === 1) {
                let message = {
                    business_type: "",
                    type: "",
                    business_industry: "",
                    business_registration_id_type: "",
                    business_registration_id: "",
                    website_url: "",
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if (key === "business_type" && value.trim() === "") {
                        message.business_type = "Business Type is required!";
                    } else if (key === "type" && value.trim() === "") {
                        message.type = "Customer Business Profile Type is required!";
                    } else if (key === "business_industry" && value.trim() === "") {
                        message.business_industry = "Business Industry is required!";
                    } else if (key === "business_registration_id_type" && value.trim() === "") {
                        message.business_registration_id_type = "Business Registration ID Type is required!";
                    } else if (key === "business_registration_id" && value.trim() === "") {
                        message.business_registration_id = "Business Registration Number (EIN#) is required!";
                    } else if (key === "website_url" && value.trim() === "") {
                        message.website_url = "Business Website is required!";
                    } else if (key === "website_url" && value !== "" && !Utils.isValidURL(value)) {
                        message.website_url = "Business Website must be a valid url!";
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }

            if (activeStep === 2) {
                let message = {
                    rep_user_1_first_name: "",
                    rep_user_1_last_name: "",
                    rep_user_1_business_title: "",
                    rep_user_1_job_position: "",
                    rep_user_1_email: "",
                    rep_user_1_phone_number: "",
                    rep_user_2_first_name: "",
                    rep_user_2_last_name: "",
                    rep_user_2_business_title: "",
                    rep_user_2_job_position: "",
                    rep_user_2_email: "",
                    rep_user_2_phone_number: "",
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if (key === "rep_user_1_business_title" && value.trim() === "") {
                        message.rep_user_1_business_title = "Business Title is required!";
                    } else if (key === "rep_user_1_email" && value.trim() === "") {
                        message.rep_user_1_email = "Email Address is required!";
                    } else if (key === "rep_user_1_email" && value !== "" && !Utils.isValidEmail(value)) {
                        message.rep_user_1_email = "Email Address must be valid!";
                    } else if (key === "rep_user_1_phone_number" && value.trim() === "") {
                        message.rep_user_1_phone_number = "Mobile Number is required!";
                    } else if (key === "rep_user_1_phone_number" && value !== "" && !Utils.isValidMobileNumber(value)) {
                        message.rep_user_1_phone_number = "Mobile Number must be valid!";
                    } else if (key === "rep_user_1_job_position" && value.trim() === "") {
                        message.rep_user_1_job_position = "Job Position is required!";
                    } else if (key === "rep_user_1_first_name" && value.trim() === "") {
                        message.rep_user_1_first_name = "First Name is required!";
                    } else if (key === "rep_user_1_last_name" && value.trim() === "") {
                        message.rep_user_1_last_name = "Last Name is required!";
                    }

                    if (a2pDlcData.auth_rep_2 !== "false") {
                        if (key === "rep_user_2_business_title" && value.trim() === "") {
                            message.rep_user_2_business_title = "Business Title is required!";
                        } else if (key === "rep_user_2_email" && value.trim() === "") {
                            message.rep_user_2_email = "Email Address is required!";
                        } else if (key === "rep_user_2_email" && value !== "" && !Utils.isValidEmail(value)) {
                            message.rep_user_2_email = "Email Address must be valid!";
                        } else if (key === "rep_user_2_phone_number" && value.trim() === "") {
                            message.rep_user_2_phone_number = "Mobile Number is required!";
                        } else if (key === "rep_user_2_phone_number" && value !== "" && !Utils.isValidMobileNumber(value)) {
                            message.rep_user_2_phone_number = "Mobile Number must be valid!";
                        } else if (key === "rep_user_2_job_position" && value.trim() === "") {
                            message.rep_user_2_job_position = "Job Position is required!";
                        } else if (key === "rep_user_2_first_name" && value.trim() === "") {
                            message.rep_user_2_first_name = "First Name is required!";
                        } else if (key === "rep_user_2_last_name" && value.trim() === "") {
                            message.rep_user_2_last_name = "Last Name is required!";
                        }
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }
        }
    }

    const handleNext = () => {
        if (!validate()) {
            window.showNotification("Error", "Please fill the form correctly. For more details check the form.");
            return;
        }

        if (activeStep === (steps.length - 1)) {
            return;
        }
        if (activeStep === (steps.length - 2)) {
            setIsFinal(true);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        if (isFinal) {
            setIsFinal(false);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseForOneDay = async () => {
        onClose();
        await closeA2pModalApi();
    }

    const handleChangeA2pDlcData = (name, value) => {
        setA2pDlcData({ ...a2pDlcData, [name]: value });
    };

    const handleErrorCallback = (name, message) => {
        setA2pDlcError({ ...a2pDlcError, [name]: message });
    };

    const handleSubmitInformation = () => {

        if (activeStep === 3 && a2pDlcData.accept_term_privacy !== "yes") {
            window.showNotification("ERROR", "You have to accept the terms and policy.");
            return;
        }

        if (submitting) {
            return;
        }
        setSubmitting(true);
        if (a2pDlcData.rep_user_1_phone_number && a2pDlcData.rep_user_1_phone_number.length === 11) {
            a2pDlcData.rep_user_1_phone_number = a2pDlcData.rep_user_1_phone_number.substring(1);
        }

        if (a2pDlcData.rep_user_2_phone_number && a2pDlcData.rep_user_2_phone_number.length === 11) {
            a2pDlcData.rep_user_2_phone_number = a2pDlcData.rep_user_2_phone_number.substring(1);
        }

        submitStandardPolicyApi(a2pDlcData).then(res => {
            let response = res.data;
            if (response.status === "success") {
                setHasSuccess("Successfully submitted the form.");
                setResponse({
                    "campaign_setting_available": response?.campaign_setting_available || false,
                    "campaign_reg_failed_reason": response?.campaign_reg_failed_reason || ""
                });
                if (data.callback){
                    data.callback();
                }
            } else {

                if (response.error_message && Array.isArray(response.error_message)) {
                    setSubmissionError(response.error_message);
                }

                if (response.message) {
                    setHasError(response.message);
                } else {
                    setHasError("Can not register 10DLC information!");
                }
            }
            setSubmitting(false);
        }).catch((e) => {
            setSubmitting(false);
            setHasError("Can not register 10DLC information!");
            setSubmitting(false);
        });
    };

    const getStepBody = () => {
        switch (activeStep) {
            case 0:
                return <GeneralInformation
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />;
            case 1:
                return <BusinessInformation
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />;
            case 2:
                return <PointOfContact
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />;
            case 3:
                return <TermsAndPolicy
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />;
            default:
                return;
        }
    };

    const getStepTitle = (index) => {
        return steps[index]?.title;
    };

    const handleRedirectHelp = () => {

        if (Utils.getAccountData('agencyId') === 1) {
            return `https://softwarehelp.freshdesk.com/support/solutions/articles/66000517994-understanding-a2p-10dlc-registration`;
        }
        return `${process.env.REACT_APP_CDN_LINK}asset/files/releases/1.0.0/10dlc-help.pdf`;
    }

    const handleAfterResponseClose = async ({ onlyClose = true, redirect = false }) => {
        if (onlyClose && redirect) {
            if (response != null) {
                if (!response.campaign_setting_available) {
                    handleClose();
                    if (data && data.from){
                        window.globalA2pCampaignUseCaseRegistration(true, null, data.from);
                    }else {
                        window.globalA2pCampaignUseCaseRegistration(true);
                    }
                    return;
                } else if (response.campaign_reg_failed_reason !== "") {
                    handleClose();
                    if (data && data.from){
                        window.globalA2pCampaignUseCaseRegistration(true, null, data.from);
                    }else {
                        window.globalA2pCampaignUseCaseRegistration(true);
                    }
                    return;
                }
            }
            onClose();
        } else if (onlyClose) {
            setSubmissionError([]);
            setSubmitting(false);
            setHasError("");
            setHasSuccess("");
            setActiveStep(0);
            setIsFinal(false);
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => { }}
            aria-labelledby="multi-stepper-modal-title"
            aria-describedby="multi-stepper-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
                {
                    submitting ?
                        <Processing
                            style={{ width: width, height: (height + 222) }}
                        /> :
                        (
                            (!submitting && (hasError || hasSuccess || response)) ?
                                <ResponseMessage
                                    style={{ width: width, height: (height + 222) }}
                                    hasError={hasError}
                                    submissionError={submissionError}
                                    hasSuccess={hasSuccess}
                                    response={response}
                                    handleClose={handleAfterResponseClose}
                                /> :
                                <Fragment>
                                    <div className={Styles.headerWrapper}>
                                        {
                                            steps.map((step, index) => (
                                                <div className={`${Styles.tabStepper} ${activeStep === index ? Styles.tabStepperActive : ""}`}>
                                                    <span className={Styles.tabStepperLabel}>{(index + 1)}. {step.label}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={Styles.modalBody}>
                                        <div className={Styles.modalBodyHeader}>
                                            <div className={Styles.modalBodyTitleWrapper}>
                                                <h1 className={Styles.modalBodyTitle}>{getStepTitle(activeStep)}</h1>
                                            </div>
                                            <div className={Styles.modalBodyTools}>
                                                <Button size="small" target="_blank" href={handleRedirectHelp()} className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                                                    ACCESS HELP GUIDE
                                                </Button>
                                                <Button size="small" onClick={handleCloseForOneDay} className={`${classes.root} ${Styles.redButton}`} variant="outlined">
                                                    EXIT
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{ width: width, height: height }} className={Styles.bodyContainer}>
                                            {getStepBody()}
                                        </div>
                                    </div>
                                    <div className={Styles.modalFooter}>
                                        {
                                            activeStep !== 0 &&
                                            (
                                                <Button size="large" onClick={handleBack} className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                                                    Back
                                                </Button>
                                            )
                                        }
                                        <Button size="large" disabled={submitting} onClick={isFinal ? handleSubmitInformation : handleNext} className={`${classes.root} ${Styles.overwriteButton}`} variant="contained">
                                            {isFinal ? (submitting ? "Submitting Information..." : "Submit Information") : "Next"}
                                        </Button>
                                    </div>
                                </Fragment>
                        )
                }
            </Paper>
        </Modal>
    );
};

export default MultiStepperModal;