import axios from "axios";
import { getCookie } from "../../../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    try{
        payload.append('api-key', apiKey)
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': apiKey}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}