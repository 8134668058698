import React, { useState, useRef , useEffect} from 'react';
import './ContactUs.css';
import { sendErrorMessageToDevelopers } from '../../../api/globalDialerApi';
import messageImg from '../../../components/auth/images/message.png';

const ContactUs = () => {

    const messageRef = useRef('');
    const emailInputRef = useRef('')

    const [submitHandle,setSubmitHandle] = useState(false);
    const [successMessage,setSuccessMessage] = useState('');
    const [timeOutState, setTimeoutState] = useState(null)


    useEffect(()=>{
        return(()=>{
            clearTimeout(timeOutState)
        })
    },[])

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if(submitHandle){
            return;
        }
        // let email = emailInputRef.current.value;
        // let message = messageRef.current.value;
        // if(email.trim() === '' || message.trim() === '') {
        //     window.showNotification("WARNING", "Fill up the form!")
        //     return;
        // }
        // try{
        //     setSubmitHandle(true);
        //     let form_data = {
        //         'subject': email,
        //         'body': message,
        //         'api-key': 'DGM2RNXDL8KS85JD066E'
        //     }
        //     sendErrorMessageToDevelopers(form_data).then(res => {
        //         let response = res.data;
        //         if(!response.status){
        //             window.showNotification("ERROR", "Something went wrong.")
        //         }
        //         else{
                    setSuccessMessage("Information Submitted Successfully");
                //     let timeOut = setTimeout(() => {
                //         // if(setSuccessMessage !== undefined){
                //             setSuccessMessage("")
                //         // }
                //     }, 2000);
                    emailInputRef.current.value= ''
                    messageRef.current.value= ''
                //     setTimeoutState(timeOut)
                // }
                
                // setSubmitHandle(false);
            // })
        // }catch(e){
        //     console.log(e);
        // }

    }

    return(
        <div className='contact_us_page_wrapper'>
            <div className='contact_us_image'>
                <img src={messageImg} alt="postcard"/>
            </div>
            <div className="contact_us_form">
                <form onSubmit={onSubmitHandler} className='contact_us_form_wrapper'>
                    <h4 className='form_heading'>Have Some Questions ?</h4>
                    <h6 className='form__sub__heading'>Contact with us</h6>
                    <p className="success__msg">{successMessage}</p>
                    <input ref={emailInputRef} className='input_field' placeholder='Enter your Email' id="email" type="textbox" />

                    <textarea ref={messageRef} className='input_field contact_us_textarea' placeholder='Enter your Message' id="message" type="textbox"> 
                    </textarea>
            
                     <button  className='conatct_us_form_button' type="submit">{submitHandle ? "Submitting":"Submit"}</button>
                </form>
            </div>
        </div>        
    );
}

export default ContactUs;