import Utils from "../../../../../helpers/Utils";
import DirectMailIcons from "../../../DirectMailIcons";

const Header = ({handleDataChange, from}) => {

    const renderTitle = () => {
        if(from === "upload" || from === "canva"){
            return(
                <div className='dmpc_2mt_left'>
                    <h4 className='dmpc_2mt_left_title'>Create New Design</h4>
                    <h5 className='dmpc_2mt_left_subtitle'>Use our design tools to help your card design perfect</h5>
                </div>
            );
        }

        return(
            <div className='dmpc_2mt_left'>
                <h4 className='dmpc_2mt_left_title'>Choose or Upload your Design</h4>
                <h5 className='dmpc_2mt_left_subtitle'>Use our design tools to help your card design perfect</h5>
            </div>
        );
    }

    return (
        <div className='dmpc_2nd_middle_top'>
            {renderTitle()}
            {from === "template" &&
            <div className='dmpc_2mt_right'>
                {Utils.getAccountData('agencyId') === 1 &&
                <button 
                    className='dmpc_2mt_right__canbtn'
                    onClick={() => handleDataChange({from: 'canva'})}
                >
                    <span>{DirectMailIcons.canvaIcon}</span>
                    <span>Design in Canva</span>
                </button>
                }
                <button 
                    className='dmpc_2mt_right__upbtn'
                    onClick={() => handleDataChange({from: 'upload', selectedCategory: null })}
                >
                    <span>{DirectMailIcons.uploadIcon}</span>
                    <span>Upload your design</span>
                </button>
            </div>
            }
        </div>
    );
}
export default Header;