import { sendRequest } from "../../../api/rootApi";
import Api from "./ApiList";

export const getThanksIoImagePreviewApi = async (request) => {
    return sendRequest("post", Api.getThanksIoImagePreview, request);
}
export const generateThanksIoPdfFileApi = async (request) => {
    return sendRequest("post", Api.generatePdfFile, request);
}
export const getAgencyCategoriesApi = async (request) => {
    return sendRequest("post", Api.getAgencyCategories, request);
}
export const getAgencyTemplatesApi = async (request) => {
    return sendRequest("post", Api.getAgencyTemplates, request);
}
export const getHandWritingStylesApi = async (request) => {
    return sendRequest("post", Api.getHandWritingStyles, request);
}
export const uploadImageFileApi = async (request) => {
    return sendRequest("post", Api.uploadImageFile, request);
}
export const submitDirectMailSendApi = async (request) => {
    return sendRequest("post", Api.submitDirectMailSend, request);
}
export const getContactDetailsApi = async (request) => {
    return sendRequest("post", Api.getContactDetails, request);
}
export const getCardPriceApi = async (request) => {
    return sendRequest("post", Api.getCardPrice, request);
}
export const getZendirectGiftListApi = async (request) => {
    return sendRequest("post", Api.getZendirectGiftList, request);
}
export const getUserCategoriesApi = async (request) => {
    return sendRequest("post", Api.getUserCategories, request);
}
export const getUserTemplatesApi = async (request) => {
    return sendRequest("post", Api.getUserTemplates, request);
}