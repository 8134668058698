import React, { useState, useEffect } from 'react';
import If from 'if-else-react';
import './VideoPlayer.css';

const VideoPlayer = (props) => {
    const [state, setState] = useState({
        isVideoPlaying: false,
        isVideoEnded: false,
    });

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if (state.isVideoPlaying) {
            document.getElementById('video-static').play()
        }
    }, [state.isVideoPlaying]);

    const playVideo = () => {
        setState({
            ...state,
            isVideoPlaying: true, 
            isVideoEnded: false
        });
    }

    const endVideo = () => {
        setState({
            ...state, 
            isVideoEnded: true,
            isVideoPlaying: false,
        });
    }

    const pauseVideo = () => {
        setState({
            ...state, 
            isVideoPlaying: false,
        });
    }

    return (
        <React.Fragment>
            <div className="video-player-body">
                <div className="embed-responsive embed-responsive-16by9">
                    <If condition={!state.isVideoPlaying}>
                        <div className="video-start-caption">
                            <div className="video-start-caption-content">
                                {/* <img src={'/assets/playIcon.png'} alt=""/> */}
                                <button className="video--button" onClick={() => playVideo()} id='trigger-play'>
                                    <span class="material-icons">
                                    play_circle_outline
                                    </span>
                                </button>
                                 
                            </div>
                        </div>
                    </If>
                    <If condition={state.isVideoEnded}>
                        <div className="video-end-caption">
                            <div className="video-end-caption-content">
                                <h5>Thanks for watching the Video.</h5>
                                <p>Feel free to send us a reply below.</p>
                                <div className='video_end_caption_action_wr'>
                                    <button onClick={() => playVideo()} className="video-end-caption-action">
                                        <svg width="16px" height="16px" viewBox="0 0 16 16"
                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             className="bi bi-arrow-repeat">
                                            <path
                                                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                            <path fill-rule="evenodd"
                                                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                        </svg>
                                        Watch again
                                    </button>
                                </div>
                            </div>
                        </div>
                    </If>
                    <video onEnded={() => endVideo()} /* muted */ playsInline onPlay={() => playVideo()} onPause={() => pauseVideo()} controls preload="auto" className="embed-responsive-item" id="video-static">
                        {/* <source src={state.remoteUrl + state.user.user_id + '/' + state.video + '.mp4'} type="video/mp4"/> */}
                        <source src={props.src} type="video/mp4"/>
                        <source src={props.src} type='video/mp4; codecs="hvc1"'/>
                        <source src={props.src} type='video/mp4; codecs="avc1"'/>
                        "This video is not supported on your browser"
                    </video>
                </div>
            </div>
        </React.Fragment>
    );
};

export default VideoPlayer;