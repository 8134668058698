import CustomSwitch from "../../../../../globals/Switch/CustomSwitch";

const Header = ({flag, onChange}) => {
    return (
        <div className='dmpc_templete_upload_top'>
                <h5 className='dmpc_templete_up_title'>would you like to Save this as a new Templete?</h5>
                {/* <CustomSwitch status={flag} onChange={onChange}/> */}
            </div>
    );
}
export default Header;