export const FETCH_CUSTOM_FIELD = 'FETCH_CUSTOM_FIELD';

/* TIME SCHEDULE SETTING START */
export const UPDATE_TAB_VALUE = 'UPDATE_TAB_VALUE';
export const UPDATE_HOUR = 'UPDATE_HOUR';
export const UPDATE_MIN = 'UPDATE_MIN';
export const UPDATE_DAY = 'UPDATE_DAY';
export const UPDATE_RECURRING_DAY = 'UPDATE_RECURRING_DAY';
export const UPDATE_LATER_HOUR = 'UPDATE_LATER_HOUR';
export const UPDATE_LATER_MIN = 'UPDATE_LATER_MIN';
export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_ARR_SLOT_TIME_HOUR1 = 'UPDATE_ARR_SLOT_TIME_HOUR1';
export const UPDATE_ARR_SLOT_TIME_MIN1 = 'UPDATE_ARR_SLOT_TIME_MIN1';
export const UPDATE_GREETING_OPTION = 'UPDATE_GREETING_OPTION';
export const UPDATE_GREETING_OPTION_TIME_MIN1 = 'UPDATE_GREETING_OPTION_TIME_MIN1';
export const UPDATE_GREETING_OPTION_TIME_HOUR1 = 'UPDATE_GREETING_OPTION_TIME_HOUR1';
export const UPDATE_RECURRING_TIME_HOUR1 = 'UPDATE_RECURRING_TIME_HOUR1';
export const UPDATE_RECURRING_TIME_MIN1 = 'UPDATE_RECURRING_TIME_MIN1';
/* TIME SCHEDULE SETTING END */