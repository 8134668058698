import React, { useState, useEffect } from "react";
import { getSignUpPageData, selectPackage } from "../../actions/signupAction";
import { connect } from 'react-redux';
import moment from "moment";
import PaymentForm from './PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import If, { Else } from 'if-else-react';
import { Link } from "react-router-dom";

const PRICING_MONTHLY = 'MONTHLY';
const PRICING_YEARLY = 'YEARLY';
const PRICING_CUSTOM = 'CUSTOM';

const App = (props) => {
    const stripePromise = loadStripe(props.stripePublicKey);
    const [selectedPackage, setSelectedPackage] = useState(props.selectedPackage);
    const [amount, setAmount] = useState(0);
    const [period, setPeriod] = useState(0);
    const [periodText, setPeriodText] = useState('');

    useEffect(() => {
        setSelectedPackage(props.selectedPackage);
        let pack = props.selectedPackage;
        // console.log(pack)
        if (pack && pack.selectedPricing) {
            setAmount(pack.selectedPricing.price);
            if (pack.selectedPricing.name === PRICING_CUSTOM) {
                setPeriodText(pack.selectedPricing.validity + 'days');
                setPeriod(pack.selectedPricing.validity)
            } else {
                setPeriodText(pack.selectedPricing.friendly_name);
                setPeriod(pack.selectedPricing.validity)
            }
        }
    }, [props.selectedPackage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="center-align">
            <div className="price_package_content">
                <div className="row">
                    <div className="col xl7 payment_content">
                        <div className="top_title">
                            <h1>Billing</h1>
                            <p>Complete the form below to create your new account!</p>
                        </div>
                        <div className="payment_card_form">
                            <div className="row">
                                <Elements stripe={stripePromise}>
                                    <PaymentForm />
                                </Elements>
                            </div>
                        </div>
                        <div className="secqure_payment left-align">
                            <div className="bank_logo">
                                <div className="row">
                                    <div className="col s12">
                                        <ul className="secqure_payment__flex__wrapping  d-flex justify-content-center">
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Visa.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Master.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Maestro.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/JCB.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Discover.png`} alt="" /></li>
                                        </ul>
                                        <p>100% Safe & Secure</p>
                                    </div>
                                </div>
                            </div>
                            {(selectedPackage.trial_period !== null && props.agencyId === 125) ?
                                <p>Billing will resume after 14 days. Please review <Link to="/privacy-policy">terms of use</Link> for terms of cancellation.</p>
                                :
                                <p>No contracts or commitments. Cancel anytime.</p>
                            }

                        </div>
                    </div>
                    <div className="col xl5 price_checkout">
                        <div className="packages">

                            {
                                selectedPackage.trial_period !== null &&
                                <>
                                    <ul>
                                        <li className="package_list top_title"><h1 className="text--blue">Package : {selectedPackage.name}</h1></li>
                                    </ul>
                                    <div>
                                        <ul>
                                            <li className="package_list">Today's Payment:</li>
                                            <li className="price">$0</li>
                                        </ul>

                                        <ul>
                                            <li className="package_list">Future Payment's:</li>
                                            <li className="price">${amount}/{periodText}</li>
                                        </ul>
                                    </div>
                                </>
                            }

                            {
                                selectedPackage.trial_period === null &&
                                <>
                                    <ul>
                                        <li className="package_list top_title"><h1 className="text--blue">Package : {selectedPackage.name}</h1></li>
                                    </ul>
                                    <div className={"bill-info-outer-box"}>
                                        <ul>
                                            <li className="package_list">Today's Payment:</li>
                                            <li className="price">${amount}</li>
                                        </ul>
                                        <ul>
                                            <li className="package_list">Future Payment's:</li>
                                            <li className="price">${amount}/{periodText}</li>
                                        </ul>

                                    </div>
                                </>
                            }


                            {/*{*/}
                            {/*    selectedPackage.trial_pack == 1 &&*/}
                            {/*    <div className="price_list">*/}
                            {/*        <p>Your monthly plan will be ${ amount }. It will automatically renew until your cancel. The next billing will occur on <span>{ moment().add(period,'d').format('MMMM D YYYY, h:mm A') }</span></p>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            {
                                selectedPackage.trial_period !== null &&
                                <div className="price_list">
                                    <h2>No Charge Today</h2>
                                </div>
                            }

                            {
                                selectedPackage.trial_period === null ?
                                    <div className="warrning">
                                        <p>It will automatically renew until your cancel. The next billing will occur on <span>{moment().add(period, 'd').format('MMMM D YYYY, h:mm A')}</span></p>
                                    </div>
                                    :
                                    <div className="warrning">
                                        <p>It will automatically renew until your cancel. The next billing will occur on <span>{moment().add(selectedPackage.trial_period, 'd').format('MMMM D YYYY, h:mm A')}</span></p>
                                    </div>
                            }
                            {/*<div className="payment_btn">*/}
                            {/*    <button type="submit" disabled={!stripe}>Pay Now</button>*/}
                            {/*</div>*/}
                            <div className="privecy_policy">
                                <p>
                                    <If condition={props.privacyPolicy != null && props.privacyPolicy != ''}>
                                        <a href={props.privacyPolicy}>Terms of Use & Privacy Policy</a>
                                        <Else />
                                        <Link to="/privacy-policy">Terms of Use & Privacy Policy</Link>
                                    </If>

                                    {/*<If condition={props.termsAndConditions != null && props.termsAndConditions != ''}>*/}
                                    {/*    <a className="ml-5" href={props.termsAndConditions}>Terms And Conditions</a>*/}
                                    {/*    <Else />*/}
                                    {/*    <Link to="/terms-and-conditions" className="ml-5">Terms & Conditions</Link>*/}
                                    {/*</If>*/}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedPackage: state.signupReducer.selectedPackage,
    };
};


const Payment = connect(mapStateToProps, null)(App);

export default Payment;