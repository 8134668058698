import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchCustomFieldApi = async (payload) => {
    if(payload.data){
        return sendRequest("post", Api.customFields,payload.data);
    }else{
        return sendRequest("post", Api.customFields);
    }
   
}

export const testEmailSend = async (payload) => {
    return sendRequest("POST", Api.testEmailSend, payload);
}