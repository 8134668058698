import React from "react";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const CsutomNestedList = ({list, onSelect}) => {
  const [open, setOpen] = React.useState('');
  const handleClick = (group_name) => {
    if(group_name === open){
        setOpen('');
    }
    else{
        setOpen(group_name);
    }
  };

  const handleTagSelect = (value) => {
    onSelect(value)
  }

  const renderGroupList = () => {
    let view = [];
    list.forEach((item, index) => {
        view.push(
            <div className="pgwptc__each__group">
                <div className="pgwptc__eg_info" onClick={() => handleClick(item.group_value)} key={index}>
                    <span className="pgwptc__eg_i_wr">
                        <span className="pgwptc__eg_i_title">{item.group_name}</span>
                    <span className="pgwptc__eg_i_count"> ({item.total})</span></span>
                    {open === item.group_value ? <ExpandLess /> : <ExpandMore />}
                </div>
                <Collapse key={index} in={(open === item.group_value)} timeout="auto" unmountOnExit>
                    <div className="pgwptc__eg_all_list">
                        {renderList(item.list, index, item.group_value)}
                    </div>
                </Collapse>
            </div>
        );
    });
    return view;
  }

  const renderList = (list, group_index, group_value) => {
    let view = [];
    list.forEach((item, index) => {
        view.push(
            <div className="pgwptc__group__each__item" key={group_index+'-'+index} button onClick={() => handleTagSelect(item.value)}>
                <span className="pgwptc__gei_title">{item.field_label}</span>
            </div>
        );
    });
    return view;
  }

  return (
    <div className="pypepro__group_wise__personlized_tags_container">
        {renderGroupList()}
    </div>
  );
}
export default CsutomNestedList;