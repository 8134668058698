import * as ACTION from "../constants/signupActionTypes";
import {OTP_VERIFY_PROCESS} from "../constants/signupActionTypes";

export const getSignUpPageData = (payload, callback) => ({
    type: ACTION.FETCH_SIGNUP_DATA,
    payload: {payload, callback}
});

export const attemptSignup = credentials => ({
    type: ACTION.FETCH_SIGNUP_DATA_SUCCESS,
    payload: credentials
});

export const updateValidationCheck = payload => ({
    type: ACTION.UPDATE_VALIDATION_CHECK,
    payload: payload
});

export const updateLoading = payload => ({
    type: ACTION.UPDATE_LOADING,
    payload: payload
});

export const selectPackage = payload => ({
    type: ACTION.SELECT_PACKAGE,
    payload: payload
});

export const registrationWithPayment = (payload, callback) => ({
    type: ACTION.SIGN_UP_PROCESS,
    payload: {payload, callback}
});

export const verifyUserOTP = (payload, callback) => ({
    type: ACTION.OTP_VERIFY_PROCESS,
    payload: {payload, callback }
});

export const updateSubmitting = payload => ({
    type: ACTION.UPDATE_SUBMITTING,
    payload: payload
});

export const handleStateChange = payload => ({
    type: ACTION.HANDLE_STATE_CHANGE,
    payload: payload
});