const urlify = (text) => {
    try {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    } catch (err) {
        return text;
    }
}

const Utils = {
    urlify
}

export default Utils;