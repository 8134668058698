import * as ACTION from "../constants/globalDialerActionTypes";

export const getCurrentCredit = () => ({
    type: ACTION.FETCH_CURRENT_CREDIT,
    payload: {}
})

export const clearDialNumber = () => ({
    type: ACTION.CLEAR_DIAL_NUMBER,
    payload: {dialNumber : ''}
})

export const addDialNumber = dialNumber => ({
    type: ACTION.ADD_DIAL_NUMBER,
    payload: {dialNumber : dialNumber}
})

export const getVirtualNumbers = () => ({
    type: ACTION.FETCH_VIRTUAL_NUMBER,
    payload: {}
});
export const selectFromNumber = fromNumber => ({
    type: ACTION.SELECT_FROM_NUMBER,
    payload: {fromNumber : fromNumber}
})


export const changeStage = stage => ({
    type: ACTION.CHANGE_STAGE,
    payload: {stage : stage}
})

export const setInitialContact = contactNumber => ({
    type: ACTION.SET_INITIAL_CONTACT,
    payload: {initialContactNumber : contactNumber}
})

export const getContacts = initialContactNumber => ({
    type: ACTION.GET_CONTACT,
    payload: initialContactNumber
})

export const selectActiveContact = contact => ({
    type: ACTION.SELECT_ACTIVE_CONTACT,
    payload: {activeContact : contact}
})

export const removeActiveContact = () => ({
    type: ACTION.REMOVE_ACTIVE_CONTACT,
    payload: {activeContact : []}
})

export const searchContacts = searchKey =>({
    type: ACTION.SEARCH_CONTACT,
    payload: searchKey
})

export const updateCallInProgressStatus = status => ({
    type: ACTION.UPDATE_CALL_IN_PROGRESS_STATUS,
    payload: {isCallInProgress: status}
})

export const updateIsCallReceived = status => ({
    type: ACTION.UPDATE_IS_CALL_RECEIVED,
    payload: {isCallReceived: status}
})

export const resetDialer = status => ({
    type: ACTION.RESET_DIALER,
    payload: {}
})

export const updateCallTime = time => ({
    type: ACTION.UPDATE_CALL_TIME,
    payload: {callTime: time}
})

export const updateCallInfo = callInfo => ({
    type: ACTION.UPDATE_CALL_INFO,
    payload: {callInfo: callInfo}
})


export const enableWarmTransfer = transferType => ({
    type: ACTION.ENABLE_WARM_TRANSFER,
    payload: {enableWarmTransfer: true, callOnScreen: 'second', transferType: transferType}
})

export const toggleScreen = screen => ({
    type: ACTION.TOGGLE_BETWEEN_SCREEN,
    payload: {callOnScreen: screen}
})

export const changeSecondCallStage = stage => ({
    type: ACTION.CHANGE_SECOND_CALL_STAGE,
    payload: {secondCallStage : stage}
})

export const clearSecondDialNumber = () => ({
    type: ACTION.CLEAR_SECOND_DIAL_NUMBER,
    payload: {secondDialNumber : ''}
})

export const addSecondDialNumber = secondDialNumber => ({
    type: ACTION.ADD_SECOND_DIAL_NUMBER,
    payload: {secondDialNumber : secondDialNumber}
})

export const updateSecondCallInProgressStatus = status => ({
    type: ACTION.UPDATE_SECOND_CALL_IN_PROGRESS_STATUS,
    payload: {isSecondCallInProgress: status}
})

export const updateSecondCallStatus = status => ({
    type: ACTION.UPDATE_SECOND_CALL_STATUS,
    payload: {secondCallStatus: status}
})

export const destroySecondCall = () => ({
    type: ACTION.DESTROY_SECOND_CALL,
    payload: {enableWarmTransfer: false, callOnScreen: 'first'}
})

export const holdFirstCall = () => ({
    type: ACTION.HOLD_FIRST_CALL,
    payload: {isFirstCallOnHold: true}
})

export const unHoldAll = () => ({
    type: ACTION.UNHOLD_ALL,
    payload: {isFirstCallOnHold: false, isSecondCallOnHold: false}
})

export const putAllContactInformation = (payload) => ({
    type: ACTION.PUT_CONTACT,
    payload: payload
})

export const resetDialerWithNumber = payload => ({
    type: ACTION.RESET_WITH_CONTACT_NUMBER,
    payload: payload
})

export const storeMultipleReducers = payload => ({
    type: ACTION.STORE_MULTIPLE_REDUCERS,
    payload: payload
})