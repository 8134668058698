const icon = {
  google: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="280px"
      height="192px"
     
    >
      <g>
        <path
          fill="#fefefe"
          d="M -0.5,-0.5 C 92.8333,-0.5 186.167,-0.5 279.5,-0.5C 279.5,63.5 279.5,127.5 279.5,191.5C 186.167,191.5 92.8333,191.5 -0.5,191.5C -0.5,127.5 -0.5,63.5 -0.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          
          fill="#5d9fee"
          d="M 192.5,52.5 C 191.566,51.4324 191.232,50.099 191.5,48.5C 147.167,48.5 102.833,48.5 58.5,48.5C 61.1019,46.1904 64.1019,44.3571 67.5,43C 73.1662,41.0554 78.8329,39.2221 84.5,37.5C 88.5833,29.5858 94.25,23.0858 101.5,18C 122.664,6.49308 144.331,5.49308 166.5,15C 181.757,23.0383 190.424,35.5383 192.5,52.5 Z"
        />
      </g>
      <g>
        <path
         
          fill="#4f99ea"
          d="M 58.5,48.5 C 102.833,48.5 147.167,48.5 191.5,48.5C 191.232,50.099 191.566,51.4324 192.5,52.5C 203.615,54.3876 212.948,59.3876 220.5,67.5C 188.996,67.1685 157.662,67.5018 126.5,68.5C 122.866,66.0351 119.366,63.3685 116,60.5C 108.969,66.3669 101.803,72.0336 94.5,77.5C 76.3409,76.5032 58.0076,76.1698 39.5,76.5C 39.5,80.1667 39.5,83.8333 39.5,87.5C 37.4827,72.994 42.1494,60.8274 53.5,51C 55.0779,49.9055 56.7446,49.0722 58.5,48.5 Z"
        />
      </g>
      <g>
        <path
         
          fill="#4495e7"
          d="M 220.5,67.5 C 227.246,74.2866 229.913,82.2866 228.5,91.5C 228.5,89.5 228.5,87.5 228.5,85.5C 201.328,85.1688 174.328,85.5021 147.5,86.5C 140.274,80.7267 133.274,74.7267 126.5,68.5C 157.662,67.5018 188.996,67.1685 220.5,67.5 Z"
        />
      </g>
      <g>
        <path
         
          fill="#3e92e5"
          d="M 94.5,77.5 C 91.229,80.2678 88.0623,83.2678 85,86.5C 84.3333,87.1667 84.3333,87.8333 85,88.5C 91.4102,89.4777 97.9102,89.811 104.5,89.5C 104.5,91.8333 104.5,94.1667 104.5,96.5C 83.9931,96.1695 63.6598,96.5028 43.5,97.5C 41.1034,94.691 39.7701,91.3576 39.5,87.5C 39.5,83.8333 39.5,80.1667 39.5,76.5C 58.0076,76.1698 76.3409,76.5032 94.5,77.5 Z"
        />
      </g>
      <g>
        <path
          
          fill="#348ee3"
          d="M 228.5,91.5 C 226.715,101.937 221.381,109.937 212.5,115.5C 184.338,114.502 156.005,114.169 127.5,114.5C 127.5,106.167 127.5,97.8333 127.5,89.5C 133.842,89.6662 140.175,89.4995 146.5,89C 147.252,88.3292 147.586,87.4959 147.5,86.5C 174.328,85.5021 201.328,85.1688 228.5,85.5C 228.5,87.5 228.5,89.5 228.5,91.5 Z"
        />
      </g>
      <g>
        <path
          
          fill="#2f8ce1"
          d="M 104.5,96.5 C 104.5,105.833 104.5,115.167 104.5,124.5C 89.5403,125.45 75.2069,122.95 61.5,117C 53.5621,112.231 47.5621,105.731 43.5,97.5C 63.6598,96.5028 83.9931,96.1695 104.5,96.5 Z"
        />
      </g>
      <g>
        <path
         
          fill="#2788de"
          d="M 127.5,114.5 C 156.005,114.169 184.338,114.502 212.5,115.5C 207.392,119.045 201.726,121.545 195.5,123C 179.837,123.5 164.17,123.667 148.5,123.5C 148.5,133.167 148.5,142.833 148.5,152.5C 147.514,148.03 147.181,143.363 147.5,138.5C 140.5,138.5 133.5,138.5 126.5,138.5C 126.644,130.49 126.977,122.49 127.5,114.5 Z"
        />
      </g>
      <g>
        <path
          
          fill="#2080d8"
          d="M 126.5,138.5 C 133.5,138.5 140.5,138.5 147.5,138.5C 147.181,143.363 147.514,148.03 148.5,152.5C 155.345,152.042 162.012,152.542 168.5,154C 159.198,161.963 149.865,169.963 140.5,178C 138.835,178.788 137.169,179.122 135.5,179C 125.66,170.657 115.993,162.157 106.5,153.5C 112.807,152.669 119.141,152.169 125.5,152C 126.467,147.599 126.8,143.099 126.5,138.5 Z"
        />
      </g>
    </svg>
  ),
}

export default icon;