import { useEffect, useState } from "react";

/*
    Custom accordion -- Riyad
    Accordion Props
    className = classes
    activeClass = The active class need to be added when selected
*/

const CustomAccordion = (props) => {

    const [visible,setVisible] = useState(false);

    useEffect(() => {
        setVisible(props.expand)
    }, [props.expand])

    const handleVisibility = () => {
        // setVisible(!visible)
        // if(typeof props.onClick != 'undefined') props.onClick()
    }

    const getChilds = () => {
        if(Array.isArray(props.children)) {
            return props.children.map(elem => {
                if(elem !== undefined && elem.type.name === 'CustomAccordionBody') {
                    return <div onClick={e => e.stopPropagation()} className={elem.props.className}
                        style={{display:(visible?"block":"none")}}>
                            {elem.props.children}
                        </div>
                }
                return elem;
            })
        }
        return props.children;
    }

    const getActiveClass = () => {
        return visible ? (props.activeClass !== undefined ? props.activeClass : "trigger__active") : ""
    }

    return ( 
        <ul className={props.className + " " + getActiveClass()} onClick={handleVisibility}>
            <li>
                { getChilds() }
            </li>
        </ul> 
    );
}


export const CustomAccordionBody = (props) => {
    return ( 
        <div className={props.className}>{props.children}</div>
    );
}

export const CustomAccordionHeader = (props) => {
    const handleVisibility = () => {
        if(typeof props.onClick != 'undefined') props.onClick()
    }
    return ( 
        <div className={props.className} onClick={handleVisibility}>{props.children}</div>
    );
}

export default CustomAccordion;