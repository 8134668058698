import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentCredit } from "../../actions/globalDialerAction";

const Credit = () => {

    const dispatch = useDispatch();

    const { currentCredit } = useSelector(state => state.globalDialerReducer);

    window.updateCurrentCredit= ()=>{
        dispatch(getCurrentCredit())
    }

    return (
        <Link to="/billing" className="p-0 top--bar-credit-link">
            <span className="top--bar-credit-wrapper">
                $ <span className="top--bar-credit-span">{currentCredit === undefined ? '...' : Number(currentCredit).toFixed(2)}</span>
            </span>
        </Link>
     );
}
 
export default Credit;