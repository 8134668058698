import React from "react";
import Styles from "./Styles.module.css";
import {Checkbox, FormControlLabel, FormHelperText} from "@material-ui/core";

const TermsAndPolicy = ({ formData, a2pDlcError, callback }) => {
    const handleFormDataChange = (name, value) => {
        callback(name, value);
    }

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    }

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitleAnother}>Terms of Service & Privacy Policy</h1>
                <p className={Styles.contentDescription}>We will process data according to the <a href="#">Twilio Privacy Statement</a>.</p>
            </div>
            <div className={Styles.contentBody}>
                <FormControlLabel
                    className={Styles.termCheckbox}
                    control={
                        <Checkbox
                            name="acceptedTermsAndPolicy"
                            id="acceptedTermsAndPolicy"
                            color="primary"
                            onChange={(e)=>{handleFormDataChange("accept_term_privacy", e.target.checked ? "yes" : "no")}}
                            checked={formData.accept_term_privacy === "yes"}
                        />
                    }
                    label="I declare that the information provided is accurate. I acknowledge that Twilio will be processing the information provided for the purpose of identity verification, and that Twilio reserves the right to retain Business Profile information after account closure in The case of a traceback from an authority or equivalent."
                />
                {renderErrorMessage("accept_term_privacy")}
            </div>
        </div>
    );
}

export default TermsAndPolicy;