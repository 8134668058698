import React from 'react';
import If from "if-else-react";

class EachScheduleForActivity extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <option value="">{this.props.time.time}</option>
                {/*<If	condition={this.props.isSelected !== this.props.time.time}>*/}
                {/*    <div  className="timeslot-list myclass">*/}
                {/*        <span onClick={()=>this.props.handler(this.props.time.date, this.props.time.time)} >{this.props.time.time}</span>*/}
                {/*    </div>*/}
                {/*</If>*/}

            </React.Fragment>
        );
    }
}
export default EachScheduleForActivity;