import React, {useState} from "react";
import ContactDetailModule from "../GlobalContactDetails";

const ContactDetailsInitialState = {
    /* props data */
    contactId: null,
    id: null,
    from: '',
    title: () => {return 'Contact details'},
    dealInfo:null,
    callbackOnOpen: () => {},
    dealCallback: () => {},
    closeContactDetails: () => {},
    // loggedOnUser: null
};
/* 
open={props.open}
closeContactDetails={props.onClose}
title={props.title}
dealInfo={props.dealInfo || null}
id={props.id}
callbackOnOpen={props.callbackOnOpen}
from={props.from}
dealCallback={props.dealCallback}
loggedOnUser={props.loggedOnUser}
*/
const GlobalContactDetailsModule = () => {
    const [states, setStates] = useState(ContactDetailsInitialState);
    const [isOpenContactDetailsModal, setIsOpenContactDetailsModal] = useState(false);

    window.handleGlobalContactDetailsModal = info => {
        if (info.open) {
            setStates({
                ...states,
                contactId: info.id,
                id: info.id,
                from: info.from,
                title: info.title,
                dealInfo: info.dealInfo === undefined ? ContactDetailsInitialState.dealInfo : info.dealInfo,
                callbackOnOpen: typeof info.callbackOnOpen === 'undefined' ? ContactDetailsInitialState.callbackOnOpen : info.callbackOnOpen,
                dealCallback: typeof info.dealCallback === 'undefined' ? ContactDetailsInitialState.dealCallback : info.dealCallback,
                closeContactDetails: typeof info.closeContactDetails === 'undefined' ? ContactDetailsInitialState.closeContactDetails : info.closeContactDetails
            });
            setIsOpenContactDetailsModal(true);            
        } else {
            setIsOpenContactDetailsModal(false);
            setStates(ContactDetailsInitialState);
        }
    }

    const closeContactDetailsModal = () => {
        if(states.closeContactDetails !== undefined){
            states.closeContactDetails()
        }
        setIsOpenContactDetailsModal(false);
        setStates(ContactDetailsInitialState);
    }

    return (
        <React.Fragment>
            {isOpenContactDetailsModal &&
                <ContactDetailModule
                    open={isOpenContactDetailsModal}
                    onClose={closeContactDetailsModal}
                    {...states}
                />
            }
        </React.Fragment>
    );
}
export default GlobalContactDetailsModule;