import React, {useState, useEffect} from "react";
import {getVirtualNumbers, selectFromNumber, storeMultipleReducers} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";
import Utils from "../../../helpers/Utils";
import If, {Else} from 'if-else-react';
import ListSkeleton from "../../common/Skeletons/ListSkeleton";

const FromNumber = props => {
    const [virtualNumbers, setVirtualNumbers] = useState(props.virtualNumbers);
    // const [fromNumber, setFromNumber] = useState(props.fromNumber);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.virtualNumbers !== undefined) {
            setLoading(false);
            setVirtualNumbers(props.virtualNumbers);
        } else {
            setLoading(true);
            props.getVirtualNumbers();
        }
    }, [props.virtualNumbers]);


    const renderNumbers = () => {
        if (virtualNumbers !== undefined) {
            return virtualNumbers.map((number, i) => {
                return (<option key={`${number.virtual_number}${i}`} value={number.virtual_number} className={props.fromNumber === number.virtual_number ? 'selected-text' : ''}>{Utils.formatNumber(number.virtual_number)}</option>);
            });
        }
    }

    const selectFromNumber = (e) => {

        props.storeMultipleReducers({fromNumber : e.target.value});
    }

    return (
        <div className="call-form d-flex align-items-center">
            <span>Call From</span>
            <If condition={loading}>
                <ListSkeleton width={201} height={40}/>
            <Else />
                <select className="browser-default select-form" onChange={(e) => selectFromNumber(e)} value={props.fromNumber}>
                    {renderNumbers()}
                </select>
            </If>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        virtualNumbers: state.globalDialerReducer.virtualNumbers,
        fromNumber: state.globalDialerReducer.fromNumber,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVirtualNumbers: () => dispatch(getVirtualNumbers()),
        selectFromNumber: (params) => dispatch(selectFromNumber(params)),
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FromNumber);
