import * as ACTION from "../constants/signupActionTypes";

const initialState = {
    user : '',
    agencyId : undefined,
    isSignUpSuccess: false,
    otpNeededForUser: '',
    otpError: false,
    aboutUsItems: [],
    agencyStripe: [],
    companyCategories: [],
    packages: [],
    signUpPageSetting: null,
    googleCaptchaSiteKey: null,
    validationCheck: false,
    loading: true,
    selectedPackage: null,
    companyName: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    aboutUsItem: '',
    companyCategory: '',
    signupType: '',
    isSignupComplete: true,
    submitting: false
};

const signupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SIGNUP_SUCCESS:
            return {...state, isSignUpSuccess: action.payload}

        case ACTION.FETCH_SIGNUP_DATA_SUCCESS:
            return {
                ...state,
                agencyId: action.payload.agencyId,
                aboutUsItems: action.payload.aboutUsItems,
                agencyStripe: action.payload.agencyStripe,
                googleCaptchaSiteKey: action.payload.googleCaptchaSiteKey,
                companyCategories: action.payload.companyCategories,
                packages: action.payload.packages,
                signUpPageSetting: action.payload.signUpPageSetting,
                loading: false
            }

        case ACTION.UPDATE_VALIDATION_CHECK:
            return {...state, ...action.payload}

        case ACTION.UPDATE_LOADING:
            return {...state, loading: action.payload}

        case ACTION.SELECT_PACKAGE:
            return {...state, selectedPackage: action.payload}

        case ACTION.SIGN_UP_SUCCESS:
            return {...state, isSignUpSuccess: true, submitting: false, otpNeededForUser: action.payload?.apiKey ? action.payload.apiKey : '', otpError: action.payload?.otpError ? action.payload.otpError : state.otpError}
        case ACTION.UPDATE_SUBMITTING:
            return {...state, submitting: action.payload}
        case ACTION.HANDLE_STATE_CHANGE:
            return {...state, ...action.payload}

        default:
            return state;
    }
}

export default signupReducer;
