import React,{useState,useEffect} from 'react'
import Switch from "react-switch";
import './custom-switch-style.css'

const UnChackedIcon = (props) =>{
  return(
    <div className="custom-switch-off-icon"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontSize: 16,
        color: "#8f9eb0"
      }}>
      {props.text !== undefined ? props.text : "Off"}
    </div>
    )
}
const CheckedIcon = (props) =>{
  return(
    <div className="custom-switch-on-icon"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        fontSize: 16,
        color: '#FFF',
        padidingTop:'3px'
      }}>
     {props.text !== undefined ? props.text : "On"}
    </div>
    )
}

const SwitchC = ({status, onChange, brandColor, onText, offText, minWidth40= false}) => {
  const [checked, setChecked] = useState(status);
  useEffect(() => {
    setChecked(status)
  }, [status]) 
  const handleChange = () => {
    // setChecked(!checked)
    onChange(!checked)
  };
  return (
    <Switch 
        className={`${checked?'custom-switch custom-switch-active-mode':'custom-switch custom-switch-inactive-mode'} ${minWidth40 ? 'active-width-40' : ''}`}
        onChange={handleChange} 
        checked={checked} 
        uncheckedIcon={<UnChackedIcon text={offText} />}
        checkedIcon={<CheckedIcon brandColor = {brandColor !== undefined ? brandColor : '#f94f72'} text={onText}/>}
        boxShadow='0 0 0px 0px #3bf'
        activeBoxShadow='0 0 0px 0px #3bf'
        onHandleColor={'#fff'}
        offHandleColor='#8f9eb0'
        onColor={brandColor !== undefined ? brandColor : '#f94f72'}
        height={24}
        width={minWidth40 ? 40 : 60}
    />
  )
}
export default SwitchC;