import {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router';
import './sharePage.css';
import {DeviceHub} from "@material-ui/icons";
import {nylasAccessTokenConfig} from "../../api/notificationApi";

const NylasActivatedPageV2 = () => {
    const history = useHistory();
    const codeObj = useLocation().search;
    const code = new URLSearchParams(codeObj).get('code');
    const [dots, setDots] = useState('');
    const [message, setMessage] = useState('Wait while request is being verified');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCloseAble, setIsCloseAble] = useState(false);

    useEffect(() => {
        if (isProcessing){
            const intervalId = setInterval(() => {
                setDots(prevDots => prevDots.length < 3 ? prevDots + '.' : '');
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [isProcessing]);

    useEffect(() => {
        (async () => {
            setIsProcessing(true);
            const resp = await nylasAccessTokenConfig({ code: code });

            if (resp && resp.success) {
                setTimeout(() => {
                    setIsProcessing(false);
                    setIsCloseAble(false);
                    setMessage('🎉 Congratulations! Request verified successfully');

                    setTimeout(() => {
                        setMessage('Please wait while redirecting...');

                        setTimeout(() => {
                            history.push('/user/profile/email-settings?from=connect-email', { tab: 3 });
                        }, 1000);
                    }, 2000);
                }, 3000);
            } else {
                setTimeout(() => {
                    setIsProcessing(false);
                    setIsCloseAble(false);
                    setMessage('Sorry! Unable to verify the request. Please try again later!');

                    setTimeout(() => {
                        setMessage('Please wait while redirecting...');

                        setTimeout(() => {
                            history.push('/user/profile/email-settings', { tab: 3 });
                        }, 1000);
                    }, 2000);
                }, 3000);
            }
        })();
    },[]);

    return ( 
        <div className="share_page__wrapper trigger_share">
            <div className="share_page__inner_wrapper">
                <div className="nylas_connect">
                    <DeviceHub/>
                </div>
                <h4>Connect Personal Email</h4>
                {
                    <h5 className="mt-4">{message}
                        {
                            isProcessing ?
                            <span style={{position: 'absolute'}}>{dots}</span> :
                                ''
                        }
                    </h5>
                }
                {
                    !isCloseAble ?
                        <p>Please do not close the tab or change the page</p> :
                        ''
                }
            </div>
        </div>
    );
}

export default NylasActivatedPageV2;