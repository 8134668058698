//save followup
export const SAVE_FOLLOWUP_IN_CARD_BROADCAST = 'SAVE_FOLLOWUP_IN_CARD_BROADCAST';

export const UPDATE_CAMPAIGN_EMAIL_STEP_SETTING_IN_CARD_BROADCAST = 'UPDATE_CAMPAIGN_EMAIL_STEP_SETTING_IN_CARD_BROADCAST';
/* MESSAGE TEMPLATE END */
//Card broadcast
export const GET_MAIL_CATEGORY_IN_CARD_BROADCAST = 'GET_MAIL_CATEGORY_IN_CARD_BROADCAST';
export const SET_MAIL_CATEGORY_IN_CARD_BROADCAST = 'SET_MAIL_CATEGORY_IN_CARD_BROADCAST';
export const GET_MAIL_TEMPLATES_IN_CARD_BROADCAST = 'GET_MAIL_TEMPLATES_IN_CARD_BROADCAST';

export const GET_POSTCARD_CATEGORY_IN_CARD_BROADCAST = 'GET_POSTCARD_CATEGORY_IN_CARD_BROADCAST';
export const SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST = 'SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST';
export const GET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST = 'GET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST';
export const SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST = 'SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST';
export const GET_THANKIOGREETINGCARD_CATEGORY_IN_CARD_BROADCAST = 'GET_THANKIOGREETINGCARD_CATEGORY_IN_CARD_BROADCAST';
export const SET_THANKSIOGREETINGCARD_CATEGORY_IN_CARD_BROADCAST = 'SET_THANKSIOGREETINGCARD_CATEGORY_IN_CARD_BROADCAST';
export const GET_LETTER_CATEGORY_IN_CARD_BROADCAST = 'GET_LETTER_CATEGORY_IN_CARD_BROADCAST';
export const SET_LETTER_CATEGORY_IN_CARD_BROADCAST = 'SET_LETTER_CATEGORY_IN_CARD_BROADCAST';
export const GET_GIFT_CATEGORY_IN_CARD_BROADCAST = 'GET_GIFT_CATEGORY_IN_CARD_BROADCAST';
export const SET_GIFT_CATEGORY_IN_CARD_BROADCAST = 'SET_GIFT_CATEGORY_IN_CARD_BROADCAST';
export const CREATE_POSTCARD_TEMPLATE_IN_CARD_BROADCAST = 'CREATE_POSTCARD_TEMPLATE_IN_CARD_BROADCAST';

export const GET_PERSONALIZEFIELDS_IN_CARD_BROADCAST= 'GET_PERSONALIZEFIELDS_IN_CARD_BROADCAST';
export const SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST= 'SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST';

export const SET_SELECTED_SIZE= 'SET_SELECTED_SIZE';
export const SET_HANDWRITING_ID= 'SET_HANDWRITING_ID';
export const SET_SELECTED_Template= 'SET_SELECTED_Template';
export const SET_SELECTED_CATEGORY_ID= 'SET_SELECTED_CATEGORY_ID';
export const SET_CATEGORY_LIST= 'SET_CATEGORY_LIST';
export const SET_TEMPLATE_LIST= 'SET_TEMPLATE_LIST';
export const SET_USE_THANKISO= 'SET_USE_THANKISO';
export const SET_STEP_NO= 'SET_STEP_NO';
export const SET_INITIAL_STATES= 'SET_INITIAL_STATES';

export const SET_THANKSIO_GIFT_PRICE = 'SET_THANKSIO_GIFT_PRICE';

export const UPDATE_MULTIPLE_REDUCERS= 'UPDATE_MULTIPLE_REDUCERS';

export const GET_DIRECT_MAIL_PRICE = 'GET_DIRECT_MAIL_PRICE';
export const SET_DIRECT_MAIL_PRICE = 'SET_DIRECT_MAIL_PRICE';

