import React from "react";
import Styles from "./Style.module.css";

const SampleMessagesLearnMore = () => {
    return(
        <div className={Styles.learnMoreInnerWrapper}>
            <section className={Styles.learnMoreSection}>
                <p className={`${Styles.learnMoreDesc} ${Styles.marginBottom5}`}>Sample messages should reflect actual messages to be sent under campaign.</p>
                <ul className={Styles.learnMoreDescUl}>
                    <li className={Styles.learnMoreDesc}>Do not use placeholder text for your sample messages</li>
                    <li className={Styles.learnMoreDesc}>Use brackets to indicate templated fields in sample message</li>
                    <li className={Styles.learnMoreDesc}>Consistency in sample message, use case, and campaign description</li>
                </ul>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Successful Example:</h5>
                <p className={Styles.learnMoreDesc}>"ACME 2FA Notice: Here is your one time password: 1234Call [phone-number] to report if this request was not made by you."</p>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Failure Example:</h5>
                <p className={Styles.learnMoreDesc}>"You have an upcoming appointment"
                    <p>(Opt-out is not provided, campaign will be rejected.)</p>
                </p>
            </section>
        </div>
    );
}

export default SampleMessagesLearnMore;