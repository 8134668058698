import React from "react";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "../common/icon/EditIcon";

import { RightSideStyle } from "../RightSideStyle";

import DraggableIcon from "../common/icon/DraggableIcon";

const customTitleStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: 14,
    fontWeight: 600,
    color: "var(--dark_blue)",
    whiteSpace: "nowrap",
  },
}));

function CustomTitle({ title, handleOpen,iconHide }) {
  const { flexCenterBetween, flexCenter, titleIcon } = RightSideStyle();
  const { titleStyle } = customTitleStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          className={flexCenterBetween}
          sx={{ flexWrap: "wrap", paddingRight: "10px" }}
        >
          <Typography variant="body2" className={titleStyle}>
            {title}
          </Typography>
        </Box>

        <Box flex={1}>
          <Divider />
        </Box>
        <Box
          // className={`${flexCenter} `}
           className={`${flexCenter} ${iconHide}`}
          sx={{
            height: "20px !important",
            transition: "all .35s",
            overflow: "hidden",
            gap:'6px'
          }}
        >
          <IconButton className={`${titleIcon}`} size="small">
            <DraggableIcon fill="currentColor" />
          </IconButton>
          <IconButton className={titleIcon} size="small" onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomTitle;
