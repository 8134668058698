import React, { useMemo } from 'react'

const REALISTIC_STYLES = {
    '1' : 'https://dashboard.thanks.io/images/handwriting/hiclarice.png',
    '4' : 'https://dashboard.thanks.io/images/handwriting/foxylady.png',
    '7' : 'https://dashboard.thanks.io/images/handwriting/shoppinglist.png',
    '8' : 'https://dashboard.thanks.io/images/handwriting/lazynote.png',
    '9' : 'https://dashboard.thanks.io/images/handwriting/blurredlines.png',
    '10' : 'https://dashboard.thanks.io/images/handwriting/birthdaycard.png',
    '11' : 'https://dashboard.thanks.io/images/handwriting/scully.png',
    '12' : 'https://dashboard.thanks.io/images/handwriting/wowsignal.png',
}

const BOLD_STYLES = {
    '2' : 'https://dashboard.thanks.io/images/handwriting/practicedsharpie.png',
    '3' : 'https://dashboard.thanks.io/images/handwriting/boldnote.png',
    '5' : 'https://dashboard.thanks.io/images/handwriting/thatdude.png',
    '6' : 'https://dashboard.thanks.io/images/handwriting/normalprint.png',
}

const INTERNATIONAL_STYLES = {
    '13' : 'https://dashboard.thanks.io/images/handwriting/arabic.png',
    '14' : 'https://dashboard.thanks.io/images/handwriting/chinese.png',
    '15' : 'https://dashboard.thanks.io/images/handwriting/cyrillic.png',
    '16' : 'https://dashboard.thanks.io/images/handwriting/hebrew.png',
    '17' : 'https://dashboard.thanks.io/images/handwriting/japanese.png',
    '18' : 'https://dashboard.thanks.io/images/handwriting/korean.png',
    '19' : 'https://dashboard.thanks.io/images/handwriting/vietnamese.png'
}


export const HandwritingPopoverItems = (props) => {

    const listGenerator = (styleArr) => {
        return props.list.map(each => {
            if(!styleArr[each.handwriting_style_id]) {
                return "";
            }

            return <img
                    title={each.name}
                    onClick={() => props.onChangeStyle(each.handwriting_style_id)} 
                    className={each.handwriting_style_id === props.selected ? 'selected' : ''} 
                    // src={styleArr[each.handwriting_style_id]}
                    src={each?.sample}
                    alt="handwriting_prev"/>
        })
    }

    const realisticView = useMemo(() => listGenerator(REALISTIC_STYLES), [props.selected]);  // eslint-disable-line react-hooks/exhaustive-deps
    const boldView = useMemo(() => listGenerator(BOLD_STYLES), [props.selected]) // eslint-disable-line react-hooks/exhaustive-deps
    const internationalView = useMemo(() => listGenerator(INTERNATIONAL_STYLES), [props.selected]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
        <div className='styles_pics_wrapper'>
          <b>Realistic</b>
          <small className='styles__recommended_text'>(Recommended)</small>
          {realisticView}
        </div>
        <div className='styles_pics_wrapper'>
          <b className='mb-3'>Bold</b>
          {boldView}
        </div>
        <div className='styles_pics_wrapper'>
          <b className='mb-3'>International</b>
          {internationalView}
        </div> 
    </>
  )
}
