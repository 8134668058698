import React, { Fragment } from "react";
import {
    IconButton,
    Typography
} from "@material-ui/core";
import Styles from "./ResponseV2.module.css";
import CloseIcon from "@material-ui/icons/Close";

const ResponseV2 = ({ response, hasError, hasSuccess, handleClose }) => {

    return (
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={Styles.headerTitle} variant="h5">A2P/10DLC Campaign Registration</Typography>
                {
                    hasSuccess !== "" &&
                    <IconButton size="small" onClick={() => handleClose({onlyClose: true, redirect: true})}>
                        <CloseIcon/>
                    </IconButton>
                }
            </div>

            <div className={Styles.modalBody}>
                <div className={Styles.bodyContainer}>
                    <div className={Styles.responseMessage}>
                        { hasError === "" ?
                            <div className={Styles.thanksMessage}>Thank you for submitting your campaign registration!</div> :
                            <div className={Styles.sorryMessage}>{hasError}</div>
                        }
                        {
                            hasSuccess !== "" &&
                            <div className={Styles.statueMessage}>Your registration is currently <span className={Styles.underlineText}>pending</span> approval.</div>
                        }
                    </div>
                    <div className={Styles.helperTextContainer}>
                        {
                            hasSuccess !== "" ?
                                <Fragment>
                                    <div className={Styles.howToCheck}>How to check your registration status:</div>
                                    <div className={Styles.direction}>Click Settings on the left sidebar then scroll down to <span className={Styles.bold}>Trust Center</span></div>
                                </Fragment> :
                                <div className={Styles.responseMessageButton} onClick={() => handleClose({onlyClose: true, redirect: false})}>Try again</div>
                        }
                    </div>
                </div>
            </div>
            <div className={`${Styles.modalFooter} ${Styles.modalFooterCenter}`}>
                <span className={Styles.footerMessage}><span className={Styles.bold}>Note:</span> A2P/10DLC registration is an industry wide regulation and is not unique to our platform. All submissions are handled directly by the phone companies and we have no involvement in the registration process.We simply submit the form on your behalf.</span>
            </div>
        </Fragment>
    );
};

export default ResponseV2;