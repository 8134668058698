import React  from "react";
import {
    Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  makeStyles,
} from "@material-ui/core";

import CustomModal from "../../RightSide/common/CustomModal/CustomModal";


const useStyles = makeStyles((theme) => ({
  dialogContentWidth: {
    width: "516px",
    minHeight:'300px'
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
    "&.Mui-selected": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
  },
}));

const EditDNCModal = ({ open, onClose }) => {


  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={1200}
    >
      <CustomModal
        title={"DNC Log"}
        open={open}
        onClose={onClose}
      />
      <DialogContent className={classes.dialogContentWidth}>
        <DialogContentText id="alert-dialog-description">
         <Box display={'flex'} justifyContent={'center'}>
            <Typography variant="body1">No logs availbale</Typography>
         </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default EditDNCModal;
