import {useContext, useState, useEffect} from 'react'
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from '../../components/Accordion/CustomAccordion'
import { ContactDetailContext } from "../../reducer/GlobalReduers"
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { icons } from '../../icons';
import ApiList from '../../ApiList';
import { List } from './List';
import LinkFile from '../../LinkFile';


const Appointment = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [expand, setExpand] = useState(false)
    const [activity, setActivity] = useState([])
    const [doneActivity, setDoneActivity] = useState([])
    const [loading, setLoading] = useState(false)
    const [showAddAppointModal, setShowAddAppointModal] = useState(false)

    const [editAppointment, setEditAppointment] = useState(null)
    const [editData, setEditData] = useState(null)

    const [showDoneTask, setShowDoneTask] = useState(false)

    useEffect(() => {
        if(expand){
            getPeopleActivity()
        }
    }, [expand])

    const refresh = () => {
        getPeopleActivity()
    }

    const getPeopleActivity = () => {
        setLoading(true)
        ApiList.getPeopleActivity({
            contact_id: detailStates.contactId
        }).then(res => {
            let response = res.data;
            if(response.success){
                let filter_data = response.data.filter(item => (parseInt(item.task_id) === 2 && parseInt(item.status) === 1))
                setActivity(filter_data)
                let filter_done_data = response.data.filter(item => (parseInt(item.task_id) === 2 && parseInt(item.status) === 2))
                setDoneActivity(filter_done_data)
            }
            setLoading(false)
        })
    }

    const editActivity = (id, data) => {
        // setEditAppointment(id)
        // setEditData(data)
        // setShowAddAppointModal(true)
        window.openGlobalActivityModal({
            // subUserList:this.props.subUserList,
            contactDetails:detailStates.contactDetails,
            editData:data,
            callBack:getPeopleActivity,
            taskType:'Appointment'
        })
    }

    /* handle add appointment modal */
    const handleAddAppointment = (event, status) => {
        event.stopPropagation()
        // setShowAddAppointModal(status)
        window.openGlobalActivityModal({
            // subUserList:this.props.subUserList,
            contactDetails:detailStates.contactDetails,
            // editData:this.state.editData,
            callBack:getPeopleActivity,
            taskType:'Appointment'
        })
    }

    const renderList = (list) => {
        let showList = [];
        list.map((item, index) => {
            showList.push(
                <List 
                    data={item} 
                    key={index} 
                    index={index} 
                    status={1} 
                    expand={() => setExpand(false)} 
                    refresh={refresh} 
                    makeDone={""}
                    editActivity={editActivity}/>
            )
        })
        return showList;
    }

    const renderDoneList = (list) => {
        let showList = [];
        list.map((item, index) => {
            showList.push(
                <List 
                    data={item} 
                    key={item.id} 
                    index={item.id} 
                    status={2}
                    makeDone={null}
                />
            )
        })
        return showList;
    }

    return(
        <div className="g-more-info-left g-more-info-column mt-4">
            <CustomAccordion expand={expand} className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span className="g-header-title"><AssignmentIcon /> Appointment </span>
                    <div className="g-header-action-content">
                        <span className="g-add-task-button" onClick={(event) => handleAddAppointment(event, true)}><AddCircleIcon /></span>
                        <span className="g-custom-caret">{icons.caretIcon}</span>
                    </div>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>
                    <div className="g-contact-appointment-content">
                        {loading && <span className="g-loading-content">Loading...</span> }
                        {!loading && 
                        <>
                            <div className="g-contact-appointment-list awesome__scroll_bar">
                                {activity.length == 0 ?
                                    <span className="g-no-appointment">No active activity found</span>
                                    :
                                    renderList(activity)
                                }                                
                            </div>

                            {doneActivity.length > 0 &&
                            <div className="g-contact-appointment-list awesome__scroll_bar">
                                <span className="g-done_activity_title">Mark as done appointment list : <span onClick={() => setShowDoneTask(!showDoneTask)} style={{textDecoration:'underline'}}>{showDoneTask ? 'Hide' : 'Show'}</span></span>
                                {showDoneTask && renderDoneList(doneActivity)}
                            </div>
                            }
                        </>
                        }
                    </div>
                </CustomAccordionBody>
            </CustomAccordion>

            {/* add appointment modal */}
            {/* {showAddAppointModal &&
                 <LinkFile.addAppointment 
                    open={showAddAppointModal} 
                    onClose={() => {
                        setShowAddAppointModal(false); 
                        setExpand(false);
                        setEditAppointment(null);
                        setEditData(null);
                    }} 
                    taskId={2} 
                    activityId={editAppointment}
                    editData={editData}
                />
            } */}
        </div> 
    )
}
export default Appointment;

