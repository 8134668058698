/* eslint-disable eqeqeq */
import React, {useContext, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import {
//     updateArrSlotTimeHour1, updateArrSlotTimeMin1,
//     updateDay, updateGreetingOption, updateGreetingsOptionTimeHour1, updateGreetingsOptionTimeMin1,
//     updateHour, updateLaterHour, updateLaterMin,
//     updateMin,
//     updateRecurringDay, updateRecurringTimeHour1, updateRecurringTimeMin1, updateStartDate,
//     updateTabValue, updateTime
// } from "../../../actions/marketingAction";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';
import GlobalCustomSelect from "../globals/FormComponents/GlobalCustomSelect";
import { storeMultipleReducers } from "./redux/cardBroadcastAction";
import { CardMultiStepContext } from "./CardMultiStepForm";

const hours = [
    0,1,2,3,4,5,6,7,8,9,10,11,12
];
const minutes = [
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,35,40,45,50,55
];

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ScheduleSetting = props => {

    // const [tabValue, setTabValue] = useState(1);
    // const [hour, setHour] = useState(0);
    // const [min, setMin] = useState(0);
    // const [day, setDay] = useState(2);
    const [laterHour, setLaterHour] = useState('08');
    const [laterMin, setLaterMin] = useState('00');
    // const [startDate, setStartDate] = useState(new Date());
    const [arrSlotTimeHour1, setArrSlotTimeHour1] = useState('08');
    const [arrSlotTimeMin1, setArrSlotTimeMin1] = useState('00');
    // const [greetingOption, setGreetingOption] = useState(1);
    const [greetingsOptionTimeMin1, setGreetingsOptionTimeMin1] = useState('00');
    const [greetingsOptionTimeHour1, setGreetingsOptionTimeHour1] = useState('08');
    const [recurringTimeHour1, setRecurringTimeHour1] = useState('00');
    const [recurringTimeMin1, setRecurringTimeMin1] = useState('00');
    // eslint-disable-next-line no-unused-vars

    const cardMultiStepContext = useContext(CardMultiStepContext)

    const handleChange = (event, newValue) => {

        props.storeMultipleReducers({tabValue: newValue})
        // setTabValue(newValue);
        // props.updateTabValue(newValue);
        // if(props.tabChangeCallback !== undefined){
        //     props.tabChangeCallback()
        // }
    };

    useEffect(()=>{
        if(cardMultiStepContext.editableCampaign){
            // if(props.tabValue !== cardMultiStepContext.editableCampaign.type){
                if(cardMultiStepContext.editableCampaign.type === 1){
                    let timeDate = cardMultiStepContext.editableCampaign.hour.split(':');
                    
                    props.storeMultipleReducers({tabValue: cardMultiStepContext.editableCampaign.type, hour: [0], time : timeDate[1]})
                }else if(cardMultiStepContext.editableCampaign.type === 2){

                    let timeDate = cardMultiStepContext.editableCampaign.hour.split(':');

                    if(cardMultiStepContext.editableCampaign.day > 1 || timeDate[0] > 12){
                        setLaterHour(timeDate[0])
                        setLaterMin(timeDate[1])
                        props.storeMultipleReducers({day : cardMultiStepContext.editableCampaign.day, 
                            tabValue: cardMultiStepContext.editableCampaign.type, time: cardMultiStepContext.editableCampaign.hour})
                    }else{
                        let tmpHour = timeDate[0];
                        if(tmpHour[0] === '0'){
                            tmpHour = tmpHour.replace('0','')
                        }
                        props.storeMultipleReducers({tabValue: 1, hour: tmpHour, min : timeDate[1]})

                    }
                }
                else if(cardMultiStepContext.editableCampaign.type === 3){
                    if(cardMultiStepContext.editableCampaign.fixed_date && cardMultiStepContext.editableCampaign.fixed_date!== ''){
                        
                        if(cardMultiStepContext.directMailCampaign.timezone && cardMultiStepContext.directMailCampaign.timezone!=''){
                            let tmpTimeDate = window.globalTimezoneConversionToDifferentTimezone(cardMultiStepContext.editableCampaign.fixed_date +' '+cardMultiStepContext.editableCampaign.hour , 'UTC',cardMultiStepContext.directMailCampaign.timezone, 'HH:mm:ss');
                            let timeDate = tmpTimeDate.split(':')
                            setArrSlotTimeHour1(timeDate[0]);
                            setArrSlotTimeMin1(timeDate[1])
                            props.storeMultipleReducers({
                                startDate : window.globalTimezoneConversionToDifferentTimezone(cardMultiStepContext.editableCampaign.fixed_date +' '+cardMultiStepContext.editableCampaign.hour , 'UTC',cardMultiStepContext.directMailCampaign.timezone, ''), 
                                tabValue: cardMultiStepContext.editableCampaign.type,
                                 time: tmpTimeDate})
                        
                        }else{
                            let tmpTimeDate = window.globalTimezoneConversionToDifferentTimezone(cardMultiStepContext.editableCampaign.fixed_date +' '+cardMultiStepContext.editableCampaign.hour , 'UTC','', 'HH:mm:ss');
                            let timeDate = tmpTimeDate.split(':')
                            setArrSlotTimeHour1(timeDate[0]);
                            setArrSlotTimeMin1(timeDate[1])
                            props.storeMultipleReducers({
                                startDate : window.globalTimezoneConversionToDifferentTimezone(cardMultiStepContext.editableCampaign.fixed_date +' '+cardMultiStepContext.editableCampaign.hour , 'UTC','', ''), 
                                tabValue: cardMultiStepContext.editableCampaign.type,
                                 time: tmpTimeDate})
                        
                        }
                            
                    }

                    
                }
                else if(cardMultiStepContext.editableCampaign.type === 4){
                    if(cardMultiStepContext.editableCampaign.greeting_option && cardMultiStepContext.editableCampaign.greeting_option!== ''){
                        
                            let timeDate = cardMultiStepContext.editableCampaign.hour.split(':')
                            setGreetingsOptionTimeHour1(timeDate[0]);
                            setGreetingsOptionTimeMin1(timeDate[1])
                            props.storeMultipleReducers({
                                tabValue: cardMultiStepContext.editableCampaign.type,
                                 time: cardMultiStepContext.editableCampaign.hour,
                                greetingOption : cardMultiStepContext.editableCampaign.greeting_option})
                        
                        
                            
                    }

                    
                }
                else if(cardMultiStepContext.editableCampaign.type === 5){
                    if(cardMultiStepContext.editableCampaign.day && cardMultiStepContext.editableCampaign.day!== ''){
                        
                            let timeDate = cardMultiStepContext.editableCampaign.hour.split(':')
                            setRecurringTimeHour1(timeDate[0]);
                            setRecurringTimeMin1(timeDate[1])
                            props.storeMultipleReducers({
                                tabValue: cardMultiStepContext.editableCampaign.type,
                                 time: cardMultiStepContext.editableCampaign.hour,
                                day :cardMultiStepContext.editableCampaign.day})
                    }

                    
                }

            // }
        }
    },[])

    // useEffect(() => {
    //     setTabValue(props.tabValue);
    //     setHour(props.hour);
    //     setMin(props.min);
    //     setDay(props.day);
    // }, [props.tabValue, props.hour, props.min, props.day])

    // useEffect(() => {
    //     setRecurringTimeHour1(props.recurringTimeHour1);
    //     setRecurringTimeMin1(props.recurringTimeMin1);
    // }, [props.recurringTimeHour1, props.recurringTimeMin1])

    // useEffect(() => {
    //     setGreetingOption(props.greetingOption)
    // }, [props.greetingOption])

    // useEffect(() => {
    //     setGreetingsOptionTimeHour1(props.greetingsOptionTimeHour1);
    //     setGreetingsOptionTimeMin1(props.greetingsOptionTimeMin1);
    // }, [props.greetingsOptionTimeHour1, props.greetingsOptionTimeMin1])

    // useEffect(() => {
    //     setLaterHour(props.laterHour);
    //     setLaterMin(props.laterMin);
    // }, [props.laterHour, props.laterMin])


    // useEffect(() => {
    //     setStartDate(props.startDate)
    // }, [props.startDate])

    // useEffect(() => {
    //     setArrSlotTimeHour1(props.arrSlotTimeHour1);
    //     setArrSlotTimeMin1( props.arrSlotTimeMin1);
    // }, [props.arrSlotTimeHour1, props.arrSlotTimeMin1])

    const hourChangeHandler = (value) => {

        props.storeMultipleReducers({hour :value})
        // setHour(value);
        // props.updateHour(value);
    }

    const minChangeHandler = (value) => {

        props.storeMultipleReducers({min :value})

        // setMin(value);
        // props.updateMin(value);
    }

    const dayChangeHandler = (value) => {

        props.storeMultipleReducers({day:value})
        // setDay(value);
        // props.updateDay(value);
    }

    const timeChangeHandler = value => {
        let selectedTime = value.split(':')
        let arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        let arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setLaterHour(arrSlotTimeHour1);
        setLaterMin(arrSlotTimeMin1);

        props.storeMultipleReducers({time : arrSlotTimeHour1+':'+arrSlotTimeMin1 + ":00"})
        // props.updateTime(arrSlotTimeHour1+':'+arrSlotTimeMin1 + ":00");
    }

    const timeIntervalOptions = interval => {
        let option = [];
        for (let ap = 1; ap <= 2; ap++) {
            for (let i = 0; i < 12; i++){
                for(let j = 0; j < 60/interval; j++) {
                    let hv = i;
                    let h = i;
                    if(i == 0) h = 12
                    if(ap != 1) hv = hv + 12;
                    option.push({
                        label : (('0' + h).slice(-2)+':'+('0' + interval*j).slice(-2)+' '+(ap==1?'AM':'PM')),
                        value: (('0' + hv).slice(-2)+':'+('0' + interval*j).slice(-2))
                    })
                }
            }
        }
        return option;
    }

    const timeChangeFixed = value => {

        let selectedTime = value.split(':')
        var arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        var arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setArrSlotTimeHour1(arrSlotTimeHour1);
        setArrSlotTimeMin1(arrSlotTimeMin1);

        props.storeMultipleReducers({time : arrSlotTimeHour1+':'+arrSlotTimeMin1 + ":00"})
        // props.updateTime(arrSlotTimeHour1+':'+arrSlotTimeMin1 + ":00");
    }

    const onGreetingsOptionChangeFixed = value => {

        let selectedTime = value.split(':')
        let arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        let arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setGreetingsOptionTimeHour1(arrSlotTimeHour1);
        // props.updateGreetingsOptionTimeHour1(arrSlotTimeHour1);

        setGreetingsOptionTimeMin1(arrSlotTimeMin1);
        // props.updateGreetingsOptionTimeMin1(arrSlotTimeMin1);

        props.storeMultipleReducers({time : arrSlotTimeHour1 + ":" + arrSlotTimeMin1 +":00"})
        // props.updateTime(arrSlotTimeHour1 + ":" + arrSlotTimeMin1 +":00")
    }

    const greetingOptionChangeHandler = value => {

        props.storeMultipleReducers({ greetingOption : value})
        // setGreetingOption(value);
        // props.updateGreetingOption(value);
    }

    const startDateChangeHandler = value => {
        if(!moment(value).isValid()){

            props.storeMultipleReducers({startDate : null})
            // setStartDate(null)
            props.updateStartDate(null)
            return
        }

        props.storeMultipleReducers({startDate : moment(value).format('MM/DD/YYYY')})
        // setStartDate(moment(value).format('MM/DD/YYYY'));
        // props.updateStartDate(moment(value).format('MM/DD/YYYY'));
    }

    const ongRecurringChangeFixed = value => {

        let selectedTime = value.split(':')
        let arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        let arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setRecurringTimeHour1(arrSlotTimeHour1);
        // updateRecurringTimeHour1(arrSlotTimeHour1);

        setRecurringTimeMin1(arrSlotTimeMin1);
        // updateRecurringTimeMin1(arrSlotTimeMin1);

        props.storeMultipleReducers({ time : arrSlotTimeHour1 + ":" + arrSlotTimeMin1 +":00"})
        // props.updateTime(arrSlotTimeHour1 + ":" + arrSlotTimeMin1 +":00")
    }

    return (
        <div className="select_followup_buttons">
            <h3 className="mb-3">Select when to send this followup</h3>
            <div className="massage_sending_button d-flex justify-content-center">
                <Paper square>
                    <Tabs
                        value={props.tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                        className="campaign_module_schedule_tab"
                    >
                        <Tab label="Immediately" />
                        <Tab label="Later the same day" />
                        <Tab label="On a later day" />
                        <Tab label="Custom Date" disabled={ !props.showAllTimeSetting } />
                        <Tab label="Greetings" disabled={ !props.showAllTimeSetting } />
                        <Tab label="Recurring" disabled={ !props.showAllTimeSetting } />
                    </Tabs>

                    <TabPanel className="campaign_module_box_text" value={props.tabValue} index={0}>
                        Setting will be triggered immediately after receiving lead
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={props.tabValue} index={1}>
                        <div className="form-group d-flex justify-content-center align-items-center global__custom_select">
                            <div className='pr-2 d-flex align-items-center'>
                                <GlobalCustomSelect
                                    placeholder="Hours"
                                    data={hours}
                                    value={props.hour}
                                    handleOnChange={(event) => hourChangeHandler(event.target.value)}
                                    value_field="__FULL_DATA__"
                                    title_field="__FULL_DATA__"
                                />
                                hours
                            </div>
                            <div className="pr-2 d-flex align-items-center">
                                <GlobalCustomSelect
                                    placeholder="Mins"
                                    data={minutes}
                                    value={props.min}
                                    handleOnChange={(event) => minChangeHandler(event.target.value)}
                                    value_field="__FULL_DATA__"
                                    title_field="__FULL_DATA__"
                                />
                                minutes later
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={props.tabValue} index={2}>
                        <div className="form-group d-flex justify-content-center w-100">
                            <div className='pr-2 d-flex align-items-center position-relative'>
                                Day
                                <div className="input-field subject_input selects_fields">
                                    <input 
                                        id="email-setting-subject-input-field" 
                                        style={{marginBottom: 0}} 
                                        type="number" 
                                        min={1} 
                                        max={1000} 
                                        placeholder="Days" 
                                        value={props.day} 
                                        onChange={(event) => {
                                            if(/^\d+$/.test(event.target.value)){
                                                if(event.target.value > 1000){
                                                    dayChangeHandler(1000)
                                                }else if(event.target.value < 1){
                                                    dayChangeHandler(1)
                                                }
                                                else{
                                                    dayChangeHandler(event.target.value)
                                                }
                                            }
                                            else{
                                                dayChangeHandler(1)
                                            }                                            
                                        }} 
                                    />
                                </div>
                                at
                                <span className="day-select-message">Day range between 1 to 1000</span>
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        value={laterHour + ':' + laterMin}
                                        handleOnChange={(event) => timeChangeHandler(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={props.tabValue} index={3}>
                        <div className="form-group d-flex justify-content-center w-100">
                            <div className="pr-2 d-flex align-items-center date_input">
                                Date
                                <DatePicker placeholderText="MM/DD/YYYY" minDate={new Date()} className="trigger__modal_textField dealValue setFullWidth" selected={props.startDate != null ?new Date(props.startDate) : null} onChange={(date) => startDateChangeHandler(date)} />

                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        value={arrSlotTimeHour1 + ':' + arrSlotTimeMin1}
                                        handleOnChange={(event) => timeChangeFixed(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={props.tabValue} index={4}>
                        <div className="form-group d-flex justify-content-center w-100 global__custom_select">
                            <div className='pr-2 d-flex align-items-center'>
                                Select Greetings
                                <GlobalCustomSelect
                                    placeholder="Greetings"
                                    data={[
                                        {label : 'Birthday', value: 1},
                                        {label : 'Anniversary', value: 2}
                                    ]}
                                    value={props.greetingOption}
                                    handleOnChange={(event) => greetingOptionChangeHandler(event.target.value)}
                                    value_field={'value'}
                                    title_field={'label'}
                                /> at
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        // value={min}
                                        value={greetingsOptionTimeHour1 + ':' + greetingsOptionTimeMin1}
                                        handleOnChange={(event) => onGreetingsOptionChangeFixed(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={props.tabValue} index={5}>
                        <div className="form-group d-flex justify-content-center align-items-center w-100">
                            Followup Sequence Repeat Every
                            <div className='pr-2 d-flex align-items-center position-relative'>
                                <div className="input-field subject_input selects_fields">
                                    <input 
                                        id="email-setting-subject-input-field" 
                                        style={{marginBottom: 0}} 
                                        type="number" 
                                        min={0} 
                                        max={1000} 
                                        placeholder="Days" 
                                        value={props.day} 
                                        onChange={(event) => {
                                            if(/^\d+$/.test(event.target.value)){
                                                if(event.target.value > 1000){
                                                    dayChangeHandler(1000)
                                                }else if(event.target.value < 0){
                                                    dayChangeHandler(0)
                                                }
                                                else{
                                                    dayChangeHandler(event.target.value)
                                                }
                                            }
                                            else{
                                                dayChangeHandler(0)
                                            }                                            
                                        }} 
                                    />
                                </div>
                                <span className="day-select-message left-6">Day range between 1 to 1000</span>
                                days at
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        // value={min}
                                        value={recurringTimeHour1 + ':' + recurringTimeMin1}
                                        handleOnChange={(event) => ongRecurringChangeFixed(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Paper>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

        tabValue: state.cardBroadcastReducer.tabValue,
        hour: state.cardBroadcastReducer.hour,
        min: state.cardBroadcastReducer.min,
        day: state.cardBroadcastReducer.day,
        startDate: state.cardBroadcastReducer.startDate,
        greetingOption: state.cardBroadcastReducer.greetingOption,

        // userInfo: state.marketingReducer.userInfo,
        // campaignDetails: state.marketingReducer.campaignDetails,

        // tabValue: state.marketingReducer.tabValue,
        // hour: state.marketingReducer.hour,
        // min: state.marketingReducer.min,
        // day: state.marketingReducer.day,
        // recurringDay: state.marketingReducer.recurringDay,
        // laterHour: state.marketingReducer.laterHour,
        // laterMin: state.marketingReducer.laterMin,
        // time: state.marketingReducer.time,
        // startDate: state.marketingReducer.startDate,
        // arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
        // arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
        // greetingOption: state.marketingReducer.greetingOption,
        // greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
        // greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
        // recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
        // recurringTimeMin1: state.marketingReducer.recurringTimeMin1,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

        // updateTabValue: (params) => dispatch(updateTabValue(params)),
        // updateHour: (params) => dispatch(updateHour(params)),
        // updateMin: (params) => dispatch(updateMin(params)),
        // updateDay: (params) => dispatch(updateDay(params)),
        // updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        // updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        // updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        // updateTime: (params) => dispatch(updateTime(params)),
        // updateStartDate: (params) => dispatch(updateStartDate(params)),
        // updateArrSlotTimeHour1: (params) => dispatch(updateArrSlotTimeHour1(params)),
        // updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
        // updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
        // updateGreetingsOptionTimeMin1: (params) => dispatch(updateGreetingsOptionTimeMin1(params)),
        // updateGreetingsOptionTimeHour1: (params) => dispatch(updateGreetingsOptionTimeHour1(params)),
        // updateRecurringTimeHour1: (params) => dispatch(updateRecurringTimeHour1(params)),
        // updateRecurringTimeMin1: (params) => dispatch(updateRecurringTimeMin1(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSetting);