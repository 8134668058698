import { MenuItem } from "@material-ui/core";
import React from "react";
import CustomSelectAddDeal from "./assets/components/CustomSelect";

const CreateNewDeal = (props) => {

  return (
    <div className="create-new-deal-container-add-deal">
      <div className="add__deal__lead__source__wrapper deal__single__section__wr__row_3">
        <h3 className="add__deal__label__title">Lead source</h3>
        <CustomSelectAddDeal
          value={props.prevStates.source}
          handleChange={(event) => props.handleUpdateState({ source: event.target.value })}
          placeholder={
            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>
              Select lead source
            </MenuItem>
          }
          item={props.leadSources}
          value_field={"id"}
          title_field={"source_title"}
          menuItemClass={"dropdownhelper-menuitem-class"}
        />
      </div>

      <div className="crate__add__deal__group__wrapper">
        <div className="deal__single__section__wr__row_3">
          <h3 className="add__deal__label__title">First name</h3>
          <input
            className="add__deal__text__field"
            value={props.prevStates.firstName}
            onChange={(event) => props.handleUpdateState({ firstName: event.target.value })}
            placeholder="Enter first name"
          />
        </div>
        <div className="deal__single__section__wr__row_3">
          <h3 className="add__deal__label__title">Last name</h3>
          <input
            className="add__deal__text__field"
            value={props.prevStates.lastName}
            onChange={(event) => props.handleUpdateState({ lastName: event.target.value })}
            placeholder="Enter last name"
          />
        </div>
      </div>

      <div className="crate__add__deal__group__wrapper">
       <div className="deal__single__section__wr__row_3">
          <h3 className="add__deal__label__title">Email</h3>
          <input
            className="add__deal__text__field"
            value={props.prevStates.email}
            onChange={(event) => props.handleUpdateState({ email: event.target.value })}
            placeholder="Enter email address"
          />
        </div>
        <div className="deal__single__section__wr__row_3">
          <h3 className="add__deal__label__title">Number</h3>
          <input
            className="add__deal__text__field"
            type="number"
            min="0"
            value={props.prevStates.number}
            onChange={(event) => props.handleUpdateState({ number: event.target.value })}
            placeholder="Enter number"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateNewDeal;