import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const ITEM_HEIGHT = 48;

const Personalized = ({options, onChange}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        // onChange(value)
        setAnchorEl(null);
    };

    const renderItem = () => {
        let list = []
        options.map((option, index) => {
            let more_props = {}
            if(option.isDisabled != undefined && option.isDisabled){
                more_props['disabled'] = true
            }
            list.push(
                <MenuItem className="dropdownhelper-menuitem-class" key={option+'-'+index} value={option.value} onClick={() => {onChange(option.value);handleClose()}} {...more_props}>
                    {option.label}
                </MenuItem>
            )
        })
        return list;
    }

    return (
        <div className="g-personalized-content">
            <div  onClick={handleClick} className="g-personalized-button">
                Personalized
            </div>
            <Menu
                id="personalized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        // width: '20ch',
                    },
                }}
            >
                {renderItem()}
            </Menu>
        </div>
    );
}
export default Personalized;