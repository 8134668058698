import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Styles from "./Style.module.css";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const App = (props) => {

  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={Styles.mainWrp}>
              <div className={Styles.header}>
                  <h5 className={Styles.learnMoreTopTitle}>
                      {props.title}
                  </h5>
                  <span className={Styles.close} onClick={onClose}>
                      <CloseIcon/>
                  </span>
              </div>
            <div className={Styles.bodyWrp}>
                {props.innerBody}
            </div>
          </div>
        );
      },
      closeOnClickOutside: props.closeOnClickOutside,
      overlayClassName: Styles.overlay
    })
  )
}
export default App;