import React, { createContext, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { addCardBroadcastToContact,/* it might needed future */ producePDF } from './redux/cardBroadcastApi';
import { Skeleton } from '@material-ui/lab';
import CoreConstants from './assets/CoreConstants';
import GlobalModal from './assets/GlobalModal';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { CreateCardTemplate, followupSave, getDirectMailPrice, storeHandWrtingId, storeInitialStates, storeMultipleReducers, storeSelectedSize, storeSelectedTemplate, storeStepNo, updateEmailStepSetting } from './redux/cardBroadcastAction';
import PrintingDeliveryTimeModal from './PrintingDeliveryTimeModal';
import CreateNewCardTemplate from './CreateNewCardTemplate';
import ScheduleSetting from './ScheduleSetting';
import moment from 'moment';

export const CardMultiStepContext = createContext();

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;
const THANKSIO_GIFT = 11;


const IMG_SOURCE_TYPE_LOCAL = 1;
const IMG_SOURCE_TYPE_CANVA = 2;

const DirectmailTypeConvert = {
   [POSTCARD]: CoreConstants.Timeline.CONTENT_TYPE_POST_CARD,
   [GREETING_CARD]: CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD,
   [LETTER]: CoreConstants.Timeline.CONTENT_TYPE_LETTER,
   [GIFT]: CoreConstants.Timeline.CONTENT_TYPE_GIFT,
   [THANKSIO_GREETING_CARD]: CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD,
   [THANKSIO_GIFT]: CoreConstants.Timeline.CONTENT_TYPE_THANKSIO_GIFT,
}

const THANKSIO_POSTCARD_SIZES = ["4x6", "6x9"]

const useStyles = makeStyles((theme) => ({
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
}));



const CardMultipleStepForm = (props) => {
   const classes = useStyles();
   // const [stepNo, setStepNo] = useState(1);
   // const [cardSizes, setCardSizes] = useState([]);
   // const [loadingThirdPartyInfo, setLoadingThirdPartyInfo] = useState(true)
   // const [props.selectedGift, setprops.selectedGift] = useState(null);

   // const [textEditorValue, setTextEditorValue] = useState('');

   //For new template
   // const [createNewPostcardFrom, setCreateNewPostcardFrom] = useState('');
   //thirdstep

   // const [loadingPDF, setLoadingPDF] = useState(true)
   // const [pdfLink, setPDFLink] = useState('');
   const [letterPages, setLetterPages] = useState(['', '', '', '', '', '', '', '']);
   const [currentPage, setCurrentPage] = useState(0);

   //Fourth Step

   // const [submitSettings, setSubmitSettings] = useState(false)

   // const [submittingDirectMail, setSubmittingDirectMail] = useState(false)


   useEffect(() => {

      props.getDirectMailPrice({messageType : props.directMailInfos.messageType});
      getCardInformation();
      if (props.directMailInfos.editableCampaign !== undefined && props.directMailInfos.editableCampaign != null) {
         updateCampaignSetting();
      }
   }, []);  // eslint-disable-line  react-hooks/exhaustive-deps

   const getCardInformation = async () => {


      switch (props.directMailInfos.messageType) {

         case LETTER:
            props.storeMultipleReducers({ stepNo: 2, cardSizes: [], loadingThirdPartyInfo: false });
            break;
         case GREETING_CARD:
            props.storeMultipleReducers({ stepNo: 2, selectedCardSize: "5X7", loadingThirdPartyInfo: false });
            break;
         case GIFT:
            props.storeMultipleReducers({ useThanksIo: false, selectedCardSize: "5X7", loadingThirdPartyInfo: false })
            break;
         //For Thanksio Greeting card
         case THANKSIO_GREETING_CARD:
            props.storeMultipleReducers({ stepNo: 2,selectedCardSize: "", loadingThirdPartyInfo: false })
            break;
         case THANKSIO_GIFT:
            props.storeMultipleReducers({ selectedCardSize: "5X7", loadingThirdPartyInfo: false })
            break;
         //For Postcard
         default:
            setEditPostCardSizeAndType();
            break;

      }

   }
   const updateCampaignSetting = () => {

         // props.storeMultipleReducers({textEditorValue : (props.directMailInfos.editableCampaign.message !== undefined && props.directMailInfos.editableCampaign.message != null) ? props.directMailInfos.editableCampaign.message : ''})

         let tempStoreData = { textEditorValue: (props.directMailInfos.editableCampaign.message !== undefined && props.directMailInfos.editableCampaign.message != null) ? props.directMailInfos.editableCampaign.message : '' }

         // if (props.directMailInfos.editableCampaign.template_pdf_path !== undefined && props.directMailInfos.editableCampaign.template_pdf_path != null) {
         if (props.directMailInfos.editableCampaign.template_pdf_path) {

            // props.storeMultipleReducers({pdfLink:props.directMailInfos.editableCampaign.template_pdf_path})
            tempStoreData = { ...tempStoreData, ...{ pdfLink: props.directMailInfos.editableCampaign.template_pdf_path } }
         } else if (props.directMailInfos.editableCampaign.pdf_url) {
            // props.storeMultipleReducers({pdfLink:props.directMailInfos.editableCampaign.pdf_url})
            tempStoreData = { ...tempStoreData, ...{ pdfLink: props.directMailInfos.editableCampaign.pdf_url } }
         }
         props.storeMultipleReducers({ ...tempStoreData, ...{ loadingPDF: false } })
         // }
      
   }

   const changeLetterPage = (pageNumber) => {
      let pagesArray = [...letterPages];
      pagesArray[currentPage] = props.textEditorValue;
      setLetterPages(pagesArray);
      props.storeMultipleReducers({ textEditorValue: pagesArray[pageNumber] });
      setCurrentPage(pageNumber);
   }

   //Start setup postcard firststep
   const setEditPostCardSizeAndType = () => {

      let cardSize = ''
      if (props.directMailInfos.editableCampaign !== undefined && props.directMailInfos.editableCampaign != null) {

         if (props.directMailInfos.editableCampaign.file_type && THANKSIO_POSTCARD_SIZES.includes(props.directMailInfos.editableCampaign.file_type)) {
            cardSize = props.directMailInfos.editableCampaign.file_type
         } else if (props.directMailInfos.editableCampaign.card_size && THANKSIO_POSTCARD_SIZES.includes(props.directMailInfos.editableCampaign.card_size)) {
            cardSize = props.directMailInfos.editableCampaign.card_size
         }
         else {
            cardSize = THANKSIO_POSTCARD_SIZES[0]
         }

      } else {
         cardSize = THANKSIO_POSTCARD_SIZES[0]
      }

      props.storeMultipleReducers({ cardSizes: THANKSIO_POSTCARD_SIZES, selectedCardSize: cardSize, loadingThirdPartyInfo: false })
   }
   //End setup postcard firststep

   const moveNextStep = async (e, nextStep) => {
      if (e != null) {
         e.preventDefault();
      }
      if (nextStep === 2) {
         if ((props.cardSizes.includes(props.selectedCardSize) || props.selectedGift != null) && props.stepNo === 1) {
            props.storeStepNo(nextStep);
         }
         if (props.directMailInfos.messageType === LETTER && !props.useThanksIo) {
            props.storeStepNo(3);
         }
      } else if (nextStep === 3) {

         if (props.stepNo === 2 && props.selectedTemplate != null) {
            props.storeStepNo(2.5);
         }
         else if (props.stepNo === 2.5) {
            props.storeStepNo(nextStep);
         } else {
            window.showNotification('ERROR', "Please select template")
         }
      } else if (nextStep === 4) {
         if (props.stepNo === 3) {
            if (props.loadingPDF !== true && props.pdfLink !== '') {

               if (props.from !== "addQuickTemplate" && props.from !== "inbox" && props.from !== 'map-preview-data'
                  && props.from !== "campaign-settings") {
                  props.storeStepNo(nextStep);

               } else {

                  let formData = {}

                  let tempMessage = props.textEditorValue.replaceAll('<br />', '\n');
                  tempMessage = tempMessage.replaceAll('<br/>', '\n').trim();

                  if(tempMessage === ''){
                     window.showNotification('ERROR', 'Message can not be empty!');

                  }
                  formData['message'] = !props.useThanksIo && props.message_type === LETTER ? letterPages : tempMessage;
                  formData['content'] = props.directMailInfos.messageType;

                  formData['handwriting_style_id'] = props.selectedHandWritingId;
                  formData['template_category_id'] = props.selectedTemplate != null ? props.selectedTemplate.agency_card_category_id : null;
                  formData['template_id'] = props.selectedTemplate != null ? props.selectedTemplate.id : null;
                  formData['template_thumbnail_path'] = props.selectedTemplate != null ? props.selectedTemplate.image_path : null;
                  formData['file_path'] = props.selectedTemplate != null ? props.selectedTemplate.image_path : null;
                  formData['file_type'] = props.selectedCardSize;
                  formData['template_pdf_path'] = props.pdfLink;
                  formData['gift_production_id'] = props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_production_id : '';
                  formData['gift_product_id'] = props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_product_id : '';
                  formData['gift_product_sku'] = props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_product_sku : '';
                  formData['gift_handling_fee'] = props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_handling_fee : '';
                  formData['gift_price'] = props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_price : '';
                  formData['gift_image_url'] = props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_image_url : '';
                  formData['subject'] = ''
                  directMailSubmit(formData)
               }
            } else {
               window.showNotification('ERROR', 'PDF must generate properly to continue!');
            }
         }
      } else if (nextStep === 5) {
         if (props.stepNo === 4) {

         }
      }
   }

   const moveToSpecificStep = (step) => {
      if (step < props.stepNo) {
         props.storeStepNo(step);
      }
   }

   const closeModalAction = (view = false, custom =false)=>{

      if(custom && props.createNewPostcardFrom !== ''){
         props.storeMultipleReducers({ createNewPostcardFrom: '' })
      }else{
         props.storeInitialStates({})
         props.onClose();
      }
   }


   // const changeTextInEditor = (text) => {
   //    props.storeMultipleReducers({ textEditorValue:text})
   // }
   const producePDFLink = async () => {

      let payLoad = {
         file_type: props.selectedCardSize
      }

      switch (props.directMailInfos.messageType) {


         case POSTCARD:
            payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_POST_CARD
            break;
         case LETTER:
            payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_LETTER
            break;
         case THANKSIO_GREETING_CARD:
            payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_THANKSIO_GREETING_CARD
            break;
         case THANKSIO_GIFT:
               payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_THANKSIO_GIFT
               payLoad.gift_price = props.selectedThanksIOGiftPrice;
               payLoad.gift_url = props.selectedGift.image
               break;

         //For GC And GIFT
         default:
            payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD
            break;

      }

      if (!props.useThanksIo && props.directMailInfos.messageType === LETTER) {
         let pagesArray = [...letterPages];
         pagesArray[currentPage] = props.textEditorValue;
         payLoad.message = pagesArray;
      } else {
         payLoad.handwriting_style_id = props.selectedHandWritingId;
         payLoad.template_id = props.selectedTemplate.id;
         payLoad.template_thumbnail_path = props.selectedTemplate.image_path;
         payLoad.message = props.textEditorValue.replaceAll('<br />', '\n');
         payLoad.message = props.textEditorValue.replaceAll('<br/>', '\n');
      }

      props.storeMultipleReducers({ loadingPDF: true });
      await producePDF(payLoad)
         .then(res => {
            if(res.data.pdf_path == null){
               window.showNotification('ERROR','Text is too long. Please reduce text characters');
               if(props.pdfLink !== ''){
                  props.storeMultipleReducers({loadingPDF: false });

               }else{
                  props.storeMultipleReducers({ showPDF: false,loadingPDF: false });
               }
            }else{
               props.storeMultipleReducers({ pdfLink: res.data.pdf_path, loadingPDF: false });

            }
            // props.storeMultipleReducers({ pdfLink: res.data.pdf_path, loadingPDF: false });
            // setLoadingPDF(false)
         });
   }

   const sendText = async () => {

      let tempMessage = props.textEditorValue.replaceAll('<br />', '\n');
      tempMessage = tempMessage.replaceAll('<br/>', '\n');
      let formInfo = {
         'message': !props.useThanksIo && props.message_type === LETTER ? letterPages : tempMessage,
         'message_type': DirectmailTypeConvert[props.directMailInfos.messageType],
         'handwriting_style_id': props.selectedHandWritingId,
         'template_category_id': props.selectedTemplate != null ? props.selectedTemplate.agency_card_category_id : null,
         'template_id': props.selectedTemplate != null ? props.selectedTemplate.id : null,
         'template_thumbnail_path': props.selectedTemplate != null ? props.selectedTemplate.image_path : null,
         'file_path': props.selectedTemplate != null ? props.selectedTemplate.image_path : null,
         'file_type': props.selectedCardSize,
         'template_pdf_path': props.pdfLink,
         'gift_production_id': props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_production_id : '',
         'gift_product_id': props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_product_id : '',
         'gift_product_sku': props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_product_sku : '',
         'gift_handling_fee': props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_handling_fee : '',
         'gift_price': props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_price : '',
         'gift_image_url': props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_image_url : '',
      }

      if (props.directMailInfos.editableCampaign !== undefined && props.directMailInfos.editableCampaign != null) {
         props.onSubmit(formInfo, props.directMailInfos.editableCampaign.id)

      } else {

         if (props.from === 'contact-details' || props.from === 'inbox' || props.from === 'map-preview-data') {
            sendText(formInfo)
         }
      }
   }

   const directMailSubmit = async (formInfo) => {

      formInfo['dateTime'] = null
      formInfo['contact_id'] = props.directMailInfos.directMailContactId
      if (props.directMailInfos.directMailContactId === '') {
         formInfo['contactDetails'] = JSON.stringify(props.directMailInfos.contactDetails)
      }
      props.storeMultipleReducers({submittingDirectMail :true})
      await addCardBroadcastToContact({ params: formInfo })
         .then(res => {
            if (res.data.success) {
               window.showNotification('SUCCESS', res.data.message)
               if (props.callBack !== undefined && props.callBack != null) {
                  props.storeMultipleReducers({submittingDirectMail :false})
                  props.callBack(res.data.newContactData);
               } else {
                  closeModalAction()
               }
            } else {
               props.storeMultipleReducers({submittingDirectMail :false})
               window.showNotification('ERROR', 'Something went wrong')
            }
         })
         .catch((e) => {
            console.log(e)
         })
   }

   const loader = () => {
      let skeloader = []
      for (let i = 0; i < 3; i++) {
         skeloader.push(

            <Skeleton animation="wave" style={{ height: '70%', width: '25%', marginLeft: 25 }} />

         )
      }

      return (
         <div className="loading-skeleton-in-direct-mail d-flex row justify-content-center" style={{ height: 420 }}>
            {skeloader}
         </div>
      )

   }

   const footerLoader = () => {
      let skeloader = []
      for (let i = 0; i < 2; i++) {
         skeloader.push(

            <Skeleton animation="wave" style={{ height: '100%', width: '25%', marginLeft: 25 }} />

         )
      }
      return (
         <div className="loading-skeleton-in-direct-mail d-flex row justify-content-center" style={{ height: 60 }}>
            {skeloader}
         </div>
      )

   }

   const onSubmit = () => {

      if (props.stepNo === 4 ) {
         //lagteche na
         saveCampaignSettings();
      } else if (props.stepNo !== 4 && props.createNewPostcardFrom === '') {
         moveNextStep(null, props.stepNo !== 2.5 ? props.stepNo + 1 : 3)
      } else if (props.stepNo !== 4 && props.createNewPostcardFrom !== '') {

         // window.submitNewTemplate !== undefined ? window.submitNewTemplate() : console.log('not found')
         createNewTemplate()
    }

   }

   const saveCampaignSettings=async()=>{

      props.storeMultipleReducers({submittingDirectMail : true})

      let scheduleType;
      switch (props.tabValue) {
          case 1:
          case 2:
              scheduleType = CoreConstants.SENDING_TYPE_SCHEDULE;
          break;
          case 0:
              scheduleType = CoreConstants.SENDING_TYPE_IMMEDIATE;
              break;
          default:
              scheduleType = props.tabValue;
          break;
      }

      let formData = new FormData();

      let tempMessage = props.textEditorValue.replaceAll('<br />', '\n');
                  tempMessage = tempMessage.replaceAll('<br/>', '\n').trim();

                  if(tempMessage === ''){
                     window.showNotification('ERROR', 'Message can not be empty!');
                     props.storeMultipleReducers({submittingDirectMail : false})

                  }
      formData.append('message', !props.useThanksIo && props.message_type === LETTER ? letterPages : tempMessage);
      formData.append('message_type', DirectmailTypeConvert[props.directMailInfos.messageType]);
      formData.append('handwriting_style_id', props.selectedHandWritingId);
      formData.append('template_category_id', props.selectedTemplate ? props.selectedTemplate.agency_card_category_id : null);
      formData.append('template_id', props.selectedTemplate ? props.selectedTemplate.id : null);
      formData.append('template_thumbnail_path', props.selectedTemplate ? props.selectedTemplate.image_path : null);
      formData.append('file_path', props.selectedTemplate ? props.selectedTemplate.image_path : null);
      formData.append('file_type', props.selectedCardSize);
      formData.append('template_pdf_path', props.pdfLink);
      formData.append('gift_production_id',  props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_production_id : '');
      formData.append('gift_product_id', props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_product_id : '');
      formData.append('gift_product_sku', props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_product_sku : '');
      formData.append('gift_handling_fee',props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_handling_fee : '');
      formData.append('gift_price', props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_price : '');
      formData.append('gift_image_url', props.directMailInfos.messageType === GIFT ? props.selectedGift.gift_image_url : '');

      formData.append('nScheduleHours', props.hour);
      formData.append('nScheduleMinutes', props.min);
      formData.append('nScheduleDays', props.day);
      formData.append('followupScheduleTime', props.time);
      formData.append('campaign_id', props.directMailInfos.directMailCampaign.id);
      formData.append('type', scheduleType);
      formData.append('day_type', (props.tabValue !== 0 ? props.tabValue : 1));
      formData.append('date', moment(props.startDate).format('YYYY-MM-DD'));
      formData.append('greeting_option', props.greetingOption);
      // for (let [key, value] of formData.entries()) {
      //     console.log(`${key}: ${value}`);
      //   }
      if (props.directMailInfos.editableCampaign !=null) {

       formData.append('id', props.directMailInfos.editableCampaign.id);
       await props.updateEmailStepSetting(formData, (response) => {
          // props.handleRemoveCurrentClip()
          if(response.status === 'success'){
              window.showNotification('SUCCESS', 'Campaign Settings updated successfully')
               props.directMailInfos.directMailCallBack()
              closeModalAction()
            //   campaignSettingSubmitSuccess()
          }else{
            props.storeMultipleReducers({submittingDirectMail : false})

             window.showNotification('ERROR', 'Something went wrong')
          }
       })
    }
    else {
       await props.followupSave(formData, (response)=>{

          if(response.status === 'success'){
              window.showNotification('SUCCESS', 'Campaign Settings saved successfully')
              props.directMailInfos.directMailCallBack()
              closeModalAction()

            //   campaignSettingSubmitSuccess()
          }else{
            props.storeMultipleReducers({submittingDirectMail : false})

             window.showNotification('ERROR', 'Something went wrong')
          }
       });

    }
   }

   const showCardPrice = () => {
      if(props.directMailPrice === '') {
         return 'Loading Price...';
      }

      let price = null;

      if(props.directMailInfos.messageType === POSTCARD) {
         if(props.directMailPrice !== null && props.directMailPrice[props.directMailInfos.messageType+"_"+props.selectedCardSize] !== undefined) {
            price = props.directMailPrice[props.directMailInfos.messageType+"_"+props.selectedCardSize];
         }
      }

      if(props.directMailInfos.messageType === LETTER || props.directMailInfos.messageType === THANKSIO_GREETING_CARD) {
         if(props.directMailPrice !== null && props.directMailPrice[props.directMailInfos.messageType] !== null) {
            price = props.directMailPrice[props.directMailInfos.messageType];
         }
      }

      if(props.directMailInfos.messageType === GIFT && props.selectedGift) {
         price = parseFloat(props.selectedGift.agency_gift_price);
      }

      if(price) {
         return 'Price: $' + parseFloat(price).toFixed(2);
      }

      return '' 
   }

   const createNewTemplate=async()=>{
      if (props.uploadedFile == null) {
         window.showNotification('ERROR','File is required');
     }
     else if (props.newTemplateTitle === '') {
         window.showNotification('ERROR','Template title is required');
     } else if (props.newCategoryId === '') {
         window.showNotification('ERROR','Please select a category')
     } else if (props.newCategoryOrientation === '' && props.directMailInfos.messageType !== LETTER) {
         window.showNotification('ERROR','Please select a orientation')
     } else {
        props.storeMultipleReducers({submittingDirectMail : true})
         let formData = new FormData();
         formData.append("new_card_title", props.newTemplateTitle);
         formData.append("new_card_category_id", props.newCategoryId);
         if(props.directMailInfos.messageType === LETTER){
            formData.append("new_card_orientation", 2);
         }else{
            formData.append("new_card_orientation", props.newCategoryOrientation);

         }

         if(props.createNewPostcardFrom === 'computer' ){
             formData.append("qqfile", props.uploadedFile[0]);
             formData.append("source_type", IMG_SOURCE_TYPE_LOCAL);

         }else{
             formData.append("canvaImage", props.uploadedFile);
             formData.append("source_type", IMG_SOURCE_TYPE_CANVA);
         }
         await props.CreateCardTemplate(formData, (response) => {

             if (response.success) {

                  //must parse kora lagbe
                  props.storeMultipleReducers({selectedCategoryId: parseInt(response.data.template.agency_card_category_id), 
                  createNewPostcardFrom : '', reloadTemplateList : !props.reloadTemplateList, submittingDirectMail: false})
             } else {
                 window.showNotification('ERROR',response.message);
                 props.storeMultipleReducers({submittingDirectMail: false})
             }
         }) 

     }
   }

   let buttonText;
   let buttonIcon;
   if (props.stepNo === 4) {
      buttonText = 'Set Followup Email'
      buttonIcon = <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z" fill="#3C7EF3" /></svg>
   } else if (props.createNewPostcardFrom !== '') {
      buttonText = 'Create Template'
      buttonIcon = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" /></svg>
   }
   else if (props.stepNo === 3 && props.from === 'contact-details') {
      buttonText = 'Send'
      buttonIcon = <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z" fill="#3C7EF3" /></svg>
   }
   else if (props.stepNo === 3 && (props.from === 'inbox' || props.from === 'map-preview-data')) {
      buttonText = 'Send'
      buttonIcon = <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z" fill="#3C7EF3" /></svg>
   }
   else {
      buttonIcon = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" /></svg>
      buttonText = 'Save & Continue'
   }

   const contextValue = {
      messageType: props.directMailInfos.messageType,
      editableCampaign: props.directMailInfos.editableCampaign,
      from: props.from,
      moveToSpecificStep: moveToSpecificStep,
      producePDFLink : producePDFLink,
      directMailCampaign: props.directMailInfos.directMailCampaign
   }

   return (

         <CardMultiStepContext.Provider value={contextValue}>
      <GlobalModal 
         open = {props.directMailInfos.open}
         // className={'cardBordcastTab globalBroadcastModal alt'}
         onClose = {closeModalAction}
		   className={'global-card-broadcast-modal'}
         width={900}
         title={`${props.from === 'addQuickTemplate' ? 'Save' : 'Send'} ${CoreConstants.Timeline.CONTENT_TYPES[DirectmailTypeConvert[props.directMailInfos.messageType]]}`}
      // modalBodyClass={'awesome__scroll_bar'}
         buttonText={buttonText}
         buttonIcon={buttonIcon}
         onSubmit={onSubmit}
         hideFooter={!props.loadingThirdPartyInfo ? false : true}
         customCloseModal={props.createNewPostcardFrom !== '' ? true :false}
         extra_props_active={true}
      >

         <React.Fragment>
            {/* {!submitSettings && */}

               <div className="multistep-main-content container-fluid">
                  <div className="row justify-content-center">
                     <div className="col-11 s10 md10 lg6 xl5 text-center p-0">
                        <div className="custom-card-multistep card px-0 pt-4 pb-0 mt-3 mb-3 updated_quick_reply">
                           <form id="msform">
                              {/* <!-- progressbar Start--> */}
                              <ul id="progressbar">

                                 {(props.directMailInfos.messageType === POSTCARD || props.directMailInfos.messageType === GIFT || props.directMailInfos.messageType === THANKSIO_GIFT || (!props.useThanksIo && props.directMailInfos.messageType === LETTER)) &&
                                    <li className={` ${(props.stepNo === 1) ? ' activatedPageClass' : 'active'}`} id="account" onClick={() => moveToSpecificStep(1)}>
                                       <strong>
                                          {props.directMailInfos.messageType === GIFT || props.directMailInfos.messageType === THANKSIO_GIFT ? 'Gift' : 'Size'}

                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <g clipPath="url(#clip0)">
                                                <path d="M15.7013 10.0004C15.7013 9.64199 15.5645 9.2836 15.2913 9.01032L6.6917 0.410771C6.14466 -0.136273 5.25772 -0.136273 4.7109 0.410771C4.16408 0.957593 4.16408 1.84435 4.7109 2.39144L12.3203 10.0004L4.71116 17.6095C4.16434 18.1565 4.16434 19.0432 4.71116 19.59C5.25799 20.1373 6.14492 20.1373 6.69197 19.59L15.2916 10.9905C15.5648 10.7171 15.7013 10.3587 15.7013 10.0004Z" fill="#546376" />
                                             </g>
                                             <defs>
                                                <clipPath id="clip0">
                                                   <rect width="20" height="20" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" />
                                                </clipPath>
                                             </defs>
                                          </svg>
                                       </strong>
                                    </li>
                                 }
                                 {/* changed 22-03 Atish*/}
                                 {/* {((props.directMailInfos.messageType === LETTER && props.useThanksIo) || (props.directMailInfos.messageType === POSTCARD) || (props.directMailInfos.messageType === GREETING_CARD) || (props.directMailInfos.messageType === GIFT)) && */}
                                    <li id="personal" className={`${(props.stepNo > 1 && props.stepNo !== 2 && props.stepNo !== 2.5) ? 'active' : ''} ${(props.stepNo === 2 || props.stepNo === 2.5) ? ' activatedPageClass' : ''}`} onClick={() => moveToSpecificStep(2)}>
                                       <strong>
                                          Design
                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <g clipPath="url(#clip0)">
                                                <path d="M15.7013 10.0004C15.7013 9.64199 15.5645 9.2836 15.2913 9.01032L6.6917 0.410771C6.14466 -0.136273 5.25772 -0.136273 4.7109 0.410771C4.16408 0.957593 4.16408 1.84435 4.7109 2.39144L12.3203 10.0004L4.71116 17.6095C4.16434 18.1565 4.16434 19.0432 4.71116 19.59C5.25799 20.1373 6.14492 20.1373 6.69197 19.59L15.2916 10.9905C15.5648 10.7171 15.7013 10.3587 15.7013 10.0004Z" fill="#546376" />
                                             </g>
                                             <defs>
                                                <clipPath id="clip0">
                                                   <rect width="20" height="20" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" />
                                                </clipPath>
                                             </defs>
                                          </svg>
                                       </strong>
                                    </li>
                                 {/* } */}
                                 <li id="payment" className={`${(props.stepNo > 2.5 && props.stepNo !== 3 ? 'active' : '')} ${(props.stepNo === 3) ? ' activatedPageClass' : ''}`} onClick={() => moveToSpecificStep(3)}>
                                    <strong>
                                       {(props.from !== "inbox" && props.from !== 'map-preview-data') ? "Message" : "Message & Send"}
                                       {(props.from !== "addQuickTemplate" && props.from !== "inbox" && props.from !== 'map-preview-data') &&

                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <g clipPath="url(#clip0)">
                                                <path d="M15.7013 10.0004C15.7013 9.64199 15.5645 9.2836 15.2913 9.01032L6.6917 0.410771C6.14466 -0.136273 5.25772 -0.136273 4.7109 0.410771C4.16408 0.957593 4.16408 1.84435 4.7109 2.39144L12.3203 10.0004L4.71116 17.6095C4.16434 18.1565 4.16434 19.0432 4.71116 19.59C5.25799 20.1373 6.14492 20.1373 6.69197 19.59L15.2916 10.9905C15.5648 10.7171 15.7013 10.3587 15.7013 10.0004Z" fill="#546376" />
                                             </g>

                                             <defs>
                                                <clipPath id="clip0">
                                                   <rect width="20" height="20" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" />
                                                </clipPath>
                                             </defs>
                                          </svg>
                                       }
                                    </strong>
                                 </li>
                                 {(props.from === "campaign-details") &&
                                    <li id="confirm" className={`${(props.stepNo > 3 ? 'active' : '')} ${(props.stepNo === 4) ? ' activatedPageClass' : ''}`}>
                                       <strong>Save</strong></li>
                                 }

                                 <p className='text-dark-blue' style={{fontSize : 18, lineHeight : 1}}>{showCardPrice()}</p>
                              </ul>

                              {/* progressbar End */}

                              {/* <!--====================== 01 multistep form Start ======================--> */}
                              {(props.stepNo === 1 && !props.loadingThirdPartyInfo) ?

                                 <FirstStep
                                    // moveNextStep={moveNextStep}
                                    // closeModal={closeModal}
                                    // cardSizes={cardSizes}
                                    // messageType={props.directMailInfos.messageType}
                                    // useThanksIo={useThanksIo}
                                    // props.selectedGift={props.selectedGift}
                                    // setprops.selectedGift={setprops.selectedGift}
                                    // loadingThirdPartyInfo={props.loadingThirdPartyInfo}
                                 // editableCampaign={props.directMailInfos.editableCampaign}
                                 /> :
                                 props.stepNo === 1 &&
                                 <>
                                    {loader()}
                                    {footerLoader()}
                                 </>
                              }
                              {/* <!--====================== 01 multistep form End ======================-->
                                  <!--====================== 02 multistep form  ======================--> */}
                              {(props.stepNo === 2 || props.stepNo === 2.5) && !props.loadingThirdPartyInfo &&
                                 <SecondStep
                                 // closeModal={closeModal}
                                 // stepNo={stepNo}
                                 // messageType={props.directMailInfos.messageType}
                                 // editableCampaign={props.directMailInfos.editableCampaign}
                                 // from={props.from}
                                 // moveToSpecificStep={moveToSpecificStep}
                                 // setCreateNewPostcardFrom={setCreateNewPostcardFrom}
                                 // createNewPostcardFrom={createNewPostcardFrom}
                                 />
                                 
                              }
                              {/* <!--====================== 02 multistep form  ======================-->
                                  <!--====================== 03 multistep form  ======================--> */}
                              {props.stepNo === 3 && !props.loadingThirdPartyInfo &&
                                 <ThirdStep
                                    // closeModal={closeModal}
                                    // stepNo={stepNo}
                                    // changeTextInEditor={changeTextInEditor}
                                    // textEditorValue={textEditorValue}
                                    // loadingPDF={loadingPDF}
                                    // pdfLink={pdfLink}
                                    // producePDFLink={producePDFLink}
                                    // messageType={props.directMailInfos.messageType}
                                    // useThanksIo={useThanksIo}
                                    setPage={changeLetterPage}
                                 // editableCampaign={props.directMailInfos.editableCampaign}
                                 // from={props.from}
                                 // moveToSpecificStep={moveToSpecificStep}
                                 />

                              }
                              {props.submittingDirectMail &&
                                 <Backdrop className={classes.backdrop} open={props.submittingDirectMail} onClick={() => console.log('submittinging in process')}>
                                    <CircularProgress color="inherit" />
                                 </Backdrop>
                              }
                              {/* <!--====================== 03 multistep form  ======================-->
                                  <!--====================== 04 multistep form  ======================--> */}
                              {props.stepNo === 4 && props.from === "campaign-details" &&
                                 
                                 <ScheduleSetting showAllTimeSetting={true} editableCampaign={props.directMailInfos.editableCampaign} />
                                 
                                 //  <FourthStep 
                                 //  closeModal={closeModal}
                                 //  moveNextStep={moveNextStep}
                                 //  stepNo={stepNo}
                                 //  selectedDate={selectedDate}
                                 //  setSelectedDate={setSelectedDate}
                                 //  selectedTime={selectedTime}
                                 //  setSelectedTime={setSelectedTime}
                                 //  sentImmediately={sentImmediately}
                                 //  setSentImmediately={setSentImmediately}
                                 //  messageType={props.directMailInfos.messageType}
                                 //  useThanksIo={useThanksIo}
                                 //  />
                                 // <ScheduleSetting showAllTimeSetting={showAllTimeSetting} editableCampaign={props.directMailInfos.editableCampaign} />


                              }

                              {/* <!--====================== 04 multistep form  ======================--> */}

                              {props.showPrintDeliveryOptions &&
                                 <PrintingDeliveryTimeModal />
                              }
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
               {/* } */}
               {/* {stepNo === 4 && !loadingThirdPartyInfo &&
               <div className="massage_send_close_button d-flex justify-content-center align-items-center">
                  <a href="#" onClick={(event) => sendText(event)} className="send_massage">
                     <span>https://meet.google.com/jps-ywtw-jrd
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z"
                              fill="#3C7EF3" />
                        </svg>
                     </span> Set Followup Email
                  </a>
                  <a href="#" className="close_btn">
                     <span>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M6.71964 6.71947C6.46898 6.97013 6.24461 7.15099 5.99395 6.90033L4 4.90638L2.00605 6.90033C1.75584 7.15053 1.53102 6.97013 1.28036 6.71947C1.0297 6.46881 0.849296 6.24399 1.0995 5.99378L3.09345 3.99983L1.0995 2.00588C0.849296 1.75567 1.0297 1.53085 1.28036 1.28019C1.53102 1.02953 1.75584 0.849125 2.00605 1.09933L4 3.09328L5.99395 1.09933C6.24461 0.848672 6.46898 1.02953 6.71964 1.28019C6.9703 1.53085 7.15116 1.75522 6.9005 2.00588L4.90655 3.99983L6.9005 5.99378C7.15116 6.24444 6.9703 6.46881 6.71964 6.71947Z"
                              fill="#FF264A" />
                        </svg>
                     </span> Close
                  </a>
               </div>
            } */}
               {/* {(stepNo!==4 && createNewPostcardFrom==='') &&
                              <div className="massage_send_close_button d-flex justify-content-center align-items-center">
                                 <button className="custom-next next action-button accent--bg--color" onClick={(e) =>moveNextStep(e, stepNo!==2.5 ? stepNo+1 : 3)}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                                    </svg>
                                    <span>{stepNo === 2.5 ? 'Next' : 'Save & Continue'}</span>
                                 </button>
                                 <button className="custom-next custom-close modal-close action-button" onClick={(e) => closeModal(e)}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                                    </svg>
                                    <span>Close</span>
                                 </button>
                              </div>
                              }
                              {(stepNo!==4 && createNewPostcardFrom!=='') &&

                              <div className="massage_send_close_button d-flex justify-content-center align-items-center">
            <button className="custom-next next action-button accent--bg--color" onClick={(e) => window.submitNewTemplate(e)}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                </svg>
                <span>Create Template</span>
            </button>
            <button className="custom-next custom-close modal-close action-button" onClick={(e) => changeCreateNewPostcardFrom(e, '')}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                </svg>
                <span>Close</span>
            </button>
            </div>
         } */}
            </React.Fragment>



         </GlobalModal>
      </CardMultiStepContext.Provider>
   );
   //  }
}
const mapStateToProps = state => {
   return {
      categoryList: state.cardBroadcastReducer.categoryList,
      selectedCardSize: state.cardBroadcastReducer.selectedCardSize,
      selectedHandWritingId: state.cardBroadcastReducer.selectedHandWritingId,
      selectedTemplate: state.cardBroadcastReducer.selectedTemplate,
      stepNo: state.cardBroadcastReducer.stepNo,
      cardSizes: state.cardBroadcastReducer.cardSizes,
      selectedGift: state.cardBroadcastReducer.selectedGift,
      textEditorValue: state.cardBroadcastReducer.textEditorValue,
      createNewPostcardFrom: state.cardBroadcastReducer.createNewPostcardFrom,
      loadingPDF: state.cardBroadcastReducer.loadingPDF,
      showPrintDeliveryOptions: state.cardBroadcastReducer.showPrintDeliveryOptions,
      submittingDirectMail: state.cardBroadcastReducer.submittingDirectMail,
      uploadedFile: state.cardBroadcastReducer.uploadedFile,
      newTemplateTitle: state.cardBroadcastReducer.newTemplateTitle,
      newCategoryId: state.cardBroadcastReducer.newCategoryId,
      newCategoryOrientation: state.cardBroadcastReducer.newCategoryOrientation,
      reloadTemplateList: state.cardBroadcastReducer.reloadTemplateList,
      useThanksIo: state.cardBroadcastReducer.useThanksIo,
      pdfLink: state.cardBroadcastReducer.pdfLink,
      tabValue: state.cardBroadcastReducer.tabValue,
      hour: state.cardBroadcastReducer.hour,
      min: state.cardBroadcastReducer.min,
      day: state.cardBroadcastReducer.day,
      time: state.cardBroadcastReducer.time,
      startDate: state.cardBroadcastReducer.startDate,
      greetingOption: state.cardBroadcastReducer.greetingOption,
      loadingThirdPartyInfo: state.cardBroadcastReducer.loadingThirdPartyInfo,
      selectedThanksIOGiftPrice: state.cardBroadcastReducer.selectedThanksIOGiftPrice,
      directMailPrice: state.cardBroadcastReducer.directMailPrice,
   };
};

const mapDispatchToProps = dispatch => {
   return {
      storeSelectedSize: (params, callback) => dispatch(storeSelectedSize(params, callback)),
      storeHandWrtingId: (params, callback) => dispatch(storeHandWrtingId(params, callback)),
      storeSelectedTemplate: (params, callback) => dispatch(storeSelectedTemplate(params, callback)),
      storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),
      storeStepNo: (params) => dispatch(storeStepNo(params)),
      storeInitialStates: (params) => dispatch(storeInitialStates(params)),
      CreateCardTemplate: (params, payload) => dispatch(CreateCardTemplate(params, payload)),
      updateEmailStepSetting: (params, payload) => dispatch(updateEmailStepSetting(params, payload)),
      followupSave: (params, payload) => dispatch(followupSave(params, payload)),
      getDirectMailPrice: (params) => dispatch(getDirectMailPrice(params))

   };
}
export default connect(mapStateToProps, mapDispatchToProps)(CardMultipleStepForm);
