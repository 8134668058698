import React from 'react'
import { Link } from 'react-router-dom';

export const CampaignTypeNotification = (props) => {

  return (
    <div className={`people-list-items d-flex align-items-center ${props.notification.seen === 0 ? 'unseen' : ''}`}>
        <Link to={"/campaign/details/"+props.notification.permalink} onClick={e => props.onDataClick()} className="d-flex align-items-center">
            <span className="avatar d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1200"><path d="M1030.014 96.91C874.836 250.578 646.766 344.637 427.042 357.866H100.749C49.358 358.572 2.898 402.262 0 459.889V607.02c.701 51.369 43.068 99.121 100.699 102.021H231.52c-34.962 118.567 2.593 238.596 43.15 356.415 60.166 56.81 197.785 49.742 242.638-24.195-95.523-75.091-142.05-277.145-26.889-326.979 196.285 22.271 390.749 122.01 539.594 255.716 51.169-.701 97.705-42.745 100.699-100.062V628.618c102.449-36.383 81.738-164.259 0-190.28V197.659c-.683-51.365-43.073-97.799-100.699-100.7v-.049zm2.595 130.135v613.453C888.778 728.424 711.072 653.216 527.741 619.46V448.134c179.894-27.072 355.835-110.077 504.868-221.089z"/></svg>
            </span>
            <div className="notification-name">
                <p className="name"> {'Campaign Paused'}
                    <span className="notification-time ml-5">{props.usageDate}</span>
                </p>
                <p className="title">{props.notification.message}</p>
            </div>
            <div className="notification-time" onClick={e => {e.preventDefault();e.stopPropagation(); props.onClose()}}>
                <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="m14.383 16.637 2.01-2.012L6.135 4.361l-2.01 2.011 10.258 10.265z" fill="#FF264A"/>
                    <path d="M6.135 16.64 16.393 6.374l-2.01-2.012L4.125 14.627l2.01 2.012z" fill="#FF264A"/>
                </svg>
            </div>
        </Link>
    </div>
  )
}
