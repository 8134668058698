import { Button, CircularProgress } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { duplicateSharedContact, duplicateSharedSetting, getCopyContactSetting } from '../../api/globalApi';
import Utils from '../addContact/Utils';
import './sharePage.css';

const ContactSharePage = () => {

    const history = useHistory();
    const { contactId } = useParams();
    const [copySetting, setCopySetting] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        getCopyContactSetting({contactString : contactId}).then(res => {
            if(!res.data.success) {
                setCopySetting({
                    success : false
                });
                return;
            }

            let contact = res.data.data.contact;
            let contactFullName = (contact.first_name?contact.first_name:'') + ' ' + (contact.last_name?contact.last_name:'') 
            setCopySetting({
                contact : res.data.data?.contact,
                contactName : Utils.getContactDisplayName(contactFullName,contact.number,contact.email),
                user : res.data.data?.userName,
                success : res.data.success
            });
        });
    },[]);

    const handleCopyContact = () => {
        setSubmitting(true);
        duplicateSharedContact({contactString : contactId}).then(res => {
            if(res.data.success){
                window.showNotification('SUCCESS','Accept request successful, you will notify after process');
                history.push('/contacts');
            } else {
                window.showNotification('ERROR','Unable to duplicate the contact.');
                setSubmitting(false);
            }
        });
    }

    return ( 
        <div className="share_page__wrapper trigger_share">
            <div className="share_page__inner_wrapper">
            {
                !copySetting ?
                <>
                <h5><CircularProgress/> Loading</h5>
                </> : copySetting.success ? 
                <>
                    <h5>{copySetting.user} shared the contact - "{copySetting.contactName}" with you.</h5>
                    <p>To copy this contact, please click the button.</p>
                    <Button disabled={submitting} onClick={() => handleCopyContact()} className="accent--bg--text--color" size="large" startIcon={<FileCopy/>}>{submitting ? "Please wait" : "Copy Contact"}</Button>
                </> :
                <>
                    <h5>Sorry! This lead is already copied or removed.</h5>
                    <h5>Please contact with your provider.</h5>
                </>  
            }
                
            </div>
        </div> 
    );
}
 
export default ContactSharePage;