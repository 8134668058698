import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
return {
    fontWeight:
    personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
};
}

const CustomMultipleSelect = (props) => {
    const classes = useStyles();
    const theme = useTheme();
  
    return (
      <div>
        <FormControl className={classes.formControl}>
          <Select
            labelId="multiple-custom-select"
            id="multiple-custom-select"
            className={props.className != undefined ? props.className : ''}
            multiple
            displayEmpty
            value={props.value}
            onChange={props.onChange}
            input={<Input id="multiple-custom-select" />}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <em>{props.placeholder}</em>;
                }
                return(
                    <div className={classes.chips}>
                        {selected.map((value, index) => (
                        <Chip key={index} label={value.label} className={classes.chip} />
                        ))}
                    </div>
                )
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem disabled value="">
                <em>{props.placeholder}</em>
            </MenuItem>
            {props.list.map((each, index) => (
              <MenuItem key={index} value={each} style={getStyles(each.email, props.value, theme)}>
                {each.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
}
export default CustomMultipleSelect;