import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import './loader.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function NewLoader(props) {
  const classes = useStyles();
  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // if(props.message !== undefined){
  //   return(
  //     <div className={`new-loader ${classes.root}`}>
  //       <div className={classes.wrapper}>
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           className={buttonClassname}
  //           disabled={props.loading}
  //         >
  //           {props.message}
  //         </Button>
  //         {props.loading && <CircularProgress size={props.size || 24} className={classes.buttonProgress} />}
  //       </div>
  //     </div>
  //   )
  // }

  // if(props.onlyLoader !== undefined){
  //   return(
  //     <div className={`new-loader ${classes.root}`}>
  //       <div className={classes.wrapper}>
  //         {props.loading && <CircularProgress size={props.size || 24} className={classes.buttonProgress} />}
  //       </div>
  //     </div>
  //   )
  // }
  if(props.loading){
    return (


      <div className={`new-loader ${classes.root}`}>
    <div className={classes.wrapper}>
    {/*<Button*/}
    {/*          variant="contained"*/}
    {/*          color="primary"*/}
    {/*          className={buttonClassname}*/}
    {/*          disabled={props.loading}*/}
    {/*        >*/}
    {/*          {props.message}*/}
    {/*        </Button>*/}
            {props.loading && <CircularProgress size={props.size || 24} className={classes.buttonProgress} />}
          </div>
        </div>
    );
  }else{
    return null
  }

  
}