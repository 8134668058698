import React, { useEffect, useState } from "react";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ModalAddCampaignForm from "./ModalAddCampaignForm";
import { getCampaignList } from "../../redux/contactApi";
import Campaign from "./Campaign";
import { Box } from "@material-ui/core";


const CampaignList = (props) => {
  const [expand, setExpand] = useState(false);
  const [show,setShow] = useState(false);
    const [campaigns, setCampaigns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [count, setCount] = useState(0)

  


  useEffect(() => {
    loadCampaignList();
}, [props.contact_id])

const loadCampaignList = () =>{
    getCampaignList({
        contact_id: props.contact_id
    }).then(res => {
        setCount(res.data.length);
        setCampaigns(res.data)
    });
}

const reloadCampaignList = () =>{
    loadCampaignList();
    setShowModal(false)
    setShow(true)
}


const renderCampaigns=()=>{

  let dataArray=[];
  if(count> 0){
      for(let i =0;i<count ; i++){
          if(campaigns[i] !== undefined && campaigns[i] != null && campaigns[i].campaign !== undefined && campaigns[i].campaign != null){
              dataArray.push(
              <Campaign reloadCampaignList={reloadCampaignList} contact_id={props.contact_id} 
              key={i} data={campaigns[i]}/>);
          }
      }
      if(dataArray.length > 0){
          return dataArray;

      }else{
          return (<div class="d-flex justify-content-between align-items-center custom__compain_content">
      <h6 class="text-gray">no campaign assigned</h6></div>)
      }

  }else{

      return (<div class="d-flex justify-content-between align-items-center custom__compain_content">
      <h6 class="text-gray">no campaign assigned</h6></div>)

  }
}
 

  return (
    <div className=" mt-2">
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`g-collapsible-header ${expand ? "expand" : ""}`}
        >
          <span className="g-header-title">
            <span className="material-icons">campaign</span> Campaign{" "}
            <span className="g-header-count">{count}</span>
          </span>

          <div className="g-header-action-content">
            <span className="g-add-task-button" onClick={()=> setShowModal(!showModal)}>
              <AddCircleIcon />
            </span>
            <span className="g-custom-caret">{icons.caretIcon}</span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          
          <Box py={2} sx={{overflowY:'auto',overflowX:'hidden',height:'200px'}}>
                            {renderCampaigns()}
                        </Box>
        </CustomAccordionBody>
      </CustomAccordion>
          {
                    showModal && (
                        <ModalAddCampaignForm
                            contact_id={props.contact_id}
                            selectedCampaign={campaigns}
                            title={"Add Campaigns"}
                            people={props.people}
                            isOpen={showModal}
                            callBack={()=>reloadCampaignList()}
                            modalToggle={()=> setShowModal(!showModal)}
                        />
                    )
                }
    </div>
  );
};
export default CampaignList;
