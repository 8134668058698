import React from "react";
import Styles from "./Styles.module.css";
import {Box, FormControl, FormHelperText, Grid, InputBase, MenuItem, Select, withStyles} from "@material-ui/core";
import Utils from "../../helpers/Utils";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
    },
}))(InputBase);

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#707070 !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4
        },
    },
}))(InputBase);

const verticalTypeList = [
    {
        "key": "AGRICULTURE",
        "value": "Agriculture"
    },
    {
        "key": "COMMUNICATION",
        "value": "Communication"
    },
    {
        "key": "CONSTRUCTION",
        "value": "Construction"
    },
    {
        "key": "EDUCATION",
        "value": "Education"
    },
    {
        "key": "ENERGY",
        "value": "Energy"
    },
    {
        "key": "ENTERTAINMENT",
        "value": "Entertainment"
    },
    {
        "key": "FINANCIAL",
        "value": "Financial"
    },
    {
        "key": "GAMBLING",
        "value": "Gambling"
    },
    {
        "key": "GOVERNMENT",
        "value": "Government"
    },
    {
        "key": "HEALTHCARE",
        "value": "Healthcare"
    },
    {
        "key": "HOSPITALITY",
        "value": "Hospitality"
    },
    {
        "key": "HUMAN_RESOURCES",
        "value": "Human Resources"
    },
    {
        "key": "INSURANCE",
        "value": "Insurance"
    },
    {
        "key": "LEGAL",
        "value": "Legal"
    },
    {
        "key": "MANUFACTURING",
        "value": "Manufacturing"
    },
    {
        "key": "NGO",
        "value": "Ngo"
    },
    {
        "key": "POLITICAL",
        "value": "Political"
    },
    {
        "key": "POSTAL",
        "value": "Postal"
    },
    {
        "key": "PROFESSIONAL",
        "value": "Professional"
    },
    {
        "key": "REAL_ESTATE",
        "value": "Real Estate"
    },
    {
        "key": "RETAIL",
        "value": "Retail"
    }
];

const GeneralInformation = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError }) => {
    const handleFormDataChange = (name, value) => {
        if (name === "firstName"){
            if (value.trim() === ""){
                errorCallback("firstName","Firstname is required!");
            }else{
                errorCallback("firstName","");
            }
        }else if (name === "lastName"){
            if (value.trim() === ""){
                errorCallback("lastName","Lastname is required!");
            }else{
                errorCallback("lastName","");
            }
        }else if (name === "verifiedAddress"){
            if (value.trim() === ""){
                errorCallback("verifiedAddress","Verified Address is required!");
            }else {
                errorCallback("verifiedAddress","");
            }
        }else if (name === "city"){
            if (value.trim() === ""){
                errorCallback("city","City is required!");
            }else {
                errorCallback("city","");
            }
        }else if (name === "state"){
            if (value.trim() === ""){
                errorCallback("state","State is required!");
            }else {
                errorCallback("state","");
            }
        }else if (name === "zipCode"){
            if (value.trim() === ""){
                errorCallback("zipCode","Postal/Zip Code is required!");
            }else {
                errorCallback("zipCode","");
            }
        }else if (name === "verifiedEmail"){
            if (value.trim() === ""){
                errorCallback("verifiedEmail","Email Address is required!");
            }else if (!Utils.isValidEmail(value)){
                errorCallback("verifiedEmail","Email Address must be valid!");
            }else {
                errorCallback("verifiedEmail","");
            }
        }else if (name === "verifiedNumber"){
            if (value.trim() === ""){
                errorCallback("verifiedNumber","Mobile Number is required!");
            }else if (!Utils.isValidMobileNumber(value)){
                errorCallback("verifiedNumber","Mobile Number must be valid!");
            }else {
                errorCallback("verifiedNumber","");
            }
        }else if (name === "brandName"){
            if (value.trim() === ""){
                errorCallback("brandName","Brand Name is required!");
            }else {
                errorCallback("brandName","");
            }
        }else if (name === "vertical"){
            if (value.trim() === ""){
                errorCallback("vertical","Business Type is required!");
            }else {
                errorCallback("vertical","");
            }
        }
        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    const renderCampaignUseCaseListOptions = () => {
        const item = [];
        for(let i=0;i<verticalTypeList.length;i++){
            item.push(<MenuItem className="dropdownhelper-menuitem-class" value={verticalTypeList[i].key}>{verticalTypeList[i].value}</MenuItem>);
        }
        return item;
    };

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>When completing this page please reference your official IRS documentation. (EIN documents, W2, W9, or CP575). Answer the questions carefully to ensure the info you provide matches your official IRS documents <u>EXACTLY</u>. Inaccuracies can lead to manual reviews and long delays in getting your business registered.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                                  <span className={Styles.label}>
                                    First Name
                                    <span className={Styles.required}> *</span>
                                  </span>
                                <CustomInput
                                    placeholder="Enter your Firstname"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={(e)=>{handleFormDataChange("firstName", e.target.value)}}
                                />
                                {renderErrorMessage("firstName")}
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                                  <span className={Styles.label}>
                                    Last Name
                                    <span className={Styles.required}> *</span>
                                  </span>
                                <CustomInput
                                    placeholder="Enter your Lastname"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={(e)=>{handleFormDataChange("lastName", e.target.value)}}
                                />
                                {renderErrorMessage("lastName")}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[2] = el} className={Styles.formControl}>
                              <span className={Styles.label}>
                                Verified Address
                                <span className={Styles.required}> *</span>
                              </span>
                                <CustomInput
                                    placeholder="Enter your business street address here. Must be a valid US address."
                                    name="verifiedAddress"
                                    value={formData.verifiedAddress}
                                    onChange={(e)=>{handleFormDataChange("verifiedAddress", e.target.value)}}
                                />
                                {renderErrorMessage("verifiedAddress")}
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[3] = el} className={Styles.formControl}>
                              <span className={Styles.label}>
                                City
                                <span className={Styles.required}> *</span>
                              </span>
                                <CustomInput
                                    placeholder="Enter your city name."
                                    name="city"
                                    value={formData.city}
                                    onChange={(e)=>{handleFormDataChange("city", e.target.value)}}
                                />
                                {renderErrorMessage("city")}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[4] = el} className={Styles.formControl}>
                              <span className={Styles.label}>
                                State
                                <span className={Styles.required}> *</span>
                              </span>
                                <CustomInput
                                    placeholder="Enter your state name."
                                    name="state"
                                    value={formData.state}
                                    onChange={(e)=>{handleFormDataChange("state", e.target.value)}}
                                />
                                {renderErrorMessage("state")}
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[5] = el} className={Styles.formControl}>
                              <span className={Styles.label}>
                                Postal/Zip Code
                                <span className={Styles.required}> *</span>
                              </span>
                                <CustomInput
                                    placeholder="Enter your Postal/Zip Code."
                                    name="zipCode"
                                    value={formData.zipCode}
                                    onChange={(e)=>{handleFormDataChange("zipCode", e.target.value)}}
                                />
                                {renderErrorMessage("zipCode")}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[6] = el} className={Styles.formControl}>
                              <span className={Styles.label}>
                                Verified Email
                                <span className={Styles.required}> *</span>
                              </span>
                                <CustomInput
                                    placeholder="Enter your verified email address."
                                    name="verifiedEmail"
                                    value={formData.verifiedEmail}
                                    onChange={(e)=>{handleFormDataChange("verifiedEmail", e.target.value)}}
                                />
                                {renderErrorMessage("verifiedEmail")}
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl ref={el => inputScrollToViewRef.current[7] = el} className={Styles.formControl}>
                              <span className={Styles.label}>
                                Verified Number
                                <span className={Styles.required}> *</span>
                                <span className={Styles.extraLabel}>[An OTP will send to this number]</span>
                              </span>
                                <CustomInput
                                    placeholder="Enter your verified number."
                                    name="verifiedNumber"
                                    value={formData.verifiedNumber}
                                    onChange={(e)=>{handleFormDataChange("verifiedNumber", e.target.value)}}
                                />
                                <span className={`${Styles.extraLabel} ${Styles.colorDark}`}>Number cannot be which you've acquired from a CPaaS provider such as Twilio.</span>
                                {renderErrorMessage("verifiedNumber")}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl ref={el => inputScrollToViewRef.current[8] = el} className={Styles.formControl}>
                          <span className={Styles.label}>
                            Brand Name
                            <span className={Styles.required}> *</span>
                          </span>
                        <span className={Styles.supHelpText}>Brand name must be the your real business name. If you don't have any business name provide your first and last name, but you can also provide a <a href="https://en.wiktionary.org/wiki/dba" target="_blank">DBA</a> name if there is one.</span>
                        <CustomInput
                            placeholder="Enter your business name."
                            name="brandName"
                            value={formData.brandName}
                            onChange={(e)=>{handleFormDataChange("brandName", e.target.value)}}
                        />
                        {renderErrorMessage("brandName")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[9] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Type
                        <span className={Styles.required}> *</span>
                      </span>
                        <Select
                            name="vertical"
                            value={formData.vertical}
                            displayEmpty
                            onChange={(e)=>{ handleFormDataChange("vertical", e.target.value) }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                style: { maxHeight: 300 }
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>Select a business type</MenuItem>
                            {renderCampaignUseCaseListOptions()}
                        </Select>
                        {renderErrorMessage("vertical")}
                    </FormControl>
                </div>
                <div className={Styles.contentBodyRight}>
                <Box className={Styles.notes}><b className={Styles.soleRegTips}>Sole Proprietor Registration Tips:</b>
                <hr/>
                <p>Sole proprietors must register using their own unique information. If you are a branch office or a franchise of a larger brand <span style={{color:"red"}}>DO NOT</span> submit the registration using the larger brand's info.</p>

                <p>Example: </p>
                <p>John Doe owns a local Allstate insurance agency. When John completes his sole proprietor registration he needs to provide his own info, not the info for Allstate.</p>

                <p>The brand name must be your real business name. If you don't have a business name just provide your first and last name.</p>

                <p>Brand Name Examples: </p>
                <p>John Doe Insurance Agency</p>
                <p>John Doe</p>
                            
                </Box>
                </div>
            </div>
        </div>
    );
}

export default GeneralInformation;