import React, {createContext, useReducer} from 'react'
import ActionType from './ActionType';

const SmsSendContext = createContext({});

export const smsSendStates = {
    /* main props */
    contactId: null,
    from: '',
    sendSuccessCallBack: null,
    onClose: null,
    contactInfo: null, //name, number

    /* component states */
    personalized: [],
    lastConnectedVn: '',
    lastConnectedVnData: '',
    vnList: [],
    selctedVn: '',
    selectedToNum: '',
    contactNumbers:  [],

    saveAsTemplate: false,
    templateTitle: '',
    isShowTemplates: false,

    message: '',
    message_type: 2, //2=sms, 5=mms,
    file_url: '',
    file_data: null,
    selectedMmsFromTemplate: false,

    isLoading: true,
    wentWrong: false,
    errorText: '',
    sending: false,

    appendUnsubText: false
}

export const smsSendReducer = (state, action) => {
    switch(action.type){
        case ActionType.HANDLE_LOADING:
            if(typeof action.payload === 'object'){
                return {
                    ...state, 
                    isLoading: action.payload.isLoading !== undefined ? action.payload.isLoading : state.isLoading, 
                    wentWrong: action.payload.wentWrong,
                    errorText: action.payload.errorText
                }
            }
            return {...state, isLoading: action.payload}

        case ActionType.STORE_PROPS:
            return {
                ...state, 
                contactId: action.payload.contactId, 
                from: action.payload.from, 
                sendSuccessCallBack: action.payload.sendSuccessCallBack,
                contactEmails: action.payload.contactEmails ? action.payload.contactEmails : [],
                contactNumbers: action.payload.contactNumbers ? action.payload.contactNumbers : [],
                onClose: action.payload.onClose,
                contactInfo: action.payload.contactInfo
            }
        
        case ActionType.STORE_PERSONALIZED:
            return {
                ...state, 
                personalized: action.payload
            }

        case ActionType.UPDATE_MESSAGE:
            if(typeof action.payload === 'object'){
                return {
                    ...state,
                    message: action.payload.message !== undefined ? action.payload.message : state.message,
                    message_type: action.payload.message_type
                }
            }
            return {
                ...state,
                message: action.payload
            }

        case ActionType.HANDLE_SENDING:
            return { ...state, sending: action.payload }

        case ActionType.UPDATE_SAVE_AS_TEMPLATE_FLAG:
            return { ...state, saveAsTemplate: action.payload }
            
        case ActionType.UPDATE_TITLE:
            return { ...state, templateTitle: action.payload }
            
        case ActionType.UPDATE_LAST_CONNECTED_VN:
            return { 
                ...state, 
                lastConnectedVn: action.payload.id,
                lastConnectedVnData: action.payload.data
            }
        
        case ActionType.UPDATE_SELECTED_VN:
            return { ...state, selctedVn: action.payload }

        case ActionType.UPDATE_VN_LIST:
            return { ...state, vnList: action.payload }
            
        case ActionType.UPDATE_IS_SHOW_TEMPLATE:
            return { ...state, isShowTemplates: action.payload }

        case ActionType.UPDATE_MMS_FILE: 
            return { 
                ...state,
                file_url: action.payload.file_url,
                file_data: action.payload.file_data,
                selectedMmsFromTemplate: action.payload.selectedMmsFromTemplate
            }
        
        case ActionType.UPDATE_UNSUB_TEXT:
            return { ...state, appendUnsubText: action.payload }
        case ActionType.UPDATE_SELECTED_TO_NUM:
            return { ...state, selectedToNum: action.payload }

        default: return state
    }
}

const SmsSendProvider = ({children}) => {
    const [states, dispatch] = useReducer(smsSendReducer, smsSendStates)
    return (
        <SmsSendContext.Provider
            value={{
                states,
                dispatch
            }}
        >
            {children}
        </SmsSendContext.Provider>
    )
}
export {SmsSendContext, SmsSendProvider}