import React, {createContext, useReducer} from 'react'
import ActionType from './ActionType';

const ContactDetailContext = createContext({});

export const contactDetailStates = {
    isLoading: true,
    contactId: null,
    contactDetails: null,
    tab: 'info', //note
    from: '',
    totalCampaign: 0,
    dealCallback: null
}

export const contactDetailReducer = (state, action) => {
    switch(action.type){
        case ActionType.HANDLE_LOADING:
            return {...state, isLoading: action.payload}

        case ActionType.HANDLE_TAB:
            return {...state, tab: action.payload}

        case ActionType.STORE_CONTACT_ID:
            return {...state, contactId: action.payload}

        case ActionType.STORE_CONTACT_INFO:
            return {...state, contactDetails: action.payload}

        case ActionType.HANDLE_FROM:
            return {...state, from: action.payload}

        case ActionType.STORE_DEAL_CALLBACK:
            return {...state, dealCallback: action.payload}
            
        case ActionType.STORE_TOTAL_CAMPAIGN:
            return {...state, totalCampaign: action.payload}

        default:
            return state
    }
}

const ContactDetailsProvider = ({children}) => {
    const [detailStates, detailsDispatch] = useReducer(contactDetailReducer, contactDetailStates)
    return (
        <ContactDetailContext.Provider
            value={{
                // states
                detailStates,
                // dispatch
                detailsDispatch
            }}
        >
            {children}
        </ContactDetailContext.Provider>
    )
}
export {ContactDetailContext, ContactDetailsProvider}