export const IconList = {
    search : <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 11.3633H11.71L11.43 11.0933C12.41 9.95328 13 8.47328 13 6.86328C13 3.27328 10.09 0.363281 6.5 0.363281C2.91 0.363281 0 3.27328 0 6.86328C0 10.4533 2.91 13.3633 6.5 13.3633C8.11 13.3633 9.59 12.7733 10.73 11.7933L11 12.0733V12.8633L16 17.8533L17.49 16.3633L12.5 11.3633ZM6.5 11.3633C4.01 11.3633 2 9.35328 2 6.86328C2 4.37328 4.01 2.36328 6.5 2.36328C8.99 2.36328 11 4.37328 11 6.86328C11 9.35328 8.99 11.3633 6.5 11.3633Z" fill="#3C7EF3"/>
            </svg>,
    crossCircleWhite:    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.71964 6.71947C6.46898 6.97013 6.24461 7.15099 5.99395 6.90033L4 4.90638L2.00605 6.90033C1.75584 7.15053 1.53102 6.97013 1.28036 6.71947C1.0297 6.46881 0.849296 6.24399 1.0995 5.99378L3.09345 3.99983L1.0995 2.00588C0.849296 1.75567 1.0297 1.53085 1.28036 1.28019C1.53102 1.02953 1.75584 0.849125 2.00605 1.09933L4 3.09328L5.99395 1.09933C6.24461 0.848672 6.46898 1.02953 6.71964 1.28019C6.9703 1.53085 7.15116 1.75522 6.9005 2.00588L4.90655 3.99983L6.9005 5.99378C7.15116 6.24444 6.9703 6.46881 6.71964 6.71947Z" fill="#FF264A"/>
                        </svg>,
    plusCircleWhite:  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.33611 4.00045C8.33611 4.35494 8.30534 4.64148 7.95085 4.64148H5.13098V7.46135C5.13098 7.81519 4.84444 7.8466 4.48995 7.8466C4.13547 7.8466 3.84893 7.81519 3.84893 7.46135V4.64148H1.02906C0.675209 4.64148 0.643799 4.35494 0.643799 4.00045C0.643799 3.64596 0.675209 3.35943 1.02906 3.35943H3.84893V0.539553C3.84893 0.185066 4.13547 0.154297 4.48995 0.154297C4.84444 0.154297 5.13098 0.185066 5.13098 0.539553V3.35943H7.95085C8.30534 3.35943 8.33611 3.64596 8.33611 4.00045Z" fill="#3C7EF3"/>
                    </svg>,
    pauseIconBlue: <svg width="13" height="14"
                    viewBox="0 0 13 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="4" height="14"
                        fill="#3C7EF3" />
                    <rect x="9" width="4" height="14"
                        fill="#3C7EF3" />
                </svg>,
    playIcon: <svg xmlns="http://www.w3.org/2000/svg" 
                    height="25" viewBox="0 0 24 25" width="24" fill="#3C7EF3">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M8 5v14l11-7z"/>
                </svg>,
    muteIcon: <svg xmlns="http://www.w3.org/2000/svg" 
                    height="21" viewBox="0 0 23 21" width="23" fill="#3C7EF3">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/>
                </svg>,
    unmuteIcon: <svg width="23" height="21"
                    viewBox="0 0 23 21" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.8664 0.0731868C12.6096 -0.0493225 12.307 -0.0168502 12.0856 0.161748L4.90628 5.90492H1.47602C0.662731 5.90492 0 6.56766 0 7.38094V13.285C0 14.0998 0.662731 14.761 1.47602 14.761H4.90628L12.0841 20.5042C12.2185 20.6105 12.3823 20.6651 12.5461 20.6651C12.6554 20.6651 12.7646 20.64 12.8664 20.5913C13.1218 20.4688 13.2841 20.2105 13.2841 19.9271V0.73887C13.2841 0.455475 13.1218 0.197172 12.8664 0.0731868Z"
                        fill="#3C7EF3" />
                    <path
                        d="M17.0182 5.11472C16.7274 4.82838 16.261 4.8328 15.9746 5.12063C15.6883 5.4114 15.6913 5.87782 15.9806 6.16565C17.0964 7.26675 17.7104 8.7472 17.7104 10.3339C17.7104 11.9206 17.0964 13.4011 15.9806 14.5022C15.6913 14.7871 15.6883 15.255 15.9746 15.5457C16.1193 15.6919 16.3097 15.7642 16.4986 15.7642C16.6861 15.7642 16.8735 15.6933 17.0182 15.5502C18.4175 14.173 19.1865 12.3192 19.1865 10.3339C19.1865 8.34867 18.4175 6.4948 17.0182 5.11472Z"
                        fill="#3C7EF3" />
                    <path
                        d="M19.1019 3.03404C18.8111 2.74622 18.3447 2.74917 18.0569 3.03847C17.7705 3.32777 17.7735 3.79566 18.0613 4.08201C19.7395 5.74548 20.6635 7.96541 20.6635 10.3329C20.6635 12.7005 19.7395 14.9189 18.0613 16.5824C17.7735 16.8702 17.7705 17.3381 18.0569 17.6274C18.203 17.7721 18.3919 17.8444 18.5808 17.8444C18.7683 17.8444 18.9572 17.7735 19.1019 17.6304C21.062 15.6894 22.1395 13.0975 22.1395 10.3329C22.1395 7.56836 21.062 4.97648 19.1019 3.03404Z"
                        fill="#3C7EF3" />
                </svg>,
    downloadIcon: <svg width="16" height="23"
                    viewBox="0 0 16 23" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.3531 18.0648C7.35293 18.0647 7.35327 18.065 7.3531 18.0648C7.37429 18.086 7.3971 18.1065 7.42026 18.1256C7.43088 18.1343 7.44209 18.1418 7.453 18.15C7.46602 18.1598 7.47879 18.1699 7.49232 18.179C7.50538 18.1877 7.51892 18.1952 7.53232 18.2032C7.54461 18.2106 7.5566 18.2183 7.56924 18.225C7.5829 18.2323 7.59695 18.2385 7.61091 18.2451C7.62406 18.2513 7.63704 18.2579 7.65053 18.2635C7.66407 18.2691 7.6779 18.2736 7.69165 18.2785C7.70613 18.2837 7.7204 18.2893 7.73518 18.2937C7.74893 18.2979 7.7629 18.3009 7.77682 18.3044C7.7919 18.3083 7.80681 18.3125 7.82219 18.3155C7.83831 18.3187 7.85455 18.3206 7.87075 18.3229C7.88411 18.3248 7.8973 18.3274 7.91084 18.3287C7.97099 18.3346 8.03162 18.3346 8.09178 18.3287C8.10531 18.3274 8.1185 18.3248 8.13187 18.3229C8.14807 18.3206 8.16431 18.3187 8.18042 18.3155C8.19581 18.3125 8.21072 18.3083 8.2258 18.3044C8.23968 18.3009 8.25368 18.2979 8.26743 18.2937C8.28222 18.2893 8.29652 18.2837 8.31096 18.2785C8.32471 18.2736 8.33855 18.2691 8.35208 18.2635C8.36557 18.2579 8.37855 18.2513 8.3917 18.2451C8.40566 18.2385 8.41972 18.2323 8.43338 18.225C8.44601 18.2183 8.458 18.2105 8.47029 18.2032C8.4837 18.1952 8.49723 18.1877 8.51029 18.179C8.52383 18.1699 8.53659 18.1598 8.54961 18.15C8.56052 18.1419 8.57174 18.1343 8.58235 18.1256C8.60551 18.1065 8.62781 18.0865 8.649 18.0653C8.64883 18.0655 8.64917 18.0651 8.649 18.0653L15.0662 11.6482C15.4241 11.2902 15.4241 10.7098 15.0662 10.3518C14.7082 9.99384 14.1278 9.99384 13.7698 10.3518L8.91794 15.2036V0.916652C8.91794 0.410395 8.50754 0 8.00129 0C7.49503 0 7.08463 0.410395 7.08463 0.916652V15.2036L2.23282 10.3518C1.87484 9.99384 1.29442 9.99384 0.936448 10.3518C0.578476 10.7098 0.578476 11.2902 0.936448 11.6482L7.3531 18.0648Z"
                        fill="#133159" />
                    <path
                        d="M14.4179 20.168H1.58462C1.07836 20.168 0.667969 20.5784 0.667969 21.0846C0.667969 21.5909 1.07836 22.0013 1.58462 22.0013H14.418C14.9242 22.0013 15.3346 21.5909 15.3346 21.0847C15.3346 20.5784 14.9242 20.168 14.4179 20.168Z"
                        fill="#133159" />
                </svg>,

    crossWhiteWithRedBgIcon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z" fill="white"/>
                        <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z" fill="white"/>
                    </svg>,
    copyIcon : <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.30784 0.394992C7.26697 0.394992 3.35179 0.384766 3.35179 0.384766C2.65421 0.384766 2.03312 1.02466 2.03312 1.70345L1.65202 1.71235C0.954766 1.71235 0.384766 2.34333 0.384766 3.02213V9.61553C0.384766 10.2943 1.00587 10.9342 1.70345 10.9342H6.64851C7.34608 10.9342 7.96718 10.2943 7.96718 9.61553H8.29685C8.99443 9.61553 9.61553 8.97566 9.61553 8.29686V3.03004L7.30784 0.394992ZM6.64851 10.2749H1.70345C1.3573 10.2749 1.04412 9.95149 1.04412 9.61553V3.02213C1.04412 2.53158 1.44763 2.36279 2.03312 2.36279V8.29686C2.03312 8.97566 2.65421 9.61553 3.35179 9.61553C3.35179 9.61553 6.92608 9.61192 7.3108 9.61192C7.3108 10.0121 7.0484 10.2749 6.64851 10.2749ZM7.30784 7.31146H4.34081C4.15884 7.31146 4.01115 7.1641 4.01115 6.98211C4.01115 6.80015 4.15884 6.65279 4.34081 6.65279H7.30784C7.48982 6.65279 7.63751 6.80015 7.63751 6.98211C7.63751 7.16412 7.48982 7.31146 7.30784 7.31146ZM7.30784 5.66477H4.34081C4.15884 5.66477 4.01115 5.51739 4.01115 5.33543C4.01115 5.15345 4.15884 5.00609 4.34081 5.00609H7.30784C7.48982 5.00609 7.63751 5.15345 7.63751 5.33543C7.63751 5.51707 7.48982 5.66477 7.30784 5.66477ZM7.96718 3.02213C7.61609 3.02213 7.30784 2.70532 7.30784 2.36279C7.30784 2.36279 7.30784 1.69487 7.30784 1.05433V1.05366L8.9562 3.02213H7.96718Z" fill="#3C7EF3"/>
            </svg>,
    sendIcon: <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.346 0.384766L0.115234 5.57707L3.06521 6.66965L7.61525 2.404L4.15434 7.07305L4.15371 9.61553L5.80804 7.6855L7.90372 8.4617L9.346 0.384766Z" fill="#3C7EF3"/>
            </svg>,

    backCircleWhite: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z" fill="white"/>
                    </svg>,

    smsIconBlue: <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="8.14551" cy="7.6" rx="8" ry="7.6" fill="#3C7EF3"/>
                    <path d="M8.14554 0C3.73433 0 0.145538 3.31169 0.145538 7.38226C0.145538 8.91225 0.639911 10.3624 1.57976 11.6023L0.197402 15.0222C0.0888581 15.2906 0.153873 15.5979 0.361699 15.7994C0.497101 15.9309 0.67566 16 0.856999 16C0.953873 16 1.05149 15.9802 1.14373 15.9396L5.08186 14.2037C6.05301 14.5762 7.08214 14.7647 8.14554 14.7647C9.49307 14.7647 10.8256 14.4496 11.999 13.8538C12.3493 13.6758 12.4889 13.2477 12.3111 12.8974C12.1331 12.5472 11.7051 12.4075 11.3548 12.5853C10.3801 13.0804 9.27043 13.3422 8.14554 13.3422C7.16809 13.3422 6.22731 13.1521 5.34896 12.7774C5.16818 12.7002 4.96313 12.7013 4.78291 12.7807L2.17267 13.9314L3.05491 11.7488C3.15438 11.503 3.10882 11.2226 2.93692 11.0207C2.04135 9.97027 1.56809 8.71202 1.56809 7.38245C1.56809 4.09613 4.51877 1.42255 8.14554 1.42255C11.7723 1.42255 14.723 4.09613 14.723 7.38245C14.723 8.51253 14.3722 9.61222 13.7083 10.5628C13.4834 10.8849 13.5622 11.3284 13.8843 11.5532C14.2062 11.7781 14.6498 11.6994 14.8747 11.3773C15.706 10.1866 16.1455 8.80519 16.1455 7.38245C16.1455 3.31169 12.5567 0 8.14554 0Z" fill="#3C7EF3"/>
                    <path d="M13.5633 7.66344L10.7688 4.42066C10.6295 4.25895 10.4268 4.16875 10.2118 4.17393C9.9986 4.1793 9.79893 4.28025 9.66816 4.44881L7.08015 7.78236L6.22458 6.7725C6.08918 6.61283 5.8908 6.52096 5.68186 6.52096C5.67983 6.52096 5.67779 6.52096 5.67575 6.52096C5.46459 6.52262 5.2651 6.6182 5.13137 6.78176L3.31169 9.00542C3.06293 9.30938 3.10775 9.75763 3.41171 10.0064C3.71567 10.2552 4.16392 10.2103 4.4125 9.90637L5.69131 8.34379L6.47038 9.26344C6.51557 9.34642 6.57763 9.42181 6.65672 9.48349L8.77573 11.9848C8.9165 12.151 9.11692 12.2365 9.31882 12.2364C9.48126 12.2364 9.64463 12.1812 9.77818 12.0678C10.0779 11.8138 10.1151 11.365 9.86116 11.0653L8.01981 8.89169L10.2587 6.00788L12.4858 8.59218C12.7422 8.88965 13.1914 8.92299 13.4889 8.66664C13.7865 8.4101 13.8199 7.9611 13.5633 7.66344Z" fill="#3C7EF3"/>
                    <path d="M6.38476 4.38845C6.38476 4.90005 5.96985 5.31497 5.45825 5.31497C4.94646 5.31497 4.53174 4.90005 4.53174 4.38845C4.53174 3.87667 4.94646 3.46194 5.45825 3.46194C5.96985 3.46194 6.38476 3.87667 6.38476 4.38845Z" fill="#3C7EF3"/>
                    <path d="M1.74365 14.8044L2.54365 12.4044L4.94365 13.6044L1.74365 14.8044Z" fill="#3C7EF3"/>
                </svg>,
    smsIconDeepBlue: <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="8.14551" cy="7.6" rx="8" ry="7.6" fill="#133159"/>
                    <path d="M8.14554 0C3.73433 0 0.145538 3.31169 0.145538 7.38226C0.145538 8.91225 0.639911 10.3624 1.57976 11.6023L0.197402 15.0222C0.0888581 15.2906 0.153873 15.5979 0.361699 15.7994C0.497101 15.9309 0.67566 16 0.856999 16C0.953873 16 1.05149 15.9802 1.14373 15.9396L5.08186 14.2037C6.05301 14.5762 7.08214 14.7647 8.14554 14.7647C9.49307 14.7647 10.8256 14.4496 11.999 13.8538C12.3493 13.6758 12.4889 13.2477 12.3111 12.8974C12.1331 12.5472 11.7051 12.4075 11.3548 12.5853C10.3801 13.0804 9.27043 13.3422 8.14554 13.3422C7.16809 13.3422 6.22731 13.1521 5.34896 12.7774C5.16818 12.7002 4.96313 12.7013 4.78291 12.7807L2.17267 13.9314L3.05491 11.7488C3.15438 11.503 3.10882 11.2226 2.93692 11.0207C2.04135 9.97027 1.56809 8.71202 1.56809 7.38245C1.56809 4.09613 4.51877 1.42255 8.14554 1.42255C11.7723 1.42255 14.723 4.09613 14.723 7.38245C14.723 8.51253 14.3722 9.61222 13.7083 10.5628C13.4834 10.8849 13.5622 11.3284 13.8843 11.5532C14.2062 11.7781 14.6498 11.6994 14.8747 11.3773C15.706 10.1866 16.1455 8.80519 16.1455 7.38245C16.1455 3.31169 12.5567 0 8.14554 0Z" fill="#133159"/>
                    <path d="M13.5633 7.66344L10.7688 4.42066C10.6295 4.25895 10.4268 4.16875 10.2118 4.17393C9.9986 4.1793 9.79893 4.28025 9.66816 4.44881L7.08015 7.78236L6.22458 6.7725C6.08918 6.61283 5.8908 6.52096 5.68186 6.52096C5.67983 6.52096 5.67779 6.52096 5.67575 6.52096C5.46459 6.52262 5.2651 6.6182 5.13137 6.78176L3.31169 9.00542C3.06293 9.30938 3.10775 9.75763 3.41171 10.0064C3.71567 10.2552 4.16392 10.2103 4.4125 9.90637L5.69131 8.34379L6.47038 9.26344C6.51557 9.34642 6.57763 9.42181 6.65672 9.48349L8.77573 11.9848C8.9165 12.151 9.11692 12.2365 9.31882 12.2364C9.48126 12.2364 9.64463 12.1812 9.77818 12.0678C10.0779 11.8138 10.1151 11.365 9.86116 11.0653L8.01981 8.89169L10.2587 6.00788L12.4858 8.59218C12.7422 8.88965 13.1914 8.92299 13.4889 8.66664C13.7865 8.4101 13.8199 7.9611 13.5633 7.66344Z" fill="#133159"/>
                    <path d="M6.38476 4.38845C6.38476 4.90005 5.96985 5.31497 5.45825 5.31497C4.94646 5.31497 4.53174 4.90005 4.53174 4.38845C4.53174 3.87667 4.94646 3.46194 5.45825 3.46194C5.96985 3.46194 6.38476 3.87667 6.38476 4.38845Z" fill="#133159"/>
                    <path d="M1.74365 14.8044L2.54365 12.4044L4.94365 13.6044L1.74365 14.8044Z" fill="#133159"/>
                </svg>,
    emailIconBlue: <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.66666 0C1.95942 0 1.28114 0.280951 0.781048 0.781048C0.280951 1.28114 0 1.95942 0 2.66666V2.93466L7.99999 7.24266L16 2.936V2.66666C16 1.95942 15.719 1.28114 15.2189 0.781048C14.7188 0.280951 14.0406 0 13.3333 0H2.66666Z" fill="#3C7EF3"/>
                        <path d="M16 4.44901L8.31599 8.58633C8.21887 8.63862 8.11029 8.66598 7.99999 8.66598C7.88969 8.66598 7.78112 8.63862 7.68399 8.58633L0 4.44901V10.6663C0 11.3736 0.280951 12.0518 0.781048 12.5519C1.28114 13.052 1.95942 13.333 2.66666 13.333H13.3333C14.0406 13.333 14.7188 13.052 15.2189 12.5519C15.719 12.0518 16 11.3736 16 10.6663V4.44901Z" fill="#3C7EF3"/>
                    </svg>,
    emailIconDeepBlue: <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.66666 0C1.95942 0 1.28114 0.280951 0.781048 0.781048C0.280951 1.28114 0 1.95942 0 2.66666V2.93466L7.99999 7.24266L16 2.936V2.66666C16 1.95942 15.719 1.28114 15.2189 0.781048C14.7188 0.280951 14.0406 0 13.3333 0H2.66666Z" fill="#133159"/>
                            <path d="M16 4.44901L8.31599 8.58633C8.21887 8.63862 8.11029 8.66598 7.99999 8.66598C7.88969 8.66598 7.78112 8.63862 7.68399 8.58633L0 4.44901V10.6663C0 11.3736 0.280951 12.0518 0.781048 12.5519C1.28114 13.052 1.95942 13.333 2.66666 13.333H13.3333C14.0406 13.333 14.7188 13.052 15.2189 12.5519C15.719 12.0518 16 11.3736 16 10.6663V4.44901Z" fill="#133159"/>
                        </svg>,
    videoIconBlue: <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.05039 0H1.75056C0.787752 0 0 0.787752 0 1.75056V7.64994C0 8.61275 0.787752 9.4005 1.75056 9.4005H9.05039C10.0132 9.4005 10.8009 8.61275 10.8009 7.64994V1.75056C10.8009 0.770246 10.0132 0 9.05039 0Z" fill="#3C7EF3"/>
                        <path d="M14.7045 0.963255C14.5995 0.980761 14.4944 1.03328 14.4069 1.08579L11.676 2.6613V6.7226L14.4244 8.2981C14.9321 8.5957 15.5623 8.42064 15.8599 7.91298C15.9474 7.75543 15.9999 7.58037 15.9999 7.38781V1.97858C15.9999 1.33087 15.3872 0.805705 14.7045 0.963255Z" fill="#3C7EF3"/>
                    </svg>,
    videoIconDeepBlue: <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.05039 0H1.75056C0.787752 0 0 0.787752 0 1.75056V7.64994C0 8.61275 0.787752 9.4005 1.75056 9.4005H9.05039C10.0132 9.4005 10.8009 8.61275 10.8009 7.64994V1.75056C10.8009 0.770246 10.0132 0 9.05039 0Z" fill="#133159"/>
                            <path d="M14.7045 0.963255C14.5995 0.980761 14.4944 1.03328 14.4069 1.08579L11.676 2.6613V6.7226L14.4244 8.2981C14.9321 8.5957 15.5623 8.42064 15.8599 7.91298C15.9474 7.75543 15.9999 7.58037 15.9999 7.38781V1.97858C15.9999 1.33087 15.3872 0.805705 14.7045 0.963255Z" fill="#133159"/>
                        </svg>,
    voiceIconBlue: <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2392 8.2641C10.2392 7.94412 9.99456 7.69943 9.67458 7.69943C9.35461 7.69943 9.10992 7.94412 9.10992 8.2641C9.10992 10.4663 7.32182 12.2544 5.11962 12.2544C2.91743 12.2544 1.12933 10.4663 1.12933 8.2641C1.12933 7.94412 0.884641 7.69943 0.564664 7.69943C0.244688 7.69943 0 7.94412 0 8.2641C0 10.8804 1.9575 13.0826 4.55496 13.3649V14.8707H2.50335C2.18337 14.8707 1.93868 15.1154 1.93868 15.4353C1.93868 15.7553 2.18337 16 2.50335 16H7.7359C8.05588 16 8.30057 15.7553 8.30057 15.4353C8.30057 15.1154 8.05588 14.8707 7.7359 14.8707H5.68429V13.3649C8.28174 13.0826 10.2392 10.8804 10.2392 8.2641Z" fill="#3C7EF3"/>
                        <path d="M5.12011 0C3.38847 0 1.97681 1.41166 1.97681 3.1433V8.2441C1.97681 9.99456 3.38847 11.3874 5.12011 11.4062C6.85174 11.4062 8.2634 9.99456 8.2634 8.26292V3.1433C8.2634 1.41166 6.85174 0 5.12011 0Z" fill="#3C7EF3"/>
                    </svg>,
    voiceIconDeepBlue: <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2392 8.2641C10.2392 7.94412 9.99456 7.69943 9.67458 7.69943C9.35461 7.69943 9.10992 7.94412 9.10992 8.2641C9.10992 10.4663 7.32182 12.2544 5.11962 12.2544C2.91743 12.2544 1.12933 10.4663 1.12933 8.2641C1.12933 7.94412 0.884641 7.69943 0.564664 7.69943C0.244688 7.69943 0 7.94412 0 8.2641C0 10.8804 1.9575 13.0826 4.55496 13.3649V14.8707H2.50335C2.18337 14.8707 1.93868 15.1154 1.93868 15.4353C1.93868 15.7553 2.18337 16 2.50335 16H7.7359C8.05588 16 8.30057 15.7553 8.30057 15.4353C8.30057 15.1154 8.05588 14.8707 7.7359 14.8707H5.68429V13.3649C8.28174 13.0826 10.2392 10.8804 10.2392 8.2641Z" fill="#133159"/>
                        <path d="M5.12011 0C3.38847 0 1.97681 1.41166 1.97681 3.1433V8.2441C1.97681 9.99456 3.38847 11.3874 5.12011 11.4062C6.85174 11.4062 8.2634 9.99456 8.2634 8.26292V3.1433C8.2634 1.41166 6.85174 0 5.12011 0Z" fill="#133159"/>
                    </svg>,
    sendIconBlue : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0L0 8.99999L5.1133 10.8938L13 3.5L7.00112 11.593L7.006 11.5948L7.00002 11.593V16L9.86753 12.6546L13.5 14L16 0Z" fill="#3C7EF3"/>
                    </svg>,
    sendIconDeepBlue: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 0L0 8.99999L5.1133 10.8938L13 3.5L7.00112 11.593L7.006 11.5948L7.00002 11.593V16L9.86753 12.6546L13.5 14L16 0Z" fill="#133159"/>
                        </svg>,
    saveTemplate: <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="13" cy="13" r="13" fill="white"/>
                        <path d="M15 15H10V16H15V15Z" fill="#3C7EF3"/>
                        <path d="M10 17V17.5V18H15V17.5V17H10Z" fill="#3C7EF3"/>
                        <path d="M17.8998 9.87553L15.8907 7.1998C15.7963 7.07404 15.6482 7 15.4909 7H14.6667V7.50001V8.00001V11.1667C14.6667 11.4428 14.4428 11.6667 14.1667 11.6667H9.50001C9.22387 11.6667 9 11.4428 9 11.1667V7.99999V7.50001V7H7.50001C7.22387 7 7 7.22391 7 7.50001V17.5C7 17.7761 7.22387 18 7.50001 18H9V17.5V17V16.5V14.5C9 14.2238 9.22387 14 9.50001 14H15.5C15.7762 14 16 14.2238 16 14.5V16.5V17V17.5V18H17.5C17.7761 18 18 17.7761 18 17.5V10.1758C18 10.0675 17.9648 9.96209 17.8998 9.87553Z" fill="#3C7EF3"/>
                        <path d="M10 7V7.50001V7.99999V10.6667H13.6667V7.99999V7.50001V7H10Z" fill="#3C7EF3"/>
                    </svg>,
    uploadIcon: <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.834 6.39588C4.84169 2.38155 8.91278 -0.0557942 12.9271 0.951897C16.0642 1.73941 18.3421 4.45076 18.5768 7.67664C20.8043 8.04397 22.3122 10.1475 21.9449 12.3749C21.6184 14.3552 19.9025 15.8054 17.8955 15.7974H14.4891V14.4349H17.8955C19.4005 14.4349 20.6206 13.2148 20.6206 11.7098C20.6206 10.2048 19.4005 8.98469 17.8955 8.98469C17.5192 8.98469 17.2142 8.67969 17.2142 8.30341C17.2177 4.91709 14.4752 2.16917 11.0889 2.1658C8.15754 2.16286 5.63414 4.23522 5.06711 7.11118C5.01111 7.39834 4.77807 7.61771 4.48802 7.6562C2.6255 7.92143 1.33069 9.64629 1.59588 11.5088C1.83403 13.1812 3.26198 14.4265 4.95129 14.4349H7.67639V15.7974H4.95129C2.31748 15.7895 0.188833 13.6479 0.196838 11.0141C0.20348 8.82165 1.70416 6.91617 3.834 6.39588Z" fill="#133159"/>
                    <path d="M11.5669 9.18216L14.292 11.9073L13.3314 12.8679L11.7645 11.3077V17.8412H10.4019V11.3077L8.84179 12.8679L7.88119 11.9073L10.6063 9.18216C10.872 8.918 11.3012 8.918 11.5669 9.18216Z" fill="#133159"/>
                </svg>,
    recordVoiceInAddTemplate : <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="#3C7EF3" fillOpacity="0.1"/>
                                    <path d="M19.5831 8.79922C17.4873 8.79922 15.7822 10.5043 15.7822 12.6001V20.0702C15.7822 22.1657 17.4873 23.8708 19.5831 23.8708C21.6789 23.8708 23.384 22.1775 23.384 20.0963V12.6001C23.384 10.5043 21.6789 8.79922 19.5831 8.79922ZM21.8 20.0961C21.8 21.3039 20.8054 22.2868 19.5831 22.2868C18.3607 22.2868 17.3659 21.2925 17.3659 20.0699V12.6001C17.3659 11.3775 18.3605 10.3829 19.5831 10.3829C20.8057 10.3829 21.8003 11.3775 21.8 12.6001V20.0961Z" fill="#3C7EF3"/>
                                    <path d="M20.3747 26.1414H18.791V29.7048H20.3747V26.1414Z" fill="#3C7EF3"/>
                                    <path d="M21.6683 28.9117H17.4979C17.0605 28.9117 16.7061 29.2662 16.7061 29.7036C16.7061 30.1409 17.0605 30.4954 17.4979 30.4954H21.6683C22.1057 30.4954 22.4602 30.1409 22.4602 29.7036C22.4602 29.2662 22.1057 28.9117 21.6683 28.9117Z" fill="#3C7EF3"/>
                                    <path d="M25.575 18.7508C25.1377 18.7508 24.7832 19.1053 24.7832 19.5426V20.1761C24.7832 23.0431 22.4504 25.3759 19.5833 25.3759C16.7161 25.3759 14.3835 23.0432 14.3835 20.1761V19.5426C14.3835 19.1053 14.029 18.7508 13.5917 18.7508C13.1543 18.7508 12.7998 19.1053 12.7998 19.5426V20.1761C12.7998 23.9165 15.8429 26.9596 19.5833 26.9596C23.3238 26.9596 26.3669 23.9165 26.3669 20.1761V19.5426C26.3669 19.1053 26.0124 18.7508 25.575 18.7508Z" fill="#3C7EF3"/>
                                </svg>,
    stopRecordInAddTemplate: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#3C7EF3" fillOpacity="0.1"/>
                                <path d="M22.7518 16.9941H17.2562C17.1246 16.9941 17.0166 17.0973 17.0166 17.231V22.7861C17.0166 22.917 17.1239 23.0243 17.2562 23.0243H22.7518C22.8833 23.0243 22.99 22.9177 22.99 22.7861V17.231C22.9893 17.098 22.8833 16.9941 22.7518 16.9941Z" fill="#FF264A"/>
                                <path d="M20.0042 9.59219C14.2586 9.59219 9.59961 14.2498 9.59961 19.9968C9.59961 25.7437 14.2586 30.4013 20.0042 30.4013C25.7504 30.4013 30.4074 25.7437 30.4074 19.9968C30.4074 14.2498 25.7504 9.59219 20.0042 9.59219ZM20.0042 28.6632C15.2177 28.6632 11.3371 24.7825 11.3371 19.9968C11.3371 15.2089 15.2177 11.3289 20.0042 11.3289C24.7913 11.3289 28.6699 15.2089 28.6699 19.9968C28.6699 24.7825 24.7913 28.6632 20.0042 28.6632Z" fill="#FF264A"/>
                            </svg>,
    uploadIconBlue: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#3C7EF3" fillOpacity="0.1"/>
                        <path d="M11.773 16.4141C12.9837 11.5908 17.8752 8.66234 22.6984 9.87309C26.4677 10.8193 29.2046 14.077 29.4866 17.9529C32.1629 18.3943 33.9748 20.9216 33.5334 23.598C33.1411 25.9773 31.0794 27.7197 28.668 27.7102H24.5752V26.073H28.668C30.4763 26.073 31.9423 24.6071 31.9423 22.7988C31.9423 20.9905 30.4763 19.5246 28.668 19.5246C28.2159 19.5246 27.8495 19.1581 27.8495 18.706C27.8536 14.6373 24.5585 11.3356 20.4898 11.3316C16.9678 11.3281 13.9359 13.818 13.2546 17.2735C13.1873 17.6185 12.9073 17.8821 12.5588 17.9284C10.321 18.247 8.76523 20.3195 9.08386 22.5573C9.37 24.5667 11.0857 26.0629 13.1154 26.073H16.3897V27.7102H13.1154C9.95087 27.7006 7.39328 25.1274 7.4029 21.9629C7.41088 19.3287 9.21396 17.0392 11.773 16.4141Z" fill="#3C7EF3"/>
                        <path d="M21.0637 19.7619L24.3379 23.0361L23.1837 24.1903L21.301 22.3158V30.1658H19.6639V22.3158L17.7894 24.1903L16.6352 23.0361L19.9095 19.7619C20.2288 19.4445 20.7444 19.4445 21.0637 19.7619Z" fill="#3C7EF3"/>
                    </svg>,
    infoIconRed : <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 0C4.7103 0 0 4.71036 0 10.5001C0 16.2898 4.7103 21 10.5 21C16.2897 21 21 16.2898 21 10.5001C21 4.71036 16.2897 0 10.5 0ZM10.5 19.0909C5.76291 19.0909 1.90909 15.2371 1.90909 10.5001C1.90909 5.76304 5.76291 1.90909 10.5 1.90909C15.2371 1.90909 19.0909 5.76304 19.0909 10.5001C19.0909 15.2371 15.237 19.0909 10.5 19.0909Z" fill="#FF6B6B"/>
                        <path d="M10.4995 4.4543C9.79787 4.4543 9.22705 5.0255 9.22705 5.7276C9.22705 6.42906 9.79787 6.99975 10.4995 6.99975C11.2012 6.99975 11.772 6.42906 11.772 5.7276C11.772 5.0255 11.2012 4.4543 10.4995 4.4543Z" fill="#FF6B6B"/>
                        <path d="M10.499 8.90695C9.97182 8.90695 9.54443 9.33433 9.54443 9.8615V15.5888C9.54443 16.1159 9.97182 16.5433 10.499 16.5433C11.0261 16.5433 11.4535 16.1159 11.4535 15.5888V9.8615C11.4535 9.33433 11.0261 8.90695 10.499 8.90695Z" fill="#FF6B6B"/>
                    </svg>,
    crossIconModal: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z" fill="white"/>
                        <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z" fill="white"/>
                    </svg>
    
}