import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    makeStyles,
    Modal,
    Paper,
    Radio,
    Typography
} from "@material-ui/core";
import Styles from "./EnhanceData.module.css";
import StripedProgress from "./StripedProgress";
import icon from "./icon";
import ResultStyles from "./ValidationResultModal.module.css"


const useStyles = makeStyles((theme) => {
    return {
      root: {
        "&.MuiButtonBase-root": {
          width: "190px",
        },
        "&.MuiButton-root": {
          backgroundColor: "#008945",
          padding: "10px 20px",
          borderRadius: "4px",
        },
        "&.MuiButton-text": {
          color: "grey",
        },
        "&.MuiButton-contained": {
          color: "white",
        },
        "&.MuiButton-outlined": {
          color: "brown",
        },
      },
     
    }
});



const ImportEnhancePopUp = ({ isOpen, onClose, data, carrierLookupPrices, updateCarrierLookupPrices }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showModalContent, setShowModalContent] = useState(true);

    useEffect(() => {
        if (!carrierLookupPrices) {
            setLoading(true);
        }
    }, []);

      const toggleModalContent = () => {
        setShowModalContent((prev) => !prev)
      }

    return (
      <>
        <Modal
          open={isOpen}
          onClose={() => {}}
          aria-labelledby="enhance-modal-title"
          aria-describedby="enhance-modal-description"
          className={`${Styles.modalWrapper} global-xs-modal`}
        >
            <Paper className={Styles.modalInner}>
            <div style={{ padding: "10px", width: "100%" }}>
                <div id="importEnhancePopupProgress">
                    <h5 id="importEnhancePopupSuccessHeader" className={`${ResultStyles.successTitle} ${ResultStyles.displayHidden}`} >Processing: 100% complete</h5>
                    <div id="importEnhancePopupHeader">
                        <StripedProgress
                            width={100}
                            text={
                                "Processing... this may take a few minutes depending on the number of records"
                            }
                        />
                    </div>

                    <div className={ResultStyles.borderedDiv}>
                        <span className={ResultStyles.topText}>RESULTS</span>
                        <div className={ResultStyles.content}>
                            <div>
                                <div className={`${ResultStyles.svgIcon}`}>{icon.google}</div>
                                <div id="helpTextPopupModal" style={{textAlign:"center"}}>
                                    <p className={ResultStyles.unsetMargin}>
                                        <b>
                                            You can wait here or close the screen.
                                        </b>
                                    </p>
                                    <p className={ResultStyles.unsetMargin}>
                                        <b>
                                            We'll notify you when your import is ready!
                                        </b>
                                    </p>

                                </div>
                                <input hidden id="download_file" type="text"/>
                                <div id="successTextPopupModal" className={`${ResultStyles.displayHidden}`}>
                                </div>
                                <div  style={{textAlign:"center"}} id="importEnhancePopupModal" className={`${ResultStyles.displayHidden}`}>
                                    <button className={`${ResultStyles.downloadButton}`}>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ResultStyles.closeButtonDiv}>
                    <button id="closeImportEnhancePopup" className={ResultStyles.closeButton}  onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
            </Paper>
        </Modal>
      </>
    )
}

export default connect()(ImportEnhancePopUp);