import * as ACTION from "../constants/notificationActionTypes";

const initialState = {
    siteNotifications : null,
    leadNotifications : null,
    siteNotificationsLoaded : false,
    leadNotificationsLoaded : false,
    siteNotificationsPage : 0,
    leadNotificationsPage : 0,
    siteHasScroll : true,
    leadHasScroll : true,
    notificationCount : 0,
    inboxCount : 0,
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.RESTORE_SITE_NOTIFICATION:
            return {...state, 
                siteNotificationsLoaded : true,
                siteNotifications: [],
                siteNotificationsPage : 1,
                siteHasScroll : false
            }
        case ACTION.STORE_SITE_NOTIFICATION:
            return {...state, 
                siteNotificationsLoaded : true,
                siteNotifications: action.payload.notifications,
                siteNotificationsPage : 1,
                siteHasScroll : action.payload.notifications.length === action.payload.take
            }
        case ACTION.RESTORE_LEAD_NOTIFICATION:
            return {...state,
                leadNotificationsLoaded : true, 
                leadNotifications: [],
                leadNotificationsPage : 1,
                leadHasScroll : false
            }
        case ACTION.STORE_LEAD_NOTIFICATION:
            return {...state,
                leadNotificationsLoaded : true, 
                leadNotifications: action.payload.notifications,
                leadNotificationsPage : state.leadNotificationsPage + 1,
                leadHasScroll : action.payload.notifications.length === action.payload.take
            }
        case ACTION.APPEND_SITE_NOTIFICATION:
            return {...state,
                siteNotificationsLoaded : true, 
                siteNotifications: [...state.siteNotifications,...action.payload.notifications],
                siteNotificationsPage : state.siteNotificationsPage + 1,
                siteHasScroll : action.payload.notifications.length === action.payload.take
            }
        case ACTION.APPEND_LEAD_NOTIFICATION:
            return {...state,
                leadNotificationsLoaded : true, 
                leadNotifications: [...state.leadNotifications,...action.payload.notifications],
                leadNotificationsPage : state.leadNotificationsPage + 1,
                leadHasScroll : action.payload.notifications.length === action.payload.take
            }
        case ACTION.CLOSE_SITE_NOTIFICATION:
            let notificationArr = [...state.siteNotifications];
            notificationArr.splice(action.payload.index,1);
            return {...state, siteNotifications: notificationArr};
        case ACTION.CLOSE_LEAD_NOTIFICATION:
            let leadNotificationArr = [...state.leadNotifications];
            leadNotificationArr.splice(action.payload.index,1);
            return {...state, leadNotifications: leadNotificationArr};
        case ACTION.SET_SITE_NOTIFICATION_LOADED:
            return {...state, siteNotificationsLoaded : action.payload}
        case ACTION.SET_LEAD_NOTIFICATION_LOADED:
            return {...state, leadNotificationsLoaded : action.payload}
        case ACTION.STORE_NOTIFICATION_COUNT:
            return {...state, 
                notificationCount: action.payload.notificationCount,
                inboxCount : action.payload.inboxCount
            }
        case ACTION.INCREMENT_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount : state.notificationCount + 1
            }
        default:
            return state;
    }
}

export default notificationReducer;
