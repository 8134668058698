export const setCookie = (cname, cvalue, exTime) => {
    // var d = new Date();
    // d.setTime(d.getTime() + timeToMiliSecond(exTime));
    // var expires = "expires="+d.toUTCString();
    // var expires = "max-age="+timeToSecond(exTime);
    // document.cookie = "test1=Hello; SameSite=None; Secure";
    document.cookie = cname+"="+ btoa(cvalue) +"; Secure; path=/";
    // document.cookie = cname + "=" + btoa(cvalue) + ";" + expires + ";path=/";
}

export const setCookieLogin = (cname, cvalue, exTime) => {
    let b64 = encodeURIComponent(cvalue);
    document.cookie = cname+"="+ window.btoa(b64) +"; Secure; path=/";

    var d = new Date();
    d.setTime(d.getTime() + timeToMiliSecond('365 day'));
    var expires = "expires="+d.toUTCString();
    document.cookie = "encode__process__new=true;" + expires + ";path=/";
}

const timeToMiliSecond = time => {
    const number = time.substring(0, time.indexOf(' '));
    switch (time.substr(time.indexOf(' ')+1)) {
        case 'day':
            return number * 24 * 60 * 60 * 1000;
        case 'hour':
            console.log('here');
            return number * 60 * 60 * 1000;
        case 'minute':
            return number * 60 * 1000;
        case 'second':
            return number * 1000;
        default:
            return number * 60 * 1000;
    }
}

export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

export const getCookieOfThirdParty = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const getCookieLogin = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let str = c.substring(name.length, c.length).replace(/\s/g, ''); 
            return decodeURIComponent(window.atob(str))
        }
    }
    return "";
}


// const timeToMiliSecond = time => {
//     const number = time.substring(0, time.indexOf(' '));
//     switch (time.substr(time.indexOf(' ')+1)) {
//         case 'day':
//             return number * 24 * 60 * 60 * 1000;
//         case 'hour':
//             return number * 60 * 60 * 1000;
//         case 'minute':
//             return number * 60 * 1000;
//         case 'second':
//             return number * 1000;
//         default:
//             return number * 60 * 1000;
//     }
// }

// const timeToSecond = time => {
//     const number = time.substring(0, time.indexOf(' '));
//     switch (time.substr(time.indexOf(' ')+1)) {
//         case 'day':
//             return number * 24 * 60 * 60;
//         case 'hour':
//             return number * 60 * 60;
//         case 'minute':
//             return number * 60;
//         case 'second':
//             return number;
//         default:
//             return number * 60;
//     }
// }

export const deleteCookie = cname => {
    if( getCookie(cname) ) {
        document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}


export const checkCookie = (cname) => {
    const cookieInfo = getCookie(cname);
    return !!cookieInfo;
}