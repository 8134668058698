import DirectMailIcons from "../../../../DirectMailIcons";

const FooterButton =({
    handleStepChange,
    selectDesignStep,
    isValid=true
}) => {
    return (
        <div className='dmpc_tv_right_inner_btn'>
            <button 
                className='dmpc_tv_right_inner_backbtn' 
                onClick={(e) => {
                    e.preventDefault();
                    handleStepChange(false)
                }}
            >
                <span>{DirectMailIcons.backIcon}</span>
                <span>Go Back</span>
            </button>
            <button 
                className={`dmpc_tv_right_inner_savebtn ${selectDesignStep.selectedTemplate == null ? 'global_post_card_disabled_btn' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    if(selectDesignStep.selectedTemplate == null){
                        return
                    }
                    handleStepChange(true)
                }}
            >
                <span>{DirectMailIcons.saveContinueIcon}</span>
                <span>Save & Continue</span>
            </button>
        </div>
    );
}
export default FooterButton;