import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 396,
    },

  }));
export const DropdownHelper = (props) => {
    const classes = useStyles();
    const [datalist, setDatalist] = useState([]);

    useEffect(() => {
        if (props.datalist !== undefined && props.datalist != null && Array.isArray(props.datalist)) {
            setDatalist(props.datalist);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.datalist !== undefined && props.datalist != null && Array.isArray(props.datalist)) {
            setDatalist(props.datalist);
        }
    }, [props.datalist.length]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateSelectedData = async (event) => {

        if(props.multipleSelect){

            if(event.target.value.indexOf(-1) === 0){
                let values = event.target.value
                values.splice(0,1)
                props.updateSelectedData(values)
                if(props.updateDatatable !== undefined && props.updateDatatable != null && props.dropdownHeader!== undefined && props.dropdownHeader != null){
                    props.updateDatatable(props.dropdownHeader ,values);
                    
                }
            }else if (event.target.value.includes(-1)){
                props.updateSelectedData([-1])
                props.updateDatatable(props.dropdownHeader ,[-1]);

            }else{
                await props.updateSelectedData(event.target.value);
                if(props.updateDatatable !== undefined && props.updateDatatable != null && props.dropdownHeader!== undefined && props.dropdownHeader != null){
                props.updateDatatable(props.dropdownHeader ,event.target.value);
    
                }
            }

        }else{
            await props.updateSelectedData(event.target.value);
            if(props.updateDatatable !== undefined && props.updateDatatable != null && props.dropdownHeader!== undefined && props.dropdownHeader != null){
                props.updateDatatable(props.dropdownHeader ,event.target.value);
    
            }
        }
        
    }

    return (
        <React.Fragment>
            <div className={(props.parentDivClassName !== undefined && props.parentDivClassName != null) ?"droupdown__helper "+props.parentDivClassName : "droupdown__helper"}>
                <FormControl className="second__step__area__select second__step__area__select__100 assign__stage_select_wrapper contact-list-dropdowns">
                    <Select
                        // className="contact__middle__inbox__select__innner"
                        multiple={props.multipleSelect}
                        value={props.selectedValue} 
                        onChange={updateSelectedData}
                        className={(props.className !== undefined && props.className != null && props.className !== "") ? `${props.className} ` : ''}
                        displayEmpty
                        inputProps={{ 'aria-label': (props.ariaLabel !== undefined && props.ariaLabel != null) ? props.ariaLabel : 'Without label' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.menuPaper }
                        }}
                    >
                        {props.noneFieldRequired &&
                            <MenuItem value="" index="" disabled 
                            className={'dropdownhelper-menuitem-class'}>
                                <em>{(props.noneFieldTitle !== undefined && props.noneFieldTitle != null) ? props.noneFieldTitle : 'None'}</em>
                            </MenuItem>
                        }
                        {props.selectAllFieldRequired && props.multipleSelect &&
                            <MenuItem value={-1} key={`dropdownHelper${-1}`}
                            className={'dropdownhelper-menuitem-class'}>
                                {'All users'}
                            </MenuItem>
                        }
                        {Array.isArray(datalist) && props.mapping!=null &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data.id} key={`dropdownHelper${index}${data.id}`}
                                    className={`dropdownhelper-menuitem-class ${(props.menuItemClassName !== undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}`}
                                >{data[props.mapping.title]}
                                    {data.userName !== undefined && (
                                        <span>{` - ${data.userName}`}</span>
                                    )}
                                </MenuItem>

                            ))
                        }
                        {Array.isArray(datalist) && props.mapping ==null &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data} key={`dropdownHelper${index}`}
                                    className={`dropdownhelper-menuitem-class d-flex ${(props.menuItemClassName !== undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}`}
                                >{data}</MenuItem>

                            ))
                        }
                        {/* {props.mapping.id == 'key' &&

                            Object.keys(datalist).map((x,y) => <MenuItem key={x} value={x}>{datalist[x]}</MenuItem>)
                        } */}
                    </Select>
                </FormControl>
            </div>
        </React.Fragment>
    )
}