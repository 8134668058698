import {useContext, useEffect, useState} from 'react';
import ApiList from '../assets/ApiList';
import LinkFile from '../LinkFile';
import { updateMessage, updateMmsFile, updateShowQuickReply } from '../reducers/Action';
import { SmsSendContext } from '../reducers/GlobalReduers';

const QuickReplies = (props) => {
    const {states, dispatch} = useContext(SmsSendContext)
    const [isLoading, setIsLoading] = useState(true)
    const [templates, setTemplates] = useState([])

    /* for fetching data onscroll */
    const [perPage, setPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        if(props.scrolling){
            ApiList.getSmsTemplates({
                search_key: "",
                type: [2,5],
                'perPage': perPage,
                'page': currentPage
            }).then(res => {
                let response = res.data
                if(response.status === 'success'){
                    if(response.html.length >= perPage){
                        if(props.updateScrollOption !== undefined){
                            props.updateScrollOption("needToLoad", true)
                        }
                        setCurrentPage(currentPage + 1)
                    }
                    else{
                        if(props.updateScrollOption !== undefined){
                            props.updateScrollOption("needToLoad", false)
                        }
                    }
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("fetchingMore", false)
                    }
                    setTemplates([...templates, ...response.html])
                }
            })
        }
    }, [props.scrolling])

    useEffect(() => {
        ApiList.getSmsTemplates({
            search_key: "",
            type: [2,5],
            'perPage': perPage,
            'page': currentPage
        }).then(res => {
            let response = res.data
            if(response.status === 'success'){
                setTemplates(response.html)

                if(response.html.length >= perPage){
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("needToLoad", true)
                    }
                    setCurrentPage(currentPage + 1)
                }
                else{
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("needToLoad", false)
                    }
                }
                if(props.updateScrollOption !== undefined){
                    props.updateScrollOption("fetchingMore", false)
                }
            }
            setIsLoading(false)
        })
        return () => setIsLoading(true)
    }, [])

    const handleBackButton = () => {
        dispatch(updateShowQuickReply(false))
    }

    const hanldeTemplateChange = (item) => {
        if(item.type == 5){
            dispatch(updateMmsFile({
                file_url: item.video_file_url,
                file_data: null,
                selectedMmsFromTemplate: true
            }));
            dispatch(updateMessage({
                message: item.message,
                message_type: 5
            }));
        }
        else{
            dispatch(updateMmsFile({
                file_url: '',
                file_data: null,
                selectedMmsFromTemplate: false
            }));
            dispatch(updateMessage({
                message: item.message,
                message_type: 2
            }));
        }
        handleBackButton()
    }

    const renderList = () => {
        let list = []
        templates.map((item, index) => {
            list.push(<LinkFile.qucikReplyCard
                data={item}
                key={index}
                onSelect={hanldeTemplateChange}
            />)
        });
        return list;
    }

    return(
        <div className="g-email-template-content">
            <div className="g-template-header">
                <span className="g-template-title">Email Quick Reply List</span>
                <div className="g-template-back" > 
                    <div onClick={handleBackButton} className="quick__back__btn__alt accent--bg--color accent--bg--text--color">{'<<'} Back</div>
                </div>
            </div>
            <div className="g-template-body awesome__scroll_bar">
                {isLoading ? 
                    <div className="g-template-loading">Please wait...</div>
                :
                    renderList()
                }
            </div>
        </div>
    )
}
export default QuickReplies;


