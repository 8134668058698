// import EmailSend from "../Deal/Deals/SendModules/EmailSend";
// import SmsSend from "../Deal/Deals/SendModules/SmsSend";
import useDelayCallback from "../../hooks/useDelayCallback";
import { useIsMounted } from "../../hooks/useIsMounted";
import TextEditorTinyMCE from "../common/TextEditorTinyMCE";
import GlobalModal from "../GlobalModal/Modal/GlobalModal";
// import EmailSendModule from "../SendComponents/EmailSendModule";
// import VideoEmailModal from "../SendComponents/Video/VideoEmailModal";
// import VoiceSendModule from "../SendComponents/VoiceSendModule";
// import AddAppointMent from "./modals/AddAppointment/AddAppointMent";
// import CardMultipleStepForm from '../SendComponents/DirectCardSend/CardMultiStepForm'
// import SmsSendModule from "../SendComponents/SmsSendModule";

const LinkFile = {
    'modal' : GlobalModal,
    // 'addAppointment': AddAppointMent,
    'TextEditorTinyMCE': TextEditorTinyMCE,
    // 'smsSend': SmsSend,
    // 'smsSend': SmsSendModule,
    // 'emailSend': EmailSendModule,
    // 'voiceSend': VoiceSendModule,
    // 'videoSend': VideoEmailModal,
    // 'cardMultipleStepForm': CardMultipleStepForm,
    'useDelayCallback': useDelayCallback,
    'isMounted': useIsMounted,
}
export default LinkFile;