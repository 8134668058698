import React, {useContext} from 'react'
import { ToasterContext } from './ToasterProvider';
import Notification from "./Notification";

const SingleToasterView = () => {
    const {state, dispatch} = useContext(ToasterContext);
    const singleNotification = state.single
    if(singleNotification.id === undefined){
        return null
    }
    return(
            <Notification view="single" dispatch={dispatch} key={singleNotification.id} {...singleNotification} />
    )
} 
export default SingleToasterView;