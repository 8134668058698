import { sendRequest } from './rootApi';
import Api from "../constants/Api";

// export const fetchUser = async () => {
//     return await sendRequest("get", "https://my-json-server.typicode.com/rislam169/demo/user");
// }

export const loginRequest = async (loginCredential) => {
    return sendRequest("post", Api.login, loginCredential);
}

export const loginOtpRequest = async (otpCredential) => {
    return sendRequest("post", Api.loginOtpRequest, otpCredential);
}

export const fetchAccountData = async () => {
    return sendRequest("post", Api.fetchAccountData);
}

export const fetchDirectAccountData = async () => {
    return sendRequest("post", Api.fetchDirectAccountData);
}

export const fetchLoginPageData = async () => {
    return sendRequest("post", Api.fetchLoginPageData);
}

export const fetchPrivacyPolicy = async () => {
    return sendRequest("post", Api.fetchPrivacyPolicy);
}

export const fetchTermsAndConditions = async () => {
    return sendRequest("post", Api.fetchTermsAndConditions);
}

export const resendOtpFromLoginScreen = async (form_data) => {
    return sendRequest("post", Api.resendOtpFromLoginScreen, form_data);
}