import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getCurrentCredit = async (params) => {
    return sendRequest("post", Api.getCurrentCredit, params);
}

export const dialerTokenRequest = async (params) => {
    return sendRequest("post", Api.dialerToken, params);
}

export const getContact = async (params) => {
    return sendRequest("post", Api.getContact, params);
}

export const getContactWithPagination = async (params) => {
    return sendRequest("post", Api.getContactWithpagination, params);
}

export const getVirtualNumber = async (params) => {
    return sendRequest("post", Api.getVirtualNumber, params);
}

export const callHangUp = async (params) => {
    return sendRequest("post", Api.callHangUp, params);
}

export const holdCurrentCall = async (params) => {
    return sendRequest("post", Api.holdCurrentCall, params);
}

export const addParticipant = async (params) => {
    return sendRequest("post", Api.addParticipant, params);
}

export const unHoldCallParticipant = async (params) => {
    return sendRequest("post", Api.unHoldParticipant, params);
}

export const sendErrorMessageToDevelopers = async (params) => {
    return sendRequest("post", Api.sendErrorNotification, params);
}

export const getDialerSettings = async (params) => {
    return sendRequest("post", Api.getDialerSettings, params);
}