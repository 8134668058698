
import axios from "axios";
import ContactHelper from "./Helper";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const dealRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const contactRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const marketingRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const inboxRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
// const apiKey = ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)

/* get contact details by id */
const getContactInboxThread = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl+'/get-contact-last-connected-vn',
        data: data
    });
}
/* get personalized */
const getPersonalizedTag = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl+'/get-custom-fields',
        data: {
            'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN),
            'for_custom_field_update' : true
        },
    });
}
/* get user virtual number */
const getUserVirtualNumber = () => {
    return axios.request({
        method: 'POST',
        url: dealRootUrl+'/get-virtual-number-by-user',
        data: {
            'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)
        },
    });
}
/* get sms templates */
const getSmsTemplates = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: marketingRootUrl+'/message-templates',
        data: data
    });
}

/* send sms */
const sendSms = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    if(formData){
        params.append('api-key', ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl+'/make-conversation',
        data: formData ? params : data
    });
}

/* send sms from map */
const sendSmsFromMap = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    if(formData){
        params.append('api-key', ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl + '/make-conversation-from-map',
        data: formData ? params : data
    });
}

const ApiList = {
    getPersonalizedTag,
    getSmsTemplates,
    sendSms,
    getContactInboxThread,
    getUserVirtualNumber,
    sendSmsFromMap
}
export default ApiList;