import React from "react";
import Styles from "./Styles.module.css";
import Icons from "./Icons";

const ResponseMessage = ({ response, hasError, hasSuccess, handleClose, ...rest }) => {

    return(
        <div className={Styles.processingWrapper} {...rest}>
            <div className={Styles.responseMessageIcon}>
                {hasSuccess !== "" ? Icons.success : Icons.failed}
            </div>
            <h4 className={Styles.responseMessage}>
                {hasError === "" ? "Thank you for completing your registration!" : hasError}
            </h4>
            {
                hasSuccess !== "" ?
                    <div
                        className={Styles.responseMessageButton}
                        onClick={() => handleClose({onlyClose: true, redirect: true})}
                    >
                        Close the window
                    </div> :
                    <div
                        className={Styles.responseMessageButton}
                        onClick={() => handleClose({onlyClose: true, redirect: false})}
                    >
                        Try again
                    </div>
            }
        </div>
    );
};

export default ResponseMessage;