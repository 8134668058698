import { Button, Grid, Select, MenuItem } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useIsMounted } from "../../hooks/useIsMounted";
import { buyVirtualNumber, getVirtualSearchNumberList } from "../../api/onboardApi";
import Utils from "../../helpers/Utils";
import Alert from '@material-ui/lab/Alert';
import './number.css';

const Number = (props) => {
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(false);
    // const [componentLoading, setComponentLoading] = useState(false);

    const [displayResult, setDisplayResult] = useState(false);

    const [country, setCountry] = useState('US');
    const [areaCode, setAreaCode] = useState('');

    const [data, setData] = useState([]);

    // const [perPage, setPerPage] = useState(10);
    // const [sortType, setSortType] = useState('desc');
    // const [sortBy, setSortBy] = useState('phone_number');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]);

    const searchPhoneNumber = () => {
        setLoading(true);

        getVirtualSearchNumberList({
            country: country,
            areaCode: areaCode
        })
        .then(response => {
            setData(response.data.data);
        })
        .finally(() => {
            setDisplayResult(true);
            setLoading(false);
        })
    }

    const returnToSearch = (e) => {
        e.preventDefault();
        setData([]);
        setQuery('');
        setDisplayResult(false);
    }

    const buyNumber = (_number) => {
        setLoading(true);

        buyVirtualNumber({
            type: 'single', 
            'getaway_provider_id': 1, 
            virtual_number: _number, 
        }).then(response => {
            if (response.data.status === 'success') {
                props.goToStep(props.activeStep+1);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        Utils.showNotification(error, 'error');
                    });
                }
            } else {
                Utils.showNotification(response.data.message, 'error');
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const renderTable = () => {
        if (!data.length) {
            return (
                <tr>
                    <td colspan="5">
                        <div className="onboarding__vn__search__empty">
                            NO DATA AVAILABLE. <a onClick={returnToSearch} style={{cursor:'pointer'}}>SEARCH AGAIN</a>.
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.filter((item) => {
                let keyword = debouncedQuery.toUpperCase().trim();
                if (keyword !== '') {
                    if (item.phone_number.toUpperCase().indexOf(keyword) > -1) {
                        return true;
                    }
                } else {
                    return true;
                }
            }).map((item,index) => (
                <tr key={index}>
                    <td>{item.phone_number}</td>
                    <td>{item.friendly_name}</td>
                    <td>{item.iso_country}</td>
                    <td>{item.postal_code}</td>
                    <td>
                        <span className="action">
                            <Button variant="contained" color="primary" onClick={() => buyNumber(item.phone_number)}>
                                Add Number
                            </Button>
                        </span>
                    </td>
                </tr>
            ))
        }
    }

    const renderSearch = () => (
        <Grid container>
            <Grid item xs={12} className="mb-2">
                <Alert severity="info">In the US and Canada, these regions are represented by area codes. For example,+1(415)XXX-XXXX numbers are located in the San Francisco Bay Area.</Alert>
            </Grid>
            <Grid item xs={12} className="mb-2">
                <label className="onboarding__number__section__label">
                    Country
                </label>
            </Grid>
            <Grid item xs={12} className="mb-2 onboarding__number__section__select" >
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}
                >
                    <MenuItem value={'US'}>US</MenuItem>
                    <MenuItem value={'CA'}>CANADA</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12} className="mb-2">
                <label className="onboarding__number__section__label">
                    Area code
                </label>
            </Grid>
            <Grid item xs={12} className="mb-2 onboarding__number__section__input">
                <input type="text" placeholder="Write an Area Code" value={areaCode} onChange={e => setAreaCode(e.target.value)} />
            </Grid>
            <Grid item xs={12} className="mb-4 mt-2">
                <Button variant="contained" color="primary" disabled={loading} onClick={searchPhoneNumber}>
                    {loading ? 'Searching...' : 'Search Phone Number'}
                </Button>
            </Grid>
        </Grid>
    )

    const renderResult = () => (
        <>
            <div className="container-fluid mail__inbox onboard_number_stage">
                <div className="tagBox">
                    <div className="tag_list d-flex justify-content-between onboarding__number__section__top__back">
                        <h6>Free Numbers List</h6>
                        <a href="#" className="modal-trigger onboarding__number__section__top__back__btn" onClick={returnToSearch}>Back</a>
                    </div>
                </div>
                <div className="details free_virtual_number">
                    <div className="items">
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        </div>
                        <div className="record_search onboarding__number__section__input">
                            <input type="text" placeholder="Search Number" className="search " onChange={(e) => setQuery(e.target.value)}/>
                            <div className="search_icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                </svg>
                            </div>							
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr className="header">
                                <th>Phone Number</th>
                                <th>Friendly Number</th>
                                <th>ISO Country</th>
                                <th>Postal Code</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className="tbody_content">
                            {renderTable()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12} className="onboarding__setp__btn__group">
                    <div className="onboarding__setp__btn__group__inner">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        size="small" 
                        className="mr-2" 
                        startIcon={<ArrowBackIcon />} 
                        onClick={() => props.goToStep(props.activeStep-1)}
                        disabled={props.loading}
                    >
                        Previous Step
                    </Button>
                    {
                        (props.data.hasVirtualNumber || 
                        (props.data.currentPackage && typeof props.data.currentPackage.plan_type !== 'undefined' && props.data.currentPackage.plan_type !== 'STANDARD'))
                        && (
                            <Button 
                                variant="contained" 
                                color="primary" 
                                size="small" 
                                endIcon={<ArrowForwardIcon />} 
                                onClick={() => props.goToStep(props.activeStep+1)}
                                disabled={props.loading}
                            >
                                Next Step
                            </Button>
                        )
                    }
                    </div>
                    <p className="onboarding__step__one__title_log_out" onClick={props.logout}>Log Out</p>
                </Grid>
                {
                    (props.data.currentPackage && typeof props.data.currentPackage.plan_type !== 'undefined' && props.data.currentPackage.plan_type !== 'STANDARD') ? (
                        <div className="mt-5 pb-4" style={{width:'100%'}}><Alert severity="success">Looks like you are on package where phone number is not needed. Simply click on <code>Next Step</code> to progress further.</Alert></div>
                    ) : (
                        props.data.hasVirtualNumber ? (
                           <div className="mt-5 pb-4" style={{width:'100%'}}><Alert severity="success">You have already bought your default phone number. Simply click on <code>Next Step</code> to progress further.</Alert> </div>
                        ) : (
                            <Fragment>
                                <Grid item xs={12}>
                                    <p className="onboarding__number__section__sub">**Please select at least 1 virtual number to use inside your account. Select your country, enter your preferred area code and click search.</p>
                                </Grid>
                                <Grid item xs={12}  className="mt-2">
                                    {
                                        !displayResult ? renderSearch() : renderResult()
                                    }
                                </Grid>
                            </Fragment>
                        )
                    )
                }
            </Grid>
        </Fragment>
    );
}

export default Number;