import { Skeleton } from "@material-ui/lab";
import React from "react";

export const InboxModuleSkeleton = () => {
  return (
    <div className="inbox-loader-parent d-flex gap-20">
      <div className="child-one">
        <div className="p-3 ">
          <Skeleton
            className="borderRadius50px"
            variant="rect"
            height={25}
          />
        </div>
        <div className="d-flex p-3 gap-5">
          <div style={{ width: "100%" }}>
            <Skeleton variant="rect" height={25} />
          </div>
          <div>
            <Skeleton variant="rect" width={50} height={25} />
          </div>
        </div>

        <div className="d-flex p-3 gap-5">
          <div style={{ width: "100%" }}>
            <Skeleton className="subitem" variant="rect" height={25} />
          </div>
          <div style={{ width: "100%" }}>
            <Skeleton className="subitem" variant="rect" height={25} />
          </div>
          <div style={{ width: "100%" }}>
            <Skeleton className="subitem" variant="rect" height={25} />
          </div>
          <div style={{ width: "100%" }}>
            <Skeleton className="subitem" variant="rect" height={25} />
          </div>
          <div style={{ width: "100%" }}>
            <Skeleton className="subitem" variant="rect" height={25} />
          </div>

        </div>
        <div className="d-flex p-3 mt-3">
            <div className="mt-3 borderRadius50-percent">
              <Skeleton
                variant="circle"
                width={50}
                height={50}
              />
            </div>
            <div className="pl-2 mt-3" style={{ width: "60%" }}>
              <Skeleton variant="rect" className="mb-2" height={25} />
              <Skeleton variant="rect" />
            </div>
           
          </div>
          <div className="d-flex p-3 mt-3">
            <div>
              <Skeleton
                className="borderRadius50-percent mt-3"
                variant="circle"
                width={50}
                height={50}
              />
            </div>
            <div className="pl-2 mt-3" style={{ width: "60%" }}>
              <Skeleton variant="rect" className="mb-2" height={25} />
              <Skeleton variant="rect" height={25} />
            </div>
           
          </div>
          <div className="d-flex p-3 mt-3">
            <div className="mt-3">
              <Skeleton
                variant="circle"
                width={50}
                height={50}
              />
            </div>
            <div className="pl-2 mt-3" style={{ width: "60%" }}>
              <Skeleton variant="rect" className="mb-2" height={25} />
              <Skeleton variant="rect" height={25} />
            </div>
            
          </div>
          <div className="d-flex p-3 mt-3">
            <div mt-3>
              <Skeleton
                className="borderRadius-50percent"
                variant="circle"
                width={50}
                height={50}
              />
            </div>
            <div className="pl-2 mt-3" style={{ width: "60%" }}>
              <Skeleton variant="rect" className="mb-2" height={25} />
              <Skeleton variant="rect" height={25} />
            </div>
            
          </div>
      </div>
      <div className="child-two d-flex" >
      <div style={{ width: "80px" }}>
            <Skeleton className="borderRadius50-percent" variant="rect" height={80}/>
        </div>
       <div style={{ width: "50%" }}>
            <Skeleton variant="rect" height={35} />
        </div>
        <div style={{ width: "60%" }}>
            <Skeleton variant="rect" height={25} />
        </div>
        <div style={{ width: "70%" }}>
            <Skeleton variant="rect" height={20} />
        </div>
      </div>
    </div>
  );
};
