import React, {useContext} from 'react'
import { ToasterContext } from './ToasterProvider';
import Notification from "./Notification";

const MultipleNotification = () => {
    const {state, dispatch} = useContext(ToasterContext);
    const multipleNotification = state.multiple
    let notificationView = [];
    multipleNotification.forEach(note => {
        notificationView.push(<Notification view="multiple" dispatch={dispatch} key={note.id} {...note} />)
    })
    return notificationView;
} 
export default MultipleNotification;