import React, { useEffect } from 'react'
import { Skeleton } from "@material-ui/lab";

const MarketingModule = () => {
  useEffect(() => {
    if (window.setCollapsedMenu) {
        window.setCollapsedMenu(true);
    }
  }, []);

  return (
    <div
      className="marketing-loader-parent d-flex gap-20"
    >
      <div className="marketing-loader-left" >
        <div className="mb-3" >
          <Skeleton variant="rect" height={40} style={{borderRadius:"4px"}} />
        </div>
        <div className="mb-1" style={{ width: "40%" }}>
          <Skeleton variant="rect" height={18} />
        </div>
        <div className="mb-3" >
          <Skeleton variant="rect" height={40} style={{borderRadius:"4px"}} />
        </div>

        {Array.from(Array(6)).map((_, i) => (
          <div className="d-flex" key={i} style={{height:"40px"}}>
              <Skeleton variant="rect"  height={25} width={25} />
            <div className="pl-4" style={{ width: "100%" }}>
              <Skeleton variant="rect"  height={25} />
            </div>
          </div>
        ))}
      </div>


      <div className="marketing-loader-right" >
        <div className="d-flex">
          <div
            className="marketing-loader-right-L d-flex"
            style={{ width: "100%" }}
          >
            <div className="pr-2 py-2">
              <Skeleton variant="rect" width={30} height={30} />
            </div>
            <div className="px-2 py-2">
              <Skeleton variant="rect" width={30} height={30} />
            </div>
            <div className="px-2 py-2">
              <Skeleton variant="rect" width={80} height={30} />
            </div>
          </div>

          <div className="marketing-loader-right-R">
            <div className="py-2">
              <Skeleton variant="rect" width={180} height={30} />
            </div>
          </div>
        </div>
        <div className="marketing-loader-child " >
          {Array.from(Array(4)).map((_, i) => (
            <div key={i} className="my-3 marketing-loader-child-card">
              <div className="mb-1" style={{ width: "400px" }}>
                <Skeleton variant="rect" height={22} />
              </div>
              <div className="d-flex mb-2">
                <div style={{ width: "70px" }}>
                  <Skeleton variant="rect" height={16} />
                </div>  
                <div className='px-3'>
                  <Skeleton variant="rect" height={16} width={2} />
                </div> 
                <div style={{ width: "20px" }} className='mr-2'>
                  <Skeleton variant="rect" height={16} />
                </div>  
                <div style={{ width: "50px" }}>
                  <Skeleton variant="rect" height={16} />
                </div>  
                
              </div>
              
              <div className="py-2" style={{ width: "100%" }}>
                <Skeleton variant="rect" height={2} />
              </div>
              <div
                className="d-flex justify-content-between "
                style={{ height: "100%" }}
              >
                <div className="d-flex gap-10 py-2">
                  {Array.from(Array(5)).map((_, i) => (
                    <div key={i} className="d-flex">
                      <div>
                        <Skeleton variant="circle" width={28} height={28} />
                      </div>
                      <div className="pl-2">
                        <div className='my-1'>
                          <Skeleton variant="rect" width={16} height={10} />
                        </div>
                        <div>
                          <Skeleton variant="rect" width={50} height={10} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="pt-2">
                  <Skeleton
                    variant="rect"
                    width={100}
                    height={25}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MarketingModule;