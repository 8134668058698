const DirectMailIcons ={
    postCardIcon:<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.67 21.32a2.75 2.75 0 0 1-1.94-.8L3.31 16.1a2.76 2.76 0 0 1 0-3.89L12.56 3a2.8 2.8 0 0 1 3.88 0l4.42 4.41a2.76 2.76 0 0 1 0 3.89l-9.24 9.25a2.77 2.77 0 0 1-1.95.77ZM14.5 3.66a1.22 1.22 0 0 0-.88.37l-9.25 9.24a1.27 1.27 0 0 0 0 1.77l4.42 4.42a1.27 1.27 0 0 0 1.77 0l9.24-9.25a1.25 1.25 0 0 0 0-1.77L15.38 4a1.22 1.22 0 0 0-.88-.34Z" fill="#000"/><path d="M14.5 12.57a1.34 1.34 0 0 1-1-.41l-1.6-1.59a1.401 1.401 0 0 1 0-2l1.69-1.69a1.4 1.4 0 0 1 2 0l1.59 1.6a1.379 1.379 0 0 1 0 2l-1.69 1.69a1.34 1.34 0 0 1-.99.4Zm-1.44-3L14.5 11l1.56-1.56-1.46-1.39-1.54 1.52Zm8.44 12.27h-19a.75.75 0 1 1 0-1.5h19a.75.75 0 1 1 0 1.5Zm-14-7a.75.75 0 0 1-.5-1.28l2-2a.74.74 0 0 1 1.06 0 .75.75 0 0 1 0 1.06l-2 2a.79.79 0 0 1-.56.22Zm2 2a.75.75 0 0 1-.5-1.28l2-2a.74.74 0 0 1 1.06 0 .75.75 0 0 1 0 1.06l-2 2a.79.79 0 0 1-.56.22Z" fill="#000"/></svg>,
    uploadIcon:<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 20h14v-2H5v2Zm0-10h4v6h6v-6h4l-7-7-7 7Z" fill="#3C7EF3"/></svg>,
    canvaIcon:<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.928 9.405c.366-.756.758-1.517.835-2.368.131-1.436-.835-2.835-2.387-2.646-4.871.595-6.618 6.789-6.033 10.941.163 1.155.552 2.316 1.256 3.256 1.304 1.741 3.774 1.928 5.641 1.005 1.564-.772 2.66-2.278 3.498-3.759.396-.7.675-.161.7.146.113 1.399-1.438 3.224-2.35 4.09-2.04 1.936-4.983 2.441-7.59 1.398-5.835-2.333-5.323-10.113-2.251-14.465.318-.45.669-.879 1.054-1.274 2.318-2.384 7.34-4.088 9.98-1.244 1.182 1.272 1.028 3.246.054 4.593-1.299 1.793-2.834 1.208-2.407.327Z" fill="#fff"/></svg>,
    backIcon:<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 12c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10-10 4.477-10 10Zm10.224-4.392a.769.769 0 0 1 .004 1.088l-2.515 2.535h6.422a.769.769 0 1 1 0 1.538h-6.423l2.516 2.535a.77.77 0 0 1-1.093 1.084l-3.817-3.846a.77.77 0 0 1 0-1.084l3.817-3.846a.77.77 0 0 1 1.089-.004Z" fill="#133159"/></svg>,
    saveContinueIcon:<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.5 12c0-5.52-4.48-10-10-10s-10 4.48-10 10 4.48 10 10 10 10-4.48 10-10Zm-10.22 4.39a.852.852 0 0 1-.17-.25.717.717 0 0 1 0-.58c.04-.09.09-.18.17-.25l2.52-2.54H8.37c-.2 0-.4-.08-.54-.23A.741.741 0 0 1 7.6 12c0-.2.08-.4.23-.54.14-.14.34-.23.54-.23h6.42L12.27 8.7a.803.803 0 0 1-.22-.55c0-.1.02-.2.06-.29.04-.09.1-.18.17-.25s.16-.13.25-.17a.717.717 0 0 1 .58 0c.09.04.18.1.25.17l3.82 3.85c.15.14.23.34.23.54s-.08.4-.22.54l-3.82 3.85c-.07.07-.16.13-.25.17a.717.717 0 0 1-.58 0 .692.692 0 0 1-.26-.17Z" fill="#fff"/></svg>,
    addCategoryIcon:<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.999 1.833A6.67 6.67 0 0 0 1.332 8.5a6.67 6.67 0 0 0 6.667 6.667A6.67 6.67 0 0 0 14.665 8.5 6.67 6.67 0 0 0 8 1.833Zm3.333 7.334H8.665v2.666H7.332V9.167H4.665V7.833h2.667V5.167h1.333v2.666h2.667v1.334Z" fill="#3C7EF3"/></svg>,
    checkedImg:<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".094" y=".968" width="60" height="60" rx="30" fill="#fff"/><path d="M30.11.968C13.541.968.094 14.415.094 30.984S13.54 61 30.11 61s30.016-13.447 30.016-30.016C60.126 14.414 46.68.968 30.11.968Zm-6.003 45.024L9.099 30.984l4.232-4.233 10.776 10.746 22.782-22.782 4.232 4.262-27.014 27.015Z" fill="#007D00"/></svg>,
    closeImg:<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="60" height="60" rx="30" transform="matrix(1 0 0 -1 0 60)" fill="#fff"/><path d="M30 60C13.43 60 0 46.57 0 30S13.43 0 30 0s30 13.43 30 30-13.43 30-30 30Zm8.7-36c.761-.738.208-1.408-.53-2.17-.762-.76-1.431-1.291-2.193-.53L30 27.277 24.023 21.3c-.761-.762-1.43-.23-2.17.53-.76.762-1.292 1.432-.553 2.17l5.977 6-5.977 5.977c-.739.738-.208 1.43.554 2.17.738.76 1.408 1.291 2.17.553l5.976-6 5.977 6c.762.738 1.43.208 2.192-.554.739-.738 1.293-1.43.531-2.17L32.723 30l5.977-6Z" fill="#FF264A"/></svg>,
    checkedIcon:<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm-2 15-5-5 1.41-1.41 3.59 3.58 7.59-7.59L19.5 8l-9 9Z" fill="#fff"/></svg>,
    arrowDownIcon:<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.175 7.158 10 10.975l3.825-3.817L15 8.333l-5 5-5-5 1.175-1.175Z" fill="#546376"/></svg>,
    arrowLeftIcon:<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 2.154 2.292 5.979 6 9.804l-1.142 1.175-4.858-5 4.858-5L6 2.154Z" fill="#546376"/></svg>,
    visibility: <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M10 13.229q1.542 0 2.604-1.073 1.063-1.073 1.063-2.594 0-1.541-1.073-2.604Q11.521 5.896 10 5.896T7.406 6.969Q6.333 8.042 6.333 9.562q0 1.542 1.073 2.605Q8.479 13.229 10 13.229Zm0-1.458q-.917 0-1.562-.646-.646-.646-.646-1.563 0-.916.646-1.562.645-.646 1.562-.646T11.562 8q.646.646.646 1.562 0 .917-.646 1.563-.645.646-1.562.646Zm0 3.896q-2.979 0-5.406-1.667-2.427-1.667-3.532-4.438 1.126-2.77 3.553-4.427Q7.042 3.479 10 3.479q2.979 0 5.406 1.656 2.427 1.657 3.532 4.427-1.105 2.75-3.521 4.428Q13 15.667 10 15.667Z" fill="#fff" /></svg>,
    invisibility: <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m16.292 18.583-3.354-3.375q-.709.23-1.438.344-.729.115-1.5.115-3.083 0-5.479-1.698T1.062 9.562Q1.479 8.5 2.146 7.583q.666-.916 1.479-1.645l-2.25-2.313L2.542 2.5l14.916 14.938ZM10 13.229q.208 0 .448-.031.24-.031.427-.073L6.458 8.729l-.083.417q-.042.208-.042.416 0 1.542 1.063 2.605Q8.458 13.229 10 13.229Zm5.958.333-2.562-2.541q.146-.292.208-.677.063-.386.063-.782 0-1.541-1.063-2.604Q11.542 5.896 10 5.896q-.354 0-.729.062-.375.063-.729.188l-2-2q.833-.334 1.698-.49Q9.104 3.5 10 3.5q3.062 0 5.469 1.677 2.406 1.677 3.469 4.385-.48 1.188-1.23 2.198-.75 1.011-1.75 1.802Zm-3.75-3.75L9.729 7.354q.563-.042 1.042.125.479.167.791.521.355.396.532.844.177.448.114.968Z" fill="#fff" /></svg>,
    
    




    
}

export default DirectMailIcons