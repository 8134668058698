import {useContext, useState, useEffect} from 'react'
import { ContactDetailContext } from '../../reducer/GlobalReduers'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { RenderEachCustomField } from './RenderEachCustomField';
import ApiList from '../../ApiList';
import moment from "moment";

const EachField = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [showInput, setShowInput] = useState(false)
    const [value, setValue] = useState('')
    const [saving, setSaving] = useState(false)
    let inputData = ''

    const renderValue = () => {
        let valueText = ''
        props.conactCustomFields.map((item) => {
            if(item['user_custom_fields'].personalize_tag == props.item.personalize_tag){
                valueText = item.value
            }
        })
        inputData=valueText
        return valueText;
    }

    const handleShowInput = () => {
        setShowInput(true)
        setValue(inputData)
    }

    const onChangeValue = (value) => {
        setValue(value)
    }

    const handleSubmit = () => {
        if(saving)return
        setSaving(true)

        let tempVal = value;
        if (props.item.type_id === 11){
            tempVal = moment(new Date(value)).format("YYYY-MM-D HH:mm:ss");
        }

        ApiList.updateCustomField({
            contact_id: detailStates.contactId,
            custom_field_id: props.item.id,
            value: tempVal
        }).then(res => {
            if(res.data.status === 'success'){
                props.updateFieldData();
                if(detailStates.from === 'deal-details'){
                    if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                        detailStates.dealCallback(true)
                        // props.reloadDealStage(true)
                    }
                }
            }
            setShowInput(false)
            setSaving(false)
        })
    }

    return(
        <div className="g-custom-field-each-content" key={props.key}>
            <>
                <div className="g-custom-field-topbar">
                    <span>{props.item.title}</span>
                    {!showInput &&
                    <button onClick={handleShowInput}>edit</button>
                    }
                </div>
                
                <div className="g-custom-field-wrapper">
                    
                    {!showInput &&
                    <>
                    <span>{renderValue()}</span>
                    </>
                    }
                    {showInput &&
                    <>
                    <div className="g-custom-field-icon" onClick={handleSubmit}><CheckIcon /></div>
                    <div className="g-custom-field-icon" onClick={() => setShowInput(false)}><ClearIcon /></div>
                    </>
                    }
                </div>

                {showInput && 
                    <div className="g-custom-field-edit-content">
                        <RenderEachCustomField
                            data={props.item}
                            index={props.index}
                            onChange={onChangeValue}
                            previousData={value}
                        />
                    </div>
                }
            </>
        </div>
    )
}
export default EachField;