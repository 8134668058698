import './css/global-sms-send-component.css'
import './css/sms-send-time-setting.css'
import { SmsSendProvider } from './reducers/GlobalReduers'
import SendModule from './pages/SendModule'

const GlobalSmsSendModule = (props) => {
    return(
        <SmsSendProvider>
            <SendModule 
                open={props.open}
                onClose={props.onClose}
                id={props.id}
                contactInfo={props.contactInfo}
                from={props.from}
                sendSuccessCallBack={props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null}
            />
        </SmsSendProvider>
    )
}
export default GlobalSmsSendModule;
/* 
id {contact id},
contactInfo{basic info: name, number}
open,
onClose,
sendSuccessCallBack,
from
*/