import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import createSagaMiddleware from 'redux-saga';
import rootMiddleware from "../middlewares/rootMiddleware";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

let persistConfig = [];
if (process.env.REACT_APP_REDUX_PERSIST_DATA === 'true') {
    persistConfig = {
        key: 'root',
        storage
    }
} else {
    persistConfig = {
        key: 'root',
        storage,
        whitelist: []
    }
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    storeEnhancers(applyMiddleware(sagaMiddleware))
);

const  persistor = persistStore(store);

sagaMiddleware.run(rootMiddleware);

export {store, persistor};
