import { Skeleton } from '@material-ui/lab'
import React from 'react'

export const ActivityCalendarModule = () => {
  return (
    <div className="activityCalendar-Parent m-3">
        <div className="activity-child-one d-flex  p-2 justify-content-between">
            {" "}
            {/*child 1 div*/}
            <div style={{width:'12%'}}>
            <Skeleton variant="text"  height={40} />
            </div>
            <div  style={{width:'12%'}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div  style={{width:'12%'}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div  style={{width:'12%'}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div  style={{width:'12%'}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div  style={{width:'12%'}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div  style={{width:'12%'}}>
            <Skeleton variant="rect"  height={40} />
            </div>
        </div>
       <div><Skeleton variant='rect' height={2}/></div>

        <div className="activity-child-two d-flex  p-2 justify-content-start">
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect" width={40} height={25} />
            </div>
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect"  width={40} height={25} />
            </div>
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect"  width={40} height={25} />
            </div>
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect"  width={40} height={25} />
            </div>
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect"  width={40} height={25} />
            </div>
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect"  width={40} height={25} />
            </div>
            <div style={{border:"1px solid #e5e8ef"}}>
            <Skeleton variant="rect"  width={40} height={25} />
            </div>
        </div>
        <div className="d-flex">
            <div className="activity-child-three d-flex  p-2 justify-content-start">
                <div>
                <Skeleton variant="rect" width={40} height={25} />
                </div>
                <div>
                <Skeleton variant="rect"  width={40} height={25} />
                </div>
                <div>
                <Skeleton variant="rect"  width={40} height={25} />
                </div>
                <div>
                <Skeleton variant="rect"  width={40} height={25} />
                </div>
            </div>
            <div className="activity-child-three d-flex  p-2 justify-content-end">
                <div>
                <Skeleton variant="rect" width={40} height={25} />
                </div>
                <div>
                <Skeleton variant="rect"  width={40} height={25} />
                </div>
                <div>
                <Skeleton variant="rect"  width={40} height={25} />
                </div>
                <div>
                <Skeleton variant="rect"  width={40} height={25} />
                </div>
            </div>
        </div>
       
       <div className='CalendarClass mt-3'>
            {Array.from(Array(7)).map((_, i) =>
                <div className='CalendarClass-child' key={i}><Skeleton variant='rect' height={40}/></div>
           )}
           
           {Array.from(Array(35)).map((_, i) =>
                <div className='CalendarClass-child' key={i}><Skeleton variant='rect' height={80}/></div>
           )}
       </div>
       
        
    </div>
  )
}
