import { Skeleton } from "@material-ui/lab";
import React from "react";
import icon from "./icon";

export const MapFinderModuleSkeleton = () => {
  return (
    <div className="map-finder-parent">
      <div className="map-finder-child-one d-flex justify-content-start m-2 p-2">
        <div style={{ width: "8%" }}>
          <Skeleton variant="text" height={30} />
        </div>

        <div className="ml-3" style={{ width: "25%" }}>
          <Skeleton
            variant="rect"
            height={30}
            style={{ borderRadius: "10px" }}
          />
        </div>
        <div style={{ width: "5%" }}>
          <Skeleton variant="rect" height={30} />
        </div>
      </div>
      <div className="map-finder-child-two d-flex justify-content-end gap-10 p-3">
        <div style={{ width: "2%" }}>
          <Skeleton variant="rect" height={20} />
        </div>
        <div style={{ width: "5%" }}>
          <Skeleton variant="rect" height={20} />
        </div>
        <div style={{ width: "2%" }}>
          <Skeleton variant="rect" height={20} />
        </div>
        <div style={{ width: "5%" }}>
          <Skeleton variant="rect" height={20} />
        </div>
        <div style={{ width: "2%" }}>
          <Skeleton variant="rect" height={20} />
        </div>
        <div style={{ width: "5%" }}>
          <Skeleton variant="rect" height={20} />
        </div>
      </div>
      <div className="d-flex justify-content-start p-2 gap-5">
        <div>
          <Skeleton variant="circle" width={20} height={20} />
        </div>
        <div style={{ width: "20%" }}>
          <Skeleton variant="text" height={20} />
        </div>
      </div>
      {/* parent div */}
      <div
        className="d-flex p-3 justify-content-between"
        style={{ background: "white" }}
      >
        {/* child div1 */}
        <div className="d-flex" style={{ width: "20%" }}>
          <div style={{ width: "5%" }}>
            <Skeleton variant="rect" height={20} />
          </div>
          <div style={{ width: "5%" }}>
            <Skeleton variant="rect" height={20} />
          </div>
          <div style={{ width: "5%" }}>
            <Skeleton variant="rect" height={20} />
          </div>
        </div>
        {/* child div2 */}
        <div
          className="d-flex justify-content-between"
          style={{ width: "30%" }}
        >
          <div className="d-flex" style={{ width: "100%",gap:"2px" }}>
            <div style={{ width: "5%" }}>
              <Skeleton variant="rect" height={20} />
            </div>
            <div style={{ width: "5%" }}>
              <Skeleton variant="rect" height={20} />
            </div>
            <div style={{ width: "5%" }}>
              <Skeleton variant="rect" height={20} />
            </div>
            <div style={{ width: "5%" }}>
              <Skeleton variant="rect" height={20} />
            </div>
            <div style={{ width: "5%" }}>
              <Skeleton variant="rect" height={20} />
            </div>
            <div style={{ width: "5%" }}>
              <Skeleton variant="rect" height={20} />
            </div>
            <div style={{width:"10%"}}>
              <Skeleton variant="rect" height={20}/>
            </div>
          </div>
        </div>
        <div style={{ width: "40px" }}>
          <Skeleton variant="rect" width={40} height={40} />
        </div>
      </div>
      <div className="svg-icon">{icon.google}</div>
    </div>
  );
};
