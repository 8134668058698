export const FETCH_CURRENT_CREDIT = "FETCH_CURRENT_CREDIT";
export const STORE_CURRENT_CREDIT = "STORE_CURRENT_CREDIT";
export const CLEAR_DIAL_NUMBER = "CLEAR_DIAL_NUMBER";
export const ADD_DIAL_NUMBER = "ADD_DIAL_NUMBER";
export const FETCH_VIRTUAL_NUMBER = "FETCH_VIRTUAL_NUMBER";
export const STORE_VIRTUAL_NUMBER = "STORE_VIRTUAL_NUMBER";
export const SELECT_FROM_NUMBER = "SELECT_FROM_NUMBER";
export const CHANGE_STAGE = "CHANGE_STAGE";
export const RECENT_CONTACT_LOADED = "RECENT_CONTACT_LOADED";
export const SEARCH_CONTACT = "SEARCH_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const PUT_CONTACT = "PUT_CONTACT";
export const SELECT_ACTIVE_CONTACT = "SELECT_ACTIVE_CONTACT";
export const REMOVE_ACTIVE_CONTACT = "REMOVE_ACTIVE_CONTACT";
export const RECENT_CONTACT_LOADING = "RECENT_CONTACT_LOADING";
export const UPDATE_CALL_IN_PROGRESS_STATUS = "UPDATE_CALL_IN_PROGRESS_STATUS";
export const UPDATE_CALL_STATUS = "UPDATE_CALL_STATUS";
export const RESET_DIALER = "RESET_DIALER";
export const RESET_WITH_CONTACT_NUMBER = "RESET_WITH_CONTACT_NUMBER";
export const UPDATE_CALL_TIME = "UPDATE_CALL_TIME";
export const UPDATE_CALL_INFO = "UPDATE_CALL_INFO";
export const SET_INITIAL_CONTACT = "SET_INITIAL_CONTACT";
export const STORE_MULTIPLE_REDUCERS = "STORE_MULTIPLE_REDUCERS";

export const ENABLE_WARM_TRANSFER = "ENABLE_WARM_TRANSFER";
export const TOGGLE_BETWEEN_SCREEN = "TOGGLE_BETWEEN_SCREEN";
export const CHANGE_SECOND_CALL_STAGE = "CHANGE_SECOND_CALL_STAGE";
export const CLEAR_SECOND_DIAL_NUMBER = "CLEAR_SECOND_DIAL_NUMBER";
export const ADD_SECOND_DIAL_NUMBER = "ADD_SECOND_DIAL_NUMBER";
export const UPDATE_SECOND_CALL_IN_PROGRESS_STATUS = "UPDATE_SECOND_CALL_IN_PROGRESS_STATUS";
export const UPDATE_IS_CALL_RECEIVED = "UPDATE_IS_CALL_RECEIVED";
export const UPDATE_SECOND_CALL_STATUS = "UPDATE_SECOND_CALL_STATUS";
export const DESTROY_SECOND_CALL = "DESTROY_SECOND_CALL";
export const HOLD_FIRST_CALL = "HOLD_FIRST_CALL";
export const UNHOLD_ALL = "UNHOLD_ALL";
