const CoreConstants = {
    VIDEO_STATUS_WELCOME: 0,
    VIDEO_STATUS_WEBCAM: 1,
    VIDEO_STATUS_WARNING: 2,
    VIDEO_STATUS_PREVIEW: 3,

    MESSAGE_TEMPLATE: {
        EMAIL: 1,
        SMS: 2,
        VOICE_MAIL: 3,
        VIDEO_EMAIL: 4,
        MMS: 5,
        POST_CARD: 6,
        GREETING_CARD: 7,
        GIFT_CARD: 8,
        LETTER: 9,
    },

    MESSAGE_TYPE: {
        SMS: 1,
        MMS: 2,
        VOICE: 3,
        EMAIL: 4,
        CALL: 5,
        ACTIVITY: 6,
        OUTGOING_CALL: 7,
        WEBHOOK: 9,
        STAGE: 10,
        APPOINTMENT: 12,
        CALL_BRIDGE: 13,
        VIDEO_EMAIL: 14,
        CHAT: 15,
        NOTE: 16,
        GENERAL: 20,
        POST_CARD: 21,
        GREETINGS_CARD: 22,
        GIFT: 23,
        LETTER: 24,
        CALL_RECORD: 25,
    },
    SENDING_TYPE: {
        IMMEDIATE: 1,
        SCHEDULE: 2,
        Fixed: 3,
        GREETINGS: 4,
        RECURRING: 5,
    },




}

export default CoreConstants;