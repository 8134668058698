import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { icons } from "../../icons.js";
import { RightSideStyle } from "../RightSideStyle.js";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion.js";
import DragableSection from "../DragableSection.js";

const ContactInfo = () => {
  const [expand, setExpand] = useState(false);

  const { customScrollBar } = RightSideStyle();
  return (
    <Box>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`g-collapsible-header ${expand ? "expand" : ""}`}
        >
          <span class="g-header-title">
            <AccountCircleRoundedIcon
              style={{ marginRight: "8px!important" }}
            />{" "}
            ContactInfo
          </span>
          <span className="g-custom-caret">{icons.caretIcon}</span>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          <Box className={customScrollBar}>
            <DragableSection />
          </Box>
        </CustomAccordionBody>
      </CustomAccordion>
    </Box>
  );
};

export default ContactInfo;
