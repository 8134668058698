import {useContext, useRef,useEffect,useState} from 'react';
import Personalized from '../components/personalized/Personalized';
import { updateMessage, updateMmsFile, updateQuickReplyTitle, updateSaveAsTemplateFlag, updateShowQuickReply, updateUnsubTextFlag } from '../reducers/Action';
import { SmsSendContext } from '../reducers/GlobalReduers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ImageIcon from '@material-ui/icons/Image';
import Utils from '../../../addContact/Utils';
import SwitchC from '../components/Switch/Switch';
import LinkFile from '../LinkFile';
import HelpIcon from '@material-ui/icons/Help';
import GlobalCustomFieldGrouped from "../../../GlobalCustomFieldGrouped/Index";

const Message = () => {
    const {states, dispatch} = useContext(SmsSendContext)
    const messageInputRef = useRef(null)
    const [smsCount,setSmsCount] = useState({
        smslength:0,
        sms:0
    });
    /* for adding unsubscribed message */
    const mmsInputField = useRef(null)

    const handleMessagePersonalize = (value) => {
        let start = messageInputRef.current.selectionStart;
        let end = messageInputRef.current.selectionEnd;
        let text = messageInputRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        dispatch(updateMessage(newText))
        setTimeout(() => {
            messageInputRef.current.selectionStart = messageInputRef.current.selectionEnd = start + value.length;
            messageInputRef.current.focus();
        }, 100);
    }

    const handleTemplateView = () => {
        dispatch(updateShowQuickReply(true))
    }

    const handleMessageChange = (value) => {
        dispatch(updateMessage(value));
        let countResult = Utils.countSms(value);
        setSmsCount({
            ...smsCount,
            smslength:countResult.length,
            sms:countResult.sms
        })
    }

    const handleSaveAs = () => {
        dispatch(updateSaveAsTemplateFlag(!states.saveAsTemplate))
        if(!states.saveAsTemplate){
            dispatch(updateQuickReplyTitle(""))
        }
    }

    const handleQucikReplyTitleChange = (event) => {
        dispatch(updateQuickReplyTitle(event.target.value))
    }

    const handleRemoveMmsFile = () => {

    }

    const selectImage = () => {
        // let inputField = document.getElementById('mms-file-g-sms');
        // inputField.click();
        mmsInputField.current.click();
    }

    const changeMmsAttachments = (e) => {
        let file = document.querySelector('#mms-file-g-sms').files[0];
        // let file =  mmsInputField.current.files[0];
        let preview = document.getElementById("g-sms-module-image-upload");
        if (e.target.value) {
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                preview.src = reader.result;
                document.getElementById('preview-holder-sms-module').classList.remove("d-none");
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
            dispatch(updateMmsFile({
                file_url: '',
                file_data: file,
                selectedMmsFromTemplate: true
            }));
            dispatch(updateMessage({
                message_type: 5
            }));
        }

    }

    const removeAttachments = () => {
        let mmsFile=document.getElementById("mms-file-g-sms");
        let imageFile = document.getElementById("g-sms-module-image-upload");
        let mmsSelector = document.getElementById('preview-holder-sms-module');
        if(mmsFile){
            mmsFile.value = "";
        }
        if(imageFile){
            imageFile.src='';
        }
        if(mmsSelector){
            mmsSelector.classList.add("d-none");
        }
        dispatch(updateMmsFile({
            file_url: '',
            file_data: null,
            selectedMmsFromTemplate: false
        }));
        dispatch(updateMessage({
            message_type: 2
        }));
    }

    const renderUnsubTooltip = () => {
        let whole_message = "";
        if(states.message.length > 0){
            whole_message = states.message + "\r\nReply STOP to unsubscribe.";
        }
        else{
            whole_message = "Reply STOP to unsubscribe.";
        }
        if(!states.appendUnsubText){
            whole_message = states.message;
        }
        let countResult = Utils.countSms(whole_message);
        return(
            <div>
                {states.appendUnsubText &&
                <div>
                    <p style={{padding: 0, margin:0, underLine: '1px solid', fontSize:'12px'}}><b>Receiver Text: </b></p>
                    {states.message.length > 0 ?
                        <i>{states.message}<br/>Reply STOP to unsubscribe.</i>
                    :
                        <i>Reply STOP to unsubscribe.</i>
                    }
                </div>
                }
                {!states.appendUnsubText &&
                <div>
                    <p style={{padding: 0, margin:0, underLine: '1px solid', fontSize:'12px'}}><b>Receiver Text: </b></p>
                    <i>{states.message}</i>
                </div>
                }
                <br/>
                <div><span>Total SMS: {countResult.length}/{countResult.sms}</span></div>
                <span style={{color: 'white'}}>If turned on, “Reply STOP to unsubscribe” will appear at the end of all your text messages.</span>
            </div>
        );
    }

    return(
        <div className="g__sms__send__message__content">
            <div className="g__message">
                <div className="g__sms__send__textarea__alt">
                    <div className="g__sms__send__textarea__alt__row">
                        <div className="g__sms__send__options">
                            <GlobalCustomFieldGrouped labelText={"Personalized"} className="sms_module__global_custom__field" handleSelect={handleMessagePersonalize}/>
                            <div className="g__template__button" onClick={handleTemplateView}>
                                <span><svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"></path></svg></span>
                                <span>Use Quick Reply</span>
                                </div>
                            <div className="g__attachment__button" onClick={selectImage}>
                                <ImageIcon/>
                                <span>Attachment</span>
                                <span className="g__sms__only__image">(Only Image Upload)</span>
                            </div>
				        </div>
                    </div>
                    <div className="g__sms__text__area__divided">

                        <div className={`g__sms__text__area__divided__left ${((states.message_type == 5 && states.file_url !== '') || states.file_data !== null) ? 'g__sms__text__area__active' : ''}`}>
                            <textarea 
                                className="g__sms__message awesome__scroll_bar"
                                value={states.message}
                                placeholder="Text message"
                                onChange={(event) => handleMessageChange(event.target.value)}
                                ref={messageInputRef}
                            />
                        </div>

                        <div className={`g__sms__text__area__divided__right ${((states.message_type == 5 && states.file_url !== '') || states.file_data !== null) ? '' : 'd-none'}`}>
                            {(states.message_type == 5 && states.file_url !== '') &&
                                <div className="g__sms__message__image">
                                    <img src={states.file_url} />
                                </div>
                            }

                            <div className="preview__holder__sms__module d-none" id="preview-holder-sms-module">
                                <img src="" alt="" className="g__sms__module__image__upload" id="g-sms-module-image-upload"/>
                                <div className="g__sms__img__close" onClick={removeAttachments}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z" fill="white"></path><path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z" fill="white"></path></svg>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='modal__text__couter__wrapper__v2'>
                <div className="g__save__as__content">
                    <div className='mtcwv__unsub_wraper'>
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={<Checkbox checked={states.saveAsTemplate} onChange={handleSaveAs} name="save-as-template" />}
                                label="Save as quick reply"
                            />
                        </FormControl>
                        {/* for adding unsubscribed message */}
                        <div className="add__unsubscried__text">
                            <span><small><i>Reply STOP to unsubscribe</i></small></span>
                            <SwitchC 
                                status={states.appendUnsubText} 
                                brandColor={"#27AE60"} 
                                onChange={(value) => dispatch(updateUnsubTextFlag(value))}
                                onText={''}
                                offText={''}
                                minWidth40={true}
                            />
                            <LinkFile.BootstrapTooltip arrow placement={'top'} title={renderUnsubTooltip()}>
                                <HelpIcon fontSize="small" />
                            </LinkFile.BootstrapTooltip>
                        </div>
                    </div>
                    {/* <div className="g__sms__send__options">
                        <Personalized options={states.personalized} onChange={handleMessagePersonalize} />
                        <div className="g-template-button" onClick={handleTemplateView}>Use Quick Reply</div>
                    </div> */}
                    </div>
                    <p>Total SMS: {smsCount.smslength}/{smsCount.sms}</p>
            </div>

            {states.saveAsTemplate &&
            <div className="g__save__as__template__title">
                <input placeholder="Enter quick reply title" value={states.templateTitle} onChange={handleQucikReplyTitleChange} />
            </div>
            }

            {/* <div className="" onClick={selectImage}>
                <svg className="" xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 20">
                    <path fill="#133159" d="M13.833 13H2.167A1.162 1.162 0 0 1 1 11.842V3.158C1 2.518 1.522 2 2.167 2h11.666C14.478 2 15 2.518 15 3.158v8.684c0 .64-.522 1.158-1.167 1.158zM2.4 3a.4.4 0 0 0-.4.4v8.2c0 .22.18.4.4.4h11.2a.4.4 0 0 0 .4-.4V3.4a.4.4 0 0 0-.4-.4H2.4zm7.625 2.005a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm4.49 6.495a.503.503 0 0 1-.311-.106l-3.305-2.601-1.31 1.03.41.365a.43.43 0 0 1 .014.64.51.51 0 0 1-.684.014L5.68 7.594l-3.845 3.765a.509.509 0 0 1-.686.015.432.432 0 0 1-.016-.638l4.18-4.093a.497.497 0 0 1 .34-.141.445.445 0 0 1 .345.124l2.886 2.57 1.703-1.34a.51.51 0 0 1 .624 0l3.617 2.847c.204.16.23.446.058.636a.5.5 0 0 1-.37.161z"></path>
                </svg>
                <span>Attachment</span>
                <span className="">Only Image Upload</span>
            </div> */}
            <input onChange={(e) => changeMmsAttachments(e)} type="file" accept="image/png, image/gif, image/jpeg" className='d-none' ref={mmsInputField} id='mms-file-g-sms'/>
        
        </div>
    )
}
export default Message;