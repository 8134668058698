const LetterImagePreview = ({
    image_url,
    selectedTemplate
}) => {
    const renderImage = () => {
        if(image_url == null){
            return selectedTemplate.image_path
        }
        return image_url
    }
    return(
        <div className='dmpc_3rd_tv_right_inner_img' style={{background:`url('${renderImage()}')`}}>
            <div class="letter-address-block">
               <div> Address block</div>
                Keep clear
            </div>
            <div className="dmpc__letter__folding__hints"></div>
        </div>
    );
}
export default LetterImagePreview;