// import { sendRequest } from "../../../../api/rootApi";
import Api from "./Api";
import axios from "axios";

// export const fetchCampaignList = async (payload) => {
//     return sendRequest("POST", Api.campaigns, payload);
// }


// export const addSingleContact = async (payload) => {
//     return sendRequest("POST", Api.addSingleContactToCampaign, payload);
// }

// export const fetchCampaignDetails = async (payload) => {
//     return sendRequest("post", Api.campaignDetails, payload);
// }

// export const fetchCampaignTimelineApi = async (payload) => {
//     return sendRequest("post", Api.campaignTimeline, payload);
// }

// export const fetchCustomField = async () => {
//     return sendRequest("post", Api.customFields);
// }

// export const updateCampaignDetails = async (payload) => {
//     return sendRequest("post", Api.updateCampaignDetails, payload);
// }

// export const fetchCampaignLeadSourceDetails = async (payload) => {
//     return sendRequest("post", Api.campaignLeadSourceDetails, payload);
// }

// export const fetchUserInfo = async (payload) => {
//     return sendRequest("post", Api.getUserData, payload);
// }

// export const fetchLeadGroups = async (payload) => {
//     return sendRequest("post", Api.fetchLeadGroups, payload);
// }

// export const updateLeadGroups = async (payload) => {
//     return sendRequest("post", Api.updateLeadGroups, payload);
// }

// export const fetchLeadFlows = async (payload) => {
//     return sendRequest("post", Api.fetchLeadFlows, payload);
// }

// export const updateLeadFlows = async (payload) => {
//     return sendRequest("post", Api.updateLeadFlows, payload);
// }

// // share campaign
// export const shareCampaignItem = async (payload) => {
//     return sendRequest("post", Api.shareCampaign, payload);
// }

// // delete campaign
// export const deleteCampaignItem = async (payload) => {
//     return sendRequest("post", Api.deleteCampaign, payload);
// }

// // fetch pre recording list
// export const fetchPreRecordingList = async (payload) => {
//     return sendRequest("post", Api.getPreRecording, payload);
// }

// export const addCampaignStepSettingList = async (payload) => {
//     return sendRequest("post", Api.storeCampaignStepSetting, payload);
// }

// export const addCampaignStepSettingPreTempList = async (payload) => {
//     return sendRequest("post", Api.storeCampaignStepSettingPreTemp, payload);
// }

// export const updateCampaignSettingApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignSetting, payload);
// }

// export const updateCampaignActivityApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignActivitySetting, payload);
// }

// export const updateCampaignSettingWithMultimediaApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignStepSettingWithMultimedia, payload);
// }

// export const updateCampaignSettingWithPreviousFileApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignStepSettingWithPreviousFile, payload);
// }

// export const updateCampaignSettingWithPreTempApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignStepSettingWithPreTemp, payload);
// }

// export const fetchStages = async (payload) => {
//     return sendRequest("post", Api.fetchStages, payload);
// }

// export const fetchTags = async (payload) => {
//     return sendRequest("post", Api.fetchTags, payload);
// }

// export const fetchTeamMembers = async (payload) => {
//     return sendRequest("post", Api.fetchTeamMembers, payload);
// }

// export const fetchCampaignsExceptCurrent = async (payload) => {
//     return sendRequest("post", Api.fetchCampaignsExceptCurrent, payload);
// }

export const saveFollowUp = async (payload) => {
    return sendRequest("post", Api.saveFollowup, payload);
}

// export const saveActionFollowup = async (payload) => {
//     return sendRequest("post", Api.saveActionFollowup, payload);
// }

// export const saveActivityFollowup = async (payload) => {
//     return sendRequest("post", Api.saveActivityFollowup, payload);
// }

// export const fetchCampaignContactStat = async (payload) => {
//     return sendRequest("post", Api.fetchCampaignContactStat, payload);
// }

// export const fetchVideoFolders = async (payload) => {
//     return sendRequest("post", Api.fetchVideoFolders, payload);
// }

// export const fetchVideos = async (payload) => {
//     return sendRequest("post", Api.fetchVideos, payload);
// }

// export const deleteVideo = async (payload) => {
//     return sendRequest("post", Api.deleteVideo, payload);
// }

// export const getMessageTemplate = async (payload) => {
//     return sendRequest("post", Api.getMessageTemplate, payload);
// }

// export const deleteMessageTemplate = async (payload) => {
//     return sendRequest("post", Api.messageTemplateDelete, payload);
// }

// export const getSingleMessageTemplate = async (payload) => {
//     return sendRequest("post", Api.getSingleMessageTemplate, payload);
// }

// export const updateMessageTemplate = async (payload) => {
//     return sendRequest("post", Api.updateMessageTemplate, payload);
// }

// export const addToBroadcast = async (payload) => {
//     return sendRequest("post", Api.addToBroadcast, payload);
// }


// export const updateSmsStepSettingApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignSmsStepSetting, payload);
// }

export const updateEmailStepSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignEmailStepSetting, payload);
}

// export const updateVideoEmailStepSettingApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignVideoEmailStepSetting, payload);
// }

// export const updateCallBridgeStepSettingApi = async (payload) => {
//     return sendRequest("post", Api.updateCampaignCallBridgeStepSetting, payload);
// }

// export const deleteCampaignSettingApi = async (payload) => {
//     return sendRequest("post", Api.deleteCampaignetting, payload);
// }

export const producePDF = async (payload)=>{
    return sendRequest("post", Api.producePDF, payload);
}

export const getThirdPartyService = async (payload)=>{
    return sendRequest("post", Api.getThirdPartyInfo, payload);
}
export const getMailCategory = async (payload)=>{
    return sendRequest("post", Api.getMailCategory, payload);
}

export const getMailTemplate = async (payload)=>{
    return sendRequest("post", Api.getMailTemplate, payload);
}

export const createMailTemplate = async (payload)=>{
    return sendRequest("post", Api.createMailTemplate, payload);
}

export const getCustomFields = async (payload) => {
    return sendRequest("post", Api.getCustomFields, payload);
}
export const addCardBroadcastToContact = async (payload) =>{
    return sendRequest("post", Api.addCardBroadcastToContact, payload);
}

// export const fetchLeadSourceApi = async (payload) => {
//     return sendRequest("post", Api.fetchLeadSource, payload);
// }

// export const fetchContactForDataTableApi = async (payload) => {
//     return sendRequest("post", Api.fetchContactListForDataTable, payload);
// }

// export const moveContactToCampaignApi = async (payload) => {
//     return sendRequest("post", Api.contactMoveToCampaign, payload);
// }

// export const copyCampaignApi = async (payload) => {
//     return sendRequest("post", Api.copyCampaign, payload);
// }

// export const campaignEnableToStartApi = async (payload) => {
//     return sendRequest("post", Api.campaignEnableToStart, payload);
// }

// export const getCampaignBasicInfoApi = async (payload) => {
//     return sendRequest("post", Api.getCampaignBasicInfo, payload);
// }

// export const campaignCheckToStartApi = async (payload) => {
//     return sendRequest("post", Api.campaignCheckToStart, payload);
// }

// export const campaignStartApi = async (payload) => {
//     return sendRequest("post", Api.campaignStart, payload);
// }

// export const campaignPauseApi = async (payload) => {
//     return sendRequest("post", Api.campaignPause, payload);
// }

// export const addQuickReplyTemplateApi = async (payload) => {
//     return sendRequest("post", Api.addQuickReplyTemplate, payload);
// }

// export const createNewCampaignApi = async (payload) => {
//     return sendRequest("post", Api.createNewCampaign, payload);
// }


// export const getUserEmailList = async (payload) => {
//     return sendRequest("post", Api.userEmailList, payload);
// }

// export const getUserNumberList = async (payload) => {
//     return sendRequest("post", Api.userNumberList, payload);
// }

// export const updateOrCreateLeadGroup = async (payload) => {
//     return sendRequest("post", Api.updateOrCreateLeadGroup, payload);
// }

// export const getTeamUsers = async (payload) => {
//     return sendRequest("post", Api.getTeamUsers, payload);
// }

// export const getSmsTemplateApi = async (payload) => {
//     return sendRequest("post", Api.getSmsTemplate, payload);
// }
// export const createLeadFlow = async (payload) => {
//     return sendRequest("POST", Api.createLeadFlow, payload);
// }
export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    try{
        payload.append('api-key', getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}

const setCookie = (cname, cvalue, exTime) => {
    var d = new Date();
    d.setTime(d.getTime() + timeToMiliSecond(exTime));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + btoa(cvalue) + ";" + expires + ";path=/";
}

export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

const timeToMiliSecond = time => {
    const number = time.substring(0, time.indexOf(' '));
    switch (time.substr(time.indexOf(' ')+1)) {
        case 'day':
            return number * 24 * 60 * 60 * 1000;
        case 'hour':
            return number * 60 * 60 * 1000;
        case 'minute':
            return number * 60 * 1000;
        case 'second':
            return number * 1000;
        default:
            return number * 60 * 1000;
    }
}

export const getDirectMailPrice = async (payload) => {
    return sendRequest("post", Api.getDirectMailPrice, payload);
}




