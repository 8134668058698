import React, { Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const style = {
    zIndex: "10000000"
};

class CanvaStartModal extends Component {

    constructor(props) {
        super(props);
    }

    closeModal = () => {
        this.props.closeModal();
    }

    callback = (e) => {
        e.preventDefault();
        this.props.callback();
    };

    getAlertImage = () => {
        if(this.props.setting_type === 'greeting') {
            return <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/canva-greeting.png`} alt='canva' className={'w-100'}/>
        } else if (this.props.setting_type === 'Letter') {
            return <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/canva-letter-alert.png`} alt='canva' className={'w-100'}/>
        } else {
            return <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/canva-alert.png`} alt='canva' className={'w-100'}/>
        }
    }

    render() {
        return (
            <Modal autoFocus={true} backdrop={true} zIndex={"100000000"}
                   isOpen={this.props.isOpen} fade={false}
                   style={style} className="allActivityModal modal-md canva-alert-modal" toggle={this.closeModal}>

                <ModalHeader toggle={this.closeModal}>
                    <i className={'flaticon-add'}/>
                    { this.props.title}
                </ModalHeader>

                <ModalBody>
                    <div className="m-portlet__body mt-4">
                        <div className="m-form__section m-form__section--first text-left">
                            <p>
                                <strong>Please
                                Note:</strong><br/>
                                We use Canva for our designer, you will need to create an account if you don't already
                                have one. Please
                                note if you use any of their stock photography there is a cost of $1.
                            </p>
                            <p>
                                <em><strong>Important: </strong></em>
                                Only design the front of card, not the back.<br/>
                            </p>
                            <div className={'text-center'}>
                                {this.getAlertImage()}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 text-center">
                        <button type="button" className={'btn btn-primary mr-2 accent--bg--color'} color={'primary'} onClick={e => this.callback(e)}>
                            OK
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

export default CanvaStartModal;
