// import './modal.css'
import { Slide } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from "react";
import icons from "../assets/icons";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ModalGlobalModal (props)  {
    const classes = useStyles();
    const [show, setShow] = useState(props.open)

    const closeModal=()=>{
        setShow(false);
        setTimeout(()=>{
            props.onClose(false)
        },500)
    }


    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={`${classes.modal} ${props.className}`}
                open={show}
                onClose={props.setOpenSMSModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
                onBackdropClick ={closeModal}

            >
                <Slide in={show} direction="left">
                    <div className="globalModalWrapper cardBordcastTab" style={{minWidth : props.width != undefined ? props.width : 700, width : props.width != undefined ? props.width : 700}}>
                        <div id="globalModal">
                            <div className="globalModalTitle d-flex justify-content-between align-items-center">
                                <div className="sub_title">
                                    {
                                        typeof props.title === 'string' ? 
                                        <h2>{props.title}</h2> : 
                                        <>{props.title}</>
                                    }
                                    
                                </div>
                                {typeof props.extraButton != 'undefined' &&
                                <div className="extra-button" onClick={props.onClickExtraButton}>
                                    {props.extraButton}
                                </div>
                                }
                                <div onClick={closeModal} className="share_campaign_cross_button d-flex justify-content-center align-items-center modal-close">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                            fill="white"/>
                                        <path
                                            d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                            fill="white"/>
                                    </svg>
                                </div>
                            </div>

                            {/* Modal Body */}

                            <div className="modalBody">
                              {props.children}
                            </div>
                            
                            {/*Send Massage and close button*/}
                            {
                                props.hideFooter !== true && 
                                <div className="massage_send_close_button d-flex justify-content-center align-items-center">
                                    <a href="javascript:void(0)" className="send_massage" onClick={props.handleSubmit}>
                                        <span>
                                        {props.buttonIcon}
                                        </span> {props.buttonText}
                                    </a>
                                    <a className="close_btn" href="javascript:void(0)" onClick={closeModal}>
                                        <span>
                                        {icons.crossIconModal}
                                        </span> Close
                                    </a>
                                </div>
                            }
                            
                        </div>
                    </div>
                </Slide>
            </Modal>
        </>
    );
}