import React, { useEffect } from 'react'

const CustomPage = () => {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const showUrl = params.get('url');
  const title = params.get('title');

  useEffect(() => {
    if(window.setActiveSidebar) {
        window.setActiveSidebar('');
    }
  }, []) 

  return (
    <iframe width={'100%'} height={'100%'} title={title}
      message="Can not load content. Please contact with system administrator"
      src={showUrl}></iframe>
  )
}

export default CustomPage;