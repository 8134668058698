import * as ACTION from "./contactActionTypes";
// import { SET_SHOW_NOTE_MODAL } from "../constants/contactActionTypes";

const initialState = {
    contact: null,
    showNoteModal: false,
    showTaskModal: false,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    messageTemplates: null,
    allContactList: [],
    userInfo: [],
    allActivityList : [],
    subUserList: null,
    userTagList:[],
    sourceList: null,
    userCustomFields: null,
    pipelineList: null,
    campaignList: null,
    tags : null,
    collaborators: []
};

const rightsideReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_CONTACT_DETAILS:
            return { ...state, contact: action.payload }
        case ACTION.SET_SHOW_NOTE_MODAL:
            return { ...state, showNoteModal: action.payload }
        case ACTION.SET_SHOW_TASK_MODAL:
            return { ...state, showTaskModal: action.payload }
        case ACTION.UPDATE_CONTACT_COLUMN:
            return {
                ...state, contact: {
                    ...state.contact,
                    ...action.payload
                }
            }
        /* Video Email Start */
        case ACTION.STORE_VIDEOS:
            return { ...state, ...action.payload }

        case ACTION.STORE_VIDEO_FOLDERS:
            return { ...state, videoFolders: action.payload, videoLoader: false }

        case ACTION.UPDATE_FOLDER_ID:
            return { ...state, folderId: action.payload }
        /* Video Email End */

        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return { ...state, messageTemplates: action.payload }
        /* Message Template End */

        /*Get All Contacts */
        case ACTION.SET_ALL_CONTACTS:
            return { ...state, allContactList: action.payload }
        /* GET All Contact End */

        /*Set User Info */
        case ACTION.SET_USER_INFO:
            return { ...state, userInfo: action.payload }

        
        case ACTION.SET_ALL_TASKS:
            return { ...state, allActivityList: action.payload }
        case ACTION.SET_SUBUSER_LIST:
            return { ...state, subUserList: action.payload }

        case ACTION.SET_USERTAG_LIST:
            return { ...state, userTagList: action.payload }

        case ACTION.SET_USERSOURCE_LIST_RIGHTSIDE:
            return { ...state, sourceList: action.payload }    

        case ACTION.SET_USERCUSTOMFIELD_LIST:
            return { ...state, userCustomFields: action.payload }  

        case ACTION.SET_PIPELINE_LIST:
            return { ...state, pipelineList: action.payload }
        case ACTION.SET_CAMPAIGN_LIST:
            return { ...state, campaignList: action.payload }     
        case ACTION.SET_CONTACT_TAG:
            return {...state,tags:action.payload}        
        case ACTION.SET_CONTACT_DETAILS_ACTIVITY_LIST:
            return {...state,allActivityList:action.payload}
        case ACTION.UPDATE_FIELD_DATA:
            return {...state, ...action.payload}
        default:
            return state;
    }
}


export default rightsideReducer;
