import React from 'react'

const closeIcon = (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 2.5C8.09615 2.5 2.5 8.09615 2.5 15C2.5 21.9038 8.09615 27.5 15 27.5C21.9038 27.5 27.5 21.9038 27.5 15C27.5 8.09615 21.9038 2.5 15 2.5ZM18.625 17.5C18.9423 17.8077 18.7115 18.0865 18.4038 18.4038C18.0865 18.7212 17.8077 18.9423 17.4904 18.625L15 16.1346L12.5096 18.625C12.1923 18.9423 11.9135 18.7212 11.6058 18.4038C11.2885 18.0865 11.0673 17.8077 11.375 17.5L13.8654 15L11.375 12.5096C11.0673 12.2019 11.2885 11.9135 11.6058 11.6058C11.9135 11.2885 12.1923 11.0673 12.5096 11.375L15 13.875L17.4904 11.375C17.8077 11.0673 18.0865 11.2885 18.4038 11.6058C18.7115 11.9135 18.9423 12.2019 18.625 12.5096L16.1346 15L18.625 17.5Z" fill="#FF264A"/>
</svg>
)
const DirectMailCardHeader = ({title, price, onClose, from}) => {
    return(
        <div className='dmch__wr'>
            <div className='dmch__ls'>
                <h4 className='dmch__ls__title'>Send {title}</h4>
                <span className='dmch__ls__price' id="pype__direct_mail__v2__price__span">Price: ${price === '---' ? price : parseFloat(price).toFixed(2)}</span>
            </div>
            {from != null &&
            <div className='dmch__rs'>
                <span onClick={(e) => onClose(e)}>{closeIcon}</span>
            </div>
            }
        </div>
    )
}
export default DirectMailCardHeader