import React, {useState, useEffect} from "react";
import {updateCallInProgressStatus, updateCallTime, updateCallInfo,selectFromNumber} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";

const DialTrigger = props => {
    // const [stage, setStage] = useState(props.stage);
    const [activeContact, setActiveContact] = useState(props.activeContact);
    const [dialNumber, setDialNumber] = useState(props.dialNumber);

    // useEffect(() => {
    //     setStage(props.stage);
    // }, [props.stage]);

    useEffect(() => {
        setActiveContact(props.activeContact);
    }, [props.activeContact]);

    useEffect(() => {
        setDialNumber(props.dialNumber);
    }, [props.dialNumber]);

    const isNotAbleToCall = () => {
        if(props.currentCredit <= 0){
            return true;
        }else if (props.stage === 'dial' && (dialNumber.length < 10)) {
            return true;
        } else return props.stage === 'contact' && typeof activeContact.contact_id === 'undefined';
    }

    const initiateDial = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isNotAbleToCall()) {
            props.initiateDial();
        }
    }

    return (
        <div className="call-button d-flex justify-content-center align-items-center" onClick={(e) => initiateDial(e)}>
            <svg width="48" height="20" viewBox="0 0 48 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.59628 18.5844C11.1269 17.0538 12.8691 15.7903 14.7639 14.8368C15.6998 14.377 16.3393 13.3007 16.3278 12.4374L17.0168 7.50435C22.0169 5.98456 26.0324 5.98391 30.9843 7.50299L31.6625 12.3003C31.6618 13.3698 32.2505 14.3423 33.2129 14.8448C35.1172 15.8038 36.8594 17.0658 38.3907 18.5971C39.4968 19.7033 41.2967 19.7033 42.4028 18.5972L46.9939 14.0061C48.1001 12.8999 48.1001 11.1001 46.9939 9.99394C34.3149 -2.68512 13.685 -2.68505 1.0059 9.99401C-0.10022 11.1001 -0.100223 12.9 1.0059 14.0061L5.58423 18.5845C6.69035 19.6904 8.49022 19.6904 9.59628 18.5844Z"
                    fill="white"/>
            </svg>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        stage: state.globalDialerReducer.stage,
        activeContact: state.globalDialerReducer.activeContact,
        dialNumber: state.globalDialerReducer.dialNumber,
        fromNumber: state.globalDialerReducer.fromNumber,
        callInfo: state.globalDialerReducer.callInfo,
        isCallInProgress: state.globalDialerReducer.isCallInProgress,
        currentCredit: state.globalDialerReducer.currentCredit,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCallInProgressStatus: (params) => dispatch(updateCallInProgressStatus(params)),
        updateCallTime: (params) => dispatch(updateCallTime(params)),
        updateCallInfo: (params) => dispatch(updateCallInfo(params)),
        selectFromNumber: (params) => dispatch(selectFromNumber(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DialTrigger);
