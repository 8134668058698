import { Button } from "@material-ui/core";
import { useRef, useState, useEffect } from "react";
import { StyledMenu } from "../../common/StyledMenu";
import GlobalDialer from "./GlobalDialer";
import Timer from "react-compound-timer";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import GlobalModal from "../../GlobalModal/Modal/GlobalModal";
import ContactDetailCallOutcome from "../../contactDetails";
import { connect, useDispatch } from "react-redux";
import { storeMultipleReducers } from "../../../actions/globalDialerAction";
import { CALL_TYPE } from "../../../constants/CoreConstants";
import Utils from "../../addContact/Utils";

const GlobalDialerWrapper = (props) => {
    
    const dispatch = useDispatch();
    const callButton = useRef(null);
    const [updateNumber, setUpdateNumber] = useState({update: false,dialerContactNumber:null});
    const [callEl, setCallEl] = useState(null);
    const [connectedCallerInfo, setConnectedCallerInfo] = useState(null);
    const [openContactDetailModal, setOpenContactDetailModal] = useState(false);
    const [dialOccurrence,setDialOccurrence] = useState(0);


    useEffect(() => {
        if(callEl == null){
            setUpdateNumber((prev)=> {return {update: !(prev.update),dialerContactNumber:null}});
        }
    }, [callEl])

    window.initiateGlobalDialer = (contact = null, contactListInfo = null) => {

        if(contact != null){
            if(contact.dialToNumber) {
                dispatch(storeMultipleReducers({stage : 'dial',dialNumber : contact.number}));
                setDialOccurrence(occur => occur + 1);
                localStorage.setItem('isDialFromMap',true);
            } 
            else {
                if(contactListInfo){
                    props.storeMultipleReducers({ listCurrentIndex : contactListInfo.currentIndex ?? 0, stage: 'contact', 
                    recentContacts: (contactListInfo.currentIndex) ?  contactListInfo.contactList ?? [contact] :  [contact]
                })
                }
                setUpdateNumber({stage: 'contact', update: !updateNumber.update,dialerContactNumber:contact.number, contactInfo: contact, contactListInfo: contactListInfo});
            }
        }else {
            setUpdateNumber({update: false,dialerContactNumber:null, contactInfo: null,contactListInfo: contactListInfo})
        }
        setTimeout(()=>{
            callButton.current.click();
        },1000);
    }

    const hangUpCall = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(connectedCallerInfo !== null) {
            connectedCallerInfo.endCall();
        }
    }

    const OpenContactDetailModal=()=>{

        setCallEl(false);
        setTimeout(()=>{
            setOpenContactDetailModal(true)
        },500);
    }

    const closeDialog = () => {
        localStorage.setItem('isDialFromMap',false);
        setCallEl(null);
        setDialOccurrence(0);
    }

    const onCloseCallOutcomeModal=()=>{
        setOpenContactDetailModal(false);

        if(props.goToNextInList){

            let currentIndex = props.listCurrentIndex+1;
            if(props.recentContacts[currentIndex]){
                do{
                    if(props.recentContacts[currentIndex].number && props.recentContacts[currentIndex].number !== ''){
                        console.log(props.recentContacts[currentIndex])
    
                        let contact = props.recentContacts[currentIndex];
                        props.storeMultipleReducers({goToNextInList: false, listCurrentIndex : currentIndex})
                        setUpdateNumber({update: !updateNumber.update,dialerContactNumber:contact.number, contactInfo: contact,
                             contactListInfo: { from: 'contactListPage',contactList: props.recentContacts, 
                             totalContacts: props.recentContacts.length ,currentIndex : currentIndex}});
    
                        currentIndex = -1
                    }else{
                        currentIndex++;
                    }
                }while(props.recentContacts[currentIndex] && currentIndex !== -1)
            }

        }

        

    }

    return (
        <li className="top-dial-icon  call">
            <Button
                ref={callButton}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                className={`top__bar_dial ${connectedCallerInfo === null ? '' : 'connected'}`}
                onClick={(e) => setCallEl(e.currentTarget)}
            >
                {
                    connectedCallerInfo === null ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.20833 6.91667C4.54167 9.5 6.5 11.4583 9.08333 12.7917L11.0417 10.8333C11.3194 10.5556 11.625 10.4861 11.9583 10.625C12.9583 10.9583 14.0139 11.125 15.125 11.125C15.375 11.125 15.5833 11.2083 15.75 11.375C15.9167 11.5417 16 11.75 16 12V15.125C16 15.375 15.9167 15.5833 15.75 15.75C15.5833 15.9167 15.375 16 15.125 16C10.9583 16 7.38889 14.5278 4.41667 11.5833C1.47222 8.61111 0 5.04167 0 0.875C0 0.625 0.0833333 0.416667 0.25 0.25C0.416667 0.0833333 0.625 0 0.875 0H4C4.25 0 4.45833 0.0833333 4.625 0.25C4.79167 0.416667 4.875 0.625 4.875 0.875C4.875 1.98611 5.04167 3.04167 5.375 4.04167C5.48611 4.40278 5.41667 4.70833 5.16667 4.95833L3.20833 6.91667Z"
                            fill="white"/>
                    </svg> :
                    <>
                        <div className="call_end_wrapper">
                            <BootstrapTooltip arrow title="End Call">
                                <svg className="call_end_icon_top" onClick={hangUpCall} width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.20833 6.91667C4.54167 9.5 6.5 11.4583 9.08333 12.7917L11.0417 10.8333C11.3194 10.5556 11.625 10.4861 11.9583 10.625C12.9583 10.9583 14.0139 11.125 15.125 11.125C15.375 11.125 15.5833 11.2083 15.75 11.375C15.9167 11.5417 16 11.75 16 12V15.125C16 15.375 15.9167 15.5833 15.75 15.75C15.5833 15.9167 15.375 16 15.125 16C10.9583 16 7.38889 14.5278 4.41667 11.5833C1.47222 8.61111 0 5.04167 0 0.875C0 0.625 0.0833333 0.416667 0.25 0.25C0.416667 0.0833333 0.625 0 0.875 0H4C4.25 0 4.45833 0.0833333 4.625 0.25C4.79167 0.416667 4.875 0.625 4.875 0.875C4.875 1.98611 5.04167 3.04167 5.375 4.04167C5.48611 4.40278 5.41667 4.70833 5.16667 4.95833L3.20833 6.91667Z"
                                        fill="white"/>
                                </svg>
                            </BootstrapTooltip>

                            <div>
                                <span>
                                    <Timer>
                                        <Timer.Hours formatValue={value => `${(value < 10 ? `0${value}` : value)}:`}/>
                                        <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)}:`}/>
                                        <Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`}/>
                                    </Timer>
                                </span>
                                <span className="call_phone">{connectedCallerInfo.callerInfo ? connectedCallerInfo.callerInfo.length > 12 ? connectedCallerInfo.callerInfo.substr(0, 9)+' ...' : connectedCallerInfo.callerInfo : ''}</span>
                            </div>
                        </div>
                    </>
                }

            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={callEl}
                keepMounted
                className={`top-bar-dial-result ${Boolean(callEl) ? '' : 'd-none'}`}
                open={true}
                onClose={() => setCallEl(null)}
                style={{zIndex: dialOccurrence === 0 ? 1300 : 999999999}}
            >
                {/*{callEl &&*/}
                {Utils.getAccountData('userId') !== 446 ?
                    <GlobalDialer
                        updateNumber={updateNumber}
                        openDialog={() => callButton.current.click()}
                        closeDialog={() => closeDialog()}
                        setConnectedCalledInfo={(info) => setConnectedCallerInfo(info)}
                        OpenContactDetailModal={OpenContactDetailModal}
                        dialOccurrence={dialOccurrence}
                    />
                :
                    <GlobalDialer
                        updateNumber={updateNumber}
                        openDialog={() => callButton.current.click()}
                        closeDialog={() => closeDialog()}
                        setConnectedCalledInfo={(info) => setConnectedCallerInfo(info)}
                        OpenContactDetailModal={OpenContactDetailModal}
                        dialOccurrence={dialOccurrence}
                    />
                }
                
            </StyledMenu>

            {openContactDetailModal &&
                <GlobalModal
                    open={openContactDetailModal}
                    title="Contact Details"
                    onClose={() => onCloseCallOutcomeModal()}
                    buttonText={'Add Deal'}
                    hideFooter={true}
                    extra_props_active={true}
                >
                    <ContactDetailCallOutcome closeModal={() => setOpenContactDetailModal(false)}/>
                </GlobalModal>
            }
        </li>
    );
}

const mapStateToProps = state => {
    return {
        listCurrentIndex: state.globalDialerReducer.listCurrentIndex,
        goToNextInList: state.globalDialerReducer.goToNextInList,
        recentContacts: state.globalDialerReducer.recentContacts,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialerWrapper);
