import { Skeleton } from '@material-ui/lab'
import React from 'react'

export const ContactModuleSkeleton = () => {
  return (
    <div className='contact-parent-loader'>
      <div className='d-flex'>
         <div className="contact-child-one d-flex justify-content-start m-2 p-2 gap-20">
            {" "}
            
            <div style={{width:"14%"}}>
            <Skeleton variant="text"  height={40} />
            </div>
            <div style={{width:"18%"}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div style={{width:"19%"}}> 
            <Skeleton variant="rect"  height={40} />
            </div>
          </div>
          <div className="contact-child-one d-flex justify-content-between m-2 p-2 gap-20">
            {" "}
           
            <div style={{width:"40%"}}>
            <Skeleton variant="text"  height={40} />
            </div>
            <div style={{width:"50%"}}>
            <Skeleton className="borderRadius50px" variant="rect"  height={40} />
            </div>
            <div style={{width:"18%"}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div style={{width:"14%"}}>
            <Skeleton variant="text"  height={40} />
            </div>
            <div style={{width:"14%"}}>
            <Skeleton variant="rect"  height={40} />
            </div>
            <div style={{width:"10%"}}>
            <Skeleton variant="rect"  height={40} />
            </div>
          </div>
      </div>
      <div><Skeleton variant='text' style={{borderRadius:"10px"}}/></div>
      <div className='mt-2' style={{width:'10%'}}><Skeleton variant='text'/></div>

      <table>
        <thead>
          <tr>
            <th style={{width:'20%'}}>
              <Skeleton variant='rect' height={35} style={{width:"60%"}}/>
            </th>
            <th style={{width:'20%'}}>
              <Skeleton variant='rect' height={35} style={{width:"60%"}}/>
            </th>
            <th style={{width:'15%'}}>
              <Skeleton variant='rect' height={35} style={{width:"60%"}}/>
            </th>
            <th style={{width:'15%'}}>
              <Skeleton variant='rect' height={35} style={{width:"60%"}}/>
            </th>
            <th style={{width:'15%'}}>
              <Skeleton variant='rect' height={35} style={{width:"60%"}}/>
            </th>
            <th style={{width:'15%'}}>
              <Skeleton variant='rect' height={35} style={{width:"60%"}}/>
            </th>
          </tr>
        </thead>
        <tbody>
        {[1,2,3,4].map((i) =>
          <tr>
              <td>
              {[1,2,3,4].map((i) =>
                <div className='d-flex mb-2' style={{gap:"5px"}} key={i}>
                  <Skeleton variant='rect' width={15} height={15}/> 
                  <Skeleton variant='rect' height={15} style={{width:"50%"}}/> 
                </div>)}
               
              </td>
              <td>
                <div className='d-flex' style={{gap:"10px"}}>
              {[1,2,3,4,5].map((i) =>
                <div className=' mb-2' style={{width:"30px"}} key={i}>
                  {/* <Skeleton variant='circle' width={15} height={15}/>  */}
                  <Skeleton variant='circle' height={30} width={30}/> 
                </div>)}
                </div>
              </td>
              <td>
                <Skeleton variant='rect' height={20} style={{width:"60%"}}/>
              </td>
              <td>
              <Skeleton className="borderRadius50px"variant='rect' height={20} style={{width:"60%"}}/>
              </td>
              <td>
              <Skeleton className="borderRadius50px"variant='rect' height={20} style={{width:"60%"}}/>
              </td>
              <td>
              <Skeleton variant='rect' height={20} style={{width:"60%"}}/>
              </td>
          </tr>
        )}
        </tbody>
      </table>
        
    </div>
  )
}
