import moment from 'moment-timezone';

const TimezoneConversation = (inputTime, currentTimezone = 'UTC', convertTimezone = 'UTC', format = "YYYY-MM-DD HH:mm:ss") => {

  try {
    var currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
    return currentTimeInGivenTimezone
      .clone()
      .tz(convertTimezone)
      .format(format);
  } catch (e) {
    return inputTime
  }

}

export const getTimeCompareWithCurrentTime = (date, dbTimezone = 'UTC') => {
  try {
    return moment.tz(date, dbTimezone)
      .clone()
      .fromNow();
  } catch (e) {
    return date;
  }

}

export const formatDate = (date, dbTimezone = 'UTC', format = "YYYY-MM-DD HH:mm:ss") => {
  try {
    return moment.tz(date, dbTimezone)
      .format(format)
  } catch (e) {
    return date

  }

}
export const formatDateWithoutZone = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  try {
    return moment(date).format(format)
  } catch (e) {
    return date
  }

}
export const convertStringIntoTime = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  try {
    return moment(date, format)

  } catch (e) {
    return date;
  }
}


export default TimezoneConversation;
