import {useContext, useState, useEffect} from 'react'
import ApiList from '../../ApiList'
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from '../../components/Accordion/CustomAccordion'
import { ContactDetailContext } from '../../reducer/GlobalReduers'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CustomSelect from '../../components/Accordion/CustomSelect';
import { MenuItem} from '@material-ui/core';  
import { icons } from '../../icons';

const Source = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [expand, setExpand] = useState(false)
    const [leadSource, setLeadSource] = useState([])
    const [selectedLeadSource, setSelectedLeadSource] = useState('')
    const [sourceText, setSourceText] = useState('')
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        if(detailStates.contactDetails !== null){
            setSelectedLeadSource(detailStates.contactDetails.source)
            // fetch all lead source
            ApiList.getLeadSource().then(res => {
                let response = res.data;
                let list = response.leadSources
                response.leadSources.map((item, index) => {
                    list[index].value =  item.id.toString()
                })
                setLeadSource(list)
                const selected_list = response.leadSources.filter(item => item.id.toString() === detailStates.contactDetails.source.toString())
                if(selected_list.length > 0){
                    setSourceText(selected_list[0].source_title)
                }
                    
            })
        }
    }, [detailStates.contactDetails])

    const handleChange = (event, element) => {
        setSelectedLeadSource(event.target.value)
        setSourceText(element.props.name)
        setUpdating(true)
        ApiList.updateContactSource({
            contact_id: detailStates.contactId,
            source_id: event.target.value
        }).then(res => {
            setExpand(false)
            setUpdating(false)
            if(detailStates.from === 'deal-details'){
                if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                    detailStates.dealCallback(true)
                }
            }
        })
    }

    return(
        <div className="g-more-info-left g-more-info-column mt-4">
            <CustomAccordion expand={expand} className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span className="g-header-title">
                    <span className="material-icons">approval</span>
                     Source </span>
                    {(sourceText != '' && !updating) &&
                        <span className="g-contact-source-text">{sourceText}</span>
                    }
                    {(sourceText != '' && updating) &&
                        <span className="g-contact-source-text-updating"><MoreHorizIcon fontSize="small"/></span>
                    }
                    <span className="g-custom-caret">{icons.caretIcon}</span>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>
                <div className="g-contact-source-content">
                    <CustomSelect
                        id="lead-source-select"
                        value={selectedLeadSource}
                        handleChange={handleChange}
                        item={leadSource}
                        value_field={'value'}
                        titel_field={'source_title'}
                        name={'source_title'}
                        placeholder={(<MenuItem value="" disabled> Select a source </MenuItem>)}
                    />
                </div>
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
export default Source;