import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
// import ShareContactModal from "../../../leftSide/ShareContactModal";
// import EditIcon from "../../common/icons/EditIcon";
import { RightSideStyle } from "../../RightSideStyle";
import { ContactsStyles } from "../ContactsStyles";
import Utils from "../../../../../helpers/Utils"



const useStyle = makeStyles({
  titleStyle: {
    //maxWidth: "150px",
    // textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // overflowX: "hidden",
    fontSize: "25px",
    fontWeight: 600,
    // color: "white",
  },
  customTooltip: {
    fontSize: "16px", // Set your desired font size here
  },
});

const ContactTitle = ({ handleOpen, contactDetails }) => {

    const [state, setState] = useState({
        isOpen: false,
        firstNameInvalidError: false,
        firstNameLengthError: false,
        lastNameInvalidError: false,
        lastNameLengthError: false,
        showShareContactModal: false,
        dncStatus: "NOT_VERIFIED",
    });

  const classes = useStyle();

    const padZero = (value) => {
        return value < 10 ? `0${value}` : value;
    };

  const {
    secondaryButton,
    textWhite,
    alignJustifyCenter,
  } = RightSideStyle();
  const { shareIcon, editIcon } = ContactsStyles();

    const firstName = (contactDetails?.first_name || "").trim();
    const lastName = (contactDetails?.last_name || "").trim();

    const userName = `${firstName} ${lastName}`.trim() || "N/A";

    const enhanceData = () => {
        window.globalEnhancedData(true, {
            id: contactDetails.id,
            email: contactDetails.email,
            number: contactDetails.number,
            address: contactDetails.address,
            city: contactDetails.city,
            state: contactDetails.state,
            zip: contactDetails.zip,
            contact_additional_informations: contactDetails.contact_additional_informations,
            requestModule: "CONTACT",
            callback: (res) => {
                let tempContact = { ...contactDetails };

                const [isValid, inValid, doNotCall, verifiedOk] = ["VALID", "INVALID", "DO_NOT_CALL", "VERIFIED_OK"];
                const [requestDone, requestFailed] = ["REQUEST_DONE", "REQUEST_FAILED"];

                let cleanDataInfos = null;

                if (res.data && Array.isArray(res.data)) {
                    cleanDataInfos = res.data.find((validationInfo) => validationInfo.source === "CONTACT");
                }

                const currentDate = new Date();
                const formattedDate = `${currentDate.getFullYear()}-${padZero(currentDate.getMonth() + 1)}-${padZero(
                    currentDate.getDate()
                )} ${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(
                    currentDate.getSeconds()
                )}`;

                if (cleanDataInfos) {
                    if (!tempContact.contact_additional_informations) {
                        tempContact.contact_additional_informations = {};
                    }

                    if (res["email-lookup-status"] === requestDone && cleanDataInfos["email-validation-status"]) {
                        tempContact.contact_additional_informations.email_validation_status =
                            cleanDataInfos["email-validation-status"];

                        if (res["email-validation-infos"]) {
                            tempContact.contact_additional_informations.email_validation_infos = res["email-validation-infos"];
                        } else {
                            tempContact.contact_additional_informations.email_validation_infos = {
                                state: cleanDataInfos["email-state"],
                            };
                        }

                        tempContact.contact_additional_informations.email_validation_date = formattedDate;
                    }

                    if (res["carrier-lookup-status"] === requestDone && cleanDataInfos["number-validation-status"]) {
                        tempContact.contact_additional_informations.number_validation_status =
                            cleanDataInfos["number-validation-status"];

                        if (res["number-validation-infos"]) {
                            tempContact.contact_additional_informations.number_validation_infos = res["number-validation-infos"];
                        } else {
                            tempContact.contact_additional_informations.number_validation_infos = {
                                state: cleanDataInfos["number-state"],
                            };
                        }

                        tempContact.contact_additional_informations.number_state = cleanDataInfos["number-state"];
                        tempContact.contact_additional_informations.number_validation_date = formattedDate;
                    }

                    if (res["dnc-lookup-status"] === requestDone && cleanDataInfos["dnc-state"]) {
                        tempContact.contact_additional_informations.dnc_status = cleanDataInfos["dnc-state"];
                        tempContact.contact_additional_informations.dnc_validation_date = formattedDate;
                    }

                    if (res["skip-trace-lookup-status"] === requestDone) {
                        tempContact.contact_additional_informations.skip_trace_date = formattedDate;
                        tempContact.contact_additional_informations.skip_trace_status = "TRACED";
                    }
                }

                // setContactDetails(tempContact);
            },
        });
    };


  return (
    <Box>
      <Box>
        <Box className={alignJustifyCenter} sx={{ gap: 5, whiteSpace: "no-wrap" }}>
          <Tooltip
            className={classes.customTooltip}
            title={userName}
          >
            <Typography variant='h4' className={`${textWhite}`}>
              <div className={classes.titleStyle}>{userName ? userName : "No contact name"}</div>
            </Typography>
          </Tooltip>
            {Utils.getAccountData("carrierLookup") && state.dncStatus === "DO_NOT_CALL" && (
            <Box
              sx={{
                backgroundColor: "#F30000",
                color: "#fff",
                fontSize: "10px",
                padding: "2px 10px",
                borderRadius: "10px",
              }}
            >
              DNC
            </Box>
            )}
        </Box>

        {/* <Box
          sx={{ opacity: 0, transition: "all .35s", height: "auto !important" }}
          className={`${alignJustifyCenter} contactTitleAction`}
          gridGap={1}
          ml={"auto"}
          position={"absolute"}
          top={10}
          right={10}
        >
          <IconButton className={editIcon} size='small' >
            <ShareIcon style={{ color: "white", fontSize: 16 }} fill='white' />
          </IconButton> */}
          {/* <IconButton onClick={handleOpen} className={editIcon} size='small'> */}
            {/* <EditIcon fill='white' /> */}
          {/* </IconButton> */}
        {/* </Box> */}
      </Box>

      {/* <Box className={alignJustifyCenter} pt={0.5}>
        <ContactCompany />
      </Box> */}

      <Box className={alignJustifyCenter} sx={{ gridGap: 5, pt: 1 }}>
     
          <Button
            className={secondaryButton}
            variant='contained'
            size='small'
            disableElevation
            style={{ borderRadius: 25, paddingBlock: 2, backgroundColor: "#00ff91", color: "#000000" }}
            onClick={enhanceData}
          >
            Enhance Data
          </Button>
      
      </Box>

      {/* {state.showShareContactModal && (
        <ShareContactModal
          title={"Share contact information"}
          isOpen={state.showShareContactModal}
          open={state.showShareContactModal}
          contact_id={contacts?.id}
          closeModal={closeShareContactModal}
          onClose={closeShareContactModal}
        />
      )} */}
    </Box>
  );
};



export default ContactTitle;
