import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import { connect } from "react-redux";
import { storeMultipleReducers } from "./redux/cardBroadcastAction";
import GlobalModal from './assets/GlobalModal';




const PrintingDeliveryTimeModal=(props)=>{

    const closeModal=()=>{
        props.storeMultipleReducers({showPrintDeliveryOptions:false});
        // setTimeout(()=>{
            // props.onClose(false)
        // },500)
    }

return (
    <GlobalModal 
    open = {props.showPrintDeliveryOptions}
    // className={'cardBordcastTab globalBroadcastModal alt'}
    onClose = {closeModal}
    className={'global-card-broadcast-modal'}
    width={900}
    title={`More About Printing & Delivery Times`}
 // modalBodyClass={'awesome__scroll_bar'}
    buttonText={'ok'}
    // buttonIcon={buttonIcon}
    // onSubmit={onSubmit}
    hideFooter={true}
 >

        <p>Orders after 10am Eastern will be processed the next day. Orders placed on weekends or after 10am Eastern Friday will not be printed until Monday morning. </p>

    <p>Orders for Notecards placed by 8 PM Eastern are printed and mailed on the next USPS business day.  USPS business days are defined as every day except Sundays and federal holidays. </p>
    <p>Postcards and letters are sent USPS First Class. Typically this means the card will arrive at the recipient's address 5-8 days after it is dropped off to be mailed out. </p>
    <p>For deliveries outside of the USA, delivery times are typically double the USPS First Class Delivery estimate (10-16 days.) </p>
    <p><b>Postcards</b> and <b>Letters</b> may take up to 4 days to print.</p>
    <p><b>Notecards</b> are printed typically within 24 hours.
    </p>

    </GlobalModal>

)

}
const mapStateToProps = state => {
    return {
        showPrintDeliveryOptions: state.cardBroadcastReducer.showPrintDeliveryOptions,

    };
 };
 const mapDispatchToProps = dispatch => {
    return {
       storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
 }

export default connect(mapStateToProps,mapDispatchToProps)(PrintingDeliveryTimeModal);
