import { Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Utils from '../../helpers/Utils';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import { Link } from 'react-router-dom';
import { getCustomMenuData } from '../addContact/contactApi';


const SidebarNewCustomMenu = props => {

    const [customMenu, setCustomMenu] = useState([]);

    useEffect(() => {
        getCustomMenuData().then(res => {
            if(res.data.status && res.data.data){
                setCustomMenu(res.data.data)
            }
        }).catch((e) => {
            console.log(e);
        });
    },[]);


    return (
        <>

       { customMenu.map(elem => (
            <BootstrapTooltip  title={props.menuCollapsed ? elem.title : ""} arrow placement="right" 
                key={elem.id}>
                <li>
                    { elem.is_iframe === 'NO' ?
                        <a href={elem.link} className={"waves-effect left-side-main-menu"} style={{color : elem.text_color,backgroundColor : elem.background_color}}
                            target={elem.link_target === 'NEW_TAB' ? '_blank' : '_self'}
                            rel="noreferrer">
                            <Icon style={{fontSize : 18}}>{elem.icon}</Icon>
                            <span className="item__text" style={{fontSize: 14,lineHeight : 20+"px"}}>{elem.title}</span>
                        </a>
                        :
                        <Link to={'/user/custom-page?url='+elem.link+'&title='+elem.title} className={"waves-effect left-side-main-menu"} style={{color : elem.text_color,backgroundColor : elem.background_color}}
                            target={elem.link_target==='NEW_TAB'? '_blank' : '_self'}
                            rel="noreferrer">
                            <Icon style={{fontSize : 18}}>{elem.icon}</Icon>
                            <span className="item__text" style={{fontSize: 14,lineHeight : 20+"px"}}>{elem.title}</span>
                        </Link>
                    }
                    
                </li>
            </BootstrapTooltip>
        ))}
        </>
    )
}

export default SidebarNewCustomMenu
