import React, { useState } from 'react';
import Countdown from 'react-countdown-now';

const Webcam = props => {
    const [recordStarted, setRecordStarted] = useState(0);

    const countDownStart = () => {
        setRecordStarted(1);
    }

    const startClick = () => {
        setRecordStarted(2);
        props.startClick();
    }

    const stopClick = e => {
        if(e) {
            e.preventDefault();
        }
        props.stopClick();

    }

    return (
        <div className="video-recorder__Wrapper-sc-7k20rv-0 WroCl-old WroCl__custom">
            {recordStarted == 2 &&
                <div>
                    <div className="render-actions__ActionsWrapper-dp6lnv-0 MccLt">
                        <div className="stop-button__Border-sc-1h536gx-1 bPvwx">
                            <button data-qa="stop-recording" color="black" className="stop-button__Button-sc-1h536gx-0 dBzviu" onClick={(e) => stopClick(e)}></button>
                        </div>
                    </div>
                    <div className="timer">
                        <div className="d-none">
                            <Countdown
                                date={Date.now() + 4000}
                                renderer={({ completed }) => {
                                    if (completed) {
                                        props.stopGif();
                                        return <span></span>;
                                    } else {
                                        // Render a countdown
                                        return '';
                                    }
                                }}
                            />
                        </div>
                        <div className="timer__RecIcon-avqbub-1 vJroN"></div>
                        <Countdown
                            date={Date.now() + 2*60000}
                            renderer={({ minutes, seconds, completed }) => {
                                if (completed) {
                                    stopClick();
                                    return <span>3</span>;
                                } else {
                                    // Render a countdown
                                    return <span>{minutes}:{seconds}</span>;
                                }
                            }}
                        />
                    </div>
                </div>
            }

            <div className="video-recorder__CameraView-sc-7k20rv-1 hppFhA">
                <video id="video-email-vid" autoPlay muted src='' className="gpOkVY gpOkVY__custom "/>
            </div>

            {recordStarted == 0 &&
                <div className="render-actions__ActionsWrapper-dp6lnv-0 MccLt">
                    <div className="record-button__RecWrapper-sc-1n5amwk-1 fWXksH">
                        <div className="record-button__Instructions-sc-1n5amwk-3 bTwNuH">
                            <div>PRESS</div>
                            <div className="record-button__InstuctionsHighlight-sc-1n5amwk-4 iSDLnZ"> REC</div>
                            WHEN READY
                        </div>
                        <div className="record-button__ButtonBorder-sc-1n5amwk-2 fFdAUW">
                            <button data-qa="start-recording" color="black"
                                    className="record-button__Button-sc-1n5amwk-0 eTRucs"
                                    onClick={() => countDownStart()}></button>
                        </div>
                    </div>
                </div>
            }

            {recordStarted == 1 &&
                <div className="render-actions__ActionsWrapper-dp6lnv-0 MccLt">
                    <div className="record-button__RecWrapper-sc-1n5amwk-1 fWXksH">
                        <div className="record-button__Instructions-sc-1n5amwk-3 bTwNuH">
                            <div className="countdown update_countdown">
                                <Countdown
                                    date={Date.now() + 3000}
                                    renderer={({ seconds, completed }) => {
                                        if (completed) {
                                            startClick();
                                            return '';
                                        } else {
                                            // Render a countdown
                                            return seconds;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Webcam;
