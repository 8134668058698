import * as ACTION from "../components/addContact/addContactActionTypes"

const initialState = {
  userTagListAddContact: [],
  sourceListAddContact: null,
  userCustomFieldsAddContact: null,
  allContactList: [],
  userInfo: [],
  subUserList: null,
  contact: null,
  contactAdditionalContacts: [],
}

const addContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_USERTAG_LIST_ADDCONTACT:
      return { ...state, userTagList: action.payload }
    case ACTION.SET_SUBUSER_LIST:
      return { ...state, subUserList: action.payload }
    case ACTION.SET_CONTACT_DETAILS:
      return { ...state, contact: action.payload }

    case ACTION.SET_USERSOURCE_LIST_ADDCONTACT:
      return { ...state, sourceListAddContact: action.payload }

    case ACTION.SET_USERCUSTOMFIELD_LIST_ADDCONTACT:
      return { ...state, userCustomFieldsAddContact: action.payload }

    case ACTION.SET_USERCUSTOMFIELD_LIST_ADDCONTACT_NEW:
      return { ...state, userCustomFieldsAddContact: action.payload.payload }
    /*Get All Contacts */
    case ACTION.SET_ALL_CONTACTS:
      return { ...state, allContactList: action.payload }
    /*Set User Info */
    case ACTION.SET_USER_INFO:
      return { ...state, userInfo: action.payload }
    case ACTION.UPDATE_MULTIPLE_DATA:
      return {...state, ...action.payload}

    default:
      return state
  }
}

export default addContactReducer
