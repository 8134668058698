import DirectMailIcons from "../../DirectMailIcons";

const FooterButton = ({handleStepChange, isValid}) => {
    return(
        <div className='dmpc_tv_right_inner_btn'>
            <div className={`dmpc_tv_right_inner_savebtn ${isValid ? '' : 'global_post_card_disabled_btn'}`} onClick={()=>handleStepChange(true)}>
                <span>{DirectMailIcons.saveContinueIcon}</span>
                <span>Next</span>
            </div>
        </div>
    );
}
export default FooterButton;