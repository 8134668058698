import { useEffect, useState, useRef } from "react";
import Popover from '@material-ui/core/Popover';
import CsutomNestedList from "./CsutomNestedList";
const downSingleArrow = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7636 6.56341C5.11508 6.21194 5.68492 6.21194 6.0364 6.56341L9 9.52701L11.9636 6.56341C12.3151 6.21194 12.8849 6.21194 13.2364 6.56341C13.5879 6.91488 13.5879 7.48473 13.2364 7.8362L9.6364 11.4362C9.28492 11.7877 8.71508 11.7877 8.3636 11.4362L4.7636 7.8362C4.41213 7.48473 4.41213 6.91488 4.7636 6.56341Z" fill="#111827"/>
                    </svg>

const CustomPopupMenu = ({
    showMenu, hanldeMenu, list, handleClick, isLoading, labelField, labelText
}) => {
    const [anchorActionMenu, setAnchorActionMenu] = useState(null);
    const [open, setOpen] = useState(false);
    const dropDownLabel = useRef(null);

    useEffect(() => {
        if(showMenu){
            dropDownLabel.current.click()
        }
    }, [showMenu])

    const handleMenuLabel = (e) => {
        setAnchorActionMenu(e.currentTarget);
        setOpen(true)
    }

    const handleClose = (e) => {
        setAnchorActionMenu(null);
        setOpen(false)
        hanldeMenu(false)
    }

    const renderLabel = () => {
        return (
            <span style={{textTransform: 'capitalize'}} className={`label_class_wrapper`} onClick={(e) => handleMenuLabel(e)} ref={dropDownLabel}>
                {labelText !== undefined && labelText}
                {downSingleArrow}
            </span>
        )
    }

    return (
        <div className={`custom_menu_option_container`} onClick={(e) => e.stopPropagation()}>
            {renderLabel()}

            <Popover
                id={"menu__popup__pypepro"}
                open={open}
                anchorEl={anchorActionMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        boxShadow: "0px 2px 3px rgba(0,0,0,0.5)",
                        width: "300px",
                        height: "400px"
                    }
                }}
            >
                {(isLoading || list.length === 0) &&
                    <div className="empty__containner">
                        <span>{isLoading ? "Please wait..." : "No data found !"}</span>
                    </div>
                }
                {(!isLoading && list.length > 0) &&
                <div className="awesome__scroll_bar gift_card_main_wrpper">
                    <CsutomNestedList list={list} onSelect={(value) => handleClick(value)} />
                </div>
                }
            </Popover>
        </div>
    )
}
export default CustomPopupMenu;