import React, {useEffect, useState} from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import NewCampaignPopup from "../GlobalModal/NewCampaignPopup";
import ActivityModal from "../GlobalModal/ActivityModal";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 170,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const AddNewMenu = (props) => {

    const classes = useStyles();
    const [openAddNewCampaignModal, setOpenAddNewCampaignModal] = React.useState(false);
    // const [openActivityModal, setOpenActivityModal] = React.useState(false);
    // const [subUserList, setSubUserList] = React.useState(null)
    // const [callBackObject, setCallBackObject]= React.useState(null)
    // const [people, setPeople] = React.useState(null)
    // const [editData, setEditData] = React.useState(null)
    // const [showAll, setShowAll] = React.useState(true)
    // const [slotInfo, setSlotInfo] = React.useState([])
    // const [activityFrom, setActivityFrom] = React.useState('')

    const [activityModalInfos, setActivityModalInfos] = useState({open:false,subUserList: null, callbackFunction: null,
        people: null, editData: null, showAll: null, slotInfo: [], activityFrom: '' })


    const handleAddNewCampaignModal = (status) => {
        setOpenAddNewCampaignModal(status);
    };
// 
    const closeActivityModel = () => {
        setActivityModalInfos({open:false,subUserList: null, callbackFunction: null,
            people: null, editData: null, showAll: null, slotInfo: [], activityFrom: '' })
    }

    // useEffect(() => {
    //     if(callBackObject != null){
    //         console.log('asd')
    //         callBackObject.callbackFunction()
    //     }
    // }, [callBackObject])

    window.openGlobalActivityModal= async(info)=>{
        
        let tempActivityModalInfos = {...activityModalInfos}
        if(info.subUserList !== undefined && info.subUserList != null){
            tempActivityModalInfos.subUserList =info.subUserList
        }
        if(info.callBack !== undefined && info.callBack != null){

            tempActivityModalInfos.callbackFunction =info.callBack 
        }
        if(info.contactDetails !== undefined && info.contactDetails != null){
            tempActivityModalInfos.people =info.contactDetails
        }
        if(info.slotInfo !== undefined && info.slotInfo != null){
            tempActivityModalInfos.slotInfo = info.slotInfo 
        }
        if(info.editData !== undefined && info.editData != null){
            tempActivityModalInfos.editData = info.editData
            
        }
        if(info.taskType !== undefined && info.taskType != null && info.taskType === 'Appointment'){
            tempActivityModalInfos.showAll =false
        }else{
            tempActivityModalInfos.showAll =true;
        }
        if(info.from !== undefined && info.from != null){
            tempActivityModalInfos.activityFrom = info.from
        }else{
            tempActivityModalInfos.activityFrom = '';
        }
        tempActivityModalInfos.open =true

        setActivityModalInfos(tempActivityModalInfos);
    }


    const callBack = ()=>{
        if(activityModalInfos.callbackFunction != null){
            activityModalInfos.callbackFunction()
        }
    }
    return ( 
        <ul>
            <li>
                <a href="#!" onClick={(e)=>{e.preventDefault();props.showAddDeal()}}>
                    <h4>New Deal</h4> Add a new deal to your sales pipeline
                </a>
            </li>
            <li>
                <a href="#!" onClick={(e)=>{e.preventDefault();props.showAddContactModal()}}>
                    <h4>New Contact</h4> Add a new contact to the <br /> system
                </a>
            </li>
            <li>
                <a href="#!" onClick={(e)=>{e.preventDefault();props.handleClose('new'); window.openGlobalActivityModal({})}} >
                    <h4>New Activity</h4> Schedule meetings, task and follow up
                    activities
                </a>

                {/* <ActivityModal
                    openActivityModal={openActivityModal}
                    setOpenActivityModal={() => closeActivityModel()}
                    showAll={true}
                    subUserList={subUserList}
                />
                {(this.props.from ==='Task' && this.state.showModal)&& */}
                {activityModalInfos.open &&
                <ActivityModal
                    openActivityModal={activityModalInfos.open}
                    closeActivityModel={closeActivityModel}
                    showAll={activityModalInfos.showAll}
                    slotInfo={activityModalInfos.slotInfo}
                    subUserList={activityModalInfos.subUserList}
                    // fetchTask={() => this.props.refreshData()}
                    callBack={() => callBack()}
                    people={activityModalInfos.people}
                    editData={activityModalInfos.editData}
                    activityFrom={activityModalInfos.activityFrom}
                />
                }

            </li>
            <li>
                <a href="#" onClick={(e)=>{e.preventDefault(); props.handleClose('new'); handleAddNewCampaignModal(true);}}>
                    <h4>New Campaign</h4> Create a new automated drip marketing
                    campaign
                </a>

                {/* <Modal
                    open={openAddNewCampaignModal}
                    onClose={() => handleAddNewCampaignModal(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"

                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                > */}
                    {/* <Fade in={openAddNewCampaignModal}> */}
                        {openAddNewCampaignModal &&
                            <NewCampaignPopup 
                            handleAddNewCampaignModal={handleAddNewCampaignModal}
                            open={openAddNewCampaignModal}
                            close={() => handleAddNewCampaignModal(false)}/>
                        }
                    {/* </Fade> */}
                {/* </Modal> */}
            </li>
        </ul>
    );
}
 
export default AddNewMenu;