import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DirectMailCardHeader from '../components/DirectMailCardHeader';
import DirectMailSteps from '../components/DirectMailSteps';
import { useParams } from 'react-router';
import ZendirectContent from './ZendirectContent';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
}));
const zendirect_gift_steps = [
    {title: 'Select Gift'},
    {title: 'Select Design'},
    {title: 'Type your Message'},
    {title: 'Ready to Send'}
];
const THANKSIO_GREETING_CARD = 10;

const ZendirectApp = ({handleBack, from, processFor, campaignData}) => {
    const classes = useStyles();
    const { contactId } = useParams();

    const [states, setStates] = useState({
        current_steps: 0,
        completed_steps: [],
        goingForward: false,
        isLoading: false,
        lastCompletedStep: 0
    })

    const handleChangeStates = (payload) => {
        setStates({...states, ...payload})
    }

    const handleChangeStep = (value) => {
        if(parseInt(value) > states.lastCompletedStep){
            handleChangeStates({current_steps: parseInt(value), lastCompletedStep: parseInt(value)})
        }
        else{
            handleChangeStates({current_steps: parseInt(value)})
        }
    }

    return(
        <div className='dmpc__wr'>
            <DirectMailCardHeader title={"Gift"} price={'---'} onClose={handleBack} from={from} />

            <div className={`${classes.root} dmpc__setper_wr`}>
                <DirectMailSteps 
                    steps={zendirect_gift_steps}
                    completed_steps={states.completed_steps}
                    lastCompletedStep={states.lastCompletedStep}
                    current_step={states.current_steps}
                    // onclick={(value) => {}}
                    onclick={(value) => {
                        if(states.lastCompletedStep >= parseInt(value)){
                            handleChangeStep(value)
                        }
                    }}
                />
                <ZendirectContent 
                    current_step={states.current_steps} 
                    completed_steps={states.completed_steps} 
                    onChangeStep={handleChangeStep}
                    contactId={contactId}
                    handleBack={handleBack}
                    processFor={processFor}
                    campaignData={campaignData}
                    lastCompletedStep={states.lastCompletedStep}
                    handleChangeStates={handleChangeStates}
                />
             </div>
        </div>
    )
}
export default ZendirectApp;