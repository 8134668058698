import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      height : "100%"
    },
    root: {
      height : "100%"
    },
    menuPaper: {
        maxHeight: 350
    }
}));

const GlobalCustomSelect = (props) => {
    const classes = useStyles();
    let propsData = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        getContentAnchorEl: null,
        classes: { paper: classes.menuPaper }
    }

    return (
        <React.Fragment>
            <FormControl
                className={"form-control"}
            >
                <Select
                    value={props.value}
                    onChange={props.handleOnChange}
                    variant={'standard'}
                    displayEmpty
                    MenuProps={propsData}
                    className={`custom-select-container`}
                >
                    <MenuItem value="" disabled={true}>{props.placeholder != undefined ? props.placeholder : 'Placeholder'}</MenuItem>
                    {props.data.map((item, index) => {
                        let _value = props.value_field === '__FULL_DATA__' ? item : item[props.value_field]
                        let _title = props.title_field === '__FULL_DATA__' ? item : item[props.title_field]
                        return (
                            <MenuItem className="menuitem-li-hover-color-with-border" value={_value} key={index}>{_title}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>

    );

}
export default GlobalCustomSelect;