import {Skeleton} from "@material-ui/lab";
import React from 'react';

const ListSkeleton = props => {

    const renderSkeletons = () => {
        if (props.number) {
            let skeletons = [];
            for (let i = 0; i < props.number; i++) {
                skeletons.push(<Skeleton animation={'wave'} width={props.width ?? 100} height={props.height ?? 50} key={i}/>);
            }

            return skeletons;
        } else {
            return <Skeleton animation={'wave'} width={props.width ?? 100} height={props.height ?? 50}/>
        }

    }

    return (
        <React.Fragment>
            {renderSkeletons()}
        </React.Fragment>
    );
}

export default ListSkeleton;