import React, { useState } from 'react';
import FormEntryDetails from './FormEntryDetails';

const OldFormEntry = ({entry}) => {

    const [openModal, setOpenModal] = useState(false);

    const toggleOpenModal = (e) => {
        e.preventDefault();
        setOpenModal(!openModal);
    }

    return (
        <React.Fragment>
            <div className="m-widget2__item complete__form">
                <div className="m-widget2__desc">
                    <a onClick={toggleOpenModal} className="image-popup-container" href="#!">
                        {entry.form_builder.title} <i className="fa fa-wpforms"/><br/>
                        <p className="text-dark">{entry.created_at}</p>
                    </a>
                </div>
            </div>
            {
                openModal && (
                    <FormEntryDetails
                        entry={entry}
                        open={openModal}
                        closeModal={() => setOpenModal(false)}
                    />
                )
            }
        </React.Fragment>
    )
}

export default OldFormEntry;
