import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as ACTION from './addContactActionTypes'
import * as CONTACT_API from "./contactApi";
import Utils from "./Utils";



function* getUserTagListWatcher() {
    yield takeEvery(ACTION.GET_USERTAG_LIST_ADDCONTACT, attemptGetUserTagList)
}

function* attemptGetUserTagList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getTagList, action.payload);
        let responseData = response.data;

        if (response.status == 200) {
            yield put({type: ACTION.SET_USERTAG_LIST_ADDCONTACT, payload: responseData});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* getUserSourceListWatcher() {
    yield takeEvery(ACTION.GET_USERSOURCE_LIST_ADDCONTACT, attemptGetUserSourceList)
}

function* attemptGetUserSourceList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getSourceListDropdownForContactTable, action.payload);
        let responseData = response.data;
        if (response.status == 200) {
            yield put({type: ACTION.SET_USERSOURCE_LIST_ADDCONTACT, payload: responseData});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* getUserCustomFieldListWatcher() {
    yield takeEvery(ACTION.GET_USERCUSTOMFIELD_LIST_ADDCONTACT, attemptGetUserCustomFieldList)
}

function* attemptGetUserCustomFieldList(action) {

    let { payload } = action;
    
    if(payload.payload){
        payload = payload.payload
    }

    try {
        const response = yield call(CONTACT_API.getUserCustomField, payload);
        let responseData = response.data;
        if (response.status == 200) {
            yield put({type: ACTION.SET_USERCUSTOMFIELD_LIST_ADDCONTACT, payload: responseData.data});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

export default function* addContactMiddleware() {
    yield all([
        getUserTagListWatcher(), getUserSourceListWatcher(),
        getUserCustomFieldListWatcher()
    ])
}