import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Styles from "./RecheckAlert.module.css";

const RecheckAlert = (props) => {
    let default_params = {
        type: "warning",
        title: "",
        description: "",
        highlightText: "",
        cancelText: "No",
        submitText: "Yes",
        onCloseClick: () => {},
        onSubmit: () => {},
        closeOnClickOutside: true,
        width: "700px"
    };
    let data_params = {...default_params, ...props};
    let {
        title, description, closeOnClickOutside, cancelText, submitText, onCloseClick, onSubmit, width
    } = data_params;
    const handleSubmit = (onClose) => {
        onSubmit();
        onClose();
    }
    return (
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={Styles.mainWrp} style={{ width: width }}>
                        <div className={Styles.headerTitle}>
                            {title}
                        </div>
                        <div className={Styles.bodyWrp}>
                            <span className={Styles.bodyTitle}>{description}</span>
                            <span className={Styles.descriptionText}>Would you still like to proceed?</span>
                        </div>
                        <div className={Styles.footerWrp}>
                            <span onClick={() => {handleSubmit(onClose)}} className={`${Styles.button} ${Styles.submitButton}`}>
                             {submitText}
                            </span>
                            <span onClick={() => { onCloseClick(); onClose();}} className={`${Styles.button} ${Styles.cancelButton}`}>{cancelText}</span>
                        </div>
                    </div>
                );
            },
            closeOnClickOutside: closeOnClickOutside,
            overlayClassName: Styles.overlay
        })
    )
}
export default RecheckAlert;