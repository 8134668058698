import DirectMailIcons from "../../../../DirectMailIcons";

const CanvaZoneView = ({
    hintsText,
    canvaIntegModalHandler,
    showHints,
    uploadedFile
}) => {
    return(
        <div className='dmpc_templete_upload_drag'>
            <h4 className='dmpc_templete_ud_title'>Process template image</h4>
            <div className='dmpc_templste_ud_canva'>
                {uploadedFile !== "" ?
                    <img src={`${uploadedFile}`} className="dmpc_templste_ud_canva_img" width="300px" alt="" />
                    :
                    <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/81z01Q736F604c1aiq9R.png`} className="mb-1" width="45px" alt=""/>
                }
                <button className='dmpc_templste_ud_canva_btn' onClick={canvaIntegModalHandler}>
                    <span>{DirectMailIcons.canvaIcon}</span>
                    <span>DESIGN ONLINE USING CANVA</span>
                </button>
            </div>
            {showHints && <span className='dmpc_templete_ud_file_limit'>{hintsText}</span>}
        </div>
    );
}
export default CanvaZoneView;    