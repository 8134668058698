import FooterButton from "./FooterButton";

const NotecardView = ({selectDesignStep, handleStepChange}) => {
    
    const renderImage = () => {
        if(selectDesignStep.selectedTemplate != null){
            return selectDesignStep.selectedTemplate.image_path
        }
        return "";
    }
    return (
        <div className='dmpc_notecard_preview_right'>
            <div className='dmpc_npr_inner'>
                {selectDesignStep.selectedTemplate != null &&
                <div className="dmpc_npr_i__image__preview" style={{background:`url('${renderImage()}')`}}>
                    <div className="dmpc_npr_i_ip_cut_zone">
                        <div className="dmpc_npr_i_ip_safety_zone"></div>
                    </div>
                </div>
                }

                {selectDesignStep.selectedTemplate == null &&
                <div className='dmpc_npr_i__image__preview'>
                    <div className="dmpc_npr_i_ip_cut_zone">
                        <div className="dmpc_npr_i_ip_safety_zone"></div>
                    </div>
                    <div className='gallery_prview_select_empty'>Select a template</div>
                </div>
                }
            
                <div className='dpmp_tv_footer_line_wr'>
                    <div className='dpmp_tv_footer_line_left'>
                        <span className='dpmp_tv_footer_line_left_border'></span>
                        <span>Cut Line</span>
                    </div>
                    <div className='dpmp_tv_footer_line_left'>
                        <span className='dpmp_tv_footer_line_right_border'></span>
                        <span>Safety Zone</span>
                    </div>
                </div>

                <FooterButton 
                    selectDesignStep={selectDesignStep}
                    handleStepChange={handleStepChange}
                />
            </div>
        </div>
    );
}
export default NotecardView;