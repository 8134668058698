import React from "react";
import Styles from "./Styles.module.css";
import {
    FormControl,
    InputBase,
    withStyles,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormHelperText, Select, MenuItem
} from "@material-ui/core";
import Utils from "../../helpers/Utils";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
        "&::placeholder": {
            fontSize: "11px !important"
        }
    },
}))(InputBase);

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#707070 !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4
        },
    },
}))(InputBase);

const jobPositionList = [
    {label: "Director", value: "Director"},
    {label: "GM", value: "GM"},
    {label: "VP", value: "VP"},
    {label: "CEO", value: "CEO"},
    {label: "CFO", value: "CFO"},
    {label: "General Counsel", value: "General_Counsel"},
    {label: "Other", value: "Other"}
];

const PointOfContact = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError }) => {
    const handleFormDataChange = (name, value) => {
        if (name === "rep_user_1_first_name"){
            if (value.trim() === ""){
                errorCallback("rep_user_1_first_name","First Name is required!");
            }else{
                errorCallback("rep_user_1_first_name","");
            }
        }else if (name === "rep_user_1_last_name"){
            if (value.trim() === ""){
                errorCallback("rep_user_1_last_name","Last Name is required!");
            }else {
                errorCallback("rep_user_1_last_name","");
            }
        }else if (name === "rep_user_1_business_title"){
            if (value.trim() === ""){
                errorCallback("rep_user_1_business_title","Business Title required!");
            }else {
                errorCallback("rep_user_1_business_title","");
            }
        }else if (name === "rep_user_1_job_position"){
            if (value.trim() === ""){
                errorCallback("rep_user_1_job_position","Job Position is required!");
            }else {
                errorCallback("rep_user_1_job_position","");
            }
        }else if (name === "rep_user_1_email"){
            if (value.trim() === ""){
                errorCallback("rep_user_1_email","Email Address is required!");
            }else if (!Utils.isValidEmail(value)){
                errorCallback("rep_user_1_email","Email Address must be valid!");
            }else {
                errorCallback("rep_user_1_email","");
            }
        }else if (name === "rep_user_1_phone_number"){
            if (value.trim() === ""){
                errorCallback("rep_user_1_phone_number","Mobile Number is required!");
            }else if (!Utils.isValidMobileNumber(value)){
                errorCallback("rep_user_1_phone_number","Mobile Number must be valid!");
            }else {
                errorCallback("rep_user_1_phone_number","");
            }
        }

        if (formData.auth_rep_2 !== "false"){
            if (name === "rep_user_2_first_name"){
                if (value.trim() === ""){
                    errorCallback("rep_user_2_first_name","First Name is required!");
                }else{
                    errorCallback("rep_user_2_first_name","");
                }
            }else if (name === "rep_user_2_last_name"){
                if (value.trim() === ""){
                    errorCallback("rep_user_2_last_name","Last Name is required!");
                }else {
                    errorCallback("rep_user_2_last_name","");
                }
            }else if (name === "rep_user_2_business_title"){
                if (value.trim() === ""){
                    errorCallback("rep_user_2_business_title","Business Title required!");
                }else {
                    errorCallback("rep_user_2_business_title","");
                }
            }else if (name === "rep_user_2_job_position"){
                if (value.trim() === ""){
                    errorCallback("rep_user_2_job_position","Job Position is required!");
                }else {
                    errorCallback("rep_user_2_job_position","");
                }
            }else if (name === "rep_user_2_email"){
                if (value.trim() === ""){
                    errorCallback("rep_user_2_email","Email Address is required!");
                }else if (!Utils.isValidEmail(value)){
                    errorCallback("rep_user_2_email","Email Address must be valid!");
                }else {
                    errorCallback("rep_user_2_email","");
                }
            }else if (name === "rep_user_2_phone_number"){
                if (value.trim() === ""){
                    errorCallback("rep_user_2_phone_number","Mobile Number is required!");
                }else if (!Utils.isValidMobileNumber(value)){
                    errorCallback("rep_user_2_phone_number","Mobile Number must be valid!");
                }else {
                    errorCallback("rep_user_2_phone_number","");
                }
            }
        }

        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    const renderJobPositionListOptions = () => {
        const item = [];
        for(let i=0;i<jobPositionList.length;i++){
            item.push(<MenuItem className="dropdownhelper-menuitem-class" value={jobPositionList[i].value}>{jobPositionList[i].label}</MenuItem>);
        }
        return item;
    }

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>Please complete the info below in case we need to contact you or an authorized representative regarding your registration. We will only contact you if there are urgent issues.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        First Name
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your first name."
                            name="rep_user_1_first_name"
                            value={formData.rep_user_1_first_name}
                            onChange={(e)=>{handleFormDataChange("rep_user_1_first_name", e.target.value)}}
                        />
                        {renderErrorMessage("rep_user_1_first_name")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Last Name
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your last name."
                            name="rep_user_1_last_name"
                            value={formData.rep_user_1_last_name}
                            onChange={(e)=>{handleFormDataChange("rep_user_1_last_name", e.target.value)}}
                        />
                        {renderErrorMessage("rep_user_1_last_name")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[2] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Title
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your business title."
                            name="rep_user_1_business_title"
                            value={formData.rep_user_1_business_title}
                            onChange={(e)=>{handleFormDataChange("rep_user_1_business_title", e.target.value)}}
                        />
                        {renderErrorMessage("rep_user_1_business_title")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[3] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Job Position
                        <span className={Styles.required}> *</span>
                      </span>
                        <Select
                            name="rep_user_1_job_position"
                            value={formData.rep_user_1_job_position}
                            displayEmpty
                            onChange={(e)=>{ handleFormDataChange("rep_user_1_job_position", e.target.value) }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>Select your job position.</MenuItem>
                            {renderJobPositionListOptions()}
                        </Select>
                        {renderErrorMessage("rep_user_1_job_position")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[4] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        What is your email address?
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your email address."
                            name="rep_user_1_email"
                            value={formData.rep_user_1_email}
                            onChange={(e)=>{handleFormDataChange("rep_user_1_email", e.target.value)}}
                        />
                        {renderErrorMessage("rep_user_1_email")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[5] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        What is your phone number?
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your phone number."
                            name="rep_user_1_phone_number"
                            value={formData.rep_user_1_phone_number}
                            onChange={(e)=>{handleFormDataChange("rep_user_1_phone_number", e.target.value)}}
                        />
                        {renderErrorMessage("rep_user_1_phone_number")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[6] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Do you want to add another authorized representative? (optional)
                        <span className={Styles.required}> *</span>
                      </span>
                        <span className={Styles.formControlMessage}>We may contact this authorized representative if the first one doesn't respond.</span>
                        <RadioGroup className={Styles.radioGroupOverwrite} aria-label="auth_rep_2" name="auth_rep_2" value={formData.auth_rep_2} onChange={(e)=> { handleFormDataChange("auth_rep_2",e.target.value) }}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"false"} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                    <div>
                        {
                            formData.auth_rep_2 === "true" ? (
                                <div>
                                    <div className={Styles.addAnotherRep}>
                                        <h5>Authorized Representative 2</h5>
                                    </div>
                                    <FormControl ref={el => inputScrollToViewRef.current[7] = el} className={Styles.formControl}>
                                          <span className={Styles.label}>
                                            First Name
                                            <span className={Styles.required}> *</span>
                                          </span>
                                        <CustomInput
                                            placeholder="Enter your first name."
                                            name="rep_user_2_first_name"
                                            value={formData.rep_user_2_first_name}
                                            onChange={(e)=>{handleFormDataChange("rep_user_2_first_name", e.target.value)}}
                                        />
                                        {renderErrorMessage("rep_user_2_first_name")}
                                    </FormControl>
                                    <FormControl ref={el => inputScrollToViewRef.current[8] = el} className={Styles.formControl}>
                                          <span className={Styles.label}>
                                            Last Name
                                            <span className={Styles.required}> *</span>
                                          </span>
                                        <CustomInput
                                            placeholder="Enter your last name."
                                            name="rep_user_2_last_name"
                                            value={formData.rep_user_2_last_name}
                                            onChange={(e)=>{handleFormDataChange("rep_user_2_last_name", e.target.value)}}
                                        />
                                        {renderErrorMessage("rep_user_2_last_name")}
                                    </FormControl>
                                    <FormControl ref={el => inputScrollToViewRef.current[9] = el} className={Styles.formControl}>
                                          <span className={Styles.label}>
                                            Business Title
                                            <span className={Styles.required}> *</span>
                                          </span>
                                        <CustomInput
                                            placeholder="Enter your business title."
                                            name="rep_user_2_business_title"
                                            value={formData.rep_user_2_business_title}
                                            onChange={(e)=>{handleFormDataChange("rep_user_2_business_title", e.target.value)}}
                                        />
                                        {renderErrorMessage("rep_user_2_business_title")}
                                    </FormControl>
                                    <FormControl ref={el => inputScrollToViewRef.current[10] = el} className={Styles.formControl}>
                                          <span className={Styles.label}>
                                            Job Position
                                            <span className={Styles.required}> *</span>
                                          </span>
                                        <Select
                                            name="rep_user_2_job_position"
                                            value={formData.rep_user_2_job_position}
                                            displayEmpty
                                            onChange={(e)=>{ handleFormDataChange("rep_user_2_job_position", e.target.value) }}
                                            input={<BootstrapInput/>}
                                            inputProps={{ "aria-label": "Without label" }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>Select your job position.</MenuItem>
                                            {renderJobPositionListOptions()}
                                        </Select>
                                        {renderErrorMessage("rep_user_2_job_position")}
                                    </FormControl>
                                    <FormControl ref={el => inputScrollToViewRef.current[11] = el} className={Styles.formControl}>
                                          <span className={Styles.label}>
                                            What is your email address?
                                            <span className={Styles.required}> *</span>
                                          </span>
                                        <CustomInput
                                            placeholder="Enter your email address."
                                            name="rep_user_2_email"
                                            value={formData.rep_user_2_email}
                                            onChange={(e)=>{handleFormDataChange("rep_user_2_email", e.target.value)}}
                                        />
                                        {renderErrorMessage("rep_user_2_email")}
                                    </FormControl>
                                    <FormControl ref={el => inputScrollToViewRef.current[12] = el} className={Styles.formControl}>
                                          <span className={Styles.label}>
                                            What is your phone number?
                                            <span className={Styles.required}> *</span>
                                          </span>
                                        <CustomInput
                                            placeholder="Enter your phone number."
                                            name="rep_user_2_phone_number"
                                            value={formData.rep_user_2_phone_number}
                                            onChange={(e)=>{handleFormDataChange("rep_user_2_phone_number", e.target.value)}}
                                        />
                                        {renderErrorMessage("rep_user_2_phone_number")}
                                    </FormControl>
                                </div>
                            ) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PointOfContact;