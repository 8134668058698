import { Button, CircularProgress } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { duplicateSharedCampaign, getCopyCampaignSetting } from '../../api/globalApi';
import './sharePage.css';

const CampaignSharePage = () => {

    const { campaignId } = useParams();
    const [copySetting, setCopySetting] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        getCopyCampaignSetting({campaignString : campaignId}).then(res => {
            setCopySetting({
                campaign : res.data.data.campaign,
                user : res.data.data.userName,
                success : res.data.success
            });
        });
    },[]);

    const handleCopyCampaign = () => {
        setSubmitting(true);
        duplicateSharedCampaign({campaignString : campaignId}).then(res => {
            if(res.data.success === true){
                window.location.href = "/campaign/details/"+res.data.data.campaignId;
            } else {
                window.showNotification('ERROR','Unable to duplicate the campaign.');
                setSubmitting(false);
            }
        });
    }

    return ( 
        <div className="share_page__wrapper trigger_share">
            <div className="share_page__inner_wrapper">
            {
                !copySetting ?
                <>
                <h5><CircularProgress/> Loading</h5>
                </> : copySetting.success ? 
                <>
                    <h5>{copySetting.user} shared the campaign - "{copySetting.campaign}" with you.</h5>
                    <p>To copy this campaign, please click the button.</p>
                    <Button disabled={submitting} onClick={() => handleCopyCampaign()} className="accent--bg--text--color" size="large" startIcon={<FileCopy/>}>{submitting ? "Please wait" : "Copy Campaign"}</Button>
                </> :
                <>
                    <h5>Sorry! This campaign is already copied or removed.</h5>
                    <h5>Please contact with your provider.</h5>
                </> 
                
            }
                
            </div>
        </div> 
    );
}
 
export default CampaignSharePage;