// import {MenuItem } from '@material-ui/core';
import CustomDate from "./CustomDate";
import Greetings from "./Greetings";
import LeterSameDay from "./LeterSameDay";
import OnLeterDay from "./OnLeterDay";
import Recurring from "./Recurring";
// import '../../../css/FourthStep.css'

export const timeIntervalOptions = (interval, MenuItem) => {
    let times = [],
        tt = 0,
        ap = ['AM', 'PM'];

    for (var i=0;tt<24*60; i++) {
        let hh = Math.floor(tt/60);
        let mm = (tt%60);
        let label = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)];
        let value = (hh > 10 ? hh : "0"+hh) + ':' + ("0" + mm).slice(-2)+":00";
        // times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)];
        times[i] = (<MenuItem value={value} className='dropdownhelper-menuitem-class' >{label}</MenuItem>)
        tt = tt + interval;
    }
    return times;
}

const SendTypeStep = ({activeTab, readyToSend, handleReadyToSendChange, contactInfo, processFor}) => {

    const renderTabContent = () => {
        if(activeTab === 0){
            return ImmediatelySend();
        }
        else if(activeTab === 1){
            return <LeterSameDay readyToSend={readyToSend} handleReadyToSendChange={handleReadyToSendChange} />
        }
        else if(activeTab === 2){
            return <OnLeterDay readyToSend={readyToSend} handleReadyToSendChange={handleReadyToSendChange} />
        }
        else if(activeTab === 3){
            return <CustomDate readyToSend={readyToSend} handleReadyToSendChange={handleReadyToSendChange} />
        }
        else if(activeTab === 4){
            return <Greetings readyToSend={readyToSend} handleReadyToSendChange={handleReadyToSendChange} contactInfo={contactInfo} processFor={processFor}/>
        }
        else if(activeTab === 5){
            return <Recurring readyToSend={readyToSend} handleReadyToSendChange={handleReadyToSendChange} />
        }
        else{
            return ImmediatelySend();
        }
    }

    const ImmediatelySend = () =>{
        return(
            <div className='dmpc_4th_tab_immediate_title' >
                <span>Setting will be triggered immediately after receiving lead</span>
            </div>
        )
    }

    return (
        <div className='dmpc_4th_mc_tab_contanier'>
            {renderTabContent()}
        </div>
    );
}
export default SendTypeStep;