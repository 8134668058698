import React, { useContext, useEffect, useState } from 'react';
import { connect } from "react-redux";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Loading from './assets/Loading';
import { TextField } from '@material-ui/core';
import ImageUploadInput from "./assets/ImageUploadInput";
import CanvaService from './assets/CanvaService'
import { CreateCardTemplate, storeMultipleReducers } from './redux/cardBroadcastAction';
import { DropdownHelper } from './assets/DropdownHelper';
import { CardMultiStepContext } from './CardMultiStepForm';


const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;

const CreateNewCardTemplate = (props) => {

    const cardMultiStepContext = useContext(CardMultiStepContext)

    const ORIENTATION_LANDSCAPE = 'Landscape'
    const ORIENTATION_PORTRAIT = 'Portrait'

    const IMG_SOURCE_TYPE_LOCAL = 1;
    const IMG_SOURCE_TYPE_CANVA = 2;

    const [loadingCategory, setLoadingCategory] = useState(false);
    // const [newTemplateTitle, setNewTemplateTitle] = useState('');
    const [newCategoryName, setNewCategoryName] = useState('');
    // const [newCategoryId, setNewCategoryId] = useState('');
    // const [newCategoryOrientation, setNewCategoryOrientation] = useState('');
    // const [uploadedFile, setUploadedFile] = useState(null);
    // const [orientationList, setOrientationList] = useState([]);
    // const [categoryList, setCategoryList] = useState([]);


    useEffect(()=>{
        if(cardMultiStepContext.messageType !== GIFT){
            if(cardMultiStepContext.messageType === POSTCARD){
                // setOrientationList(props.postcardCategoryList.orientation_types)
                // setCategoryList(props.postcardCategoryList.template_categories)
                // props.storeMultipleReducers({newCategoryId : props.selectedCategoryId,newCategoryOrientation : props.postcardCategoryList.orientation_types[0].id})
                props.storeMultipleReducers({newCategoryId : props.selectedCategoryId})

            }else if(cardMultiStepContext.messageType === GREETING_CARD){
                // setOrientationList(props.greetingCardCategoryList.orientation_types)
                // setCategoryList(props.greetingCardCategoryList.template_categories)
                props.storeMultipleReducers({newCategoryId : props.selectedCategoryId})

            }else if(cardMultiStepContext.messageType === THANKSIO_GREETING_CARD ){
                
                // setOrientationList(props.thanksIoGreetingCardCategoryList.orientation_types)
                // setCategoryList(props.thanksIoGreetingCardCategoryList.template_categories)
                props.storeMultipleReducers({newCategoryId : props.selectedCategoryId})


            }else if(cardMultiStepContext.messageType === LETTER){
                // setOrientationList(props.letterCategoryList.orientation_types)
                // setCategoryList(props.letterCategoryList.template_categories)

            }
            
         }else{
            // setOrientationList(props.greetingCardCategoryList.orientation_types)
            // setCategoryList(props.greetingCardCategoryList.template_categories)

         }

    },[])

    const updateFile = (item)=>{

        props.storeMultipleReducers({uploadedFile :item});
    }
    const handleCategoryDropdownChangeForNewCard = (value) => {
        if (value !== '') {
            props.storeMultipleReducers({newCategoryId :value})
        }
    }

    const handleOrientationDropdownChangeForNewCard = (value) => {
        if (value !== '') {
            props.storeMultipleReducers({newCategoryOrientation :value})
        }
    }


    return (

        <React.Fragment>

            <div className="create-new-templete-row row  create__new__template__row__for__drag">
                <div className="col m12">
                    <div className="create-tem-main-area">
                        <div className="create-main-top">
                            <h2 className="create__new__template__row__for__h2 mb-2">Create New Template</h2>
                            <div className="margin-none-row customGrid">
                                {/* <!-- single item --> */}
                                <div className="customGridItem">
                                    <div className="custom-input deal-select-label">
                                        <label for="lead_name">Card Title</label>
                                        {/* <input type="text" /> */}
                                        <TextField id="standard-basic" value={props.newTemplateTitle} placeholder="Template title" onChange={(event) => props.storeMultipleReducers({newTemplateTitle :event.target.value})} />
                                    </div>
                                </div>
                                {/* <!-- single item --> */}
                                <div className="customGridItem">
                                    <div className="custom-input  deal-select-label">
                                        <label for="lead_nameff">Category</label>
                                        {/* <select name="" id="lead_ffname">
			                                <option value="">Select a category</option>
			                                <option value="">02</option>
			                                <option value="">03</option>
			                            </select> */}
	                                        {!loadingCategory && props.categoryList!=null &&
	                                        <DropdownHelper 
	                                        datalist={props.categoryList}
	                                        noneFieldRequired={true}
	                                        noneFieldTitle="Choose Category"
	                                        className=""
	                                        mapping={{ title: "title" }}
	                                        menuItemClassName=""
	                                        updateSelectedData={handleCategoryDropdownChangeForNewCard}
	                                        selectedValue={props.newCategoryId}
	                                        // updateDatatable={updateDatatable}
	                                        dropdownHeader={'categoryChange'}

	                                     />
                                            // <Select className="send__postcard__create__select"
                                            //     labelId="demo-simple-select-placeholder-label-label"
                                            //     id="demo-simple-select-placeholder-label lead_name"
                                            //     value={newCategoryId}
                                            //     onChange={handleCategoryDropdownChangeForNewCard}
                                            //     displayEmpty
                                            // >
                                            //     <MenuItem value="">
                                            //         <em>Choose Category</em>
                                            //     </MenuItem>
                                            //     {props.categoryList != null &&
                                            //         props.categoryList.template_categories.map((category) => (
                                            //             <MenuItem value={category.id}>{category.title}</MenuItem>
                                            //         ))
                                            //     }
                                            // </Select>
                                        }
                                        {loadingCategory &&
                                            <Loading />
                                        }
                                    </div>
                                </div>
                                {/* <!-- single item --> */}

                                {/* {cardMultiStepContext.messageType !== LETTER && 
                                    <div className="customGridItem">
                                        <div className="custom-input  deal-select-label">

                                            <label for="lead_name">Orientation</label>
                                            {!loadingCategory &&
                                                <DropdownHelper 
                                                    datalist={orientationList}
                                                    noneFieldRequired={true}
                                                    noneFieldTitle="Choose orientation"
                                                    className=""
                                                    mapping={{ title: "title" }}
                                                    menuItemClassName=""
                                                    updateSelectedData={handleOrientationDropdownChangeForNewCard}
                                                    selectedValue={props.newCategoryOrientation}
                                                    dropdownHeader={'orientationChange'}

                                                />
                                            }
                                            {loadingCategory &&
                                                <Loading />
                                            }


                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                        {props.createNewPostcardFrom === 'computer' &&
                            <div className="create-main-btm custom__drag__drop__wr">
                                <h2 className="custom__drag__drop__file_h2">Upload template image</h2>
                                <div className="drag-drop-file custom__drag__drop__file">
                                    <div className="drag-drop-inner custom__drag__drop__file_inner save_post_card_modal_fileUpload">
                                        <div className="custom-file">
                                            <ImageUploadInput
                                                callBack={updateFile}
                                                filesLimit={1}
                                            />
                                            {/* <input type="file" className="custom-file-input" id="customFileLangHTML" /> */}
                                            {/* <label className="custom-file-label" for="customFileLangHTML" data-browse="Bestand kiezen"><img src="images/contact/u2.png" alt="manu Image" /><span className="browse-up"><a href="">Browse File</a></span></label> */}
                                        </div>
                                    </div>
                                    
                                </div>
                                <span>Supports any image file up to 5 MB in size.</span>
                            </div>
                        }
                        {props.createNewPostcardFrom === 'canva' &&
                            <div className="create-main-btm">
                                <h2>Upload template image</h2>
                                {/* <div className="drag-drop-file"> */}
                                    {/* <div className="drag-drop-inner">
                        <div className="custom-file">
                            <ImageUploadInput
                                callBack     = {setUploadedFile}
                                filesLimit   = {1}
                            />
                            <input type="file" className="custom-file-input" id="customFileLangHTML" />
                            <label className="custom-file-label" for="customFileLangHTML" data-browse="Bestand kiezen"><img src="images/contact/u2.png" alt="manu Image" /><span className="browse-up"><a href="">Browse File</a></span></label>
                        </div>
                    </div> */}
                                    <CanvaService
                                        setting_type={cardMultiStepContext.messageType === LETTER ? 'letter' : 'postcard'}
                                        design_type={cardMultiStepContext.messageType === LETTER ? 'Letter' : 'Postcard'}
                                        design_width={cardMultiStepContext.messageType === LETTER ? 1275 : cardMultiStepContext.messageType === POSTCARD ? 1875 : 1800}
                                        design_height={cardMultiStepContext.messageType === LETTER ? 1875 : cardMultiStepContext.messageType === POSTCARD ? 1275 : 1313}
                                        callback={updateFile}
                                        uploadedFile={props.uploadedFile}
                                    />
                                {/* </div> */}
                                {/* <span>Supports any .jpg file up to 5 MB in size.</span> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="massage_send_close_button d-flex justify-content-center align-items-center">
            <button className="custom-next next action-button accent--bg--color" onClick={(e) => window.submitNewTemplate(e)}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                </svg>
                <span>Create Template</span>
            </button>
            <button className="custom-next custom-close modal-close action-button" onClick={(e) => changeCreateNewPostcardFrom(e, '')}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                </svg>
                <span>Close</span>
            </button>
            </div> */}
        </React.Fragment>
    )
}
// }

const mapStateToProps = state => {
    return {
        postcardCategoryList: state.cardBroadcastReducer.postcardCategoryList,
        greetingCardCategoryList: state.cardBroadcastReducer.greetingCardCategoryList,
        letterCategoryList: state.cardBroadcastReducer.letterCategoryList,
        thanksIoGreetingCardCategoryList: state.cardBroadcastReducer.thanksIoGreetingCardCategoryList,
        categoryList: state.cardBroadcastReducer.categoryList,
        createNewPostcardFrom: state.cardBroadcastReducer.createNewPostcardFrom,
        messageType: state.cardBroadcastReducer.messageType,
        uploadedFile: state.cardBroadcastReducer.uploadedFile,
        newTemplateTitle: state.cardBroadcastReducer.newTemplateTitle,
        newCategoryOrientation: state.cardBroadcastReducer.newCategoryOrientation,
        selectedCategoryId: state.cardBroadcastReducer.selectedCategoryId,
        newCategoryId: state.cardBroadcastReducer.newCategoryId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        CreateCardTemplate: (params, payload) => dispatch(CreateCardTemplate(params, payload)),
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCardTemplate);
