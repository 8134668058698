import React from "react";
import Styles from "./Styles.module.css";

const loadingGifImg = "https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/loadr.gif";

const Processing = ({ ...rest }) => {
    return(
        <div className={Styles.processingWrapper} {...rest}>
            <img src={loadingGifImg} alt="loading..."/>
            <h4 className={Styles.processingTitle}>
                Processing Registration
            </h4>
            <p className={Styles.processingMessage}>
                Your Submission has been received and we are processing your registration.
                This process can make several minutes to complete. Please! Do not close the window.
            </p>
        </div>
    );
};

export default Processing;