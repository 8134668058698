import React, { useState } from "react";
import Utils from "../../helpers/Utils";
import SearchLead from "./SearchLead";

const SelectExistingDeal = (props) => {
  const [isContactSelected, setIsContactSelected] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});

  /* For Java End */

  const onSelectContactForElastic = (param = "") => {
    setIsContactSelected(!isContactSelected);
    setSelectedLead({
      fullName: param?.fullName ? param.fullName : "",
      email: param?.email ? param.email : "",
      number: param?.number ? param.number : "",
    });

    if (param) {
      props.handleExistingSelect({ contact_id: param.id });
    }
  };

  return (
    <div className="select-existing-contact-add-deal">
      {/* //Todo: Need to make it required in CSS */}
      {!isContactSelected ? (
        <>
          <h3 className="add__deal__label__title">Search Lead</h3>
          <div className="global_new_deal_search_wr ">
            <span className="global__lead__search__required">*</span>
            <SearchLead
              from="deal-modal"
              onSubmit={onSelectContactForElastic}
            />
          </div>
        </>
      ) : (
        <>
          {!Utils.isEmpty(selectedLead.fullName) && (
            <div className="col m4 s12">
              <h6 className="text-dark-blue m-0 text-left mb-2">Name </h6>
              <input
                type="text"
                defaultValue={selectedLead.fullName}
                readOnly={true}
                className="modal_bordered_text_field radius-5 white"
              />
            </div>
          )}
          {!Utils.isEmpty(selectedLead.email) && (
            <div className="col m8 s12">
              <h6 className="text-dark-blue m-0 text-left mb-2">Email</h6>
              <input
                type="email"
                defaultValue={selectedLead.email}
                readOnly={true}
                className="modal_bordered_text_field radius-5 white"
              />
            </div>
          )}
          {!Utils.isEmpty(selectedLead.number) && (
            <div className="col m4 s12">
              <h6 className="text-dark-blue m-0 text-left mb-2">Phone</h6>
              <input
                type="text"
                defaultValue={selectedLead.number}
                readOnly={true}
                className="modal_bordered_text_field radius-5 white"
              />
            </div>
          )}

          <button className="lead__search__again__btn" onClick={() => setIsContactSelected(!isContactSelected)}>
            Search Again
          </button>
        </>
      )}
    </div>
  );
};

export default SelectExistingDeal;
