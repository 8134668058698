import Helper from "./Helper";
const carrierLookupBackendApi = `${process.env.REACT_APP_CARRIER_LOOKUP_BACKEND_SERVER}/v1`;
const contactBackendApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`;

export const carrierLookupSingleApi = async (body) => {
    return Helper.sendRequestBackend("post", `${carrierLookupBackendApi}/carrier/lookup/single/v1`,body);
}

export const getCarrierLookupCreditSettingsApi = async (body) => {
    return Helper.sendRequest("post", `${contactBackendApi}/carrier-lookup/get-credit-settings`,body);
}