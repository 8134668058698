import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center'
    },
}));

const Loading = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.root} lodar_f_pdf`}>
            <CircularProgress size={20} />
        </div>
    );
}

export default Loading;