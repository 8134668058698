import React,{useState} from 'react'
import CustomDragAndDrop from '../../../CustomDragAndDrop/CustomDragAndDrop';
import { uploadImageFileApi } from '../../../../api/ApiAction';
import Header from './Header';
import AddOption from './AddOption';
import Footer from './Footer';

const UploadView = ({selectDesignStep, handleDataChange, handleStepChange, selectSizeStep, type}) => {
    const [errorMsg, setErrorMsg] = useState('');

    const handleSwitch = () => {
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{isTrue: !selectDesignStep.saveAsTemplate.isTrue}}
        handleDataChange({
            saveAsTemplate : old_data
        })
        setErrorMsg("")
    };

    const handleTitleChange = (event) => {
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{title: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleChangeCategory = (event) =>{
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{category: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleChangeOrientation = (event) =>{
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{orientation: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleAddNewCategory = (event) => {
        let old_data = {...selectDesignStep.saveAsTemplate}
        old_data = {...old_data, ...{new_category_title: event.target.value}}
        handleDataChange({
            saveAsTemplate : old_data
        })
    }

    const handleBack = () => {
        if(selectDesignStep.uploadingFile){
            return
        }
        if(selectDesignStep.selectedCategory == null){
            handleDataChange({from: 'template', templates: [], templateFrom: "agency"})
        }
        else{
            handleDataChange({from: 'template'})
        }
    }

    const goForward = async () => {
        if(selectDesignStep.uploadingFile){
            return
        }
        if(selectDesignStep.uploadData != null || selectDesignStep.uploadUrl !== ""){
            /* todo: check validation */
            if(selectDesignStep.saveAsTemplate.isTrue){
                if(selectDesignStep.saveAsTemplate.title.trim() === ""){
                    setErrorMsg("Please fill up template title !")
                    return
                }
                if(selectDesignStep.saveAsTemplate.category == null){
                    setErrorMsg("Please select a categoty !")
                    return
                }
                if(selectDesignStep.saveAsTemplate.category != null && selectDesignStep.saveAsTemplate.category === "add_new" && selectDesignStep.saveAsTemplate.new_category_title.trim() === ""){
                    setErrorMsg("Please fill up category title !")
                    return
                }
            }
            handleDataChange({uploadingFile: true})
            setErrorMsg("")
            let formData = new FormData();
            formData.append("is_saved", Boolean(selectDesignStep.saveAsTemplate.isTrue));
            formData.append("new_card_title", selectDesignStep.saveAsTemplate.title);
            formData.append("new_card_category_id", selectDesignStep.saveAsTemplate.category);
            formData.append("new_card_category_title", selectDesignStep.saveAsTemplate.new_category_title);
            
            formData.append("source_type", 1);
            formData.append("upload_file_data", selectDesignStep.uploadData);
            formData.append("card_type", type);
            if(type === 'postcard'){
                formData.append("card_size", selectSizeStep.selected_size);
            }
            else{
                formData.append("card_size", null);
            }

            if(type === 'zendirect_gift'){
                formData.append("new_card_orientation", 1);
            }
            else{
                formData.append("new_card_orientation", 1);
            }
            if(selectDesignStep.uploadData == null){
                formData.append("info_update", true);
                formData.append("image_path", selectDesignStep.uploadUrl);
                formData.append("image_thumb_path", selectDesignStep.uploadThumbUrl);
            }

            await uploadImageFileApi(formData).then(res => {
                let response = res.data;
                if(response.status === "success"){
                    if(selectDesignStep.saveAsTemplate.isTrue){
                        handleDataChange({
                            uploadingFile: false,
                            uploadUrl: response.data.image_path,
                            uploadThumbUrl: response.data.image_thumb_path,
                            uploadData: null,
                            selectedCategory: parseInt(response.data.category_id),
                            selectedTemplate: response.data.card_info,
                            categories: [],
                            from: "template",
                            reloadCategory: true
                        })
                    }
                    else{
                        handleDataChange({
                            uploadingFile: false,
                            uploadUrl: response.data.image_path,
                            uploadThumbUrl: response.data.image_thumb_path,
                            uploadData: null
                        })
                    }
                    // handleStepChange(true)
                }else{
                    window.showNotification('Error', response.message);
                    handleDataChange({uploadingFile: false})
                }
            }).catch(error => {
                handleDataChange({uploadingFile: false})
            })
        }
        else{
            setErrorMsg("Please upload your valid image file !")
            return
        }
    }

    const renderCustomDropProps = () => {
        if(type === 'postcard'){
            return {
                ratio: {width: 1875, height: 1275},
                ratioMessage: 'Image file width & height ratio should be 1875 X 1275',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1875 X 1275).'
            };
        }
        else if(type === 'letter'){
            return {
                ratio: {width: 2550, height: 3300},
                ratioMessage: 'Image file width & height ratio should be 2550 X 3300',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (2550 X 3300).'
            };
        }
        else if(type === 'notecard'){
            return {
                ratio: {width: 1800, height: 1313},
                ratioMessage: 'Image file width & height ratio should be 1800 X 1313',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1800 X 1313).'
            };
        }
        else if(type === 'zendirect_gift'){
            if(selectDesignStep.saveAsTemplate.orientation === 1){
                return {
                    ratio: {width: 522, height: 378},
                    ratioMessage: 'Image file width & height ratio should be 522 X 378',
                    hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (522 X 378).'
                };
            }
            else if(selectDesignStep.saveAsTemplate.orientation === 2){
                return {
                    ratio: {width: 378, height: 522},
                    ratioMessage: 'Image file width & height ratio should be 378 X 522',
                    hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (378 X 522).'
                };
            }
            return {
                ratio: {width: 1500, height: 1500},
                ratioMessage: 'Image file width & height ratio should be 1500 X 1500',
                hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1500 X 1500).'
            };
        }
        return {
            ratio: {width: 1875, height: 1275},
            ratioMessage: 'Image file width & height ratio should be 1875 X 1275',
            hints: 'Supports jpg/png type image file up to 5 MB in size. Image should be (1875 X 1275).'
        };
    }

    return(
        <div className='dmpc_templete_upload'>
            <Header 
                flag={selectDesignStep.saveAsTemplate.isTrue}
                onChange={handleSwitch}
            />

            {selectDesignStep.saveAsTemplate.isTrue &&
                <AddOption 
                    selectDesignStep={selectDesignStep}
                    handleTitleChange={handleTitleChange}
                    handleChangeCategory={handleChangeCategory}
                    handleAddNewCategory={handleAddNewCategory}
                    type={type}
                    handleChangeOrientation={handleChangeOrientation}
                    handleDataChange={handleDataChange}
                />
            }

            {errorMsg !== "" &&
            <div style={{color: '#FF264A', fontSize: '14px', marginTop: '10px'}}>{errorMsg}</div>
            }

            <div className='dmpc_templete_upload_drag'>
                <h4 className='dmpc_templete_ud_title'>Upload template image</h4>
                <CustomDragAndDrop 
                    storeImage={handleDataChange}
                    ratio={renderCustomDropProps().ratio}
                    ratioMessage={renderCustomDropProps().ratioMessage}
                    imagePath={selectDesignStep.uploadUrl}
                />
                <span className='dmpc_templete_ud_file_limit'>{renderCustomDropProps().hints}</span>
            </div>

            <Footer 
                handleBack={handleBack}
                selectDesignStep={selectDesignStep}
                goForward={goForward}
            />
        </div>
    );
}
export default UploadView;