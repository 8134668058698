import React from 'react';
import { connect } from 'react-redux';


const STEPNO = 2.5
const NEXTSTEPNO= 3
const SecondHalfStep = (props) => {

    return (
        <React.Fragment>
            <fieldset>
                <div className="create-new-templete-row row">
                    <div className="col m12 create__team__main--area">
                        <div className="create-tem-main-area second__half__selected__template__h2__wr">
                            <div className="create-main-top">
                                <h2 className="second__half__selected__template__h2" >Selected template</h2>
                            </div>
                            <div className="create-main-btm-template second__half__selected__template__wr">
                               <div className="second__half__selected__img">
                               <img src={props.selectedTemplate.image_path} alt="img" />
                               </div>
                                {/* <ImageShow
                                    className=""
                                    isServerImage={false}
                                    imageUrl={props.selectedTemplateImageUrl}
                                    imageAlt="Template Image"
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </React.Fragment>

    )


}


const mapStateToProps = state => {
   return {
      selectedTemplate: state.cardBroadcastReducer.selectedTemplate,

   };
};

export default connect(mapStateToProps, null)(SecondHalfStep);

