import React from "react";
import Styles from "./Style.module.css";

const MessageFlowLearnMore = () => {
    return(
        <div className={Styles.learnMoreInnerWrapper}>
            <section className={Styles.learnMoreSection}>
                <p className={`${Styles.learnMoreDesc} ${Styles.marginBottom5}`}>
                    This field should describe how and when consumers opt-in to the campaign, therefore giving consent to the sender to receive their messages. The call-to-action must be explicitly clear and inform consumers of the nature of the program. If multiple opt-in methods can be used for the same, list them all.
                </p>
                <ul className={Styles.learnMoreDescUl}>
                    <li className={Styles.learnMoreDesc}>Provide website where opt-in occurs.</li>
                    <li className={Styles.learnMoreDesc}>List all opt-in methods.</li>
                    <li className={Styles.learnMoreDesc}>Include an opt-in message if users can text.</li>
                    <li className={Styles.learnMoreDesc}>If a website is provided, it must be accessible with privacy policy and terms.</li>
                    <li className={Styles.learnMoreDesc}>If opt-in occurs behind a gated login or on paper, provide a hosted link of a screenshot or document of the opt-in.</li>
                </ul>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Successful Example:</h5>
                <p className={Styles.learnMoreDesc}>"Customers provide opt-in specifically when enrolling into our website, or in person by requesting SMS capabilities within the store. Opt-in during website is a self-service process and occurs at acme.com/signup. By submitting, you authorize ACME to send text messages with offers and other information. Message/data rates apply. Consent is not a condition of purchase. [Link to terms and conditions]."
                </p>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Failure Example:</h5>
                <p className={Styles.learnMoreDesc}>
                    "Customers sign up"
                    <p>(Where and how the customer provides opt-in is unclear, campaign will be rejected.)</p>
                </p>
            </section>
        </div>
    );
}

export default MessageFlowLearnMore;