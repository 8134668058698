import { sendRequest } from './rootApi';
import Api from "../constants/Api";

// export const fetchUser = async () => {
//     return await sendRequest("get", "https://my-json-server.typicode.com/rislam169/demo/user");
// }

export const getAgencyApiDomain = async (loginCredential) => {
    return sendRequest("get", Api.agencyApiDomain, loginCredential);
}
