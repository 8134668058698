import ActionType from "./ActionType";

export const handleFrom = (payload) => ({
    type: ActionType.HANDLE_FROM,
    payload: payload
});

export const handleTab = (payload) => ({
    type: ActionType.HANDLE_TAB,
    payload: payload
});

export const handleLoading = (payload) => ({
    type: ActionType.HANDLE_LOADING,
    payload: payload
});

export const storeContactId = (payload) => ({
    type: ActionType.STORE_CONTACT_ID,
    payload: payload
});

export const storeContactInfo = (payload) => ({
    type: ActionType.STORE_CONTACT_INFO,
    payload: payload
});

export const storeTotalCampaign = (payload) => ({
    type: ActionType.STORE_TOTAL_CAMPAIGN,
    payload: payload
});

export const storeDealFunction = (payload) => ({
    type: ActionType.STORE_DEAL_CALLBACK,
    payload: payload
});