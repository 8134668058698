import { Chip } from "@material-ui/core";
import { NOTIFICATION_MESSAGE_TYPE, NOTIFICATION_OUTCOME_TYPE } from "../../constants/CoreConstants";

const acceptStyle = {
    border:"1px solid #138613",
    color : "#138613"
}

const OutcomeStatus = ({ notification }) => {

    if(notification.type === NOTIFICATION_MESSAGE_TYPE.CALL) {
        if (notification.outcome_status === NOTIFICATION_OUTCOME_TYPE.ANSWERED) {
            return <Chip style={acceptStyle} className="mb-2" variant="outlined" color="primary" size="small" label="Call Status : Answered"/>
        }
    
        if (notification.outcome_status === NOTIFICATION_OUTCOME_TYPE.MISSED_CALL) {
            return <Chip className="mb-2" variant="outlined" color="secondary" size="small" label="Call Status : Missed Call"/>
        }
    
        if (notification.outcome_status === NOTIFICATION_OUTCOME_TYPE.VOICEMAIL) {
            return <Chip className="mb-2" variant="outlined" color="primary" size="small" label="Call Status : Voicemail"/>
        }
    }

    return null;
}

export default OutcomeStatus
