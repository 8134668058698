import React from "react";
import * as ACTION from "./cardBroadcastActionTypes";

export const followupSave = (payload, callback) => ({
    type: ACTION.SAVE_FOLLOWUP_IN_CARD_BROADCAST,
    payload: {payload, callback}
});

export const updateEmailStepSetting = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_EMAIL_STEP_SETTING_IN_CARD_BROADCAST,
    payload: {payload, callback}
});

export const getCategoryList = (payload, callback) =>({
    type: ACTION.GET_MAIL_CATEGORY_IN_CARD_BROADCAST,
    payload: {payload, callback}
})

export const CreateCardTemplate = (payload,callback)=>({
    type: ACTION.CREATE_POSTCARD_TEMPLATE_IN_CARD_BROADCAST,
    payload: {payload, callback}
})

export const storeCustomFieldsAction = payload => ({
    type: ACTION.SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST,
    payload: payload
});

export const storePostcardCategoryList = payload => ({
    type: ACTION.SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST,
    payload: payload
});

export const storeGreetingcardCategoryList = payload => ({
    type: ACTION.SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST,
    payload: payload
});

export const storeLetterCategoryList = payload => ({
    type: ACTION.SET_LETTER_CATEGORY_IN_CARD_BROADCAST,
    payload: payload
});

export const storeGiftCategoryList = payload => ({
    type: ACTION.SET_GIFT_CATEGORY_IN_CARD_BROADCAST,
    payload: payload
});
export const storeSelectedSize = payload=> ({
    type: ACTION.SET_SELECTED_SIZE,
    payload: payload
})
export const storeHandWrtingId = payload=> ({
    type: ACTION.SET_HANDWRITING_ID,
    payload: payload
})

export const storeThanksIOGiftPrice = payload=> ({
    type: ACTION.SET_THANKSIO_GIFT_PRICE,
    payload: payload
})

export const storeSelectedTemplate = payload=> ({
    type: ACTION.SET_SELECTED_Template,
    payload: payload
})
export const storeSelectedCategoryId = payload=> ({
    type: ACTION.SET_SELECTED_CATEGORY_ID,
    payload: payload
})
export const storeCategoryList = payload=> ({
    type: ACTION.SET_CATEGORY_LIST,
    payload: payload
})
export const storeTemplateList = payload=> ({
    type: ACTION.SET_TEMPLATE_LIST,
    payload: payload
})
export const storeUseThanksIO = payload=> ({
    type: ACTION.SET_USE_THANKISO,
    payload: payload
})

export const storeMultipleReducers = payload=> ({
    type: ACTION.UPDATE_MULTIPLE_REDUCERS,
    payload: payload
})

export const storeStepNo = payload=> ({
    type: ACTION.SET_STEP_NO,
    payload: payload
})

export const storeInitialStates = payload=> ({
    type: ACTION.SET_INITIAL_STATES,
    payload: payload
})

export const getDirectMailPrice = payload=> ({
    type: ACTION.GET_DIRECT_MAIL_PRICE,
    payload: payload
})



