import CustomCircleLoader from "../../../../CustomCircleLoader";
import DirectMailIcons from "../../../../DirectMailIcons";

const Footer = ({
    handleBack,
    selectDesignStep,
    goForward
}) => {
    const renderForwardButtonText = () => {
        if(selectDesignStep.uploadData == null && selectDesignStep.uploadUrl === ""){
            return "Save & Continue";
        }
        if(selectDesignStep.uploadData == null && selectDesignStep.uploadUrl !== ""){
            return "Next";
        }
        if(selectDesignStep.uploadData != null && selectDesignStep.uploadUrl !== ""){
            return "Save & Continue";
        }
        if(selectDesignStep.uploadData != null && selectDesignStep.uploadUrl === ""){
            return "Save & Continue";
        }
    }

    const renderNextButtonClass = () => {
        if(selectDesignStep.uploadData == null && selectDesignStep.uploadUrl === ""){
            return 'global_post_card_disabled_btn'
        }
        return '';
    }

    return (
        <div className='dmpc_tv_right_inner_btn'>
            <button className='dmpc_tv_right_inner_backbtn' onClick={handleBack}>
                <span>{DirectMailIcons.backIcon}</span>
                <span>Go Back To Template List</span>
            </button>
            <button className={`dmpc_tv_right_inner_savebtn ${renderNextButtonClass()}`} onClick={goForward}>
                {selectDesignStep.uploadingFile ?
                    <span className="dmpc_btn_circle_loader"><CustomCircleLoader/></span>
                :
                    <span>{DirectMailIcons.saveContinueIcon}</span>
                }
                <span>{renderForwardButtonText()}</span>
            </button>
        </div>
    );
}
export default Footer;