import selectImg from '../../images/selectImage.png'
import DirectMailIcons from '../../DirectMailIcons';
import DirectMailCardModal from './DirectMailCardModal/DirectMailCardModal';

const CanvaInfoModal = ({open, onClose, onClick}) => {
    return (
        <DirectMailCardModal title="Canva Integration Alert" open={open} onClose={() => onClose(false)}>
            <div className='dmpc_canva_modal_body'>
                <div className='dmpc_canva_modal_body_top'>
                    <h4 className='dmpc_canva_modal_body_title'>Please Note:</h4>
                    <p className='dmpc_canva_modal_body_content'>We use Canva for our designer, you will need to create an account if you don't already have one. Please note if you use any of their stock photography there is a cost of <span>$1</span>.</p>
                </div>
                <h4 className='dmpc_canva_modal_body_important'>Important:</h4>
                <p className='dmpc_canva_modal_body_note'>Only design the front of card, not the back.</p>

                <div className='dmpc_canva_modal_gallery'>
                    <div className='dmpc_canva_modal_g_sigle'>
                        <img src={selectImg} alt={'dfd'} />
                        <span className='dmpc_canva_modal_g_sigle_span'>{DirectMailIcons.checkedImg}</span>
                    </div>
                    <div className='dmpc_canva_modal_g_sigle'>
                        <img src={selectImg} alt={'dfd'} />
                        <span className='dmpc_canva_modal_g_sigle_span'>{DirectMailIcons.closeImg}</span>
                    </div>
                    
                </div>
                <div className='dmpc_canva_modal_g_ok'>
                    <div className='dmpc_canva_modal_g_ok__btn' onClick={onClick}>
                        <span>{DirectMailIcons.checkedIcon}</span>
                        <span>Ok</span>
                    </div>
                </div>
            </div>
        </DirectMailCardModal>
    );
}
export default CanvaInfoModal;