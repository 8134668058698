import { submitDirectMailSendApi } from "./api/ApiAction";
import { DIRECT_MAIL_FOR_CAMPAIGN_SETTING, DIRECT_MAIL_FOR_SEND } from "./Index";

const POSTCARD = 6;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;
const ZENDRECT_GIFT_CARD = 8;

export const SendProcess = (data = {}) => {
    let default_data = {
        contactInfo: null,
        contactId: null,
        selectSizeStep: null,
        selectDesignStep: null,
        typeYourMessageSteps: null,
        readyToSend: null,
        campaignData: null,
        processFor: '',
        handleBack: () => {},
        handleReadyToSendChange: () => {},
        card_type: '',
        price_value: '0.00',
        giftSelect: null
    }
    let data_params = {...default_data, ...data}
    let {
        readyToSend, handleReadyToSendChange
    } = data_params

    if(readyToSend.isSending){
        return;
    }
    let send_type_data = {}
    if(readyToSend.send_type === 1){
        if(readyToSend.hours == null || readyToSend.minutes == null){
            handleReadyToSendChange({errorMsg: "Please fill up Hour and Minutes correctly !"})
            return;
        }
        else{
            send_type_data = {
                hours: readyToSend.hours,
                minutes: readyToSend.minutes
            }
        }
    }
    else if(readyToSend.send_type === 2){
        if(readyToSend.day === "" || readyToSend.at_time == null){
            handleReadyToSendChange({errorMsg: "Please fill up Day and Time correctly !"})
            return;
        }
        else{
            send_type_data = {
                day: readyToSend.day,
                at_time: readyToSend.at_time
            }
        }
    }
    else if(readyToSend.send_type === 3){
        if(readyToSend.at_time == null){
            handleReadyToSendChange({errorMsg: "Please fill up Time correctly !"})
            return;
        }
        else{
            send_type_data = {
                date: readyToSend.date,
                at_time: readyToSend.at_time
            }
        }
    }
    else if(readyToSend.send_type === 4){
        if(readyToSend.greeting == null || readyToSend.at_time == null){
            handleReadyToSendChange({errorMsg: "Please fill up Greeting type and Time correctly !"})
            return;
        }else{
            send_type_data = {
                greeting: readyToSend.greeting,
                at_time: readyToSend.at_time
            }
        }
    }
    else if(readyToSend.send_type === 5){
        if(readyToSend.day === '' || readyToSend.at_time == null){
            handleReadyToSendChange({errorMsg: "Please fill up Day and Time correctly !"})
            return;
        }
        else{
            send_type_data = {
                day: readyToSend.day,
                at_time: readyToSend.at_time
            }
        }
    }
    handleReadyToSendChange({isSending: true, errorMsg: ""})
    hanldeSendProcess(send_type_data, data_params)

}
const hanldeSendProcess = (send_type_data, data_params) => {
    let {
        contactInfo, selectSizeStep, selectDesignStep, typeYourMessageSteps, readyToSend,
        campaignData, processFor, handleBack, handleReadyToSendChange, card_type, price_value,
        contactId, giftSelect
    } = data_params

    /* if needed need to check data validation */
    let card_type_value = getCardTypeValue(card_type)
    let post_card_size = getPostCardSize(card_type, selectSizeStep)

    /* todo: form data */
    let form_data = {}
    form_data['card_type'] = card_type;
    form_data['card_price'] = price_value;
    form_data['post_card_size'] = post_card_size;
    form_data['card_type_value'] = card_type_value;
    form_data['category_id'] = selectDesignStep.selectedCategory;
    form_data['template_id'] = selectDesignStep.selectedTemplate.id;
    form_data['template_url'] = selectDesignStep.selectedTemplate.image_path;
    form_data['template_url_thumbnail'] = selectDesignStep.selectedTemplate.image_thumb_path;
    form_data['message'] = typeYourMessageSteps.message;
    form_data['handwriting_id'] = typeYourMessageSteps.handWritingStyle;
    form_data['message_image_url'] = typeYourMessageSteps.previewImage;
    form_data['message_pdf_url'] = typeYourMessageSteps.pdfUrl;
    form_data['send_type'] = readyToSend.send_type;
    form_data['send_type_value'] = send_type_data;
    form_data['contact_id'] = contactId;

    if(card_type === 'zendirect_gift'){
        form_data['template_orientation'] = selectDesignStep.selectedTemplate.orientation;
        form_data['second_page_message'] = typeYourMessageSteps.message;
        form_data['third_page_message'] = typeYourMessageSteps.thirdPageText;

        let selected_gift = giftSelect.selected
        form_data['gift_product_id'] = selected_gift.gift_product_id;
        form_data['gift_product_name'] = selected_gift.gift_product_name;
        form_data['gift_handling_fee'] = selected_gift.gift_handling_fee;
        form_data['gift_production_id'] = selected_gift.gift_production_id;
        form_data['gift_product_sku'] = selected_gift.gift_product_sku;
        // form_data['gift_price'] = price_value;
        form_data['gift_price'] = selected_gift.gift_price;
        form_data['empty_pdf_file'] = typeYourMessageSteps.emptyPdfFile;
        form_data['gift_product_url'] = selected_gift.gift_image_url;
    }

    if(contactInfo != null && processFor === DIRECT_MAIL_FOR_SEND){
        form_data['birth_date'] = contactInfo.birth_date;
        form_data['anniversary_date'] = contactInfo.anniversary_date;
        if(readyToSend.send_type === 4){
            if(readyToSend.greeting === 1){
                form_data['greeting_date'] = contactInfo.birth_date;
            }
            else{
                form_data['greeting_date'] = contactInfo.anniversary_date;
            }
        }
    }
    else{
        if(processFor === DIRECT_MAIL_FOR_CAMPAIGN_SETTING){
            form_data['greeting'] = readyToSend.greeting;
        }
        else{
            form_data['birth_date'] = null;
            form_data['anniversary_date'] = null;
        }
    }

    if(campaignData !== null){
        form_data['from_campaign_setting'] = true;
        form_data['campaign_id'] = campaignData.campaignId;
        if(campaignData.edit){
            form_data['edit'] = true;
            form_data['campaign_setting_id'] = campaignData.campaignSettingId;
        }
        else{
            form_data['edit'] = false;
        }
        if(card_type === 'zendirect_gift'){
            let selected_gift = giftSelect.selected
            form_data['message_image_url'] = selected_gift.gift_image_url;
        }
    }
    /* new data */
    form_data['file_type'] = getFileType(card_type)

    submitDirectMailSendApi(form_data).then(res => {
        let response = res.data;
        if(response.status === 'success'){
            window.showNotification('SUCCESS', response.message);
            if(localStorage.getItem("pype_postcard_store_data")){
                localStorage.removeItem("pype_postcard_store_data")
            }
            handleBack(null, true)
        }
        handleReadyToSendChange({isSending: false})
    }).catch(error => {
        handleReadyToSendChange({isSending: false})
    })
}

const getCardTypeValue = (type) => {
    if(type === 'postcard'){
        return POSTCARD;
    }
    else if(type === 'letter'){
        return LETTER;
    }
    else if(type === 'notecard'){
        return THANKSIO_GREETING_CARD;
    }
    else if(type === 'zendirect_gift'){
        return ZENDRECT_GIFT_CARD;
    }
    return null;
}
const getFileType = (type) => {
    if(type === 'zendirect_gift'){
        return 'pdf';
    }
    return 'img'
}
const getPostCardSize = (type, data) => {
    if(type === 'postcard'){
        return data.selected_size;
    }
    else if(type === 'letter'){
        return 'letter';
    }
    else if(type === 'notecard'){
        return 'notecard';
    }
    else if(type === 'zendirect_gift'){
        return '5X7'
    }
    return null;
}