import React from "react";
import {resetDialer} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";

const HangedUpState = props => {

    return (
        <div className="hangedup__state">
            <div className="call_ended  d-flex align-items-center flex-column">
                <div className="title_timer d-flex flex-column align-items-center">
                    <p className="call_ended_title">Call Ended </p>
                    <span className="call_time" dangerouslySetInnerHTML={{__html: props.callTime}}/>
                </div>
                <button>
                    <span className="call_end_icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.0908 0H11.8181C11.316 0 10.909 0.407031 10.909 0.909102C10.909 1.41117 11.316 1.8182 11.8181 1.8182H16.8961L7.53893 11.1754C7.18396 11.5304 7.18396 12.106 7.53893 12.461C7.71639 12.6385 7.94904 12.7273 8.1817 12.7273C8.41435 12.7273 8.64705 12.6385 8.82447 12.461L18.1817 3.10383V8.18184C18.1817 8.68391 18.5888 9.09094 19.0908 9.09094C19.5929 9.09094 19.9999 8.68391 19.9999 8.18184V0.909102C19.9999 0.407031 19.5929 0 19.0908 0Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M15.4545 9.09076C14.9525 9.09076 14.5454 9.49779 14.5454 9.99986V18.1817H1.81816V5.45439H10C10.5021 5.45439 10.9091 5.04736 10.9091 4.54529C10.9091 4.04322 10.5021 3.63623 10 3.63623H0.909102C0.407031 3.63623 0 4.04326 0 4.54533V19.0908C0 19.5928 0.407031 19.9999 0.909102 19.9999H15.4546C15.9566 19.9999 16.3637 19.5928 16.3637 19.0908V9.99986C16.3636 9.49779 15.9566 9.09076 15.4545 9.09076Z"
                                fill="#3C7EF3"/>
                        </svg>
                    </span>
                </button>
                <div className="hangedup__state__name">
                    <span className="icon"><i className="fa fa-external-link" /></span>
                    <div className="name"> {typeof props.activeContact.contact_id === 'undefined' ? 'Unknown' : props.activeContact.first_name+' '+props.activeContact.last_name}</div>
                </div>
            </div>
            <div className="call-button call_end_btn d-flex justify-content-center align-items-center">
                <p onClick={(e) => props.resetDialer()}>Done</p>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        activeContact: state.globalDialerReducer.activeContact,
        callTime: state.globalDialerReducer.callTime,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetDialer: () => dispatch(resetDialer()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HangedUpState);