import * as ACTION from "../constants/sendModuleActionTypes";

export const getCustomFieldData = (data=null ,callback) => ({
    
    type: ACTION.FETCH_CUSTOM_FIELD,
    payload: {callback,data}
});

/* Time schedule settings */
export const updateTabValue = payload => ({
    type:ACTION.UPDATE_TAB_VALUE,
    payload: payload
});
export const updateHour = payload => ({
    type:ACTION.UPDATE_HOUR,
    payload: payload
});
export const updateMin = payload => ({
    type:ACTION.UPDATE_MIN,
    payload: payload
});
export const updateDay = payload => ({
    type:ACTION.UPDATE_DAY,
    payload: payload
});
export const updateRecurringDay = payload => ({
    type:ACTION.UPDATE_RECURRING_DAY,
    payload: payload
});
export const updateLaterHour = payload => ({
    type:ACTION.UPDATE_LATER_HOUR,
    payload: payload
});
export const updateLaterMin = payload => ({
    type:ACTION.UPDATE_LATER_MIN,
    payload: payload
});
export const updateTime = payload => ({
    type:ACTION.UPDATE_TIME,
    payload: payload
});
export const updateStartDate = payload => ({
    type:ACTION.UPDATE_START_DATE,
    payload: payload
});
export const updateArrSlotTimeHour1 = payload => ({
    type:ACTION.UPDATE_ARR_SLOT_TIME_HOUR1,
    payload: payload
});
export const updateArrSlotTimeMin1 = payload => ({
    type:ACTION.UPDATE_ARR_SLOT_TIME_MIN1,
    payload: payload
});
export const updateGreetingOption = payload => ({
    type:ACTION.UPDATE_GREETING_OPTION,
    payload: payload
});
export const updateGreetingsOptionTimeMin1 = payload => ({
    type:ACTION.UPDATE_GREETING_OPTION_TIME_MIN1,
    payload: payload
});
export const updateGreetingsOptionTimeHour1 = payload => ({
    type:ACTION.UPDATE_GREETING_OPTION_TIME_HOUR1,
    payload: payload
});
export const updateRecurringTimeHour1 = payload => ({
    type:ACTION.UPDATE_RECURRING_TIME_HOUR1,
    payload: payload
});
export const updateRecurringTimeMin1 = payload => ({
    type:ACTION.UPDATE_RECURRING_TIME_MIN1,
    payload: payload
});
/* Time schedule settings */