const Constants = {
    MESSAGE_TEMPLATE: {
        EMAIL: 1,
        SMS: 2,
        VOICE_MAIL: 3,
        VIDEO_EMAIL: 4,
        MMS: 5,
        POST_CARD: 6,
        GREETING_CARD: 7,
        GIFT_CARD: 8,
        LETTER: 9,
    }
}
export default Constants;