import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Styles from './style.module.css'

const DealConfirmAlert = (props) => {
  let default_params = {
    type: 'warning',
    title: '',
    description: '',
    highlightText: '',
    cancelText: 'No, Keep it',
    submitText: 'Yes, Delete it',
    symbol: 3,
    onCloseClick: () => {},
    onSubmit: () => {},
    closeOnClickOutside: true
  }
  let data_params = {...default_params, ...props}
  let {
    title, description, symbol, closeOnClickOutside, cancelText, submitText, onCloseClick, onSubmit
  } = data_params
  const handleSubmit = (onClose) => {
    onSubmit();
    onClose();
  }
  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={Styles.mainWrp}>
            <div className={`${Styles.bodyWrp} `}>
              <span className={Styles.bodyTitle}>{title}</span>
              
            </div>
            <div className={Styles.footerWrp}>
                <span onClick={() => {handleSubmit(onClose)}} className={`${Styles.button} ${Styles.submitButton}`}>
                  {symbol===1?
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#fff"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"></path><path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"></path></svg>
                  :symbol===0?
                  <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#fff"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"></path><path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z"></path></svg>
                  :''
                }
                  <span className="pl-2">{submitText}</span>
                  
                </span>
              <span onClick={() => { onCloseClick(); onClose();}} className={`${Styles.button} ${Styles.cancelButton}`}>{cancelText}</span>
            </div>
          </div>
        );
      },
      closeOnClickOutside: closeOnClickOutside,
      overlayClassName: Styles.overlay
    })
  )
}
export default DealConfirmAlert;