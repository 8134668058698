import React from "react";
import {
    StyledAskOptionButtonWrapper,
    StyledAskQuestionWrapper,
    StyledConnectEmailButton
} from "./ConnectEmailStyles";

const NylasComplete = ({ goToStep, activeStep }) => {
    return(
        <StyledAskQuestionWrapper>
            <h5>Congratulations! <br/> Personal email already connected.</h5>
            <p>This email will be used to sending emails. You can also add/remove later from settings section</p>
            <StyledAskOptionButtonWrapper>
                <StyledConnectEmailButton onClick={() => goToStep(activeStep+1)}>
                    Complete
                </StyledConnectEmailButton>
            </StyledAskOptionButtonWrapper>
        </StyledAskQuestionWrapper>
    );
};

export default NylasComplete;