import React, {Fragment} from "react";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import {a2pOnBoard} from "./index";
import {hideA2pPopupPermanentlyApi} from "../../api/globalApi";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

const ExitConsent = ({ onClose, setActiveStep }) => {
    const classes = useStyles();

    const handleClose = async () => {
        onClose();

        await hideA2pPopupPermanentlyApi();
    };
    const handleSubmit = () => {

    };


    return(
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={`${Styles.headerTitle} ${Styles.headerTitleCenter}`} variant="h5">A2P/10DLC Registration</Typography>
            </div>

            <div className={Styles.modalBody}>
                <div className={Styles.askConsent}>
                    Based on your answer you are not required to register for A2P/10DLC at this time.
                </div>
                <div>
                    <Button  size="large" onClick={() => {
                        setActiveStep(a2pOnBoard.ASK_REGION)
                    }} className={`${classes.root} ${Styles.overwriteButton} ${Styles.mr1} ${Styles.width140px}`} variant="contained">
                        GO BACK
                    </Button>
                    <Button onClick={handleClose} size="large" className={`${classes.root} ${Styles.redButtonFill} ${Styles.width140px}`} variant="contained">
                        EXIT
                    </Button>
                </div>
            </div>
            <div className={Styles.modalFooter}>
            </div>
        </Fragment>
    );
};

export default ExitConsent;