import React from 'react';
import Modal from '@material-ui/core/Modal';
import Styles from './style.module.css'

const ModalComponent = ({
    isShow, hanldeModal, component, blockBackdrop=false, 
    modalWrapperCustomClass ="", style={}
}) => {
    const toggleDrawer = (open) => (event, reason) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(blockBackdrop && reason === "backdropClick"){
            return;
        }
        if(hanldeModal !== undefined){
            hanldeModal(open)
        }
    };
    let extra_props = {}
    extra_props['disableEnforceFocus'] = true;
    extra_props['disableAutoFocus'] = true;
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`${Styles.modalMainWrp} ${modalWrapperCustomClass}`}
            open={isShow}
            onClose={toggleDrawer(false)}
            closeAfterTransition
            // hideBackdrop={true}
            {...extra_props}
            style={style}
        >
            <div className={Styles.modalBodyWrp}>{component}</div>
        </Modal>
    );
}
export default ModalComponent;