
import axios from "axios";
import _Helper from "../assets/_Helper";
import { getCookie } from "../../../../helpers/Cookie";
import ApiList from "../../redux/ApiList";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const dealRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`

const apiKey = _Helper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) //for production

// const apiKey = "W6GSG6PQ6K5ZCQ1UQT5E" //for development

const getVittualNumbers = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get-virtual-number-by-user',
        data: {
            'api-key': apiKey
        },
    });
}

const getPersonalizedTag = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get-custom-fields',
        data: {
            'api-key': apiKey
        },
    });
}

const getTemplates = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get/sms/template',
        data: {
            'api-key': apiKey
        },
    });
}

const submitSendFromMap = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: rootUrl + '/send-broadcast-for-map',
        data: data
    });
}

const submitSendDefault = (params, formData = false) => {
    // let data = {
    //     ...params,
    //     ...{ 'api-key': apiKey }
    // }
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    if (formData) {
        params.append('api-key', apiKey)
        data = params;
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/contacts/conversation',
        data: data
    });
}

/* for email module */
const getOtherEmailForAContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/get-other-email-by-contact',
        data: data
    });
}

const getProfileData = () => {
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/single/profile',
        data: {
            'api-key': apiKey
        },
    });
}

const submitSendEmailDefault = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/send/bulk-email',
        data: data
    });
}

/* voice send module */
const getVoiceTemplates = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/campaign/voice-template',
        data: {
            'api-key': apiKey
        },
    });
}

const submitSendVoiceDefault = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    if (formData) {
        params.append('api-key', apiKey)
        data = params;
    }

    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/contacts/conversation',
        data: data
    });
}

const sendCallReview = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': _Helper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    if (formData) {
        params.append('api-key', _Helper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        data = params;
    }

    return axios.request({
        method: 'POST',
        url: ApiList.addCallReview,
        data: data
    });
}

const changeContactCallOutcome = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': _Helper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    if (formData) {
        params.append('api-key', _Helper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        data = params;
    }

    return axios.request({
        method: 'POST',
        url: ApiList.changeContactCallOutcome,
        data: data
    });
}

export const image_upload_handler = (blobInfo, success, failure, progress) => {
    const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', emailUploadFileApi);

    xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
    };

    xhr.onload = function () {
        var json;

        if (xhr.status === 403) {
            failure('HTTP Error: ' + xhr.status, { remove: true });
            return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
            failure('HTTP Error: ' + xhr.status);
            return;
        }

        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
        }

        success(json.location);
    };

    xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    formData.append('api-key', getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    xhr.send(formData);
}

const _ApiList = {
    getVittualNumbers,
    getPersonalizedTag,
    getTemplates,
    submitSendFromMap,
    submitSendDefault,
    getOtherEmailForAContact,
    getProfileData,
    submitSendEmailDefault,
    getVoiceTemplates,
    submitSendVoiceDefault,
    image_upload_handler,
    sendCallReview,
    changeContactCallOutcome
}
export default _ApiList;