import FooterButton from "./FooterButton";

const GreetingCardView = ({selectDesignStep, handleStepChange}) => {
    
    const renderImage = () => {
        if(selectDesignStep.selectedTemplate != null){
            return selectDesignStep.selectedTemplate.image_path
        }
        return "";
    }

    const rendetView = () => {
        if(selectDesignStep.selectedTemplate != null){
            if(selectDesignStep.selectedTemplate.orientation === 1){
                return <div className="dmpc_gpr_landscape_i__image__preview" style={{background:`url('${renderImage()}')`}}></div>
            }
            return <div className="dmpc_gpr_portrait_i__image__preview" style={{background:`url('${renderImage()}')`}}></div>
        }
        return (
            <div className="dmpc_gpr_select_landscape_portrait_wr">No template selected !</div>
        );
    }

    return (
        <div className='dmpc_greeting_preview_right'>
            <div className='dmpc_gpr_inner'>
               {rendetView()}
                <FooterButton 
                    selectDesignStep={selectDesignStep}
                    handleStepChange={handleStepChange}
                />
            </div>
        </div>
    );
}
export default GreetingCardView;