import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import * as ACTION from '../constants/notificationActionTypes'
import * as API from "../api/notificationApi";
import Utils from "../helpers/Utils";

function* notificationWatcher() {
    yield takeEvery(ACTION.FETCH_SITE_NOTIFICATION, fetchSiteNotificationsMiddleware);
    yield takeEvery(ACTION.SCROLL_FETCH_SITE_NOTIFICATION, appendSiteNotificationsMiddleware);
    yield takeEvery(ACTION.FETCH_LEAD_NOTIFICATION, fetchLeadNotificationsMiddleware);
    yield takeEvery(ACTION.SCROLL_FETCH_LEAD_NOTIFICATION, appendLeadNotificationsMiddleware);
    yield takeEvery(ACTION.SET_ALL_MARK_AS_SEEN_SITE_NOTIFICATION, setMarkAllAsSeenSiteMiddleware);
    yield takeEvery(ACTION.SET_ALL_MARK_AS_SEEN_LEAD_NOTIFICATION, setMarkAllAsSeenLeadMiddleware);
    yield takeEvery(ACTION.CLOSE_SITE_NOTIFICATION_REQUEST, setCloseSiteNotificationMiddleware);
    yield takeEvery(ACTION.CLOSE_LEAD_NOTIFICATION_REQUEST, setCloseLeadNotificationMiddleware);
    yield takeEvery(ACTION.FETCH_NOTIFICATION_COUNT, fetchNotificationCountMiddleware);
}


function* fetchSiteNotificationsMiddleware (action) {
    try {
        const response = yield call(API.getSiteNotifications, { page : 0 });
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.STORE_SITE_NOTIFICATION, payload: responseData.data});
        } else {
            yield put({type: ACTION.STORE_SITE_NOTIFICATION, payload: []});

            Utils.handleException(responseData.message);
        }
    } catch (error) {
        yield put({type: ACTION.STORE_SITE_NOTIFICATION, payload: []});

        Utils.handleException(error);
    }
}

function* appendSiteNotificationsMiddleware (action) {
    try {
        const { siteNotificationsLoaded, siteNotificationsPage, siteHasScroll } = yield select(state => state.notificationReducer);
        if(siteHasScroll && siteNotificationsLoaded) {
            yield put({type: ACTION.SET_SITE_NOTIFICATION_LOADED, payload: false});
            const response = yield call(API.getSiteNotifications, { page : siteNotificationsPage });
            let responseData = response.data;
            if (responseData.success) {
                yield put({type: ACTION.APPEND_SITE_NOTIFICATION, payload: responseData.data});
            } else {
                Utils.handleException(responseData.message);
            }
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchLeadNotificationsMiddleware (action) {
    try {
        const response = yield call(API.getLeadNotifications, {page:0});
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.STORE_LEAD_NOTIFICATION, payload: responseData.data});
        } else {
            yield put({type: ACTION.STORE_LEAD_NOTIFICATION, payload: []});
            Utils.handleException(responseData.message);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* appendLeadNotificationsMiddleware (action) {
    try {
        const { leadNotificationsLoaded, leadNotificationsPage, leadHasScroll } = yield select(state => state.notificationReducer);
        if(leadHasScroll && leadNotificationsLoaded) {
            yield put({type: ACTION.SET_LEAD_NOTIFICATION_LOADED, payload: false});
            const response = yield call(API.getLeadNotifications, { page : leadNotificationsPage });
            let responseData = response.data;
            if (responseData.success) {
                yield put({type: ACTION.APPEND_LEAD_NOTIFICATION, payload: responseData.data});
            } else {
                Utils.handleException(responseData.message);
            }
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* setMarkAllAsSeenSiteMiddleware (action) {
    try {
        const response = yield call(API.setMarkAllAsSeen, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_ALL_SITE_NOTIFICATION_AS_SEEN, payload: responseData.data});
            window.updateNotificationCount();
        } else {
            Utils.handleException(responseData.message);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* setMarkAllAsSeenLeadMiddleware (action) {
    try {
        const response = yield call(API.setLeadMarkAllAsSeen, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_ALL_LEAD_NOTIFICATION_AS_SEEN, payload: responseData.data});
            window.updateNotificationCount();
        } else {
            Utils.handleException(responseData.message);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* setCloseSiteNotificationMiddleware (action) {
    try {
        yield put({type: ACTION.CLOSE_SITE_NOTIFICATION, payload: action.payload});
        const response = yield call(API.closeSiteNotification, action.payload);
        let responseData = response.data;
        window.updateNotificationCount();
        if (!responseData.success) {
            Utils.handleException(responseData.message);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* setCloseLeadNotificationMiddleware (action) {
    try {
        yield put({type: ACTION.CLOSE_LEAD_NOTIFICATION, payload: action.payload});
        const response = yield call(API.closeLeadNotification, action.payload);
        let responseData = response.data;
        window.updateNotificationCount();
        if (!responseData.success) {
            Utils.handleException(responseData.message);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchNotificationCountMiddleware (action) {
    try {
        const response = yield call(API.getNotificationCount, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.STORE_NOTIFICATION_COUNT, payload: responseData.data});
        } else {
            Utils.handleException(responseData.message);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}


export default function* notificationMiddleware() {
    yield all([
        notificationWatcher()
    ])
}