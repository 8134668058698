import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as ACTION from './videoEmailActionTypes'
import * as VIDEO_EMAIL_API from "./videoEmailApi";
import LinkFile from '../LinkFile';

function* inboxWatcher() {
    yield takeEvery(ACTION.FETCH_VIDEO_FOLDERS, fetchVideoFoldersFlow);
    yield takeEvery(ACTION.FETCH_VIDEOS, fetchVideosFlow);
    yield takeEvery(ACTION.DELETE_VIDEOS, deleteVideosFlow);
    yield takeEvery(ACTION.FETCH_MESSAGE_TEMPLATES, fetchMessageTemplatesFlow);
}

function* fetchVideoFoldersFlow(action){
    const { payload } = action;
    try {
        const response = yield call(VIDEO_EMAIL_API.fetchVideoFolders, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEO_FOLDERS, payload: responseData.data});
        }

    } catch (error) {
        console.log('error', error);
        LinkFile.Utils.handleException(error);
    }
}

function* fetchVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(VIDEO_EMAIL_API.fetchVideos, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEOS, payload: {videos: responseData.data, s3ServerVideoUrl: responseData.s3ServerVideoUrl, videoLoader: false}});
        }
    } catch (error) {
        LinkFile.Utils.handleException(error);
    }
}

function* deleteVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(VIDEO_EMAIL_API.deleteVideo, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        LinkFile.Utils.handleException(error);
    }
}

function* fetchMessageTemplatesFlow(action){
    try {
        const response = yield call(VIDEO_EMAIL_API.getMessageTemplate, action.payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({ type: ACTION.STORE_MESSAGE_TEMPLATE, payload: responseData.html });
        }
    } catch (error) {
        LinkFile.Utils.handleException(error);
    }
}

export default function* loginMiddleware() {
    yield all([
        inboxWatcher(),
    ])
}