import { makeStyles } from "@material-ui/core/styles"

export const StripedProgressStyles = makeStyles(() => ({
  progressContainer: {
    width: "100%",
    backgroundColor: "#f0f0f0",
    height: "30px",
    borderRadius: "4px",
    overflow: "hidden",
  },
  progressBar: {
    position: "relative",
    height: "100%",
    backgroundColor: "#316AFF",
    backgroundImage:
      "linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)",
    backgroundSize: "2rem 2rem",
    transition: "width 0.1s ease-in-out",
    textAlign: "center",
    lineHeight: "30px",
    color: "#fff",
  },
  progressText: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    fontWeight: 600,
  },
  "@keyframes progress-bar-stripes": {
    from: {
      backgroundPosition: "0 0",
    },
    to: {
      backgroundPosition: "2rem 0",
    },
  },
  progressRunner: {
    animation: "$progress-bar-stripes 2s linear infinite",
  },
}))
