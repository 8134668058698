import React, {useEffect, useState} from "react";
import GlobalVoiceSendModule from "../SendComponents/Voice";

const VoiceSendInitialState = {
    /* props data */
    contactId: null,
    id: null,
    contactInfo: null,
    from: '',
    sendSuccessCallBack: () => {},
    fetchContactDetails: false,
    open:false
};
/* 
open={showVoiceSendModal}
onClose={() => setShowVideoSendModal(false)}
id={detailStates.contactDetails.id}
from={"deal"}
fetchContactDetails={false}
*/

const VoiceSendModule = (props) => {
    const [voiceSendState, setVoiceSendState] = useState(VoiceSendInitialState);
    const [isOpenVoiceSendModal, setIsOpenVoiceSendModal] = useState(false);

    // window.handleGlobalVoiceSendModal = info => {
    //     if (info.open) {
    //         setVoiceSendState({
    //             ...voiceSendState,
    //             contactId: info.id,
    //             id: info.id,
    //             contactInfo: typeof info.contactInfo === 'undefined' ? voiceSendState.contactInfo : info.contactInfo,
    //             from: info.from,
    //             sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? voiceSendState.sendSuccessCallBack : info.sendSuccessCallBack,
    //             fetchContactDetails: typeof info.fetchContactDetails === 'undefined' ? voiceSendState.fetchContactDetails : info.fetchContactDetails
    //         });
    //         setIsOpenVoiceSendModal(true);
    //     } else {
    //         setIsOpenVoiceSendModal(false);
    //         setVoiceSendState(VoiceSendInitialState);
    //     }
    // }

    useEffect(()=>{
        let info = props.info;
            setVoiceSendState({
                ...voiceSendState,
                contactId: info.id,
                id: info.id,
                contactInfo: typeof info.contactInfo === 'undefined' ? voiceSendState.contactInfo : info.contactInfo,
                from: info.from,
                sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? voiceSendState.sendSuccessCallBack : info.sendSuccessCallBack,
                fetchContactDetails: typeof info.fetchContactDetails === 'undefined' ? voiceSendState.fetchContactDetails : info.fetchContactDetails,
                open:true
            });
    },[])

    const closeVoiceSendModal = () => {
        props.closeModal()
        // setVoiceSendState(VoiceSendInitialState);
        // setIsOpenVoiceSendModal(false);
    }

    return (
        <React.Fragment>
            {voiceSendState.open &&
                <GlobalVoiceSendModule
                    open={voiceSendState.open}
                    onClose={closeVoiceSendModal}
                    {...voiceSendState}
                />
            }
        </React.Fragment>
    );
}
export default VoiceSendModule;