import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { timeIntervalOptions } from './SendTypeStep';

const Recurring = ({readyToSend, handleReadyToSendChange}) => {

    return(
        <div className='dmpc_4th_tab_day'>
            <div className='dmpc_4th_tab_day_inner'>
                <div className='dmpc_4th_tab_day_hour'>
                    <div className='dmpc_4th_tab_day_hour_inner'>
                        <span className='dmpc_4th_tab_day_hour_title'> Followup Sequence Repeat Every</span>
                        <input 
                            className='dmpc_4th_middle_dateInput day_select_formControll_97'
                            value={readyToSend.day}
                            placeholder={'Day'}
                            onChange={(e) => handleReadyToSendChange({day: e.target.value}, true)}
                        />
                        <span className='dmpc_4th_tab_day_hour_title'>Days</span>
                    </div>
                    <span className='dmpc_4th_tab_day_range_left52'>Day range between 1 to 1000</span>
                </div>

                <div className='dmpc_4th_tab_day_hour'>
                    <div className='dmpc_4th_tab_day_hour_inner'>
                        <span className='dmpc_4th_tab_day_hour_title'>At</span>
                        <FormControl className="day_select_formControll day_select_formControll_97">
                            <Select
                                value={readyToSend.at_time} 
                                IconComponent={ExpandMoreIcon}
                                onChange={(e) => handleReadyToSendChange({at_time: e.target.value})}
                                className={"day_select_sf"}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={null} className='dropdownhelper-menuitem-class' disabled >Time</MenuItem>
                                {timeIntervalOptions(15, MenuItem)}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Recurring;