import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DirectMailCardHeader from '../components/DirectMailCardHeader';
import DirectMailSteps from '../components/DirectMailSteps';
import { useParams } from 'react-router';
import PostcardContent from './PostcardContent';
import { getCardPriceApi } from '../api/ApiAction';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
}));
const postcard_steps = [
    {title: 'Select Size'},
    {title: 'Select Design'},
    {title: 'Type your Message'},
    {title: 'Ready to Send'}
];
const POSTCARD = 6;

const PostCardApp = ({handleBack, from, processFor, campaignData}) => {
    const classes = useStyles();
    const { contactId } = useParams();
    const [priceInfo, setPriceInfo] = useState({
        "6_4x6": "0.59",
        "6_6x9": "0.89"
    })

    const [states, setStates] = useState({
        current_steps: 0,
        completed_steps: [],
        goingForward: false,
        isLoading: false,
        lastCompletedStep: 0
    })

    useEffect(() => {
        getCardPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getCardPrice = () => {
        getCardPriceApi({messageType: POSTCARD})
        .then(res => {
            let response = res.data;
            if(response.status === "success"){
                if(JSON.stringify(response.data) === "[]"){
                    setPriceInfo({
                        "6_4x6": "0.59",
                        "6_6x9": "0.89"
                    })
                }
                else{
                    setPriceInfo(response.data)
                }
            }
            else{
                setPriceInfo({
                    "6_4x6": "0.59",
                    "6_6x9": "0.89"
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    const handleChangeStates = (payload) => {
        setStates({...states, ...payload})
    }

    const handleChangeStep = (value) => {
        if(parseInt(value) > states.lastCompletedStep){
            handleChangeStates({current_steps: parseInt(value), lastCompletedStep: parseInt(value)})
        }
        else{
            handleChangeStates({current_steps: parseInt(value)})
        }
    }

    return(
        <div className='dmpc__wr'>
            <DirectMailCardHeader title={"Postcard"} price={priceInfo['6_4x6']} onClose={handleBack} from={from} />

            <div className={`${classes.root} dmpc__setper_wr`}>
                <DirectMailSteps 
                    steps={postcard_steps}
                    completed_steps={states.completed_steps}
                    lastCompletedStep={states.lastCompletedStep}
                    current_step={states.current_steps}
                    // onclick={(value) => {}}
                    onclick={(value) => {
                        if(states.lastCompletedStep >= parseInt(value)){
                            handleChangeStep(value)
                        }
                    }}
                />
                <PostcardContent 
                    current_step={states.current_steps} 
                    completed_steps={states.completed_steps} 
                    onChangeStep={handleChangeStep}
                    contactId={contactId}
                    handleBack={handleBack}
                    priceInfo={priceInfo}
                    processFor={processFor}
                    campaignData={campaignData}
                    lastCompletedStep={states.lastCompletedStep}
                    handleChangeStates={handleChangeStates}
                />
             </div>
        </div>
    )
}
export default PostCardApp;