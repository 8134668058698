import React, {useState, useEffect} from "react";
import {getContacts, removeActiveContact, searchContacts, putAllContactInformation, storeMultipleReducers} from "../../../actions/globalDialerAction";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {connect} from "react-redux";
import Utils from "../../../helpers/Utils";
import If, {Else} from 'if-else-react'
import ListSkeleton from "../../common/Skeletons/ListSkeleton";
import { searchContactsFromElastic } from "../../../api/globalApi";

//For typing interval while searching
let typingTimer;
let doneTypingInterval = 1000;

const RecentContacts = props => {
    const [recentContacts, setRecentContacts] = useState(props.recentContacts);
    const [activeContact, setActiveContact] = useState(props.activeContact);
    const [searchKey, setSearchKey] = useState(props.initialContactNumber);
    const [loading, setLoading] = useState(props.recentContactLoading);

    // useEffect(() => {
    //     if (!recentContacts.length) {
    //         props.getContacts(props.initialContactNumber);
    //     }
    // }, []);

    useEffect(() => {
        if(props.initialContactNumber !== ''){
            try{
                setSearchKey(props.initialContactNumber.replace(/[#,%,$,!]/g, ''));
            }
            catch(e){
                setSearchKey('')
            }
            props.searchContacts({searchKey:  props.initialContactNumber,pageNo: 1, searchColumn: ['number']});
        }
        
    }, [props.updateInitialContact]);

    useEffect(() => {
        if (!props.recentContacts.length) {
            if(props.initialContactNumber !== '' || searchKey !== ''){
                setRecentContacts(props.recentContacts);

                // props.getContacts({searchKey:  props.initialContactNumber,pageNo: 1, searchColumn: ['number']});

            }else{
                // if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic' && Utils.getAccountData('userId') === 1){
                if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){

                    searchUsingElastic()

                }
                //Elastic
                else {
                    props.getContacts({searchKey:  props.initialContactNumber,pageNo: 1, searchColumn: ['text','number']});
                }
            }
        } else {
            setRecentContacts(props.recentContacts);
        }
    }, [props.recentContacts]);

    useEffect(() => {
        setActiveContact(props.activeContact);
    }, [props.activeContact]);

    useEffect(() => {
        setLoading(props.recentContactLoading);
    }, [props.recentContactLoading]);

    const getAvatar = (name) => {
        return name.length ? name.charAt(0) : 'U';
    }

    const searchRecentContacts = (value) => {
        setSearchKey(value.replace(/[#,%,$,!]/g, ''));
        clearTimeout(typingTimer);

        if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){
            typingTimer = setTimeout(() => { searchUsingElastic(value)},doneTypingInterval)

               
        }else {
            if(Utils.checkLetterExistInText(value) && Utils.checkNumberExistInText(value)){
                typingTimer = setTimeout(() => {props.searchContacts({searchKey: value, pageNo: 1, searchColumn: ['text']})}, doneTypingInterval);
            }else if(Utils.checkNumberExistInText(value)){
                typingTimer = setTimeout(() => {props.searchContacts({searchKey: value, pageNo: 1, searchColumn: ['number']})}, doneTypingInterval);

            }else {
                typingTimer = setTimeout(() => {props.searchContacts({searchKey: value, pageNo: 1, searchColumn: ['text']})}, doneTypingInterval);

            }
        }
    }

    const searchUsingElastic= (searchKey="")=>{
        props.putAllContactInformation({recentContactLoading:true})

        searchContactsFromElastic('GET',{},"?page=1&size=10&search="+searchKey+"&dialer=true")
        .then(response => {
        if (!response) {
            window.showNotification('ERROR', 'Something went wrong')
        
        }
        if(response.status === 'SUCCESS'){
            props.putAllContactInformation({
                recentContacts : response.data.contactInfos,
                contactSearchPageNo : response.data.currentPage,
                contactSearchLastPage : response.data.lastPage,
                // activeContact: activeContact,
                recentContactLoading: false
            })
        }
        
        })
        .catch(e=>{
            console.log('error : '+e);
        });
    }

    //For Elastic
    const renderRecentContact = () => {
        if (recentContacts.length) {
            return recentContacts.map((contact, i) => {

                let number = !Utils.isEmpty(contact.number) ? contact.number : ''

                if(number !== ''){
                    if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){

                        let firstName = !Utils.isEmpty(contact.firstName) ? contact.firstName : !Utils.isEmpty(contact.first_name) ? contact.first_name : ''
                        let lastName = !Utils.isEmpty(contact.lastName) ? contact.lastName : !Utils.isEmpty(contact.last_name) ? contact.last_name :  ''
                        return (
                            
                            <li key={i} className="d-flex justify-content-start align-items-center" onClick={(e) =>{
                                // props.selectActiveContact({...contact,...{'first_name': firstName,'last_name': lastName, contact_id: contact.id
                                // }});
                                props.storeMultipleReducers({listCurrentIndex : i ,activeContact : {...contact,...{'first_name': firstName,'last_name': lastName, contact_id: contact.id
                            }}})
                                }
                                }
                                >
                                <span className="d-flex justify-content-center align-content-center avatar">{getAvatar(firstName+lastName)}</span>
                                <div className="person-info ml-3">
                                    {/* {(contact.first_name+contact.last_name).length > 0 && */}
                                        <p className="name">{firstName+' '+lastName}</p>
                                    {/* } */}
                                    {(firstName+lastName).length === 0 &&
                                        <p className="name">Unknown</p>
                                    }
                                    <p className="contact-number">{Utils.formatNumber(number)}</p>
                                </div>
                            </li>
                        );
                    }else{
                        return (
                            <li key={i} className="d-flex justify-content-start align-items-center" onClick={(e) => 
                                props.storeMultipleReducers({activeContact : {...contact,...{'first_name': contact.first_name,'last_name': contact.last_name, contact_id: contact.id
                            }}})
                            }>
                                <span className="d-flex justify-content-center align-content-center avatar">{getAvatar(contact.first_name+contact.last_name)}</span>
                                <div className="person-info ml-3">
                                    {(contact.first_name+contact.last_name).length > 0 &&
                                        <p className="name">{contact.first_name+' '+contact.last_name}</p>
                                    }
                                    {(contact.first_name+contact.last_name).length === 0 &&
                                        <p className="name">Unknown</p>
                                    }
                                    <p className="contact-number">{Utils.formatNumber(contact.number)}</p>
                                </div>
                            </li>
                        );
    
                    }
                }else{
                    return null;
                }
                
                
            });
        } else {
            return <div className='contact__not__found'><span>No contact found!</span></div>
        }

    }

    // const renderRecentContact = () => {
    //     if (recentContacts.length) {
    //         return recentContacts.map((contact, i) => {
    //             return (
    //                 <li key={i} className="d-flex justify-content-start align-items-center" onClick={(e) => props.selectActiveContact(contact)}>
    //                     <span className="d-flex justify-content-center align-content-center avatar">{getAvatar(contact.first_name+contact.last_name)}</span>
    //                     <div className="person-info ml-3">
    //                         {(contact.first_name+contact.last_name).length > 0 &&
    //                             <p className="name">{contact.first_name+' '+contact.last_name}</p>
    //                         }
    //                         {(contact.first_name+contact.last_name).length === 0 &&
    //                             <p className="name">Unknown</p>
    //                         }
    //                         <p className="contact-number">{Utils.formatNumber(contact.number)}</p>
    //                     </div>
    //                 </li>
    //             );
    //         });
    //     } else {
    //         return <div className='contact__not__found'><span>No contact found!</span></div>
    //     }

    // }

    const scrollContacts=(e)=>{
        e.preventDefault();
        if(!loading && Math.round(e.target.scrollTop + e.target.clientHeight, 20) >= Math.round(e.target.scrollHeight, 10)){
            // props.getContacts(props.initialContactNumber);
        }
    }

    return (
        <React.Fragment>
            <div className="dial-search-wrapper">
                <SearchIcon />
                <input type="text" value={searchKey} onChange={(e) => searchRecentContacts(e.target.value)} placeholder="Search Contact" readOnly={typeof activeContact.contact_id !== 'undefined'}/>
            </div>
            <div id="contact-list">
                <If condition={typeof activeContact.contact_id === 'undefined'}>
                    <div className="list-all-contact">
                        <h5>Listing all contacts</h5>
                        <div className="contact-info" >
                            <ul className="awesome__scroll_bar" onScroll={(e)=>scrollContacts(e)}>
                                <If condition={loading}>
                                    <ListSkeleton number={4} width='100%'/>
                                <Else />
                                    {renderRecentContact()}
                                </If>
                            </ul>
                        </div>
                    </div>
                <Else />
                    <div className="selected__contact">
                        <div className="contactList">
                            <small>Click on the call icon below to contact this person</small>
                            <div className="detail-box">
                                <span className="img-circle contact-info">
                                    <div className="avatar">
                                        <span style={{'width': '100%'}}>{getAvatar(activeContact.first_name+activeContact.last_name)}</span>
                                        {/*<div className="avatar_img"></div>*/}
                                    </div>
                                </span>
                                <div className="user-info">
                                    {(activeContact.first_name+activeContact.last_name).length > 0 &&
                                    <p>{activeContact.first_name+' '+activeContact.last_name}</p>
                                    }
                                    {(activeContact.first_name+activeContact.last_name).length === 0 &&
                                    <p>Unknown</p>
                                    }

                                    <span>{Utils.formatNumber(activeContact.number)}</span>
                                </div>
                                <div className="contact__remover" onClick={(e) => props.removeActiveContact()}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </If>

            </div>
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        recentContacts: state.globalDialerReducer.recentContacts,
        recentContactLoading: state.globalDialerReducer.recentContactLoading,
        activeContact: state.globalDialerReducer.activeContact,
        initialContactNumber: state.globalDialerReducer.initialContactNumber,
        contactSearchPageNo: state.globalDialerReducer.contactSearchPageNo,
        contactSearchLastPage: state.globalDialerReducer.contactSearchLastPage,
        updateInitialContact: state.globalDialerReducer.updateInitialContact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getContacts: (initialContact) => dispatch(getContacts(initialContact)),
        // selectActiveContact: (contact) => dispatch(selectActiveContact(contact)),
        removeActiveContact: () => dispatch(removeActiveContact()),
        searchContacts: (searchKey) => dispatch(searchContacts(searchKey)),
        putAllContactInformation: (data) => dispatch(putAllContactInformation(data)),
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentContacts);