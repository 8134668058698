import React, {useState, useEffect} from "react";
import {updateCallInProgressStatus, updateCallTime, updateCallInfo} from "../../../actions/globalDialerAction";
import If, {Else} from 'if-else-react';
import {connect} from "react-redux";
import SecondNumberPad from "./SecondNumberPad";
import RecentContacts from "./RecentContacts";
import SecondCallStage from "./SecondCallStage";
import SecondDialTrigger from "./SecondDialTrigger";
import SecondCallingState from "./SecondCallingState";

const SecondCall = props => {
    const [callOnScreen, setCallOnScreen] = useState(props.callOnScreen);
    const [secondCallStage, setSecondCallStage] = useState(props.secondCallStage);

    useEffect(() => {
        setCallOnScreen(props.callOnScreen);
    }, [props.callOnScreen]);

    useEffect(() => {
        setSecondCallStage(props.secondCallStage);
    }, [props.secondCallStage]);

    return (
        <div className={"second__call " + (callOnScreen === 'second' ? 'active' : '')}>
            <If condition={props.isSecondCallInProgress}>
                <SecondCallingState muteMyself={props.muteMyself}/>
            <Else />
                <div>
                    <If condition={secondCallStage === 'dial'}>
                        <SecondNumberPad/>
                    </If>

                    <If condition={secondCallStage === 'contact'}>
                        <RecentContacts/>
                    </If>

                    <div className="row pt-4 my-4">
                        <SecondDialTrigger/>
                    </div>
                </div>
            </If>

            <div className="options-icon-list">
                <SecondCallStage />
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        secondCallStage: state.globalDialerReducer.secondCallStage,
        callOnScreen: state.globalDialerReducer.callOnScreen,
        isSecondCallInProgress: state.globalDialerReducer.isSecondCallInProgress
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCallInProgressStatus: (params) => dispatch(updateCallInProgressStatus(params)),
        updateCallTime: (params) => dispatch(updateCallTime(params)),
        updateCallInfo: (params) => dispatch(updateCallInfo(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondCall);
