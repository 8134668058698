import { Skeleton } from '@material-ui/lab';
import React from 'react'

export const RenderSkeleton=()=> {

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 4; i++) {
            skeletons.push(
                <div key={i} className="skeleton_each_notification">
                    <Skeleton
                        className="skeleton_notification_edit_icon"
                        variant="circle"
                        width={30}
                        height={30}
                    />
                    <div className="skeleton_notification_details">
                        <Skeleton variant="rect" width={200} height={10} style={{marginBottom:5}} />
                        <Skeleton variant="rect" width={400} height={20} />
                    </div>
                   
                </div>
            );
        }
        return skeletons;
    };
  return (
    <div>
        {loadingSkeleton()}
    </div>
  )
}
