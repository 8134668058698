import {useContext, useEffect, useState} from 'react';
import ApiList from '../assets/ApiList';
import { updateFileTypeFlag, updateIsShowQuickReplyFlag, updateTemplateData } from '../reducers/Action';
import { VoiceSendContext } from '../reducers/GlobalReduers';
import CheckIcon from '@material-ui/icons/Check';
import LinkFile from '../LinkFile';

const QuickReplies = (props) => {
    const {states, dispatch} = useContext(VoiceSendContext)
    const [isLoading, setIsLoading] = useState(true)
    const [templates, setTemplates] = useState([])

    /* for fetching data onscroll */
    const [perPage, setPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        if(props.scrolling){
            ApiList.getVoiceTemplates({
                search_key: "",
                type: 3,
                'perPage': perPage,
                'page': currentPage
            }).then(res => {
                let response = res.data
                if(response.status === 'success'){
                    if(response.html.length >= perPage){
                        if(props.updateScrollOption !== undefined){
                            props.updateScrollOption("needToLoad", true)
                        }
                        setCurrentPage(currentPage + 1)
                    }
                    else{
                        if(props.updateScrollOption !== undefined){
                            props.updateScrollOption("needToLoad", false)
                        }
                    }
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("fetchingMore", false)
                    }
                    setTemplates([...templates, ...response.html])
                }
            })
        }
    }, [props.scrolling])

    useEffect(() => {
        // get all email template
        ApiList.getVoiceTemplates({
            search_key: "",
            type: 3,
            'perPage': perPage,
            'page': currentPage
        }).then(res => {
            let response = res.data
            if(response.status === 'success'){
                setTemplates(response.html)

                if(response.html.length >= perPage){
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("needToLoad", true)
                    }
                    setCurrentPage(currentPage + 1)
                }
                else{
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("needToLoad", false)
                    }
                }
                if(props.updateScrollOption !== undefined){
                    props.updateScrollOption("fetchingMore", false)
                }
            }
            setIsLoading(false)
        })
        return () => setIsLoading(true)
    }, [])

    const handleIsShowTemplate = () => {
        dispatch(updateIsShowQuickReplyFlag(false))
    }

    const hanldeTemplateChange = (item) => {
        dispatch(updateFileTypeFlag('TEMPLATE'))
        if(item.template_files !== undefined && item.template_files != null){
            dispatch(updateTemplateData({
                data: item?.template_files?.file,
                id: item.template_file_id,
                flag: 'template_file'
            }))
        }
        else{
            dispatch(updateTemplateData({
                data: item?.video_file_url,
                id: item.id,
                flag : 'template'
            }))
        }
        handleIsShowTemplate()
    }

    const renderList = () => {
        let list = []
        templates.map((item, index) => {
            list.push(
                <LinkFile.qucikReplyCard
                    data={item}
                    key={index}
                    onSelect={hanldeTemplateChange}
                />
            )
            // list.push(
            //     <div className="g-template-each-voice" key={index}>
            //         <span className="g-template-each-title-voice">{item.title}</span>
            //         <div className="g-template-file-view">
            //             <audio className="g-template-file" controls src={item.template_files?.file} />
            //             <span className="g-template-each-select" onClick={() => hanldeTemplateChange(item)}><CheckIcon /> Use</span>
            //         </div>
            //     </div>
            // )
        });
        return list;
    }

    return(
        <div className="g-email-template-content">
            <div className="g-template-header">
                <span className="g-template-title">Email Quick Reply List</span>
                <div className="g-template-back" onClick={handleIsShowTemplate}> {'<<'} Back</div>
            </div>
            <div className={`g-template-body awesome__scroll_bar ${isLoading ? 'isGlobalLoading' : ''}`}>
                {isLoading ? 
                    <div className="global_loader_parent g-loading-content">Please wait...</div>
                :
                    renderList()
                }
            </div>
        </div>
    )
}
export default QuickReplies;


