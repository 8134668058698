const Header = () => {
    return (
        <div className='dmpc_4th_middle_top'>
            <div className='dmpc_4mt_left'>
                <h4 className='dmpc_4mt_left_title'>Choose when you to send this followup</h4>
                {/* <h5 className='dmpc_4mt_left_subtitle'>Select a handwriting style and customize your message below.</h5> */}
            </div>
        </div>
    );
}
export default Header;