export const US_STATE_LIST = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District Of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];
  
  export const PHONE_STATE = {
    mobile: "mobile",
    landline: "landline",
    voip: "voip",
    unknown: "unknown"
  };
  
  export const PHONE_TYPES = [
    {
      value: PHONE_STATE.mobile,
      label: "Mobile"
    },
    {
      value: PHONE_STATE.landline,
      label: "Landline"
    },
    {
      value: PHONE_STATE.voip,
      label: "Voip"
    },
    {
      value: PHONE_STATE.unknown,
      label: "Unknown"
    }
  ];
  
  export const LEAD_TYPES = [
    {
      value: "CLIENT",
      label: "Client"
    },
    {
      value: 'VENDOR',
      label: "Vendor"
    },
    {
      value: 'LEAD',
      label: "Lead"
    }
  ];

export const LOOKUP_STATUS = {
    INVALID: "INVALID",
    VALID: "VALID",
    NOT_VERIFIED: "NOT_VERIFIED",
    CHECK_DNC: "CHECK_DNC",
    DO_NOT_CALL: "DO_NOT_CALL",
    VERIFIED_OK: "VERIFIED_OK",
    VERIFYING: "VERIFYING"
};

const VALIDATION_STATUS_NOT_VERIFIED = 'NOT_VERIFIED';
const VALIDATION_STATUS_VALID = 'VALID';
const VALIDATION_STATUS_INVALID = 'INVALID';
const VALIDATION_STATUS_VERIFYING = 'VERIFYING';

const NUMBER_VALIDATION_TYPE_CALLER_NAME = 'CALLER_NAME';
const NUMBER_VALIDATION_TYPE_CARRIER = 'CARRIER';
const NUMBER_VALIDATION_TYPE_DO_NOT_CALL = 'DO_NOT_CALL';
const EMAIL_VALIDATION_TYPE_CALLER_NAME = 'VERIFY_EMAIL';
const SKIP_TRACING_TYPE_PERSON_SEARCH = 'SKIP_TRACING_PERSON_SEARCH';

export const LOOKUP_PROVIDER_TYPE_NUMBER = 1;
export const LOOKUP_PROVIDER_TYPE_EMAIL = 2;

export const LOOKUP_TYPE_EMAIL_LOOKUP = "EMAIL_LOOKUP";
export const LOOKUP_TYPE_CARRIER_LOOKUP = "CARRIER_LOOKUP";
export const LOOKUP_TYPE_DNC_LOOKUP = "DNC_LOOKUP";
export const LOOKUP_TYPE_CARRIER_DNC_LOOKUP = "CARRIER_DNC_LOOKUP";
export const LOOKUP_TYPE_CARRIER_EMAIL_LOOKUP = "CARRIER_EMAIL_LOOKUP";
export const LOOKUP_TYPE_EMAIL_DNC_LOOKUP = "EMAIL_DNC_LOOKUP";
export const LOOKUP_TYPE_SKIP_TRACING_LOOKUP = "SKIP_TRACING_LOOKUP";

const LOOKUP_CALLER_NAME = 63;
const LOOKUP_CARRIER = 64;
const LOOKUP_VERIFY_EMAIL = 65;
const LOOKUP_DNC = 66;
const LOOKUP_SKIP_TRACING=67

export const CONTACT_VALIDATION_STATUS = {
    NOT_VERIFIED: VALIDATION_STATUS_NOT_VERIFIED,
    VALID: VALIDATION_STATUS_VALID,
    INVALID: VALIDATION_STATUS_INVALID,
    VERIFYING: VALIDATION_STATUS_VERIFYING
};

export const CONTACT_VALIDATION_TYPE = {
    CALLER_NAME: NUMBER_VALIDATION_TYPE_CALLER_NAME,
    CARRIER: NUMBER_VALIDATION_TYPE_CARRIER,
    VERIFY_EMAIL: EMAIL_VALIDATION_TYPE_CALLER_NAME,
    DO_NOT_CALL: NUMBER_VALIDATION_TYPE_DO_NOT_CALL,
    SKIP_TRACING_TYPE_PERSON_SEARCH: SKIP_TRACING_TYPE_PERSON_SEARCH
};

export const LOOKUP_TYPE_BY_PAYLOAD_CONST = {
    [LOOKUP_TYPE_CARRIER_LOOKUP]: LOOKUP_CARRIER,
    [LOOKUP_TYPE_EMAIL_LOOKUP]: LOOKUP_VERIFY_EMAIL,
    [LOOKUP_TYPE_DNC_LOOKUP]: LOOKUP_DNC,
    [LOOKUP_TYPE_SKIP_TRACING_LOOKUP]: LOOKUP_SKIP_TRACING,
}

export const AGENCY_CARRIER_LOOKUP_CREDIT_TYPE = {
    CALLER_NAME: LOOKUP_CALLER_NAME,
    CARRIER: LOOKUP_CARRIER,
    VERIFY_EMAIL: LOOKUP_VERIFY_EMAIL,
    DNC: LOOKUP_DNC,
    SKIP_TRACING: LOOKUP_SKIP_TRACING
};

export const CARRIER_INFO_TYPES = {
    emailVerificationType: "VERIFY_EMAIL",
    phoneVerificationType: "CARRIER",
    doNotCall: "DO_NOT_CALL",
    skipTraceCurrentOwner: "SKIP_TRACING_LOOKUP_CURRENT_OWNER",
    skipTraceCurrentResident: "SKIP_TRACING_LOOKUP_CURRENT_RESIDENT"
}


export const LOOKUP_TYPE = {
    CARRIER_LOOKUP: "CARRIER_LOOKUP",
    EMAIL_LOOKUP: "EMAIL_LOOKUP",
    DNC_LOOKUP: "DNC_LOOKUP",
    SKIP_TRACING_LOOKUP: "SKIP_TRACING_LOOKUP"
};

export const SEARCH_CATEGORY = {
    HOUSE_CURRENT_RESIDENT: "HOUSE_CURRENT_RESIDENT",
    HOUSE_CURRENT_OWNER: "HOUSE_CURRENT_OWNER"
};

export const ACTION = {
    ADD_NEW_CONTACT: "ADD_NEW_CONTACT",
    ADD_UNDER_CONTACT: "ADD_UNDER_CONTACT",
    NO_ACTION: "NO_ACTION"
};

export const REQUEST_MODULE = {
    CONTACT: "CONTACT",
    CLEAN_DATA: "CLEAN_DATA"
};