import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './helper.css';

export const DropdownHelper = (props) => {

    const [datalist, setDatalist] = useState([]);


    useEffect(() => {
        if (props.datalist !== undefined && props.datalist != null && Array.isArray(props.datalist)) {
            setDatalist(props.datalist);
        }
    }, [props?.datalist])

    useEffect(() => {
        if (props.datalist !== undefined && props.datalist != null && Array.isArray(props.datalist)) {
            setDatalist(props.datalist);
        }
    }, [props.datalist.length])

    const updateSelectedData = async (event) => {
        await props.updateSelectedData(event.target.value);
        if(props.updateDatatable != undefined && props.updateDatatable != null){
            props.updateDatatable(props.dropdownHeader ,event.target.value);

        }

    }

    return (
        <React.Fragment>
            <div className={(props.parentDivClassName != undefined && props.parentDivClassName != null) ?"droupdown__helper "+props.parentDivClassName : "droupdown__helper"}>
                <FormControl className="second__step__area__select second__step__area__select__100  lead_design">
                    <Select
                    style={{'&.MuiSelect-select:focus':{
                        padding:'0px',
                        height:'0px',
                        background:'none'
                    }}}
                        value={props.selectedValue} 
                        onChange={updateSelectedData}
                        className={(props.className !== undefined && props.undefined != null) ? props.className : ''}
                        displayEmpty
                        inputProps={{ 'aria-label': (props.ariaLabel !== undefined && props.ariaLabel != null) ? props.ariaLabel : 'Without label' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        {props.noneFieldRequired !== undefined && props.noneFieldRequired != null && props.noneFieldRequired &&
                            <MenuItem value="" index="" disabled
                            className={'dropdownhelper-menuitem-class'}>

                                <em>{(props.noneFieldTitle !== undefined && props.noneFieldTitle != null) ? props.noneFieldTitle : 'None'}</em>
                            </MenuItem>
                        }
                        {Array.isArray(datalist) &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    style={{flexDirection: 'column', alignItems: 'flex-start'}}
                                    value={data.id} key={`dropdownHelper${index}${data.id}`}
                                    className={`dropdownhelper-menuitem-class ${(props.menuItemClassName !== undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}`}
                                >
                                    <div className='mt-2 pl-2'>{data[props.mapping.title]}</div>
                                    <small className={"subTitle"}>{data[props.mapping.email]}</small>
                                </MenuItem>

                            ))
                        }
                        {
                            (props.extraOption !== "" && !props.hideExtraOption) && props.extraOption
                        }
                    </Select>
                </FormControl>
            </div>
        </React.Fragment>
    )
}
