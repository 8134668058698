

const Helper={

    getIconTextFromContact:(contact)=>{
        
        let firstName = (contact.first_name !== undefined && contact.first_name != null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name !== undefined && contact.last_name != null) ? contact.last_name.trim() : ''
        
        if(firstName !== '' && lastName !== ''){
            return (firstName.substring(0,1)+lastName.substring(0,1)).toUpperCase()
        }else if(firstName !== ''){
            return firstName.substring(0,2).toUpperCase()
        }else if(lastName !== ''){
            return lastName.substring(0,2).toUpperCase()
        }else {
            return 'N/A'
        }
    },

    isEmpty:(text)=>{
        if(text !== undefined && text!= null && text!== ''){
            return false
        }else{
            return true
        }
    },

    getContactName: (contact)=>{

        let firstName = (contact.first_name !== undefined && contact.first_name != null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name !== undefined && contact.last_name != null) ? contact.last_name.trim() : ''
        if(firstName ==='' && lastName ===''){

            if(!Helper.isEmpty(contact.number)){
                return contact.number
            }else if(!Helper.isEmpty(contact.email)){
                return contact.email
            }else{
                return null
            }
        }else{
            return (firstName+' '+lastName).trim()
        }

        
    },
    reduceTextLength :(text, limit = 40) => {
        if (text !== undefined) {
            text = text.toString();
          if (text.length > limit) {
            return text.substring(0, limit);
          } else {
            return text;
          }
        } else {
          return "";
        }
    
      },
}

export default Helper;