import ActionType from "./ActionType";

export const storeProps = (payload) => ({
    type: ActionType.STORE_PROPS,
    payload: payload
});

export const handleLoading = (payload) => ({
    type: ActionType.HANDLE_LOADING,
    payload: payload
});

export const storeUserInfo = (payload) => ({
    type: ActionType.STORE_USER_INFO,
    payload: payload
});

export const storeFromEmailsInfo = (payload) => ({
    type: ActionType.STORE_FROM_EMAILS_INFO,
    payload: payload
});

export const storeToEmailsInfo = (payload) => ({
    type: ActionType.STORE_TO_EMAILS_INFO,
    payload: payload
});

export const updateToEmail = (payload) => ({
    type: ActionType.UPDATE_TO_EMAIL,
    payload: payload
});

export const updateFromEmail = (payload) => ({
    type: ActionType.UPDATE_SELECTED_FROM_EMAIL,
    payload: payload
});

export const updateSaveAsFlag = (payload) => ({
    type: ActionType.UPDATE_SAVE_AS_FLAG,
    payload: payload
});

export const updateIsShowQuickReplyFlag = (payload) => ({
    type: ActionType.UPDATE_QUCIK_REPLY_SHOW_FLAG,
    payload: payload
});

export const updateSubject = (payload) => ({
    type: ActionType.UPDATE_SUBJECT,
    payload: payload
});

export const updateMessage = (payload) => ({
    type: ActionType.UPDATE_MESSAGE,
    payload: payload
});

export const updateAttachment = (payload) => ({
    type: ActionType.UPDATE_ATTACHMENT,
    payload: payload
});

export const updateSendingFlag = (payload) => ({
    type: ActionType.UPDATE_SENDING_FLAG,
    payload: payload
});

export const storePrimaryEmail = (payload) => ({
    type: ActionType.STORE_PRIMARY_EMAIL,
    payload: payload
});

export const storeQuickReplies = (payload) => ({
    type: ActionType.STORE_QUICK_REPLIES,
    payload: payload
});

export const storePersonalized = (payload) => ({
    type: ActionType.STORE_PERSONALIZED,
    payload: payload
});

export const updateSaveAsTitle = (payload) => ({
    type: ActionType.UPDATE_SAVE_AS_TITLE,
    payload: payload
});
/* cc and bcc options */
export const updateCcAndBccFlag = (payload) => ({
    type: ActionType.UPDATE_CC_BCC_FLAG,
    payload: payload
});

export const storeCcMailInfo = (payload) => ({
    type: ActionType.STORE_CC_MAIL_INFO,
    payload: payload
});

export const storeBccMailInfo = (payload) => ({
    type: ActionType.STORE_BCC_MAIL_INFO,
    payload: payload
});

export const updateToEmailSingle = (payload) => ({
   type: ActionType.UPDATE_TO_EMAIL_SINGLE,
   payload: payload
});