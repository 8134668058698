import { useState, useEffect} from 'react'
import { CustomFieldType } from '../../Constant'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DatePicker from "react-datepicker";
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export const RenderEachCustomField = ({data, index, onChange, previousData}) => {
    const [checkBox, setCheckBox] = useState(previousData)

    useEffect(() => {
        setCheckBox(previousData)
    }, [previousData])

    const checkValueExit = (value) => {
        if(checkBox.includes(value)){
            return true
        }
        return false
    }

    const handleOnChange = (e, self) => {
        if(type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.ZIP_CODE){
            onChange(e.target.value, data?.max_length)
        }
        else if(type_id == CustomFieldType.CHECKBOX ){
            let new_value = ''
            if(checkValueExit(e)){
                if(checkBox.indexOf(e) !== -1){
                    let value_array = checkBox.split(",")
                    value_array.forEach((each_data, each_index) => {
                        if(each_data != e){
                            if(new_value == ''){
                                new_value += each_data
                            }
                            else{
                                new_value = new_value + "," + each_data
                            }
                        }
                    });
                }

            }
            else{
                if(checkBox.trim() == ''){
                    new_value = e
                }
                else{
                    new_value = checkBox + "," + e
                }
            }
            setCheckBox(new_value)
            onChange(new_value)
        }
        else if(type_id == CustomFieldType.RADIO){
            onChange(e, index, data.id)
        }
        else if(type_id == CustomFieldType.DATE){
            onChange(moment(e).format('YYYY-MM-DD'))
        }
        else if(type_id == CustomFieldType.DATETIME){
            onChange(moment(e).format('MM-D-YYYY HH:mm:ss'))
        }
        else{
            onChange(e.target.value)
        }
    }

    const type_id = data.type_id;

    if(type_id == CustomFieldType.TEXT || type_id == CustomFieldType.MULTILINE_TEXT ||
        type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.DATE ||
        type_id == CustomFieldType.DATETIME ||
        type_id == CustomFieldType.PHONE || type_id == CustomFieldType.ZIP_CODE ||
        type_id == CustomFieldType.WEBSITE || type_id == CustomFieldType.CHECKBOX ||
        type_id == CustomFieldType.RADIO || type_id == CustomFieldType.SELECT ){
        return (
            <div className="g-custom-contact-field-edit-each" key={index}>

                {(type_id == CustomFieldType.TEXT || type_id == CustomFieldType.PHONE) &&
                    <input type="text" id={`custom-field-${index}`} value={previousData} placeholder={data?.title} maxLength={data?.max_length} onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.MULTILINE_TEXT &&
                    <textarea rows="5" className={'g-custom-field-textarea'} id={`custom-field-${index}`} placeholder={data?.title}
                              onChange={(e) => handleOnChange(e)}
                    >{previousData}</textarea>
                }

                {(type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.ZIP_CODE) &&
                    <input type="number" id={`custom-field-${index}`} value={previousData} placeholder={data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.DATE &&
                    <DatePicker id={`custom-field-${index}`} className="g-custom-field-date-picker" placeholderText={'dd/mm/yyyy'} selected={(previousData !== null && previousData !== "") ? new Date(previousData) : new Date()} onChange={(date) => handleOnChange(date)} />
                }

                {type_id == CustomFieldType.DATETIME &&
                    <DatePicker showTimeSelect timeFormat="HH:mm" dateFormat="MM-dd-yyyy HH:mm:ss" id={`custom-field-${index}`} className="g-custom-field-date-picker" placeholderText={'mm-dd-yyyy HH:mm:ss'} selected={(previousData !== null && previousData !== "") ? new Date(previousData) : new Date()} onChange={(date) => handleOnChange(date)} />
                }

                {type_id == CustomFieldType.WEBSITE &&
                    <input type="url" id={`custom-field-${index}`} value={previousData} placeholder={data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.CHECKBOX &&
                    <div className="g-custom-field-check-box-group">
                        {data.default_values.map((each, each_index) => {
                            return(
                                <div key={each_index} onClick={() => {handleOnChange(each)}} className="g-custom_checkbox">
                                    {checkValueExit(each) ? <CheckIcon color="secondary"/> : <CheckBoxOutlineBlankIcon />}
                                    {each}
                                </div>
                            )
                        })}
                    </div>
                }

                {type_id == CustomFieldType.RADIO &&
                    <div className="g-custom-field-radio-group">
                        {data.default_values.map((each, each_index) => {
                            return(
                                <div key={each_index} onClick={() => {handleOnChange(each)}} className="g-custom_checkbox">
                                    {checkValueExit(each) ? <FiberManualRecordIcon color="secondary" /> : <RadioButtonUncheckedIcon />}
                                    {each}
                                </div>
                            )
                        })}
                    </div>
                }

                {type_id == CustomFieldType.SELECT &&
                    <div className="g-custom-field-select">
                        <select className="form-control h-100" value={previousData} style={{display: 'block'}} placeholder={data.title} name={`add_contact_custom_field${data.personalize_tag}`} onChange={(e) => handleOnChange(e)}>
                            <option value="" key={-1} disabled>{data.title}</option>
                            {data.default_values.map((each, each_index) => {
                                return(
                                    <option value={each} key={`custom-select-${each_index}`}>{each}</option>
                                )
                            })}
                        </select>
                    </div>
                }
            </div>
        )
    }
    return null
}