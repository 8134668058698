import React, {useState} from "react";
import {
    StyledAddEmailInput, StyledAskOptionButtonWrapper, StyledCancelButton, StyledConnectEmailButton,
    StyledConnectEmailContent,
    StyledConnectEmailIconWrapper,
    StyledConnectEmailWrapper
} from "./ConnectEmailStyles";
import {DeviceHub} from "@material-ui/icons";
import Utils from "../../../helpers/Utils";
import {connectNylas} from "../../../api/onboardApi";

const ConnectEmail = ({ cancelForm }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [connectingEmail, setConnectingEmail] = useState('');

    const initConnectionProcess = async () => {
        if (connectingEmail === ""){
            window.showNotification('error', 'Email address required');
            return;
        }
        if (!Utils.validateEmail(connectingEmail)){
            window.showNotification('error', 'Invalid email address');
            return;
        }
        setIsSubmitting(true);
        try {
            const resp = await connectNylas({ email: connectingEmail });

            if (resp && resp.success && resp.data && resp.data.redirect) {
                window.showNotification('success', 'Please wait while redirecting...');
                setTimeout(()=>{
                    window.open(resp.data.redirect, '_self');
                },2000);
            } else {
                window.showNotification('error', 'Unable to connect. Please try again later!');
            }
        } catch (error) {
            window.showNotification('error', 'Unable to connect. Please try again later!');
        } finally {
            setIsSubmitting(false);
        }
    };

    return(
        <StyledConnectEmailWrapper>
            <StyledConnectEmailContent>
                <StyledConnectEmailIconWrapper>
                    <DeviceHub/>
                </StyledConnectEmailIconWrapper>
                <h1>Sign in to your email account</h1>
                <StyledAddEmailInput type="email" value={connectingEmail} onChange={(event)=>{ setConnectingEmail(event.target.value) }} placeholder={'Your personal email address'}/>
                <StyledAskOptionButtonWrapper>
                    <StyledCancelButton onClick={cancelForm}>Cancel</StyledCancelButton>
                    <StyledConnectEmailButton disabled={isSubmitting} onClick={initConnectionProcess}>Next</StyledConnectEmailButton>
                </StyledAskOptionButtonWrapper>
            </StyledConnectEmailContent>
        </StyledConnectEmailWrapper>
    );
};

export default ConnectEmail;