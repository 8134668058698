import Helper from "./Helper";
const tenantServerPowerDialer = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1/power-dialer`;

export const getPowerDialerVirtualNumbers = async () => {
    return Helper.sendRequestBackend("get", `${tenantServerPowerDialer}/virtual/numbers`);
}
export const getPowerDialerLists = async () => {
    return Helper.sendRequestBackend("get", `${tenantServerPowerDialer}/all/lists`);
}
export const startCallFromOutside = async (payload) => {
    return Helper.sendRequestBackend("post", `${tenantServerPowerDialer}/call/single/start-calling-from-outside`, payload);
}