import React, {Fragment} from "react";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import {a2pOnBoard} from "./index";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

const RestrictSole = ({ data, onClose, setActiveStep }) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return(
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={`${Styles.headerTitle} ${Styles.headerTitleCenter}`} variant="h5">A2P/10DLC Registration</Typography>
            </div>

            <div className={Styles.modalBody}>
                <div className={Styles.askConsent}>
                    Notice: You are not eligible to register as a <br/> Sole Proprietor
                </div>
               <div className={Styles.restrictButtonGroup}>
                   <Button  size="large" onClick={() => {
                       setActiveStep(a2pOnBoard.EIN_CONFIRM)
                   }} className={`${classes.root} ${Styles.overwriteButton} ${Styles.mr1} ${Styles.width140px}`} variant="contained">
                       Go Back
                   </Button>
                   <Button onClick={() => {
                       window.open("/user/phone-settings", "_self");
                   }} size="large" className={`${classes.root} ${Styles.redButtonFill} ${Styles.width300px}`} variant="contained">
                       Go To Virtual Number Page
                   </Button>
               </div>
            </div>
            <div className={Styles.modalFooter}>
                <div className={`${Styles.congestedMessage} ${Styles.headerTitleCenter}`}>
                    <p>
                        <b>REASON:</b>  When you register as a sole proprietor the phone companies only allow you to have <b>1 virtual number</b> for your entire team.
                        <b>(You have {data?.count} active virtual numbers)</b> In order to move forward with registration as a sole proprietor you will need to remove your extra virtual numbers.
                        You can also register with an EIN (preferred route). EIN registration doesn't restrict the amount of numbers you can have.
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default RestrictSole;