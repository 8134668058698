import './css/global-voice-send-component.css'
import './css/voice-send-time-setting.css'
import SendModule from './pages/SendModule'
import { VoiceSendProvider } from './reducers/GlobalReduers';

const GlobalVoiceSendModule = (props) => {
    return(
        <VoiceSendProvider>
            <SendModule 
                open={props.open}
                onClose={props.onClose}
                id={props.id}
                from={props.from}
                sendSuccessCallBack={props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null}
                fetchContactDetails={props.fetchContactDetails !== undefined ? props.fetchContactDetails : false}
                contactInfo={props.contactInfo !== undefined ? props.contactInfo : null}
            />
        </VoiceSendProvider>
    )
}
export default GlobalVoiceSendModule;