import {useContext, useState} from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ContactDetailContext } from '../../reducer/GlobalReduers';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ContactHelper from '../../Helper';
import TimezoneConversation from '../../components/Accordion/TimezoneConversion';
import { confirmAlert } from 'react-confirm-alert';
import ApiList from '../../ApiList';

export const List = ({data, index, status, expand, refresh, key, editActivity}) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [markAsDone, setMarkAsDone] = useState(false)
    const [submitMarkAsDone, setSubmitMarkAsDone] = useState(false)

    const changeMarkAsDone = (task_id) => {
        if(submitMarkAsDone) return;
        let loggedUserId = ContactHelper.getAccountData('userId')
        if (data.user.id.toString() !== loggedUserId.toString()) {
            confirmAlert({
                title: 'Alert!',
                message: 'You can not add or update appointment to member user lead.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
            return false;
        }
        else{
            setMarkAsDone(true)
            setSubmitMarkAsDone(true)
            ApiList.activityMarkAsDone({id: data.id}).then(res => {
                setMarkAsDone(false);
                setSubmitMarkAsDone(false);
                refresh();
            })
        }
    }

    const updateActivity = () => {
        if(status == 1){
            editActivity(data.id, data)
        }
    }

    return(
        <div className="g-contact-appointment-each" key={key}>
            <div className="g-appointment-check">
                {(status == 1 && !markAsDone) &&
                    <span onClick={changeMarkAsDone}><CheckBoxOutlineBlankIcon /></span>            
                }
                {(status == 1 && markAsDone) &&
                    <CheckBoxIcon />            
                }
            </div>
            <div onClick={updateActivity} className="g-appintment-info">
                <span className="g-appintment-info-title" style={{color: '#039be5'}}>
                    {data.task_title}
                </span>
                <span className="g-appintment-info-date">
                    {TimezoneConversation(data.date + " " + data.time,'UTC',ContactHelper.getAccountData('userTimezoneRaw') , "MM/DD/YYYY hh:mm A")}
                </span>
                <span className="g-appintment-info-user">
                    By {data.user?.full_name}
                </span>
            </div>
        </div> 
    )
}