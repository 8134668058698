import React, { useCallback, useEffect, useState } from 'react';
import LinkFile from "./LinkFile";
import { Box } from "@material-ui/core";
import { connect } from 'react-redux';
import RightSide from "./RightSide/RightSide";
import {
  fetchContactAdditionalContacts,
  getAllContacts,
  setContactDetails,
  updateMultipleData
} from '../../actions/contactAction';
import {fetchContactDetailsNew} from "../../api/contactApi";


const Details = (props) => {

  const [contactId, setContactId] = useState(props.id);
  const [data, setData] = useState(null);

  useEffect(()=>{
    loadData(contactId);
    getAdditionalContactList(contactId);
  },[])

  const loadData = (id) => {
    fetchContactDetailsNew({
      id: id ?? contactId,
      for_custom_field_update: true
    }).then(response => {
      if (response.data.status === 'success') {
        if (response.data.data !== undefined && response.data.data != null && response.data.data['people'] !== undefined && response.data.data['people'] != null) {
          setData(response.data.data);
          props.setContactDetails(response.data.data['people']);
        } else {
          window.showNotification('ERROR', "You don't have access to this contact")
        }
      } else {
        window.showNotification('ERROR', response.data.message)
      }
    }).finally(() => {
    })
  }

  const getAdditionalContactList = (id) => {
    props.fetchContactAdditionalContacts({
      page_no: 1,
      per_page: 100,
      contact_id: id,
    });
  };

  const closeModal = () => {
    props.onClose();
  }

  return (
    <LinkFile.modal
      open={props.open}
      // title={renderTitle()}
      onClose={props.onClose}
      hideFooter={true}
      className="global-450-modal"
      extra_props_active={true}
    >
      <Box display={'flex'}>
        {/* <Box width={'50%'}></Box> */}
        <Box width={'100%'}>
          <RightSide closeModal={closeModal} contactDetails={data != null ? data['people'] : null}/>
        </Box>
      </Box>
    </LinkFile.modal>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    getAllContactList: (params, callback) => dispatch(getAllContacts(params, callback)),
    fetchContactAdditionalContacts: (params) => dispatch(fetchContactAdditionalContacts(params))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
