import { useRef } from "react";
import Handwritting from "../../handwritting/Handwritting";
// import ZendirectHandwriting from "../../handwritting/ZendirectHandwriting";
import PersonalizedTag from "../../PersonalizedTag/PersonalizedTag";
// import VoiceRecognizer from "../speech_recognition/CheckValidity";
// import VoiceRecognizer from "../speech_recognition/CheckValidity";

const Customize = ({typeYourMessageSteps, handleDataChange, type, handleGeneratePreviewImageByButton, generatePdf}) => {
    const textEditorRef = useRef(null);

    const handlePersonalize = (selectedPersonalize) => {
        let start = textEditorRef.current.selectionStart;
        let end = textEditorRef.current.selectionEnd;
        let text = textEditorRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + selectedPersonalize + after);
        if(type !== "zendirect_gift"){
            handleDataChange({message: newText})
        }else{
            if(typeYourMessageSteps.showingPage === "first_page"){
                return;
            }
            if(typeYourMessageSteps.showingPage === "third_page"){
                handleDataChange({message: newText})
            }
            else{
                handleDataChange({thirdPageText: newText})
            }
        }
        
    }

    const renderTextArea = () => {
        if(type !== "zendirect_gift"){
            return (
                <textarea value={typeYourMessageSteps.message} ref={textEditorRef} onChange={(e) => handleDataChange({message: e.target.value})} className='dmpc_3rd_tv_left_textarea' placeholder="Enter your text message"></textarea>
            );
        }
        else{
            let value = (typeYourMessageSteps.showingPage === "third_page") ? typeYourMessageSteps.message : typeYourMessageSteps.thirdPageText;
            if(typeYourMessageSteps.showingPage === "first_page"){
                value = ""
            }
            return (
                <textarea 
                    value={value} 
                    ref={textEditorRef} 
                    onChange={(e) => {
                        if(typeYourMessageSteps.showingPage === "second_page"){
                            handleDataChange({thirdPageText: e.target.value})
                        }else if(typeYourMessageSteps.showingPage === "third_page"){
                            if(e.target.value.trim() === ""){
                                handleDataChange({message: e.target.value, pdfUrl: ""})
                            }
                            else{
                                handleDataChange({message: e.target.value})
                            }
                        }
                        else{
                            handleDataChange({message: e.target.value})
                        }
                    }} 
                    // className={`dmpc_3rd_tv_left_textarea ${typeYourMessageSteps.handWritingStyle}`}
                    className={`dmpc_3rd_tv_left_textarea`}
                    placeholder="Enter your text message"
                    readOnly={typeYourMessageSteps.showingPage === "first_page" ? true : false}
                    style={typeYourMessageSteps.showingPage === "first_page" ? {background: "#ddd", cursor: "not-allowed"} :{}}
                ></textarea>
            );
        }
    }

    const renderNextButtonClass = () => {
        if(typeYourMessageSteps.processingPreviewImage){
            return 'global_post_card_disabled_btn'
        }
        if(!generatePdf && typeYourMessageSteps.message === ""){
            return 'global_post_card_disabled_btn'
        }
        else if(generatePdf && typeYourMessageSteps.message === "" && typeYourMessageSteps.thirdPageText === ""){
            return 'global_post_card_disabled_btn'
        }
        return '';
    }

    return (
        <div className='dmpc_3rd_tv_left'>
            <div className='dmpc_3rd_tv_left_innter'>
                <div className='dmpc_3rd_tv_left_select'>
                    {type === "zendirect_gift" ? <></>
                    // <ZendirectHandwriting 
                    //     value={typeYourMessageSteps.handWritingStyle} 
                    //     onChange={(value) => handleDataChange({handWritingStyle: value})} 
                    // />
                    :
                    <Handwritting 
                        value={typeYourMessageSteps.handWritingStyle} 
                        onChange={(value) => handleDataChange({handWritingStyle: value})} 
                    />
                    }
                    <PersonalizedTag onChange={value => handlePersonalize(value)} />
                </div>
                <div className="dmpc_3rd_tv_left_textarea_wr">
                    {renderTextArea()}
                    {/* <VoiceRecognizer 
                        hanldeSaveAsMessage={(text) => handleDataChange({message: text})}
                        previousMessage={typeYourMessageSteps.message}
                    /> */}
                </div>
                {/* {type !== "zendirect_gift" &&
                <div className="dmpc_3rd_tv_left_hints">
                    <span className="dmpc_3rd_tv_left_hints_bold">HINTS:</span>
                    <span>Please wait 3-5 seconds after change text message</span>
                </div>
                } */}
                {type === "zendirect_gift" &&
                <div className="dmpc_3rd_tv_left_hints">
                    <span className="dmpc_3rd_tv_left_hints_bold">HINTS:</span>
                    <span>{typeYourMessageSteps.showingPage === "second_page" ? "Second page text message" : (typeYourMessageSteps.showingPage === "third_page" ? "Third page text message" : "Change the page to compose your text")}</span>
                </div>
                }

                {type !== "zendirect_gift" &&
                <div style={{textAlign: 'center'}}>
                    <div className={`pdm_type_msg_generate_file ${renderNextButtonClass()}`} onClick={handleGeneratePreviewImageByButton}>
                        {typeYourMessageSteps.processingPreviewImage ? "Generating" : "Generate"} preview
                    </div>
                </div>
                }
            </div>
        </div>
    );
}
export default Customize;