import React, {useEffect, useState} from "react";
import GlobalSmsSendModule from "../SendComponents/Sms";

const SmsSendInitialState = {
    /* props data */
    // open: false,
    contactId: null,
    id: null,
    contactDetails: null,
    contactInfo: null,
    from: '',
    open:false,
    // onClose: null,
    sendSuccessCallBack: () => {}
};
/* 
id {contact id},
contactInfo{basic info: name, number}
open,
onClose,
sendSuccessCallBack,
from
*/

const SmsSendModule = (props) => {
    const [smsSendState, setSmsSendState] = useState(SmsSendInitialState);
    const [isOpenSmsSendModal, setIsOpenSmsSendModal] = useState(false);

    // window.handleGlobalSmsSendModal = info => {
    //     if (info.open) {
    //         setSmsSendState({
    //             ...smsSendState,
    //             // open: info.open,
    //             contactId: info.id,
    //             id: info.id,
    //             contactDetails: info.contactInfo,
    //             contactInfo: info.contactInfo,
    //             from: info.from,
    //             // onClose: info.onClose,
    //             sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? SmsSendInitialState.sendSuccessCallBack : info.sendSuccessCallBack
    //         });
    //         setIsOpenSmsSendModal(true);
    //     } else {
    //         setIsOpenSmsSendModal(false);
    //         setSmsSendState(SmsSendInitialState);
    //     }
    // }

    useEffect(()=>{

        let info = props.info;
            setSmsSendState({
                ...smsSendState,
                // open: info.open,
                contactId: info.id,
                id: info.id,
                contactDetails: info.contactInfo,
                contactInfo: info.contactInfo,
                from: info.from,
                // onClose: info.onClose,
                sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? SmsSendInitialState.sendSuccessCallBack : info.sendSuccessCallBack,
                open: true
            });
    },[])

    const closeSmsSendModal = () => {
        // setSmsSendState(SmsSendInitialState);
        // setIsOpenSmsSendModal(false);
        props.closeModal()
    }

    return (
        <React.Fragment>
            {smsSendState.open &&
                <GlobalSmsSendModule
                    open={smsSendState.open}
                    onClose={closeSmsSendModal}
                    {...smsSendState}
                />
            }
        </React.Fragment>
    );
}
export default SmsSendModule;