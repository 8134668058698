import React,{useEffect,useState} from 'react';
import { fetchPrivacyPolicy } from '../../../api/loginApi';
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import './privacyAndTc.css';


const PrivacyPolicy = () => {
    
    const [pageInfo, setPageInfo] = useState({
        agencyLogo: '',
        favIcon: '',
        agencyName: '',
        policyText: ''
    });

    useEffect(() => {
        fetchPrivacyPolicy({}).then(res => {
            setPageInfo({
                domain: res.data.data.subdomain,
                agencyLogo: res.data.data.agencyLogo,
                favIcon: res.data.data.favIcon,
                agencyName: res.data.data.agencyName,
                policyText: res.data.data.policyText?.page_text
            });
        }).catch((error) => {
            console.log(error)
        });
    },[])

    return (
        <>
        <Helmet>
            <link rel="icon" href={pageInfo.favIcon} />
            <title>{`Privacy Policy | ${pageInfo.agencyName}`}</title>
            <meta name="description" content="All-In-One Sales & Marketing Platform" />
            <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`} />
            <meta name="author" content={pageInfo.agencyName} />
        </Helmet>
        <div className="pypepro__privacy__policy">
            <div className="pypepro__header">

                <div className="pypepro__container d-flex justify-content-between align-items-center">
                    <div className="pypepro__privacy__logo">
                        <img src={pageInfo.agencyLogo} width="150px" alt="logo" />
                    </div>
                    <div className="header-right-buttons">
                        <Link to="/paid/get-started" className={"btn m-btn btn-primary btn-sm accent--bg--text--color mr-2"} > Sign Up</Link>
                        <Link to="/login" className={"btn m-btn btn-primary btn-sm accent--bg--text--color"} > Sign In</Link>
                    </div>
                </div>
                
            </div>
        
            <div className="pypepro__banner">
                <div className="pypepro__banner__overly"></div>
                <div className="pypepro__container">
                    <div className="pypepro__banner__content">
                            <h1>PRIVACY POLICY</h1>
                    </div>
                </div>
            </div>
    
            <div className="pypepro__inner__content">
                <div className="pypepro__container">
                    {
                        ( pageInfo.policyText === null || pageInfo.policyText === undefined ) ?
                        <>
                        <div className="pypepro__single__item">
                            <h5>1. Purpose 
                            </h5>
                            <p> This Privacy Policy (“Policy”) applies to {pageInfo.agencyName} website (“Website”) and web-enabled applications (“Dashboard”) (together, the “Platform”) operated by {pageInfo.agencyName}, LLC dba {pageInfo.agencyName} (“us”, “we”, or “our”). This Policy identifies the personally identifiable information (“PI”) and other anonymous information (“AI”) we gather (collectively, “Information”) and how we use such Information. Please contact us using the information below if you have any questions about this Policy.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>2. Acceptance of the Policy
                            </h5>
                            <p> You accept this Policy by using any part of the Platform, including by setting up or registering a user account.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>3. Changes to Policy
                            </h5>
                            <p> If we make any significant changes to this Policy, we will notify you by email or flag this Policy on the Platform as updated. You agree to our use of electronic communications with you for purposes of this Policy. If you do not agree to the changes to this Policy, we will continue to maintain and use PI previously collected in accordance with the Policy in force as of that date, but we reserve the right to terminate your further use of your account and your access to any Platform functionality.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>4. Personally Identifiable Information
                            </h5>
                            <p> We collect PI that you provide us in connection with your creation of an account. This Information includes your username, password, full name, email, and other information related to your use of the Platform. We may also have access to, and use of, PI that you provide to third parties that help us provide the services to you (i.e. payment processors). We primarily use PI to communicate with you about the Platform and our services, to help you manage your access to the Platform, to analyze activity on the Platform, to assess and address security issues, and to generally administer and improve the Platform. We may also use your PI to provide you with newsletters or our own marketing materials. We use anonymized Information or information in the aggregate for our business purposes and other than as specified in this Policy, we do not use aggregated information in a manner that allows identification of an individual user.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>5. Legal
                            </h5>
                            <p> We may use PI as required or permitted by law, including in response to service of legal process (court order, summons, subpoena, and the like). We may disclose PI to law enforcement or regulatory authorities as part of an investigation into activity at the Platform (such as a suspected breach) or in connection with your account. We shall use reasonable measures to limit disclosure and use of such PI. We may use PI in connection with the establishment or defense of legal claims.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>6. Security
                            </h5>
                            <p> Although no electronic method of transmission or storage is completely secure, we have implemented reasonable technical, physical, and administrative safeguards designed to protect PI against loss and against unauthorized access, use, and disclosure. Account passwords are stored in encrypted form, but you are also responsible for the confidentiality of your account information and password and should act accordingly. If you lose your password, you must immediately notify us. We may retain PI as necessary for the purposes stated in Sections 4 or 5 or to otherwise meet legal requirements.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>7. Anonymous Information
                            </h5>
                            <p> We may collect AI regarding your use of the Platform, such as computer-related information, including the device or browser type you use, your Internet Protocol address, the website you came from or left to, and other information. We may send to your device a “cookie” or “pixel tag” that contains an identification number that is unique to the device you are using. You may decline, through your browser or machine settings, to provide such cookies or other information to us, although some of the Platform’s features or services may not function properly as a result, and some services may not be available at all. We may use this AI to operate the Platform, to enhance the Platform, to understand how our Platform is used, to investigate and verify proper use of the Platform, and to monitor the security and integrity of the Platform.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>8. Third Party Agents – Access to and Use of the Information
                            </h5>
                            <p> We contract with third parties to assist us in administering the Platform and hosting the Information. This may require you or us to share AI in order to make the Platform available for your use. Such third parties agree with us to maintain your privacy at least with the level of protection set forth in this Policy. However, we do not provide third parties with the PI you share with us during the registration process, nor do we sell, lease, rent, or otherwise use your PI in any manner unrelated to the Platform.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>9. Third-Party Links and Information
                            </h5>
                            <p> The Platform may contain links to websites or other information and materials provided by third parties, including, but not limited to, links to third-party content. We do not own or control such websites, content, or third parties and are not responsible for the information or services provided by those third parties. As such, we are not responsible for their privacy policies, terms of use or service, or the information collected at or by such third-party websites or services.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>10. Transfer of Information
                            </h5>
                            <p> We may transfer any information we have about you as an asset in connection with a merger or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of a company reorganization or other change in company control.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>11. Information about Children
                            </h5>
                            <p> We do not knowingly collect PI from or about children under the age of thirteen (13).</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>12. Your Access to PI
                            </h5>
                            <p> You may request access or updates to your PI or request a correction be made to an inaccuracy in your PI by contacting us as set out below or by using the functionality available on the Platform. For security purposes, we may request PI from you in connection with such request.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>13. Deleting and Retention of PI
                            </h5>
                            <p>You may request that we delete or deactivate your account or otherwise delete PI, and we shall attempt to accommodate such requests. However, we may retain and use PI for such periods of time as required by law or to continue to provide you with the Platform or any services related to the Platform.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>14. Third Party Tracking, Cookies, and Do Not Track
                            </h5>
                            <p>Generally, information we collect in connection with your use of the Platform is used within the platform and not shared with third parties. However, in some cases we may use cookies and other third party advertising products in connection with the website and Platform, and the information collected may be shared with third parties. For more information on cookies and third party advertising products, please see our Cookie Policy in Section 18. Do Not Track (“DNT”) is a concept that has been promoted by regulatory agencies such as the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing Internet users to control the tracking of their online activities across websites by using browser settings. The World Wide Web Consortium (W3C) has been working with industry groups, Internet browsers, technology companies, and regulators to develop a DNT technology standard. While some progress has been made, it has been slow. No standard has been adopted to this date. As such, we may not recognize or respond to Do Not Track signals.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>15. Effective Date of Policy
                            </h5>
                            <p> The effective date of this Policy is December 14, 2018.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>16. Applicable Jurisdiction
                            </h5>
                            <p> This Policy is governed by the laws of the State of Michigan. We do not represent that it complies with privacy laws outside the United States. If your use of the Platform would be subject to the laws of a jurisdiction with which this Policy does not comply, you shall not use the Platform.</p>
                        </div>
                        <div className="pypepro__single__item">
                            <h5>17. Contact
                            </h5>
                            <p> Please contact us with any questions or comments you may have using the following contact information:</p>
                            <p>LLC dba {pageInfo.agencyName}</p>
                            <p><b>Attn</b>: Default Agency</p>
                            <p><b>Email</b>: abc@gmail.com</p>
                        </div>
        
                        <div className="pypepro__single__item">
                            <h5>18. Cookie Policy
                            </h5>
                            <p> This Cookie Policy explains what cookies are and how we use them. You should read this policy so you can understand what type of information we collect using cookies and how that information is used. Cookies are little text files which are stored on the browser or hard drive of your computer or mobile device when you visit a webpage or application. Cookies work to make your experience browsing our site as smooth as possible and they remember your preferences so you don’t have to insert your details again and again. We use cookies to enhance your browsing experience by: (a) recognizing when you log in and any preferred settings; (b) giving you a browsing experience that is unique to you and to serve you content which we believe improves your site experience; and (c) analyzing how your use our site which helps us to troubleshoot any problems and to monitor our own performance. Cookies and other ad technology such as beacons, pixels, and tags help us serve relevant ads to you more effectively. Because your web browser may request advertisements and beacons directly from third party ad network servers, these networks can view, edit, or set third party cookies, just as if you had requested a web page from their site. Ads served by us may also set third party cookies.
                                Within your browser you can choose whether you wish to accept cookies or not. Different browsers make different controls available to you. Generally, your browser will offer you the choice to accept, refuse or delete cookies at all times, or those from providers that website owners use i.e. third party cookies, or those from specific websites. Each browser’s website should contain instructions on how you can do this. If you block cookies on our website, you may be unable to access certain areas of our website and certain functions and pages will not work in the usual way. We may update this Cookie Policy from time to time. If we make significant changes we will let you know but please regularly check this Cookie Policy to ensure you are aware of the most updated version.</p>
                        
                        </div>
                        </> : <div dangerouslySetInnerHTML={{__html: pageInfo.policyText}}></div>
                    }
                    
                </div>
            </div>
        </div>
        </>
       
    );
}

export default PrivacyPolicy;