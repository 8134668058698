import cardImg from '../../images/selectCard.svg'
import selectActiveCard from '../../images/selectActiveCard.svg'
import FooterButton from './FooterButton';

const post_card_sizes = [
    {value: '4x6', title: '4x6 Postcard', price: 0.59},
    {value: '6x9', title: '6x9 Postcard', price: 0.89}
];

const SelectSize = ({selectSizeStep, handleSizeChange, priceInfo, handleStepChange}) => {

    const renderCardSizes = () => {
        let view = [];
        post_card_sizes.forEach((item, index) => {
            let price_value = '0.00'
            if(item.value === "4x6"){
                price_value = priceInfo['6_4x6']
            }
            else{
                price_value = priceInfo['6_6x9']
            }
            view.push(
                <div key={index} className={`dmpc_1st__mp__single ${selectSizeStep.selected_size === item.value ? 'dmpc_1st__mp__active' : ''}`} onClick={() => handleSizeChange(item.value, price_value)}>
                    <div className='dmpc_1st__middle__icon'>
                        <img src={selectSizeStep.selected_size === item.value ? selectActiveCard : cardImg} alt={'post card size'} />
                    </div>
                    <h4 className='dmpc_1st__mcard'>{item.title}</h4>
                    <span className='dmpc_1st__mprice'>Price: ${parseFloat(price_value).toFixed(2)}</span>
                </div>
            );
        });
        return view;
    }

    const _handleStepChange = (value) => {
        if(selectSizeStep.selected_size != null){
            handleStepChange(true)
        }
    }

    const renderButtonClass = () => {
        if(selectSizeStep.selected_size == null){
            return false
        }
        return true;
    }

    return (
        <div className='dmpc_1st_middle'>
            <h3 className='dmpc_1st_middle__title'>Create a New Postcard Design</h3>
            <span className='dmpc_1st_middle__subtitle'>Create an amazing Postcard Design.</span>

            <div className='dmpc_1st__middle_postCard'>
                {renderCardSizes()}
            </div>

            <FooterButton handleStepChange={_handleStepChange} isValid={renderButtonClass()} />
        </div>
    );

}
export default SelectSize;