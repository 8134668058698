import React, {createContext, useReducer} from 'react'
import ActionType from './ActionType';

const EmailSendContext = createContext({});

export const emailSendStates = {
    /* main props */
    contactId: null,
    from: '',
    sendSuccessCallBack: null,
    onClose: null,
    primaryEmail: '',
    contactInfo: null,
    emailId : null,

    /* contact information state */
    agency: null,
    userInfo: null,
    userSignature: null,
    appointmentUrl: null,
    personalized: [],

    /* from email state */
    selectedFromEmail: '',
    allFromEmails: [],
    connectedEmails: [],
    nylasActive: false,

    /* to email */
    allToEmails: [],
    selectedToEmails: [],
    selectedToEmail: "",

    /* email template state */
    isOpenQuickReply: false,
    saveAsTemplate: false,
    quickReplies: [],
    saveTemplateTitle: '',

    /* subject & message state */
    subject: '',
    validSubject: false,
    message: '',
    validMessage: false,
    attachmentFiles: [],

    isLoading: true,
    wentWrong: false,
    errorText: '',
    sending: false,

    /* cc and bcc options */
    activeCc: false,
    activeBcc: false,

    defaultCc: [],
    ccMails: [],
    textToAddCc: '',
    isCcValid: true,
    showCcValidMessage: '',

    bccMails: [],
    textToAddBcc: '',
    isBccValid: true,
    showBccValidMessage: ''
}

export const eamilSendReducer = (state, action) => {
    switch(action.type){
        case ActionType.HANDLE_LOADING:
            if(typeof action.payload === 'object'){
                return {
                    ...state, 
                    isLoading: action.payload.isLoading !== undefined ? action.payload.isLoading : state.isLoading, 
                    wentWrong: action.payload.wentWrong,
                    errorText: action.payload.errorText
                }
            }
            return {...state, isLoading: action.payload}

        case ActionType.STORE_PROPS:
            return {
                ...state, 
                contactId: action.payload.contactId, 
                from: action.payload.from, 
                sendSuccessCallBack: action.payload.sendSuccessCallBack,
                onClose: action.payload.onClose,
                primaryEmail: action.payload.primaryEmail,
                contactInfo: action.payload.contactInfo,
                emailId : action.payload.emailId
            }

        case ActionType.STORE_USER_INFO:
            return {
                ...state, 
                agency: action.payload.agency,
                userInfo: action.payload.userInfo,
                userSignature: action.payload.userSignature,
                appointmentUrl: action.payload.appointmentUrl
            }

        case ActionType.STORE_FROM_EMAILS_INFO:
            return {
                ...state, 
                allFromEmails: action.payload.allFromEmails,
                nylasActive: action.payload.nylasActive,
                connectedEmails: action.payload.connectedEmails
            }

        case ActionType.UPDATE_SELECTED_FROM_EMAIL:
            return { ...state, selectedFromEmail: action.payload }

        case ActionType.STORE_TO_EMAILS_INFO:
            return { ...state, allToEmails: action.payload }

        case ActionType.UPDATE_TO_EMAIL:
            return { ...state, selectedToEmails: action.payload }
        
        case ActionType.UPDATE_SAVE_AS_FLAG:
            return { ...state, saveAsTemplate: action.payload }

        case ActionType.UPDATE_QUCIK_REPLY_SHOW_FLAG:
            return { ...state, isOpenQuickReply: action.payload }

        case ActionType.UPDATE_SUBJECT:
            return {
                ...state, 
                subject: action.payload.subject !== undefined ? action.payload.subject : state.subject,
                validSubject: action.payload.validSubject,
            }

        case ActionType.UPDATE_MESSAGE:
            return {
                ...state, 
                message: action.payload.message !== undefined ? action.payload.message : state.message,
                validMessage: action.payload.validMessage,
            }

        case ActionType.UPDATE_ATTACHMENT:
            return { ...state, attachmentFiles: action.payload }

        case ActionType.UPDATE_SENDING_FLAG:
            return { ...state, sending: action.payload }
            
        case ActionType.STORE_PRIMARY_EMAIL:
            return { ...state, primaryEmail: action.payload }
            
        case ActionType.STORE_QUICK_REPLIES:
            return { ...state, quickReplies: action.payload }
            
        case ActionType.STORE_PERSONALIZED:
            return { ...state, personalized: action.payload }
        
        case ActionType.UPDATE_SAVE_AS_TITLE:
            return { ...state, saveTemplateTitle: action.payload }
        
        case ActionType.UPDATE_CC_BCC_FLAG:
            return { 
                ...state, 
                activeCc: action.payload.activeCc === undefined ?  state.activeCc : action.payload.activeCc,
                activeBcc: action.payload.activeBcc === undefined ?  state.activeBcc : action.payload.activeBcc,
            }
            
        case ActionType.STORE_CC_MAIL_INFO:
            return { 
                ...state, 
                defaultCc: action.payload.defaultCc === undefined ?  state.defaultCc : action.payload.defaultCc,
                ccMails: action.payload.ccMails === undefined ?  state.ccMails : action.payload.ccMails,
                textToAddCc: action.payload.textToAddCc === undefined ?  state.textToAddCc : action.payload.textToAddCc,
                isCcValid: action.payload.isCcValid === undefined ?  state.isCcValid : action.payload.isCcValid,
                showCcValidMessage: action.payload.showCcValidMessage === undefined ?  state.showCcValidMessage : action.payload.showCcValidMessage,
                activeCc: action.payload.activeCc === undefined ?  state.activeCc : action.payload.activeCc
            }
            
        case ActionType.STORE_BCC_MAIL_INFO:
            return { 
                ...state, 
                bccMails: action.payload.bccMails === undefined ?  state.bccMails : action.payload.bccMails,
                textToAddBcc: action.payload.textToAddBcc === undefined ?  state.textToAddBcc : action.payload.textToAddBcc,
                isBccValid: action.payload.isBccValid === undefined ?  state.isBccValid : action.payload.isBccValid,
                showBccValidMessage: action.payload.showBccValidMessage === undefined ?  state.showBccValidMessage : action.payload.showBccValidMessage
            }

        case ActionType.UPDATE_TO_EMAIL_SINGLE:
            return { ...state, selectedToEmail: action.payload }

        default: return state
    }
}

const EmailSendProvider = ({children}) => {
    const [states, dispatch] = useReducer(eamilSendReducer, emailSendStates)
    return (
        <EmailSendContext.Provider
            value={{
                states,
                dispatch
            }}
        >
            {children}
        </EmailSendContext.Provider>
    )
}
export {EmailSendContext, EmailSendProvider}