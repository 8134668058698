import { Button } from "@material-ui/core";
import React, { useState } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import moment from "moment";

const TimeSetting = props => {

    const [timeSetting, setTimeSetting] = useState({
        tab: '1', //1 = immediate, 2 = custom
        date: new Date(),
        time: new Date()
    })

    const [msgDateTime, setMsgDateTime] = useState('')

    const handleTabClick = (value) => {
        setTimeSetting({
            ...timeSetting,
            tab: value
        })
        props.handleTimesetting('tab', value)
    }

    const handleDateChange = (value) => {
        try{
            const selectedDate = moment(value);
            const selectedDateFormated = moment(value).format('MM/DD/yyyy');
            const currentDate = moment(new Date());
            const currentDateFormated = moment(new Date()).format('MM/DD/yyyy');

            const currentTime = moment(new Date());
            const selectedTime = moment(timeSetting.time);

            const dif_date = moment(selectedDate, 'MM/DD/yyyy').diff(moment(currentDate, 'MM/DD/yyyy'), 'days');
            const dif_time = moment(selectedTime, 'HH:mm: A').diff(moment(currentTime, 'HH:mm: A'), 'minutes');
            
            if(dif_date === 0){
                if(selectedDateFormated !== currentDateFormated){
                    setMsgDateTime('')
                }
                else{
                    /* check time */
                    if(dif_time > 0){
                        setMsgDateTime('')
                    }
                    else{
                        setMsgDateTime('You have selected a previous date time for scheduling ! Message will send immediately.')
                    }
                }
            }
            else if(dif_date > 0){
                setMsgDateTime('')
                /* check time */
            }
            else{
                setMsgDateTime('You have selected a previous date time for scheduling ! Message will send immediately.')
            }

            setTimeSetting({
                ...timeSetting,
                date: value
            })
            props.handleTimesetting('date', value)
        }
        catch(e){
            setTimeSetting({
                ...timeSetting,
                date: value
            })
            props.handleTimesetting('date', value)
        }
        // setTimeSetting({
        //     ...timeSetting,
        //     date: value
        // })
        // props.handleTimesetting('date', value)
    }

    const timeChangeHandler = value => {
        
        try{
            const selectedDate = moment(timeSetting.date);
            const selectedDateFormated = moment(timeSetting.date).format('MM/DD/yyyy');
            const currentDate = moment(new Date());
            const currentDateFormated = moment(new Date()).format('MM/DD/yyyy');

            const currentTime = moment(new Date());
            const selectedTime = moment(value);

            const dif_date = moment(selectedDate, 'MM/DD/yyyy').diff(moment(currentDate, 'MM/DD/yyyy'), 'days');
            const dif_time = moment(selectedTime, 'HH:mm: A').diff(moment(currentTime, 'HH:mm: A'), 'minutes');

            if(dif_date === 0){
                if(selectedDateFormated !== currentDateFormated){
                    setMsgDateTime('')
                }
                else{
                    /* check time */
                    if(dif_time > 0){
                        setMsgDateTime('')
                    }
                    else{
                        setMsgDateTime('You have selected a previous date time for scheduling ! Message will send immediately.')
                    }
                }
            }
            else if(dif_date > 0){
                setMsgDateTime('')
                /* check time */
            }
            else{
                setMsgDateTime('You have selected a previous date time for scheduling ! Message will send immediately.')
            }

            setTimeSetting({
                ...timeSetting,
                time: value
            })
            props.handleTimesetting('time', value)
        }
        catch(e){
            setTimeSetting({
                ...timeSetting,
                time: value
            })
            props.handleTimesetting('time', value)
        }

        // setTimeSetting({
        //     ...timeSetting,
        //     time: value
        // })
        // props.handleTimesetting('time', value)
    }

    return (
        <div className="global__send__module__time__setting">
            <h3 className="global__send__module__time__setting__title">Select when to send this followup</h3>
            <div className="time__setting__tabs">
                <Button className={`${timeSetting.tab == '1' ? 'accent--bg--text--color' : '' }`} variant={`${timeSetting.tab == '1' ? 'contained' : 'outlined'}`} onClick={() => handleTabClick('1')}>NOW</Button>
                <Button className={`${timeSetting.tab == '2' ? 'accent--bg--text--color' : '' }`} variant={`${timeSetting.tab == '2' ? 'contained' : 'outlined'}`} onClick={() => handleTabClick('2')}>LATER</Button>
            </div>

            {timeSetting.tab == '1' &&
            <div className="ds__send_immediately pt-4">Message will send immediately</div>
            }

            {timeSetting.tab == '2' &&
            <div className="d-flex align-items-center g__sms__custom_date_wrapper">
                <div>
                    <h3 className="g__sms__send__date__alt__title">Date</h3>
                    <div className="g__sms__send__date__alt">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justifyContent="space-around">
                                <KeyboardDatePicker
                                    format="MM/dd/yyyy"
                                    value={timeSetting.date}
                                    onChange={(date) => handleDateChange(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className=""
                                />
                                </Grid>
                            </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div >
                    <h3 className="g__sms__send__time__alt__title">Time</h3>
                    
                     <div className="g__sms__send__time__alt">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <KeyboardTimePicker
                                    format="hh:mm a"
                                    value={timeSetting.time}
                                    onChange={(date) => timeChangeHandler(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    className=""
                                />

                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                    
                </div>

            </div>
            }

            <div className="global_sms_send_schedule_error_container">
                <span>{msgDateTime}</span>
            </div>
        </div>
    )
}
export default TimeSetting;