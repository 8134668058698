const OnScrollFetchBox = props => {

    let threshold = props.threshold === undefined ? 400 : props.threshold;
    let callback = true;

    const onScroll = (e) => {
        if(callback && e.target.scrollHeight-e.target.scrollTop <= e.target.clientHeight+threshold){
            if(props.scrollCallBack !== undefined) {
                props.scrollCallBack();
            }
            callback = false;
            setTimeout(() => {
                callback = true;
            }, 500);
        }
    }

    return ( 
        <div className={props.className} onScroll={onScroll}>
            {props.children}
        </div>
    );
}
 
export default OnScrollFetchBox;