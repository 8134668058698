import React from "react";
import Styles from "./Styles.module.css";
import {FormControl, FormHelperText, InputBase, withStyles} from "@material-ui/core";
import A2pSuggestion from "../a2pSuggestion";
import {CODE_WISE_DATA} from "./Constants";
import A2pLearnMore from "../a2pLearnMore";
import MessageFlowLearnMore from "../a2pLearnMore/MessageFlowLearnMore";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        minHeight: "200px !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
    },
}))(InputBase);

const EndUserConsent = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError }) => {
    const handleFormDataChange = (name, value) => {
        if (name === "messageFlow"){
            if (value.trim() === ""){
                errorCallback("messageFlow","Message Flow is required!");
            }else if(value.length < 40 || value.length > 2084){
                errorCallback("messageFlow","Message Flow can not be less than 40 characters and more than 2084 characters!");
            }else {
                errorCallback("messageFlow","");
            }
        }

        if(name === "optOutKeywords"){
            if (value.trim() === ""){
                errorCallback("optOutKeywords","Opt-out is required!");
            }else if (value.split(",").length > 0){
                const helperKeywordArray = value.split(",");

                helperKeywordArray.forEach((keyword)=>{
                    let index = keyword.indexOf(" ");

                    if(keyword === ""){
                        errorCallback("optOutKeywords","Remove trailing comma from the Opt-out keywords!");
                    }else if (keyword.length > 255){
                        errorCallback("optOutKeywords","Each Opt-out keyword must be less then 255!");
                    }else if (index >= 0){
                        errorCallback("optOutKeywords","Opt-out Keyword cannot contain white-space!");
                    }else if(keyword.includes('_') || keyword.includes('-')){
                        errorCallback("optOutKeywords","Opt-out Keyword must contain either alphanumeric or accented characters!");
                    }else {
                        errorCallback("optOutKeywords","");
                    }
                });
            }else {
                errorCallback("optOutKeywords","");
            }
        }

        if (name === "optOutMessage"){
            if (value.trim() === ""){
                errorCallback("optOutMessage","Opt-out Message  is required!");
            }else if(value.length < 20 || value.length > 320){
                errorCallback("optOutMessage","Opt-out Message  can not be less than 20 characters and more than 320 characters!");
            }else {
                errorCallback("optOutMessage","");
            }
        }

        if(name === "helpKeywords"){
            if (value.trim() === ""){
                errorCallback("helpKeywords","Help is required!");
            }else if (value.split(",").length > 0){
                const helperKeywordArray = value.split(",");

                helperKeywordArray.forEach((keyword)=>{
                    let index = keyword.indexOf(" ");

                    if(keyword === ""){
                        errorCallback("helpKeywords","Remove trailing comma from the Help keywords!");
                    }else if (keyword.length > 255){
                        errorCallback("helpKeywords","Each Help keyword must be less then 255!");
                    }else if (index >= 0){
                        errorCallback("helpKeywords","Help Keyword cannot contain white-space!");
                    }else if(keyword.includes('_') || keyword.includes('-')){
                        errorCallback("helpKeywords","Help Keyword must contain either alphanumeric or accented characters!");
                    }else {
                        errorCallback("helpKeywords","");
                    }
                });
            }else {
                errorCallback("helpKeywords","");
            }
        }

        if (name === "helpMessage"){
            if (value.trim() === ""){
                errorCallback("helpMessage","Help Message  is required!");
            }else if(value.length < 20 || value.length > 320){
                errorCallback("helpMessage","Help Message  can not be less than 20 characters and more than 320 characters!");
            }else {
                errorCallback("helpMessage","");
            }
        }

        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>Before the phone companies are willing to approve your messaging campaigns they need to review some examples of the types of messages you intend to send. Complete the info below.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                      <span className={`${Styles.label} ${Styles.dFlex} ${Styles.justifySpaceBetween}`}>
                        <span>
                            How do end users consent to receive messages from you?
                            <span className={Styles.required}> *</span>
                        </span>
                          {
                              formData.useCase &&
                              <span onClick={(event)=> {
                                  // A2pSuggestion({
                                  //     suggestionTitle: "Message Flow Suggestions",
                                  //     suggestions: CODE_WISE_DATA.MESSAGE_FLOW,
                                  //     onChange: (value) => {
                                  //         handleFormDataChange("messageFlow", value)
                                  //     }
                                  // });

                                  A2pLearnMore({
                                      title: "How do end-users consent to receive messages?",
                                      innerBody: <MessageFlowLearnMore/>
                                  });
                              }} className={Styles.smallButton}>Suggestions</span>
                          }
                      </span>
                        <p className={Styles.superHelper}>10DLC regulations require that all contacts opt-in to receive messages from your company. The phone companies are looking to see the exact method you use to obtain this consent. The rules and the language they are looking for are very specific. To make this easier we recommend using our pre-filled answer below.  We've also included a link to a pre-built, customized opt-in form for your business. <br/><b>(Recommended - use our pre-filled answer)</b></p>
                        <CustomInput
                            placeholder={"Example: \n" +
                                "End users can opt-out to receive messages by visiting our website and filling out a form. Before submitting the form there are presented with a notice that by submitting the form they are agreeing to be contacted by our business via text.\n" +
                                "\n" +
                                "Example: \n" +
                                "End users can opt-out by texting START to (111) 222-3333."}
                            name="messageFlow"
                            value={formData.messageFlow}
                            onChange={(e)=>{handleFormDataChange("messageFlow", e.target.value)}}
                            multiline
                        />
                        {renderErrorMessage("messageFlow")}
                        <span className={Styles.multilineInputLimit}>Min length: 40 characters. Max length: 2084 characters.</span>
                    </FormControl>
                    {/*<FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>*/}
                    {/*  <span className={Styles.label}>*/}
                    {/*    Opt-out Keywords*/}
                    {/*    <span className={Styles.required}> *</span>*/}
                    {/*  </span>*/}
                    {/*    <CustomInput*/}
                    {/*        placeholder="Add your opt-out keywords separated by comma. Examples: Subscribe, Stop, OptOut etc."*/}
                    {/*        name="optOutKeywords"*/}
                    {/*        value={formData.optOutKeywords}*/}
                    {/*        onChange={(e)=>{handleFormDataChange("optOutKeywords", e.target.value)}}*/}
                    {/*        multiline*/}
                    {/*    />*/}
                    {/*    {renderErrorMessage("optOutKeywords")}*/}
                    {/*    <span className={`${Styles.italicMessage} ${Styles.mbm}`}>Opt-out keywords are keywords your contacts can text you to automatically opt-out to receive messages.We recommend using the examples we provide above, but you can also add your own.</span>*/}
                    {/*    <span className={Styles.multilineInputLimit}>Max length: 255 characters.</span>*/}
                    {/*</FormControl>*/}
                    {/*<FormControl ref={el => inputScrollToViewRef.current[2] = el} className={Styles.formControl}>*/}
                    {/*  <span className={Styles.label}>*/}
                    {/*    Opt-out Message*/}
                    {/*    <span className={Styles.required}> *</span>*/}
                    {/*  </span>*/}
                    {/*    <CustomInput*/}
                    {/*        placeholder={"Example:\n" +*/}
                    {/*            "\n" +*/}
                    {/*            "Hi Joe this is Jeff from XYZ Insurance. You are now opted in to received messages from our business. Reply STOP to unsubscribe."}*/}
                    {/*        name="optOutMessage"*/}
                    {/*        value={formData.optOutMessage}*/}
                    {/*        onChange={(e)=>{handleFormDataChange("optOutMessage", e.target.value)}}*/}
                    {/*        multiline*/}
                    {/*    />*/}
                    {/*    {renderErrorMessage("optOutMessage")}*/}
                    {/*    <span className={Styles.multilineInputLimit}>Min length: 20 characters. Max length: 320 characters.</span>*/}
                    {/*</FormControl>*/}
                    {/*<FormControl ref={el => inputScrollToViewRef.current[3] = el} className={Styles.formControl}>*/}
                    {/*  <span className={Styles.label}>*/}
                    {/*    Help Keywords*/}
                    {/*    <span className={Styles.required}> *</span>*/}
                    {/*  </span>*/}
                    {/*    <CustomInput*/}
                    {/*        placeholder="Add your help keywords separated by comma. Examples: Help, NeedHelp, Assist etc."*/}
                    {/*        name="helpKeywords"*/}
                    {/*        value={formData.helpKeywords}*/}
                    {/*        onChange={(e)=>{handleFormDataChange("helpKeywords", e.target.value)}}*/}
                    {/*        multiline*/}
                    {/*    />*/}
                    {/*    {renderErrorMessage("helpKeywords")}*/}
                    {/*    <span className={`${Styles.italicMessage} ${Styles.mbm}`}>Help keywords are keywords your contacts can text you to automatically to get any inquiry or help via messages.We recommend using the examples we provide above, but you can also add your own.</span>*/}
                    {/*    <span className={Styles.multilineInputLimit}>Max length: 255 characters.</span>*/}
                    {/*</FormControl>*/}
                    {/*<FormControl ref={el => inputScrollToViewRef.current[4] = el} className={Styles.formControl}>*/}
                    {/*  <span className={Styles.label}>*/}
                    {/*    Help Message*/}
                    {/*    <span className={Styles.required}> *</span>*/}
                    {/*  </span>*/}
                    {/*    <CustomInput*/}
                    {/*        placeholder={"Example:\n" +*/}
                    {/*            "\n" +*/}
                    {/*            "Hi Joe this is Jeff from XYZ Insurance. You are now opted in to received messages from our business. Reply STOP to unsubscribe."}*/}
                    {/*        name="helpMessage"*/}
                    {/*        value={formData.helpMessage}*/}
                    {/*        onChange={(e)=>{handleFormDataChange("helpMessage", e.target.value)}}*/}
                    {/*        multiline*/}
                    {/*    />*/}
                    {/*    {renderErrorMessage("helpMessage")}*/}
                    {/*    <span className={Styles.multilineInputLimit}>Min length: 20 characters. Max length: 320 characters.</span>*/}
                    {/*</FormControl>*/}
                </div>
            </div>
        </div>
    );
}

export default EndUserConsent;