import { sendRequest, sendRequestBackEnd } from './rootApi';
import Api from "../constants/Api";

export const createNewCampaign = async (payload) => {
    return sendRequest("post", Api.createNewCampaign, payload);
}

export const getUserGroup = async (payload) => {
    return sendRequest("post", Api.getTeamUsers, payload);
}

export const getCalendarWidgets = async (payload) => {
    return sendRequest("post", Api.getCalendarWidgets, payload);
}

export const getCalendarWidgetDetails = async (payload) => {
    return sendRequest("post", Api.getCalendarWidgetDetails, payload);
}

export const getContacts = async (payload) => {
    return sendRequest("post", Api.getContacts, payload.form_data);
}

export const saveActivityFollowup = async (payload) => {
    return sendRequest("post", Api.saveActivityFollowup, payload);
}

export const getContactsFromTopbar = async (payload) => {
    return sendRequest("post", Api.searchContacts, payload);
}

export const getContactsFromTopbarNew = async (payload) => {
    return sendRequest("post", Api.searchContactsNew, payload);
}

export const getCodeSnippet = async (payload) => {
    return sendRequest("post", Api.getCodeSnippet, payload);
}

export const getCopyTriggerSetting = async (payload) => {
    return sendRequest("post", Api.getCopyTriggerSetting, payload);
}

export const getCopyCampaignSetting = async (payload) => {
    return sendRequest("post", Api.getCopyCampaignSetting, payload);
}

export const duplicateSharedSetting = async (payload) => {
    return sendRequest("post", Api.duplicateSharedSetting, payload);
}

export const getCopyContactSetting = async (payload) => {
    return sendRequest("post", Api.getCopyContactSetting, payload);
}

export const duplicateSharedContact = async (payload) => {
    return sendRequest("post", Api.duplicateSharedContact, payload);
}

export const duplicateSharedCampaign = async (payload) => {
    return sendRequest("post", Api.duplicateSharedCampaign, payload);
}

export const redirectNylasHosted = async (payload) => {
    return sendRequest("post", Api.redirectNylasHosted, payload);
}

export const unsubscribePublicContact = async (payload) => {
    return sendRequest("post", Api.unsubscribePublicContact, payload);
}

export const searchContactsFromElastic = async (method, payload,queryparams =null) => {
    return sendRequestBackEnd(method, Api.searchContactFromElastic, payload, queryparams);
}

export const getAgencyTwilioSurChargeApi = async () => {
    return sendRequest("post", Api.getAgencyTwilioSurCharge);
}

export const viewUserMapFeatureApi = async () => {
    return sendRequest("post", Api.viewUserMapFeatureApi);
}

/* for global custom fields api */
export const getCustomFieldAndPersonalizedFieldApi = async (payload) => {
    return sendRequest("post", Api.getCustomFieldAndpersonalizedFieldGlobal, payload);
}

export const fetchFormList = async (payload) => {
    return sendRequest("post", Api.fetchFormList, payload);
}

export const getUserSettingDataApi = async (payload) => {
    return sendRequest("post", Api.getUserSettingData, payload);
}

export const getTwilioCampaignUseCaseApi = async (payload) => {
    return sendRequest("POST", Api.getTwilioCampaignUseCase, payload);
}

export const submitStandardPolicyApi = async (payload) => {
    return sendRequest("post", Api.submitTwilioMigrationInfo, payload);
}

export const createCampaignUseCaseV2Api = async (payload) => {
    return sendRequest("POST", Api.createCampaignUseCaseV2, payload);
}

export const createCampaignUseCaseUpdateBrandV2Api = async (payload) => {
    return sendRequest("POST", Api.createCampaignUseCaseUpdateBrandV2, payload);
}

export const requestForSoleProprietorRegistrationApi = async (payload) => {
    return sendRequest("POST", Api.requestForSoleProprietorRegistration, payload);
}

export const closeA2pModalApi = async (payload) => {
    return sendRequest("post", Api.closeA2pModal, payload);
}

export const hideA2pPopupPermanentlyApi = async (payload) => {
    return sendRequest("post", Api.hideA2pPopupPermanently, payload);
}

export const checkUserA2pPopupRequiredApi = async (payload) => {
    return sendRequest("POST", Api.checkUserA2pPopupRequired, payload);
}

export const form10DlcLink = () => {
    return sendRequestBackEnd("GET", Api.form10DlcLink);
}

export const getUserConnectedCalendarList=()=>{
    return sendRequest("POST", Api.fetchUserConnectedCalendarList);
}