import CustomCircleLoader from "../../../CustomCircleLoader";
import DirectMailIcons from "../../../DirectMailIcons";

const RightSideFooterButton = ({
    handleForward,
    typeYourMessageSteps,
    handleDataChange,
    handleStepChange,
    generatePdf
}) => {

    const renderNextButtonClass = () => {
        if(!generatePdf && typeYourMessageSteps.previewImage == null){
            return 'global_post_card_disabled_btn'
        }
        else if(generatePdf && typeYourMessageSteps.message === "" && typeYourMessageSteps.thirdPageText === ""){
            return 'global_post_card_disabled_btn'
        }
        return '';
    }

    const renderForwardButton = () => {
        if(!generatePdf){
            return (
                <button className={`dmpc_tv_right_inner_savebtn ${renderNextButtonClass()}`} onClick={handleForward}>
                    <span>{DirectMailIcons.saveContinueIcon}</span>
                    <span>Save & Next</span>
                </button>
            ); 
        }
        return (
            <button className={`dmpc_tv_right_inner_savebtn ${renderNextButtonClass()}`} onClick={handleForward}>
                {typeYourMessageSteps.isProcessingPdf ?
                    <span className="dmpc_btn_circle_loader"><CustomCircleLoader size="small" /></span>
                :
                    <span>{DirectMailIcons.saveContinueIcon}</span>
                }
                <span>{typeYourMessageSteps.pdfUrl === "" ? 'Generate Pdf' : 'Save & Next'}</span>
            </button>
        );
    }
    return (
        <div className='dmpc_tv_right_inner_btn'>
            <button className='dmpc_tv_right_inner_backbtn' onClick={() => handleStepChange(false)}>
                <span>{DirectMailIcons.backIcon}</span>
                <span>Go Back</span>
            </button>
            {renderForwardButton()}
            {typeYourMessageSteps.pdfUrl !== "" &&
            <button className='dmpc_tv_right_inner_savebtn' onClick={() => handleDataChange({isShowingPdf: !typeYourMessageSteps.isShowingPdf})}>
                <span>{typeYourMessageSteps.isShowingPdf ? DirectMailIcons.invisibility : DirectMailIcons.visibility}</span>
                <span>{typeYourMessageSteps.isShowingPdf ? 'Hide ' : 'Show '} Pdf</span>
            </button>
            }
        </div>
    );
}
export default RightSideFooterButton;