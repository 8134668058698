import {useContext, useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import ApiList from "./ApiList"
import { icons } from "./icons"
import { ContactDetailContext } from "./reducer/GlobalReduers"
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ContactHelper from './Helper';
import UpdateContactInfo from './moreInfo/update-contact/UpdateContactInfo';
import './css/aditionalInfomodal.css'

const EachItem = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [edit, setEdit] = useState(false)
    const [submitEditing, setSubmitEditing] = useState(false)
    const [text, setText] = useState(props.data)

    const handleEditSubmit = () => {
        if(props.checkValidation != undefined){
            let validation = props.checkValidation(text)
            if(validation != ''){
                if(window.showNotification !== undefined){
                    window.showNotification("ERROR", validation);
                }
            return
            }
        }
        setSubmitEditing(true)
        let formData = {
            'contact_id': detailStates.contactId,
            'text': text,
            'field' : props.field
        }
        ApiList.updateContactField(formData).then(res => {
            let response = res.data;
            if(response.status == 'success'){
                if(props.updateValue != undefined){
                    props.updateValue(text)
                }
                setEdit(false)
                if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                    detailStates.dealCallback(true)
                    // props.reloadDealStage(true)
                }
            }
            else{
                if(response.status == 'validation-error'){
                    let msg = response.html.number != undefined ? response.html.number[0] : (response.html.email != undefined ? response.html.email[0] : '')
                    if(msg != ''){
                        if(window.showNotification !== undefined){
                            window.showNotification("ERROR", msg);
                        }
                    }
                }
                else if(response.status == 'error'){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", response.html);
                    }
                }
            }
            setSubmitEditing(false)
        })
    }

    const handleCloseEdit = () => {
        setText(props.data)
        setEdit(false)
    }
  
    let icon = (props.data === undefined || props.data === null || props.data.trim() == '') ? <AddIcon /> : <EditIcon />
    
    return(
        <li className="g-basic-info-each">
            <span>{props.icon}</span> 
            {!edit &&
            <>
                {(props.data === null || props.data.trim() == '') ? props.title : props.data}
                <span className="g-each-item-icon" onClick={() => setEdit(!edit)}>{icon}</span>
            </>
            }
            {edit &&
            <div className="g-each-item-edit">
                <input type="text" defaultValue={props.data} onChange={(event) => setText(event.target.value)} />
                {!submitEditing && 
                <>
                <span onClick={handleEditSubmit}><CheckIcon /></span>
                <span onClick={handleCloseEdit}><CloseIcon /></span>
                </>
                }
                {submitEditing && 
                <span ><MoreHorizIcon fontSize="small" /></span>
                }
            </div>
            }
        </li>
    )
}

const BasicInfo = props => {
    const {detailStates} = useContext(ContactDetailContext)
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [isShowEditModal, setIsShowEditModal] = useState(false)
    const [titleAddContact,setTitleAddContact] = useState("Add Contact")

    useEffect(() => {
        if(detailStates.contactDetails !== null){
            setNumber(detailStates.contactDetails.number);
            setEmail(detailStates.contactDetails.email);

            if(detailStates.contactDetails.address !=null && detailStates.contactDetails.address != ""){
                setTitleAddContact("Update Contact")
            }
            else if(detailStates.contactDetails.city !=null && detailStates.contactDetails.city != ""){
                setTitleAddContact("Update Contact")
            }
            else if(detailStates.contactDetails.state !=null && detailStates.contactDetails.state != ""){
                setTitleAddContact("Update Contact")
            }
            else if(detailStates.contactDetails.zip !=null && detailStates.contactDetails.zip != ""){
                setTitleAddContact("Update Contact")
            }
            else if(detailStates.contactDetails.country !=null && detailStates.contactDetails.country != ""){
                setTitleAddContact("Update Contact")
            }
        }
    }, [detailStates.contactDetails])

    const handleEditModal = (status = false) => {
        setIsShowEditModal(status)
        props.refresh()
    }

    return(
        <div className="g-basic-info-content">
            <div className="g-basic-info g-left-side">
                <h5 className="g-basic-info-title">Basic Info</h5>
                <ul className="g-basic-info-lists">
                    <EachItem
                        data={number}
                        icon={icons.callIconInList}
                        field={'number'}
                        title="Add Number"
                        checkValidation={(value) => {
                            if(value === "" || value.trim() === ""){
                                return "";
                            }
                            let invalid = ''
                            if(/^\d+$/.test(value)){
                                if(!ContactHelper.validateMobileNumber(value)){
                                    invalid = 'Invalid number'
                                }
                            }
                            else{
                                invalid = 'Invalid number'
                            }
                            return invalid;
                        }} 
                        updateValue={(value) => setNumber(value)}
                    />

                    <EachItem
                        data={email}
                        icon={icons.emailIconInListForSingle}
                        field={'email'}
                        title="Add Email"
                        checkValidation={(value) => {
                            if(value === "" || value.trim() === ""){
                                return "";
                            }
                            let invalid = ''
                            if(!ContactHelper.validateEmail(value)){
                                invalid = 'Invalid email'
                            }
                            return invalid;
                        }}
                        updateValue={(value) => setEmail(value)}
                    />

                    <li className="g-basic-info-each">
                        <span>{icons.companyMarkerIcon}</span>
                        {ContactHelper.isEmpty(detailStates.contactDetails?.company_name) ? 'Add Company' : detailStates.contactDetails?.company_name}
                        <span className="g-each-item-icon" onClick={()=> setIsShowEditModal(true)}>
                            {ContactHelper.isEmpty(detailStates.contactDetails?.company_name) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="g-basic-info-each">
                        <span>{icons.mapMarkerIcon}</span>
                        {ContactHelper.isEmpty(detailStates.contactDetails?.address) ? 'Add Address' : detailStates.contactDetails?.address}
                        <span className="g-each-item-icon" onClick={()=> setIsShowEditModal(true)}>
                            {ContactHelper.isEmpty(detailStates.contactDetails?.address) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>
                    <li className="g-basic-info-each g-go-to-profile"><Link to={`/contacts/${detailStates.contactDetails?.id}`} target="_blank"><span>Go to Profile</span></Link></li>
                </ul>
            </div>
            <div className="g-basic-info g-right-side">
                <h5 className="g-basic-info-title">Additional Info</h5>
                <ul className="g-basic-info-lists">
                    <li className="g-basic-info-each">
                        <span>{icons.mapMarkerIcon}</span>
                        {ContactHelper.isEmpty(detailStates.contactDetails?.city) ? 'Add City' : detailStates.contactDetails?.city}
                        <span className="g-each-item-icon" onClick={()=> setIsShowEditModal(true)} >
                            {ContactHelper.isEmpty(detailStates.contactDetails?.city) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="g-basic-info-each">
                        <span>{icons.mapMarkerIcon}</span>
                        {ContactHelper.isEmpty(detailStates.contactDetails?.state) ? 'Add State' : detailStates.contactDetails?.state}
                        <span className="g-each-item-icon" onClick={()=> setIsShowEditModal(true)} >
                            {ContactHelper.isEmpty(detailStates.contactDetails?.state) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="g-basic-info-each">
                        <span>{icons.mapMarkerIcon}</span>
                        {ContactHelper.isEmpty(detailStates.contactDetails?.country) ? 'Add Country' : detailStates.contactDetails?.country}
                        <span className="g-each-item-icon" onClick={()=> setIsShowEditModal(true)} >
                        {ContactHelper.isEmpty(detailStates.contactDetails?.country) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="g-basic-info-each">
                        <span>{icons.mapMarkerIcon}</span>
                        {ContactHelper.isEmpty(detailStates.contactDetails?.zip) ? 'Add zip' : detailStates.contactDetails?.zip}
                        <span className="g-each-item-icon" onClick={()=> setIsShowEditModal(true)} >
                        {ContactHelper.isEmpty(detailStates.contactDetails?.zip)  ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>
                </ul>
            </div>
            
            {/* contact address info update */}
            {isShowEditModal &&
                <UpdateContactInfo 
                    open={isShowEditModal} 
                    customClass="additional__info__modal__wr"
                    onClose={handleEditModal} 
                    data={detailStates.contactDetails}
                    title = {titleAddContact}
                />
            }
        </div>
    )
}
export default BasicInfo;