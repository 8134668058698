import React, { useState, useEffect } from "react";
import { getSignUpPageData, selectPackage } from "../../actions/signupAction";
import {connect} from 'react-redux';
import Payment from "./Payment";
import { Grid } from '@material-ui/core';

const App = (props) => {
    const [packages, setPackages] = useState(props.packages);
    const [selectedPackage, setSelectedPackage] = useState(props.selectedPackage);
    const [isTrialPage, setIsTrialPage] = useState(false);

    useEffect(() => {
        setPackages(props.packages);

        if(props.packages[0] != undefined){
            if(props.packages[0].trial_pack == 1){
                setIsTrialPage(true);
            }
        }
    }, [props.packages]);

    useEffect(() => {
        setSelectedPackage(props.selectedPackage);
    }, [props.selectedPackage]);


    const renderPackages = () => {
        let packageList = [];
        if (packages.length) {
            packages.map(function (row, index) {

                let packagePerDayString = row.life_line + 'days';
                if(parseInt(row.life_line) == 30){
                    packagePerDayString = 'MO';
                }else if(parseInt(row.life_line) == 90){
                    packagePerDayString = 'Quarterly';
                }else if(parseInt(row.life_line) == 180){
                    packagePerDayString = 'Bi Yearly';
                }else if(parseInt(row.life_line) == 365){
                    packagePerDayString = 'Yearly';
                }else if(parseInt(row.life_line) > 365){
                    packagePerDayString = 'Yr +';
                }

                packageList.push(
                    <Grid item xs={4} key={index}>
                        <div className="mt-3 my-1">
                            <div className="card first_price_list">
                                <div className="card-content center px-1">
                                    <h5>{row.name}</h5>
                                </div>
                                <div className="card-content price_content  secong_pric_list  center">
                                    <h2><sup>$</sup>{row.price}<span>/{packagePerDayString}</span></h2>
                                </div>
                                <ul className='collection pric_list_content center'>
                                    <li className='collection-item'>
                                        <strong>Text Messaging</strong>
                                    </li>
                                    <li className='collection-item'>
                                        <strong>Ringless Vmail</strong>
                                    </li>
                                    <li className='collection-item'>
                                        <strong>Video Email</strong>
                                    </li>
                                    <li className='collection-item'>
                                        <strong>Call Bridge</strong>
                                    </li>
                                    <li className='collection-item'>
                                        <strong>Inbound/Outbound Calling</strong>
                                    </li>
                                    <li className='collection-item'>
                                        <strong>Marketing Automation</strong>
                                    </li>
                                    {(props.agencyId !== undefined && props.agencyId != null && props.agencyId === 1) &&
                                        <>
                                        <li className='collection-item'>
                                            <strong>Direct Mail</strong>
                                        </li>
                                        <li className='collection-item'>
                                            <strong>Lead Finder</strong>
                                        </li>
                                        </>
                                    }
                                </ul>
                                <div className="card-content center">
                                    <div className="select_plan_btn">
                                        {
                                            isTrialPage ?
                                                <button onClick={() => props.selectPackage(row)} className="btn">Free Trial</button>
                                                :
                                                <button onClick={() => props.selectPackage(row)} className="btn">Select Plan</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                );
            });
        }

        return packageList;
    }

    return (
        <React.Fragment>
            {!selectedPackage &&
            <div className="packages_content center-align">
                <h1>Choose Your Package</h1>
                <h6>Select an option below. {isTrialPage ? 'You will not be charged at this time.' : ''}</h6>
                <Grid container className={"packages_content__wrapper__alt"}>
                    { renderPackages() }
                </Grid>
                {
                    isTrialPage?
                        <small>*If you don't cancel within the free trial period, your account will convert to the paid plan chosen above.</small>
                        :
                        ''
                }

            </div>
            }
            {(selectedPackage && props.agencyStripe && props.agencyStripe.value) &&
            <Payment
                stripePublicKey={props.agencyStripe.value}
                privacyPolicy={props.privacyPolicy}
                termsAndConditions={props.termsAndConditions}
            />
            }
        </React.Fragment>

    );
}

const mapStateToProps = state => {
    return {
        validationCheck: state.signupReducer.validationCheck,
        packages: state.signupReducer.packages,
        selectedPackage: state.signupReducer.selectedPackage,
        agencyStripe: state.signupReducer.agencyStripe,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSignUpPageData: (params) => dispatch(getSignUpPageData(params)),
        selectPackage: (params) => dispatch(selectPackage(params)),
    };
};

const SelectPackage = connect(mapStateToProps, mapDispatchToProps)(App);

export default SelectPackage;