import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { getContactDetails } from "../redux/contactDetailAction";
import { updateContactFieldApi } from "../redux/contactDetailApi";
import ModalGlobalModal from "./ModalGlobalModal";

const App = (props) => {
    
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [zipCode, setZipCode] = useState(0)
    const [submitUpdating, setSubmitUpdating] = useState(false)

    useEffect(() => {
      if(props.open){
        setAddress(props.data.address)
        setCity(props.data.city)
        setState(props.data.state)
        setZipCode(props.data.zip)
        setCountry(props.data.country)
      }
    }, [props.open])

    const resetForm = () => {
      setAddress('')
      setCity('')
      setState('')
      setZipCode('')
      setCountry('')
      setSubmitUpdating(false)
    }

    const closeModal = () => {
      resetForm()
      props.onClose()
    }

    const handleSubmit = () => {
      if(submitUpdating) return;
      setSubmitUpdating(true)
      let formData = {
        'contact_id': props.data.id,
        'field' : 'address',
        address: address,
        city: city,
        country: country,
        state: state,
        zip: zipCode
      }
      updateContactFieldApi(formData).then(res => {
        let response = res.data
        if(response.status == 'success'){
          props.getContactDetails({
            id: props.data.id
          })
          closeModal()
        }
        else{
          alert(response.html)
        }
        setSubmitUpdating(false)
      })
    }
  
    return <ModalGlobalModal              
              open={props.open} 
              title="Update Contact" 
              onClose={closeModal}
              buttonText={'Update Contact'}
              buttonIcon={''}
              handleSubmit={handleSubmit}
              width={800}
              className="update__deal_modal"
            >             
            
            <h3 className="trigger__modal_title">Address</h3>
            <input className="trigger__modal_textField dealValue setFullWidth global-contact-details-update-input" value={address} onChange={(event) => setAddress(event.target.value)} placeholder="Enter address" />
            
            <h3 className="trigger__modal_title">State</h3>
            <input className="trigger__modal_textField dealValue setFullWidth global-contact-details-update-input" value={state} onChange={(event) => setState(event.target.value)} placeholder="Enter state" />
            
            <h3 className="trigger__modal_title">City</h3>
            <input className="trigger__modal_textField dealValue setFullWidth global-contact-details-update-input"value={city} onChange={(event) => setCity(event.target.value)} placeholder="Enter city" />
            
            <h3 className="trigger__modal_title">Country</h3>
            <input className="trigger__modal_textField dealValue setFullWidth global-contact-details-update-input" value={country} onChange={(event) => setCountry(event.target.value)} placeholder="Enter country" />
            
            <h3 className="trigger__modal_title">Zip code</h3>
            <input className="trigger__modal_textField dealValue setFullWidth global-contact-details-update-input" min="0" value={zipCode} onChange={(event) => setZipCode(event.target.value)} placeholder="Enter zip code" />
          </ModalGlobalModal>;
}
 
const mapDispatchToProps = dispatch => ({
  getContactDetails : (params, callback) => dispatch(getContactDetails(params, callback)),
})
const UpdateContactInfo = connect(null, mapDispatchToProps)(App);
export default UpdateContactInfo;