import React from "react";
import Styles from "./Style.module.css";

const CampaignUseCaseLearnMore = () => {
    return(
        <div className={Styles.learnMoreInnerWrapper}>
            <section className={Styles.learnMoreSection}>
                <p className={`${Styles.learnMoreDesc} ${Styles.marginBottom20}`}>Use case that most closely resembles your campaign.</p>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Successful Example:</h5>
                <p className={Styles.learnMoreDesc}>
                    2FA use case is selected for any authentication or account verification such as OTP.
                    <p>(Use case matches campaign description and sample messages.)</p>
                </p>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Failure Example:</h5>
                <p className={Styles.learnMoreDesc}>
                    Higher Education is selected for any authentication or account verification such as OTP.
                    <p>(Use case would not match intended use, campaign will be rejected.)</p>
                </p>
            </section>
        </div>
    );
}

export default CampaignUseCaseLearnMore;