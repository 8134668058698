import React from "react";
import Styles from "./Style.module.css";

const CampaignUseCaseDescriptionLearnMore = () => {
    return(
        <div className={Styles.learnMoreInnerWrapper}>
            <section className={Styles.learnMoreSection}>
                <p className={`${Styles.learnMoreDesc} ${Styles.marginBottom5}`}>Explanation of Campaign objective or purpose.</p>
                <ul className={Styles.learnMoreDescUl}>
                    <li className={Styles.learnMoreDesc}>If the Campaign pertains to financial services, please include "Direct Lending" in the Campaign description to avoid failure or rejection of this Campaign.</li>
                </ul>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Successful Example:</h5>
                <p className={Styles.learnMoreDesc}>"This Campaign sends OTP for MFA challenges to login to an online portal to make changes to a user profile for security purposes. Customer confirms with an SMS when profile changes are made."
                <p>(Campaign description matches sample message and sample message)</p>
                </p>
            </section>
            <section className={Styles.learnMoreSection}>
                <h5 className={Styles.learnMoreTitle}>Failure Example:</h5>
                <p className={Styles.learnMoreDesc}>"This Campaign sends appointment information and confirmations to customer"</p>
            </section>
        </div>
    );
}

export default CampaignUseCaseDescriptionLearnMore;