import React,{useState, useEffect, useContext} from 'react';
import {connect} from 'react-redux'
import BootstrapTooltip from '../common/BootstrapTooltip';
import { DirectMailIcons } from './assets/images/directMailIcons';
import Loading from './assets/Loading';
import { CardMultiStepContext } from './CardMultiStepForm';
import GiftImagePopover from './GiftImagePopover';
import { getCategoryList, storeMultipleReducers } from './redux/cardBroadcastAction';

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GIFT = 11;
const NEXTSTEPNO = 2;

const FirstStep = (props) => {

    const cardMultiStepContext = useContext(CardMultiStepContext)

    const [giftList, setGiftList] = useState([])
    const [hasGiftInSystem, setHasGiftInSystem] = useState(true)

    const [loadingGift, setLoadingGIFT] = useState(true)


    useEffect(() => {
        if (cardMultiStepContext.messageType === GIFT || cardMultiStepContext.messageType === THANKSIO_GIFT) {
            getGiftList();
        }
    }, []);

    const getGiftList = async() => {
            await props.getCategoryList({ 'message_type': cardMultiStepContext.messageType }, (response) => {
                let gifts = response.data.gifts;
                setGiftList(gifts)
                let selectedGift = cardMultiStepContext.messageType === THANKSIO_GIFT ? gifts[Object.keys(gifts)[0]]['brands'][0] : gifts[0];
                props.storeMultipleReducers({selectedGift : selectedGift})
                setHasGiftInSystem(response.data.has_system_gift)
                setLoadingGIFT(false)
            });
        
    }

    const getGiftTitle = (title) => {
        return(
            <BootstrapTooltip title={title} arrow placement="top">
                <span className="gift_product_title____alt">{title.length > 26 ? (title.substring(0, 23)+"...") : title}</span>
            </BootstrapTooltip>
        )
    }

    const getFormattedPrice = (price) => {
        if(price === null) {
            return "";
        }
        return '$'+parseFloat(price).toFixed(2);
    }

    const showZendirectGifts = () => {
        return giftList.map((giftInfo, index) => (
            <GiftImagePopover key={giftInfo.gift_product_id} imageUrl={giftInfo.gift_image_url} data={giftInfo}>
                <div className={"select-photo-size-single " + (props.selectedGift.gift_product_id === giftInfo.gift_product_id ? "is_select" : "")}
                    onClick={() => props.storeMultipleReducers({selectedGift : giftInfo})}>
                    <img src={giftInfo.gift_image_url} alt="gift"/>
                    { getGiftTitle(giftInfo.gift_product_name) }
                    <span className='gift_product_price_span'>{getFormattedPrice(giftInfo.agency_gift_price)}</span>
                </div>
            </GiftImagePopover>

        ))
    }

    const showThanksIOGifts = () => {
        let giftsArr = [];
        for(let giftGroupKey in giftList) {
            let eachGiftGroup = giftList[giftGroupKey];
            giftsArr.push(<p className='trigger__modal_title w-100'>{eachGiftGroup.title}</p>);

            giftsArr.push(eachGiftGroup.brands.map(eachGift => (
                <GiftImagePopover key={eachGift.brand_code} imageUrl={eachGift.image}>
                    <div className={"select-photo-size-single " + (props.selectedGift.brand_code === eachGift.brand_code ? "is_select" : "")}
                        onClick={() => props.storeMultipleReducers({selectedGift : eachGift})}>
                        <img src={eachGift.image} alt="gift"/>
                        { getGiftTitle(eachGift.title) }
                    </div>
                </GiftImagePopover>
            )));
        }
        return giftsArr;
    }

    return (
        <React.Fragment>

            <div className={"fieldset__fixed__height "+ (props.loadingThirdPartyInfo? 'fieldset__fixed__dynamic__height':'')}>
            <fieldset>
                <div className="custom-form-card form-card">
                    <div className="row">
                        <div className="col s12">
                            <div className="first-step-page-main2 first-step-page-main">
                                <div className="first-page-inner-item">
                                <span>Select Your {cardMultiStepContext.messageType === POSTCARD ? 'Postcard Size' : 'GIFT'}</span>
                                </div>
                                <div className="select__photo__size__box"> 
                                    <div className="select__photo__size__box_one">

                                    {props.cardSizes.length > 0 && (cardMultiStepContext.messageType === POSTCARD || cardMultiStepContext.messageType === LETTER) &&

                                        props.cardSizes.map((postcardSize, index) => (
                                            <div className={"select-photo-size-single " + (props.selectedCardSize === postcardSize ? "is_select" : "")}
                                                onClick={() => props.storeMultipleReducers({selectedCardSize :postcardSize})} key={index}>
                                                <a href="#!" onClick={(e)=>e.preventDefault()}>
                                                    {DirectMailIcons.postcardFirstStepCard}
                                                </a>
                                                <span>{postcardSize}</span>
                                                {
                                                    cardMultiStepContext.messageType === POSTCARD && props.directMailPrice[cardMultiStepContext.messageType+"_"+postcardSize] &&
                                                    <p className='m-0 text-gray'>${parseFloat(props.directMailPrice[cardMultiStepContext.messageType+"_"+postcardSize]).toFixed(2)}</p>
                                                }
                                                
                                            </div>
                                        ))
                                    }

                                    {((cardMultiStepContext.messageType === GIFT || cardMultiStepContext.messageType === THANKSIO_GIFT) && loadingGift) ?
                                        <Loading /> : cardMultiStepContext.messageType === GIFT ? showZendirectGifts() : showThanksIOGifts()
                                    }
                                    </div>                                  
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <!-- common button multi --> */}
            </fieldset>
           
            </div>
        </React.Fragment>

    )
}

const mapStateToProps = state => {
    return {
       categoryList: state.cardBroadcastReducer.categoryList,
       selectedCardSize: state.cardBroadcastReducer.selectedCardSize,
       cardSizes: state.cardBroadcastReducer.cardSizes,
       selectedGift: state.cardBroadcastReducer.selectedGift,
       loadingThirdPartyInfo: state.cardBroadcastReducer.loadingThirdPartyInfo,
       directMailPrice: state.cardBroadcastReducer.directMailPrice
    };
 };
 
 const mapDispatchToProps = dispatch => {
    return {
       getCategoryList: (params, callback) => dispatch(getCategoryList(params, callback)),
    //    storeSelectedSize: (params, callback) => dispatch(storeSelectedSize(params, callback)),
       storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
 }
 
 export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);
