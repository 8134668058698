import React, {useEffect, useState} from "react";
import {Button, Grid} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ConnectEmail from "./ConnectEmail";
import AskOption from "./AskOption";
import NylasActivatingPage from "./NylasActivatingPage";
import NylasComplete from "./NylasComplete";

export const clearQueryParams = () => {
    let url = window.location.href;
    let parsedUrl = new URL(url);
    parsedUrl.search = '';
    window.history.replaceState({}, document.title, parsedUrl.toString());
}

const NylasConnectEmail = (props) => {
    const [showForm, setShowForm] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { code } = Object.fromEntries(urlSearchParams.entries());
    const [showActivating, setActivating] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (code){
         setActivating(true);
        }
    }, []);

    if(showActivating) {
        return <NylasActivatingPage updateSuccessStatus={(callback)=>{ setIsSuccess(callback); }} handleClose={()=>{ setActivating(false) }}/>
    }

    return(
        <Grid container>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="mr-2"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => props.goToStep(props.activeStep-1)}
                    disabled={props.loading}
                >
                    Previous Step
                </Button>
                <p className="onboarding__step__one__title_log_out" onClick={props.logout}>Log Out</p>
            </Grid>
            <Grid item xs={12}>
                {
                    showForm ?
                        <ConnectEmail cancelForm={()=>{ setShowForm(false) }} user={props.data.user} callback={() => props.goToStep(0)}/> :
                        !isSuccess ?
                            <AskOption activeStep={props.activeStep} goToStep={props.goToStep} goConnect={()=>{ setShowForm(true) }}/> :
                            <NylasComplete activeStep={props.activeStep} goToStep={props.goToStep}/>
                }
            </Grid>
        </Grid>
    );
};

export default NylasConnectEmail;