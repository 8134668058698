import React, { useEffect,useState } from 'react';
import {changeIsVideoPlaying} from "./redux/videoEmailAction";
import {connect} from "react-redux";

const RecordedVideo = props => {

    const [videoRefresh, setVideoRefresh] = useState(false)
    const [videoLink, setVideoLink] = useState(null)
    useEffect(() => {
        setVideoRefresh(true)
        setVideoLink(props.urlLink)
        setTimeout(()=>{
            setVideoRefresh(false)
        },500)

    }, [props.urlLink])
    return(
        <div className="video-area">
            <div className="video-inner-old video__inner__custom">
                {!videoRefresh &&
                <video controls playsInline preload="auto" className="video-custom" id="video-static" onPlay={() => props.changeIsVideoPlaying(true)} onPause={() => props.changeIsVideoPlaying(false)}>
                    <source src={videoLink} type="video/mp4" />
                </video>
                }
            </div>
        </div>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        changeIsVideoPlaying: (params) => dispatch(changeIsVideoPlaying(params))
    }
}

export default connect(null, mapDispatchToProps)(RecordedVideo);