import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect, useState } from 'react';
import { getHandWritingStylesApi } from '../../api/ApiAction';
import { HandwritingStyles } from '../handwritingComponent/HandwritingStyles';

const Handwritting = ({value, onChange}) => {

    const [styles, setStyles] = useState([]);

    useEffect(() => {
        getHandWritingStyles();
        // let get_data = localStorage.getItem('pype_direct_mail_handwriting_styles');
        // if(get_data){
        //     try{
        //         let json_parse = JSON.parse(get_data)
        //         setStyles(json_parse)
        //         if(value == null){
        //             onChange(parseInt(json_parse[0].handwriting_style_id))
        //         }
        //     }
        //     catch(error){
        //         getHandWritingStyles();
        //     }
        // }else{
        //     getHandWritingStyles();
        // }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHandWritingStyles = () => {
        getHandWritingStylesApi().then(res => {
            let response = res.data;
            if(response.status === "success") {
                setStyles(response.data)
                if(value == null){
                    onChange(parseInt(response.data[0].handwriting_style_id))
                }
                localStorage.setItem('pype_direct_mail_handwriting_styles', JSON.stringify(response.data))
            }
        }).catch(error => {

        })
    }

    return (
        <HandwritingStyles 
            list={styles}
            selected={value}
            onChangeStyle={onChange}
        />
    );
}
export default Handwritting;