import { useEffect } from "react";
import { getZendirectGiftListApi } from "../../api/ApiAction";
import FooterButton from "./FooterButton";

const GiftSelect = ({giftSelect, handleDataChange, handleStepChange, type}) => {
    useEffect(() => {
        if(giftSelect.list.length === 0){
            getZenddirectGift() 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getZenddirectGift = () => {
        if(giftSelect.isLoading){
            return
        }
        handleDataChange({isLoading: true})
        getZendirectGiftListApi()
        .then(res => {
            let response = res.data;
            handleDataChange({isLoading: false, list: response.data})
        })
        .catch(error => {
            handleDataChange({isLoading: false})
        })
    }

    const _handleStepChange = (value) => {
        if(giftSelect.selected != null){
            handleStepChange(value)
        }
    }

    /* render functions */
    const renderList = () => {
        let view = [];
        giftSelect.list.forEach((item, index) => {
            view.push(
                <div className={`gift_1st_mg_single ${(giftSelect.selected === item || item.id === giftSelect.selected?.id) ? 'gift_1st_mg_single_active' : ''}`} key={index} onClick={() => handleDataChange({selected: item}, true)}>
                    <img className='gift_1st_mg_img' src={item.gift_image_url} alt={item.gift_product_name} />
                    <h4 className='gift_1st_mg_title'>{item.gift_product_name}</h4>
                    <span className='gift_1st_mg_sub'>Price: ${item.agency_gift_price == null ? '---' : parseFloat(item.agency_gift_price).toFixed(2)}</span>
                </div>
            )
        });
        if(view.length === 0){
            return (<span>No gift found !</span>)
        }
        return view;
    }
    return(
        <div className='gifc_1st_middle'>
            <div className='gift_1st_middle_top'>
                <h3 className='gift_1st_mt_title'>Select your Gift</h3>
                <h5 className='gift_1st_mt_subtitle'>Select your gift what you offer to</h5>
            </div>
            {!giftSelect.isLoading &&
            <div className='gift_1st_middle_gallery awesome__scroll_bar'>
                {renderList()}
            </div>
            }
            {giftSelect.isLoading &&
            <div className='gift_1st_middle_gallery1'>
                <span >Please wait...</span>
            </div>
            }
            <FooterButton handleStepChange={_handleStepChange} />
        </div>
    )
}
export default GiftSelect;