import { Button } from "@material-ui/core";
import { submitForTiktokAuth } from "../../api/containerApi";



const TiktokConnect = () => {

    const connectApp = () => {
        submitForTiktokAuth().then((res) => {

            console.log(res);
        });
    }

    return (
        <>
            <Button color="primary" onClick={connectApp}> Connect TIktok</Button>
        </>
    )

}

export default TiktokConnect;