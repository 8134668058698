export const LOOKUP_TYPE = {
    CARRIER_LOOKUP: "CARRIER_LOOKUP",
    EMAIL_LOOKUP: "EMAIL_LOOKUP",
    DNC_LOOKUP: "DNC_LOOKUP",
    SKIP_TRACING_LOOKUP: "SKIP_TRACING_LOOKUP"
};

export const SEARCH_CATEGORY = {
    HOUSE_CURRENT_RESIDENT: "HOUSE_CURRENT_RESIDENT",
    HOUSE_CURRENT_OWNER: "HOUSE_CURRENT_OWNER"
};

export const ACTION = {
    ADD_NEW_CONTACT: "ADD_NEW_CONTACT",
    ADD_UNDER_CONTACT: "ADD_UNDER_CONTACT",
    NO_ACTION: "NO_ACTION"
};

export const REQUEST_MODULE = {
    CONTACT: "CONTACT",
    CLEAN_DATA: "CLEAN_DATA"
};