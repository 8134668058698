import React from 'react';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    MenuItem,
    Select,
    Typography,
  } from "@material-ui/core";
import { BasicInput, BasicInputSelect } from '../../RightSide/RightSideStyle';
import SaveIcon from "@material-ui/icons/Save";
import { Close } from '@material-ui/icons';
const DealDrawer = ({state,setState,selectedPipeline,pipelines,setSelectedPipeline,selectedStorage,setSelectedStorage}) => {
    return (
        <div>
            {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={"right"} open={state} onClose={() => setState(false)}>
            <Box sx={{ width: "700px", padding: "16px" }}>
              <Box
                sx={{
                  marginBottom: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">Create Deal</Typography>
                <Box
                  display={"flex"}
                  sx={{
                    background: "red",
                    color: "white",
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <IconButton onClick={() => setState(false)}>
                    <Close />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box marginTop={"16px"} sx={{ gap: "16px" }}>
                <Box>
                  <Typography>Deal Title</Typography>
                  <Box mt={1}>
                    <BasicInput placeholder="Enter title" fullWidth />
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography>Deal Value</Typography>
                  <Box mt={1}>
                    {" "}
                    <BasicInput placeholder="" fullWidth />
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography>Select Title</Typography>
                  <Box mt={1}>
                    <Select
                      fullWidth
                      name="leadSource"
                      defaultValue={""}
                      value={selectedPipeline}
                      displayEmpty
                      input={<BasicInputSelect />}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {pipelines.map((pipeline) => (
                        <MenuItem
                          key={pipeline.value}
                          onClick={() => setSelectedPipeline(pipeline.value)}
                          className="dropdownhelper-menuitem-class"
                          value={pipeline.value}
                        >
                          {pipeline.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography>Select Storage</Typography>
                  <Box mt={1}>
                    <Select
                      fullWidth
                      name="leadSource"
                      defaultValue={""}
                      value={selectedStorage}
                      displayEmpty
                      input={<BasicInputSelect />}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {pipelines.map((pipeline) => (
                        <MenuItem
                          key={pipeline.value}
                          onClick={() => setSelectedStorage(pipeline.value)}
                          className="dropdownhelper-menuitem-class"
                          value={pipeline.value}
                        >
                          {pipeline.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography>Estimate Close Date</Typography>
                  <Box mt={1}>
                    {" "}
                    <BasicInput type="date" placeholder="" fullWidth />
                  </Box>
                </Box>
              </Box>
              <Box position={"absolute"} bottom={20} width={"95%"}>
                <Divider style={{ padding: "0 10px" }} />
                <Box
                  display={"flex"}
                  sx={{ gap: "10px" }}
                  justifyContent={"center"}
                  mt={3}
                >
                  <Box
                    display={"flex"}
                    sx={{
                      gap: "10px",
                      background: "black",
                      padding: "8px 10px",
                      color: "white",
                      width: "100px",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    alignItems={"center"}
                  >
                    <SaveIcon />
                    <Typography
                      sx={{
                        "&.MuiTypography-body1": {
                          color: "white",
                        },
                      }}
                      color="white !important"
                    >
                      Save
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    sx={{
                      gap: "10px",
                      background: "red",
                      padding: "8px 10px",
                      color: "white",
                      width: "100px",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    alignItems={"center"}
                    onClick={() => setState(false)}
                  >
                    <Close />
                    <Typography
                      sx={{
                        "&.MuiTypography-body1": {
                          color: "white",
                        },
                      }}
                      color="white !important"
                    >
                      Close
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        
        </React.Fragment>
      ))}
        </div>
    );
};

export default DealDrawer;