import React, { useContext, useState, useEffect } from 'react';
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from '../../components/Accordion/CustomAccordion';
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { icons } from '../../icons';
import ApiList from '../../ApiList';
import { List } from './List';
import ListAltIcon from '@material-ui/icons/ListAlt';

const Task = (props) => {
    const { detailStates } = useContext(ContactDetailContext);
    const [expand, setExpand] = useState(false);
    const [activity, setActivity] = useState([]);
    const [doneActivity, setDoneActivity] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDoneTask, setShowDoneTask] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setActivity(props.activity);
    }, [props.activity]);

    useEffect(() => {
        if (expand) {
            getPeopleActivity();
        }
    }, [expand]);

    const refresh = () => {
        getPeopleActivity();
    };

    const getPeopleActivity = () => {
        setLoading(true);
        ApiList.getPeopleActivity({
            contact_id: props.contactDetails?.id
        }).then(res => {
            let response = res.data;
            if (response.success) {
                let filter_data = response.data.filter(item => (parseInt(item.task_id) !== 2 && parseInt(item.status) === 1));
                setActivity(filter_data);
                let filter_done_data = response.data.filter(item => (parseInt(item.task_id) !== 2 && parseInt(item.status) === 2));
                setDoneActivity(filter_done_data);
            }
            setLoading(false);
        });
    };

    const editActivity = (id, data) => {
        window.openGlobalActivityModal({
            contactDetails: props.contactDetails,
            editData: data,
            callBack: getPeopleActivity
        });
    };

    const handleAddAppointment = (event, status) => {
        event.stopPropagation();
        window.openGlobalActivityModal({
            subUserList: props.subUserList,
            contactDetails: props.contactDetails,
            callBack: getPeopleActivity
        });
    };

    const RenderList = ({ list }) => {
        const taskCount = list.reduce((count, item) => {
            if (props.from === 'Task' && item.task_id !== 2 && item.status !== 2) {
                return count + 1;
            }
            return count;
        }, 0);

        useEffect(() => {
            setCount(taskCount);
        }, [list]);

        return list.map((item, index) => {
            if (props.from === 'Task' && item.task_id !== 2 && item.status !== 2) {
                return (
                    <List
                        makeDone={""}
                        data={item}
                        key={index}
                        index={index}
                        status={1}
                        expand={() => setExpand(false)}
                        refresh={refresh}
                        editActivity={editActivity}
                    />
                );
            }
            return null;
        });
    };

    const renderDoneList = (list) => {
        return list.map((item, index) => (
            <List
                data={item}
                key={item.id}
                index={item.id}
                status={2}
                makeDone={null}
            />
        ));
    };

    return (
        <div className=" mt-2">
            <CustomAccordion expand={expand} className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span className="g-header-title"><ListAltIcon /> Task <span className="g-header-count">{count}</span></span>

                    <div className="g-header-action-content">
                        <span className="g-add-task-button" onClick={(event) => handleAddAppointment(event, true)}><AddCircleIcon /></span>
                        <span className="g-custom-caret">{icons.caretIcon}</span>
                    </div>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>
                    <div className="g-contact-appointment-content">
                        {loading && <span className="g-loading-content">Loading...</span>}
                        {!loading &&
                        <>
                            <div className="g-contact-appointment-list awesome__scroll_bar" style={{ paddingBottom:'8px' }}>
                                {activity.length === 0 ?
                                    <span className="g-no-appointment">No active task found</span>
                                    :
                                    <RenderList list={activity} />
                                }
                            </div>

                            {/*{doneActivity.length > 0 &&*/}
                            {/*<div className="g-contact-appointment-list awesome__scroll_bar">*/}
                            {/*    <span className="g-done_activity_title">Mark as done task list : <span onClick={() => setShowDoneTask(!showDoneTask)} style={{ textDecoration: 'underline' }}>{showDoneTask ? 'Hide' : 'Show'}</span></span>*/}
                            {/*    {showDoneTask && renderDoneList(doneActivity)}*/}
                            {/*</div>*/}
                            {/*}*/}
                        </>
                        }
                    </div>
                </CustomAccordionBody>
            </CustomAccordion>
        </div>
    );
};

export default Task;
