import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    makeStyles,
    Modal,
    Paper,
    Radio,
    Typography
} from "@material-ui/core";
import {Close as CloseIcon} from "@material-ui/icons";
import Styles from "./EnhanceData.module.css";
import {carrierLookupSingleApi, getCarrierLookupCreditSettingsApi} from "../assets/ApiList";
import {ACTION, LOOKUP_TYPE, REQUEST_MODULE, SEARCH_CATEGORY} from "../Constants";
import {updateCarrierLookupPrices} from "../redux/enhancedDataActions";
import Response from "../Response";
import Utils from "../../../helpers/Utils";
import {EnhancedDataIcon} from "../Icons";
import RecheckAlert from "./RecheckAlert";
import { ValidationResultModal } from "./ValidationResultModal";

const useStyles = makeStyles((theme) => {
    return {
      root: {
        "&.MuiButtonBase-root": {
          width: "190px",
        },
        "&.MuiButton-root": {
          backgroundColor: "#008945",
          padding: "10px 20px",
          borderRadius: "4px",
        },
        "&.MuiButton-text": {
          color: "grey",
        },
        "&.MuiButton-contained": {
          color: "white",
        },
        "&.MuiButton-outlined": {
          color: "brown",
        },
      },
     
    }
});

const cleanNumber = (number) => {
    try{
        if(isNaN(number)){
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split('.');
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, '') + '.00';
        }
        const decimalPart = parts[1].length < 2 ? parts[1] + '0' : parts[1];
        return `${parts[0]}.${decimalPart}`;
    }catch(err){
        return "x.xx";
    }
};

const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
};

const calculateDateDifference = (startDate, endDate) => {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    const timeDiff = endTime - startTime;
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
}

const EnhancedData = ({ isOpen, onClose, data, carrierLookupPrices, updateCarrierLookupPrices }) => {
    const classes = useStyles();
    const LOOKUP_CREDIT_TYPE_MAP = {
        CARRIER_LOOKUP: 64,
        EMAIL_LOOKUP: 65,
        DNC_LOOKUP: 66,
        SKIP_TRACING_LOOKUP: 67
    };
    const [STATUS_VALID, STATUS_INVALID, STATUS_DO_NOT_CALL, STATUS_VERIFIED_OK, STATUS_PENDING, STATUS_TRACED, STATUS_TRACE_FAILED] = ["VALID", "INVALID", "DO_NOT_CALL", "VERIFIED_OK", "PENDING", "TRACED", "TRACE_FAILED"];
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedLookupType, setSelectedLookupType] = useState([]);
    const [selectedSearchCategory, setSelectedSearchCategory] = useState([SEARCH_CATEGORY.HOUSE_CURRENT_OWNER]);
    const [selectedAction, setSelectedAction] = useState(ACTION.ADD_NEW_CONTACT);
    const [alreadyVerified, setAlreadyVerified] = useState({
        EMAIL_LOOKUP: false,
        CARRIER_LOOKUP: false,
        DNC_LOOKUP: false,
        SKIP_TRACING_LOOKUP: false,
    });
    const [lastVerifiedAt, setLastVerifiedAt] = useState({
        EMAIL_LOOKUP: null,
        CARRIER_LOOKUP: null,
        DNC_LOOKUP: null,
        SKIP_TRACING_LOOKUP: null,
    });
    const [showModalContent, setShowModalContent] = useState(true);
    const [showValidationContent,setShowValidationContent]=useState(false);
    const [resData,setResData]=useState(null);
    const [REQUEST_DONE,REQUEST_FAILED] = ["REQUEST_DONE","REQUEST_FAILED"];

    useEffect(() => {
        if (!carrierLookupPrices) {
            setLoading(true);
            getCarrierLookupCreditSettingsApi()
                .then((response)=>{
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            updateCarrierLookupPrices(response.data.data);
                        }
                    }
                }).catch((err)=>{
                updateCarrierLookupPrices(null);
            }).finally(()=>{
                setLoading(false);
            });
        }
    }, []);

    useEffect(()=>{
        if (data && data.contact_additional_informations){
            let preAlreadyVerified = {
                EMAIL_LOOKUP: false,
                CARRIER_LOOKUP: false,
                DNC_LOOKUP: false,
                SKIP_TRACING_LOOKUP: false,
            };

            let preLastVerifiedAt = {
                EMAIL_LOOKUP: null,
                CARRIER_LOOKUP: null,
                DNC_LOOKUP: null,
                SKIP_TRACING_LOOKUP: null,
            };

            if (data.contact_additional_informations.email_validation_status === STATUS_VALID ||
                data.contact_additional_informations.email_validation_status === STATUS_INVALID){
                preAlreadyVerified.EMAIL_LOOKUP = true;
                preLastVerifiedAt.EMAIL_LOOKUP = data.contact_additional_informations.email_validation_date;
            }

            if (data.contact_additional_informations.number_validation_status === STATUS_VALID ||
                data.contact_additional_informations.number_validation_status === STATUS_INVALID){
                preAlreadyVerified.CARRIER_LOOKUP = true;
                preLastVerifiedAt.CARRIER_LOOKUP = data.contact_additional_informations.number_validation_date;
            }

            if (data.contact_additional_informations.dnc_status === STATUS_VERIFIED_OK ||
                data.contact_additional_informations.dnc_status === STATUS_DO_NOT_CALL){
                preAlreadyVerified.DNC_LOOKUP = true;
                preLastVerifiedAt.DNC_LOOKUP = data.contact_additional_informations.dnc_validation_date;
            }

            if (data.contact_additional_informations.skip_trace_status === STATUS_TRACED){
                preAlreadyVerified.SKIP_TRACING_LOOKUP = true;
                preLastVerifiedAt.SKIP_TRACING_LOOKUP = data.contact_additional_informations.skip_trace_date;
            }

            setAlreadyVerified(preAlreadyVerified);
            setLastVerifiedAt(preLastVerifiedAt);
        }
    },[data]);

    const getRecheckAlertMessage = (lookupType) => {
        let fDate = lastVerifiedAt[lookupType];

        if (window.globalTimezoneConversionToDifferentTimezone){
            fDate = window.globalTimezoneConversionToDifferentTimezone(fDate,'UTC','','MM/DD/YYYY');
        }

        if (lookupType === LOOKUP_TYPE.EMAIL_LOOKUP){
            return {
                title: "Notice - Email Validation",
                description: `You previously validated this email address on ${fDate}.`,
            };
        }else if (lookupType === LOOKUP_TYPE.CARRIER_LOOKUP){
            return {
                title: "Notice - Phone Validation",
                description: `You previously validated this phone number on ${fDate}.`,
            };
        }else if (lookupType === LOOKUP_TYPE.DNC_LOOKUP){
            return {
                title: "Notice - DNC Validation",
                description: `You previously checked this number against the DNC on ${fDate}.`,
            };
        }else if (lookupType === LOOKUP_TYPE.SKIP_TRACING_LOOKUP){
            return {
                title: "Notice - Skip Tracing",
                description: `You previously skip-traced this address on ${fDate}.`,
            };
        }else {
            return {
                title: "Invalid Selection",
                description: "",
            };
        }
    };

    const getLookupPrice = (lookupType) => {
        try{
            if (carrierLookupPrices){
                let price = carrierLookupPrices.find((carrierLookupPrice)=>(carrierLookupPrice.type === LOOKUP_CREDIT_TYPE_MAP[lookupType]));
                if (price){
                    return cleanNumber(price.value);
                }
            }
            return "0.00";
        }catch (err){
            return "0.00";
        }
    };

    const onLookupTypeChange = (lookupType, bypass=false) => {

        if (lastVerifiedAt[lookupType]){
            const today = new Date();
            const someDate = new Date(lastVerifiedAt[lookupType]);
            const differenceInDays = calculateDateDifference(someDate, today);
            if (alreadyVerified[lookupType] && differenceInDays <= 30 && !bypass && !selectedLookupType.includes(lookupType)){
                RecheckAlert({
                    ...getRecheckAlertMessage(lookupType),
                    onSubmit: () => {
                        onLookupTypeChange(lookupType,true);
                    }
                });
                return;
            }
        }

        let spSelectedLookupType = [...selectedLookupType];
        if (selectedLookupType.includes(lookupType)){
            let index = selectedLookupType.indexOf(lookupType);
            spSelectedLookupType.splice(index,1);
            setSelectedLookupType(spSelectedLookupType);
        }else {
            spSelectedLookupType.push(lookupType);
            setSelectedLookupType(spSelectedLookupType);
        }
    };

    const onSubmit = async () => {
        if (!selectedLookupType[0]){
            window.showNotification("ERROR", "Please select a lookup type");
            return;
        }
        setShowValidationContent((prev)=>!prev)

        setSubmitting(true);
         if (data) {
             var element = document.getElementById("enhance-modal");
             element.classList.add("d-none");
           try {
             let payload = {
               lookupType: selectedLookupType,
               requestModule: data.requestModule,
               contact: { id: data.id },
             }

             if (selectedLookupType.includes(LOOKUP_TYPE.EMAIL_LOOKUP)) {
               payload.contact.email = data.email
             }

             if (
               selectedLookupType.includes(LOOKUP_TYPE.CARRIER_LOOKUP) ||
               selectedLookupType.includes(LOOKUP_TYPE.DNC_LOOKUP)
             ) {
               payload.contact.number = data.number
             }

             if (selectedLookupType.includes(LOOKUP_TYPE.SKIP_TRACING_LOOKUP)) {
               let addressLine1 = ""
               let addressLine2 = ""

               if (data.address) {
                 addressLine1 = data.address
               }

               if (data.city) {
                 addressLine2 += data.city
               }

               if (data.state) {
                 if (addressLine2) {
                   addressLine2 += ", "
                 }
                 addressLine2 += data.state
               }

               if (data.zip) {
                 if (addressLine2) {
                   addressLine2 += ", "
                 }
                 addressLine2 += data.zip
               }

               if (addressLine1) {
                 payload.contact.addressLine1 = addressLine1
               }

               if (addressLine2) {
                 payload.contact.addressLine2 = addressLine2
               }

               payload.searchCategory = selectedSearchCategory

               if (data.requestModule === REQUEST_MODULE.CONTACT) {
                 payload.action = selectedAction
               }
             }
             const res = await carrierLookupSingleApi(payload)

             if (res.status === false && res.success === false) {
               window.showNotification(
                 "error",
                 res.message
                   ? res.message
                   : "Failed to validate. Contact with administrator"
               )
             } else {
               data.callback(res)
              //  Response({
              //    data: res,
              //    source: data.requestModule,
              //  })

              setResData(res)

               let preLastVerifiedAt = { ...lastVerifiedAt }
               let preAlreadyVerified = { ...alreadyVerified }

               const currentDate = new Date()
               const formattedDate = `${currentDate.getFullYear()}-${padZero(
                 currentDate.getMonth() + 1
               )}-${padZero(currentDate.getDate())} ${padZero(
                 currentDate.getHours()
               )}:${padZero(currentDate.getMinutes())}:${padZero(
                 currentDate.getSeconds()
               )}`

               if (res["email-lookup-status"] === REQUEST_DONE) {
                 preLastVerifiedAt.EMAIL_LOOKUP = formattedDate
                 preAlreadyVerified.EMAIL_LOOKUP = true
               }

               if (res["carrier-lookup-status"] === REQUEST_DONE) {
                 preLastVerifiedAt.CARRIER_LOOKUP = formattedDate
                 preAlreadyVerified.CARRIER_LOOKUP = true
               }

               if (res["dnc-lookup-status"] === REQUEST_DONE) {
                 preLastVerifiedAt.DNC_LOOKUP = formattedDate
                 preAlreadyVerified.DNC_LOOKUP = true
               }

               if (res["skip-trace-lookup-status"] === REQUEST_DONE) {
                 preLastVerifiedAt.SKIP_TRACING_LOOKUP = formattedDate
                 preAlreadyVerified.SKIP_TRACING_LOOKUP = true
               }
               setLastVerifiedAt(preLastVerifiedAt)
               setAlreadyVerified(preAlreadyVerified)
               window.showNotification(
                 "success",
                 "Contact validated successfully"
               )
             }
             setSubmitting(false)
             setSelectedLookupType([])
           } catch (err) {
             setSubmitting(false)
             window.showNotification("error", "Unable to validate contact")
           }
         } else {
           setSubmitting(false)
           window.showNotification("ERROR", "Please select a contact")
         }
    };

      const toggleModalContent = () => {
        setShowModalContent((prev) => !prev)
      }

    return (
      <>
        <Modal
          open={isOpen}
          onClose={() => {}}
          aria-labelledby="enhance-modal-title"
          aria-describedby="enhance-modal-description"
          className={`${Styles.modalWrapper} global-xs-modal`}
        >
          <Paper className={Styles.modalInner}>
            <div className={Styles.modalTitle} id="enhance-modal">
              {/*<div className={Styles.modalTitleSubClass}>*/}
              {/*  <Paper*/}
              {/*    elevation={0}*/}
              {/*    style={{*/}
              {/*      borderRadius: 25,*/}
              {/*    }}*/}
              {/*    className={Styles.modalTitlePaperClass}*/}
              {/*  >*/}
              {/*    Enhance Data*/}
              {/*  </Paper>*/}
              {/*</div>*/}
                {!showValidationContent ? (
                <>
                <div className={Styles.modalTitleSubClass}>
                    <Paper
                        elevation={0}
                        style={{
                            borderRadius: 25,
                        }}
                        className={Styles.modalTitlePaperClass}
                    >
                        {/*Enhance Data*/}
                    </Paper>
                </div>
              <div className={Styles.headerClose} onClick={onClose}>
                <CloseIcon />
              </div>
                    </>
                ) : ''}
              {/*<div className={Styles.howThisWork}>*/}
              {/*  <u onClick={toggleModalContent}>*/}
              {/*    {showModalContent ? "How does this work?" : "Hide"}*/}
              {/*  </u>*/}
              {/*</div>*/}
            </div>
            {showValidationContent ? (
              <>
                <ValidationResultModal
                  submitting={submitting}
                  onClose={onClose}
                  data={resData}
                  source={data?.requestModule}
                />
              </>
            ) : (
              <>
                {showModalContent && (
                  <div className={Styles.headerWrapper}>
                    <Typography
                      style={{ paddingTop: "5px" }}
                      className={Styles.headerSubTitle}
                    >
                      Having accurate, up-to-date data is key to a successful
                      outreach campaign.
                      <br />
                      Validate and append your data with a few clicks below.
                    </Typography>
                      <div className={Styles.howThisWork}>
                          <span onClick={toggleModalContent}>
                              {showModalContent ? "How does this work?" : "Hide"}
                          </span>
                      </div>
                  </div>
                )}
                <div className={Styles.paddingWrp}>
                  <div className={Styles.modalBody}>
                    {showModalContent ? (
                      <>
                        {loading && (
                          <div className={Styles.modalBackdrop}>
                            <CircularProgress />
                          </div>
                        )}
                        <div style={{ display: "flex", gap: "60px" }}>
                          <div style={{ marginTop: "18px" }}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 600 }}
                            >
                              Make a selection:
                            </Typography>
                            <Typography variant="caption">
                              (Choose all that apply)
                            </Typography>
                          </div>

                          <div>
                            <FormControl style={{gap:'10px'}}>
                              {Utils.getAccountData("carrierLookup") && (
                                <Fragment>
                                  <FormControlLabel
                                    checked={selectedLookupType.includes(
                                      LOOKUP_TYPE.EMAIL_LOOKUP
                                    )}
                                    name={LOOKUP_TYPE.EMAIL_LOOKUP}
                                    onChange={() => {
                                      onLookupTypeChange(
                                        LOOKUP_TYPE.EMAIL_LOOKUP
                                      )
                                    }}
                                    control={<Checkbox color="primary" style={{marginRight:'6px'}}/>}
                                    label={`Validate Email Address ($${getLookupPrice(
                                      LOOKUP_TYPE.EMAIL_LOOKUP
                                    )})`}
                                    disabled={data && !data.email}
                                  />
                                  <FormControlLabel
                                    checked={selectedLookupType.includes(
                                      LOOKUP_TYPE.CARRIER_LOOKUP
                                    )}
                                    name={LOOKUP_TYPE.CARRIER_LOOKUP}
                                    onChange={() => {
                                      onLookupTypeChange(
                                        LOOKUP_TYPE.CARRIER_LOOKUP
                                      )
                                    }}
                                    control={<Checkbox color="primary" style={{marginRight:'6px'}}/>}
                                    label={`Validate Phone Number ($${getLookupPrice(
                                      LOOKUP_TYPE.CARRIER_LOOKUP
                                    )})`}
                                    disabled={data && !data.number}
                                  />
                                </Fragment>
                              )}
                              {Utils.getAccountData("dnc") && (
                                <FormControlLabel
                                  checked={selectedLookupType.includes(
                                    LOOKUP_TYPE.DNC_LOOKUP
                                  )}
                                  name={LOOKUP_TYPE.DNC_LOOKUP}
                                  onChange={() => {
                                    onLookupTypeChange(LOOKUP_TYPE.DNC_LOOKUP)
                                  }}
                                  control={<Checkbox color="primary" style={{marginRight:'6px'}}/>}
                                  label={`Check Do-Not-Call(DNC) status ($${getLookupPrice(
                                    LOOKUP_TYPE.DNC_LOOKUP
                                  )})`}
                                  disabled={data && !data.number}
                                />
                              )}
                              {Utils.getAccountData("skipTrace") && (
                                <FormControlLabel
                                  checked={selectedLookupType.includes(
                                    LOOKUP_TYPE.SKIP_TRACING_LOOKUP
                                  )}
                                  name={LOOKUP_TYPE.SKIP_TRACING_LOOKUP}
                                  onChange={() => {
                                    onLookupTypeChange(
                                      LOOKUP_TYPE.SKIP_TRACING_LOOKUP
                                    )
                                  }}
                                  control={<Checkbox color="primary" style={{marginRight:'6px'}}/>}
                                  label={`Skip Trace ($${getLookupPrice(
                                    LOOKUP_TYPE.SKIP_TRACING_LOOKUP
                                  )})`}
                                  disabled={
                                    (data && !data.address) ||
                                    (data && (!data.city || !data.state))
                                  }
                                />
                              )}
                            </FormControl>

                            {selectedLookupType.includes(
                              LOOKUP_TYPE.SKIP_TRACING_LOOKUP
                            ) && (
                              <div px={4}>
                                <FormControl
                                  onChange={(event) => {
                                    setSelectedSearchCategory([
                                      event.target.value,
                                    ])
                                  }}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    name="search-category"
                                    value={SEARCH_CATEGORY.HOUSE_CURRENT_OWNER}
                                    checked={selectedSearchCategory.includes(
                                      SEARCH_CATEGORY.HOUSE_CURRENT_OWNER
                                    )}
                                    control={<Radio color="primary" />}
                                    label="Find current owner"
                                  />
                                  <FormControlLabel
                                    name="search-category"
                                    value={
                                      SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT
                                    }
                                    checked={selectedSearchCategory.includes(
                                      SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT
                                    )}
                                    control={<Radio color="primary" />}
                                    label="Find current resident"
                                  />
                                </FormControl>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* {selectedLookupType.includes(
                          LOOKUP_TYPE.SKIP_TRACING_LOOKUP
                        ) &&
                          data.requestModule === REQUEST_MODULE.CONTACT && (
                            <Fragment>
                              <Box className={classes.BoxRootClass}>
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: 600 }}
                                >
                                  What do you want to do with your skip traced
                                  data?
                                </Typography>
                                <Typography variant="caption">
                                  (Select one that apply)
                                </Typography>
                              </Box>
                              <FormControl
                                onChange={(event) => {
                                  setSelectedAction(event.target.value)
                                }}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  name="action"
                                  value={ACTION.ADD_NEW_CONTACT}
                                  checked={selectedAction.includes(
                                    ACTION.ADD_NEW_CONTACT
                                  )}
                                  control={<Radio color="primary" />}
                                  label="Add as new contact"
                                />
                                <FormControlLabel
                                  name="action"
                                  value={ACTION.ADD_UNDER_CONTACT}
                                  checked={selectedAction.includes(
                                    ACTION.ADD_UNDER_CONTACT
                                  )}
                                  control={<Radio color="primary" />}
                                  label="Add as additional contact"
                                />
                                <FormControlLabel
                                  name="action"
                                  value={ACTION.NO_ACTION}
                                  checked={selectedAction.includes(
                                    ACTION.NO_ACTION
                                  )}
                                  control={<Radio color="primary" />}
                                  label="Add only in enhanced data report"
                                />
                              </FormControl>
                            </Fragment>
                          )} */}

                      {selectedLookupType.includes(
                        LOOKUP_TYPE.SKIP_TRACING_LOOKUP
                      ) &&
                        data.requestModule === REQUEST_MODULE.CONTACT && (
                          <>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 600 }}
                              >
                                How do you want to organize the skip traced
                                data?
                              </Typography>
                              <Typography variant="caption">
                                (Select an option below)
                              </Typography>
                              <FormControl
                                onChange={(event) => {
                                  setSelectedAction(event.target.value)
                                }}
                              >
                                <div>
                                  <div
                                    className={Styles.optionBorder}
                                  >
                                    <div
                                      className={Styles.optionBody}
                                      style={{width:"100%"}}
                                    >
                                      <h6>
                                          <b>Option 1: Create New Contact Records</b>
                                      </h6>
                                      <p style={{marginTop:0, marginRight:"10px"}}>
                                        If we find multiple contacts when
                                        running a skip trace we will create a
                                        new contact record for each of them in
                                        your CRM
                                      </p>
                                    </div>
                                    <FormControlLabel
                                      className={Styles.optionCheckbox}
                                      name="action"
                                      value={ACTION.ADD_NEW_CONTACT}
                                      checked={selectedAction.includes(
                                        ACTION.ADD_NEW_CONTACT
                                      )}
                                      control={<Radio color="primary" />}
                                    />
                                  </div>
                                  <div
                                    className={Styles.optionBorder}
                                  >
                                    <div
                                      className={Styles.optionBody}
                                      style={{width:"100%"}}
                                    >
                                      <h6>
                                        <b>Option 2: Merge Data into One Contact Record</b>
                                      </h6>
                                        <p style={{marginTop:0, marginRight:"10px"}}>
                                        If we find multiple phone numbers and
                                        email addresses when running a skip
                                        trace we will merge all the data into
                                        one contact record.
                                      </p>
                                    </div>
                                    <FormControlLabel
                                      className={Styles.optionCheckbox}
                                      name="action"
                                      value={ACTION.ADD_UNDER_CONTACT}
                                      checked={selectedAction.includes(
                                        ACTION.ADD_UNDER_CONTACT
                                      )}
                                      control={<Radio color="primary" />}
                                    />
                                  </div>
                                  <div
                                    className={Styles.optionBorder}
                                  >
                                    <div
                                      className={Styles.optionBody}
                                      style={{width:"100%"}}
                                    >
                                      <h6><b>Option 3: Generate a Report</b></h6>
                                      <div>
                                        <p style={{marginTop:0, marginBottom:0}}>This option will not create new contacts
                                        or add the info we find to an existing
                                        contact record. </p>
                                        <p style={{marginTop:0, marginBottom:0}}>When you skip trace an
                                            address we will generate a report.</p> <p style={{fontSize:"smaller",marginTop:'2px'}}><b>(How
                                        to view the report: Click Enhance Data
                                        on the left sidebar-&gt;
                                          Skiptracing-&gt; Results)</b></p>
                                      </div>
                                    </div>
                                    <FormControlLabel
                                      className={Styles.optionCheckbox}
                                      name="action"
                                      value={ACTION.NO_ACTION}
                                      checked={selectedAction.includes(
                                        ACTION.NO_ACTION
                                      )}
                                      control={<Radio color="primary" />}
                                    />
                                  </div>
                                </div>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        <div className={Styles.modalFooter}>
                          <Button
                            size="large"
                            onClick={onSubmit}
                            className={`${classes.root} ${Styles.submitButton}`}
                            variant="contained"
                            disabled={submitting}
                          >
                            {submitting && <CircularProgress />}
                            Submit
                          </Button>
                        </div>
                      </>
                    ) : (
                      <iframe
                        width="100%"
                        height="350"
                        src="https://www.youtube.com/embed/your-video-id"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                  </div>
                </div>
              </>
            )}
          </Paper>
        </Modal>
      </>
    )
}
const mapStateToProps = state => {
    return {
        carrierLookupPrices: state.enhancedDataReducer.carrierLookupPrices,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCarrierLookupPrices: (payload) => dispatch(updateCarrierLookupPrices(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedData);