import * as ACTION from "../constants/notificationActionTypes";

export const fetchSiteNotification = () => ({
    type: ACTION.FETCH_SITE_NOTIFICATION
});

export const appendSiteNotification = () => ({
    type: ACTION.SCROLL_FETCH_SITE_NOTIFICATION
});

export const fetchLeadNotification = () => ({
    type: ACTION.FETCH_LEAD_NOTIFICATION
});

export const appendLeadNotification = () => ({
    type: ACTION.SCROLL_FETCH_LEAD_NOTIFICATION
});

export const setAllSiteNotificationMarkAsSeen = payload => ({
    type: ACTION.SET_ALL_MARK_AS_SEEN_SITE_NOTIFICATION,
    payload: payload
});

export const setAllLeadNotificationMarkAsSeen = payload => ({
    type: ACTION.SET_ALL_MARK_AS_SEEN_LEAD_NOTIFICATION,
    payload: payload
});

export const closeSiteNotification = payload => ({
    type: ACTION.CLOSE_SITE_NOTIFICATION_REQUEST,
    payload: payload
});

export const closeLeadNotification = payload => ({
    type: ACTION.CLOSE_LEAD_NOTIFICATION_REQUEST,
    payload: payload
});


export const fetchNotificationCount = payload => ({
    type: ACTION.FETCH_NOTIFICATION_COUNT,
    payload: payload
});

export const incrementNotificationCount = payload => ({
    type: ACTION.INCREMENT_NOTIFICATION_COUNT,
    payload: payload
});

export const setSiteNotificationLoaded = payload => ({
    type: ACTION.SET_SITE_NOTIFICATION_LOADED,
    payload: payload
})

export const restoreGeneralNotificaiton = payload => ({
    type: ACTION.RESTORE_SITE_NOTIFICATION,
    payload: payload
})
export const restoreLeadNotificaiton = payload => ({
    type: ACTION.RESTORE_LEAD_NOTIFICATION,
    payload: payload
})
