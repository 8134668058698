import * as ACTION from "../constants/loginActionTypes";

const initialState = {
    user : '',
    apiKey: '',
    agencyId : null,
    isLoading: true
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.LOGIN_SUCCESS:
            return {...state, apiKey: action.payload}

        default:
            return state;
    }
}

export default loginReducer;
