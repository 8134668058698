import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { DealStatus } from '../../Constant';
import ContactHelper from '../../Helper';

export const List = ({item, key}) => {
    return(
        <div className="g-contact-deal-list-each" key={key} >
            <div className="g-deal-info-content">   
                <div className="g-deal-title">{item.title}</div>
                <div className="g-deal-stage">{item.stage.stage}</div>
            </div>
            <div className="g-deal-value-content">
                <div className="g-deal-win">
                    {parseInt(item.status) === DealStatus.WIN ?
                        <span className="material-icons" style={{color: '#ba8327'}}>
                            emoji_events
                        </span>
                    :
                        <SentimentVeryDissatisfiedIcon />
                    }
                </div>
                <div className="d-deal-value">{ContactHelper.calculateCash(item.deal_value)}</div>
            </div>
        </div>
    )
}