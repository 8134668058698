import React, { useEffect, useState, createRef, useContext } from 'react';
import { connect } from "react-redux";
import CustomSelectBox from './CustomSelectBox';
import Loading from './assets/Loading';
import CoreConstants from './assets/CoreConstants';
import { storeHandWrtingId, storeMultipleReducers, storeThanksIOGiftPrice, storeUseThanksIO } from './redux/cardBroadcastAction';
import { CardMultiStepContext } from './CardMultiStepForm';
import { HandwritingStyles } from './handwritingComponent/HandwritingStyles';
import { DropdownHelper } from './assets/DropdownHelper';

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;
const THANKSIO_GIFT = 11;

const DirectmailTypeConvert = {
    [POSTCARD]: CoreConstants.Timeline.CONTENT_TYPE_POST_CARD,
    [GREETING_CARD]: CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD,
    [LETTER]: CoreConstants.Timeline.CONTENT_TYPE_LETTER,
    [GIFT]: CoreConstants.Timeline.CONTENT_TYPE_GIFT,
    [THANKSIO_GREETING_CARD]: CoreConstants.Timeline.CONTENT_TYPE_THANKSIO_GREETING_CARD
}

const ThirdStep = (props) => {

    const cardMultiStepContext = useContext(CardMultiStepContext)
    const [handWrtingStyleList, setHandWrtingStyleList] = useState([])
    const [priceList, setPriceList] = useState([])
    const textEditorRef = createRef()

    useEffect(() => {
        if (cardMultiStepContext.messageType === POSTCARD || cardMultiStepContext.messageType === LETTER 
                || cardMultiStepContext.messageType === THANKSIO_GREETING_CARD || cardMultiStepContext.messageType === THANKSIO_GIFT) {
            props.storeUseThanksIO(true)
            selectFirstHandwriting();
        } else {
            props.storeUseThanksIO(false)
        }
    }, [])

    const selectFirstHandwriting = () => {

        if (cardMultiStepContext.messageType === POSTCARD && props.postcardCategoryList != null) {
            setHandwritingStyles(props.postcardCategoryList)
        } else if (cardMultiStepContext.messageType === GREETING_CARD && props.greetingCardCategoryList != null) {
            setHandwritingStyles(props.greetingCardCategoryList)
        } else if (cardMultiStepContext.messageType === THANKSIO_GREETING_CARD && props.thanksIoGreetingCardCategoryList != null) {
            setHandwritingStyles(props.thanksIoGreetingCardCategoryList)
        } else if (cardMultiStepContext.messageType === LETTER && props.letterCategoryList != null) {
            setHandwritingStyles(props.letterCategoryList)
        } else if (cardMultiStepContext.messageType === THANKSIO_GIFT && props.thanksIoGreetingCardCategoryList != null) {
            setHandwritingStyles(props.thanksIoGreetingCardCategoryList)
            setPrices(props.selectedGift);
        } else {
            window.showNotification('ERROR', 'Something went wrong')
            cardMultiStepContext.moveToSpecificStep(1)
        }
    }

    const setPrices = (gift) => {
        let modifiedList = gift.available_amounts.map(price => {
            return {
                name : (price/100)+'$',
                price : price
            }
        })
        setPriceList(modifiedList);
        props.storeThanksIOGiftPrice(gift.available_amounts[0]);
    }

    const setHandwritingStyles = (fullData) => {
        setHandWrtingStyleList(fullData.hand_writings)
        if (cardMultiStepContext.editableCampaign && cardMultiStepContext.editableCampaign.handwriting_style_id && cardMultiStepContext.editableCampaign.handwriting_style_id !== '') {
            props.storeHandWrtingId(cardMultiStepContext.editableCampaign.handwriting_style_id)
        } else {
            props.storeHandWrtingId(fullData.hand_writings[0].handwriting_style_id)
        }
    }

    const handleHandwritingDropdownChange = (value) => {
        // event.preventDefault();
        if (value !== '' && value !== props.selectedHandWritingId) {
            props.storeHandWrtingId(value)
        }
    }

    const handleGiftPriceDropdownChange = (value) => {
        // event.preventDefault();
        if (value !== '' && value !== props.selectedThanksIOGiftPrice) {
            props.storeThanksIOGiftPrice(value)
        }
    }

    const handlePersonalize = (selectedPersonalize) => {

        let start = textEditorRef.current.selectionStart;
        let end = textEditorRef.current.selectionEnd;
        let text = textEditorRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + selectedPersonalize + after);
        props.storeMultipleReducers({ textEditorValue: newText })

    }

    const generatePDF = async (e) => {
        e.preventDefault();

        if(textEditorRef.current.value && textEditorRef.current.value.trim() !== '')
        {
            props.storeMultipleReducers({showPDF : true})
            await cardMultiStepContext.producePDFLink();
            // pdfviewer.current.scrollIntoView({ behavior: "smooth" });
        }else{
            window.showNotification('ERROR','Please input any message!')
        }
    }

    const updateCardMessage=(value)=>{

        value = value.replace(/&/g,'')
        props.storeMultipleReducers({ textEditorValue: value })
    }

    return (
        <React.Fragment>
            <fieldset>


                <div className="custom-form-card form-card">
                    {/* <!--=== message part 01 row ===--> */}
                    <div className="row">
                        <div className="col s12">
                            {/* <!-- common section message  --> */}
                            <div className="common-section-message">
                                {/* { cardMultiStepContext.messageType === LETTER && !props.useThanksIo &&
                                    <LetterPageButtons selectedCardSize={props.selectedCardSize} setPage={props.setPage}/>
                                } */}
                                <div className="first-step-page-main">

                                    <div className="save_postcard_topbar py-0">
                                        <div className="message-step-inner-item">
                                            <span>Customize Content</span>
                                            <p>Customize your {CoreConstants.Timeline.CONTENT_TYPES[DirectmailTypeConvert[cardMultiStepContext.messageType]]} by modifying it below with our personalize feature. You can drop in variable information for each card using this feature</p>
                                        </div>

                                        <div className='margin-none-row text-left'>
                                            {(cardMultiStepContext.messageType === POSTCARD || cardMultiStepContext.messageType === LETTER ||
                                                cardMultiStepContext.messageType === THANKSIO_GREETING_CARD || cardMultiStepContext.messageType === THANKSIO_GIFT) &&
                                                <div className="mb-2">
                                                    <span>Handwriting Styles</span>
                                                    <div className="custom-input deal-select-label">
                                                        <HandwritingStyles 
                                                            list={handWrtingStyleList}
                                                            selected={props.selectedHandWritingId}
                                                            onChangeStyle={handleHandwritingDropdownChange}/>
                                                    </div>
                                                </div>
                                            }

                                            {(cardMultiStepContext.messageType === THANKSIO_GIFT) &&
                                                <div className="mb-2">
                                                    <span>Price</span>
                                                    <div className="custom-input deal-select-label">
                                                        <DropdownHelper
                                                            datalist={priceList}
                                                            noneFieldRequired={true}
                                                            noneFieldTitle="Select Price"
                                                            className=""
                                                            mapping={{ title: "name", id: "price" }}
                                                            menuItemClassName=""
                                                            updateSelectedData={handleGiftPriceDropdownChange}
                                                            selectedValue={props.selectedThanksIOGiftPrice}
                                                            dropdownHeader={'handwriting-style'}

                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="handwriting-styles">

                                        {/* <!-- html editor --> */}
                                        <div className="forth__step__editor__pdf row">
                                            <div className="col s6 html-editor-for-design">
                                                <textarea 
                                                    className={`directmail-textfield-thirdstep`}
                                                    ref={textEditorRef}
                                                    onChange={(event) => updateCardMessage(event.target.value)}  
                                                    value={props.textEditorValue} 
                                                    rows={19}
                                                />
                                            </div>

                                            <div className={"col s6 pdf__generate__wr " + (!props.showPDF ? '' : 'pdf__generate__wr__display__inh')}>
                                                {!props.showPDF &&
                                                    <div className="generate__pdf__button">
                                                        <h6>Preview PDF not generated, Click on Generate PDF button to generate Postcard PDF preview</h6>
                                                        <div><a href="#!" onClick={(e) => generatePDF(e)}>Generate PDF</a>
                                                            <span className="span__title__g">Generate PDF is required to set Postcard</span></div>
                                                    </div>
                                                }
                                                <div className="pdf-reader-message">
                                                    {/* <img src="images/contact/pdf.png" alt="img" /> */}

                                                    {!props.loadingPDF && props.showPDF &&
                                                        <>
                                                            <div
                                                            // className="pdf-inner-msg"
                                                            // ref={pdfviewer}
                                                            >

                                                                <iframe className="show__pdf__iframe" src={props.pdfLink} frameborder="0"
                                                                    title="Postcard" loading={'eager'} name={'Postcard'} menu={false}
                                                                    allowFullScreen
                                                                ></iframe>
                                                            </div>

                                                        </>
                                                    }
                                                    {/* {!props.loadingPDF && showPDF &&

                                                
                                            } */}
                                                    {props.loadingPDF && props.showPDF &&

                                                        <Loading />
                                                    }
                                                </div>

                                            </div>
                                            <div class="pdf-create-button-row row">
                                                <div className="margin-none-row custom__input__select__wrapper_v2 col s6">
                                                    <div className="custom__input__select__wr">
                                                        <div className="custom__input__select__input custom-input custom-select-arrow-parsonaliz deal-select-label custom-select-arrow">
                                                            {/* Personalize Dropdown */}
                                                            <CustomSelectBox
                                                                handlePersonalize={handlePersonalize}
                                                                menuPlacement={'top'}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                                {!props.loadingPDF && props.showPDF &&

                                                    <div className="col s6">
                                                        <ul className="update__pdf__view__btb">
                                                            <li><a href="#!" onClick={(e) => generatePDF(e)}>Update PDF</a></li>
                                                            <li><a href={props.pdfLink} target='Blank'>View in New Tab</a></li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* <!-- html editor --> */}

                                        {/* <span>Please scroll down and update preview to ensure text will display properly!</span> */}
                                        <div className="print-message-div d-flex">
                                            <span className="print-message cursor-pointer" onClick={() => props.storeMultipleReducers({ showPrintDeliveryOptions: true })}>Learn More About Printing & Delivery Times </span>
                                            <svg className="print-message-svg MuiSvgIcon-root jss104 cursor-pointer" onClick={() => props.storeMultipleReducers({ showPrintDeliveryOptions: true })} aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='deepskyblue'>
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--=== message part 01 row ===--> */}
                </div>
                {/* <!-- common button multi --> */}

            </fieldset>
        </React.Fragment>

    )


}


const mapStateToProps = state => {
    return {
        postcardCategoryList: state.cardBroadcastReducer.postcardCategoryList,
        greetingCardCategoryList: state.cardBroadcastReducer.greetingCardCategoryList,
        letterCategoryList: state.cardBroadcastReducer.letterCategoryList,
        thanksIoGreetingCardCategoryList: state.cardBroadcastReducer.thanksIoGreetingCardCategoryList,
        selectedCardSize: state.cardBroadcastReducer.selectedCardSize,
        selectedHandWritingId: state.cardBroadcastReducer.selectedHandWritingId,
        textEditorValue: state.cardBroadcastReducer.textEditorValue,
        pdfLink: state.cardBroadcastReducer.pdfLink,
        loadingPDF: state.cardBroadcastReducer.loadingPDF,
        showPrintDeliveryOptions: state.cardBroadcastReducer.showPrintDeliveryOptions,
        showPDF: state.cardBroadcastReducer.showPDF,
        selectedGift: state.cardBroadcastReducer.selectedGift,
        selectedThanksIOGiftPrice: state.cardBroadcastReducer.selectedThanksIOGiftPrice,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        storeHandWrtingId: (params, callback) => dispatch(storeHandWrtingId(params, callback)),
        storeUseThanksIO: (params, callback) => dispatch(storeUseThanksIO(params)),
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),
        storeThanksIOGiftPrice: (params) => dispatch(storeThanksIOGiftPrice(params)), 

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);
