import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { CustomFieldType } from "./CoreConstants";
import { getUserCustomFieldList, getUserSourceList, getUserTagList, setUserCustomFieldList, setUserSourceList, setUserTagList, storeUserCustomFieldsListNew } from "./addContactAction";
import Utils from "./Utils";
import { DropdownHelper } from "./DropdownHelper";
import { getContactLimit, getZipCodeByPlace, submitSingleContact } from "./contactApi"
import "react-datepicker/dist/react-datepicker.css";
import { IconList } from "./IconList";
import NewLoader from "./NewLoader";
import './addContact.css'
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from "@material-ui/core";
import GlobalModal from '../GlobalModal/Modal/GlobalModal';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import MaskedInput from 'react-text-mask'
import Upgrade from "./Upgrade";
import moment from "moment";
import CustomDatePicker from "./datePicker/CustomDatePicker";

const GOOGLE_API_KEY = process.env.REACT_APP_MAP_KEY;

const AddContactModal = (props) => {

    const [contactForm, setContactForm] = useState({
        selectedSource: '',
        selectedSourceIndex: 0,
        first_name: '',
        last_name: '',
        number: '',
        email: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        url: '',
        deal_value: 0,
        birth_day: '',
        anniversary_date: ''
    })

    const [firstLoading, setFirstLoading] = useState(true)
    const [leadSources, setLeadSources] = useState(null)
    // validation flags
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidNumber, setInvalidNumber] = useState(false)

    // for custom field
    const [showCustomFields, setShowCustomFields] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [customFieldsData, setCustomFieldsData] = useState([])
    const [submittingContact, setSubmittingContact] = useState(false)
    const [birthDate, setBirthDate] = useState(null);
    const [anniversaryDate, setAnniversaryDate] = useState(null);
    const [value, setValue] = useState(null);

    const [contactLimit, setContactLimit] = useState(0)
    const [currentContact, setCurrentContact] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (firstLoading) {
            setIsLoading(true)
            setFirstLoading(false)

            getContactLimit().then(res => {
                setIsLoading(false)
                let data = res.data.data;
                if (data.contactLimit > data.currentContact) {
                    fetchSourceInfos();
                    fetchUserCustomFields()
                }
                if (data.contactLimit) {
                    setContactLimit(data.contactLimit)
                    setCurrentContact(data.currentContact)
                }

            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let tempCustomData = [...customFields];
        setCustomFields(tempCustomData)

    }, [customFieldsData]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchSourceInfos = async () => {
        if (props.storedSourceList !== null) {
            setLeadSources(props.storedSourceList)

        } else if (props.sourceList !== undefined && props.sourceList != null) {
            setLeadSources(props.sourceList)

        }
        else {
            await props.fetchUserSourceList({}, (callback) => {
                setLeadSources(callback)
            })
        }

    }

    const fetchUserCustomFields = async () => {
        if (props.storedCustomField !== null) {
            setCustomFields(props.storedCustomField)

        } else {

            window.globalCustomFieldWithPersonalized({
                get_custom_data: true,
                get_personalized_data: false,
                field_name: ['*'],
                for_drop_down: false,
                drop_down_field: {
                    value_field: 'id',
                    title_field: 'title'
                }
            }).then(res => {
                let customFieldDatas = res.data.custom_field;
                let customFieldValues = []
                customFieldDatas.forEach((row, index) => {
                    customFieldValues.push(null)
                });
                setCustomFields(customFieldDatas);
                setCustomFieldsData(customFieldValues)
                props.storeUserCustomFieldsNew(customFieldDatas)
            })

            // await props.fetchUserCustomFields({'for_custom_field_update':true},(callback)=>{
            //     callback = callback.data

            //     let customFieldValues = []
            // 		callback.forEach((row, index) => {
            // 				customFieldValues.push(null)
            // 			});
            //         setCustomFields(callback)
            //         setCustomFieldsData(customFieldValues)
            //     })
        }


    }


    const handleSelectChange = (value) => {
        setContactForm({ ...contactForm, selectedSource: value })
    }

    const handleInput = (element, field) => {
        let value = element.target.value
        let tempContactForm = { ...contactForm }
        if (field === 'number') {
            if (!Utils.checkLetterExistInText(value) || value === '') {
                if (value.length > 0 && value[0] !== '1') {
                    value = 1 + value;
                }
                setContactForm({ ...contactForm, 'number': value })
            } else {
                try {
                    value = value.replace(/[^0-9]+/g, '')
                    if (value.length > 0 && value[0] !== '1') {
                        value = 1 + value;
                    }
                    setContactForm({ ...contactForm, 'number': value })
                }
                catch (e) {
                    console.log(e)
                }

            }
        }
        else {
            tempContactForm[field] = value
            setContactForm(tempContactForm)
        }
    }

    const handleAddress = async (event) => {
        let placeId = event.place_id !== undefined && event.place_id != null ? event.place_id : null;
        let zip = ''
        let road = ''
        let streetNumber = ''
        let state = ''
        let city = ''
        let country = ''
        let county = ''
        if (placeId != null) {
            try {
                await getZipCodeByPlace({ placeId: placeId, googleAPIKey: GOOGLE_API_KEY })
                    .then(res => {
                        try {
                            if (res.data.data.result.formatted_address !== undefined && res.data.data.result.formatted_address != null) {
                                let data_array = res.data.data.result.formatted_address.split(',');
                                /* 23459 Ridgeway, Mission Viejo, CA 92692, USA */
                                if (data_array.length === 4) {
                                    let state_zip = data_array[2].trim().split(' ')
                                    setContactForm({
                                        ...contactForm,
                                        address: data_array[0].trim(),
                                        city: data_array[1].trim(),
                                        state: state_zip[0],
                                        country: data_array[3],
                                        zip: state_zip[1]
                                    })
                                } else {
                                    zip = res?.data?.zip
                                    if (res.data.data.result.address_components && Array.isArray(res.data.data.result.address_components)) {
                                        res.data.data.result.address_components.map((item, index) => {
                                            if (item.types[0] === 'street_number') {
                                                streetNumber = item.long_name;
                                            } else if (item.types[0] === 'route') {
                                                road = item.long_name
                                            } else if (item.types[0] === 'administrative_area_level_1') {
                                                state = item.short_name
                                            } else if (item.types[0] === 'administrative_area_level_2') {
                                                city = item.long_name
                                            } else if (item.types[0] === 'country') {
                                                country = item.long_name
                                            } else if (item.types[0] === 'postal_code') {
                                                zip = item.long_name
                                            } else if (item.types[0] === 'administrative_area_level_3') {
                                                county = item.long_name
                                            }
                                            setContactForm({ ...contactForm, address: streetNumber + ' ' + road, city: (county !== "" ? county : city), state: state, country: country, zip: zip })

                                        })

                                    } else {
                                        updateContactInfo(event, zip)
                                    }
                                }
                            } else {
                                zip = res?.data?.zip
                                if (res.data.data.result.address_components && Array.isArray(res.data.data.result.address_components)) {
                                    res.data.data.result.address_components.map((item, index) => {
                                        if (item.types[0] === 'street_number') {
                                            streetNumber = item.long_name;
                                        } else if (item.types[0] === 'route') {
                                            road = item.long_name
                                        } else if (item.types[0] === 'administrative_area_level_1') {
                                            state = item.short_name
                                        } else if (item.types[0] === 'administrative_area_level_2') {
                                            city = item.long_name
                                        } else if (item.types[0] === 'country') {
                                            country = item.long_name
                                        } else if (item.types[0] === 'postal_code') {
                                            zip = item.long_name
                                        } else if (item.types[0] === 'administrative_area_level_3') {
                                            county = item.long_name
                                        }
                                        setContactForm({ ...contactForm, address: streetNumber + ' ' + road, city: (county !== "" ? county : city), state: state, country: country, zip: zip })

                                    })

                                } else {
                                    updateContactInfo(event, zip)
                                }
                            }
                        }
                        catch (error) {
                            zip = res?.data?.zip
                            if (res.data.data.result.address_components && Array.isArray(res.data.data.result.address_components)) {
                                res.data.data.result.address_components.map((item, index) => {
                                    if (item.types[0] === 'street_number') {
                                        streetNumber = item.long_name;
                                    } else if (item.types[0] === 'route') {
                                        road = item.long_name
                                    } else if (item.types[0] === 'administrative_area_level_1') {
                                        state = item.short_name
                                    } else if (item.types[0] === 'administrative_area_level_2') {
                                        city = item.long_name
                                    } else if (item.types[0] === 'country') {
                                        country = item.long_name
                                    } else if (item.types[0] === 'postal_code') {
                                        zip = item.long_name
                                    } else if (item.types[0] === 'administrative_area_level_3') {
                                        county = item.long_name
                                    }
                                    setContactForm({ ...contactForm, address: streetNumber + ' ' + road, city: (county !== "" ? county : city), state: state, country: country, zip: zip })

                                })

                            } else {
                                updateContactInfo(event, zip)
                            }
                        }
                    })
            }
            catch (e) {
                updateContactInfo(event, '')
                console.log(e)
            }
        }
    }

    const updateContactInfo = (event, zip) => {
        let fullAddressArr = event.description.split(',');
        let address = (fullAddressArr[0] !== undefined) ? fullAddressArr[0].trim() : '';
        let city = (fullAddressArr[1] !== undefined) ? fullAddressArr[1].trim() : '';
        let fullState = (fullAddressArr[2] !== undefined) ? fullAddressArr[2].trim() : '';
        let country = (fullAddressArr[3] !== undefined) ? fullAddressArr[3].trim() : '';

        let stateArr = fullState.split(' ');
        let state = (stateArr[0] !== undefined) ? stateArr[0].trim() : '';

        setContactForm({ ...contactForm, address: address, city: city, state: state, country: country, zip: zip })
    }

    const handleOnBlur = (value, field) => {
        if (field === 'number') {
            if (value !== '' && !Utils.validateMobileNumber(value)) {
                setInvalidNumber(true)
                // document.getElementById("number-input-field").focus()
            }
            else {
                setInvalidNumber(false)
            }
        }
        else if (field === 'email') {
            if (value !== '' && !Utils.validateEmail(value)) {
                setInvalidEmail(true)
                // document.getElementById("email-input-field").focus()
            }
            else {
                setInvalidEmail(false)
            }
        }
    }

    const handleCustomFieldBody = () => {
        let body = document.getElementById("add_contact_custom_field_collapsible_body")
        if (showCustomFields) {
            body.style.display = 'none'
        }
        else {
            body.style.display = 'block'
        }
        setShowCustomFields(!showCustomFields)
    }

    const handleCustomFieldChange = (value, index_field, id, maxLength = null) => {
        if (maxLength != null & value.length > maxLength) {
            return false
        }

        let previousData = [...customFieldsData]
        if (value === '') {
            previousData[index_field] = null
        }
        else {
            previousData[index_field] = {
                user_custom_field_id: id,
                value: value
            }
        }
        setCustomFieldsData(previousData)
    }

    // const updateSelectedSource=(pickedSource)=>{

    // }

    const isDateInvalid = (birth, anniversary) => {

        let birthDate = moment(birth).isValid();
        let anniversaryDate = moment(anniversary).isValid();

        if ((birth && anniversary) || birth || anniversary) {
            if ((birthDate && anniversaryDate) || (birthDate && anniversary == null) || (anniversaryDate && birth == null)) {
                return false;
            } else {
                if ((!birthDate && anniversary != null) && (!anniversaryDate && birth != null)) {
                    return "Both Birth & Anniversary Date are Invalid!"
                } else if (!birthDate && (!birthDate && birth != null)) {
                    return "Birthdate is Invalid!";
                } else if (!anniversaryDate && (!anniversaryDate && anniversary != null)) {
                    return "Anniversary Date is Invalid!"
                } else {
                    return "Date is Invalid!"
                }
            }
        } else {
            return false;
        }
    }

    const submitNewContact = () => {
        if (contactForm.number !== "") {
            contactForm.number = contactForm.number.replace(/[^0-9]/g, "");
        }

        const isDateValid = isDateInvalid(birthDate, anniversaryDate);


        if (contactForm.email === "" && contactForm.number === '') {
            window.showNotification('Error', 'Email and Number, Either of them are necessary');
        } else if (isDateValid) {
            window.showNotification('Error', isDateValid);
        } else if (invalidEmail) {
            window.showNotification('Error', 'Email is Invalid!');
        }
        else if (invalidNumber) {
            window.showNotification('Error', 'Number is Invalid!');
        } else {
            setSubmittingContact(true)
            let postData = contactForm;

            postData['customFields'] = customFieldsData

            if (moment(birthDate).isValid()) {
                let birth = moment(birthDate).format('YYYY-MM-DD')
                postData['birth_date'] = birth
            }

            if (moment(anniversaryDate).isValid()) {
                let anniversary = moment(anniversaryDate).format('YYYY-MM-DD')
                postData['anniversary_date'] = anniversary
            }

            try {
                submitSingleContact(postData)

                    .then(response => {
                        if (response.data.messageStatus === 'error') {
                            window.showNotification('Error', response.data.html.email !== undefined ? response.data.html.email :
                                response.data.html.number !== undefined ? response.data.html.number : 'Something went wrong');
                            
                            setSubmittingContact(false)

                        } else {
                            window.showNotification('SUCCESS', 'Contact added successfully!');
                            // props.refreshDataForUpdateContact()
                            props.callback(false, true, response.data.insertedContactId)
                            setSubmittingContact(false)
                        }

                    })
            } catch (e) {
                console.log(e)
                setSubmittingContact(false)

            }
        }
    }

    return (
        <React.Fragment>
            {/* <!-- modal body content --> */}
            <GlobalModal
                open={props.open}
                title="Add Contact"
                onClose={props.onClose}
                buttonText={'Add New Contact'}
                hideFooter={currentContact >= contactLimit ? true : (submittingContact ? true : false)}
                buttonIcon={IconList.submitSVG}
                handleSubmit={submitNewContact}
                className={"global-large-modal main-add-contact-modal"}
            >
                {/* {!submittingContact && */}

                {!isLoading &&
                    <>
                        {currentContact < contactLimit &&
                            <div className="add-contact-content-body awesome__scroll_bar">
                                {/* <!-- contact list top --> */}
                                <div className="contact-list-top">
                                    <div className="row">
                                        <form className="col s12">
                                            {/* <!-- single row --> */}
                                            {/* {leadSources != null && */}
                                            <div className="margin-none-row row add-contact-list">
                                                <div className="col s12">
                                                    <div className="contact-list-input custom-input custom-select-arrow col s12">
                                                        <label for="lead_name">Lead Source:</label>
                                                        <div className="select-wrapper">
                                                            <DropdownHelper
                                                                datalist={leadSources != null ? leadSources : []}
                                                                noneFieldRequired={true}
                                                                noneFieldTitle="Select Source"
                                                                className="second__step__area__select"
                                                                mapping={{ title: "source_title" }}
                                                                menuItemClassName=""
                                                                updateSelectedData={handleSelectChange}
                                                                selectedValue={contactForm.selectedSource}
                                                                // updateDatatable={updateDatatable}
                                                                dropdownHeader={'sourceChange'}
                                                                parentDivClassName="ppc__lead_source add-contact-new-wrapper"
                                                            />

                                                        </div>
                                                        <div className="custom-select">
                                                            {IconList.dropdownIcon}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* } */}
                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="first_name" className="active">First name:</label>
                                                        <input type="text" name="" id="first_name" placeholder="Enter First Name" onChange={(e) => handleInput(e, 'first_name')} />
                                                        <div className="custom-select">
                                                            {IconList.peopleIcon}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="last_name" className="active">Last name:</label>
                                                        <input type="text" name="" id="last_name" placeholder="Enter Last name" onChange={(e) => handleInput(e, 'last_name')} />
                                                        <div className="custom-select">
                                                            {IconList.peopleIcon}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="number" className="active" for="number-input-field">Number</label>
                                                        {/* <input type="text" name="" id="number-input-field" placeholder="Enter number" onChange={(e) => handleInput(e, 'number')} 
                                    onBlur={() => handleOnBlur(contactForm.number, 'number')} value={contactForm.number}/> */}
                                                        <div className="custom-select">
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="16" cy="16" r="16" fill="#E5E8EF"></circle>
                                                            </svg>
                                                            <span className="phone-svg">
                                                                {IconList.phoneSVG}
                                                            </span>
                                                        </div>
                                                        <MaskedInput
                                                            mask={['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                            className="form-control"
                                                            placeholder="Enter a number"
                                                            guide={true}
                                                            keepCharPositions={true}
                                                            id="number-input-field"
                                                            // onChange={(e) => handleInput(e, 'number')}
                                                            onChange={(e) => handleInput(e, 'number')}
                                                            value={contactForm.number}
                                                        />
                                                    </div>
                                                    {invalidNumber &&
                                                        <span className="ppc__notValid">Number not valid! Please provide a valid number</span>
                                                    }
                                                </div>
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Email" className="active" for="email-input-field">Email:</label>
                                                        <input type="text" name="" id="email-input-field" placeholder="Enter Email" onChange={(e) => handleInput(e, 'email')} onBlur={() => handleOnBlur(contactForm.email, 'email')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}
                                                            <span className="phone-svg">
                                                                {IconList.emailSVG}
                                                            </span>


                                                        </div>
                                                    </div>
                                                    {invalidEmail &&
                                                        <span className="ppc__notValid">Email not valid! Please provide a valid email</span>
                                                    }
                                                </div>
                                            </div>

                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Company" className="active">Company Name :</label>
                                                        <input type="text" name="" id="Company" placeholder="Enter Company Name" onChange={(e) => handleInput(e, 'company_name')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}
                                                            <span className="phone-svg">
                                                                {IconList.companySVG}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row add-auto-complete">
                                                <div className="col s12">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Address" className="active">Address Search:</label>
                                                        {/* <input type="text" name="" id="Address" placeholder="Enter Street Address" 
                                    onChange={(e) => handleInput(e, 'address')} 
                                    ref={ref} {...props}
                                    /> */}
                                                        <GooglePlacesAutocomplete
                                                            apiKey={GOOGLE_API_KEY}
                                                            // minLength={2}
                                                            autoFocus={false}
                                                            autocompletionRequest={{
                                                                componentRestrictions: {
                                                                    country: ['us', 'ca'],
                                                                },

                                                            }}
                                                            selectProps={{
                                                                value,
                                                                onChange: (e) => { handleAddress(e.value) },
                                                                placeholder: 'Search Location'

                                                            }}
                                                        // onSelect={( description ) => console.log(description)}



                                                        />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}
                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.addressSVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="margin-none-row row">
                                                <div className="col s12">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Address" className="active">Address:</label>
                                                        <input type="text" name="" id="Address" placeholder="Enter Street Address"
                                                            value={contactForm.address} onChange={(e) => handleInput(e, 'address')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}
                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.addressSVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="City" className="active">City:</label>
                                                        <input type="text" name="" id="City" placeholder="Enter City"
                                                            value={contactForm.city} onChange={(e) => handleInput(e, 'city')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}
                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.citySVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="City" className="active">State:</label>
                                                        <input type="text" name="" id="State" placeholder="Enter State"
                                                            value={contactForm.state}
                                                            onChange={(e) => handleInput(e, 'state')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.stateSVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Country" className="active">Country:</label>
                                                        <input type="text" name="" id="Country" placeholder="Enter Country"
                                                            onChange={(e) => handleInput(e, 'country')}
                                                            value={contactForm.country}
                                                        />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.countrySVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Zip" className="active">Zip code:</label>
                                                        <input type="text" name="" id="Zip" placeholder="Enter Zip code"
                                                            value={contactForm.zip}

                                                            onChange={(e) => handleInput(e, 'zip')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.zipSVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="URL" className="active">URL:</label>
                                                        <input type="text" name="" id="URL" placeholder="Enter URL" onChange={(e) => handleInput(e, 'url')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.urlSVG}

                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Deal" className="active">Deal Value:</label>
                                                        <input type="number" name="" id="Deal" placeholder="Enter Deal Value" onChange={(e) => handleInput(e, 'deal_value')} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.dealSVG}

                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- single row --> */}
                                            <div className="margin-none-row row">
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Date" className="active">Birth Date:</label>
                                                        {/* <input type="date" name="" id="URL" placeholder="" onChange={(e) => handleInput(e, 'birth_day')} /> */}
                                                        <CustomDatePicker value={birthDate} changeDate={setBirthDate} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg2 phone-svg">
                                                                {IconList.birthdateSVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m12 l6 xl6">
                                                    <div className="contact-list-input custom-input col s12">
                                                        <label for="Anniversary">Anniversary Date:</label>
                                                        <CustomDatePicker value={anniversaryDate} changeDate={setAnniversaryDate} />
                                                        <div className="custom-select">
                                                            {IconList.addContactCircle}

                                                            <span className="phone-svg">
                                                                {IconList.anniversarySVG}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                {/* <!-- contact list top --> */}

                                {customFields.length > 0 &&

                                    <div className="contact-list-bottom">

                                        <div className="custom-fild-row margin-none-row row toggle-class1">
                                            <div className="col s12">
                                                <div className="custom-fild-heading col s12" onClick={handleCustomFieldBody}>
                                                    <h4>Custom Field</h4>
                                                    <span>
                                                        {IconList.customFieldDropdownSVG}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {showCustomFields &&  */}
                                        <div className="collapsible-body custom_field_body oggle-class2 row" id="add_contact_custom_field_collapsible_body">
                                            <div className="col s12">


                                                {customFields && Array.isArray(customFields) && customFields.map((each, index) => {
                                                    return (renderEachCustomField(each, index, handleCustomFieldChange, customFieldsData));
                                                })
                                                }

                                            </div>
                                        </div>
                                        {/* } */}
                                    </div>
                                }
                            </div>
                        }

                        {currentContact >= contactLimit &&
                            <Upgrade isLoading={isLoading} handleChange={() => props.onClose()} />
                        }


                        {submittingContact &&
                            <NewLoader loading={submittingContact} onlyLoader={true} size={30} message={""} />
                        }
                    </>
                }

                {isLoading &&
                    <div className="loading___container">
                        Please wait...
                    </div>
                }
            </GlobalModal>
            {/* </div> */}

            {/* {!submittingContact &&
    <div className="add-contact-footer manage-tag-footer modal-footer">
        <a href="#" className="manage-tag-footer-compain manage-tag-footer-btn-right waves-effect waves-green btn-flat accent--bg--color"
            onClick={submitNewContact}
        >
            {IconList.submitSVG}
            Add New Contact</a>

        <a href="#" className="manage-tag-footer-btn-left modal-close waves-effect waves-green btn-flat"
            onClick={(e)=>{
                e.preventDefault();
                props.callback(false, false)
            }}>
            
            {IconList.cancelSVG}
            Cancel</a>

    </div>
    } */}


        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        userTagList: state.addContactReducer.userTagListAddContact,
        storedSourceList: state.addContactReducer.sourceListAddContact,
        storedCustomField: state.addContactReducer.userCustomFieldsAddContact
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
        storeUserTagList: (params) => dispatch(setUserTagList(params)),
        fetchUserSourceList: (params, callback) => dispatch(getUserSourceList(params, callback)),
        storeUserSourceList: (params) => dispatch(setUserSourceList(params)),
        fetchUserCustomFields: (params, callback) => dispatch(getUserCustomFieldList(params, callback)),
        storeUserCustomFields: (params) => dispatch(setUserCustomFieldList(params)),
        storeUserCustomFieldsNew: (params) => dispatch(storeUserCustomFieldsListNew(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContactModal);

export const renderEachCustomField = (data, index, onChange, previousData) => {

    let type_id = data.type_id;
    let _values = (typeof previousData[index] !== 'undefined' && previousData[index] != null) ?
        (typeof previousData[index].value !== 'undefined' ?
            (type_id === CustomFieldType.CHECKBOX) ? previousData[index].value.split(',') : previousData[index].value : '') : '';

    const handleOnChange = (e, self) => {

        if (type_id === CustomFieldType.NUMERIC || type_id === CustomFieldType.ZIP_CODE) {
            onChange(e.target.value, index, data.id, data?.max_length)
        }
        else if (type_id === CustomFieldType.CHECKBOX) {
            let previous_value = (typeof previousData[index] !== 'undefined' && previousData[index] != null) ? (typeof previousData[index].value !== 'undefined' ? previousData[index].value : '') : '',
                new_value = '';
            if (e.target.checked) {
                if (previous_value.trim() === '') {
                    new_value = e.target.value
                }
                else {
                    new_value = previous_value + "," + e.target.value
                }
            }
            else {
                if (previous_value.indexOf(e.target.value) !== -1) {
                    let value_array = previous_value.split(",")
                    value_array.forEach((each_data, each_index) => {
                        if (each_data !== e.target.value) {
                            if (new_value === '') {
                                new_value += each_data
                            }
                            else {
                                new_value = new_value + "," + each_data
                            }
                        }
                    });
                }
            }
            onChange(new_value, index, data.id)
        }
        else if (type_id === CustomFieldType.DATE) {
            onChange(moment(e.target.value).format('YYYY-MM-DD'), index, data.id)
        }
        else if (type_id === CustomFieldType.DATETIME) {
            onChange(moment(e.target.value).format('MM-D-YYYY HH:mm:ss'), index, data.id)
        }
        else if (type_id === CustomFieldType.RADIO) {
            onChange(e.target.value, index, data.id)
        }
        else {
            onChange(e.target.value, index, data.id)
        }
    }

    if (type_id === CustomFieldType.TEXT || type_id === CustomFieldType.MULTILINE_TEXT ||
        type_id === CustomFieldType.NUMERIC || type_id === CustomFieldType.DATE ||
        type_id === CustomFieldType.DATETIME ||
        type_id === CustomFieldType.PHONE || type_id === CustomFieldType.ZIP_CODE ||
        type_id === CustomFieldType.WEBSITE || type_id === CustomFieldType.CHECKBOX ||
        type_id === CustomFieldType.RADIO || type_id === CustomFieldType.SELECT) {
        return (
            <div className="input-field col s12 m12 custom_input_forms custom_contact_field" key={`${type_id} ${index}`}>
                <label for={`custom-field-${index}`} style={{ left: '0', marginBottom: 0, position: 'relative' }}>{data?.title}</label>

                {(type_id === CustomFieldType.TEXT || type_id === CustomFieldType.PHONE) &&
                    <input type="text" id={`custom-field-${index}`}
                        value={(previousData[index] !== undefined && previousData[index] != null) ? previousData[index].value : ''} placeholder={"Enter " + data?.title}
                        maxLength={data?.max_length} onChange={(e) => handleOnChange(e)} />
                }

                {type_id === CustomFieldType.MULTILINE_TEXT &&
                    <textarea rows="5" className={'custom-field-textarea'} id={`custom-field-${index}`} placeholder={"Enter " + data?.title}
                        onChange={(e) => handleOnChange(e)}
                    >{previousData?.[index]?.value}</textarea>
                }

                {(type_id === CustomFieldType.NUMERIC || type_id === CustomFieldType.ZIP_CODE) &&
                    <input type="number" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id === CustomFieldType.DATE &&
                    <input type="date" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id === CustomFieldType.DATETIME &&
                    <input type="datetime-local" id={`custom-field-${index}`} value={moment(previousData?.[index]?.value).format('YYYY-MM-DD HH:mm:ss')} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id === CustomFieldType.WEBSITE &&
                    <input type="url" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id === CustomFieldType.CHECKBOX &&
                    <div className="check-box-input-groups">
                        <FormGroup row clsssName="custom_check_box_container">
                            {data.default_values && Array.isArray(data.default_values) && data.default_values.map((each, each_index) => {
                                let isChecked = _values.indexOf(each) !== -1 ? true : false
                                return (
                                    <FormControlLabel
                                        clsssName="custom_check_box"
                                        control={
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={handleOnChange}
                                                name={`${index}-${data.personalize_tag}[]`}
                                                value={each}
                                            />
                                        }
                                        label={each}
                                    />
                                )
                            })}
                        </FormGroup>
                    </div>
                }

                {type_id === CustomFieldType.RADIO &&
                    <div className="radio-button-input-groups">
                        <RadioGroup row aria-label={index + "-" + data.personalize_tag} name={index + "-" + data.personalize_tag} value={_values} onChange={handleOnChange}>
                            {data.default_values && Array.isArray(data.default_values) && data.default_values.map((each, each_index) => {
                                return (
                                    <FormControlLabel
                                        value={each}
                                        control={<Radio />}
                                        label={each}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </div>
                }

                {type_id === CustomFieldType.SELECT &&
                    <select className="form-control h-100 d-block" placeholder={"Enter " + data.title} name={`add_contact_custom_field${data.personalize_tag}`} onChange={(e) => handleOnChange(e)}>
                        <option value="" key={-1}>{data.title}</option>
                        {data.default_values && Array.isArray(data.default_values) && data.default_values.map((each, each_index) => {
                            if (previousData[index] !== undefined && previousData[index] != null && previousData[index].value === each) {
                                return (
                                    <option value={each} key={`custom-select-${each_index}`} selected>{each}</option>
                                )
                            }
                            else
                                return (
                                    <option value={each} key={`custom-select-${each_index}`} >{each}</option>
                                )
                        })}
                    </select>
                }
                {/* {(type_id !== CustomFieldType.DATE || type_id !== CustomFieldType.CHECKBOX) &&
                <span>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path d="M8.6368 10.3234C8.57582 10.3844 8.5348 10.462 8.5174 10.5456L7.90106 13.6284C7.87233 13.7713 7.91766 13.9186 8.02047 14.0224C8.10332 14.1052 8.2149 14.1496 8.32919 14.1496C8.35697 14.1496 8.38586 14.147 8.41459 14.141L11.4964 13.5246C11.5818 13.5071 11.6594 13.4662 11.7196 13.4051L18.6172 6.50745L15.5354 3.42578L8.6368 10.3234Z" fill="#133159"/>
                            <path d="M20.7483 1.29768C19.8984 0.447648 18.5157 0.447648 17.6664 1.29768L16.46 2.50417L19.5418 5.58599L20.7483 4.37934C21.1598 3.96878 21.3865 3.42124 21.3865 2.83891C21.3865 2.25658 21.1598 1.70905 20.7483 1.29768Z" fill="#133159"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                            <rect width="20.9231" height="20.9231" fill="white" transform="translate(0.464844 0.617188)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                } */}
            </div>
        )
    }

    return null
}