import React, {Fragment} from "react";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import {a2pOnBoard} from "./index";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

export const volumePolicyType = {
    policyTypeLowVolume: 1,
    policyTypeHighVolume: 2,
}

const AskVolume = ({ data=null, isBack, onClose, setActiveStep, setSelectedVolume }) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return(
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={`${Styles.headerTitle} ${Styles.headerTitleCenter}`} variant="h5">A2P/10DLC Registration</Typography>
                {
                    isBack &&
                    <Button size="small" onClick={() => {
                        setActiveStep(a2pOnBoard.EIN_CONFIRM)
                    }} className={`${classes.root}`} variant="outlined">
                        BACK
                    </Button>
                }
                {
                    data.isExit &&
                    <Button size="small" onClick={handleClose} className={`${classes.root} ${Styles.redButton}`} variant="outlined">
                        EXIT
                    </Button>
                }
            </div>

            <div className={Styles.modalBody}>
                <div className={Styles.askConsent}>
                    On average, do you plan to send more or less than 6,000 messages per day?
                </div>
               <div>
                   <Button  size="large" onClick={()=>{setSelectedVolume(volumePolicyType.policyTypeHighVolume); setActiveStep(a2pOnBoard.REGISTRATION_PRICING);}} className={`${classes.root} ${Styles.overwriteButton} ${Styles.mr1} ${Styles.width240px}`} variant="contained">
                       More than 6,000/day
                   </Button>
                   <Button onClick={()=>{setSelectedVolume(volumePolicyType.policyTypeLowVolume); setActiveStep(a2pOnBoard.REGISTRATION_PRICING);}} size="large" className={`${classes.root} ${Styles.redButtonFill} ${Styles.width240px}`} variant="contained">
                       Less than 6,000/day
                   </Button>
               </div>
            </div>
            <div className={Styles.modalFooter}>
            </div>
        </Fragment>
    );
};

export default AskVolume;