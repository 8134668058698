import {makeStyles} from "@material-ui/core";

const useBulkEmailUnsubscribe = makeStyles((theme) => ({
    page: {
        width: "100vw",
        height: "100vh",
        color: "#373737",
        margin: 0,
        padding: 0,
        background: "linear-gradient(45deg, #543093 32%, #d960ae 100%)",
    },
    container: {
        width: "600px",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        margin: 0,
        position: "absolute",
        background: "#fefefe",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingBottom: "30px",
        boxShadow: "5px 10px 40px 0 rgba(0, 0, 0, 0.2)",
        borderRadius: "5px",
    },
    innerContainer: {
        width: "100%"
    },
    bottom: {
        textAlign: "center",
        marginTop: "0em",
        maxWidth: "70%",
        position: "relative",
        margin: "0 auto",
    },
    title: {
        fontFamily: '"Rokkitt", sans-serif',
        letterSpacing: "0.05em",
        fontSize: "30px",
        lineHeight: 1.2,
        textAlign: "center",
        margin: "0 auto 0.25em",
    },
    subtitle: {
        color: "#777",
        letterSpacing: "0.1em",
        fontSize: "16px",
        lineHeight: "1.4",
        margin: "0 auto 2em"
    },
    buttons: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        "& button": {
            padding: "10px 30px",
            fontSize: "20px",
            backgroundColor: "#d960ae",
            border: 0,
            cursor: "pointer",
            borderRadius: "4px",
            letterSpacing: "0.1em",
            color: "#ffffff",
            marginRight: "20px",
            marginBottom: "15px",
            transition: "all 0.25s ease-in-out",
            "&:hover": {
                backgroundColor: '#a64084',
            }
        }
    }
}));

export default useBulkEmailUnsubscribe;