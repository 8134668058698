import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchUser = async () => {
    return await sendRequest("get", "https://my-json-server.typicode.com/rislam169/demo/user");
}

export const signupRequest = async (request) => {
    return sendRequest("post", "http://pypeprofile.test/api/v1/signup", request);
}

export const signupDataRequest = async (request) => {
    return sendRequest("post", Api.signupPageData, request);
}

export const checkRegistrationValidate = async (request) => {
    return sendRequest("post", Api.validateRegistration, request);
}

export const signup = async (request) => {
    return sendRequest("post", Api.signupv2, request);
}

export const verifyOtp = async (request) => {
    return sendRequest("post", Api.verifyOtp, request);
}

export const resendOtpFromRegistrationPageScreen = async (request) => {
    return sendRequest("post", Api.resendOtpFromRegistrationPageScreen, request);
}