import { Step, StepLabel, Stepper } from '@material-ui/core'

const DirectMailSteps = ({steps, current_step, onclick, completed_steps}) => {
    const renderSteps = () => {
        let view = [];
        steps.forEach((item, index) => {
            const stepProps = {};
            const labelProps = {};
            view.push(
                <Step key={index} {...stepProps}>
                    <StepLabel onClick={() => onclick(index)} {...labelProps}>{item.title}</StepLabel>
                </Step>
            );
        });
        return view;
    }

    return(
        <Stepper activeStep={current_step}>
            {renderSteps()}
        </Stepper>
    );
}
export default DirectMailSteps;