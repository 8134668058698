import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { unsubscribePublicContact } from '../../api/globalApi';
import './sharePage.css';
import Utils from '../../helpers/Utils';

const ContactCampaignUnsubscribe = () => {

    const { contactId } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Utils.setAgencyTitleAndFav("Unsubscribe");
        unsubscribePublicContact({contact_id : contactId}).then(res => {
            setLoading(false);
        });
    },[]);

    return ( 
        <div className="share_page__wrapper trigger_share unsubscribe_contact">
            <div className="share_page__inner_wrapper">
                {
                    loading ?
                        <h5><CircularProgress/> Unsubscribing...</h5>
                    :
                        <h5>You've been unsubscribed</h5>
                }
            </div>
        </div> 
    );
}
 
export default ContactCampaignUnsubscribe;