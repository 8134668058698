import React from "react";
import './review.css';
import _ApiList from "../../../contactDetails/SendModules/api/ApiList";
import {Grid, MenuItem} from "@material-ui/core";
import CustomSelect from "../../assets/CustomSelect";


function Review(props) {
    
    const submitReview = () => {
        if(props.numberOfStar && !props.reviewSubmitted){
            _ApiList.sendCallReview({
                numberOfStar: props.numberOfStar,
                contactId : props.contactId,
                note : props.note
            }, false).then(res => {
                if (res.data.status) {
                    props.setReviewSubmitted(true);
                    props.setNote('');
                    props.setNumberOfStar(props.numberOfStar);
                    window.showNotification('SUCCESS', res.data.message);
                } else {
                    window.showNotification('ERROR', res.data.message);
                }
            })
        }
    }

    const reviewStarClick = (star) => {
        if(!props.reviewSubmitted) {
            props.setNumberOfStar(star);
        } else {
            window.showNotification('ERROR', "You can't update after successful submission.");
        }
    }

    const callOutcomeChangeHandler = (event) => {
        let outcomeId = event.target.value;
        props.setSelectedCallOutcome(outcomeId);

        if (outcomeId) {
            _ApiList.changeContactCallOutcome({outcomeId: outcomeId, contactId : props.contactId})
                .then(response => {
                    if (response.data.status === 'success') {
                        window.showNotification('SUCCESS', response.data.html)
                    } else {
                        window.showNotification('ERROR', response.data.html)
                    }
                })
                .catch(error => {
                    window.showNotification('ERROR', error)
                })
        }
    }

    return (
        <div className="call__review_wrapper">
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <div className="call_outcome">
                        <label htmlFor="">Call Outcome</label>
                        <CustomSelect
                            id="lead-source-select"
                            value={props.selectedCallOutcome}
                            handleChange={callOutcomeChangeHandler}
                            item={props.callOutcomes}
                            value_field={'id'}
                            titel_field={'outcome'}
                            menuItemClass={'dropdownhelper-menuitem-class'}
                            placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a Call Outcome </MenuItem>)}
                        />
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className="review_right">
                        <div className="call__review text-center">
                            <h5 className="mt-4">How would you rate our phone system?</h5>
                            <div className="review__star">
                                <span className="material-icons" onClick={() => reviewStarClick(1)}>{props.numberOfStar >= 1 ? 'grade' : 'star_border'}</span>
                                <span className="material-icons" onClick={() => reviewStarClick(2)}>{props.numberOfStar >= 2 ? 'grade' : 'star_border'}</span>
                                <span className="material-icons" onClick={() => reviewStarClick(3)}>{props.numberOfStar >= 3 ? 'grade' : 'star_border'}</span>
                                <span className="material-icons" onClick={() => reviewStarClick(4)}>{props.numberOfStar >= 4 ? 'grade' : 'star_border'}</span>
                                <span className="material-icons" onClick={() => reviewStarClick(5)}>{props.numberOfStar >= 5 ? 'grade' : 'star_border'}</span>
                            </div>
                        </div>
                        {(props.numberOfStar < 5 && props.numberOfStar > 0 && !props.reviewSubmitted) &&
                            <div className="form-group">
                                <label>What could be better?</label>
                                <input type="text" className='form-control' placeholder="Any suggestion?" value={props.note} onChange={(e) => props.setNote(e.target.value)}/>
                            </div>
                        }
                        <div className="form-group text-center">
                            {
                                !props.reviewSubmitted ?
                                    <button className="btn btn-sm accent--bg--color" disabled={props.numberOfStar === 0} onClick={() => submitReview()}>Submit Review</button>
                                : <span>Thanks for the review</span>
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Review;
