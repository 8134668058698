import socketIOClient from "socket.io-client";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import { CODE_SNIPPET, LAYOUT_SETTINGS, SITE_NOTIFICATION, MESSAGE_TYPE, NOTIFICATION_MESSAGE_TYPE } from "../constants/CoreConstants";

/**
 * Handle error exception
 *
 * @param Error error
 * @param Function/null callback
 */
import { getCookie, getCookieLogin } from "./Cookie";
import { getCodeSnippet } from "../api/globalApi";
import { fetchLoginPageData } from "../api/loginApi";
import { US_STATE_LIST, PHONE_STATE } from "../components/GlobalContactDetailsForDeal/RightSide/helper/core_constant";



const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email, full = false) => {
    if (name === undefined || name == null || name.trim() === '' || name === 'null null') {
        if (number === undefined || number == null || number.trim() === '') {
            if (email === undefined || email == null || email.trim() === '') {
                return '[No Name]';
            } else {
                return email.length > 15 && !full ? email.substr(0, 13) + ' ...' : email;
            }
        } else {
            return number;
        }
    }
    name = name.trim()
    return name.length > 15 && !full ? name.substr(0, 13) + ' ...' : name;
}

const getShorterText = (text, length = 15) => {
    return text.length > length ? text.substr(0, length - 3) + ' ...' : text;
}

export const getStateObj = (value) => US_STATE_LIST.find((state) => state.value === value);

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Something went wrong', type = 'error') => {
    window.showNotification(type.toUpperCase(), message);
};

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name, initialCharacter = '') => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = initialCharacter
            if (name === 'null null' || name === '' || name === ' ') {
                fullNameInitial = initialCharacter;
            } else if (name !== 'undefined' || name !== null) {
                fullNameInitial = name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return initialCharacter;
        }
    } catch (error) {
        return initialCharacter;
    }
}

export const formatNumber = (number) => {
    if (number) {
        var cleaned = ('' + number).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return number.replace(/[. \-+()]/g, '');
    } else {
        return number;
    }

}

const loadSocket = (userId) => {
    const socket = socketIOClient(process.env.REACT_APP_NODE_SERVER, {
        resource: 'B/socket.io',
        'force new connection': true
    });

    const userRoom = "room:" + userId;
    const userBackgroundNotificationRoom = "background-task-notification:room:" + userId;

    socket.on('connect', function (data) {
        socket.emit('join-notification-room', userRoom);
        socket.emit('join-background-task-notification-room', userBackgroundNotificationRoom);
    });

    socket.on('notification', function (data) {
        if (getAccountData('new_reply_notify_push_notification')) {
            if (typeof data.params.type !== 'undefined' && (data.params.type == SITE_NOTIFICATION.SMS || data.params.type == SITE_NOTIFICATION.EMAIL || data.params.type == SITE_NOTIFICATION.CALL)) {
                toastr.options = {
                    // "closeButton": true,
                    // "debug": false,
                    // "newestOnTop": false,
                    // "progressBar": false,
                    // "positionClass": "toast-bottom-right",
                    // "preventDuplicates": false,
                    "onclick": typeof data.params.permalink !== 'undefined' && data.params.permalink ? function () { window.location.href = "/contacts/" + data.params.contact_id } : null,
                    // "timeOut": "0",
                    // "extendedTimeOut": "0",
                    // "showEasing": "swing",
                    // "hideEasing": "linear",
                    // "showMethod": "fadeIn",
                    // "hideMethod": "fadeOut"
                };

                toastr.info(data.params.message);
            }

            if (Object.values(NOTIFICATION_MESSAGE_TYPE).includes(parseInt(data.params.type))) {
                if (typeof window.loadInboxThreads === 'function') {
                    window.loadInboxThreads();
                }
                if (typeof window.refreshConersation === 'function') {
                    window.refreshConersation(data.params.contact_id);
                }
                if (typeof window.updateNotificationCount === 'function') {
                    window.updateNotificationCount(true, true);
                }
            }
        }
    });

    socket.on('background-task-notification', function (data) {

        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "3000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };

        if (data.params.action === 'delete') {
            if (typeof data.params.type !== 'undefined' && data.params.type == 'error') {
                toastr.error(data.params.message);
            } else {
                if (data.params.message !== undefined && data.params.message === "Campaign is started") {
                    // handleSocketForCampaign(data.params.related_id)
                    if (window.campaignStatusSocketProcess !== undefined) {
                        window.campaignStatusSocketProcess(parseInt(data.params.related_id))
                    }
                }
                toastr.success(data.params.message);
            }

        } else if (data.params.action === 'insert') {
            toastr.info(data.params.message);
        } else if (data.params.action === 'fileDownload') {
            let processId = 'downloadPopupHeader';
            if (isElementInViewport(processId)) {
                mySuccessProcessFunction(data.params.file_name, data.params.file_url);
            } else {
                FileDownloadNotificationHtmlTemplate('DOWNLOAD', 'Your file is ready to download!', data.params.file_url);
            }

        } else if (data.params.action === 'viewResult') {
            let processId = 'importEnhancePopupHeader';
            if (isElementInViewport(processId)) {
                handleAutoRedirect(data.params.fileSource);
            } else {
                ViewResultNotificationHtmlTemplate('VIEW RESULTS', 'Your data results are ready!', data.params.fileSource);
            }
        } else if (data.params.action === 'redirect') {

            let currentURL = window.location.href;

            if (data.params.lookupType === 'DNC_LOOKUP' && currentURL.includes("tab=dnc&page=results")) {
                let pageLink = 'contacts/clean-list?tab=dnc&page=results';
                window.location.href = window.location.origin + '/' + pageLink;
            }

            if (data.params.lookupType === 'CARRIER_LOOKUP' && currentURL.includes("tab=verify&page=results")) {
                let pageLink = 'contacts/clean-list?tab=verify&page=results';
                window.location.href = window.location.origin + '/' + pageLink;
            }

            if (data.params.lookupType === 'EMAIL_LOOKUP' && currentURL.includes("tab=verify&page=results")) {
                let pageLink = 'contacts/clean-list?tab=verify&page=results';
                window.location.href = window.location.origin + '/' + pageLink;
            }
        }
        else {

        }
    });
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * parentId
 * canvaApiKey
 * fullName
 * email
 * agencyLogo
 * profileImage
 * layoutSettings
 * userOnboard
 * isAgencyMapPackageActive
 * note_edit_access
 * companyName
*/
export const getAccountData = (needle) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if (check_new !== undefined) {
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    } else {
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

export const setLayoutStyles = () => {
    let styles = getAccountData('layoutSettings');
    if (styles !== undefined && styles[LAYOUT_SETTINGS.TOPBAR_BACKGROUND]) {
        document.documentElement.style.setProperty('--topbar-background', styles[LAYOUT_SETTINGS.TOPBAR_BACKGROUND]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.TOPBAR_TEXT_COLOR]) {
        document.documentElement.style.setProperty('--topbar-text-color', styles[LAYOUT_SETTINGS.TOPBAR_TEXT_COLOR]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.SIDEBAR_BACKGROUND]) {
        document.documentElement.style.setProperty('--sidebar-background', styles[LAYOUT_SETTINGS.SIDEBAR_BACKGROUND]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.SIDEBAR_TEXT_COLOR]) {
        document.documentElement.style.setProperty('--sidebar-text-color', styles[LAYOUT_SETTINGS.SIDEBAR_TEXT_COLOR]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.SIDEBAR_ICON_COLOR]) {
        document.documentElement.style.setProperty('--sidebar-icon-color', styles[LAYOUT_SETTINGS.SIDEBAR_ICON_COLOR]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.SIDEBAR_HOVER_COLOR]) {
        document.documentElement.style.setProperty('--sidebar-hover-color', styles[LAYOUT_SETTINGS.SIDEBAR_HOVER_COLOR]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.ACCENT_COLOR]) {
        document.documentElement.style.setProperty('--accent-color', styles[LAYOUT_SETTINGS.ACCENT_COLOR]);
        document.documentElement.style.setProperty('--accent-color-opacity1', hexToRgbA(styles[LAYOUT_SETTINGS.ACCENT_COLOR]));
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.ACCENT_TEXT_COLOR]) {
        document.documentElement.style.setProperty('--accent-text-color', styles[LAYOUT_SETTINGS.ACCENT_TEXT_COLOR]);
    }
    if (styles !== undefined && styles[LAYOUT_SETTINGS.SHORT_MENU] !== undefined && styles[LAYOUT_SETTINGS.SHORT_MENU] === "1") {
        if (window.setCollapsedMenu) {
            window.setCollapsedMenu(true);
        }
    } else if (styles !== undefined) {
        if (window.setCollapsedMenu) {
            window.setCollapsedMenu(false);
        }
    }


}

export const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)';
    }
    return hex;
}

export const isDefaultAgency = (agencyId) => {
    if (parseInt(agencyId) === 1) {
        return true;
    } else {
        return false;
    }
}

const generateTimezoneList = (tzone = null) => {
    let timezone = [
        { 'America/New_York': 'Eastern Time' },
        { 'America/Chicago': 'Central Time' },
        { 'America/Denver': 'Mountain Time' },
        { 'America/Phoenix': 'Arizona Time' },
        { 'America/Los_Angeles': 'Pacific Time' },
        { 'America/Anchorage': 'Alaska Time' },
        { 'Pacific/Honolulu': 'Hawaii Time' },
    ];

    if (tzone) {
        const found = timezone.find(element => Object.getOwnPropertyNames(element)[0] === tzone);

        return found[Object.getOwnPropertyNames(found)];
    }

    return timezone;
}

const loadCodeSnippets = (page, data) => {
    try {
        getCodeSnippet({ page, data }).then(response => {
            if (response.data.status) {
                response.data.codeSnippets.forEach(codeSnippet => {

                    let script = document.createRange().createContextualFragment(codeSnippet.code);
                    let tagName = codeSnippet.position == CODE_SNIPPET.POSITION_HEADER ? 'head' : 'body';
                    document[tagName].append(script);
                })
            }
        }).catch(err => {
            console.log("pypepro-parent-module/src/helpers/Utils.js > loadCodeSnippets > catch")
        });
    } catch (error) {
        console.log("pypepro-parent-module/src/helpers/Utils.js > loadCodeSnippets")
    }
}

const referralTrackingScript = (userEmail) => {
    try {
        const trackingScript = `<script>window.fpr("referral",{email: ${userEmail})</script>`;

        let script = document.createRange().createContextualFragment(trackingScript);
        let tagName = 'head';
        document[tagName].append(script);

    } catch (error) {
        console.log("pypepro-parent-module/src/helpers/Utils.js > referralTrackingScript")
    }
}

const getIconTextFromContact = (contact) => {

    let firstName = (contact.first_name != undefined && contact.first_name != null) ? contact.first_name.trim() : ''
    let lastName = (contact.last_name != undefined && contact.last_name != null) ? contact.last_name.trim() : ''

    if (firstName != '' && lastName != '') {
        return (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase()
    } else if (firstName != '') {
        return firstName.substring(0, 2).toUpperCase()
    } else if (lastName != '') {
        return lastName.substring(0, 2).toUpperCase()
    } else {
        return 'N/A'
    }
}

const isEmpty = (text) => {
    if (text !== undefined && text != null && text !== '') {
        return false
    } else {
        return true
    }
}

const getContactName = (contact) => {

    let firstName = (contact.first_name !== undefined && contact.first_name != null) ? contact.first_name.trim() : ''
    let lastName = (contact.last_name !== undefined && contact.last_name != null) ? contact.last_name.trim() : ''
    if (firstName === '' && lastName === '') {

        if (!isEmpty(contact.number)) {
            return contact.number
        } else if (!isEmpty(contact.email)) {
            return contact.email
        } else {
            return null
        }
    } else {
        return (firstName + ' ' + lastName).trim()
    }


}

const reduceTextLength = (text, limit = 40) => {
    if (text !== undefined) {
        text = text.toString();
        if (text.length > limit) {
            return text.substring(0, limit);
        } else {
            return text;
        }
    } else {
        return "";
    }

}

const setAgencyTitleAndFav = (title) => {
    document.title = title;
    fetchLoginPageData().then(res => {
        document.title = title + " | " + res.data.data.agencyName;

        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = res?.data?.data?.favIcon;
    });
}

const checkLetterExistInText = (text) => {
    var regExp = /[a-zA-Z]/g;
    let result = false
    if (regExp.test(text)) {

        result = true
    }
    return result
}

const checkNumberExistInText = (text) => {
    var regExp = /[0-9]/g;
    let result = false
    if (regExp.test(text)) {

        result = true
    }
    return result
}

const isNaturalNumber = (text) => {
    text = text.toString(); // force the value incase it is not
    let n1 = Math.abs(text),
        n2 = parseInt(text, 10);
    return !isNaN(n1) && n2 === n1 && n1.toString() === text;
}

const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

const mySuccessProcessFunction = (fileName, fileUrl) => {
    let processId = 'downloadPopupHeader';
    let helpText = 'helpTextPopupModal';
    let successId = 'downloadPopupSuccessHeader';
    let successTextId = 'successTextPopupModal';
    let downloadBtnId = 'downloadPopupModal';

    // Check if the div is initially visible
    if (isElementInViewport(processId)) {
        document.getElementById(processId).classList.add('d-none');
        document.getElementById(helpText).classList.add('d-none');

        document.getElementById(successId).classList.remove('displayHidden');
        document.getElementById(successTextId).classList.remove('displayHidden');
        document.getElementById(downloadBtnId).classList.remove('displayHidden');
        let element = document.getElementById(successId);
        element.style.display = 'block';
        element.style.textAlign = 'center';

        let element2 = document.getElementById(successTextId);
        element2.style.display = 'block';
        element2.style.textAlign = 'center';

        let element3 = document.getElementById(downloadBtnId);
        element3.style.display = 'block';

        document.getElementById("downloadFileName").textContent = fileName;

        var downloadButton = document.getElementById('downloadPopupModal');
        downloadButton.addEventListener('click', function () {
            var pdfUrl = fileUrl;
            var anchor = document.createElement('a');
            anchor.href = pdfUrl;
            anchor.download = 'your-pdf-file.pdf';
            anchor.click();

            var myButton = document.getElementById('closeDownloadPopUp');
            setTimeout(function () {
                myButton.click();
            }, 1000);
        });

    } else {

    }
}

function handleAutoRedirect(newLink) {
    let pageLink = '';
    switch (newLink) {
        case "ALL":
            pageLink = 'contacts/clean-list?page=imports&open=new';
            break;
        case "CARRIER":
            pageLink = 'contacts/clean-list?tab=verify&page=imports&open=new';
            break;
        case "DNC":
            pageLink = 'contacts/clean-list?tab=dnc&page=imports&open=new';
            break;
        case "SKIP_TRACE":
            pageLink = 'contacts/clean-list?tab=skiptracing&page=imports&open=new';
            break;
        default:
            pageLink = 'contacts/clean-list';
    }
    window.location.href = window.location.origin + '/' + pageLink;

};


function isElementInViewport(elementId) {
    var el = document.getElementById(elementId);
    if (!el) return false; // Return false if element is not found
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export const FileDownloadNotificationHtmlTemplate = (buttonText, message, link) => {
    let leftIcon = 'default';
    let closeText = '';
    let timer = 10000;
    let classText = 'info';
    let new_interval = '', container = '';
    function handleOnCloseFileDownloadNotification() {
        clearInterval(new_interval)
        new_interval = '';
        container.classList.add('pypepro_file_download_nt_hide');
    }

    function handleDownload() {
        let input = document.getElementById("download_link_input")
        let newLink = input.value;
        window.open(newLink, '_blank');
    };

    let element_exist = document.getElementById("pypepro_notification_file_download_wrp_id");
    if (element_exist) {
        if (element_exist.getAttribute('interval')) {
            clearInterval(element_exist.getAttribute('interval'))
            element_exist.setAttribute('interval', null)
        }
        if (!element_exist.classList.contains(classText)) {
            element_exist.classList.remove('info')
            element_exist.classList.remove('error')
            element_exist.classList.remove('success')
            element_exist.classList.remove('warning')
            element_exist.classList.remove('default')
            element_exist.classList.add(classText)
        }
        // document.getElementById("download_link_input").value = link;

        element_exist.classList.remove('pypepro_file_download_nt_hide')
        if (element_exist.classList.contains('pypepro_nt_replace')) {
            element_exist.classList.remove('pypepro_nt_replace')
        }
        else {
            element_exist.classList.add('pypepro_nt_replace')
        }
        setTimeout(() => {
            try {
                /* icon */
                let icon_span = element_exist.querySelector('span[id=pypepro_nt_li_file_download_wrp_id]');
                if (icon_span) {
                    icon_span.innerHTML = leftIcon
                }
                /* v2 */
                let message_span_v2 = element_exist.querySelector('input[id=download_link_input]');
                if (message_span_v2) {
                    message_span_v2.value = link
                }
                /* text */
                let message_span = element_exist.querySelector('span[id=pypepro_nt_info_file_download_wrp_id]');
                if (message_span) {
                    message_span.innerHTML = `<b>${buttonText}</b> ${message}`
                }
                /* dismiss */
                let dismiss_span = element_exist.querySelector('span[id=pypepro_nt_close_file_download_wrp_id]');
                if (dismiss_span) {
                    dismiss_span.innerText = closeText
                }
                new_interval = setTimeout(() => {
                    try {
                        element_exist.classList.add('pypepro_file_download_nt_hide');
                    }
                    catch (error) { }
                }, timer);
                element_exist.setAttribute('interval', new_interval)
            }
            catch (error) { }
        }, 500);
    }
    else {
        const elm = `
      <div class='customToasterContent show'>
          <div class="customToasterMessage">${message}</div>
          <div class="customToasterActions">
            <input value="${link}" hidden id="download_link_input">
            <button id="custom_file_download" class="customToasterDownload" >${buttonText}</button>
            <button id="pypepro_nt_close_file_download_wrp_id" class="customToasterCancel" >CLOSE</button>
          </div>
    </div>
    `
        container = document.createElement('div')
        container.className = "pypepro_notification_template"
        container.className = classText
        container.id = "pypepro_notification_file_download_wrp_id"
        container.innerHTML = elm
        document.body.appendChild(container)
        // document.getElementById("download_link_input").value = link;

        setTimeout(() => {
            container.classList.add('animate')
            // document.getElementById("download_link_input").value = link;
        }, 0);

        if (document.getElementById("pypepro_nt_close_file_download_wrp_id")) {
            document.getElementById("pypepro_nt_close_file_download_wrp_id").addEventListener('click', handleOnCloseFileDownloadNotification)
        }

        if (document.getElementById("custom_file_download")) {
            document.getElementById("custom_file_download").addEventListener('click', handleDownload)
        }

        new_interval = setTimeout(() => {
            try {
                if (document.getElementById("pypepro_nt_close_file_download_wrp_id")) {
                    document.getElementById("pypepro_nt_close_file_download_wrp_id").removeEventListener('click', handleOnCloseFileDownloadNotification)
                }
                // container.classList.add('pypepro_file_download_nt_hide');
                let element_exist = document.getElementById("pypepro_notification_file_download_wrp_id");
                if (element_exist) {
                    element_exist.remove();
                }

            }
            catch (error) { }
        }, timer);
        container.setAttribute('interval', new_interval)
    }
}

export const ViewResultNotificationHtmlTemplate = (buttonText, message, link) => {
    let leftIcon = 'default';
    let closeText = '';
    let timer = 10000;
    let classText = 'info';
    let new_interval = '', container = '';
    function handleOnCloseViewResultNotification() {
        clearInterval(new_interval)
        new_interval = '';
        container.classList.add('pypepro_view_result_nt_hide');
    }

    function handleDownload() {

        let input = document.getElementById("view_result_input")
        let newLink = input.value;
        let pageLink = '';
        switch (newLink) {
            case "ALL":
                pageLink = 'contacts/clean-list?page=imports&open=new';
                break;
            case "CARRIER":
                pageLink = 'contacts/clean-list?tab=verify&page=imports&open=new';
                break;
            case "DNC":
                pageLink = 'contacts/clean-list?tab=dnc&page=imports&open=new';
                break;
            case "SKIP_TRACE":
                pageLink = 'contacts/clean-list?tab=skiptracing&page=imports&open=new';
                break;
            default:
                pageLink = 'contacts/clean-list';
        }
        window.location.href = window.location.origin + '/' + pageLink;

    };

    let element_exist = document.getElementById("pypepro_notification_view_result_wrp_id");
    if (element_exist) {
        if (element_exist.getAttribute('interval')) {
            clearInterval(element_exist.getAttribute('interval'))
            element_exist.setAttribute('interval', null)
        }
        if (!element_exist.classList.contains(classText)) {
            element_exist.classList.remove('info')
            element_exist.classList.remove('error')
            element_exist.classList.remove('success')
            element_exist.classList.remove('warning')
            element_exist.classList.remove('default')
            element_exist.classList.add(classText)
        }

        element_exist.classList.remove('pypepro_view_result_nt_hide')
        if (element_exist.classList.contains('pypepro_nt_replace')) {
            element_exist.classList.remove('pypepro_nt_replace')
        }
        else {
            element_exist.classList.add('pypepro_nt_replace')
        }
        setTimeout(() => {
            try {
                /* icon */
                let icon_span = element_exist.querySelector('span[id=pypepro_nt_li_view_result_wrp_id]');
                if (icon_span) {
                    icon_span.innerHTML = leftIcon
                }
                /* v2 */
                let message_span_v2 = element_exist.querySelector('input[id=view_result_input]');
                if (message_span_v2) {
                    message_span_v2.value = link
                }
                /* text */
                let message_span = element_exist.querySelector('span[id=pypepro_nt_info_view_result_wrp_id]');
                if (message_span) {
                    message_span.innerHTML = `<b>${buttonText}</b> ${message}`
                }
                /* dismiss */
                let dismiss_span = element_exist.querySelector('span[id=pypepro_nt_close_view_result_wrp_id]');
                if (dismiss_span) {
                    dismiss_span.innerText = closeText
                }
                new_interval = setTimeout(() => {
                    try {
                        element_exist.classList.add('pypepro_view_result_nt_hide');
                    }
                    catch (error) { }
                }, timer);
                element_exist.setAttribute('interval', new_interval)
            }
            catch (error) { }
        }, 500);
    }
    else {
        const elm = `
      <div class='customToasterContent show'>
          <div class="customToasterMessage">${message}</div>
          <div class="customToasterActions">
            <input value="${link}" hidden id="view_result_input">
            <button id="custom_view_result" class="customToasterDownload" >${buttonText}</button>
            <button id="pypepro_nt_close_view_result_wrp_id" class="customToasterCancel" >CLOSE</button>
          </div>
    </div>
    `
        container = document.createElement('div')
        container.className = "pypepro_notification_template"
        container.className = classText
        container.id = "pypepro_notification_view_result_wrp_id"
        container.innerHTML = elm
        document.body.appendChild(container)
        // document.getElementById("download_link_input").value = link;

        setTimeout(() => {
            container.classList.add('animate')
            // document.getElementById("download_link_input").value = link;
        }, 0);

        if (document.getElementById("pypepro_nt_close_view_result_wrp_id")) {
            document.getElementById("pypepro_nt_close_view_result_wrp_id").addEventListener('click', handleOnCloseViewResultNotification)
        }

        if (document.getElementById("custom_view_result")) {
            document.getElementById("custom_view_result").addEventListener('click', handleDownload)
        }

        new_interval = setTimeout(() => {
            try {
                if (document.getElementById("pypepro_nt_close_view_result_wrp_id")) {
                    document.getElementById("pypepro_nt_close_view_result_wrp_id").removeEventListener('click', handleOnCloseViewResultNotification)
                }
                // container.classList.add('pypepro_view_result_nt_hide');
                let element_exist = document.getElementById("pypepro_notification_view_result_wrp_id");
                if (element_exist) {
                    element_exist.remove();
                }

            }
            catch (error) { }
        }, timer);
        container.setAttribute('interval', new_interval)
    }
}



export const NotificationHtmlTemplate = ({ leftIcon = '', text = 'Your file is ready to download!', des = 'description', closeText = 'default', onClose = () => { }, timer = 3000, classText = 'info' }) => {
    let new_interval = '', container = '';
    function handleOnCloseNotification() {
        clearInterval(new_interval)
        new_interval = ''
        container.classList.add('pypepro_nt_hide');
        onClose()
    }
    let element_exist = document.getElementById("pypepro_notification_wrp_id");
    if (element_exist) {
        if (element_exist.getAttribute('interval')) {
            clearInterval(element_exist.getAttribute('interval'))
            element_exist.setAttribute('interval', null)
        }
        if (!element_exist.classList.contains(classText)) {
            element_exist.classList.remove('info')
            element_exist.classList.remove('error')
            element_exist.classList.remove('success')
            element_exist.classList.remove('warning')
            element_exist.classList.remove('default')
            element_exist.classList.add(classText)
        }
        element_exist.classList.remove('pypepro_nt_hide')
        if (element_exist.classList.contains('pypepro_nt_replace')) {
            element_exist.classList.remove('pypepro_nt_replace')
        }
        else {
            element_exist.classList.add('pypepro_nt_replace')
        }
        setTimeout(() => {
            try {
                /* icon */
                let icon_span = element_exist.querySelector('span[id=pypepro_nt_li_wrp_id]');
                if (icon_span) {
                    icon_span.innerHTML = leftIcon
                }
                /* text */
                let message_span = element_exist.querySelector('span[id=pypepro_nt_info_wrp_id]');
                if (message_span) {
                    message_span.innerHTML = `<b>${text}</b> ${des}`
                }
                /* dismiss */
                let dismiss_span = element_exist.querySelector('span[id=pypepro_nt_close_wrp_id]');
                if (dismiss_span) {
                    dismiss_span.innerText = closeText
                }
                new_interval = setTimeout(() => {
                    try {
                        element_exist.classList.add('pypepro_nt_hide');
                    }
                    catch (error) { }
                }, timer);
                element_exist.setAttribute('interval', new_interval)
            }
            catch (error) { }
        }, 500);
    }
    else {
        const elm = `
      <div class="pypepro_nt_left_wrp" id="pypepro_nt_left_wrp_id">
        <span class="pypepro_nt_li_wrp" id="pypepro_nt_li_wrp_id">${leftIcon}</span>
        <span class="pypepro_nt_info_wrp" id="pypepro_nt_info_wrp_id"><b>${text}</b> ${des}</span>
      </div>
      <span class="pypepro_nt_close_wrp" id="pypepro_nt_close_wrp_id">${closeText}</span>
    `
        container = document.createElement('div')
        container.className = "pypepro_notification_template"
        container.className = classText
        container.id = "pypepro_notification_wrp_id"
        container.innerHTML = elm
        document.body.appendChild(container)

        setTimeout(() => {
            container.classList.add('animate')
        }, 0);

        if (document.getElementById("pypepro_nt_close_wrp_id")) {
            document.getElementById("pypepro_nt_close_wrp_id").addEventListener('click', handleOnCloseNotification)
        }

        new_interval = setTimeout(() => {
            try {
                if (document.getElementById("pypepro_nt_close_wrp_id")) {
                    document.getElementById("pypepro_nt_close_wrp_id").removeEventListener('click', handleOnCloseNotification)
                }
                // container.classList.add('pypepro_nt_hide');
                let element_exist = document.getElementById("pypepro_notification_wrp_id");
                if (element_exist) {
                    element_exist.remove();
                }

            }
            catch (error) { }
        }, timer);
        container.setAttribute('interval', new_interval)
    }
}

const isValidURL = (url) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(url);
};

const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const isValidMobileNumber = (mobileNumber) => {

    if (mobileNumber !== '' && mobileNumber.length < 10) {
        return false;
    } else if (mobileNumber !== '' && mobileNumber.length > 11) {
        return false;
    } else if (mobileNumber.length === 11 && mobileNumber.substring(0, 1) !== '1') {
        return false;
    }
    return true;
}

export const getState = (value) => US_STATE_LIST.find((state) => state.value === value)?.label;

const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {

    if (mobileNumber !== '' && mobileNumber.length < 10) {
        return false;
    } else if (mobileNumber !== '' && mobileNumber.length > 11) {
        return false
    } else if (mobileNumber.length === 11 && mobileNumber.substring(0, 1) !== '1') {
        return false;
    }
    return true
}

export const cleanNumber = (number) => {
    try {
        if (isNaN(number)) {
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split(".");
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, "") + ".00";
        }
        const decimalPart = parts[1].length < 2 ? parts[1] + "0" : parts[1];
        return `${parts[0]}.${decimalPart}`;
    } catch (err) {
        return "x.xx";
    }
}

export const toRegular = (text) => {
    try {
        const modText = text.replace(/([A-Z])/g, " $1");
        return modText.charAt(0).toUpperCase() + modText.slice(1);
    } catch (err) {
        return text;
    }
}

export const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
}

export const toHuman = (text) => {
    if (typeof text === "boolean") {
        return text === true ? "Yes" : "No";
    }

    return text;
}

export const isDeleteAble = (object, which) => {
    if (which === "email") {
        return !!object["number"];
    }

    if (which === "number") {
        return !!object["email"];
    }
};

export const toLowerCase = (value) => {
    try {
        return value.toLowerCase();
    } catch (e) {
        return value;
    }
}

export const setNumberType = (value) => {
    try {
        let list = [PHONE_STATE.mobile, PHONE_STATE.landline, PHONE_STATE.voip, PHONE_STATE.unknown];

        if (value && typeof value === "string") {
            value = value.toLowerCase();
        }

        if (value && list.includes(value)) {
            return value
        }

        return PHONE_STATE.unknown;
    } catch (er) {
        return PHONE_STATE.unknown;
    }
}

export function formatPhoneNumber(phoneNumberString) {
    if (!phoneNumberString) {
        return 'N/A';
    }

    if (phoneNumberString.length < 11) {
        phoneNumberString = '1' + phoneNumberString;
    }
    return phoneNumberString.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
}

const validURL = (string) => {
    try {
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return regexp.test(string);
    } catch (err) {
        return false;
    }
}

const calculateCash = (amount, fixedCount = 2) => {
    var flag = ''
    // 1000 = 1K
    // 1000000 = 1M = 1K * 1000
    // 1M * 1000 = 1B
    // 1B * 1000 = 1T
    if (amount >= 1000000000000000000000000) {
        amount = amount / 1000000000000000000000000
        flag = 'Y'
    }
    if (amount >= 1000000000000000000000) {
        amount = amount / 1000000000000000000000
        flag = 'Z'
    }
    if (amount >= 1000000000000000000) {
        amount = amount / 1000000000000000000
        flag = 'E'
    }
    if (amount >= 1000000000000000) {
        amount = amount / 1000000000000000
        flag = 'P'
    }
    if (amount >= 1000000000000) {
        amount = amount / 1000000000000
        flag = 'T'
    }
    else if (amount >= 1000000000) {
        amount = amount / 1000000000
        flag = 'B'
    }
    else if (amount >= 1000000) {
        amount = amount / 1000000
        flag = 'M'
    }
    else if (amount >= 1000) {
        amount = amount / 1000
        flag = 'K'
    }
    // return '$' + (Math.round(totalDeal * 100) / 100) + flag
    if (amount != null) {
        return '$' + amount.toFixed(fixedCount) + flag
    }
    return '$' + amount + flag
}

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    validateEmail,
    validateMobileNumber,
    generateTimezoneList,
    showNotification,
    formatNumber,
    loadSocket,
    getAccountData,
    getShorterText,
    loadCodeSnippets,
    referralTrackingScript,
    getIconTextFromContact,
    isEmpty,
    getContactName,
    reduceTextLength,
    setAgencyTitleAndFav,
    checkLetterExistInText,
    checkNumberExistInText,
    isNaturalNumber,
    create_UUID,
    FileDownloadNotificationHtmlTemplate,
    NotificationHtmlTemplate,
    isValidURL,
    isValidEmail,
    isValidMobileNumber,
    validURL,
    calculateCash
}

export default Utils;