import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Styles from "./PreviewSampleMessage.module.css";
import { Box, Button, Paper, Typography, withStyles } from "@material-ui/core";

const SampleMessage = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
        width:"100%",
        fontSize: "14px !important",
        height: "auto !important",
        minHeight:"100px !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        lineBreak:"anywhere"
    },
}))(Box);

const App = (props) => {
  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        props.setIsPreviewed(true);
        
        return (
            <Paper className={Styles.modalInner}>
            <div className={Styles.headerWrapper}>
              <Typography variant="h5">This is what your sample messages will look like when we combine the <br/> Sender Identification + Your Message + the Opt-Out Language.</Typography>
            </div>
            <div className={Styles.paddingWrp}>
              <div className={Styles.modalBody}>
                <p>Sample Message #1</p>
                <SampleMessage>
                    {props.formData.senderIdentification1}
                    <br/>
                    {props.formData.sampleMessage1}
                    <br/>
                    {props.formData.optOutLanguage1}
                </SampleMessage>
                <br></br>
                <p>Sample Message #2</p>
                <SampleMessage>
                    {props.formData.senderIdentification2}
                    <br/>
                    {props.formData.sampleMessage2}
                    <br/>
                    {props.formData.optOutLanguage2}
                </SampleMessage> 
              </div>
              <div className={Styles.modalFooter}>
                <Button variant="contained" color="secondary" onClick={onClose} size="large">Exit</Button>
              </div>
            </div>
          </Paper>
        );
      },
      closeOnClickOutside: props.closeOnClickOutside,
      overlayClassName: ''
    })
  )
}
export default App;