import { useEffect, useState } from "react";
import Utils from "../../../helpers/Utils";
import useDelayCallback from "../../../hooks/useDelayCallback";
import AccessDenied from "../AccessDenied";
import { getContactDetailsApi } from "../api/ApiAction";
import ReadyToSend from "../components/ReadyToSend/ReadyToSend";
import SelectDesign from "../components/SelectDesign/SelectDesign";
import TypeYourMessage from "../components/TypeYourMessage/TypeYourMessage";
import { SendProcess } from "../SendProcess";
import moment from "moment";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { DIRECT_MAIL_FOR_CAMPAIGN_SETTING } from "../Index";

const LETTER = 9;

const LetterContent = ({
    current_step,
    onChangeStep,
    contactId,
    handleBack,
    priceInfo,
    processFor,
    campaignData,
    handleChangeStates,
    lastCompletedStep
}) => {
    const isMounted = useIsMounted();
    const [contactInfo, setContactInfo] = useState(null)
    const [loadingContactDetails, setLoadingContactDetails] = useState(true);

    const [selectDesignStep, setSelectDesignStep] = useState({
        isLoadingCategory: false,
        isLoadingTemplate: false,
        templateFrom: 'agency', //user
        categories: [],
        templates: [],
        page: 1,
        hasMoreTemplate: false,
        loadingMoreTemplate: false,
        selectedCategory: null,
        selectedTemplate: null,
        saveAsTemplate: {
            isTrue: true,
            title: '',
            category: null,
            new_category: false,
            new_category_title: ''
        },
        isUploading: false,
        from: 'template', //canva, upload
        uploadUrl: "",
        uploadThumbUrl: "",
        uploadData: null,
        uploadingFile: false,
        reloadCategory: false
    })
    const [typeYourMessageSteps, setTypeYourMessageSteps] = useState({
        message: '',
        handWritingStyle: null,
        pdfUrl: "",
        isProcessingPdf: false,
        previewImage: null,
        processingPreviewImage: false,
        isShowingPdf: false
    });
    const [readyToSend, setReadyToSend] = useState({
        send_type: 0,
        hours: null,
        minutes: null,
        day: '',
        at_time: null,
        date: new Date(),
        greeting: null,
        isSending: false,
        errorMsg: ''
    });

    useEffect(() => {
        // getContactInfo();
        restoreFromLocalStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useDelayCallback(() => {
        return
        if(isMounted){
            /* build a mechanism to store data in local storage */
            localStorage.setItem('pype_postcard_store_data', JSON.stringify({
                'selectDesignStep': selectDesignStep,
                'typeYourMessageSteps': typeYourMessageSteps,
                'readyToSend': readyToSend,
                'step_info': {
                    'current_step': current_step,
                    'lastCompletedStep': lastCompletedStep
                },
                'time': new Date(),
                'type': 'letter'
            }))
        }
    },[selectDesignStep, typeYourMessageSteps, readyToSend], 2000)

    const restoreFromLocalStorage = () => {
        if(processFor === DIRECT_MAIL_FOR_CAMPAIGN_SETTING){
            if(campaignData.edit){
                restoreDataForEditCampaign(campaignData.editData)
                return;
            }
        }
        if(localStorage.getItem("pype_postcard_store_data")){
            let data = localStorage.getItem("pype_postcard_store_data")
            try{
                data = JSON.parse(data)
                var now = moment(new Date()); //todays date
                var end = moment(data.time); // another date
                var duration = moment.duration(now.diff(end));
                var hours = duration.asHours();
                if(hours < 1){
                    if(data.type === 'letter'){
                        setSelectDesignStep({...selectDesignStep, ...data.selectDesignStep})
                        setTypeYourMessageSteps({...typeYourMessageSteps, ...data.typeYourMessageSteps})
                        let send_data = {...data.readyToSend, isSending: false}
                        setReadyToSend({...readyToSend, ...send_data})
                        handleChangeStates({
                            current_step: data.step_info.current_step, 
                            lastCompletedStep: data.step_info.lastCompletedStep
                        }) 
                    }

                }
                getContactInfo()
            }
            catch(error){
                getContactInfo()
            }
        }
        else{
            getContactInfo()
        }
    }

    const restoreDataForEditCampaign = (data) => {
        if(data){
            /* TODO: need to work for card_owner after migration */
            let template_category_id = '',
                template_id = '',
                message = '',
                handwriting_style_id = '',
                image_preview = null,
                time_setting = {
                    send_type: 0,
                    hours: null,
                    minutes: null,
                    day: '',
                    at_time: null,
                    date: new Date(),
                    greeting: null,
                },
                templateFrom = 'agency';

            template_category_id = parseInt(data.template_category_id);
            template_id = parseInt(data.template_id);
            if(data.campaign_template !== undefined && data.campaign_template != null){
                if(data.campaign_template.user_id != null){
                    templateFrom = 'user'
                }
            }
            message = data.message;
            handwriting_style_id = parseInt(data.handwriting_style_id);
            if(data.gift_image_url){
                image_preview = data.gift_image_url
            }
            let send_type = parseInt(data.type)
            if(send_type === 1){
                time_setting['send_type'] = 0;
            }
            else if(send_type === 2){
                if(data.day === 1){
                    let splite_time = data.hour.split(":", 2);                   
                    time_setting['send_type'] = 1;
                    time_setting['day'] = 1;
                    time_setting['hours'] = parseInt(splite_time[0]);
                    time_setting['minutes'] = parseInt(splite_time[1]);
                }
                else{
                    time_setting['send_type'] = 2;
                    time_setting['day'] = data.day;
                    time_setting['at_time'] = data.hour;
                }
            }
            else if(send_type === 3){
                time_setting['send_type'] = 3;
                time_setting['date'] = (window.globalTimezoneConversionToDifferentTimezone(data.fixed_date));
                time_setting['at_time'] = data.hour;
            }
            else if(send_type === 4){
                time_setting['send_type'] = 4;
                time_setting['greeting'] = data.greeting_option;
                time_setting['at_time'] = data.hour;
            }
            else if(send_type === 5){
                time_setting['send_type'] = 4;
                time_setting['day'] = data.day;
                time_setting['at_time'] = data.hour;
            }
            /* TODO: need to update in states data */
            setSelectDesignStep({
                ...selectDesignStep, 
                ...{
                    selectedCategory: template_category_id,
                    template_id_for_edit: template_id,
                    templateFrom: templateFrom
                }
            })
            setTypeYourMessageSteps({
                ...typeYourMessageSteps, 
                ...{
                    message: message,
                    handWritingStyle: handwriting_style_id,
                    previewImage: image_preview
                }
            })
            setReadyToSend({
                ...readyToSend, 
                ...time_setting
            })
            getContactInfo()
        }
        else{
            getContactInfo() 
        }
    }

    const getContactInfo = () => {
        if(processFor === 'campaign-setting'){
            setLoadingContactDetails(false)
            return 
        }
        getContactDetailsApi({contact_id: contactId})
        .then(res => {
            let response = res.data;
            if(response.status === "success"){
                setContactInfo(response.data)
            }
            setLoadingContactDetails(false)
        })
        .catch(error => {
            setLoadingContactDetails(false)
            console.log(error)
        })
    }
    
    /* handle states methods */
    const handleDesignStepDataChange = (payload) => {
        setSelectDesignStep({...selectDesignStep, ...payload})
    }
    const handleTypeYourMessageStepChange = (payload) => {
        setTypeYourMessageSteps({...typeYourMessageSteps, ...payload})
    }
    const handleReadyToSendChange = (payload, day = false) => {
        if(day){
            if(payload.day === ""){
                setReadyToSend({...readyToSend, ...payload})
            }
            else{
                if(Utils.isNaturalNumber(payload.day)){
                    let parseIntValue = payload.day
                    if(parseIntValue <= 1000){
                        setReadyToSend({...readyToSend, ...payload})
                    }
                }
            }
        }else{
            setReadyToSend({...readyToSend, ...payload})
        }
    }

    /* other handle methods */
    const handleStepChange = (forward = true) => {
        // onChangeStep(value)
        if(current_step === 0 && !forward){
            handleBack()
            return
        }
        let step_no = current_step;
        if(forward){
            step_no = parseInt(step_no + 1)
        }
        else{
            step_no = parseInt(step_no - 1)
        }
        onChangeStep(step_no)
    }
    const handleSend = () => {
        SendProcess({
            contactInfo: contactInfo,
            selectDesignStep: selectDesignStep,
            typeYourMessageSteps: typeYourMessageSteps,
            readyToSend: readyToSend,
            campaignData: campaignData,
            processFor: processFor,
            handleBack: handleBack,
            handleReadyToSendChange: handleReadyToSendChange,
            card_type: 'letter',
            price_value: priceInfo['9'],
            contactId: contactId
        });
    }

    /* render methods */
    const renderStep = () => {
        if(renderAccessDenied()){
            return <AccessDenied text={loadingContactDetails? "Please wait...": "No contact information found !"} />;
        }
        if(current_step === 0){
            return(
                <SelectDesign 
                    selectDesignStep={selectDesignStep} 
                    handleDataChange={handleDesignStepDataChange} 
                    handleStepChange={handleStepChange}
                    type={'letter'}
                />
            );
        }
        else if(current_step === 1){
            return(
                <TypeYourMessage 
                    typeYourMessageSteps={typeYourMessageSteps} 
                    handleStepChange={handleStepChange} 
                    handleDataChange={handleTypeYourMessageStepChange} 
                    image_url={typeYourMessageSteps.previewImage}
                    selectedTemplate={selectDesignStep.selectedTemplate}
                    type={'letter'}
                    generatePdf={false}
                />
            );
        }
        else if(current_step === 2){
            return(
                <ReadyToSend 
                    readyToSend={readyToSend} 
                    handleReadyToSendChange={handleReadyToSendChange} 
                    handleStepChange={handleStepChange}
                    handleSend={handleSend}
                    contactInfo={contactInfo}
                    type={'letter'}
                    processFor={processFor}
                />
            );
        }
        return <AccessDenied />
    }
    const renderAccessDenied = () => {
        if(processFor === 'campaign-setting'){
            if(loadingContactDetails){
                return true;
            }
            return false;
        }
        if(contactInfo == null){
            return true;
        }
        else if(contactInfo.address === "" || contactInfo.statte === ""){
            return true;
        }
        return false;
    }
    return (
        <div className={`dmpc__steper__content`}>
            {renderStep()}
        </div>
    );
}
export default LetterContent;