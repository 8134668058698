import React, { useState , useEffect } from 'react';
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from '../../components/Accordion/CustomAccordion';
import { icons } from "../../icons";
import { Box, Typography } from '@material-ui/core';
import SmartFormEntry from "./SmartFormEntry";
import OldFormEntry from "./OldFormEntry";
const FormEntry = (props) => {
    const [expand, setExpand] = useState(false);
    let countEntries = props.entries.length + props.smartFormEntries.length;

    const populateEntries = (entries) => {
        if (entries.length > 0) {
            return entries.map((entry, index) => (
                <OldFormEntry entry={entry} />
            ))
        }
    }

    const populateSmartEntries = (entries) => {

        if (Array.isArray(entries) && entries.length > 0) {
            return entries.map((entry, index) => (
                <SmartFormEntry entry={entry} />
            ))
        }
    }

    return (
        <div className=" mt-2">
        <CustomAccordion  expand={expand} className="g-contact-details-collapsible">
            <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                <span className="g-header-title"><span className="material-icons" style={{marginTop:'-2px'}}> 
                <svg width="23" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 4.86748V19.2829C16 20.3685 15.1046 21.2486 14 21.2486H2C0.895417 21.2486 0 20.3685 0 19.2829V4.86748C0 3.78182 0.895417 2.90174 2 2.90174H5.33333C5.33333 1.45652 6.52958 0.280762 8 0.280762C9.47042 0.280762 10.6667 1.45652 10.6667 2.90174H14C15.1046 2.90174 16 3.78182 16 4.86748ZM8 1.91887C7.44771 1.91887 7 2.35891 7 2.90174C7 3.44457 7.44771 3.88461 8 3.88461C8.55229 3.88461 9 3.44457 9 2.90174C9 2.35891 8.55229 1.91887 8 1.91887ZM12 6.58749V5.76844C12 5.70327 11.9737 5.64077 11.9268 5.59469C11.8799 5.54861 11.8163 5.52272 11.75 5.52272H4.25C4.1837 5.52272 4.12011 5.54861 4.07322 5.59469C4.02634 5.64077 4 5.70327 4 5.76844V6.58749C4 6.65266 4.02634 6.71516 4.07322 6.76124C4.12011 6.80732 4.1837 6.83321 4.25 6.83321H11.75C11.8163 6.83321 11.8799 6.80732 11.9268 6.76124C11.9737 6.71516 12 6.65266 12 6.58749Z" fill="black"/>
                                        </svg>
                      </span> Form Entries    <span className="g-header-count">{countEntries}</span></span>
              
                <span className="g-custom-caret">{icons.caretIcon}</span>
            </CustomAccordionHeader>
            <CustomAccordionBody className="g-collapsible-body" expand={expand}>
             <Box padding={'8px'}>
             <Typography>
                 {
                     props.entries.length == 0 && props.smartFormEntries.length == 0 ? (
                         <div className="m-widget2__item complete__form">
                             <div className="m-widget2__desc">
                                 <h6 className="form__builder-title">{"No data available"}</h6>
                             </div>
                         </div>
                     ) : (
                         <>
                             {populateSmartEntries(props.smartFormEntries)}
                             {populateEntries(props.entries)}
                         </>
                     )
                 }
             </Typography>
             </Box>
            </CustomAccordionBody>
        </CustomAccordion>
    </div> 
    );
};

export default FormEntry;