import Styles from './style.module.css'

const ChatConversation = ({loading, data, onLoadMore}) => {
  const renderList = () => {
    const view = [];
    data.forEach((item ,index) => {
      let style_type = Styles.single;
      if(item.styleType === 'start'){
        style_type = Styles.startIndex
      }
      else if(item.styleType === 'end'){
        style_type = Styles.endIndex
      }
      else if(item.styleType === 'wrap'){
        style_type = Styles.wrapItem
      }
      view.push(
        <div className={item.in_out === 1 ? Styles.outgoingWrp : Styles.incomingWrp} key={index}>
          <span className={`${Styles.message} ${style_type}`}>{item.message}</span>
        </div>
      );
    })
    return view;
  }
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.conversationWrp}>
        {renderList()}
      </div>

      {data.length > 0 &&
      <div className={Styles.footerWrp}>
        <div className={Styles.loadMore} onClick={onLoadMore}>{loading ? 'Loading more' : 'Load more'}</div>
      </div>
      }
    </div>
  );
}
export default ChatConversation;
const styles = {
  startIndex: {
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
    marginTop: '8px'
  },
  endIndex: {
    borderBottomLeftRadius: '14px',
    borderBottomRightRadius: '14px',
  },
  single: {
    borderRadius: '14px',
    marginTop: '8px',
    marginBottom: '8px'
  },
  wrapItem: {
    borderRadius: '0px'
  }
}