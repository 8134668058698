const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const profileModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`


const Api = {
    details: `${contactUrl}/details`,
    changeLeadOwner: `${contactUrl}/change/lead-owner`,
    getAllTeamUsers: `${contactUrl}/get-all-team-users`,
    getSearchContacts: `${contactUrl}/search-contacts`,
    getCollaborators: `${contactUrl}/get-collaborators`,
    deleteCollaborator: `${contactUrl}/delete-collaborators`,
    saveCollaborator: `${contactUrl}/save-collaborator`,
    getUserGroup: `${contactUrl}/get-user-group`,
    removeFile: `${contactUrl}/file/remove`,
    uploadFile: `${contactUrl}/upload/file`,
    getTimelineLogs: `${contactUrl}/get/timeline-log`,
    emailContactToFriend: `${contactUrl}/email-contact-to-friend`,
    getGoogleMapResource: `${contactUrl}/get-google-map-resource`,
    editContactData: `${contactUrl}/edit-contact-data`,

    changeDealStatus: `${contactUrl}/status-change-deals`,

    addNewDeal: `${contactUrl}/add-new-deal`,
    updateDeal: `${contactUrl}/update-contact-deal`,
    stageList: `${contactUrl}/stage-list`,
    stageListV2: `${contactUrl}/stage-list-v2`,
    campaignList: `${contactUrl}/campaign/list/contact`,
    runCampaign: `${contactUrl}/campaign/single-contact/run`,
    pauseCampaign: `${contactUrl}/campaign/single-contact/pause`,
    unsubscribeCampaign: `${contactUrl}/campaign/single-contact/unsubscribe`,
    moveToCampaign: `${contactUrl}/campaign/contact/move`,
    updateSingleCustomField: `${contactUrl}/single/custom-field/update`,
    addTags: `${contactUrl}/add-tags`,
    tagList: `${contactUrl}/tag/list-optimized`,
    smsTemplate: `${contactUrl}/get/sms/template`,
    emailTemplate: `${contactUrl}/get/email/template`,
    getTimeline: `${contactUrl}/get/timeline`,
    updateTimelineNote: `${contactUrl}/update-timeline-note`,
    voiceTemplate: `${contactUrl}/get/voice/template`,
    userProfile: `${contactUrl}/get/user-profile`,
    sendMail: `${contactUrl}/send-mail`,
    userEmailList: `${contactUrl}/user-email-list`,
    sendMessage: `${contactUrl}/send-message`,
    getCustomFields: `${contactUrl}/get-custom-fields`,
    getRunningCampaignListNotInCurrentCampaign: `${contactUrl}/campaign/not-in-current-contact/list`,
    changeContactSource: `${contactUrl}/contact-source-type/change/source`,
    sourceListDropdown: `${contactUrl}/contact-source-type/list/dropdown`,
    updateContactInfo: `${contactUrl}/update-contact-info`,
    updateAditionalEmailPhone: `${contactUrl}/update-aditional-email-phone`,
    getContactTagList: `${contactUrl}/get-contact-tag-list`,
    deleteContactTag: `${contactUrl}/delete-contact-tag`,
    getMailCategory: `${contactUrl}/get-card-basic-setting`,
    getMailTemplate: `${contactUrl}/get-card-templates`,
    createMailTemplate: `${contactUrl}/card-template-create`,
    getUserVirtualNumberList: `${contactUrl}/virtual-number-list`,
    addNote: `${contactUrl}/add-note-submit`,
    producePDF: `${contactUrl}/generate-pdf`,
    addCardBroadcastToContact: `${contactUrl}/add-card-broadcast-to-contact`,
    getThirdPartyInfo: `${contactUrl}/get-third-party-info`,
    getContactTimeline: `${contactUrl}/contact-conversation`,

    getActivityList:`${contactUrl}/activity-list`,
    contactMarkAsDone:`${contactUrl}/activity-mark-as-done`,
    saveActivity:`${contactUrl}/save-activity`,

    //Get All Contacts
    getAllContacts: `${contactUrl}/contact-datatable`,
    getCampaignListForDropdown: `${contactUrl}/campaign-list-dropdown`,
    sourceListDropdownForContactTable: `${contactUrl}/source-list-dropdown`,
    subUserListForContactDropdown: `${contactUrl}/sub-users-optimized`,
    fileListForDropdown: `${contactUrl}/file-list-dropdown`,

    //remove contact
    removeBulkContact: `${contactUrl}/bulk-contact-delete`,

    //List
    getAllList: `${contactUrl}/contact-list-dropdown`,

    //single contact
    addContactInCampaign: `${contactUrl}/contact/move/campaign`,
    
    //multiple contacts
    addContactsInCampaign: `${contactUrl}/add-contacts-to-campaign`,

    //GetUserInfo
    getUserInfo: `${contactUrl}/get-user-data`,

    //Get Custom Fields
    // getCustomFields: `${marketingModuleUrl}/get-custom-fields`,

    //Import contact routes
    getContactLimit: `${contactUrl}/get-limit`,
    getFileLists: `${contactUrl}/get-file-lists`,
    deleteImportFiles: `${contactUrl}/delete-import-files`,
    contactImport: `${contactUrl}/contact-import`,
    fileAssignToCampaign: `${contactUrl}/campaign-file-assign`,
    allCampaignWithVn: `${contactUrl}/campaign-all-with-vn`,
    getInvalidContacts: `${contactUrl}/get-invalid-contacts`,

    //Assign Bulk Stage
    assignBulkStageForContacts:`${contactUrl}/assign-bulk-stage`,

    //Export csv from contact list
    exportCSVFromContactlist : `${contactUrl}/export-csv-from-contactlist`,
    checkUnsubscried: `${contactUrl}/check/contact/unsubscribe`,

    // get collaborator list
    getCollaboratorListDropdown: `${profileModuleUrl}/get-team-users-without-template`,


}

export default Api;