import { all, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from '../constants/loginActionTypes'
import {loginRequest, loginOtpRequest} from "../api/loginApi";

function* loginWatcher() {
    yield takeEvery(ACTION.LOGIN_ATTEMPT, attemptLoginFlow)
    yield takeEvery(ACTION.OTP_CHECK, attemptLoginOTPFlow)
}

function* attemptLoginFlow(action) {
        // setCookie(REACT_APP_ACCESS_TOKEN, 'W6GSG6PQ6K5ZCQ1UQT5E', REACT_APP_ACCESS_TOKEN_VALIDITY);
        // yield put({type: ACTION.LOGIN_SUCCESS, payload: {'id': 1, 'name': 'John Doe'}});
    const {payload} = action;
    try {
        const response = yield call(loginRequest, payload.credentials);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        console.log(error);
    }

}

function* attemptLoginOTPFlow(action) {

    const {payload} = action;
    try {
        const response = yield call(loginOtpRequest, payload.credentials);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        console.log(error);
    }

}

export default function* loginMiddleware() {
    yield all([
        loginWatcher()
    ])
}