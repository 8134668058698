import { useEffect, useState } from "react";

const CustomAccordion = (props) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(props.expand)
    }, [props.expand])

    const getChilds = () => {
        if(Array.isArray(props.children)) {
            return props.children.map(elem => {
                if(elem !== undefined && elem.type.name === 'CustomAccordionBody') {
                    return <div onClick={e => e.stopPropagation()} className={elem.props.className}
                        style={{display:(visible?"block":"none")}}>
                            {elem.props.children}
                        </div>
                }
                return elem;
            })
        }
        return props.children;
    }

    return ( 
        <ul className={`${props.className} ${ visible ? (props.activeClass !== undefined ? props.activeClass : "g-collapsible-active") : ""}`}>
            <li>
                { getChilds() }
            </li>
        </ul> 
    );
}
export default CustomAccordion;

export const CustomAccordionHeader = (props) => {
    const handleVisibility = () => {
        if(typeof props.onClick != 'undefined') props.onClick();
    }
    
    return ( 
        <div className={props.className} onClick={handleVisibility}>{props.children}</div>
    );
}

export const CustomAccordionBody = (props) => {
    return ( 
        <div className={props.className} style={!props.expand ? {display: 'none'} : {display: 'block'}}>{props.children}</div>
    );
}