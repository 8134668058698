import {useContext, useEffect, useState} from 'react';
import { VoiceSendContext } from "../reducers/GlobalReduers";
import SaveIcon from '@material-ui/icons/Save';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { updateFileData, updateFileTypeFlag, updateIsShowQuickReplyFlag, updateTemplateData } from '../reducers/Action';

var GLOBAL_VOICE_SEND_INTERVAL = '';
var GLOBAL_VOICE_SEND_RECORD_COUNT = 0;
var GLOBAL_VOICE_SEND_RECORD_AUDIO;
var GLOBAL_VOICE_SEND_AUDIO_STREAM;

const VoiceFile = props => {
    const {states, dispatch} = useContext(VoiceSendContext)
    const [audioRecordStates, setAudioRecordStates] = useState({
        showRecordPlayer: false,
        showRecordedPlayer: false,
        showSaveButton: false,
        showStopButton: false,
        showStartButton: true,
        showRefreshButton: false,
        startCount: false,
        videoUrl: '',
        minutes: '00',
        seconds: '00',
        showRecordingaudio:false,
        uploadfile:false,
        startRecording: false
    })

    useEffect(() => {
        if(GLOBAL_VOICE_SEND_AUDIO_STREAM){
            GLOBAL_VOICE_SEND_AUDIO_STREAM.getTracks().forEach(track => track.stop());
        }
        return () => {
            if(GLOBAL_VOICE_SEND_AUDIO_STREAM){
                GLOBAL_VOICE_SEND_AUDIO_STREAM.getTracks().forEach(track => track.stop());
            }
            GLOBAL_VOICE_SEND_RECORD_COUNT = 0;
            if(GLOBAL_VOICE_SEND_INTERVAL){
                clearInterval(GLOBAL_VOICE_SEND_INTERVAL)
            }
        }
    }, [])

    const pad = (val) => {
        var valString = val + "";
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    const calculateTime = () => {
        GLOBAL_VOICE_SEND_INTERVAL = setInterval(() => {
            ++GLOBAL_VOICE_SEND_RECORD_COUNT;
            setAudioRecordStates({
                ...audioRecordStates,
                startRecording: true,
                startCount: false,
                seconds: pad(GLOBAL_VOICE_SEND_RECORD_COUNT % 60),
                minutes: pad(parseInt(GLOBAL_VOICE_SEND_RECORD_COUNT / 60))
            })
            if (GLOBAL_VOICE_SEND_RECORD_COUNT >= 180) {
                stopRecord();
            }
        }, 1000);
    }

    const requestUserMedia = () => {
        GLOBAL_VOICE_SEND_AUDIO_STREAM=null;
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(function (mediaStream) {
                setAudioRecordStates({
                    ...audioRecordStates,
                    showStartButton: false,
                    showStopButton: true,
                    showRecordedPlayer: false,
                    showSaveButton: false,
                    showRefreshButton: false,
                    startCount: true,
                    minutes: '00',
                    seconds: '00',
                    videoUrl: '',
                    audioFile: [],
                    showRecordingaudio:true,
                    uploadfile:false
                })
                let audio = document.getElementById('record-audio');
                audio.srcObject=mediaStream;
                GLOBAL_VOICE_SEND_AUDIO_STREAM = mediaStream;
                audio.onloadedmetadata = function(e) {
                    audio.play();
                };
                startRecord()
            })
            .catch(function (err) {
                setAudioRecordStates({
                    ...audioRecordStates,
                    loader: 0, 
                    welcomeStatus: 2
                })
                return;
            });
    }

    const showAudio = () => {
        var sound = document.createElement('audio');
        sound.controls = 'controls';
        sound.src      = GLOBAL_VOICE_SEND_AUDIO_STREAM;
    }

    const startRecord = () => {
        GLOBAL_VOICE_SEND_RECORD_COUNT = 0;
        calculateTime();
        GLOBAL_VOICE_SEND_RECORD_AUDIO = RecordRTC(GLOBAL_VOICE_SEND_AUDIO_STREAM, {type: 'audio/webm', mimeType: 'audio/webm', recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000});
        GLOBAL_VOICE_SEND_RECORD_AUDIO.startRecording();
        dispatch(updateFileTypeFlag('RECORD'))
        dispatch(updateTemplateData({
            data: null,
            id: null
        }))
    }

    const openMic = (e) => {
        e.preventDefault();
        let hasGetUserMedia = '';
        try {
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia({audio: true}) || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            setAudioRecordStates({
                ...audioRecordStates,
                welcomeStatus: 2
            })
            return;
        }


        if (!hasGetUserMedia) {
            setAudioRecordStates({
                ...audioRecordStates,
                welcomeStatus: 2
            })
            return 0;
        }

        setAudioRecordStates({
            ...audioRecordStates,
            loader: 1
        })
        requestUserMedia();
    }

    const stopRecord = () => {
        // if(props.callback != undefined){
        //    props.callback()
        // }
        if (GLOBAL_VOICE_SEND_RECORD_COUNT < 5) {
            window.showNotification("ERROR", "Audio duration must be between 5 seconds to 3 minutes",);
            return false;
        }
        clearInterval(GLOBAL_VOICE_SEND_INTERVAL);
        GLOBAL_VOICE_SEND_RECORD_AUDIO.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(GLOBAL_VOICE_SEND_RECORD_AUDIO.blob);
            GLOBAL_VOICE_SEND_AUDIO_STREAM.getTracks().forEach(track => track.stop());

            let mimeType = 'audio/webm';
            let blob = GLOBAL_VOICE_SEND_RECORD_AUDIO instanceof Blob ? GLOBAL_VOICE_SEND_RECORD_AUDIO : GLOBAL_VOICE_SEND_RECORD_AUDIO.getBlob();
            blob = new File([blob], 'RecordRTC-audio.webm', {
                type: mimeType
            });
            setAudioRecordStates({
                ...audioRecordStates,
                videoUrl: videoUrl,
                showRecordedPlayer: true,
                showRefreshButton: true,
                showStopButton: false,
                showStartButton: true,
                showRecordingaudio:false,
                uploadfile:false,
                startRecording: false
            })
            dispatch(updateFileData(blob));
            return false;
        });
    }

    const saverecord = () => {
        // props.callBack();
    }

    const reFreshData = () => {
        setAudioRecordStates({
            ...audioRecordStates,
            showStartButton: true,
            showStopButton: false,
            showRecordedPlayer: false,
            showSaveButton: false,
            showRefreshButton: false,
            startCount: true,
            minutes: '00',
            seconds: '00',
            videoUrl: '',
            showRecordingaudio:false,
            uploadfile:false
        })
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {

    //     if(totalSeconds>=300){
    //         stopRecord();
    //     }
    // }

    const voiceUploadCallback = (e) =>{
        e.preventDefault();
        let file = document.querySelector('#voice-file-for-backend').files[0];
        if(file.size > 5242880){
            alert("File is too big!");
            return false;
        };
        dispatch(updateFileData(file));
        // if(props.callback != undefined){
        //     props.callback()
        // }
        setAudioRecordStates({
            ...audioRecordStates,
            uploadfile:true,showRecordedPlayer:false
        })
        dispatch(updateFileTypeFlag('UPLOAD'))

        if (e.target.value) {
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                let preview = document.getElementById("voice_file_preview-for-backend");
                preview.src = reader.result;
                let previewVoiceForBackend = document.getElementById('preview-voice-for-backend');

                if (previewVoiceForBackend) {
                    document.getElementById('preview-voice-for-backend').classList.remove("d-none");
                    document.getElementById('preview-voice-for-backend').classList.add("d-flex");
                }
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    }

    const uploadFrompc = () => {
        if (document.getElementById("voice-file-for-backend")){
            document.getElementById("voice-file-for-backend").click();
        }
    }

    const handleQuickReplyShow = () => {
        dispatch(updateIsShowQuickReplyFlag(true))
    }

    const handleTemplateSelect =() => {
        // template_file_id
        // template_files?.file
        // title
    }

    return(
        <div className="g-voice-send-file-content">
            <div className="g-file-action-icon">
                <div className="g-action-icon-each">
                    <div className={`g-action-icon g-start ${audioRecordStates.startRecording ? 'g-disabled' : ''}`} title="Record an audio" onClick={!audioRecordStates.startRecording ? openMic : null}><MicIcon /></div>
                    <span className="g-icon-label">Record</span>
                </div>
                <div className="g-action-icon-each">
                    <div className={`g-action-icon g-stop  ${!audioRecordStates.startRecording ? 'g-disabled' : ''}`} title="Stop recoreded audio" onClick={audioRecordStates.startRecording ? stopRecord : null}><StopIcon /></div>
                    <span className="g-icon-label">Stop</span>
                </div>
                <div className="g-action-icon-each">
                    <div className={`g-action-icon g-upload  ${audioRecordStates.startRecording ? 'g-disabled' : ''}`} title="Upload audio file" onClick={audioRecordStates.startRecording ? null : uploadFrompc}><CloudUploadIcon /></div>
                    <span className="g-icon-label">Upload</span>
                </div>
                <div className="g-action-icon-each g-margin-alt">
                    <div className={`g-action-icon g-save  ${audioRecordStates.startRecording ? 'g-disabled' : ''}`} title="Select audio from quick reply" onClick={audioRecordStates.startRecording ? null : handleQuickReplyShow}><SaveIcon /></div>
                    <span className="g-icon-label">Quick Reply</span>
                </div>
            </div>
            <div className="g-file-content">
                {(!audioRecordStates.startRecording && audioRecordStates.videoUrl === '' && !audioRecordStates.uploadfile && states.selectedTemplate === null) &&
                <div className="g-empty-file">
                    <span className="g-empty-title">How would you like to record your audio file?</span>
                    <div className="g-empty-bg-icon">
                        <span class="material-icons">
                            perm_camera_mic
                        </span>
                    </div>
                </div>
                }

                <div className={`g-file-control-content`}>
                    <input onChange={voiceUploadCallback} type="file" id='voice-file-for-backend' accept="audio/mp3, video/webm" className='d-none'/>
                    <audio className="d-none" controls='controls' id='record-audio' muted />
                    {audioRecordStates.startRecording &&
                        <div className="g-record-count-down">{`${audioRecordStates.minutes} : ${audioRecordStates.seconds}`}</div>
                    }

                    {/* {this.props.showAudio != null && */}
                    <>
                    {(!audioRecordStates.startRecording && audioRecordStates.showRecordedPlayer) && (
                        <span className='2'>
                            <audio controls="controls" src={audioRecordStates.videoUrl} />
                        </span>
                    )
                    }
                    {(!audioRecordStates.startRecording && audioRecordStates.uploadfile) && (
                        <span className='3'>
                            <audio controls id={'voice_file_preview-for-backend'} src=""/>
                        </span>
                    )
                    }
                    {(states.sendFileType === 'TEMPLATE') && (
                        <span className='3'>
                            <audio controls id={'voice_file_preview-template'} src={states.selectedTemplate}/>
                        </span>
                    )
                    }
                    </>
                    {/* } */}
                </div>
            </div>
        </div>
    )
}
export default VoiceFile;