import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "./assets/contact-details.css";
import "./assets/global__contact_details_modal.css";
import EmailSendModal from "./modals/SendModules/EmailSend";
import SmsSendModal from "./modals/SendModules/SmsSend";
import VoiceSendModal from "./modals/SendModules/VoiceSend";
import UpdateContactInfo from "./modals/UpdateContactInfo";
import BasicInfo from "./partition/BasicInfo";
import Header from "./partition/Header";
import MoreInfo from "./partition/MoreInfo";
import { AddNote } from "./partition/Note";
import { getContactDetails, _updateContactDetails, _updateStateField } from "./redux/contactDetailAction";
import Review from "./partition/CallReview/Review";

const ContactDetailCallOutcome = props => {

    const {
        contactId, contactDetails, fetchingContactDetail, deletingContact,
        showingSmsSendModal, showingEmailSendModal, showingVoiceSendModal,
        showingUpdateContactModal, tab, callOutcome
    } = props.contactDetailReducer;

    /* Review */
    const [reviewSubmitted, setReviewSubmitted] = useState(false);
    const [numberOfStar, setNumberOfStar] = useState(0);
    const [note, setNote] = useState("");
    const [callOutcomes, setCallOutcomes] = useState(callOutcome);
    const [selectedCallOutcome, setSelectedCallOutcome] = useState("");

    useEffect(() => {
        props.getContactDetails({
            id: contactId,
            from: 'call_outcome'
        })
    },[contactId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCallOutcomes(callOutcome);
    }, [callOutcome]);

    if(contactId == null || contactId === ''){
        return null;
    }

    const handleSmsSendModal = () => {
        props._updateStateField({showingSmsSendModal: false})
    }

    const handleEmailSendModal = () => {
        props._updateStateField({showingEmailSendModal: false})
    }

    const handleVoiceSendModal = () => {
        props._updateStateField({showingVoiceSendModal: false})
    }

    return (
        <>
            <div className={`contact-details-container v2 ${(deletingContact || fetchingContactDetail) ? 'loader-container' : ''}`}>

                {/* header part */}
                <Header onClose={props.closeModal}/>

                {/* basic info part */}
                {tab === 'call_review' &&
                    <Review
                        contactId={contactId}
                        reviewSubmitted={reviewSubmitted}
                        setReviewSubmitted={setReviewSubmitted}
                        numberOfStar={numberOfStar}
                        setNumberOfStar={setNumberOfStar}
                        note={note}
                        setNote={setNote}
                        callOutcomes={callOutcomes}
                        selectedCallOutcome={selectedCallOutcome}
                        setSelectedCallOutcome={setSelectedCallOutcome}
                    />
                }

                {/* basic info part */}
                {tab === 'contact' &&
                    <BasicInfo />
                }

                {tab === 'note' &&
                    <AddNote />
                }

                {/* more info part */}
                {(!fetchingContactDetail) &&
                    <MoreInfo closeModal={props.closeModal}/>
                }

                {/* ------modal part------- */}

                {/* send sms */}
                {showingSmsSendModal &&
                    <SmsSendModal 
                        open={showingSmsSendModal} 
                        onClose={handleSmsSendModal} data={contactDetails} 
                    />
                }

                {/* send email */}
                {showingEmailSendModal &&
                    <EmailSendModal 
                        open={showingEmailSendModal} 
                        onClose={handleEmailSendModal} 
                        data={contactDetails} 
                    />
                }
                
                {/* send voice */}
                {showingVoiceSendModal &&
                    <VoiceSendModal 
                        open={showingVoiceSendModal} 
                        onClose={handleVoiceSendModal} 
                        data={contactDetails} 
                    />
                }
                {/* update contact details modal */}
                {showingUpdateContactModal &&
                    <UpdateContactInfo 
                        open={showingUpdateContactModal} 
                        onClose={() => {
                            props._updateStateField({showingUpdateContactModal: false})
                        }} 
                        data={contactDetails}
                    />
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});

const mapDispatchToProps = dispatch => ({
    /* for middleware */
    getContactDetails : (params, callback) => dispatch(getContactDetails(params, callback)),
    /* for reducer */
    _updateContactDetails: params => dispatch(_updateContactDetails(params)),
    _updateStateField: params => dispatch(_updateStateField(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailCallOutcome);