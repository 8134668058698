import { useState, useEffect, useContext } from "react";
import ApiList from "../../ApiList";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import CampaignList from "./List";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const Campaign = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [expand, setExpand] = useState(false)
    const [campaigns, setCampaigns] = useState([])
    const [campaignList, setCampaignList] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [filterList, setFilterList] = useState([])
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        if(expand){
            setLoading(true)
            setSelectedCampaign('')
            ApiList.getAllContactCampaigns({
                contact_id: detailStates.contactId
            }).then(res => {
                let response = res.data;
                if(typeof response.data != 'undefined'){
                    setCampaigns(response.data)
                }
                else{
                    setCampaigns(response)
                }
                ApiList.getEligibleCampaigns({
                    contact_id: detailStates.contactId
                }).then(res => {
                    let response = res.data;
                    setCampaignList(response.campaigns)
                    setLoading(false)
                })
            })
        }
    }, [expand])

    const handleSearch = (event) => {
        setSearch(event.target.value)
        let query = event.target.value
        query = query.toLowerCase()
        let filterList = campaignList.filter(item => {
            let text = item.title.toLowerCase()
            if(text.includes(query)) return true
            return false
        })
        setFilterList(filterList)
    }

    const renderList = () => {
        let list = campaignList;
        let showList = [];
        if(search != ''){
            list = filterList
        }
        if(list.length == 0){
            return(
                <span className="noValid">No campagin found</span>
            )
        }
        list.map((item, index) => {
            showList.push(
                <div key={index} className="g-eligible-campaign" onClick={() => setSelectedCampaign(item.id.toString())}>
                    {item.id == selectedCampaign ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                    {item.title}
                </div>
            )
        })
        return showList;
    }

    const handleSubmit = () => {
        if(submit) return;
        if(selectedCampaign == '') return;
        setSubmit(true)
        ApiList.moveCampaignContact({
            campaign_id: false,
            contact_id: detailStates.contactId,
            isSubscribe: 0,
            move_campaign_id: selectedCampaign.toString()
        }).then(res => {
            let response = res.data
            setSubmit(false)
            if(response.status == 'success'){
                if(window.showNotification){
                    window.showNotification("SUCCESS", response.html);
                }
                if(detailStates.from === 'deal-details'){
                    if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                        detailStates.dealCallback(true)
                    }
                }
                setExpand(false)
            }
            else{
                if(window.showNotification){
                    window.showNotification("ERROR", 'Something went wrong');
                }
            }
        })
    }

    const renderContactCampiagnList = () => {
        let list = []
        campaigns.map((item, index) =>{
            list.push(
                <CampaignList key={index} data={item} index={index}/>
            )
        })
        return list;
    }

    let contactName = "";
    if(detailStates.contactDetails !== null){
        let details = detailStates.contactDetails;
        if((details.first_name !== null && details.first_name !== "") && (details.last_name !== null && details.last_name !== "")){
            contactName = details.first_name + " " + details.last_name
        }
        else if(details.first_name !== null && details.first_name !== ""){
            contactName = details.first_name
        }
        else if(details.last_name !== null && details.last_name !== ""){
            contactName = details.last_name
        }
        else if(details.email !== null && details.email !== ""){
            contactName = details.email
        }
        else{
            contactName = details.number
        }
    }

    return(
        <div className="g-more-info-left g-more-info-column mt-4">
            <CustomAccordion  expand={expand} className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span className="g-header-title"><span className="material-icons">campaign</span> Campaign </span>
                    <span className="g-header-count">{detailStates.totalCampaign}</span>
                    <span className="g-custom-caret">{icons.caretIcon}</span>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>
                    <div className="awesome__scroll_bar g-contact-campaign-list-content">
                        <div>
                            {campaigns.length == 0 &&
                                <span className="g-not-found-contact-campaign">No campaign is assigned for this contact</span>
                            }
                            {campaigns.length != 0 && renderContactCampiagnList()}
                        </div>

                        <div className="g-search-campaign-content">
                            <span>You can add {contactName} to the following campaigns:</span>
                            <input className="g-search-campaign" type="text" onChange={handleSearch} placeholder={'Type and search'} onChange={handleSearch}/>
                        </div>

                        <div>
                            {loading && <span>Loading...</span>}
                            {!loading && 
                            <div>
                                {renderList()}
                            </div>
                            }
                        </div>

                        {selectedCampaign != '' &&
                        <div className="g-assign-campaign-button-content">
                            <span onClick={handleSubmit}>{submit ? <MoreHorizIcon fontSize="small"/> : <AddIcon fontSize="small"/>} {submit ? 'Assigning' : 'Assign'} Campaign</span>
                        </div>
                        }
                    </div>
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
export default Campaign;