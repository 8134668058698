import * as ACTION from "../constants/globalDialerActionTypes";

const firstWorld = {
    // stage : 'dial',

    //comment 10-11
    // virtualNumbers: undefined,
    // dialNumber: '',
    // comment: 10-11

    // recentContactLoading: true,
    // recentContacts: [],
    activeContact: [],
    initialContactNumber: '',
    //comment 10-11
    // isCallInProgress: false,
    // isCallReceived : false,

    //comment 10-11

    callStatus: 'Dialing',
    callTime: '',

    callInfo: {
        firstCallSid: '',
        secondCallSid: '',
        thirdCallSid: '',
        conferenceSid: '',
    },

    enableWarmTransfer: false,
    transferType: '',
    secondCallStage: 'dial',
    callOnScreen: 'first',
    isSecondCallInProgress: false,
    secondDialNumber: '',
    isSecondCallOnHold: false,
    secondCallStatus: 'Dialing',
    contactSearchPageNo: 1,
    contactSearchLastPage: -1,
    isFirstCallOnHold:false
    // updateInitialContact: false
}

const initialState = {
    stage : 'dial',
    virtualNumbers: undefined,
    fromNumber: '',
    dialNumber: '',
    recentContactLoading: true,
    recentContacts: [],
    activeContact: {},
    initialContactNumber: '',

    isCallInProgress: false,
    callStatus: 'Dialing',
    callTime: '',

    callInfo: {
        firstCallSid: '',
        secondCallSid: '',
        thirdCallSid: '',
        conferenceSid: '',
    },

    enableWarmTransfer: false,
    transferType: '',
    secondCallStage: 'dial',
    callOnScreen: 'first',
    isSecondCallInProgress: false,
    secondDialNumber: '',
    isFirstCallOnHold:false,
    isSecondCallOnHold: false,
    secondCallStatus: 'Dialing',
    isCallReceived : false,
    updateInitialContact: false,

    //List call
    listCurrentIndex: 0,
    goToNextInList: false,

    //
    fixedDial : false,
    fixedDialNumber : '',
    globalDialerSocket : null
};
const globalDialerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_CURRENT_CREDIT:
            return {...state, currentCredit: action.payload.currentCredit}

        case ACTION.CLEAR_DIAL_NUMBER:
            return {...state, dialNumber: ''}

        case ACTION.ADD_DIAL_NUMBER:
            return {...state, dialNumber: action.payload.dialNumber}

        case ACTION.STORE_VIRTUAL_NUMBER:
            return {...state, virtualNumbers: action.payload.virtualNumbers, fromNumber: action.payload.fromNumber}

        case ACTION.SELECT_FROM_NUMBER:
            return {...state, fromNumber: action.payload.fromNumber}

        case ACTION.CHANGE_STAGE:
            return {...state, stage: action.payload.stage}

        case ACTION.RECENT_CONTACT_LOADED:
            if (action.payload.selectedContact) {
                return {
                    ...state,
                    recentContacts: action.payload.recentContacts,
                    recentContactLoading: action.payload.recentContactLoading,
                    activeContact: {
                        contact_id: action.payload.selectedContact.id,
                        user_id: action.payload.selectedContact.user_id,
                        first_name: action.payload.selectedContact.first_name,
                        last_name: action.payload.selectedContact.last_name,
                        number: action.payload.selectedContact.number,
                        current_user: action.payload.selectedContact.current_user,
                    }
                };
            } else {
                return {
                    ...state,
                    recentContacts: action.payload.recentContacts,
                    recentContactLoading: action.payload.recentContactLoading,
                }
            }

        case ACTION.SET_INITIAL_CONTACT:
            return {...state, initialContactNumber: action.payload.initialContactNumber}

        case ACTION.PUT_CONTACT:
            return {...state, ...action.payload}

        case ACTION.SELECT_ACTIVE_CONTACT:
        case ACTION.REMOVE_ACTIVE_CONTACT:
            return {...state, activeContact: action.payload.activeContact, initialContactNumber: ''}

        case ACTION.RECENT_CONTACT_LOADING:
            return {...state, recentContactLoading: action.payload.recentContactLoading}

        case ACTION.UPDATE_CALL_IN_PROGRESS_STATUS:
            return {...state, isCallInProgress: action.payload.isCallInProgress}

        case ACTION.UPDATE_CALL_STATUS:
            return {...state, callStatus: action.payload.callStatus}

        case ACTION.RESET_DIALER:
            return {...state, ...firstWorld}

        case ACTION.UPDATE_CALL_TIME:
            return {...state, callTime: action.payload.callTime}

        case ACTION.UPDATE_CALL_INFO:

            return {...state, callInfo: action.payload.callInfo}

        case ACTION.ENABLE_WARM_TRANSFER:
            return {...state, enableWarmTransfer: action.payload.enableWarmTransfer, callOnScreen: action.payload.callOnScreen, transferType: action.payload.transferType}

        case ACTION.TOGGLE_BETWEEN_SCREEN:
            return {...state, callOnScreen: action.payload.callOnScreen}

        case ACTION.CHANGE_SECOND_CALL_STAGE:
            return {...state, secondCallStage: action.payload.secondCallStage}

        case ACTION.ADD_SECOND_DIAL_NUMBER:
            return {...state, secondDialNumber: action.payload.secondDialNumber}

        case ACTION.CLEAR_SECOND_DIAL_NUMBER:
            return {...state, secondDialNumber: ''}

        case ACTION.UPDATE_SECOND_CALL_IN_PROGRESS_STATUS:
            return {...state, isSecondCallInProgress: action.payload.isSecondCallInProgress}

        case ACTION.UPDATE_IS_CALL_RECEIVED:
            return {...state, isCallReceived: action.payload.isCallReceived}

        case ACTION.UPDATE_SECOND_CALL_STATUS:
            return {...state, secondCallStatus: action.payload.secondCallStatus}

        case ACTION.DESTROY_SECOND_CALL:
            return {...state, enableWarmTransfer: action.payload.enableWarmTransfer, callOnScreen: action.payload.callOnScreen}

        case ACTION.UNHOLD_ALL:
            return {...state, isFirstCallOnHold: action.payload.isFirstCallOnHold, isSecondCallOnHold: action.payload.isSecondCallOnHold,}

        case ACTION.RESET_WITH_CONTACT_NUMBER:
            return {...state, ...firstWorld,...{initialContactNumber : action.payload.number,updateInitialContact: action.payload.updateInitialContact}}
    
        case ACTION.STORE_MULTIPLE_REDUCERS:
            return {...state, ...action.payload}
            
        default:
            return state;
    }
}

export default globalDialerReducer;
