import React, { useContext, useEffect, useState } from 'react'
import { ContactDetailContext } from '../reducer/GlobalReduers';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { icons } from '../icons';
import ApiList from '../ApiList';
import LinkFile from '../LinkFile';
import ClearIcon from '@material-ui/icons/Clear';
import NoteComponent from './noteNew/NoteComponent';

export const AddNote = ({editNote, updateData, handleEditFlag, refresh}) => {
    const {detailStates} = useContext(ContactDetailContext)
    const editorRef = React.createRef()
    const [noteText, setNoteText] = useState('')
    const [sumitProcess, setSumitProcess] = useState(false)

    useEffect(() => {
        if(editNote){
            editorRef.current.scrollIntoView({ behavior: 'smooth' })
            setNoteText(updateData.message)
        }
    }, [editNote])

    const handleAddNoteSubmit = () => {
        if(sumitProcess){
          return
        }
        if(noteText.trim() == ''){
            if(window.showNotification !== undefined){
                window.showNotification("ERROR", 'Note is required');
            }
          return
        }
        setSumitProcess(true)
    
        if(editNote){
          // props.storeNote({
          ApiList.updateNote({
            contact_id: detailStates.contactId,
            note: noteText,
            origin: 4,
            id: updateData.id
          }).then(res => {
              let response = res.data;
                if(response.status == 'success'){
                    refresh()
                    setSumitProcess(false)
                    setNoteText('')
                    handleEditFlag(false)
                }
          })
        }
        else{
            ApiList.addNote({
                contact_id: detailStates.contactId,
                note: noteText,
                origin: 4
            }).then(res => {
                let response = res.data
                if(response.status == 'success'){
                    refresh()
                    setSumitProcess(false)
                    setNoteText('')
                }
            })
        }
    }

    return(
        <div className="g-note-add-content" ref={editorRef}>
            <NoteComponent 
                value={noteText} 
                onChange={setNoteText}
            />
            <div className="g-note-button-groups">
                {editNote &&
                <button className="g-note-cancel-button" onClick={() => {setNoteText(''); handleEditFlag(false)}}>
                    <ClearIcon /> Cancel Edit
                </button> 
                }           
                <button className="g-note-add-button" onClick={handleAddNoteSubmit}>
                    {sumitProcess ? <MoreHorizIcon fontSize="small" /> : (editNote ? icons.editIcon : icons.whiteCirclePlusIcon)} {editNote ? (sumitProcess ? 'Editing' :'Edit') : (sumitProcess ? 'Adding' : 'Add')} Note
                </button>
            </div>
        </div>
    )
}