import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getUserCategoriesApi } from '../../../../api/ApiAction';
import DirectMailIcons from '../../../../DirectMailIcons';

const AddOption = ({
    selectDesignStep,
    handleTitleChange,
    handleChangeCategory,
    handleAddNewCategory,
    type,
    handleChangeOrientation,
    handleDataChange,
    showHints
}) => {

    const [userCategories, setUserCategories] = useState([]);

    useEffect(() => {
        if(!(selectDesignStep.templateFrom === "user" && selectDesignStep.categories.length > 0)){
            getUserCategory()
        }
        else{
            setUserCategories(selectDesignStep.categories)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserCategory = () => {
        let type_value = (type === 'postcard' ? 6 : (type === 'letter' ? 9 : (type === 'zendirect_gift' ? 7 : 10)))
        getUserCategoriesApi({
            type: type_value
        })
        .then(res => {
            let response = res.data;
            if(response.status === 'success'){
                setUserCategories(response.data)
                handleDataChange({
                    templateFrom: 'user',
                    categories: response.data
                })
            }
        })
        .catch(error => {

        })
    }

    const renderCategories = () => {
        let view = []
        userCategories.forEach((item, index) => {
            view.push(
                <MenuItem key={index} value={item.id} className={'dropdownhelper-menuitem-class'}>
                    {item.title}
                </MenuItem>
            );
        });
        return view;
    }

    return (
        <div className='dmpc_templete_upload_input'>
            <div className='dmpc_templete_ui_single'>
                <label className='dmpc_templete_ui_single_label'>Template Title</label>
                <input 
                    className='dmpc_templete_ui_single_input' 
                    placeholder='Write template title'
                    value={selectDesignStep.saveAsTemplate.title}
                    onChange={handleTitleChange}
                />
            </div>
            <div className='dmpc_templete_ui_single dmpc_templete_ui_single_cat'>
                <h4 className='dmpc_templete_ui_single_label'>Category</h4>
                <FormControl className="dmpc_templete_uis_formControll">
                    <Select
                        value={selectDesignStep.saveAsTemplate.category} 
                        onChange={handleChangeCategory}
                        className={"dmpc_templete_uis_sf"}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: { paper: "dmpc_templete_uis_select_wr" }
                        }}
                    >
                        <MenuItem value={null} disabled>Select Category</MenuItem>
                        <MenuItem value={'add_new'} className={' dmpc_templete_uis_select_item_add'}>
                            <span>{DirectMailIcons.addCategoryIcon}</span>
                            <span>Add New Category</span>
                        </MenuItem>
                        {renderCategories()}
                    </Select>
                </FormControl>
            </div>
            {selectDesignStep.saveAsTemplate.category === "add_new" &&
            <div className='dmpc_templete_ui_single'>
                <label className='dmpc_templete_ui_single_label'>Category Title</label>
                <input 
                    className='dmpc_templete_ui_single_input' 
                    placeholder='New category'
                    value={selectDesignStep.saveAsTemplate.new_category_title}
                    onChange={handleAddNewCategory}
                />
            </div>
            }
            {type === 'zendirect_gift' &&
            <div className='dmpc_templete_ui_single dmpc_templete_ui_single_cat'>
                <h4 className='dmpc_templete_ui_single_label'>Orientation</h4>
                <FormControl className="dmpc_templete_uis_formControll">
                    <Select
                        value={selectDesignStep.saveAsTemplate.orientation} 
                        onChange={handleChangeOrientation}
                        className={"dmpc_templete_uis_sf"}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: { paper: "dmpc_templete_uis_select_wr" }
                        }}
                    >
                        <MenuItem value={null} disabled>Select Category</MenuItem>
                        <MenuItem value={1} className={'dropdownhelper-menuitem-class'}>Landscape</MenuItem>
                        <MenuItem value={2} className={'dropdownhelper-menuitem-class'}>Portrait</MenuItem>
                    </Select>
                </FormControl>
            </div>
            }
        </div>
    );
}
export default AddOption;