import axios from "axios";

const getCookie = cname => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

const sendRequestBackend = (method, url, postData) => {
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN)//for production
 
    return fetch(url, {
        method: method,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + apiKey
        },
        referrerPolicy: "origin",
        body: JSON.stringify(postData)
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData
        })
        .finally(() => { })
        .catch(error => {
            console.log("url", error)
            return false;
        })
}

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN)//for production

    try {
        payload.append("api-key", apiKey)
        data = payload;
    } catch (error) {
        data = { ...payload, ...{ "api-key": apiKey } };
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === "get" || method === "GET") ? data : null
    });
}


const Helper = {
    sendRequestBackend,
    sendRequest
}
export default Helper;