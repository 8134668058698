export const CoreConstants = {

}

export const Labels = {

}

export const Messsages = {
    addNewDealRequired: "Number or email one is required !",
    inValidEmail: 'Email address is not valid',
    invalidNumber: 'Number is not valid',
    noContactSelect: 'No contact select to add new deal',
    noStageSelect: 'No stage seelct to add new deal.'
}