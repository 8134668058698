import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Styles from "./ConfirmPreview.module.css";

const App = (props) => {

  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={Styles.mainWrp}>
            <div className={Styles.bodyWrp}>
                Please preview your sample messages and make any necessary edits before moving on to the next step.
               <button className={Styles.closeButton} onClick={()=>{ 
                    onClose();
                    setTimeout(()=>{
                      props.onSubmit();
                    },200);
                }}>Preview</button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: props.closeOnClickOutside,
      overlayClassName: Styles.overlay
    })
  )
}
export default App;