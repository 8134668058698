import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core';
import './newPackage.css';
import { CheckCircle } from '@material-ui/icons';
import { price } from 'pos/lexicon';

const PRICING_MONTHLY = 'MONTHLY';
const PRICING_YEARLY = 'YEARLY';
const PRICING_CUSTOM = 'CUSTOM';

const renderDay = (name) => {
    return name;
}


export const NewPackageBlock = (props) => {
    const [selectedPricing, setSelectedPricing] = useState({});
    const [pricing, setPricing] = useState((props.package.pricing)?JSON.parse(props.package.pricing):[]);

    useEffect(() => {
        let tempPricing=null;
        pricing.map((item)=>{
            if(item.price !== null){
                if(tempPricing === null){ tempPricing = item; }
                else{
                    if(item.name === PRICING_YEARLY){ tempPricing = item; }
                    else if(item.name === PRICING_MONTHLY &&  tempPricing.name !== PRICING_YEARLY){
                        tempPricing = item;
                    }
                }
            }
        });
        if(tempPricing !== null){  setSelectedPricing(tempPricing); }
        // console.log(tempPricing)
    },[props.package]);

    const submitPack = () => {
        props.selectPackage({...props.package, selectedPricing: selectedPricing});
    }

    const renderPricing = () => {
        let view = [];
        if (pricing.length) {
            pricing.map((priceData, index)=> {
                if(priceData.price !== null){
                    view.push(
                        <div key={index} className={`sign_up__select_period ${selectedPricing.name === priceData.name ? 'active' : ''}`} onClick={() => setSelectedPricing(priceData)}>
                            <span>{capitalizeFirst(priceData.name)}</span>
                            <span className='mt-2'><span className='price-amount'><sup>$</sup>{priceData.price}</span> / {(priceData.name === PRICING_CUSTOM)?priceData.validity+'days':priceData.friendly_name}</span>
                            <CheckCircle/>
                        </div>    
                    )
                    return view;   
                }
            });
        }
        if(view.length){ return view;  }
    }

    const renderFeature = () => {
        let features = (props.package.view_features)?JSON.parse(props.package.view_features):[];
        let view = [];
        if (features) {
            features.map((feature, index)=> {
                view.push(
                    <li key={index} className='collection-item'><strong>{feature}</strong></li>   
                )
                return view;   
            });
        }
        if(view.length){ return view; }
    }

    const capitalizeFirst = (str='  ') => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const renderUserCount=()=>{
        if (
          !props.package.max_sub_user &&
          props.package.price_for_subuser === 0
        ) {
          return (
            <div className="trial_package_addtional_charge_title">
              <h2>Unlimited Users</h2>
            </div>
          )
        } else {
          return " ";
        }
      }
     

    return (
        <Grid item xs={4} className="sign_up__new_agency_package">
            <div className="mt-3 my-1">
                <div className="card first_price_list">
                    <div>
                        <div className="card-content center px-1">
                            <h5>{props.package.name}</h5>
                        </div>
                        <div className="card-content price_content  secong_pric_list  center">
                            { selectedPricing !== null &&
                                <h2><sup>$</sup>{selectedPricing.price}<span>/{(selectedPricing.name === PRICING_CUSTOM)?selectedPricing.validity+'days':capitalizeFirst(selectedPricing.name)}</span></h2>
                            }
                        </div>

                       {renderUserCount()}

                        <ul className='collection pric_list_content center'>
                            {renderFeature()}
                        </ul>
                    </div>
                    <div className="card-content center">

                        <div className='sign_up__periods_wrapper'>
                            {renderPricing()}
                        </div>

                        <div className="select_plan_btn">
                            <button onClick={() => submitPack()} className="btn">
                                { props.isTrialPage ? 'Continue Free Trial' : 'Continue' }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    )
}
