import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { Button } from "reactstrap";


const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    }
}));


const SidebarPopoverMenu = props => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const extraClass = props.sidebarName === 'marketing' ? 'marketing' : '';

    return ( 
        <li onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <a href="/" onClick={e => {e.preventDefault()}} className={"waves-effect left-side-main-menu" + (props.activeSidebar === props.sidebarName ? ' active' : '')}>
                {props.icon}
                <span className="item__text txt_ds_side_bar">{props.text}</span>
            </a>
            <Popover
                className={classes.popover + ` slide__nav_wrapper ${extraClass}`}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                style={{left:20}}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                { props.children }
            </Popover>

            {props.topNotification && props.topNotification=== 'true' && 
                <NewNotificationIcon type={"warning"} text="new" />
            }

        </li>
    );
}
 
export default SidebarPopoverMenu;

const NewNotificationIcon = ({text, type=""}) => {
    return <Button className={`new_notification sidebar-topicon-${type} `}>{text}</Button>
}