import { InputBase, TextField, makeStyles, withStyles } from "@material-ui/core";

export const RightSideV2Styles = makeStyles((theme) => ({
    darkBlue: {
        color: "#181F48",

        "&:first-letter": {
            textTransform: "uppercase",
        },
    },

    textWhite: {
        color: "#ffffff",
    },

    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },

    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    flexCenterV2: {
        display: "flex",
        flexDirection: "row",
    },
    flexCenterBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    alignJustifyCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    alignJustifyCenterV2: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
    },

    primaryButton: {
        textTransform: "none",
        background: "var(--light_blue)",
        "&:hover, &:focus": {
            background: "var(--light_blue)",
        },
    },

    primaryButtonRounded: {
        textTransform: "none",
        background: "var(--light_blue)",
        "&:hover, &:focus": {
            background: "var(--light_blue)",
        },
        borderRadius: 25,
    },

    secondaryButton: {
        color: "white",
        textTransform: "none",
        // background: "#00ff91",
        // "&:hover, &:focus": {
        //   background: "#00ff91",
        // },
        background: "#a352c2",
        "&:hover, &:focus": {
            background: "#a352c2",
        },
    },

    secondaryButtonRounded: {
        color: "white",
        textTransform: "none",
        // background: "#00ff91",
        // "&:hover, &:focus": {
        //   background: "#00ff91",
        // },
        background: "#a352c2",
        "&:hover, &:focus": {
            background: "#a352c2",
        },

        borderRadius: 25,
    },

    warningButtonRounded: {
        color: "white",
        textTransform: "none",
        // background: "#00ff91",
        // "&:hover, &:focus": {
        //   background: "#00ff91",
        // },
        background: "#708131",
        "&:hover, &:focus": {
            background: "#708131",
        },

        borderRadius: 25,
    },
    closeButton: {
        textTransform: "none",
        color: "#ffffff",
        background: "var(--red)",
        "&:hover, &:focus": {
            background: "var(--red)",
        },
    },

    textDarkBlue: {
        color: "#181F48",
    },

    linkUnderLine: {
        color: "var(--light_blue)",
        textDecoration: "underline",
        fontWeight: 500,
    },

    customScrollBar: {
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            width: "10px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "#2c3e50",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#706fd3",
            borderRadius: "10px",
            // outline: '1px solid #8e44ad'
        },
    },

    customScrollBarThin: {
        "&::-webkit-scrollbar": {
            width: "5px !important",
        },
    },

    primaryChip: {
        background: "#181F48",

        // Alternate Design
        // background: "#0e0f3f",

        "& .MuiChip-deleteIcon": {
            color: "var(--light_blue)",
        },
    },

    titleIcon: {
        cursor: "pointer",

        "& svg": {
            fontSize: 18,
        },

        "&:focus, &:hover ": {
            background: "transparent",
        },
    },

    draggableWrap: {
        position: "relative",
        "& .MuiIconButton-sizeSmall": {
            visibility: "hidden",
            position: "absolute",
            left: -25,
        },

        "&:hover .MuiIconButton-sizeSmall": {
            visibility: "visible",
        },
    },

    phoneButtonRounded: {
        textTransform: "none",
        color: "white",
        background: "#316aff",
        "&:hover, &:focus": {
            background: "#316aff",
        },
        borderRadius: 25,
    },

    heighFit: {
        height: "fit-content !important",
    },

    textFontSize: {
        fontSize: "16px",
    },

    titleIconHover: {
        cursor: "pointer",
        position: "absolute",
        right: "-24px",
        "& svg": {
            fontSize: 18,
        },

        "&:focus, &:hover ": {
            background: "transparent",
        },
    },
    titleIconHoverV2: {
        cursor: "pointer",
        position: "absolute",
        right: "-24px",
        top: "34px",
        "& svg": {
            fontSize: 18,
        },

        "&:focus, &:hover ": {
            background: "transparent",
        },
    }
}));

export const BasicInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "&.MuiInputBase-multiline": {
            padding: "0 !important",
        },
    },
    input: {
        position: "relative",
        color: "var(--dark_blue)",
        fontSize: "14px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },
    },
}))(InputBase);

export const BasicInputSelect = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: "relative",
        color: "var(--dark_blue)",
        fontSize: ".8vw",
        margin: "0 !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },
    },
}))(InputBase);

export const BasicTextField = withStyles((theme) => ({
    root: {
        "& .MuiInputBase-root": {
            padding: "0 !important",
            "& .MuiInputBase-input": {
                position: "relative",
                color: "var(--dark_blue)",
                fontSize: "14px !important",
                margin: "0 !important",
                height: "2.5rem !important",
                borderRadius: "4px !important",
                border: "1px solid #ced4da !important",
                transition: theme.transitions.create(["border-color", "box-shadow"]),

                "&:focus": {
                    borderRadius: 4,
                    boxShadow: "unset !important",
                },
            },
        },
    },
}))(TextField);
