import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Fragment, useEffect, useState } from "react"
import Select from 'react-select';
import { updateTimezone } from "../../../api/onboardApi";
import Utils from "../../../helpers/Utils";

const generateTimezoneList = () => {
    let timezones = [];

    Utils.generateTimezoneList().forEach(element => {
        timezones.push({
            value: Object.getOwnPropertyNames(element)[0],
            label: element[Object.getOwnPropertyNames(element)]
        })
    });

    return timezones;
}

const TimezoneForm = (props) => {
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.user && props.user.timezone) {
            setSelectedTimezone({
                value: props.user.timezone,
                label: Utils.generateTimezoneList(props.user.timezone)
            })
        }
    }, [props.user])

    const submitData = () => {

        if(selectedTimezone != null){
            setLoading(true);

            updateTimezone({
                timezone: selectedTimezone.value,
            }).then((response) => {
                props.callback();
            }).finally(() => {
                setLoading(false);
            })
        }else{
            window.showNotification('ERROR','Please select timezone')
        }
       
    }

    return (
        <Fragment>
            <div id="timezone" class="col s12 active">
                <div className="mt-4 mb-2"><Alert severity="info">Once Timezone is Setup, it can't be changed</Alert></div>
                <div class="selet_input">
                    <label className="onboarding__number__section__label mb-2">Timezone</label>
                    <div class="time_select">
                        <Select
                            options={generateTimezoneList()}
                            isClearable={false}
                            isDisabled={props.user.timezone ? true : false}
                            value={selectedTimezone}
                            onChange={(_selectedOption) => {setSelectedTimezone(_selectedOption)}}
                            placeholder="Select Timezone"
                        />
                    </div>
                </div>
                {
                    (props.user && !props.user.timezone) && (
                        <div className="mt-3">
                            <Button variant="contained" color="primary" disabled={(loading || selectedTimezone ==null)} onClick={submitData}>
                                {!loading ? 'Save Timezone' : 'Saving...'}
                            </Button>
                        </div>
                    )
                }
            </div>
        </Fragment>
    )
}

export default TimezoneForm;