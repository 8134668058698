import { Box } from '@material-ui/core';
import React from 'react';
import ContactInfo from './contactInfo/ContactInfo';
import ContactBody from './contacts/contactBody/ContactBody';
import MoreInfo from '../MoreInfo';

const RightSide = (props) => {
    const {contactDetails } = props || {};
    return (
      <Box>
        <ContactBody closeModal={props.closeModal} contactDetails={contactDetails} />
       <Box sx={{marginTop:'10px'}}>
       <ContactInfo/>
        <MoreInfo contactDetails={contactDetails}/>
       </Box>
      </Box>
    );
};

export default RightSide;