import {useContext, useState, useEffect} from 'react'
import CustomSelect from '../components/CustomSelect';
import { updateVirtualNumber } from '../reducers/Action';
import { VoiceSendContext } from '../reducers/GlobalReduers';
import { MenuItem } from "@material-ui/core";
import SendHelper from '../assets/Helper';

const VnNumber = props => {
    const {states, dispatch} = useContext(VoiceSendContext)

    const hanldeVnNumber = (value) => {
        dispatch(updateVirtualNumber(value))
    }

    return(
        <div className="g-voice-send-vn-content">
            <span className="g-voice-send-vn-label">From Number:</span>
            <div className="g-voice-send-vn-select-content">
                <CustomSelect
                    formControlClass="g-voice-send-vn-select"
                    value={states.selectedVirtualNumber}
                    handleChange={(event) => hanldeVnNumber(event.target.value)}
                    placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a from number </MenuItem>)}
                    item={states.virtualNumbers}
                    titel_field={'label'}
                    value_field={'value'}
                    menuItemClass="dropdownhelper-menuitem-class"
                />
            </div>
        </div>
    )
}
export default VnNumber;