import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DIRECT_MAIL_FOR_SEND } from '../../../Index';
import { timeIntervalOptions } from './SendTypeStep';

const greetings = [
    {label : 'Birthday', value: 1},
    {label : 'Anniversary', value: 2}
]

const Greetings = ({readyToSend, handleReadyToSendChange, contactInfo, processFor}) => {

    const renderGreetings = () => {
        let view = [];
        greetings.forEach((item, index) => {
            let disabled = false;
            if(processFor === DIRECT_MAIL_FOR_SEND){
                if(contactInfo != null){
                    if(item.value === 1){
                        if(contactInfo.birth_date == null || contactInfo.birth_date === ""){
                            disabled = true;
                        }
                    }
                    else{
                        if(contactInfo.anniversary_date == null || contactInfo.anniversary_date === ""){
                            disabled = true;
                        }
                    }
                }
                else{
                    disabled = true
                }
            }
            view.push(
                <MenuItem key={index} value={item.value} disabled={disabled} className={'dropdownhelper-menuitem-class'}>
                    {item.label}
                </MenuItem>
            );
        });
        return view;
    }

    return(
        <div className='dmpc_4th_tab_day'>
            <div className='dmpc_4th_tab_day_inner'>
                <div className='dmpc_4th_tab_day_hour'>
                    <div className='dmpc_4th_tab_day_hour_inner'>
                        <span className='dmpc_4th_tab_day_hour_title'>Select Greetings</span>
                        <FormControl className="day_select_formControll day_select_formControll_132">
                            <Select
                                value={readyToSend.greeting} 
                                IconComponent={ExpandMoreIcon}
                                onChange={(e) => handleReadyToSendChange({greeting: e.target.value})}
                                className={"day_select_sf"}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={null} className='dropdownhelper-menuitem-class' disabled >Select Greetings</MenuItem>
                                {renderGreetings()}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className='dmpc_4th_tab_day_hour'>
                    <div className='dmpc_4th_tab_day_hour_inner'>
                        <span className='dmpc_4th_tab_day_hour_title'>At</span>
                        <FormControl className="day_select_formControll day_select_formControll_97">
                            <Select
                                value={readyToSend.at_time} 
                                IconComponent={ExpandMoreIcon}
                                onChange={(e) => handleReadyToSendChange({at_time: e.target.value})}
                                className={"day_select_sf"}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={null} className='dropdownhelper-menuitem-class' disabled >Time</MenuItem>
                                {timeIntervalOptions(15, MenuItem)}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Greetings;