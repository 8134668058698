export const CODE_WISE_DATA = {
    CAMPAIGN_USE_CASE_DESCRIPTION: [
        "Customers looking to buy or sell a home",
        "Contacting consumers in the transportation industry that are in need of insurance products.",
        "Contacting consumers in the transportation industry that are in need of insurance products.",
        "Health plan options and customer service.",
        "For the buying and selling of health insurance products.",
        "The campaigns are used as an outreach to contact clients and prospects regarding their insurance policies.",
        "New Prospective Clients that are reaching out for help.",
        "To reach out to potential sellers who may be interested in selling their property.",
        "Providing personalized real estate solutions to meet your specific needs.",
        "Offering competitive rates and flexible financing options for homebuyers.",
        "Assisting clients with navigating the home loan application process.",
        "Connecting buyers and sellers in the real estate market.",
        "Exploring investment opportunities in the real estate industry.",
        "Helping individuals and families find their dream homes.",
        "Providing expert advice on property valuation and market trends.",
        "Assisting clients with property inspections and negotiations.",
        "Facilitating smooth transactions for homebuyers and sellers.",
        "Educating clients on the home buying process and available resources."
    ],
    MESSAGE_FLOW: [
        "Applying for mortgage loan application online.",
        "Loan application to be updated when status changes.",
        "Sending client reminders for appointments and updates.",
        "They are notified prior to applying that someone will reach out to them if their job experience and skill-set match the position with our agency.",
        "When applying for a loan they will opt in for updates.",
        "When applying for a mortgage client agrees to get updated via text.",
        "When applying for a mortgage, borrower will opt in to get auto texts.",
        "When applying for loan they opt of text updates."
    ],
    SAMPLE_MESSAGE: [
        "Are you looking for down payment assistance for your new trucking business. [[your_company_name]] can help! We bring great value to those we serve. Call [[help_desk_agant]] at [[help-desk-number]] for more information. Type STOP to unsubscribe",
        "Checking in to see if you and your team have any upcoming trips that we can work on for you? Please let me know if we can be of further assistance. We are available 24/7",
        "Hello [[recipient-name]] my name is [[your-name]] with [[your-company-name]]. Do you have time to discuss your property via text? I'm working on buying properties in the area. TXT STOP to END",
        "Hello [[recipient-name]], this is [[your-company-name]] with an exclusive offer for you. Are you interested in learning more about our latest properties for sale? Reply YES to receive more information",
        "hello, this is [[your-name]] with [[your-company-name]], would you consider discussing selling your property in [[city]]? textSTOP2end",
        "Hello! It's time to renew your coverage.",
        "Hey [[recipient-name]], were you still looking for lead generation services?",
        "Hi it’s [[your-name]]. [[your-company-name]]! Post your logo and your 3 Core Principles the 1st and 15th of every month on FB, IG, and Linkedin. This tells your friends 24 times a year what you Promise. More importantly, you’re reminding yourself! Don’t know your 3 Core Principles call me at [[your-contact-number]]",
        "Hi [[recipient-name]], Contacting you to see if you are ready to purchase a home.",
        "Hi this is [[your-name]], with [[your-company-name]], are you still looking for health insurance quotes? Reply yes for quotes end to unsubscribe.",
        "Hi, [[first_name]]. I’m [[your-name]] from [[your-company-name]] and we specialize in helping people get out of debt. Our program legally wipes your debts, giving you a small monthly payment instead. Please give me a call on my direct line at (714) 706-1155. Reply 'STOP'",
        "Hi, thanks for filing out our online form. Reply HELP for help, STOP to prevent future sms.",
        "Hi, this is [[your-name]]. Are you looking for quotes for vision, dental or health? Say QUIT to opt out, say YES to continue.",
        "Hi. thanks for reaching out. How can we help you?",
        "My name is [[your-name]] with [[your-company-name]]. We are buying in that area and would like to know if you would be interested in selling your property. Txt Stop to End",
        "My name is [[your-name]]. I'm trying to contact [[recipient-name]]. I came across [[street-address]] I wanted to see if you are open to talking about us purchasing it,",
        "No message will be sent because we do not text",
        "Thanks for responding to my Facebook post about getting a loan for your real estate investment goals. I'm glad to help. I'd like to find a time for a quick call to see how we can help you. Can you let me know when you'll have 15 minutes?",
        "This is [[your-name]] - I was following up about your inquiry for selling your land.",
        "This is the [[your-company-name]] alert line and we are letting you know that we are building out your programs now and should proceed with pre-launch training.",
        "To help [[your-company-name]] with their marketing and online presence",
        "To learn more about our programs please reply with 'YES'",
        "We received your request for more information regarding [[your-company-name]]. Please visit our product and service webpage for more info."
    ]
};