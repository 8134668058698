import { FormControl, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";

const CONTACT_EMAIL = "CONTACT EMAIL";
const FIRST_NAME = "FIRST NAME";
const LAST_NAME = "LAST NAME";
const MY_VIRTUAL_NUMBER = "My virtual number";
const MY_COMPANY = "My Company";
const MY_NAME = "My Name";
const MY_FIRST_NAME = "My First Name";
const MY_LAST_NAME = "My Last Name";
const MY_PHONE = "My Phone";
const MY_SIGNATURE = "My Signature";
const MY_APPOINTMENT_URL = "My Appointment URL";
const TODAY = "Today Date";
const TOMORROW = "Tomorrow Date";
const CONTACT_COUNTRY = "Property Country";
const CONTACT_STATE = "Property State";
const CONTACT_STREET_ADDRESS = "Property Street Address";
const CONTACT_URL = "Property URL";
const CONTACT_ZIP_CODE = "Property Zip Code";
const CONTACT_PHONE_NUMBER = "Contact Phone Number";
const CONTACT_CITY = "Contact City";
const CONTACT_DEAL_VALUE = "Contact Deal Value";
const CONTACT_BIRTH_DATE = "Contact Birth Date";
const CONTACT_ANNIVERSARY_DATE = "Contact Anniversary Date";
const CONTACT_COMPANY_NAME = "Contact Company Name";

export const withOutCustomsFields = [
  {
    label: FIRST_NAME,
    value: "[[first_name]]",
  },
  {
    label: LAST_NAME,
    value: "[[last_name]]",
  },
  {
    label: CONTACT_EMAIL,
    value: "[[email]]",
  },
  {
    label: CONTACT_PHONE_NUMBER,
    value: "[[contact_phone_number]]",
  },
  {
    label: MY_VIRTUAL_NUMBER,
    value: "[[virtual_number]]",
  },
  {
    label: MY_COMPANY,
    value: "[[company]]",
  },
  {
    label: MY_NAME,
    value: "[[my_name]]",
  },
  {
    label: MY_FIRST_NAME,
    value: "[[my_first_name]]",
  },
  {
    label: MY_LAST_NAME,
    value: "[[my_last_name]]",
  },
  {
    label: MY_PHONE,
    value: "[[my_phone]]",
  },
  {
    label: MY_SIGNATURE,
    value: "[[my_signature]]",
  },
  {
    label: MY_APPOINTMENT_URL,
    value: "[[appointment_url]]",
  },
  {
    label: TODAY,
    value: "[[today]]",
  },
  {
    label: TOMORROW,
    value: "[[tomorrow]]",
  },
  {
    label: CONTACT_COUNTRY,
    value: "[[contact_country]]",
  },
  {
    label: CONTACT_STATE,
    value: "[[contact_state]]",
  },
  {
    label: CONTACT_CITY,
    value: "[[contact_city]]",
  },
  {
    label: CONTACT_STREET_ADDRESS,
    value: "[[contact_street_address]]",
  },
  {
    label: CONTACT_COMPANY_NAME,
    value: "[[contact_company_name]]",
  },
  {
    label: CONTACT_URL,
    value: "[[contact_url]]",
  },
  {
    label: CONTACT_ZIP_CODE,
    value: "[[contact_zip_code]]",
  },
  {
    label: CONTACT_DEAL_VALUE,
    value: "[[contact_deal_value]]",
  },
  {
    label: CONTACT_BIRTH_DATE,
    value: "[[contact_birth_date]]",
  },
  {
    label: CONTACT_ANNIVERSARY_DATE,
    value: "[[contact_anniversary_date]]",
  },
  {
    value: "blue",
    label: "Contact Customize Fields",
    color: "#f2f2f2",
    isDisabled: true,
  },
];

const PersonalizedTag = ({ onChange }) => {
  const [customTags, setCustomTags] = useState([]);
  const [personalizedTags, setPersonalizedTags] = useState([]);

  useEffect(() => {
    let get_data = localStorage.getItem("pype_personalized_tags");
    if (get_data) {
      try {
        let json_parse = JSON.parse(get_data);
        setCustomTags(json_parse.custom_fields);
        setPersonalizedTags(json_parse.personalized);
      } catch (error) {
        getTags();
      }
    } else {
      getTags();
    }
  }, []);

  const getTags = () => {
    window
      .globalCustomFieldWithPersonalized({
        get_custom_data: true,
        get_personalized_data: true,
        field_name: ["*"],
      })
      .then((res) => {
        /* custom_field, personalized_field */
        setCustomTags(res.data.custom_field);
        setPersonalizedTags(res.data.personalized_field);
        let full_data = {
          personalized: res.data.personalized_field,
          custom_fields: res.data.custom_field,
        };
        localStorage.setItem(
          "pype_personalized_tags",
          JSON.stringify(full_data)
        );
      });
  };

  const renderTags = () => {
    let view = [];

    withOutCustomsFields.forEach((item, index) => {
      if (item.value !== "[[my_signature]]") {
        view.push(
          <MenuItem
            className='dropdownhelper-menuitem-class'
            value={item.value}
            key={index}
          >
            {item.label}
          </MenuItem>
        );
      }
    });

    if (personalizedTags.length > 0) {
      view.push(
        <MenuItem
          className='dropdownhelper-menuitem-class'
          value='__personalized__field__'
          disabled={true}
        >
          User Personalize Fields
        </MenuItem>
      );
      personalizedTags.forEach((item, index) => {
        view.push(
          <MenuItem
            className='dropdownhelper-menuitem-class'
            value={item.personalize_tag}
            key={`personalized-` + index}
          >
            {item.title}
          </MenuItem>
        );
      });
    }

    if (customTags.length > 0) {
      view.push(
        <MenuItem
          className='dropdownhelper-menuitem-class'
          value='__custom__field__'
          disabled={true}
        >
          User Custom Fields
        </MenuItem>
      );
      customTags.forEach((item, index) => {
        view.push(
          <MenuItem
            className='dropdownhelper-menuitem-class'
            value={item.personalize_tag}
            key={`custom-field-` + index}
          >
            {item.title}
          </MenuItem>
        );
      });
    }
    return view;
  };

  return (
    <div>
      <FormControl className='dmpc_3rd_tag_formControll'>
        <Select
          value={""}
          IconComponent={ExpandMoreIcon}
          onChange={(e) => onChange(e.target.value)}
          className={"dmpc_3rd_tag_s"}
          displayEmpty
          inputProps={{ className: "className__update" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem
            value={""}
            disabled
            className={"dropdownhelper-menuitem-class"}
          >
            Personalize tag
          </MenuItem>
          {renderTags()}
        </Select>
      </FormControl>
    </div>
  );
};
export default PersonalizedTag;
