import React, {Fragment, useEffect, useState} from "react";
import {
    Paper,
    Modal,
    makeStyles,
    CircularProgress,
    Backdrop
} from "@material-ui/core";
import Styles from "./a2pOnBoardStyles.module.css";
import WelcomeDlc from "./WelcomeDlc";
import AskRegion from "./AskRegion";
import ExitConsent from "./ExitConsent";
import EinConfirm from "./EinConfirm";
import AskVolume from "./AskVolume";
import RegistrationPricing from "./RegistrationPricing";
import RestrictSole from "./RestrictSole";

export const a2pOnBoard = {
    WELCOME_DLC: "WELCOME_DLC",
    ASK_REGION: "ASK_REGION",
    EXIT_CONSENT: "EXIT_CONSENT",
    EIN_CONFIRM: "EIN_CONFIRM",
    ASK_VOLUME: "ASK_VOLUME",
    REGISTRATION_PRICING: "REGISTRATION_PRICING",
    RESTRICT_SOLE: "RESTRICT_SOLE",
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const A2pDlcOnboardModal = ({ isOpen, data, onClose }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(null);
    const [selectedVolume, setSelectedVolume] = useState(null);
    const [isBack, setIsBack] = useState(false);

    useEffect(()=>{
        if(data && data.activeStep){
            setActiveStep(data.activeStep);
        }else {
            setActiveStep(a2pOnBoard.WELCOME_DLC);
        }
    },[data]);
    const handleClose = () => {
        onClose();
    };

    const getOnBoardBody = () => {
        switch (activeStep){
            case a2pOnBoard.WELCOME_DLC:
                return <WelcomeDlc data={data} onClose={handleClose} setActiveStep={setActiveStep}/>;
            case a2pOnBoard.ASK_REGION:
                return <AskRegion onClose={handleClose} setActiveStep={setActiveStep}/>;
            case a2pOnBoard.EXIT_CONSENT:
                return  <ExitConsent onClose={handleClose} setActiveStep={setActiveStep}/>;
            case a2pOnBoard.EIN_CONFIRM:
                return <EinConfirm setIsBack={setIsBack} data={data} onClose={handleClose} setActiveStep={setActiveStep}/>;
            case a2pOnBoard.RESTRICT_SOLE:
                return <RestrictSole data={data} onClose={handleClose} setActiveStep={setActiveStep}/>;
            case a2pOnBoard.ASK_VOLUME:
                return <AskVolume data={data} isBack={isBack} onClose={handleClose} setActiveStep={setActiveStep} setSelectedVolume={setSelectedVolume}/>;
            case a2pOnBoard.REGISTRATION_PRICING:
                return <RegistrationPricing data={data} onClose={handleClose} setActiveStep={setActiveStep} selectedVolume={selectedVolume}/>;
            default: 
                return "";
        }
    }

    return(
        <Fragment>
            <Modal
                open={isOpen && activeStep !== null && ((data && data.isDataLoading) ? !data.isDataLoading : true)}
                onClose={()=>{}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
                disableEnforceFocus
            >
                <Paper className={Styles.modalInner}>
                    {
                        getOnBoardBody()
                    }
                </Paper>
            </Modal>
            <Backdrop className={classes.backdrop} open={(data && data.isDataLoading)}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
}

export default A2pDlcOnboardModal;