import CanvaView from "./selectDesignPages/canva/CanvaView";
import Header from "./selectDesignPages/Header";
import TemplateViewContainer from "./selectDesignPages/TemplateViewContainer";
import UploadView from "./selectDesignPages/upload/UploadView";

const SelectDesign = ({selectDesignStep, handleDataChange, handleStepChange, selectSizeStep, type}) => {   

    return (
        <div className='dmpc_2nd_middle'>
            <Header handleDataChange={handleDataChange} from={selectDesignStep.from} />
            {selectDesignStep.from === "template" &&
            <TemplateViewContainer selectDesignStep={selectDesignStep} handleDataChange={handleDataChange} handleStepChange={handleStepChange} type={type} />
            }
            {selectDesignStep.from === "canva" &&
            <CanvaView selectDesignStep={selectDesignStep} handleDataChange={handleDataChange} handleStepChange={handleStepChange} selectSizeStep={selectSizeStep} type={type} />
            }
            {selectDesignStep.from === "upload" &&
            <UploadView selectDesignStep={selectDesignStep} handleDataChange={handleDataChange} handleStepChange={handleStepChange} selectSizeStep={selectSizeStep} type={type} />
            }
        </div>
    );

}
export default SelectDesign;