import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Button,
    Paper,
    Modal,
    makeStyles,
} from "@material-ui/core";
import Styles from "./a2pSoleCampaignModalStyles.module.css";
import CampaignDetails from "./CampaignDetails";
import SampleMessages from "./SampleMessages";
import EndUserConsent from "./EndUserConsent";
import GeneralInformation from "./GeneralInformation";
import Processing from "./Processing";
import ResponseMessage from "./ResponseMessage";
import {closeA2pModalApi, form10DlcLink, requestForSoleProprietorRegistrationApi} from "../../api/globalApi";
import Utils from "../../helpers/Utils";
import ConfirmPreview from "./ConfirmPreview";
import PreviewSampleMessage from "./PreviewSampleMessage";

const steps = [
    {
        label: "General Information",
        title: "A2P/10DLC Sole Proprietorship Registration - General Information",
    },
    {
        label: "Campaign Details",
        title: "A2P/10DLC Sole Proprietorship Registration - Campaign Details",
    },
    {
        label: "Sample Messages",
        title: "A2P/10DLC Sole Proprietorship Registration - Sample Messages",
    },
    {
        label: "End User Consent",
        title: "A2P/10DLC Sole Proprietorship Registration - End User Consent",
    }
];

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                borderWidth: "2px",
            }
        }
    };
});
const MultiStepperModal = ({ isOpen, onClose, data }) => {
    const inputScrollToViewRef = useRef([]);
    const width = window.innerWidth - 288;
    const height = window.innerHeight - 264;

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [hasError, setHasError] = useState("");
    const [hasSuccess, setHasSuccess] = useState("");
    const [response, setResponse] = useState(null);
    const [isFinal, setIsFinal] = useState(false);
    const [isNumberInvalid, setIsNumberInvalid] = useState(false);
    const [a2pDlcData, setA2pDlcData] = useState({
        firstName: "",
        lastName: "",
        verifiedAddress: "",
        city: "",
        state: "",
        zipCode: "",
        verifiedEmail: "",
        verifiedNumber: "",
        vertical: "",
        brandName: "",

        useCase: "SOLE_PROPRIETOR",
        useCaseDescription: "",

        sampleMessage1: "",
        sampleMessage2: "",

        messageFlow: "",
        optOutKeywords: "CANCEL,END,QUIT,UNSUBSCRIBE,STOP,STOPALL",
        optOutMessage: "You have successfully been unsubscribed. You will not receive any more messages from this number. Reply START to resubscribe.",
        helpKeywords: "HELP,INFO",
        helpMessage: "Reply STOP to unsubscribe. Msg&Data Rates May Apply.",

        hasEmbeddedLinks: "False",
        hasEmbeddedPhone: "False",
        optOutLanguage1:"Reply stop to unsubscribe",
        optOutLanguage2:"Reply stop to unsubscribe",
        senderIdentification1:"Hi this is {first-name} from {my-company}",
        senderIdentification2:"Hi this is {first-name} from {my-company}",
    });
    const [a2pDlcError, setA2pDlcError] = useState({
        firstName: "",
        lastName: "",
        verifiedAddress: "",
        city: "",
        state: "",
        zipCode: "",
        verifiedEmail: "",
        verifiedNumber: "",
        vertical: "",
        brandName: "",

        useCase: "",
        useCaseDescription: "",

        sampleMessage1: "",
        sampleMessage2: "",

        messageFlow: "",
        optOutKeywords: "",
        optOutMessage: "",
        helpKeywords: "",
        helpMessage: "",

        senderIdentification1:"",
        senderIdentification2:"",
        optOutLanguage1:"",
        optOutLanguage2:""
    });
    const [isPreviewed, setIsPreviewed] = useState(false);

    useEffect(()=>{
        let newData = {...a2pDlcData};

        if (Utils.getAccountData("companyName")){
            newData.senderIdentification1 = newData.senderIdentification1.replace("{my-company}", Utils.getAccountData("companyName"))
            newData.senderIdentification2 = newData.senderIdentification2.replace("{my-company}", Utils.getAccountData("companyName"))
        }

        if (Utils.getAccountData("fullName")){
            newData.senderIdentification1 = newData.senderIdentification1.replace("{first-name}", Utils.getAccountData("fullName"))
            newData.senderIdentification2 = newData.senderIdentification2.replace("{first-name}", Utils.getAccountData("fullName"))
        }

        let messageFlow = "End users opt-in to receive messages from us by completing a form on our landing page. Before submitting the form, contacts are presented with a notice that by checking a box and submitting the form they are agreeing to be contacted by our business via text message and other communication mediums.\n" +
            "\n" +
            "You can view our landing page with the opt-in form here:\n" +
            "{your-form-link}\n\n" +
            "Our privacy policy and terms of use are located in links at the bottom of the landing page.";

        form10DlcLink()
            .then((res)=>{
                if (res && res.data && res.data.link){
                    newData.messageFlow = messageFlow.replace("{your-form-link}", res.data.link);
                }
            })
            .catch((err)=>{

            });

        setA2pDlcData(newData);
    },[]);

    const validateForm = () => {
        if (a2pDlcData) {
            if (activeStep === 0) {
                let message = {
                    firstName: "",
                    lastName: "",
                    verifiedAddress: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    verifiedEmail: "",
                    verifiedNumber: "",
                    brandName: "",
                    vertical: ""
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if (key === "firstName" && value.trim() === "") {
                        message.firstName = "Firstname is required!";
                    } else if (key === "lastName" && value.trim() === "") {
                        message.lastName = "Lastname is required!";
                    } else if (key === "verifiedAddress" && value.trim() === "") {
                        message.verifiedAddress = "Verified Address is required!";
                    } else if (key === "city" && value.trim() === "") {
                        message.city = "City is required!";
                    } else if (key === "state" && value.trim() === "") {
                        message.state = "State is required!";
                    } else if (key === "zipCode" && value.trim() === "") {
                        message.zipCode = "Postal/Zip Code is required!";
                    } else if (key === "verifiedEmail" && value.trim() === "") {
                        message.verifiedEmail = "Email Address is required!";
                    } else if (key === "verifiedEmail" && !Utils.isValidEmail(value)) {
                        message.verifiedEmail = "Email Address must be valid!";
                    } else if (key === "verifiedNumber" && value.trim() === "") {
                        message.verifiedNumber = "Mobile Number is required!";
                    } else if (key === "verifiedNumber" && !Utils.isValidMobileNumber(value)) {
                        message.verifiedNumber = "Mobile Number must be valid!";
                    } else if (key === "brandName" && value.trim() === "") {
                        message.brandName = "Brand Name is required!";
                    } else if (key === "vertical" && value.trim() === "") {
                        message.vertical = "Business Type is required!";
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }

            if (activeStep === 1) {
                let message = {
                    useCase: "",
                    useCaseDescription: "",
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if (key === "useCaseDescription" && (value.length < 40 || value.length > 4096)) {
                        message.useCaseDescription = "Campaign Use Case Description can not be less than 40 characters and more than 4096 characters!";
                    } else if (key === "useCaseDescription" && value.trim() === "") {
                        message.useCaseDescription = "Campaign Use Case Description is required!";
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }
                    return false;
                }
                return true;
            }

            if (activeStep === 2) {
                let message = {
                    senderIdentification1:"",
                    senderIdentification2:"",
                    sampleMessage1: "",
                    sampleMessage2: "",
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if(key === "senderIdentification1" && value.trim() === ""){
                        message.senderIdentification1="Sender Identification 1 is required!";
                    } else if(key === "senderIdentification2" && value.trim() === ""){
                        message.senderIdentification2="Sender Identification 2 is required!";
                    } else if (key === "sampleMessage1" && value.trim() === "") {
                        message.sampleMessage1 = "Sample Message 1 is required!";
                    } else if (key === "sampleMessage1" && (value.length < 20 || value.length > 1024)) {
                        message.sampleMessage1 = "Sample Message 1 can not be less than 20 characters and more than 1024 characters!";
                    } else if (key === "sampleMessage2" && value.trim() === "") {
                        message.sampleMessage2 = "Sample Message 2 is required!";
                    } else if (key === "sampleMessage2" && (value.length < 20 || value.length > 1024)) {
                        message.sampleMessage2 = "Sample Message 2 can not be less than 20 characters and more than 1024 characters!";
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }
                    return false;
                }
                return true;
            }

            if (activeStep === 3) {
                let message = {
                    messageFlow: "",
                    optOutKeywords: "",
                    optOutMessage: "",
                    helpKeywords: "",
                    helpMessage: "",
                };

                Object.entries(a2pDlcData).map(([key, value]) => {
                    if (key === "messageFlow" && value.trim() === "") {
                        message.messageFlow = "Consent Message Sample is required!";
                    } else if (key === "messageFlow" && (value.length < 40 || value.length > 2084)) {
                        message.messageFlow = "Message Flow can not be less than 40 characters and more than 2084 characters!";
                    } else if (key === "optOutKeywords" && value.trim() === "") {
                        message.optOutKeywords = "Opt Out Keywords is required!";
                    } else if (key === "optOutKeywords" && value.split(",").length > 0) {
                        const helperKeywordArray = value.split(",");

                        helperKeywordArray.forEach((keyword) => {
                            let index = keyword.indexOf(" ");
                            if (keyword === "") {
                                message.optOutKeywords = "Remove trailing comma from the Opt-out keywords!";
                            } else if (keyword.length > 255) {
                                message.optOutKeywords = "Each Opt-out keyword must be less then 255!";
                            } else if (index >= 0) {
                                message.optOutKeywords = "Opt-out Keyword cannot contain white-space!";
                            } else if (keyword.includes('_') || keyword.includes('-')) {
                                message.optOutKeywords = "Opt-out Keyword must contain either alphanumeric or accented characters!";
                            }
                        });
                    } else if (key === "optOutMessage" && value.trim() === "") {
                        message.optOutMessage = "Opt-out Message is required!";
                    } else if (key === "optOutMessage" && (value.length < 20 || value.length > 320)) {
                        message.optOutMessage = "Opt-out Message  can not be less than 20 characters and more than 320 characters!";
                    } else if (key === "helpKeywords" && value.trim() === "") {
                        message.helpKeywords = "Help Keywords is required!";
                    } else if (key === "helpKeywords" && value.split(",").length > 0) {
                        const helperKeywordArray = value.split(",");

                        helperKeywordArray.forEach((keyword) => {
                            let index = keyword.indexOf(" ");
                            if (keyword === "") {
                                message.helpKeywords = "Remove trailing comma from the Help keywords!";
                            } else if (keyword.length > 255) {
                                message.helpKeywords = "Each Help keyword must be less then 255!";
                            } else if (index >= 0) {
                                message.helpKeywords = "Help Keyword cannot contain white-space!";
                            } else if (keyword.includes('_') || keyword.includes('-')) {
                                message.helpKeywords = "Help Keyword must contain either alphanumeric or accented characters!";
                            }
                        });
                    } else if (key === "helpMessage" && value.trim() === "") {
                        message.helpMessage = "Help Message is required!";
                    } else if (key === "helpMessage" && (value.length < 20 || value.length > 320)) {
                        message.helpMessage = "Help Message  can not be less than 20 characters and more than 320 characters!";
                    }
                });

                if (message && message !== {} && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }
                    return false;
                }
                return true;
            }
        }
    };

    const showPreview = () => {
        PreviewSampleMessage({
            formData: a2pDlcData,
            setIsPreviewed: setIsPreviewed,
        });
    };

    const showConfirmPreview = () => {
        ConfirmPreview({
            onSubmit: () => {
                showPreview();
            }
        });
    };

    const handleNext = () => {

        if (!validateForm()) {
            window.showNotification("Error", "Please fill the form correctly. For more details check the form.");
            return;
        }
        if (activeStep === 2 && !isPreviewed) {
            showConfirmPreview();
            return;
        }

        if (isNumberInvalid) {
            window.showNotification("Error", "Please fill the form correctly. For more details check the form.");
            return;
        }

        if (activeStep === (steps.length - 1)) {
            return;
        }
        if (activeStep === (steps.length - 2)) {
            setIsFinal(true);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        if (isFinal) {
            setIsFinal(false);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseForOneDay = async () => {
        onClose();
        await closeA2pModalApi();
    };

    const handleChangeA2pDlcData = (name, value) => {
        if (isNumberInvalid && name === "verifiedNumber") {
            setIsNumberInvalid(false);
        }
        setA2pDlcData({ ...a2pDlcData, [name]: value });
    };

    const handleErrorCallback = (name, message) => {
        setA2pDlcError({ ...a2pDlcError, [name]: message });
    };

    const handleSubmitInformation = () => {

        if (!validateForm()) {
            return;
        }

        if (submitting) {
            return;
        }
        setSubmitting(true);

        let form_data = {
            firstName: a2pDlcData.firstName,
            lastName: a2pDlcData.lastName,
            verifiedAddress: a2pDlcData.verifiedAddress,
            city: a2pDlcData.city,
            state: a2pDlcData.state,
            zipCode: a2pDlcData.zipCode,
            verifiedEmail: a2pDlcData.verifiedEmail,
            verifiedNumber: a2pDlcData.verifiedNumber,
            description: a2pDlcData.useCaseDescription,
            vertical: a2pDlcData.vertical,
            brandName: a2pDlcData.brandName,
            messageFlow: a2pDlcData.messageFlow,
            optOutKeywords: a2pDlcData.optOutKeywords,
            optOutMessage: a2pDlcData.optOutMessage,
            sampleMessage1: `${a2pDlcData.senderIdentification1}\n${a2pDlcData.sampleMessage1}\n${a2pDlcData.optOutLanguage1}`,
            sampleMessage2: `${a2pDlcData.senderIdentification2}\n${a2pDlcData.sampleMessage2}\n${a2pDlcData.optOutLanguage2}`,
            helpKeywords: a2pDlcData.helpKeywords,
            helpMessage: a2pDlcData.helpMessage,
            usAppToPersonUsecase: a2pDlcData.useCase,
            hasEmbeddedLinks: a2pDlcData.hasEmbeddedLinks,
            hasEmbeddedPhone: a2pDlcData.hasEmbeddedLinks,
            from: "campaign_registration_only_from_modal"
        }
        requestForSoleProprietorRegistrationApi(form_data).then(res => {
            let response = res.data;
            if (response.success) {
                setHasSuccess("Successfully submitted the form.");
                setResponse(response);
                if (data.callback){
                    data.callback();
                }
            } else {
                if (response.message) {
                    if (response.data && response.data?.number === "INVALID") {
                        setA2pDlcError({ ...a2pDlcError, verifiedNumber: response.message });
                        setIsNumberInvalid(true);
                    }
                    setHasError(response.message);
                } else {
                    setHasError("Can not register 10DLC information!");
                }
            }
            setSubmitting(false);
        }).catch(error => {
            setSubmitting(false);
            console.log(error);
        })
    };

    const handleRedirectHelp = () => {

        if (Utils.getAccountData('agencyId') === 1) {
            return `https://softwarehelp.freshdesk.com/support/solutions/articles/66000517994-understanding-a2p-10dlc-registration`;
        }
        return `${process.env.REACT_APP_CDN_LINK}asset/files/releases/1.0.0/10dlc-help.pdf`;
    };

    const getStepBody = () => {
        switch (activeStep) {
            case 0:
                return <GeneralInformation
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />;
            case 1:
                return <CampaignDetails
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />;
            case 2:
                return <SampleMessages
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                    setIsPreviewed={setIsPreviewed}
                    isPreviewed={isPreviewed}
                    showPreview={showPreview}
                />;
            case 3:
                return <EndUserConsent
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                />
            default:
                return;
        }
    };

    const getStepTitle = (index) => {
        return steps[index]?.title;
    };

    const handleAfterResponseClose = ({ onlyClose = true, redirect = false }) => {
        if (onlyClose && redirect) {
            if (response.success) {
                window.location.reload();
            }
        }
        else if (onlyClose) {
            setSubmitting(false);
            setHasError("");
            setHasSuccess("");
            setActiveStep(0);
            setIsFinal(false);
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => { }}
            aria-labelledby="multi-stepper-modal-title"
            aria-describedby="multi-stepper-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
            disableEnforceFocus
        >
            <Paper className={Styles.modalInner}>
                {
                    submitting ?
                        <Processing
                            style={{ width: width, height: (height + 222) }}
                        /> :
                        (
                            (!submitting && (hasError || hasSuccess || response)) ?
                                <ResponseMessage
                                    style={{ width: width, height: (height + 222) }}
                                    hasError={hasError}
                                    hasSuccess={hasSuccess}
                                    response={response}
                                    handleClose={handleAfterResponseClose}
                                /> :
                                <Fragment>
                                    <div className={Styles.headerWrapper}>
                                        {
                                            steps.map((step, index) => (
                                                <div className={`${Styles.tabStepper} ${activeStep === index ? Styles.tabStepperActive : ""}`}>
                                                    <span className={Styles.tabStepperLabel}>{(index + 1)}. {step.label}</span>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className={Styles.modalBody}>
                                        <div className={Styles.modalBodyHeader}>
                                            <div className={Styles.modalBodyTitleWrapper}>
                                                <h1 className={Styles.modalBodyTitle}>{getStepTitle(activeStep)}</h1>
                                            </div>
                                            <div className={Styles.modalBodyTools}>
                                                <Button size="small" target="_blank" href={handleRedirectHelp()} className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                                                    ACCESS HELP GUIDE
                                                </Button>
                                                <Button size="small" onClick={handleCloseForOneDay} className={`${classes.root} ${Styles.redButton}`} variant="outlined">
                                                    EXIT
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{ width: width, height: height }} className={Styles.bodyContainer}>
                                            {getStepBody()}
                                        </div>
                                    </div>

                                    <div className={Styles.modalFooter}>
                                        {
                                            activeStep !== 0 &&
                                            (
                                                <Button size="large" onClick={handleBack} className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                                                    Back
                                                </Button>
                                            )
                                        }
                                        <Button size="large" disabled={submitting} onClick={isFinal ? handleSubmitInformation : handleNext} className={`${classes.root} ${Styles.overwriteButton}`} variant="contained">
                                            {isFinal ? (submitting ? "Submitting Information..." : "Submit Information") : "Next"}
                                        </Button>
                                    </div>
                                </Fragment>
                        )
                }
            </Paper>
        </Modal>
    );
};

export default MultiStepperModal;