const defaultStyle ={
    control: {
        backgroundColor: '#fff',
        fontSize: 12,
        fontWeight: 'normal',
    },

    highlighter: {
        overflow: 'hidden',
    },

    input: {
        padding: 9,
        height: '200px',
        outline: 0,
        border: 0,
    },

    '&singleLine': {
        control: {
            display: 'inline-block',
            width: 130,
        },

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },

        input: {
            padding: 1,
            border: '2px inset',
        },
    },

    '&multiLine': {
        control: {
            border: '1px solid #ebedf2',
            borderRadius: 4,
        },

        highlighter: {
            padding: 9,
            borderColor: '#6564cf',
            '&focused': {
                borderColor: '#6564cf',
            },
        },

        input: {
            paddingTop: 15,
            paddingBottom: 15,
            borderColor: '#ced4da',
            minHeight: '200px',
            outline: 0,
            border: 0,
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 10,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
};
export default defaultStyle;

