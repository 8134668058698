import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import _Helper, { getAccountData } from "../assets/Helper";
import icons from "../assets/icons";
import NoteComponent from '../assets/noteNew/NoteComponent';
import TextEditorTinyMCE from "../assets/TextEditorTinyMCE";
import TimezoneConversation from '../assets/TimezoneConversion';
import { getConversationApi, storeNoteApi, updateNoteApi } from "../redux/contactDetailApi";
import Utils from "../../../helpers/Utils";

const mapStateToProps = state => ({
//   dealContact: state.dealReducer.dealContact,

  contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({


//   storeNote: (params, callback) => dispatch(storeNote(params, callback)),
//   updateNote: (params, callback) => dispatch(updateNote(params, callback)),
//   getConversation: (params, callback) => dispatch(getConversation(params, callback)),
})


const AddNoteApp = (props) => {
    const { contactId, contactDetails } = props.contactDetailReducer

    const editorRef = React.createRef()
    const [deal, setDeal] = useState(null)
    const [noteText, setNoteText] = useState('')
    const [sumitProcess, setSumitProcess] = useState(false)
    const [noteList, setNoteList] = useState([])
  
    // const [updateNoteFlag, setUpdateNoteFlag] = useState(false)
    const [updateData, setUpdateData] = useState(null)
    const [editNote, setEditNote] = useState(false)
  
    useEffect(() => {
      setDeal(contactDetails)
      if(contactId != null){
        fetchNotes()
      }
    }, [contactDetails])
  
    const fetchNotes = () => {
        getConversationApi({
            contact_id: contactId,
            content_type: 16,
            skip: 0
        }).then(res => {
            let response = res.data
            setNoteList(response)
        })
    }
  
    let name = ''
    // addFlag = false
    if(JSON.stringify(contactDetails) != '{}'){
        if(!_Helper.isEmpty(contactDetails.first_name) &&  !_Helper.isEmpty(contactDetails.last_name)){
          name = contactDetails.first_name + ' ' + contactDetails.last_name
        }
        else if(!_Helper.isEmpty(contactDetails.first_name)){
            name = contactDetails.first_name
        }
        else if(!_Helper.isEmpty(contactDetails.last_name)){
            name = contactDetails.last_name
        }
        if(name.trim() === ''){
            // addFlag = true
            if(contactDetails.email !== ''){
                name = contactDetails.email
            }
            else{
                name = _Helper.formatPhoneNumber(contactDetails.number)
            }
        }
    }
  
    const handleAddNoteSubmit = () => {
      if(sumitProcess){
        return
      }
      if(noteText.trim() == ''){
        window.showNotification("WARNING", 'Note is required');
        return
      }
      setSumitProcess(true)
  
      if(editNote){
        updateNoteApi({
          contact_id: contactId,
          note: noteText,
          origin: 4,
          id: updateData.id
        }).then(res => {
            let response = res.data
            if(response.status === 'success'){
                fetchNotes(deal)
                setSumitProcess(false)
                setNoteText('')
                setEditNote(false)
            }
        })
      }
      else{
        storeNoteApi({
            contact_id: contactId,
            note: noteText,
            origin: 4
        }).then(res => {
            let response = res.data
            if(response.status == 'success'){
                fetchNotes(deal)
                setSumitProcess(false)
                setNoteText('')
            }
        })
      }
    }
  
    const updateNote = (item) => {
      // setUpdateNoteFlag(true)
      setUpdateData(item)
      setNoteText(item.message)
      setEditNote(true)
      editorRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleClearNote = () => {
      setUpdateData(null)
      setNoteText("")
      setEditNote(false)
    }
  
    return(
      <>
      {/* <div className="d-flex align-items-center noteHeader">
        <div className="note__avatar note__avatar-alt">
          {name[0]}
        </div>
        <div className="userInfo">
          <h3 className="trigger__modal_title">{name}</h3>
        </div>
      </div> */}
      
      <div className="addNote__message" ref={editorRef}>
        {/* <textarea placeholder="Type to add a note" rows={5} onChange={(e)=>setNoteText(e.target.value)} value={noteText}></textarea> */}
        <NoteComponent 
            value={noteText} 
            onChange={(value)=>setNoteText(value)}
        />
        {/* <TextEditorTinyMCE height="300" textEditorValue={noteText} changeTextInEditor={setNoteText} /> */}
        <div className='edit__and__add__note__section'>
          <button className="note__blue_btn accent--bg--color" onClick={handleAddNoteSubmit}>
          {sumitProcess ? <MoreHorizIcon fontSize="small" /> :icons.whiteCirclePlusIcon} {editNote ? (sumitProcess ? 'Editing' :'Edit') : (sumitProcess ? 'Adding' : 'Add')} Note
          </button>
          {
            editNote &&
            <button className="clear__note__btn" onClick={handleClearNote}>Clear Note </button>
          }
        </div>
      
      </div>
      <div className="addNote__lists awesome__scroll_bar">
        {noteList.length == 0 &&
        <div>No note added !</div>
        }
  
        {noteList.map((item, index) => {
          return(
            <NoteItem data={item} key={item.id} updateNote={updateNote}/>
          )
        })}
        
      </div>
      </>
    )
}
export const AddNote = connect(mapStateToProps, mapDispatchToProps)(AddNoteApp);

const NoteItem = (props) => {
  const getUserTimezone = getAccountData('userTimezoneRaw')
  let userTimezone = (getUserTimezone && getUserTimezone != null) ? getUserTimezone : 'UTC'

  return(
      <div className="noteList">
      <div className="noteList_header d-flex align-items-center justify-content-between">
          {/* <span className="editIcon leftAlignIcon">{icons.editIcon}</span> */}
          <div className="d-flex align-items-center">
              {props.data.user.full_name}
              {
                  Utils.getAccountData("note_edit_access") == "1" ? (
                      <span className="editIcon ml-4" onClick={() => props.updateNote(props.data)}>{icons.editIcon} Edit</span>
                  ) : null
              }
          </div>
          {/* <span className="note__time">{moment(props.data.created_at).format('MMM DD, YYYY - hh:mm:ss A')}</span> */}
          <span className="note__time">{TimezoneConversation(props.data.created_at, 'UTC', userTimezone, 'LLL')}</span>
      </div>
          <div className="noteList_content" dangerouslySetInnerHTML={{ __html: _Helper.getHtmlText(props.data.message) }} />
      </div>
  )
}