import * as ACTION from "./contactDetailActionTypes";

/* middlware actions */
export const getContactDetails = (payload, callback) => ({
    type: ACTION.FETCH_CONTACT_DETAILS,
    payload: {payload, callback}
});

/* reducer action */
export const _updateContactId = payload =>({
    type: ACTION.UPDATE_CONTACT_ID,
    payload: payload
})

export const _updateContactDetails = payload =>({
    type: ACTION.UPDATE_CONTACT_DETAILS,
    payload: payload
})

export const _updateStateField = payload =>({
    type: ACTION.UPDATE_STATE_FIELD,
    payload: payload
})