const isEmpty = (text) => {
   if(text != undefined && text!= null && text != '' && text.trim() != ''){
       return false
   }else{
       return true
   }
}

const isValidText = (text) => {
   if(text != undefined && text!= null){
       return true
   }
   return false
}

const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

const sendRequestBackend = (method, url, postData) => {
    return fetch(url, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getCookie(process.env.REACT_APP_ACCESS_TOKEN)
        },
        referrerPolicy: 'origin',
        body: JSON.stringify(postData)
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData
        })
        .finally(() => { })
        .catch(error =>{
            console.log("url", error)
            return false;
        })
}

const Helper = {
   isEmpty,
   isValidText,
   getCookie,
   sendRequestBackend
}
export default Helper;