import Appointment from './moreInfo/appointment/Appointment';
import Campaign from './moreInfo/campaign/Campaign';
import CustomField from './moreInfo/custom-field/CustomField';
import Deal from './moreInfo/deal/Deal';
import Delete from './moreInfo/delete/Delete';
import Source from './moreInfo/source/Source';
import Tags from './moreInfo/tags/Tags';
import Task from './moreInfo/task/Task';

const MoreInfo = props => {

    return(
        <div className="g-more-info-content">
            <div className="g-more-info-left-side">
                <CustomField />
                <Campaign />
                <Appointment />
                <Deal />
            </div>
            <div className="g-more-info-right-side">
                <Tags />
                <Source />
                <Task />
                <Delete onClose={props.onClose}/>
            </div>
        </div>
    )
}
export default MoreInfo;