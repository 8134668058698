import React from "react";
import {
  StyledAskOptionButtonWrapper,
  StyledAskQuestionWrapper,
  StyledCancelButton,
  StyledConnectEmailButton
} from "./ConnectEmailStyles";

const AskOption = ({ goConnect, goToStep, activeStep }) => {
  return(
    <StyledAskQuestionWrapper>
      <h5>Do you want to connect your personal email?</h5>
      <p>This email will be used to sending emails. You can also add/remove later from settings section</p>
      <StyledAskOptionButtonWrapper>
        <StyledCancelButton onClick={() => goToStep(activeStep+1)}>
          No, Thanks
        </StyledCancelButton>
        <StyledConnectEmailButton onClick={goConnect}>
          Connect
        </StyledConnectEmailButton>
      </StyledAskOptionButtonWrapper>
    </StyledAskQuestionWrapper>
  );
};

export default AskOption;