import HttpRequest from "../../api/HttpRequest";
import { sendRequest, sendRequestBackEnd } from "../../api/rootApi";
import Api from "./Api";

export const fetchContactInfo = async (payload) => {
    return sendRequest("post", Api.details, payload);
}

export const fetchContactDetails = async (payload) => {
    return sendRequest("post", Api.details, payload);
}

export const changeLeadOwner = async (payload) => {
    return sendRequest("post", Api.changeLeadOwner, payload);
}

export const getAllTeamUsers = async (payload) => {
    return sendRequest("post", Api.getAllTeamUsers, payload);
}

export const getContacts = async (payload) => {
    return sendRequest("post", Api.getSearchContacts, payload);
}

export const getCollaborators = async (payload) => {
    return sendRequest("post", Api.getCollaborators, payload);
}

export const deleteCollaborator = async (payload) => {
    return sendRequest("post", Api.deleteCollaborator, payload);
}

export const saveCollaborator = async (payload) => {
    return sendRequest("post", Api.saveCollaborator, payload);
}

export const getUserGroup = async (payload) => {
    return sendRequest("post", Api.getUserGroup, payload);
}

export const changeDealStatus = async (payload) => {
    return sendRequest("post", Api.changeDealStatus, payload);
}


export const addNewDeal = async (payload) => {
    return sendRequest("post", Api.addNewDeal, payload);
}

export const updateDeal = async (payload) => {
    return sendRequest("post", Api.updateDeal, payload);
}

export const getStageList = async (payload) => {
    return sendRequest("post", Api.stageList, payload);
}

export const getCampaignList = async (payload) => {
    return sendRequest("post", Api.campaignList, payload);
}

export const runCampaign = async (payload) => {
    return sendRequest("post", Api.runCampaign, payload);
}

export const pauseCampaign = async (payload) => {
    return sendRequest("post", Api.pauseCampaign, payload);
}

export const unsubscribeCampaign = async (payload) => {
    return sendRequest("post", Api.unsubscribeCampaign, payload);
}

export const moveToCampaign = async (payload) => {
    return sendRequest("post", Api.moveToCampaign, payload);
}

export const getRunningCampaignListNotInCurrentCampaign = async (payload) => {
    return sendRequest("post", Api.getRunningCampaignListNotInCurrentCampaign, payload);
}

export const changeContactSource = async (payload) => {
    return sendRequest("post", Api.changeContactSource, payload);
}

export const getSourceListDropdown = async (payload) => {
    return sendRequest("post", Api.sourceListDropdown, payload);
}

export const updateSingleCustomField = async (payload) => {
    return sendRequest("post", Api.updateSingleCustomField, payload);
}

export const addTags = async (payload) => {
    return sendRequest("post", Api.addTags, payload);
}

export const getTagList = async (payload) => {
    return sendRequest("post", Api.tagList, payload);
}

export const getSmsTemplate = async (payload) => {
    return sendRequest("post", Api.smsTemplate, payload);
}

export const getVoiceTemplate = async (payload) => {
    return sendRequest("post", Api.voiceTemplate, payload);
}

export const getActivityList = async (payload) => {
    return sendRequest("post", Api.getActivityList, payload);
}

export const taskMarkAsDone = async (payload) => {
    return sendRequest("post", Api.contactMarkAsDone, payload);
}

export const saveActivity = async (payload) => {
    return sendRequest("post", Api.saveActivity, payload);
}

export const getUserProfile = async (payload) => {
    return sendRequest("post", Api.userProfile, payload);
}

export const sendMail = async (payload) => {
    return sendRequest("post", Api.sendMail, payload);
}

export const getEmailTemplate = async (payload) => {
    return sendRequest("post", Api.emailTemplate, payload);
}

export const getTimeline = async (payload) => {
    return sendRequest("post", Api.getTimeline, payload);
}

export const updateTimelineNote = async (payload) => {
    return sendRequest("post", Api.updateTimelineNote, payload);
}

export const removeFile = async (payload) => {
    return sendRequest("post", Api.removeFile, payload);
}

export const getTimelineLogs = async (payload) => {
    return sendRequest("post", Api.getTimelineLogs, payload);
}

export const getUserEmailList = async (payload) => {
    return sendRequest("post", Api.userEmailList, payload);
}

export const sendMessage = async (payload) => {
    return sendRequest("post", Api.sendMessage, payload);
}

export const getCustomFields = async (payload) => {
    return sendRequest("post", Api.getCustomFields, payload);
}

export const updateContactInfo = async (payload)=>{
    return sendRequest("post", Api.updateContactInfo, payload);
}
export const updateAditionalEmailPhone = async (payload)=>{
    return sendRequest("post", Api.updateAditionalEmailPhone, payload);
}
export const getContactTagList = async (payload)=>{
    return sendRequest("post", Api.getContactTagList, payload);
}
export const deleteContactTag = async (payload)=>{
    return sendRequest("post", Api.deleteContactTag, payload);
}

export const getMailCategory = async (payload)=>{
    return sendRequest("post", Api.getMailCategory, payload);
}

export const getMailTemplate = async (payload)=>{
    return sendRequest("post", Api.getMailTemplate, payload);
}

export const createMailTemplate = async (payload)=>{
    return sendRequest("post", Api.createMailTemplate, payload);
}

export const getUserVirtualNumberList = async (payload)=>{
    return sendRequest("post", Api.getUserVirtualNumberList, payload);
}

export const emailContactToFriend = async (payload)=>{
    return sendRequest("post", Api.emailContactToFriend, payload);
}

export const getGoogleMapResource = async (payload)=>{
    return sendRequest("post", Api.getGoogleMapResource, payload);
}

export const editContactData = async (payload)=>{
    return sendRequest("post", Api.editContactData, payload);
}

export const addNote = async (payload)=>{
    return sendRequest("post", Api.addNote, payload);
}

export const producePDF = async (payload)=>{
    return sendRequest("post", Api.producePDF, payload);
}

export const createCardBroadcast = async (payload)=>{
    return sendRequest("post", Api.addCardBroadcastToContact, payload);
}

export const getThirdPartyService = async (payload)=>{
    return sendRequest("post", Api.getThirdPartyInfo, payload);
}

export const getContactTimeline = async (payload)=>{
    return sendRequest("post", Api.getContactTimeline, payload);
}

export const fetchVideoFolders = async (payload) => {
    return sendRequest("post", Api.fetchVideoFolders, payload);
}

export const fetchVideos = async (payload) => {
    return sendRequest("post", Api.fetchVideos, payload);
}

export const deleteVideo = async (payload) => {
    return sendRequest("post", Api.deleteVideo, payload);
}

export const getMessageTemplate = async (payload) => {
    return sendRequest("post", Api.getMessageTemplate, payload);
}

export const getAllContacts = async (payload) => {
    return sendRequest("post", Api.getAllContacts, payload);
}

export const getAllCampaignForDropdown = async (payload) => {
    return sendRequest("post", Api.getCampaignListForDropdown, payload);
}

export const getSourceListDropdownForContactTable = async (payload) => {
    return sendRequest("post", Api.sourceListDropdownForContactTable, payload);
}

export const getSubUserListForContactlist = async (payload) => {
    return sendRequest("post", Api.subUserListForContactDropdown, payload);
}


export const getFileListForDropdown = async (payload) => {
    return sendRequest("post", Api.fileListForDropdown, payload);
}

export const removeBulkContact = async (payload) => {
    return sendRequest("post", Api.removeBulkContact, payload);
}

export const getContactListForDropdown = async (payload) => {
    return sendRequest("post", Api.getAllList, payload);
}

//Single contact
export const addContactInCampaign = async (payload) => {
    return sendRequest("post", Api.addContactInCampaign, payload);
}

//Multiple contacts
export const addContactsInCampaign = async (payload) => {
    return sendRequest("post", Api.addContactsInCampaign, payload);
}

//getUserInfo
export const getUserInfo = async (payload) => {
    return sendRequest("post", Api.getUserInfo, payload);
}

//get user Custom Field
//getUserInfo
export const getUserCustomField = async (payload) => {
    return sendRequest("post", Api.getCustomFields, payload);
}

export const submitSingleContact = async (payload) => {
    return sendRequest("post", Api.addSingleContact, payload);
}

export const submitSingleContactV2 = async (payload) => {
    return sendRequest("post", Api.addSingleContactV2, payload);
}

//get pipeline list
export const getPipelineList = async (payload) => {
    return sendRequest("post", Api.getPipelineList, payload);
}

//get Stage list
export const getStageListByPipeline = async (payload) => {
    return sendRequest("post", Api.getAllStageByPipeline, payload);
}


//import page apis
export const getContactLimit = async (payload) => {
    return sendRequest("post", Api.getContactLimit, payload);
}

export const getFileLists = async (payload) => {
    return sendRequest("post", Api.getFileLists, payload);
}

export const deleteImportFiles = async (payload) => {
    return sendRequest("post", Api.deleteImportFiles, payload);
}

export const contactImport = async (payload,config = []) => {
    return sendRequest("post", Api.contactImport, payload,config);
}

export const campaignFileAssign = async (payload) => {
    return sendRequest("post", Api.fileAssignToCampaign, payload);
}

export const getAllCampaignWithVN = async (payload) => {
    return sendRequest("post", Api.allCampaignWithVn, payload);
}

export const getInvalidContacts = async (payload) => {
    return sendRequest("post", Api.getInvalidContacts, payload);
}

export const fetchContactListDropdown = async (payload) => {
    return sendRequest("post", Api.getContactList, payload);
}

export const saveContactList = async (payload) => {
    return sendRequest("post", Api.saveContactList, payload);
}

export const assignBulkStage = async (payload) => {
    return sendRequest("post", Api.assignBulkStageForContacts, payload);
}
export const exportCSVFromContactlist = async (payload) => {
    return sendRequest("post", Api.exportCSVFromContactlist, payload);
}
export const getZipCodeByPlace = async (payload) => {
    return sendRequest("post", Api.getZipCodeByPlace, payload);
}

export const contactLimitRequest = (payload) => {
    return sendRequest("post", Api.contactLimitRequest, payload);
}

export const isUserPackageInTrailApi = (payload) => {
    return sendRequest("post", Api.isUserPackageInTrial, payload);
}

export const getCustomMenuData = async (payload) => {
    return sendRequest("post", Api.getCustomMenuData, payload);
}

export const updateContactLeadType = (payload) => {
    return HttpRequest.post(Api.updateContactLeadTypeApi, payload);
}

export const getContactSmartFormEntries = async (contactId) => {
    return sendRequestBackEnd(
      'get',
      Api.getContactSmartFormEntries + `?contactId=${contactId}`
    )
  }