import React, { useContext, useEffect, useState } from 'react';
import { connect } from "react-redux";

import CreateNewCardTemplate from './CreateNewCardTemplate';
import SecondHalfStep from './SecondHalfStep'
import { getCategoryList, storeCategoryList, storeMultipleReducers, storeSelectedCategoryId, storeSelectedTemplate } from './redux/cardBroadcastAction';
import Helper from './assets/Helper';
import { Skeleton } from '@material-ui/lab';
import { getMailTemplate } from './redux/cardBroadcastApi';
import Loading from './assets/Loading';
import { DropdownHelper } from './assets/DropdownHelper';
import { CardMultiStepContext } from './CardMultiStepForm';
import Utils from '../../helpers/Utils';


const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;
const THANKSIO_GIFT = 11;

const STEPNO = 2
const NEXTSTEPNO= 3
const HALFSTEPNO = 2.5

const SecondStep = (props) => {

   const cardMultiStepContext = useContext(CardMultiStepContext)

   const [loadingCategory, setLoadingCategory] = useState(false);
   const [loadingTemplate, setLoadingTemplate] = useState(true);

   useEffect(() => {
      updateTemplateList();
   }, []) // eslint-disable-line

   useEffect(()=>{
      
      
      if(props.selectedTemplate){
         setLoadingTemplate(true);

         getTemplateList(props.selectedCategoryId)

      }
   },[props.reloadTemplateList])

   const updateTemplateList = async () => {

      if(cardMultiStepContext.messageType !== GIFT){
         if(cardMultiStepContext.messageType === POSTCARD && props.postcardCategoryList != null){
            setCardRelatedData(props.postcardCategoryList)
         }else if(cardMultiStepContext.messageType === GREETING_CARD && props.greetingCardCategoryList != null){
            setCardRelatedData(props.greetingCardCategoryList)
         }else if(cardMultiStepContext.messageType === LETTER && props.letterCategoryList != null){
            setCardRelatedData(props.letterCategoryList)
         }
         else if(cardMultiStepContext.messageType === THANKSIO_GREETING_CARD && props.thanksIoGreetingCardCategoryList != null){
            setCardRelatedData(props.thanksIoGreetingCardCategoryList)
         }else{
            await props.getCategoryList({ 'message_type': cardMultiStepContext.messageType }, (response) => {
               if(response.success){
                  setCardRelatedData(response.data)
               }else{
                  window.showNotification('ERROR','Something went wrong')
                  cardMultiStepContext.moveToSpecificStep( 1)
               }
            });
         }
         
      }else{
         setLoadingCategory(false)
         setCardRelatedData(props.greetingCardCategoryList)

      }
   }

   const setCardRelatedData=(responseData)=>{
      props.storeCategoryList(responseData.template_categories)
      let changeCategory = false
      let tLength = responseData.template_categories.length

      if(cardMultiStepContext.editableCampaign){

         if(props.selectedCategoryId === ''){
            for(let j=0; j<tLength; j++ ){
               if(responseData.template_categories[j].id === cardMultiStepContext.editableCampaign.template_category_id){
                  changeCategory = true
                  // setLoadingTemplate(false);
                  break;
               }
            }
            if(changeCategory){
               props.storeMultipleReducers({selectedCategoryId : cardMultiStepContext.editableCampaign.template_category_id
                  })
                  getTemplateList(cardMultiStepContext.editableCampaign.template_category_id, true);

            }else{
               props.storeMultipleReducers({selectedCategoryId : responseData.template_categories[0].id})
               getTemplateList(responseData.template_categories[0].id, true);
            }
            setLoadingCategory(false)
            
         }else{
            //nothing required
            setLoadingTemplate(false);
         }
      }else{

         if(props.selectedCategoryId === ''){
            // for(let j=0; j<tLength; j++ ){
            //    if(responseData.template_categories[j].id === props.selectedCategoryId){
            //       changeCategory = true
            //       setLoadingTemplate(false);
            //       break;
            //    }
            // }
            // if(changeCategory){
               props.storeMultipleReducers({selectedCategoryId : responseData.template_categories[0].id})
               setLoadingCategory(false)
   
               getTemplateList(responseData.template_categories[0].id, true);
            // }
         }else{
            setLoadingTemplate(false);

            //nothing required
         }

      }
   }

   const getTemplateList = async (id, firstLoad = false) => {
      if (id !== '') {
         await getMailTemplate({
            category_id: id
         }).then(res => {
            let templateData = res.data.data

            if(firstLoad && cardMultiStepContext.editableCampaign && !props.templateList){
               // props.storeTemplateList(templateData)
               let stateChanged = false;
               for( let i=0; i<templateData.length;i++){

                  if(templateData[i].id === cardMultiStepContext.editableCampaign.template_id){
                     stateChanged = true

                     // setSelectedTemplateId(templateData[i].id)
                     // setSelectedTemplateImageUrl(templateData[i].image_path)
                     props.storeMultipleReducers({templateList : templateData ,selectedTemplate : templateData[i]})
                     // props.storeSelectedTemplate(templateData[i]);
                     break;
                  }
               }

               if(!stateChanged){
                  // setSelectedTemplateId(templateData[0].id)
                  // setSelectedTemplateImageUrl(templateData[0].image_path)
                  if(templateData[0]){
                     props.storeMultipleReducers({templateList : templateData ,selectedTemplate : templateData[0]})

                  }else{
                     props.storeMultipleReducers({templateList : templateData  ,selectedTemplate : null})

                  }

                  // props.storeSelectedTemplate(templateData[0]);
               }     

            }else{

               if(templateData[0]){

                  props.storeMultipleReducers({templateList : res.data.data ,selectedTemplate : templateData[0]})
               }else{
                  props.storeMultipleReducers({templateList : res.data.data ,selectedTemplate : null})

               }
               // props.storeTemplateList(res.data.data)
               // props.storeSelectedTemplate(res.data.data[0]);
            }
            
            setLoadingTemplate(false);
         })
      }
   }

   const handleCategoryDropdownChange = (value) => {

      if (value !== '' && value !== props.selectedCategoryId) {
         props.storeMultipleReducers({selectedCategoryId: value})

         getTemplateList(value);

         // let tempTemplateList = [...templateList];
         // props.storeTemplateList(tempTemplateList)
         // setLoadingTemplate(true);
         // getTemplateList(value);
      }
   }

   const selectTemplate = (e, index) => {
      e.preventDefault();
      // setSelectedTemplateId(templateList[index].id)
      // setSelectedTemplateImageUrl(templateList[index].image_path)
      props.storeSelectedTemplate(props.templateList[index]);

   }

   const changeCreateNewPostcardFrom = (from) => {
      props.storeMultipleReducers({createNewPostcardFrom :from})
   }

   const addNewTemplate = (newTemplate) => {

      // if(selectedCategoryId == newTemplate.agency_card_category_id){
      //    setLoadingTemplate(true);
      //    setTemplateList(templateList.push(newTemplate))
      //    setLoadingTemplate(false);
      // }else{
      props.storeSelectedCategoryId(newTemplate.agency_card_category_id);
      setLoadingTemplate(true);
      getTemplateList(newTemplate.agency_card_category_id);


      // }
   }

   const loader = () => {
      let skeloader = []
      for (let i = 0; i < 4; i++) {
         skeloader.push(

            <Skeleton animation="wave" style={{ height: '60%', width: '25%', marginLeft: 25 }} />
         )
      }

      return (
         <div style={{ height: 400, width: 600 }}>
            <Skeleton animation="wave" style={{ height: '52px', width: '352px', marginLeft: 20 }} />
            <div className="loading-skeleton-in-direct-mail d-flex row justify-content-center" style={{ height: 350, width: 600 }}>
               {skeloader}
            </div>
         </div>
         
      )

   }

   const emptyFieldTitle=()=>{

      switch(cardMultiStepContext.messageType){
         case POSTCARD:
            return "Choose Postcard Template Category"
         case LETTER:
            return "Choose Letter Template Category"
         default :
            return "Choose Greeting card Template Category"
      }
   }

   return (
      <React.Fragment>
         <fieldset>
            <div className="custom-form-card form-card">
               <div className="row">
                  <div className="col s12">
                     <div className="first-step-page-main">
                        {/* <!-- start design step 01 section row--> */}
                        {props.createNewPostcardFrom === '' && props.stepNo === STEPNO &&
                           <div className="row">
                              <div className="col xl7">
                                 <div className="select-template-main-area second__step__area__one">
                                    <div className="templete-opt-area">
                                       <div className="margin-none-row row">
                                          <div className="col s12">
                                             {/* <div className="custom-input deal-select-label custom-select-arrow col s8"> */}
                                             <div className="custom-input deal-select-labe col s8">
                                                <label for="lead_name" className="second__step__area__label" >Select Template</label>

                                                {(!loadingCategory && props.categoryList!=null) &&
                                                <DropdownHelper 
                                                   datalist={props.categoryList}
                                                   noneFieldRequired={true}
                                                   noneFieldTitle={emptyFieldTitle()}
                                                   className=""
                                                   mapping={{ title: "title" }}
                                                   menuItemClassName=""
                                                   updateSelectedData={handleCategoryDropdownChange}
                                                   selectedValue={props.selectedCategoryId}
                                                   // updateDatatable={updateDatatable}
                                                   dropdownHeader={'campaignChange'}

                                                />
                                                }
                                                {/* {loadingCategory &&
                                                   <Loading />
                                                } */}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="updated_templete-img-main templete-img-main templete__img__main awesome__scroll_bar">
                                       {/* <!-- single item --> */}
                                       {!loadingTemplate && props.templateList != null &&
                                          props.templateList.map((template, index) => {
                            
                                             if(!Helper.isEmpty(template.image_path) && template.agency_card_category_id === props.selectedCategoryId){
                                                return(
                                                   <div key={`${index}${props.selectedCategoryId}${template.id}`} className={"tem-single-item " + (template.id === props.selectedTemplate.id ? "is_template_selected" : "")} onClick={(e) => selectTemplate(e, index)}>
                                                   <a href="#!" onClick={(e)=>e.preventDefault()}><img src={template.image_path} alt="img" /></a>
                                                </div>
                                                )
                                             }else{
                                                // console.log('null value')
                                                return null
                                             }
                                                
                                             
                                          })
                                       }
                                       {!loadingTemplate && props.templateList != null && props.templateList.length ===0 &&
                                          <div>
                                             <p>No Templates available</p>
                                          </div>
                                       }
                                       {loadingTemplate &&
                                          // <Loading />
                                          loader()

                                       }
                                    </div>
                                 </div>
                              </div>
                              <div className="col xl5">
                                 <div className="select-opt-right">
                                    <div className="select-opt-right-flex">
                                       {Utils.getAccountData('agencyId') === 1 &&
                                          <div className="select-single-item" onClick={() => props.storeMultipleReducers({createNewPostcardFrom : 'canva'})}>
                                             <span>Design in Canva</span>
                                             <a href="#!" onClick={(e)=>e.preventDefault()}><img src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/81z01Q736F604c1aiq9R.png`} alt="" /></a>
                                          </div>
                                       }
                                       <div className="select-single-item" onClick={() => props.storeMultipleReducers({ createNewPostcardFrom : 'computer'})}>
                                          <span>Upload From Computer</span>
                                          <div className="custom-file">
                                             {/* <input type="file" className="custom-file-input" id="customFileLangHTML" /> */}
                                             <label className="custom-file-label cursor-pointer" for="customFileLangHTML" data-browse="Bestand kiezen">
                                                <img src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/MLIRUCr40u1126286CL4.png`} alt="computer" />
                                             </label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        }
                        {/* End design step 01 section row */}
                        {props.createNewPostcardFrom !== '' && props.stepNo === STEPNO &&
                           <CreateNewCardTemplate
                              // categoryList={props.categoryList}
                              // submitNewPostcard={submitNewPostcard}
                              // changeCreateNewPostcardFrom={changeCreateNewPostcardFrom}
                              // createNewPostcardFrom={props.createNewPostcardFrom}
                              // addNewTemplate={addNewTemplate}
                              // messageType={cardMultiStepContext.messageType}
                           // newPostcardTemplateId={this.createdNewPostcardtemplate}
                           />
                        }

                        {props.stepNo === HALFSTEPNO &&
                           <SecondHalfStep
                           />
                        }
                        {/* End design step 04 section row */}
                     </div>
                  </div>
               </div>
            </div>
         </fieldset>
      </React.Fragment>

   )


}
const mapStateToProps = state => {
   return {
      postcardCategoryList: state.cardBroadcastReducer.postcardCategoryList,
      greetingCardCategoryList: state.cardBroadcastReducer.greetingCardCategoryList,
      letterCategoryList: state.cardBroadcastReducer.letterCategoryList,
      thanksIoGreetingCardCategoryList: state.cardBroadcastReducer.thanksIoGreetingCardCategoryList,
      selectedTemplate: state.cardBroadcastReducer.selectedTemplate,
      categoryList: state.cardBroadcastReducer.categoryList,
      templateList: state.cardBroadcastReducer.templateList,
      selectedCategoryId: state.cardBroadcastReducer.selectedCategoryId,
      stepNo: state.cardBroadcastReducer.stepNo,
      createNewPostcardFrom: state.cardBroadcastReducer.createNewPostcardFrom,
      reloadTemplateList: state.cardBroadcastReducer.reloadTemplateList,
   };
};

const mapDispatchToProps = dispatch => {
   return {
      getCategoryList: (params, callback) => dispatch(getCategoryList(params, callback)),
      storeSelectedTemplate: (params, callback) => dispatch(storeSelectedTemplate(params, callback)),
      storeCategoryList: (params, callback) => dispatch(storeCategoryList(params, callback)),
      storeSelectedCategoryId: (params, callback) => dispatch(storeSelectedCategoryId(params, callback)),
      // storeTemplateList: (params, callback) => dispatch(storeTemplateList(params, callback)),
      storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

   };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
